import { List as ImmutableList } from 'immutable';
import {
  IRecipientListItemImt,
  IStatistics,
  ITotalsItem,
} from 'modules/reports/interfaces/campaignsReport';
import { reportStatistics, StatisticsNames } from 'modules/reports/constants/campaignsReport';

export const addFullNameToRecipients = (recipientsList: ImmutableList<IRecipientListItemImt>) =>
  recipientsList?.map((recipient: IRecipientListItemImt) =>
    recipient.set('name', `${recipient.get('firstName')} ${recipient.get('lastName')}`),
  );

export const prepareStatistics = (stats: IStatistics): ITotalsItem[] => {
  const recipientsItem = reportStatistics.find(el => el.id === StatisticsNames.RECIPIENTS);

  if (recipientsItem) {
    recipientsItem.amount = stats.get('recipientsCount');
  }

  const successfulDeliveriesItem = reportStatistics.find(
    el => el.id === StatisticsNames.SUCCESSFUL_DELIVERIES,
  );

  if (successfulDeliveriesItem) {
    successfulDeliveriesItem.amount = (stats.get('totalDelivered') / stats.get('totalSend')) * 100;
  }

  const openRateItem = reportStatistics.find(el => el.id === StatisticsNames.OPEN_RATE);

  if (openRateItem) {
    openRateItem.amount = (stats.get('totalOpened') / stats.get('totalSend')) * 100;
  }

  const totalClicksItem = reportStatistics.find(el => el.id === StatisticsNames.TOTAL_CLICKS);

  if (totalClicksItem) {
    totalClicksItem.amount = stats.get('totalClicked');
  }

  const clickRateItem = reportStatistics.find(el => el.id === StatisticsNames.CLICK_RATE);

  if (clickRateItem) {
    clickRateItem.amount = (stats.get('totalClicked') / stats.get('totalSend')) * 100;
  }

  const totalOpensItem = reportStatistics.find(el => el.id === StatisticsNames.TOTAL_OPENS);

  if (totalOpensItem) {
    totalOpensItem.amount = stats.get('totalOpened');
  }

  const bouncedItem = reportStatistics.find(el => el.id === StatisticsNames.BOUNCED);

  if (bouncedItem) {
    bouncedItem.amount = (stats.get('totalBounced') / stats.get('totalSend')) * 100;
  }

  const unsubscribedItem = reportStatistics.find(el => el.id === StatisticsNames.UNSUBSCRIBED);

  if (unsubscribedItem) {
    unsubscribedItem.amount = (stats.get('totalUnsubscribed') / stats.get('totalSend')) * 100;
  }

  return reportStatistics;
};
