import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormProvider, useForm } from 'react-hook-form';
import { List as ImmutableList } from 'immutable';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  selectPersonsFoundList,
  selectPersonsFoundListLoading,
} from 'common/state/newPerson/primaryInfo/selectors';
import {
  resetPersonsFound,
  searchMembers,
  searchReferralMembers,
} from 'common/state/newPerson/primaryInfo/actions';
import { ICameFromIndicatorDictionaryItemImt } from 'common/interfaces/dictionary';
import {
  IImmutablePrimaryMemberInfo,
  IPersonAdditionalFormFields,
  IPrimaryMemberInfo,
} from 'common/components/PersonProfile/interfaces';
import { INamedEntity, INamedEntityImt, IObject } from 'common/interfaces/common';
import { PeakModules } from 'common/constants/peakModules';
import { IUserProfileInfoImt } from 'modules/authentication/interfaces';
import { PersonSearchParamsType } from 'common/interfaces/http';
import { PhoneTypes } from 'common/components/PersonProfile/constants';
import { initialAddress } from 'common/constants/initialValues';
import { StepContext } from 'common/createContext/stepContext';
import { useAppDispatch } from 'store/hooks';
import { PrimaryInfoStepValidationSchema } from './ValidationSchema/ValidationSchema';
import { ScrollBox } from 'common/components/index';
import GeneralInfoSection from 'modules/crm/components/LeadForm/LeadPrimaryInfoStep/GenerealInfoSection/GeneralInfoSection';
import ContactSection from 'modules/crm/components/LeadForm/LeadPrimaryInfoStep/ContactSection/ContactSection';
import SalesInfoSection from './SalesInfoSection/SalesInfoSection';
import AttachmentsSection from './AttachmentsSection/AttachmentsSection';
import LeadSettingsSection from './LeadSettingsSection/LeadSettingsSection';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import isFormDirty from 'common/hooks/isFormDirty';
import useDiscardChangesContext from 'common/hooks/useDiscardChangesContext';
import DiscardChangesModalProvider from 'common/modals/DiscardChangesModal/DiscardChangesModalProvider';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';
import LeadHomeClubModal from './LeadHomeClubModal/LeadHomeClubModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    flex: 1,
    height: '100%',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  formInner: {
    paddingBottom: theme.spacing(2),
  },
}));

interface IMemberPrimaryInfoStep {
  employees: INamedEntity<number>[];
  cameFromIndicators?: ImmutableList<ICameFromIndicatorDictionaryItemImt>;
  primaryInfo?: IImmutablePrimaryMemberInfo;
  isActiveMembersRestricted?: boolean;
  hideAutocomplete?: boolean;
  selectedMembers?: IObject[];
  setPrimaryInfo?: (person: IImmutablePrimaryMemberInfo) => void;
  isResponseSuccess?: boolean;
  handleOnSubmit?: (data?, homeClubId?: string) => void;
  hasHiddenFooter?: boolean;
  currentUser?: IUserProfileInfoImt;
  module?: PeakModules;
  selectedClubId?: string;
}

const getInitialValues = (currentUser, isPhoneRequiredField): IPrimaryMemberInfo => {
  let salesperson: Record<string, any> | null = null;

  if (currentUser && currentUser.get('salesperson')) {
    salesperson = {
      id: currentUser.get('id'),
      name: `${currentUser.get('firstName')} ${currentUser.get('lastName')}`,
    };
  }

  return {
    firstName: '',
    lastName: '',
    birthday: null,
    gender: '',
    barcode: '',
    image: null,
    email: '',
    address: initialAddress,
    attachments: [],
    phones: isPhoneRequiredField
      ? [
          {
            phoneType: PhoneTypes.Mobile,
            phone: '',
            allowAutomatedCalls: true,
            canReceiveSms: true,
            useAsDefault: true,
          },
        ]
      : [],
    salesperson,
    cameFromValue: '',
    cameFromIndicatorId: '',
    referralMember: null,
    leadMember: false,
    ptLeadMember: false,
  };
};

const MemberPrimaryInfoStep: FC<IMemberPrimaryInfoStep> = ({
  employees,
  cameFromIndicators,
  isActiveMembersRestricted,
  selectedMembers,
  primaryInfo,
  setPrimaryInfo = () => {},
  hideAutocomplete,
  handleOnSubmit,
  isResponseSuccess,
  hasHiddenFooter,
  currentUser,
  module,
  selectedClubId,
}) => {
  const dispatch = useAppDispatch();

  const membersAutocompleteList: ImmutableList<
    IPrimaryMemberInfo | INamedEntityImt
  > = useRootSelector(selectPersonsFoundList);
  const isSubmitted = useRootSelector(selectIsSubmittedRecommendedFieldsModal);
  const profileRequiredFields = useRootSelector(selectProfileRequiredFields);
  const isLoading = useRootSelector(selectPersonsFoundListLoading);
  const [isOpenLeadHomeClubModal, setIsOpenLeadHomeClubModal] = useState(false);
  const [homeClub, setHomeClub] = useState<string | null>(null);

  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();
  const { onBack, renderFooter, onNext } = useContext(StepContext);
  const dcContext = useDiscardChangesContext();
  const classes = useStyles();

  const initialValues = useMemo(
    () =>
      getInitialValues(
        currentUser,
        profileRequiredFields.get(RequiredProfileInfo.PhoneNumber) !== FieldNecessity.Required,
      ),
    [currentUser, profileRequiredFields],
  );

  const formMethods = useForm<IPrimaryMemberInfo & IPersonAdditionalFormFields>({
    defaultValues: initialValues,
    resolver: yupResolver(PrimaryInfoStepValidationSchema(employees, profileRequiredFields)) as any, // TODO - PRM-1810 need resolver type
    mode: 'onBlur',
  });

  const { handleSubmit, reset, getValues, formState } = formMethods;
  const isDirty = isFormDirty(formState);

  useEffect(() => {
    if (
      primaryInfo?.get('firstName') ||
      primaryInfo?.get('lastName') ||
      primaryInfo?.get('phones')?.size
    ) {
      const convertedPrimaryInfo = primaryInfo.toJS();
      const { referralMember, cameFromIndicator, phones, salesperson } = convertedPrimaryInfo;

      reset({
        ...initialValues,
        ...convertedPrimaryInfo,
        referralMember,
        phones: phones?.length
          ? phones
          : [
              {
                phoneType: PhoneTypes.Mobile,
                phone: '',
                allowAutomatedCalls: true,
                canReceiveSms: true,
                useAsDefault: true,
              },
            ],
        cameFromIndicatorId: cameFromIndicator?.id,
        cameFromValue: convertedPrimaryInfo.cameFromValue || '',
        cameFromType: cameFromIndicator?.type,
        optional: cameFromIndicator?.optional,
        attachments: convertedPrimaryInfo?.attachments || [],
        ...(salesperson && {
          salesperson: {
            id: salesperson.id,
            name: `${salesperson.firstName} ${salesperson.lastName}`,
          },
        }),
      });
    } else {
      let newValues: IObject | null = null;

      if (module === PeakModules.FrontDesk) {
        const convertedPrimaryInfo = primaryInfo?.toJS();

        if (convertedPrimaryInfo) {
          newValues = {
            ...initialValues,
            ...convertedPrimaryInfo,
          };

          if (convertedPrimaryInfo.leadMember) {
            newValues.leadMember = convertedPrimaryInfo.leadMember;
          }

          if (convertedPrimaryInfo.ptLeadMember) {
            newValues.ptLeadMember = convertedPrimaryInfo.ptLeadMember;
          }
        }
      }

      if (!newValues) {
        newValues = { ...initialValues };
      }

      reset(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryInfo, reset]);

  useEffect(() => {
    if (onNext && isResponseSuccess) {
      onNext();
    }
  }, [isResponseSuccess, onNext]);

  useEffect(() => {
    if (isSubmitted) {
      const { optional, cameFromType, referralMember, salesperson, ...data } = getValues();
      const onClickSubmit = handleOnSubmit || onNext;

      if (onClickSubmit) {
        onClickSubmit({
          ...data,
          referralMemberId: referralMember?.id,
          assignedSalespersonId: salesperson?.id,
        });
      }

      dispatch(showRecommendedFieldsModal(false));
    }
  }, [isSubmitted, getValues, handleOnSubmit, onNext, dispatch]);

  useEffect(() => {
    if (homeClub) {
      const { optional, cameFromType, referralMember, salesperson, ...data } = getValues();

      const onClickSubmit = handleOnSubmit || onNext;

      if (onClickSubmit) {
        onClickSubmit(
          {
            ...data,
            referralMemberId: referralMember?.id,
            assignedSalespersonId: salesperson?.id,
          },
          homeClub,
        );
      }
    }
  }, [homeClub, getValues, onNext, handleOnSubmit]);

  const getOptionsByValue = useCallback(
    (searchString: string, customerSearchSortBy: PersonSearchParamsType) => {
      dispatch(searchMembers(searchString, customerSearchSortBy, module));
    },
    [dispatch, module],
  );

  const getReferralMembersByValue = useCallback(
    (searchString: string) => {
      dispatch(searchReferralMembers(searchString, module));
    },
    [dispatch, module],
  );

  const clearSearchResults = useCallback(() => {
    dispatch(resetPersonsFound());
  }, [dispatch]);

  const onSubmit = (values: IPrimaryMemberInfo & IPersonAdditionalFormFields) => {
    if (module === PeakModules.Members && !selectedClubId) {
      setIsOpenLeadHomeClubModal(true);
    } else {
      const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty<
        IPrimaryMemberInfo & IPersonAdditionalFormFields
      >(values);

      if (hasEmptyRecommendedFields) {
        dispatch(showRecommendedFieldsModal(true));
      } else {
        const { optional, cameFromType, referralMember, salesperson, ...data } = values;
        const onClickSubmit = handleOnSubmit || onNext;

        if (onClickSubmit) {
          onClickSubmit({
            ...data,
            referralMemberId: referralMember?.id,
            assignedSalespersonId: salesperson?.id,
          });
        }
      }
    }
  };

  const onCloseLeadHomeClubModal = () => {
    setIsOpenLeadHomeClubModal(false);
    setHomeClub(null);
  };

  const onSelectClubModalSubmit = (clubId: string) => {
    setHomeClub(clubId);
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <ScrollBox hasShadowsOnScroll>
          <form
            id="primary-info-form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.root}
            autoComplete="none"
          >
            <Grid className={classes.formInner} container spacing={4}>
              <Grid item xs={12}>
                <GeneralInfoSection<ImmutableList<IPrimaryMemberInfo>>
                  clearSearchResults={clearSearchResults}
                  isPersonSearchLoading={isLoading}
                  persons={membersAutocompleteList as ImmutableList<IPrimaryMemberInfo>}
                  getOptionsByValue={getOptionsByValue}
                  setPrimaryInfo={setPrimaryInfo}
                  isActiveMembersRestricted={isActiveMembersRestricted}
                  selectedMembers={selectedMembers}
                  hideAutocomplete={hideAutocomplete}
                  recommendedFields={profileRecommendedFields}
                />
              </Grid>

              <Grid item xs={12}>
                <ContactSection<ImmutableList<IPrimaryMemberInfo>>
                  clearSearchResults={clearSearchResults}
                  isPersonSearchLoading={isLoading}
                  persons={membersAutocompleteList as ImmutableList<IPrimaryMemberInfo>}
                  getOptionsByValue={getOptionsByValue}
                  setPrimaryInfo={setPrimaryInfo}
                  isActiveMembersRestricted={isActiveMembersRestricted}
                  selectedMembers={selectedMembers}
                  hideAutocomplete={hideAutocomplete}
                  recommendedFields={profileRecommendedFields}
                  requiredFields={profileRequiredFields}
                />
              </Grid>

              <Grid item xs={12}>
                <SalesInfoSection
                  primaryInfo={primaryInfo}
                  isPersonSearchLoading={isLoading}
                  persons={membersAutocompleteList as ImmutableList<INamedEntityImt>}
                  getReferralMembersByValue={getReferralMembersByValue}
                  clearSearchResults={clearSearchResults}
                  cameFromIndicators={cameFromIndicators}
                  employees={employees}
                  recommendedFields={profileRecommendedFields}
                />
              </Grid>

              <Grid item xs={12}>
                <LeadSettingsSection />
              </Grid>

              <Grid item xs={12}>
                <AttachmentsSection />
              </Grid>
            </Grid>
          </form>
        </ScrollBox>

        {!hasHiddenFooter && !!renderFooter && renderFooter(onBack, handleSubmit(onSubmit))}
      </FormProvider>

      {dcContext && isDirty ? <DiscardChangesModalProvider {...dcContext} /> : null}

      {isOpenLeadHomeClubModal && (
        <LeadHomeClubModal onClose={onCloseLeadHomeClubModal} onSubmit={onSelectClubModalSubmit} />
      )}
    </>
  );
};

export default MemberPrimaryInfoStep;
