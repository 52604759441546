/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { Component } from 'react';
import { createStyles, StyledComponentProps, withStyles } from '@material-ui/core';
import { List } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { Connect } from 'common/decorators';
import TableSection, {
  ITableHeaderColumn,
  ITableSectionColumn,
} from 'modules/reports/components/TableSection/TableSection';
import {
  selectRevenueSummary,
  selectRevenueSummaryLoading,
} from 'modules/reports/state/salesPOS/selectors';
import { fetchRevenueSummary } from 'modules/reports/state/salesPOS/actions';
import { DEFAULT_TABLE_PARAMS } from 'common/constants/table';
import { IRevenueSummaryItem, IRevenueSummaryItemImt } from 'modules/reports/interfaces/salesPOS';
import { DispatchFunction } from 'common/state/interfaces';
import { ITableParams, Order } from 'common/interfaces/table';
import { formatPrice } from 'common/utils';
import messages from 'modules/reports/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import { selectRoot } from 'common/hooks/useRootSelector';

const styles = () => createStyles({});

interface IInvoicesSummaryProps extends StyledComponentProps {
  mainTableParams: ITableParams;
  revenueSummary?: List<IRevenueSummaryItemImt>;
  revenueSummaryLoading?: boolean;

  fetchRevenueSummaryData?: (params?) => void;
}

@(withStyles(styles) as any)
@(Connect({
  mapStateToProps: () => ({
    revenueSummary: selectRoot(selectRevenueSummary()),
    revenueSummaryLoading: selectRoot(selectRevenueSummaryLoading()),
  }),
  mapDispatchToProps: (dispatch: DispatchFunction) => ({
    fetchRevenueSummaryData: (requestOptions?) => dispatch(fetchRevenueSummary(requestOptions)),
  }),
}) as any)
class RevenueSummary extends Component<IInvoicesSummaryProps, ITableParams<IRevenueSummaryItem>> {
  private readonly columnSettings: ITableSectionColumn[];

  private readonly headerSettings: ITableHeaderColumn<IRevenueSummaryItem>[];

  constructor(props: IInvoicesSummaryProps) {
    super(props);

    this.state = {
      page: 0,
      perPage: 5,
      orderBy: 'code',
      order: 'desc',
      filters: [],
    };

    this.columnSettings = [
      {
        name: 'code',
        label: <FormattedMessage {...inputLabels.revenueCode} />,
      },
      {
        name: 'quantity',
        label: <FormattedMessage {...messages.qty} />,
        align: 'right',
      },
      {
        name: 'netSales',
        label: <FormattedMessage {...inputLabels.netSales} />,
        align: 'right',
        transform: value => formatPrice(value),
      },
    ];

    this.headerSettings = [
      {
        name: 'code',
        label: <FormattedMessage {...inputLabels.revenueCode} />,
        sort: true,
      },
      {
        name: 'quantity',
        label: <FormattedMessage {...messages.qty} />,
        sort: true,
        align: 'right',
      },
      {
        name: 'netSales',
        label: <FormattedMessage {...inputLabels.netSales} />,
        align: 'right',
        sort: true,
      },
    ];
  }

  componentDidMount(): void {
    const { fetchRevenueSummaryData, mainTableParams } = this.props;
    const { page, perPage, orderBy, order } = this.state;

    this.setState({ filters: mainTableParams.filters });

    if (fetchRevenueSummaryData) {
      fetchRevenueSummaryData({
        ...DEFAULT_TABLE_PARAMS,
        filters: mainTableParams.filters,
        page,
        perPage,
        orderBy,
        order,
      });
    }
  }

  componentDidUpdate(): void {
    const { mainTableParams, fetchRevenueSummaryData } = this.props;
    const { page, perPage, orderBy, order, filters } = this.state;

    if (JSON.stringify(filters) !== JSON.stringify(mainTableParams.filters)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filters: mainTableParams.filters });

      if (fetchRevenueSummaryData) {
        fetchRevenueSummaryData({
          ...DEFAULT_TABLE_PARAMS,
          filters: mainTableParams.filters,
          page,
          perPage,
          orderBy,
          order,
        });
      }
    }
  }

  handleChangeTableProps = (page: number, orderBy, order: Order): void => {
    this.updateTableParams({ ...this.state, page, orderBy, order });
  };

  updateTableParams = (tableParams: ITableParams<IRevenueSummaryItem>): void => {
    this.setState(tableParams);
    const { fetchRevenueSummaryData } = this.props;

    if (fetchRevenueSummaryData) {
      fetchRevenueSummaryData(tableParams);
    }
  };

  render(): JSX.Element {
    const { revenueSummary, revenueSummaryLoading } = this.props;
    const { page, perPage, orderBy, order } = this.state;

    return (
      <TableSection<IRevenueSummaryItem, IRevenueSummaryItemImt>
        id="sales-category-summary-section"
        title={<FormattedMessage {...messages.revenuCodeSummaryTitle} />}
        columns={this.columnSettings}
        headerColumns={this.headerSettings}
        data={revenueSummary as any} // TODO - PRM-3575 need types
        dataLoading={!!revenueSummaryLoading}
        page={page}
        total={revenueSummary?.size || 25}
        order={order}
        orderBy={orderBy}
        perPage={perPage}
        hidePagination
        onSettingsChanged={this.handleChangeTableProps}
      />
    );
  }
}

export default RevenueSummary;
