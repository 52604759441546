import * as yup from 'yup';
import { stringNumberSchema } from 'common/validationSchemas/stringNumberSchema';
import inputErrors from 'common/messages/inputErrors';
import { getRequiredErrorMessage } from 'common/utils/validation';

export const getClientRewardsValidationSchema = (rewards: number): yup.AnyObjectSchema => {
  return yup.object().shape({
    amount: stringNumberSchema
      .max(rewards, () => inputErrors.amountHigherThanClientRewards)
      .min(1, () => inputErrors.checksAmountMinError)
      .typeError(getRequiredErrorMessage)
      .required(getRequiredErrorMessage),
  });
};
