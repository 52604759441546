import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import { getQueueColor } from 'common/utils';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { BillingsChartLegendLabels } from 'modules/reports/constants/scheduledBillingsReport';

import { ScheduledBillingsActivityType } from 'modules/reports/interfaces/scheduledBillingsReport';

import { ChartFilter } from 'modules/reports/components/index';

const scheduledBillingsActivityTypes = Object.values(ScheduledBillingsActivityType);

interface IProps {
  onChange: (filters: ScheduledBillingsActivityType[]) => void;
}

const BillingsChartLegend = ({ onChange }: IProps): JSX.Element => {
  const [filters, setFilters] = useState<ScheduledBillingsActivityType[]>(
    scheduledBillingsActivityTypes,
  );

  const renderIntlMessage = useRenderIntlMessage();

  useEffect(() => {
    onChange(filters);
  }, [filters, onChange]);

  const handleFilterStateChange = (filterType: ScheduledBillingsActivityType): void => {
    if (filters.includes(filterType)) {
      setFilters(filters.filter(f => f !== filterType));
    } else {
      setFilters([...filters, filterType]);
    }
  };

  return (
    <Grid container spacing={3} justifyContent="flex-end">
      {scheduledBillingsActivityTypes.map((type: ScheduledBillingsActivityType, index: number) => (
        <Grid item key={type}>
          <ChartFilter
            label={renderIntlMessage(BillingsChartLegendLabels[type]) || ''}
            color={getQueueColor(index)}
            isSelected={filters.includes(type)}
            onChange={() => handleFilterStateChange(type)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default BillingsChartLegend;
