import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';

import { ICheckInHistoryImt } from 'common/components/PersonProfile/interfaces';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { IFilterSettings } from 'common/interfaces/filter';
import { IPageMetaImt } from 'common/interfaces/pagination';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { Table } from 'common/components/index';

import tableHeaders from 'common/messages/tableHeaders';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';

interface IProps {
  title?: string | JSX.Element;
  checkInsHistory: ImmutableList<ICheckInHistoryImt>;
  checkInsHistoryMeta?: IPageMetaImt;
  isCheckInsHistoryLoading: boolean;
  hideSearchInput?: boolean;
  hidePagination?: boolean;
  tableFilterSettings?: IFilterSettings[];
  tableParams?: ITableParams;
  onChangeTableParams?: (tableParams: ITableParams) => void;
  disableSorting?: boolean;
  hidePadding?: boolean;
  suppressFiltersMobilePadding?: boolean;
}

const CheckInsHistoryTable = ({
  title,
  tableParams,
  checkInsHistory,
  checkInsHistoryMeta,
  isCheckInsHistoryLoading,
  hidePagination,
  hideSearchInput,
  onChangeTableParams,
  tableFilterSettings,
  disableSorting,
  hidePadding = false,
  suppressFiltersMobilePadding = false,
}: IProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();

  const headerOptions = useMemo(
    (): IHeadCell[] => [
      {
        id: 'club',
        label: <FormattedMessage {...tableHeaders.club} />,
        sort: !disableSorting,
      },
      {
        id: 'entrance',
        label: <FormattedMessage {...tableHeaders.entrance} />,
        sort: !disableSorting,
      },
      {
        id: 'date',
        label: <FormattedMessage {...tableHeaders.date} />,
        sort: !disableSorting,
      },
    ],
    [disableSorting],
  );

  const rows = useMemo(() => {
    return checkInsHistory
      ?.map(
        (item: ICheckInHistoryImt): ITableRow => {
          return {
            id: item.get('id'),
            cells: [
              {
                label: item.getIn(['club', 'title']) || '-',
              },
              {
                label: item.getIn(['entrance', 'title']),
              },
              {
                label: `${timezoneMoment(item.get('checkinDate')).format(
                  DEFAULT_DATE_TIME_FORMAT,
                )}`,
              },
            ],
          };
        },
      )
      .toJS();
  }, [checkInsHistory, timezoneMoment]);

  return (
    <Table
      title={title}
      rows={rows}
      showPerPageSelect={!hidePagination}
      headerOptions={headerOptions}
      hideSearchInput={hideSearchInput}
      hidePagination={hidePagination}
      isLoading={isCheckInsHistoryLoading}
      page={checkInsHistoryMeta?.get('page')}
      totalRows={checkInsHistoryMeta?.get('total')}
      onChangeParams={onChangeTableParams}
      tableParams={tableParams}
      filters={tableFilterSettings}
      borderShadow={false}
      hidePadding={hidePadding}
      suppressFiltersMobilePadding={suppressFiltersMobilePadding}
    />
  );
};

export default CheckInsHistoryTable;
