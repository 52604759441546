import { IntlShape, MessageDescriptor } from 'react-intl';

class IntlService {
  private intl: IntlShape;

  public setIntl(intl: IntlShape): void {
    this.intl = intl;
  }

  public formatMessage(message: MessageDescriptor, values?: Record<string, any>): string {
    return this.intl.formatMessage(message, values);
  }
}

export default new IntlService();
