import * as yup from 'yup';
import { dateSchema, futureDateSchema } from 'common/validationSchemas/dateSchemas';
import moment from 'moment-timezone';
import { getRequiredErrorMessage, getEndTimeErrorMessage } from 'common/utils/validation';
import { availabilityValidationSchema } from 'common/validationSchemas/dayTimeAvailabilitySchema';
import inputErrors from 'common/messages/inputErrors';
import messages from 'modules/booking/messages';

export const AvailabilitySchema = yup.object().shape({
  startDate: dateSchema.required(getRequiredErrorMessage),
  endDate: yup.string().when({
    is: value => !!value,
    then: futureDateSchema.test('endDate', getEndTimeErrorMessage, function(endDate) {
      return !this.parent.startDate || moment(this.parent.startDate).isBefore(endDate);
    }),
    otherwise: yup.string().nullable(),
  }),
  clubs: yup
    .array()
    .min(1, getRequiredErrorMessage)
    .required(getRequiredErrorMessage),
  weekdays: yup
    .array()
    .of(yup.object().shape({}))
    .min(1, () => inputErrors.weekdaysMinLengthError),
  dayTimeAvails: yup.array().of(availabilityValidationSchema),
});

export const ResourceValidationSchema = yup.object().shape({
  label: yup
    .string()
    .max(32, () => messages.resourceLabelNameLength)
    .required(getRequiredErrorMessage),
  employee: yup.object().nullable(),
  resourceTag: yup
    .array()
    .min(1, getRequiredErrorMessage)
    .required(getRequiredErrorMessage),
  eventTag: yup
    .array()
    .min(1, getRequiredErrorMessage)
    .required(getRequiredErrorMessage),
  availability: yup.array().of(AvailabilitySchema),
});
