import React from 'react';
import { Box, createStyles, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { LeadStatuses } from 'modules/crm/constants/leads';
import { injectIntl, WrappedComponentProps } from 'react-intl';

type IStyleProps = {
  small?: boolean;
};

const useStyles = makeStyles<Theme, IStyleProps>(() =>
  createStyles({
    root: {
      minWidth: '20px',
      padding: '1px 4px 1px 4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1.5px solid',
      borderRadius: 4,
      fontSize: ({ small }) => (small ? '0.7rem' : '0.875rem'),
    },
  }),
);

interface IMemberStatusViewProps extends WrappedComponentProps {
  status: string;
  // eslint-disable-next-line react/no-unused-prop-types
  small?: boolean;
  full?: boolean;
}

const MemberStatusView = (props: IMemberStatusViewProps): JSX.Element => {
  const classes = useStyles(props);
  const { status, full, intl } = props;
  const leadStatus = LeadStatuses.find(status);
  const name = leadStatus ? intl.formatMessage(leadStatus.message) : null;

  const displayName = () => {
    if (name) {
      return full ? name : name.charAt(0);
    }
    return '-';
  };

  return (
    <Box display="flex">
      <Typography
        className={classes.root}
        variant="subtitle2"
        style={leadStatus ? { color: leadStatus.color } : {}}
      >
        {displayName()}
      </Typography>
    </Box>
  );
};

export default React.memo(injectIntl(MemberStatusView));
