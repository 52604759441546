import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, makeStyles } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogComponent, PasswordField } from 'common/components';
import messages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import { webPassSchema } from 'common/validationSchemas/passwordSchemas';
import { getRequiredErrorMessage } from 'common/utils/validation';
import inputErrors from 'common/messages/inputErrors';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { CustomTheme } from 'common/ui/interfaces';
import { useUpdateProfilePasswordMutation } from 'memberPortalModules/MemberProfile/state/profile/memberPortileProfile.api';
import { IFormEditMemberPortalPasswordValues } from 'memberPortalModules/MemberProfile/interface';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { useDispatch } from 'react-redux';

interface IEditCredentialsModalProps {
  onClose: () => void;
}

const validationSchema = yup.object({
  oldPassword: yup.string().required(getRequiredErrorMessage),
  newPassword: webPassSchema.required(getRequiredErrorMessage),
  confirmPassword: yup
    .string()
    .test(
      'confirmPassword',
      () => inputErrors.passwordsDoNotMatchError,
      function(password = '') {
        return password.trim() ? this.parent.newPassword === password : true;
      },
    )
    .required(getRequiredErrorMessage),
});

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const useStyles = makeStyles((theme: CustomTheme) => ({
  field: {
    paddingTop: theme.spacing(1.5),
  },
}));

const EditMemberProfilePasswordModal = ({ onClose }: IEditCredentialsModalProps): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();
  const dispatch = useDispatch();
  const [putProfilePassword, { isLoading }] = useUpdateProfilePasswordMutation();

  const formMethods = useForm<IFormEditMemberPortalPasswordValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const onSubmit = async (data: IFormEditMemberPortalPasswordValues) => {
    try {
      await putProfilePassword(data).unwrap();

      onClose();
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const classes = useStyles();
  const formId = 'update-password-form';

  return (
    <DialogComponent
      isOpen
      size="sm"
      title={<FormattedMessage {...messages.changePasswordHeader} />}
      submitBtnTitle={<FormattedMessage {...messages.saveBtn} />}
      onClose={onClose}
      formId={formId}
      loading={isLoading}
    >
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Controller
              name="oldPassword"
              control={control}
              render={({ field }) => (
                <PasswordField
                  variant="outlined"
                  label={<FormattedMessage {...inputLabels.currentPassword} />}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  fullWidth
                  error={Boolean(errors.oldPassword)}
                  helperText={renderIntlMessage(errors.oldPassword?.message)}
                  value={field.value}
                />
              )}
            />
          </Grid>
          <Grid className={classes.field} item xs={12} sm={12}>
            <Controller
              name="newPassword"
              control={control}
              render={({ field }) => (
                <PasswordField
                  variant="outlined"
                  label={<FormattedMessage {...inputLabels.newPassword} />}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  fullWidth
                  error={Boolean(errors.newPassword)}
                  value={field.value}
                  showPasswordValidationHint
                />
              )}
            />
          </Grid>
          <Grid className={classes.field} item xs={12} sm={12}>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <PasswordField
                  variant="outlined"
                  label={<FormattedMessage {...inputLabels.confirmNewPassword} />}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  fullWidth
                  error={Boolean(errors.confirmPassword)}
                  helperText={renderIntlMessage(errors.confirmPassword?.message)}
                  value={field.value}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </DialogComponent>
  );
};

export default EditMemberProfilePasswordModal;
