import React, { useCallback } from 'react';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { List as ImmutableList } from 'immutable';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';

import {
  IPaymentMethodItem,
  IPaymentMethodItemImt,
} from 'modules/pos-settings/interfaces/paymentMethods';
import { getPayTypeIcon } from 'common/components/InvoiceOperating/constants';
import TooltipTypography from 'common/components/TooltipTypography/TooltipTypography';
import { PaymentsTypeLabels } from 'modules/pos-settings/constants/paymentMethods';
import { getAccessByPropPath } from 'common/utils/errorObject';
import { IPackageInstanceSubscription } from 'common/components/PersonProfile/interfaces';
import { CustomTheme } from 'common/ui/interfaces';
import { PaymentsType } from 'common/interfaces/invoices';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import serviceMessages from 'common/messages/tableHeaders';
import IntlService from 'services/intl/IntlService';

interface IPaymentMethodFieldProps {
  subscription: IPackageInstanceSubscription;
  paymentMethods: ImmutableList<IPaymentMethodItemImt>;
  isNewCardInputDisabled: boolean;
  index: number;
  isSecondary?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  paymentMethodOptionTitle: {
    marginLeft: theme.spacing(1),
  },
  optionIcon: {
    width: '16px',
    height: '16px',
    color: theme.palette.darkBackground?.light,
  },
  autocompleteInput: {
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      padding: theme.spacing(0.375, 1),
    },
  },
  inputStartIcon: {
    alignSelf: 'center',
    color: theme.palette.darkBackground?.light,

    '& svg': {
      width: '1rem',
      height: '1rem',
    },
  },
  optionIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

// TODO refactor this to normal
const PaymentTypeLabels = PaymentsTypeLabels;

const PaymentMethodField = (props: IPaymentMethodFieldProps): JSX.Element => {
  const { subscription, index, paymentMethods, isNewCardInputDisabled, isSecondary } = props;
  const { setValue, formState, control } = useFormContext();
  const { errors } = formState;
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();

  const checkIsDisabledPaymentOption = useCallback(
    (option: PaymentsType): boolean => {
      const paymentMethodByType = paymentMethods.find(pm => pm.get('type') === option);
      if (!paymentMethodByType?.get('active')) {
        return true;
      }

      if (
        option === PaymentsType.TAB_ON_A_CORPORATE_ACCOUNT ||
        option === PaymentsType.MEMBER_REWARDS
      ) {
        return true;
      }

      return false;
    },
    [paymentMethods],
  );

  const paymentMethodType = isSecondary
    ? subscription.secondaryPaymentMethod?.type
    : subscription.paymentMethod?.type;

  const paymentAccountPath = isSecondary ? 'secondaryPaymentAccount' : 'paymentAccount';
  const paymentMethodPath = isSecondary ? 'secondaryPaymentMethod' : 'paymentMethod';
  const checkingPath = isSecondary ? 'secondaryChecking' : 'checking';

  return (
    <Controller
      name={`subscriptions.${index}.${paymentMethodPath}`}
      defaultValue={isSecondary ? subscription.secondaryPaymentMethod : subscription.paymentMethod}
      control={control}
      render={({ field }) => (
        <MuiAutocomplete
          multiple={false}
          value={field.value}
          onChange={(_, option: IPaymentMethodItem) => {
            if (paymentMethodType !== option.type) {
              setValue(`subscriptions.${index}.${paymentAccountPath}`, null);
              setValue(`subscriptions.${index}.${checkingPath}`, null);
            }
            field.onChange(option);
          }}
          onBlur={field.onBlur}
          disableClearable
          disabled={isNewCardInputDisabled}
          size="small"
          options={paymentMethods?.toJS()}
          renderOption={(option: IPaymentMethodItem) => {
            const PayTypeIcon = getPayTypeIcon(option.type);

            return (
              <Box display="flex" alignItems="center" width="100%">
                <Typography className={classes.optionIconWrapper}>
                  <PayTypeIcon className={classes.optionIcon} />
                </Typography>

                <TooltipTypography ellipsized className={classes.paymentMethodOptionTitle}>
                  {PaymentTypeLabels[option.type]}
                  &nbsp;
                  {!option.active && <FormattedMessage {...messages.inactivePaymentMethodTitle} />}
                </TooltipTypography>
              </Box>
            );
          }}
          getOptionLabel={(option: IPaymentMethodItem) =>
            `${option.title} ${
              option.active ? '' : renderIntlMessage(messages.inactivePaymentMethodTitle)
            }`
          }
          getOptionSelected={(option: IPaymentMethodItem, selectedOption: IPaymentMethodItem) =>
            selectedOption?.id === option?.id
          }
          getOptionDisabled={(option: IPaymentMethodItem) =>
            checkIsDisabledPaymentOption(option.type)
          }
          renderInput={params => {
            const StartIcon = getPayTypeIcon(field.value?.type);

            return (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: field.value ? (
                    <InputAdornment className={classes.inputStartIcon} position="start">
                      <StartIcon />
                    </InputAdornment>
                  ) : null,
                }}
                className={classes.autocompleteInput}
                variant="outlined"
                label={null}
                error={!!getAccessByPropPath(errors, `subscriptions.${index}.${paymentMethodPath}`)}
                helperText={renderIntlMessage(
                  getAccessByPropPath(errors, `subscriptions.${index}.${paymentMethodPath}`)
                    ?.message,
                )}
                placeholder={IntlService.formatMessage(
                  isSecondary ? messages.paymentMethodsOptional : serviceMessages.paymentMethod,
                )}
              />
            );
          }}
        />
      )}
    />
  );
};

export default PaymentMethodField;
