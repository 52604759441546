import baseApi from 'api/baseApi';
import { IReqGetEmployeeId } from 'common/interfaces/employee.interfaces';

const employeeApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getEmployeeIdentifier: builder.query<string, IReqGetEmployeeId>({
      query: params => ({
        url: '/api/v1/employees/identifier',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useLazyGetEmployeeIdentifierQuery: useLazyGetEmployeeIdentifier } = employeeApi;

export default employeeApi;
