export enum ActionResult {
  SUCCESS_ACTION = 'SUCCESS_ACTION',
  FAIL_ACTION = 'FAIL_ACTION',
}

export interface IActionResultData {
  result: ActionResult;
  data: unknown;
}

export const FRONT_DESK_PATH = '/front-desk';
export const HOME_PATH = '/';
export const SIGN_IN_PATH = '/sign-in';
export const PRIVACY_POLICY_PATH = '/privacy-policy';

export const MAX_FILE_SIZE = 30000000;
