import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Grid, TextField, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentAdapter from '@date-io/moment';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import {
  AdaptiveDatePicker,
  BarcodeScannerField,
  NumberTextField,
  PasswordField,
  PinCodeMaskedInput,
} from 'common/components';

import { defaultNumberFormatProps } from 'common/components/NumberController/NumberController';

import inputLabels from 'common/messages/inputLabels';
import employeesMessages from 'common/messages/employeesMessages';

interface IAccountSection {
  disableEmployeeId?: boolean;
}

const AccountSection: FC<IAccountSection> = ({ disableEmployeeId = false }) => {
  const { control, formState } = useFormContext<Record<string, any>>();
  const { errors } = formState;
  const renderIntlMessage = useRenderIntlMessage();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="button" color="textSecondary" gutterBottom>
          <FormattedMessage {...employeesMessages.account} />
        </Typography>
      </Grid>

      <MuiPickersUtilsProvider utils={MomentAdapter}>
        <Grid item xs={6} sm={3}>
          <Controller
            name="hireDate"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <AdaptiveDatePicker
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                disableFuture
                label={<FormattedMessage {...inputLabels.hireDate} />}
                error={!!errors.hireDate}
                helperText={renderIntlMessage(errors.hireDate?.message)}
                openTo="year"
              />
            )}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <Controller
            name="terminationDate"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <AdaptiveDatePicker
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                disablePast
                label={<FormattedMessage {...inputLabels.terminationDate} />}
                inputVariant="outlined"
                error={!!errors.terminationDate}
                helperText={renderIntlMessage(errors.terminationDate?.message)}
                openTo="year"
              />
            )}
          />
        </Grid>
      </MuiPickersUtilsProvider>

      <Grid item xs={6} sm={3}>
        <Controller
          name="posDiscountPercentage"
          control={control}
          render={({ field }) => (
            <NumberTextField
              value={field.value}
              onChange={field.onChange}
              variant="outlined"
              onBlur={field.onBlur}
              fullWidth
              label={<FormattedMessage {...inputLabels.posDiscountPercentage} />}
              numberFormatProps={{
                ...defaultNumberFormatProps,
                isAllowed: ({ floatValue }) => !floatValue || floatValue <= 100,
              }}
              error={!!errors.posDiscountPercentage}
              helperText={renderIntlMessage(errors.posDiscountPercentage?.message)}
            />
          )}
        />
      </Grid>

      <Grid item xs={6} sm={3}>
        <BarcodeScannerField name="barcode" />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="employeeIdentifier"
          control={control}
          render={({ field }) => (
            <TextField
              autoComplete="off"
              variant="outlined"
              label={<FormattedMessage {...inputLabels.employeeId} />}
              onBlur={field.onBlur}
              onChange={field.onChange}
              fullWidth
              error={!!errors.employeeIdentifier}
              helperText={renderIntlMessage(errors.employeeIdentifier?.message)}
              value={field.value}
              disabled={disableEmployeeId}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="userId"
          control={control}
          render={({ field }) => (
            <TextField
              variant="outlined"
              label={<FormattedMessage {...inputLabels.userId} />}
              onBlur={field.onBlur}
              onChange={field.onChange}
              fullWidth
              error={!!errors.userId}
              helperText={renderIntlMessage(errors.userId?.message)}
              value={field.value}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextField
              variant="outlined"
              label={<FormattedMessage {...inputLabels.emailLogin} />}
              onBlur={field.onBlur}
              onChange={field.onChange}
              fullWidth
              error={!!errors.email}
              helperText={renderIntlMessage(errors.email?.message)}
              value={field.value}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <PasswordField
              variant="outlined"
              label={<FormattedMessage {...inputLabels.password} />}
              onBlur={field.onBlur}
              onChange={field.onChange}
              fullWidth
              error={!!errors.password}
              value={field.value}
              showPasswordValidationHint
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="passwordHint"
          control={control}
          render={({ field }) => (
            <TextField
              variant="outlined"
              label={<FormattedMessage {...inputLabels.passwordHint} />}
              onBlur={field.onBlur}
              onChange={field.onChange}
              fullWidth
              error={!!errors.passwordHint}
              helperText={renderIntlMessage(errors.passwordHint?.message)}
              value={field.value}
            />
          )}
        />
      </Grid>

      <Grid item xs={6} sm={3}>
        <Controller
          name="pinCode"
          control={control}
          render={({ field }) => (
            <PasswordField
              variant="outlined"
              label={<FormattedMessage {...inputLabels.pinCode} />}
              onBlur={field.onBlur}
              onChange={event => field.onChange(event.target.value === '' ? null : event)}
              fullWidth
              InputProps={{ inputComponent: PinCodeMaskedInput }}
              error={!!errors.pinCode}
              helperText={renderIntlMessage(errors.pinCode?.message)}
              value={field.value}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(AccountSection);
