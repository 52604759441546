import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Box, TextField } from '@material-ui/core';

import { ReactComponent as ReplayIcon } from 'img/icons/reset.svg';
import { isBulkConstant } from 'common/constants/bulkConstants';
import { CustomTheme } from 'common/ui/interfaces';
import PhoneNumberInput from 'common/components/PhoneNumberInput/PhoneNumberInput';
import { IPhoneNumberInputWithResetProps } from 'common/components/PhoneNumberInput/interfaces';
import { FormattedMessage } from 'react-intl';
import inputLabels from 'common/messages/inputLabels';

const useStyles = makeStyles((theme: CustomTheme) => ({
  mainContainer: {
    position: 'relative',
  },
  replayContainer: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    zIndex: 1,
    right: 0,
    borderRadius: 3,
    cursor: 'pointer',
    height: 18,
    width: 20,
    textAlign: 'center',
  },
  iconReplay: {
    fill: theme.palette.background.paper,
    height: 16,
    width: 16,
    padding: theme.spacing(0.25, 0.25, 0),
  },
}));

const PhoneNumberInputWithReset = ({
  onChange,
  value,
  reset,
  multi,
  isDefaultCanResetFlag,
  ...otherProps
}: IPhoneNumberInputWithResetProps): JSX.Element => {
  const classes = useStyles();

  const [canReset, setCanReset] = useState(false);

  const refInput = useRef<HTMLInputElement | null>(null);

  const handleResetBtnClick = () => {
    setCanReset(false);
    onChange(reset || '');
  };

  const handleInputFocus = () => {
    if (multi && !canReset && reset !== undefined) {
      setCanReset(true);

      if (isBulkConstant(value)) {
        onChange('');
      }
    }
  };

  useEffect(() => {
    if (canReset) {
      refInput.current?.focus();
    }
  }, [canReset]);

  useEffect(() => {
    if (isDefaultCanResetFlag && canReset) {
      setCanReset(false);
    }
  }, [isDefaultCanResetFlag, setCanReset, canReset]);

  const hasBulkConstant = multi && isBulkConstant(value);

  return (
    <Box className={classes.mainContainer}>
      {canReset && reset !== value && (
        <Box className={classes.replayContainer} onClick={handleResetBtnClick}>
          <ReplayIcon className={classes.iconReplay} />
        </Box>
      )}
      {hasBulkConstant ? (
        <TextField
          name={otherProps.name}
          type="text"
          fullWidth
          InputProps={{ inputComponent: undefined, inputProps: undefined }}
          value={multi && isBulkConstant(value) ? 'Multiple' : value}
          label={<FormattedMessage {...inputLabels.phone} />}
          variant="outlined"
          onBlur={otherProps.onBlur}
          onFocus={handleInputFocus}
          onChange={(e: React.BaseSyntheticEvent) => onChange(e.target.value)}
        />
      ) : (
        <PhoneNumberInput
          onRef={ref => {
            refInput.current = ref;
          }}
          onFocus={handleInputFocus}
          onChange={onChange}
          value={value}
          {...otherProps}
        />
      )}
    </Box>
  );
};

export default PhoneNumberInputWithReset;
