import { CheckCircle, SvgIconComponent } from '@material-ui/icons';

import { AlertVariant } from 'common/components/Alert/types';
import { CustomColor } from 'common/ui/interfaces';
import { AlertTypes } from 'common/interfaces/alerts';

import { InfoIcon, WarningIcon } from 'img/icons';

export const getAlertColor = (severity: AlertTypes): CustomColor => {
  switch (severity) {
    case AlertTypes.Danger:
      return 'error';
    case AlertTypes.Warning:
      return 'warning';
    case AlertTypes.Success:
      return 'primary';
    default:
      return 'celebration';
  }
};

export const getAlertIcon = (severity: AlertTypes): SvgIconComponent => {
  switch (severity) {
    case AlertTypes.Info:
      return InfoIcon as any; // TODO - PRM-3575 need types
    case AlertTypes.Success:
      return CheckCircle;
    default:
      return WarningIcon as any; // TODO - PRM-3575 need types
  }
};

export const getFadeValue = (variant: AlertVariant | undefined = 'standard') => {
  switch (variant) {
    case 'outlined':
      return 0;
    case 'filled':
      return 1;
    default:
      return 0.2;
  }
};

export const getPaletteColorField = (variant?: AlertVariant) =>
  variant === 'filled' ? 'contrastText' : 'main';
