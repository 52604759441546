export enum PeakModules {
  Authentication,
  Booking,
  Clubs,
  Corporations,
  Crm,
  PersonalTrainingCrm,
  Dictionaries,
  DocumentTemplates,
  Employees,
  FrontDesk,
  Members,
  Permissions,
  PosKiosk,
  PosSettings,
  Reports,
  Services,
  KidZone,
  Timeclock,
  MemberPortal,
}

export enum CrmSubModules {
  Tasks,
  Leads,
  Scripts,
  Campaigns,
  SmsEmailTemplates,
}

export enum ServicesSubModules {
  ServicePackages,
}
