import React, { useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CancelMembershipData } from 'common/components/PersonProfile/interfaces';
import { EndReasonActionTypes } from 'modules/dictionaries/interfaces/interfaces';
import { StepContext } from 'common/createContext/stepContext';
import { validators } from 'common/constants/validators';
import NotInTermReasonStep from './NotInTermReasonStep';
import InTermReasonStep from './InTermReasonStep';
import inputErrors from 'common/messages/inputErrors';
import { getRequiredErrorMessage } from 'common/utils/validation';

const ValidationSchema = yup.object().shape({
  endReasonId: yup
    .string()
    .when('resaleOptionAccepted', {
      is: false,
      then: yup.string().required(inputErrors.reasonRequiredError),
      otherwise: yup.string().notRequired(),
    })
    .nullable(),
  newZipCode: yup.string().when('endReason.actionType', {
    is: EndReasonActionTypes.ZIP_CODE,
    then: validators.ZIP_CODE,
    otherwise: yup.string().notRequired(),
  }),
  mdLicense: yup.string().when('endReason.actionType', {
    is: EndReasonActionTypes.MD_LICENSE,
    then: yup
      .string()
      .min(2, () => inputErrors.mdLicenceMinLengthError)
      .max(150, () => inputErrors.mdLicenceMaxLengthError)
      .required(getRequiredErrorMessage),
    otherwise: yup.string().notRequired(),
  }),
  note: yup.object().when('endReason.actionType', {
    is: EndReasonActionTypes.NOTE_CODE,
    then: yup.object().shape({
      text: yup.string().required(getRequiredErrorMessage),
    }),
    otherwise: yup.object().notRequired(),
  }),
});

export const initialCancelReasonValues = {
  reason: null,
  note: { text: '', alertAtNextCheckIn: false, noteCode: null },
  mdLicense: '',
  newZipCode: '',
  resaleOptionAccepted: true,
};
const ReasonStep = (): JSX.Element => {
  const { onBack, renderFooter, onNext = () => {} } = useContext(StepContext);

  const formMethods = useForm<CancelMembershipData>({
    mode: 'onBlur',
    resolver: yupResolver(ValidationSchema) as any, // TODO - PRM-1810 need resolver type
    defaultValues: initialCancelReasonValues,
  });

  const { handleSubmit, watch } = formMethods;
  const isResaleOptionAccepted = watch('resaleOptionAccepted');

  return (
    <FormProvider {...formMethods}>
      <form>
        <InTermReasonStep />

        {!isResaleOptionAccepted && <NotInTermReasonStep />}
      </form>

      {renderFooter ? renderFooter(onBack, handleSubmit(onNext)) : null}
    </FormProvider>
  );
};

export default React.memo(ReasonStep);
