import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableBody as MuiTableBody, TableCell, TableRow } from '@material-ui/core';
import cx from 'classnames';
import { ITableRow } from 'common/interfaces/table';
import { CustomTheme } from 'common/ui/interfaces';
import { Checkbox, TooltipTypography } from 'common/components';
import EmptyTableCell from '../EmptyTableCell/EmptyTableCell';

const useStyles = makeStyles((theme: CustomTheme) => ({
  checkBoxCell: {
    verticalAlign: 'top',
    paddingTop: theme.spacing(1.5),
  },
  selectCheckbox: {
    padding: 0,
    fontSize: '18px',
  },
  smartViewCell: {
    '&:nth-child(2), &:nth-last-child(2)': {
      verticalAlign: 'top',
      paddingTop: theme.spacing(1.5),
      paddingRight: '0px',
    },
  },
  disabled: {
    position: 'relative',

    '&::after': {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      content: '""',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.lightBackground,
    },
  },
}));

interface ITableBody {
  rows: ITableRow[];
  activeSelect?: boolean;
  activeSelectBodyClassName?: string;
  smartView?: boolean;
  isAllSelected?: boolean;
  onSelectClick: (id: string) => void;
  isSelected: (key: string) => boolean;
  isExcluded: (key: string) => boolean;
  hidePadding: boolean;
}

const TableBody: FC<ITableBody> = ({
  activeSelect,
  activeSelectBodyClassName = '',
  rows,
  smartView,
  isExcluded,
  isSelected,
  onSelectClick,
  hidePadding,
  isAllSelected,
}) => {
  const classes = useStyles();

  return (
    <MuiTableBody>
      {rows.map((item: ITableRow) => {
        const {
          id,
          cells,
          cellsMini,
          disabled,
          disabledSelection,
          isCustomRow,
          rowComponent,
        } = item;

        const isItemExcluded = isExcluded(id);
        const isItemSelected = !isItemExcluded && (isSelected(id) || isAllSelected);
        const cellsToRender = smartView ? cellsMini || cells : cells;

        return isCustomRow ? (
          <TableRow
            key={id}
            hover={!smartView}
            selected={!disabledSelection && isItemSelected}
            tabIndex={-1}
            className={cx({ [classes.disabled]: disabled })}
          >
            {!hidePadding && <EmptyTableCell />}

            {activeSelect && (
              <TableCell
                className={cx(classes.checkBoxCell, {
                  [classes.smartViewCell]: smartView,
                  [activeSelectBodyClassName]: !!activeSelectBodyClassName,
                })}
                padding="none"
              >
                <Checkbox
                  className={classes.selectCheckbox}
                  onClick={() => onSelectClick(id)}
                  checked={!disabledSelection && isItemSelected}
                  inputProps={{ 'aria-labelledby': `table-checkbox-${id}` }}
                  disabled={disabledSelection || disabled}
                />
              </TableCell>
            )}
            {rowComponent}
            {!hidePadding && <EmptyTableCell />}
          </TableRow>
        ) : (
          <TableRow
            key={id}
            hover={!smartView}
            selected={!disabledSelection && isItemSelected}
            tabIndex={-1}
            className={cx({ [classes.disabled]: disabled })}
          >
            {!hidePadding && <EmptyTableCell />}

            {activeSelect && (
              <TableCell
                className={cx({
                  [classes.smartViewCell]: smartView,
                  [activeSelectBodyClassName]: !!activeSelectBodyClassName,
                })}
                padding="none"
              >
                <Checkbox
                  className={classes.selectCheckbox}
                  onClick={() => onSelectClick(id)}
                  checked={!disabledSelection && isItemSelected}
                  inputProps={{ 'aria-labelledby': `table-checkbox-${id}` }}
                  disabled={disabledSelection || disabled}
                />
              </TableCell>
            )}

            {cellsToRender?.map((bodyCell, cellIndex) => {
              const {
                label,
                padding,
                customPadding,
                align,
                variant,
                cellComponent,
                ellipsis,
                maxWidth,
                width,
                verticalAlign,
                className = '',
              } = bodyCell;

              return (
                <TableCell
                  key={`${id}-${cellIndex + 1}`}
                  padding={padding}
                  align={align || 'left'}
                  className={cx({ [className]: !!className, [classes.smartViewCell]: smartView })}
                  style={{
                    maxWidth: maxWidth || 'none',
                    width,
                    padding: customPadding,
                    verticalAlign: verticalAlign || '',
                  }}
                >
                  {cellComponent || (
                    <TooltipTypography ellipsized={ellipsis} variant={variant || 'body1'}>
                      {label}
                    </TooltipTypography>
                  )}
                </TableCell>
              );
            })}

            {!hidePadding && <EmptyTableCell />}
          </TableRow>
        );
      })}
    </MuiTableBody>
  );
};

TableBody.defaultProps = {
  smartView: false,
};

export default TableBody;
