/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { PureComponent } from 'react';
import { List } from 'immutable';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  createStyles,
  StyledComponentProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { LoadingBackdrop, ScrollBox } from 'common/components';
import TableFooter from 'common/components/Table/TableFooter/TableFooter';
import { ITableParams, Order, IFooterOptions } from 'common/interfaces/table';
import messages from 'modules/front-desk/messages';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
      paddingBottom: '0 !important',
      position: 'relative',
    },
    relative: {
      position: 'relative',
    },
    pagination: {
      minHeight: '0',
      height: 'auto',
      padding: 0,
      margin: 0,
      overflow: 'visible',
      '& button': {
        padding: 0,
        borderRadius: '3px',
      },
      '& .MuiToolbar-root': {
        minHeight: 'auto',
      },
    },
    tableHeader: {
      borderBottom: `1px solid ${theme.palette.secondary.light}`,

      '& .MuiTableCell-head': {
        padding: '6px',
      },
    },
    rowHeader: {
      cursor: 'pointer',
      verticalAlign: 'center',
    },
    rowHeaderText: {
      textTransform: 'uppercase',
      opacity: 0.4,
      fontWeight: 400,
      fontSize: '0.6875rem',
    },
    sortIcon: {
      width: '12px',
      height: 'auto',
      color: theme.palette.primary.main,
      marginLeft: '4px',
    },
    tableCell: {
      padding: theme.spacing(1.5, 0.75),
      border: 'none',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:lats-child': {
        paddingRight: 0,
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(1.5, 1),
      },
      [theme.breakpoints.up('desktop')]: {
        padding: theme.spacing(1.75, 1),
      },
    },
    tableBody: {
      '& > tr': {
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
      },

      '& > tr:last-child': {
        borderBottom: 'none',
      },
    },
    tableFooter: {
      boxShadow: `0px -1px 12px rgba(0, 0, 0, 0.16)`,
      borderRadius: theme.spacing(0.5),
      '& .MuiTableCell-footer > .MuiTypography-root': {
        fontSize: '0.75rem',
        fontWeight: 'bold',
      },
    },
    noDataRoot: {
      maxWidth: '100%',
      paddingBottom: '0 !important',
    },
    noDataSection: {
      width: '100%',
      height: '100%',
    },
    noDataTable: {
      minHeight: '150px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    emptyText: {
      textAlign: 'center',
      fontSize: '0.9rem',
      color: '#c1c1c1',
      opacity: 0.5,
      lineHeight: '19px',
    },
  });

export interface ITableHeaderColumn<T> {
  name: keyof T;
  label: string | JSX.Element;
  align?: 'right' | 'left' | 'center';
  contentAlign?: 'right' | 'left';
  minWidth?: number;
  sort?: boolean;
}

export interface ITableSectionColumn {
  name: string;
  label: string | JSX.Element;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  contentAlign?: 'right' | 'left';

  transform?: (data: any) => any;
  className?: (data: any) => string;
}

interface ITableSectionProps<T, K> extends StyledComponentProps, ITableParams<T> {
  id: string;
  title: string | JSX.Element;
  className?: string;

  headerColumns: ITableHeaderColumn<T>[];
  columns: ITableSectionColumn[];

  data: List<K>;
  dataLoading: boolean;
  rowClassName?: (item: K) => string;

  order: Order;
  orderBy?: keyof T;
  page: number;
  perPage: number;
  total: number;
  hidePagination?: boolean;
  footerOptions?: IFooterOptions;

  onSettingsChanged: (page: number, orderBy: keyof T, order: Order) => void;
}

@(withStyles(styles) as any)
export default class TableSection<T, K> extends PureComponent<ITableSectionProps<T, K>> {
  private handlePageChange = (event: unknown, newPage: number): void => {
    const { onSettingsChanged, order, orderBy } = this.props;

    if (orderBy) {
      onSettingsChanged(newPage, orderBy, order);
    }
  };

  private handleSortChange = (value: keyof T): void => {
    const { onSettingsChanged, page, order, orderBy } = this.props;

    let direction = order || 'asc';
    if (orderBy === value) {
      direction = direction === 'asc' ? 'desc' : 'asc';
    }

    onSettingsChanged(page, value, direction);
  };

  private renderSortingIcon = (columnName: keyof T): JSX.Element | null => {
    const { classes, orderBy, order } = this.props;
    if (orderBy !== columnName) return null;

    const Icon = order === 'asc' ? ArrowDownward : ArrowUpward;
    return <Icon className={classes?.sortIcon} />;
  };

  private renderTableHeader = (): JSX.Element => {
    const { classes, headerColumns } = this.props;

    return (
      <TableHead className={classes?.tableHeader}>
        <TableRow>
          {headerColumns.map(column => (
            <TableCell
              key={column.name as string}
              align={column.align}
              style={{ minWidth: column.minWidth }}
              className={`${classes?.rowHeader} ${classes?.tableCell}`}
              onClick={column.sort ? () => this.handleSortChange(column.name) : () => {}}
            >
              <Box
                display="inline-flex"
                alignItems="center"
                justifyContent={column.contentAlign === 'right' ? 'flex-end' : 'flex-start'}
              >
                <Typography variant="body2" className={classes?.rowHeaderText}>
                  {column.label}
                </Typography>
                {column.sort ? this.renderSortingIcon(column.name) : ''}
              </Box>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  private renderTableBody = (): JSX.Element => {
    const { classes, data, columns, rowClassName } = this.props;

    return (
      <TableBody className={classes?.tableBody}>
        {data.map((item: any) => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={`${item.get('id')}-${item.get('date')}`}
              className={`${rowClassName ? rowClassName(item) : ''}`}
            >
              {columns.map(column => {
                return (
                  <TableCell
                    key={String(column.name)}
                    align={column.align}
                    className={`${classes?.tableCell} ${
                      column.className ? column.className(item.get(column.name)) : ''
                    }`}
                  >
                    {column.transform
                      ? column.transform(item.get(column.name))
                      : item.get(column.name)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  private renderTableFooter = (): JSX.Element | null => {
    const { classes, footerOptions } = this.props;

    if (footerOptions) {
      return (
        <TableFooter className={classes?.tableFooter} footerOptions={footerOptions} hidePadding />
      );
    }

    return null;
  };

  private renderNoData = (): JSX.Element => {
    const { classes, title, dataLoading } = this.props;

    return (
      <Box
        className={`${classes?.noDataRoot} box_bordered ${dataLoading ? classes?.relative : ''}`}
      >
        <Box className={classes?.noDataSection}>
          <Box className="table__header">
            <Typography className="table__title">{title}</Typography>
          </Box>
          <Box className={classes?.noDataTable}>
            <Typography className={classes?.emptyText}>
              <FormattedMessage {...messages.emptyTableTextDescription} />
            </Typography>
          </Box>
        </Box>
        <LoadingBackdrop isLoading={dataLoading} />
      </Box>
    );
  };

  render(): JSX.Element {
    const {
      className,
      classes,
      data,
      dataLoading,
      title,
      page = 0,
      perPage = 5,
      total = 0,
      hidePagination,
    } = this.props;

    if (!data || !data.size || total === 0) {
      return this.renderNoData();
    }

    return (
      <Box
        className={`${classes?.root} box_bordered ${className} ${
          dataLoading ? classes?.relative : ''
        }`}
      >
        <LoadingBackdrop isLoading={dataLoading} />
        <Box className="table__header">
          <Typography className="table__title">{title}</Typography>
          {!hidePagination && (
            <>
              {/* // TODO MATERIAL change props after MUI V5 upgrade */}
              {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore */}
              <TablePagination
                page={page}
                rowsPerPage={perPage}
                rowsPerPageOptions={[perPage]}
                count={total}
                className={classes?.pagination}
                onChangePage={this.handlePageChange}
                component="div"
              />
            </>
          )}
        </Box>

        <Box style={{ width: '100%', minHeight: 0, flex: 1 }}>
          <ScrollBox>
            <Table aria-label="sticky table" style={{ minWidth: '250px' }}>
              {this.renderTableHeader()}
              {this.renderTableBody()}
              {this.renderTableFooter()}
            </Table>
          </ScrollBox>
        </Box>
      </Box>
    );
  }
}
