import React, { Dispatch, FC, SetStateAction } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { List as ImmutableList } from 'immutable';
import { ICorporationItemImt } from 'modules/authentication/interfaces';
import * as selectors from 'modules/authentication/state/selectors';
import { Login } from 'modules/authentication/components';

interface ISignIn {
  setLoginView: Dispatch<SetStateAction<'client' | 'member' | null>>;
}

const SignIn: FC<ISignIn> = ({ setLoginView }) => {
  const signInError = useRootSelector(selectors.selectSignInError);
  const isLoading: boolean = useRootSelector(selectors.selectSignInLoading);
  const corporationsForSignIn: ImmutableList<ICorporationItemImt> = useRootSelector(
    selectors.selectCorporationsForSignIn,
  );
  const isCorporationsForSignInLoading: boolean = useRootSelector(
    selectors.selectCorporationsForSignInLoading,
  );

  return (
    <Login
      signInError={signInError}
      isLoading={isLoading || isCorporationsForSignInLoading}
      corporations={corporationsForSignIn}
      setLoginView={setLoginView}
    />
  );
};

export default SignIn;
