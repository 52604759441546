class SignContractService {
  private packageInstanceIds: number[] = [];

  public getPackageInstanceIds(): number[] {
    return this.packageInstanceIds;
  }

  public setPackageInstanceIds(packageInstanceIds: number[]): void {
    this.packageInstanceIds = packageInstanceIds;
  }

  public clearPackageInstanceIds(): void {
    this.packageInstanceIds = [];
  }
}

export default new SignContractService();
