import { createAction } from '@reduxjs/toolkit';

import { IPaginatedTasks } from 'services/network/TasksService';

import Services from 'services/network';
import { actionTypes } from 'modules/crm/constants/tasks';

import { IMultipleSelectData, ITableParams } from 'common/interfaces/table';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { PeakModules } from 'common/constants/peakModules';
import { GeneralThunkAction } from 'common/state/interfaces';
import { IObject } from 'common/interfaces/common';

const fetchTasksListLoadingAction = createAction<boolean>(actionTypes.FETCH_TASKS_LIST_LOADING);
const fetchTasksListSuccessAction = createAction<IPaginatedTasks | null>(
  actionTypes.FETCH_TASKS_LIST_SUCCESS,
);

export const fetchTasks = (
  pageOptions?: ITableParams,
  module?: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchTasksListLoadingAction(true));
    try {
      let paginatedData: IPaginatedTasks | null;

      switch (module) {
        case PeakModules.Crm:
          paginatedData = await Services.TasksService.getTasks(pageOptions);
          break;
        case PeakModules.PersonalTrainingCrm:
          paginatedData = await Services.PtTasksService.getTasks(pageOptions);
          break;
        default:
          paginatedData = null;
      }
      dispatch(fetchTasksListSuccessAction(paginatedData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchTasksListLoadingAction(false));
    }
  };
};

const taskCompletingAction = createAction<boolean>(actionTypes.TASK_COMPLETING);
const taskCompletedAction = createAction<IObject>(actionTypes.TASK_COMPLETED);

export const completeTasks = (
  data: IMultipleSelectData,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(taskCompletingAction(true));
    try {
      switch (module) {
        case PeakModules.Crm:
          await Services.TasksService.completeTasks(data);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PtTasksService.completeTasks(data);
          break;
        // TODO: PRM-3724 - maybe remove
        case PeakModules.PosKiosk:
          await Services.PtTasksService.completeTasks(data);
          break;
        default:
      }
      dispatch(taskCompletedAction({ success: true }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(taskCompletingAction(false));
    }
  };
};

export type ReassignData = {
  salespersonId: string;
  excludedIds?: string[];
  includedIds?: string[];
  allSelected?: boolean;
};

const assigningSalespersonTasksAction = createAction<boolean>(
  actionTypes.SALESPERSON_TASKS_ASSIGNING,
);
const assignSalespersonTasksAction = createAction<IObject>(actionTypes.SALESPERSON_TASKS_ASSIGNED);

export const assignSalespersonTasks = (
  options: ReassignData,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(assigningSalespersonTasksAction(true));
    try {
      switch (module) {
        case PeakModules.Crm:
          await Services.TasksService.assignSalespersonTasks(options);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PtTasksService.assignSalespersonTasks(options);
          break;
        default:
      }
      dispatch(assignSalespersonTasksAction({ success: true }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(assigningSalespersonTasksAction(false));
    }
  };
};

export const resetTasksActionResult = createAction(actionTypes.RESET_TASKS_ACTION_RESULT);
