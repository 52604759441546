import React from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Avatar, makeStyles } from '@material-ui/core';

import { useAppDispatch } from 'store/hooks';
import { ReactComponent as UserIcon } from 'img/icons/user.svg';
import { ReactComponent as ExitIcon } from 'img/icons/exit.svg';
import { ReactComponent as KeyIcon } from 'img/icons/key_deprecated.svg';

import { logOut } from 'modules/authentication/state/actions';
import { memberPortalLogOut } from 'memberPortalModules/authentication/state/actions';
import { selectUserAvatar } from 'modules/authentication/state/selectors';
import { selectUserAvatar as selectMemberPortalUserAvatar } from 'memberPortalModules/authentication/state/selectors';
import menuItems from 'common/messages/menuItems';
import messages from 'common/messages/messages';
import { HeaderId } from 'common/enums/html.enums';
import { ActionItem, ActionsMenu } from 'common/components';
import { selectUserRole } from 'common/state/userRole/selectors';

const useStyles = makeStyles({
  menuIcon: {
    height: 14,
    width: 14,
  },
  userAvatar: {
    width: 36,
    height: 36,
    cursor: 'pointer',
  },
});

interface IProps {
  onChangePasswordClick: () => void;
}

const UserMenu = ({ onChangePasswordClick }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const clientPortalUserAvatar = useRootSelector(selectUserAvatar);
  const memberPortalUserAvatar = useRootSelector(selectMemberPortalUserAvatar);

  const userRole = useRootSelector(selectUserRole);

  const navigate = useNavigate();

  const classes = useStyles();

  const isClientPortal = userRole === 'client';
  const userAvatar = isClientPortal ? clientPortalUserAvatar : memberPortalUserAvatar;

  const handleLogOut = () => {
    if (isClientPortal) {
      dispatch(logOut());
    } else {
      dispatch(memberPortalLogOut());
    }
  };

  const showMyProfile = () => {
    navigate('/my-profile');
  };

  return (
    <ActionsMenu
      moreActionsBtn={
        <Avatar
          className={classes.userAvatar}
          alt={userAvatar?.get('preferredName')}
          src={userAvatar?.get('avatar')}
        />
      }
    >
      {isClientPortal && (
        <ActionItem
          id={HeaderId.HeaderActionBtnProfile}
          icon={<UserIcon className={classes.menuIcon} />}
          onClick={showMyProfile}
        >
          <FormattedMessage {...messages.myProfileHeader} />
        </ActionItem>
      )}

      {!isClientPortal && (
        <ActionItem
          key="2"
          icon={<KeyIcon width={16} height={16} />}
          onClick={onChangePasswordClick}
        >
          <FormattedMessage {...messages.changePasswordHeader} />
        </ActionItem>
      )}

      <ActionItem
        id={HeaderId.HeaderActionBtnSignOut}
        icon={<ExitIcon className={classes.menuIcon} />}
        onClick={handleLogOut}
      >
        <FormattedMessage {...menuItems.signOut} />
      </ActionItem>
    </ActionsMenu>
  );
};

export default UserMenu;
