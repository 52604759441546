import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useRootSelector from 'common/hooks/useRootSelector';
import isArray from 'lodash/isArray';
import clone from 'lodash/clone';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as contractSelectors from 'common/state/newPerson/contract/selectors';
import {
  selectIsContractHTMLLoading,
  selectSignContractActionResult,
} from 'common/state/newPerson/contract/selectors';
import * as actions from 'common/state/newPerson/contract/actions';
import {
  printContract,
  setSignContractActionResult,
} from 'common/state/newPerson/contract/actions';
import { selectMemberId } from 'common/state/newPerson/primaryInfo/selectors';
import { selectContactInfo } from 'common/state/newPerson/contactInfo/selectors';
import { getContactInfoThunk } from 'common/state/newPerson/contactInfo/actions';
import { selectCreatedLead } from 'modules/crm/state/leads/selectors';
import {
  selectMainPanelPersonId,
  selectRightPanelPersonId,
} from 'modules/front-desk/state/selectors';
import {
  fetchSenderAvailabilityThunk,
  resetBookingEvents,
} from 'modules/booking/state/senderAvailability/actions';
import {
  IPersonDocumentItemDto,
  IPersonDocumentToSignDto,
  IReqContract,
} from 'common/interfaces/contract';
import { IAttachment } from 'common/interfaces/uploadFile';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { ILeadProfileImt } from 'modules/crm/interfaces/leads';
import { SignType } from 'common/constants/contract';
import { PeakModules } from 'common/constants/peakModules';
import { ActionResult } from 'common/constants';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { ENotificationType } from 'modules/booking/constants/notificationType';
import commonFileErrorMessages from 'common/messages/fileErrors';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { useAppDispatch } from 'store/hooks';
import { closeSigWebTablet, getFieldName } from 'helpers';
import { StepContext } from 'common/createContext/stepContext';
import { selectWaiversIsLoading } from 'common/state/newPerson/waiver/selectors';
import { useCombinePrintLoading } from 'common/hooks/useCombinePrintLoading';
import isFormDirty from 'common/hooks/isFormDirty';
import useDiscardChangesContext from 'common/hooks/useDiscardChangesContext';
import useSignatureContext from 'common/hooks/context/useSignatureContext';
import DiscardChangesModalProvider from 'common/modals/DiscardChangesModal/DiscardChangesModalProvider';
import SignDocumentsStep from 'common/components/Steps/SignDocumentStep/SignDocumentsStep';
import SignContractService from 'services/application/SignContractService';

const useStyles = makeStyles(() => ({
  signContractStepForm: {
    height: 'calc(100% - 180px)',
  },
}));

interface ISignContractsStepContainer {
  module: PeakModuleForNewPersonType;
}

interface IFormSignContract {
  signType: SignType | null;
  files: IAttachment[];
}

interface ISignContractsStepFormValues {
  phone?: string;
  email?: string;
}

const SignContractsStepContainer: FC<ISignContractsStepContainer> = ({ module }) => {
  const dispatch = useAppDispatch();
  const [signedDocumentData, setSignedDocumentData] = useState<Record<string, boolean>>({});
  const { documentSignatures } = useSignatureContext();

  const rightPanelPersonId = useRootSelector(selectRightPanelPersonId);
  const mainPanelPersonId = useRootSelector(selectMainPanelPersonId);
  const contracts = useRootSelector(contractSelectors.selectContracts);
  const contactInfo = useRootSelector(selectContactInfo);
  const isLoadingContract = useRootSelector(contractSelectors.selectContractIsLoading);
  const memberProfileId = useRootSelector(selectMemberId);
  const signContractActionResult = useRootSelector(selectSignContractActionResult);
  const isLoadingWaiver = useRootSelector(selectWaiversIsLoading);
  const isContractHTMLLoading = useRootSelector(selectIsContractHTMLLoading);
  const leadPrimaryInfo: ILeadProfileImt = useRootSelector(selectCreatedLead);

  const isCrmOrPTCrmModule =
    module === PeakModules.Crm || module === PeakModules.PersonalTrainingCrm;

  const personId = isCrmOrPTCrmModule
    ? leadPrimaryInfo.get('id')
    : rightPanelPersonId || memberProfileId || mainPanelPersonId;

  const renderIntlMessage = useRenderIntlMessage();
  const { renderFooter, onBack, onNext } = useContext(StepContext);
  const dcContext = useDiscardChangesContext();
  const classes = useStyles();

  const formMethods = useForm<Record<string, any>>({
    defaultValues: {
      email: null,
      phone: null,
    },
    shouldUnregister: false,
    mode: 'onBlur',
  });

  const { handleSubmit, setValue, formState } = formMethods;
  const isDirty = isFormDirty(formState);

  useEffect(() => {
    const { email, phones = [] } = contactInfo;

    if (email) {
      setValue('email', email);
    }

    if (phones[0]) {
      setValue('phone', phones[0].phoneId);
    }
  }, [setValue, contactInfo]);

  useEffect(() => {
    const packageInstanceIds = SignContractService.getPackageInstanceIds();

    const onSuccess = (newContracts: IPersonDocumentItemDto[]): void => {
      for (const newContract of newContracts) {
        setValue(
          `signType:${newContract.documentId}` as any,
          newContract.signType || SignType.ViaTopaz,
        );
      }
    };

    dispatch(actions.getPersonContractThunk(personId, module, packageInstanceIds, onSuccess));
    dispatch(getContactInfoThunk(personId, module));
  }, [dispatch, personId, module, setValue]);

  useEffect(() => {
    if (signContractActionResult === ActionResult.SUCCESS_ACTION) {
      if (onNext) {
        onNext();
      }

      dispatch(setSignContractActionResult(null));
    }
  }, [onNext, signContractActionResult, dispatch]);

  useEffect(() => {
    return () => {
      void closeSigWebTablet({ shouldCallReject: false });
    };
  }, []);

  const getUuid = (key: string): string => {
    const keyParts = key.split(':');
    return keyParts.length === 2 ? keyParts[1] : '';
  };

  const onSubmitForm = (values: ISignContractsStepFormValues) => {
    const { setError } = formMethods;
    const { email, phone } = values;
    const reqContracts: IReqContract[] = [];
    const formContracts: Record<string, IFormSignContract> = {};

    for (const key of Object.keys(values)) {
      if (key.startsWith('files') && isArray(values[key]) && values[key].length) {
        const uuid = getUuid(key);

        if (uuid) {
          if (!formContracts[uuid]) {
            formContracts[uuid] = {
              signType: null,
              files: [],
            };
          }

          formContracts[uuid].files.push(...values[key]);
        }
      } else if (key.startsWith('signType')) {
        const uuid = getUuid(key);

        if (uuid) {
          if (!formContracts[uuid]) {
            formContracts[uuid] = {
              signType: null,
              files: [],
            };
          }

          formContracts[uuid].signType = values[key];
        }
      }
    }

    for (const entry of Object.entries(formContracts)) {
      const [documentId, formContract] = entry;
      const { files, signType } = formContract;

      if (signType) {
        const defaultSignContract: IPersonDocumentToSignDto = {
          attachments: [],
          documentId,
          signType,
          signed: true,
        };

        if (signType === SignType.SendViaEmail) {
          if (email) {
            reqContracts.push({
              contract: {
                ...defaultSignContract,
                email,
              },
            });
          }
        } else if (signType === SignType.SendViaSMS) {
          if (phone) {
            reqContracts.push({
              contract: {
                ...defaultSignContract,
                phoneId: phone,
              },
            });
          }
        } else if (signType === SignType.InPerson) {
          if (files.length) {
            reqContracts.push({
              contract: {
                ...defaultSignContract,
                attachments: files,
              },
            });
          } else {
            const fieldName = getFieldName('files', documentId);

            setError(fieldName as any, {
              message: renderIntlMessage(commonFileErrorMessages.fileCountError),
            });
          }
        } else if (signType === SignType.ViaTopaz) {
          const contract = contracts.find(item => item.documentId === documentId);

          if (contract?.signature?.id) {
            reqContracts.push({
              contract: {
                ...defaultSignContract,
                signature: clone(contract.signature),
              },
            });
          }
        } else if (signType === SignType.ESignature) {
          const docSignature = documentSignatures.find(item => item.documentId === documentId);

          reqContracts.push({
            contract: { ...defaultSignContract },
            documentSignature: docSignature || null,
          });
        }
      }
    }

    if (reqContracts.length) {
      dispatch(actions.signContractThunk(personId, reqContracts, module, onNext));
    } else {
      if (onNext) {
        onNext();
      }
    }
  };

  const applyContractSignature = (file: File, documentId: string): void => {
    dispatch(actions.applyContractSignatureThunk(personId, documentId, file, module));
  };

  const getEventContract = useCallback((): ENotificationType => {
    switch (module) {
      case PeakModules.Crm:
        return ENotificationType.CRM_LEAD_CONTRACT_SENDING;
      case PeakModules.PersonalTrainingCrm:
        return ENotificationType.PT_CRM_LEAD_CONTRACT_SENDING;
      case PeakModules.FrontDesk:
        return ENotificationType.FRONTDESK_CONTRACT_SENDING;
      default:
        return ENotificationType.MEMBER_CONTRACT_SENDING;
    }
  }, [module]);

  useEffect(() => {
    dispatch(
      fetchSenderAvailabilityThunk([SenderAvailabilityTypeList.DOCUMENTS], {
        module,
        events: [getEventContract()],
      }),
    );
    return () => {
      dispatch(resetBookingEvents({ type: SenderAvailabilityTypeList.DOCUMENTS }));
    };
  }, [dispatch, getEventContract, module]);

  const isContractHTMLPrinting = useCombinePrintLoading(isContractHTMLLoading);

  const onPrintContract = (contractId: string): void => {
    dispatch(printContract(personId, contractId, module));
  };

  const isLoading = isLoadingWaiver || isLoadingContract || isContractHTMLPrinting;

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          className={classes.signContractStepForm}
          id="contract-form"
          onSubmit={handleSubmit(onSubmitForm)}
          autoComplete="none"
        >
          <SignDocumentsStep
            contracts={contracts.toJS()}
            fetchApplySignature={applyContractSignature}
            contactInfo={contactInfo}
            signedDocumentData={signedDocumentData}
            setSignedDocumentData={setSignedDocumentData}
            onPrint={onPrintContract}
          />

          {!!renderFooter && renderFooter(onBack, handleSubmit(onSubmitForm), isLoading)}
        </form>
      </FormProvider>

      {dcContext && isDirty ? <DiscardChangesModalProvider {...dcContext} /> : null}
    </>
  );
};

export default SignContractsStepContainer;
