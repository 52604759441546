import {ServerError} from "common/errors/serverErrors";
import {ISetSigConfigParams, ISigWebMethods, IStartTabletParams, ParamsType} from "common/interfaces/sigWeb";

const expandedWindow: any = window;

const {
  IsSigWebInstalled,
  GetSigWebVersion,
  SetJustifyMode,
  SetImagePenWidth,
  Reset,
  SetTabletState,
  ClearTablet,
  NumberOfTabletPoints,
  SetSigCompressionMode,
  GetSigString, GetSigImageB64,
  TabletModelNumber,
  KeyPadClearHotSpotList,
  ClearSigWindow,
  SetDisplayXSize,
  SetLCDCaptureMode,
  SetDisplayYSize,
  LcdRefresh,
  SigWebEvent,
  KeyPadQueryHotSpot,
  SetImageXSize,
  SetImageYSize,
  LCDGetLCDSize,
  LCDSendGraphicUrl,
  LCDWriteString,
  KeyPadAddHotSpot,
  LCDStringWidth,
  LCDStringHeight,
  LCDSetWindow,
  SetSigWindow,
  AutoKeyAddANSIData,
  SetEncryptionMode,
  GetTabletState,
} = expandedWindow;

const sigWebStartMethods = ((): ISigWebMethods => {
  let tmr;
  let eventTmr;

  let resetIsSupported = false;

//* ****add these variables
  let lcdSize;
  let lcdX;
  let lcdY;
  let scrn;
  let sigString = '';

  const signatureConfig: any = {
    imageXSize: 500,
    imageYSize: 100,
    displayXSize: 500,
    displayYSize: 100,
    canvasRef: null,
  }

  const promisification = (callback) => (params: ParamsType = {}) => {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        const {shouldCallReject = true} = params;

        try {
          callback(params)
          resolve();
        } catch (e) {
          if (shouldCallReject) {
            reject(e)
          }
        }
      }, 400)
    })
  }

  const parse = (textData: string) => {
    const words = textData.split(' ');
    let writeData = '';
    let tempData = '';
    let xSize = 0;
    let ySize = 0;
    let i = 0;
    let yPos = 0;

    for (i = 0; i < words.length; i += 1) {
      tempData += words[i];

      xSize = LCDStringWidth('9pt Arial', tempData);

      if (xSize < lcdX) {
        writeData = tempData;
        tempData += ' ';

        xSize = LCDStringWidth('9pt Arial', tempData);

        if (xSize < lcdX) {
          writeData = tempData;
        }
      } else {
        ySize = LCDStringHeight('9pt Arial', tempData);

        LCDWriteString(0, 2, 0, yPos, '9pt Arial', 15, writeData);

        tempData = '';
        writeData = '';
        yPos += ySize;
        i -= 1;
      }
    }

    if (writeData !== '') {
      LCDWriteString(0, 2, 0, yPos, '9pt Arial', 15, writeData);
    }
  }

  const processPenUp = (setSigCaptured: (base64: string) => void, setSigString?: (asciiData: string) => void) => {
    const {imageXSize, imageYSize} = signatureConfig;

    if (KeyPadQueryHotSpot(0) > 0) {
      ClearSigWindow(1);
      LcdRefresh(1, 16, 45, 50, 15);

      if (scrn === 1) {
        ClearTablet();
        LcdRefresh(0, 0, 0, 240, 64);

        const data2 = `We'll bind the signature to all the displayed text. Please press Continue.`;

        parse(data2);

        LCDWriteString(0, 2, 15, 45, '9pt Arial', 15, 'Continue');
        LCDWriteString(0, 2, 200, 45, '9pt Arial', 15, 'Back');

        KeyPadAddHotSpot(1, 1, 195, 40, 20, 15);  // Back

        scrn = 2;
      } else if (scrn === 2) {
        LcdRefresh(2, 0, 0, 240, 64);
        ClearTablet();
        KeyPadClearHotSpotList();
        KeyPadAddHotSpot(2, 1, 10, 5, 53, 17);   // CLEAR
        KeyPadAddHotSpot(3, 1, 197, 5, 19, 17);  // OK
        LCDSetWindow(2, 22, 236, 40);
        SetSigWindow(1, 0, 22, 240, 40);
      }

      SetLCDCaptureMode(2);
    }

    if (KeyPadQueryHotSpot(1) > 0) {
      ClearSigWindow(1);
      LcdRefresh(1, 200, 45, 25, 15);

      if (scrn === 2) {
        KeyPadClearHotSpotList();
        LcdRefresh(1, 200, 45, 25, 15);
        ClearTablet();
        LcdRefresh(0, 0, 0, 240, 64);

        const data = 'These are sample terms and conditions. Please press Continue.';

        parse(data);

        LCDWriteString(0, 2, 15, 45, '9pt Arial', 15, 'Continue');

        KeyPadAddHotSpot(0, 1, 12, 40, 40, 15);   // Continue

        scrn = 1;
      }

      SetLCDCaptureMode(2);
    }

    if (KeyPadQueryHotSpot(2) > 0) {
      ClearSigWindow(1);
      LcdRefresh(1, 10, 0, 53, 17);

      LcdRefresh(2, 0, 0, 240, 64);
      ClearTablet();
    }

    if (KeyPadQueryHotSpot(3) > 0) {
      ClearSigWindow(1);
      LcdRefresh(1, 210, 3, 14, 14);

      if (NumberOfTabletPoints() > 0) {
        LcdRefresh(0, 0, 0, 240, 64);
        LCDWriteString(0, 2, 35, 25, '9pt Arial', 15, 'Signature capture complete.');

        // NOW, EXTRACT THE SIGNATURE IN THE TOPAZ BIOMETRIC FORMAT -- SIGSTRING
        // OR AS A BASE64-ENCODED PNG IMAGE
        // OR BOTH

        //* *******************USE THIS SECTION IF YOU WISH TO APPLY AUTOKEY TO YOUR TOPAZ SIGNATURE
        // READ ABOUT AUTOKEY AND THE TOPAZ SIGNATURE FORMAT HERE: http://topazsystems.com/links/robustsignatures.pdf
        // AUTOKEY IS CRITICAL TO SAVING AN eSIGN-COMPLIANT SIGNATURE
        // AUTOKEY ONLY APPLIES TO THE TOPAZ-FORMAT SIGSTRING AND DOES NOT APPLY TO AN IMAGE OF THE SIGNATURE
        // AUTOKEY ALLOWS THE DEVELOPER TO CRYPTOGRAPHICALLY BIND THE TOPAZ SIGNATURE TO A SET OF DATA
        // THE PURPOSE OF THIS IS TO SHOW THAT THE SIGNATURE IS BEING APPLIED TO THE DATA YOU PASS IN USING AutoKeyAddData()
        // IN GENERAL TOPAZ RECOMMENDS REPLICATING A TRADITIONAL 'PAPER AND PEN' APPROACH
        // IN OTHER WORDS, IF YOU WERE TO PRINT OUT ON PAPER THE TERMS/INFORMATION THE SIGNER IS SUPPOSED TO READ AND AGREE WITH
        // THE DATA ON THIS PAPER IS WHAT SHOULD IN WHOLE BE PASSED INTO AUTOKEYADDANSIDATA() DIGITALLY
        // THE TOPAZ SIGSTRING IS THEN BOUND TO THIS DATA, AND CAN ONLY BE SUCCESSFULLY DECRYPTED LATER USING THIS DATA
        // AUTOKEYADDDATA IS DEPRECATED AND REPLACED BY AUTOKEYADDANSIDATA
        let CryptoData = '';
        CryptoData = 'This represents sample data the signer reads and is agreeing to when signing.';
        CryptoData += 'Concatenate all this data into a single variable.';
        AutoKeyAddANSIData(CryptoData); // PASS THE DATA IN TO BE USED FOR AUTOKEY
        SetEncryptionMode(2);
        //* ******END AUTOKEY SECTION

        // NOTE THAT THE AUTOKEY SECTION ABOVE IS NOT REQUIRED TO RETURN A TOPAZ SIGSTRING
        // BUT IT IS STRONGLY RECOMMENDED IF YOU REQUIRE eSIGN COMPLIANCE
        // RETURN THE TOPAZ-FORMAT SIGSTRING
        SetSigCompressionMode(1);
        // alert("KEYSTRING:" + GetKeyString());

        if (setSigString) {
          setSigString(sigString += GetSigString())
        }

        clearInterval(eventTmr);
        // setTimeout(endDemo, 2000);
        // TO RETURN A BASE64-ENCODED PNG IMAGE OF THE SIGNATURE
        SetImageXSize(imageXSize);
        SetImageYSize(imageYSize);
        SetImagePenWidth(5);
        GetSigImageB64(setSigCaptured); // PASS IN THE FUNCTION NAME SIGWEB WILL USE TO RETURN THE FINAL IMAGE
      } else {
        LcdRefresh(0, 0, 0, 240, 64);
        LCDSendGraphicUrl(0, 2, 4, 20, 'http://www.sigplusweb.com/SigWeb/please.bmp');
        // LCDWriteString(0, 2, 4, 20, "9pt Arial", 15, "Please Complete Signature");
        ClearTablet();
        LcdRefresh(2, 0, 0, 240, 64);
        SetLCDCaptureMode(2);
      }
    }

    ClearSigWindow(1);
  }

  const isOlderVersion = (oldVer: string, newVer: string) => {
    const oldParts = oldVer.split('.')
    const newParts = newVer.split('.')
    for (let i = 0; i < newParts.length; i += 1) {
      const a = parseInt(newParts[i], 10) || 0
      const b = parseInt(oldParts[i], 10) || 0
      if (a < b) return true
      if (a > b) return false
    }
    return false;
  }

  const isOlderSigWebVersionInstalled = (cmprVer: string) => {
    const sigWebVer = GetSigWebVersion();

    if (sigWebVer !== '') {
      return isOlderVersion(cmprVer, sigWebVer);
    }

    return false;
  }

  const GetResetSupported = () => {
    const minSigWebVersionResetSupport = '1.6.4.0';

    return !isOlderSigWebVersionInstalled(minSigWebVersionResetSupport);
  }

  const startTablet = ({setSigCaptured, setSigString}: IStartTabletParams) => {
    const { displayYSize, displayXSize, imageXSize, imageYSize, canvasRef } = signatureConfig;

    if (IsSigWebInstalled()) {
      resetIsSupported = GetResetSupported();
      if (!resetIsSupported) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw {
          codes: [ServerError.NON_UPDATED_VERSION_SIGWEB],
          message: undefined,
        }
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw {
        codes: [ServerError.UNABLE_COMMUNICATE_SIGWEB],
        message: undefined,
      }
    }

    SetTabletState(1);
    const retmod = TabletModelNumber();
    SetTabletState(0);

    if (retmod === '11' || retmod === '12' || retmod === '15') {
      expandedWindow.ctx = canvasRef?.getContext('2d');
      // eslint-disable-next-line @typescript-eslint/no-implied-eval
      eventTmr = setInterval(SigWebEvent as () => void, 20);

      tmr = SetTabletState(1, expandedWindow.ctx, 50) || tmr;
      SetLCDCaptureMode(2);
      LcdRefresh(0, 0, 0, 240, 64);
      SetJustifyMode(0);
      KeyPadClearHotSpotList();
      ClearSigWindow(1);
      SetDisplayXSize(displayXSize);
      SetDisplayYSize(displayYSize);
      SetImageXSize(imageXSize);
      SetImageYSize(imageYSize);
      SetLCDCaptureMode(2);


      LCDSendGraphicUrl(1, 2, 0, 20, 'http://www.sigplusweb.com/SigWeb/Sign.bmp');
      LCDSendGraphicUrl(1, 2, 207, 4, 'http://www.sigplusweb.com/SigWeb/OK.bmp');
      LCDSendGraphicUrl(1, 2, 15, 4, 'http://www.sigplusweb.com/SigWeb/CLEAR.bmp');

      /* For Some reason this does not work
      LcdWriteLocalImage(1, 2, 0, 20, "/images/Sign.bmp");
      LcdWriteLocalImage(1, 2, 207, 4, "/images/OK.bmp");
      LcdWriteLocalImage(1, 2, 15, 4, "/images/CLEAR.bmp");
      */
      lcdSize = LCDGetLCDSize();
      // eslint-disable-next-line no-bitwise
      lcdX = lcdSize & 0xffff;
      // eslint-disable-next-line no-bitwise, @typescript-eslint/no-unused-vars
      lcdY = (lcdSize >> 16) & 0xffff;

      const data = 'These are sample terms and conditions. Please press Continue.';

      parse(data);

      LCDWriteString(0, 2, 15, 45, "9pt Arial", 15, "Continue");

      KeyPadAddHotSpot(0, 1, 12, 40, 40, 15);   // Continue

      ClearTablet();

      LCDSetWindow(0, 0, 1, 1);
      SetSigWindow(1, 0, 0, 1, 1);
      SetLCDCaptureMode(2);

      scrn = 1;

      expandedWindow.onSigPenUp = () => {
        processPenUp(setSigCaptured, setSigString);
      };

      SetLCDCaptureMode(2);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw {
        codes: [ServerError.NO_CORRESPONDING_SIGNATURE_PAD],
        message: undefined,
      }
    }
  }

  const endSigWeb = () => {
    LcdRefresh(0, 0, 0, 240, 64);
    LCDSetWindow(0, 0, 240, 64);
    SetSigWindow(1, 0, 0, 240, 64);
    KeyPadClearHotSpotList();
    SetLCDCaptureMode(1);
    SetTabletState(0, tmr);
    ClearTablet();
  }

  const close = () => {
    clearInterval(tmr);

    if (resetIsSupported) {
      Reset();
    } else {
      endSigWeb();
    }

    signatureConfig.imageXSize = 500;
    signatureConfig.imageYSize = 100;
    signatureConfig.displayXSize = 500;
    signatureConfig.displayYSize = 100;
    signatureConfig.canvasRef = null;
  }

  const signatureWebTabletIsConnected = () => {
    if (GetTabletState() !== '1') {
      throw new Error()
    }
  }

  const setSigConfig = ({config}: ISetSigConfigParams) => {
    const {imageXSize, imageYSize, displayXSize, displayYSize, canvasRef} = config

    signatureConfig.imageXSize = imageXSize || signatureConfig.imageXSize;
    signatureConfig.imageYSize = imageYSize || signatureConfig.imageYSize;
    signatureConfig.displayXSize = displayXSize || signatureConfig.displayXSize;
    signatureConfig.displayYSize = displayYSize || signatureConfig.imageYSize;
    signatureConfig.canvasRef = canvasRef || signatureConfig.canvasRef;
  }

  const clear = () => {
    ClearTablet()
    ClearSigWindow(1);
  }

  return {
    close: promisification(close),
    clear: promisification(clear),
    startTablet: promisification(startTablet),
    setSigConfig,
    signatureWebTabletIsConnected: promisification(signatureWebTabletIsConnected),
  };
})()

export const sigWebTabletIsConnected = sigWebStartMethods.signatureWebTabletIsConnected;

export const startSigWebTablet = sigWebStartMethods.startTablet;

export const closeSigWebTablet = sigWebStartMethods.close;

export const clearSigWebTablet = sigWebStartMethods.clear;

/*
* Before connecting to Topaz, set the configuration for the signature size
* */
export const setSignatureConfig = sigWebStartMethods.setSigConfig;
