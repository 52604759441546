import React, { useCallback, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormControlLabel, FormHelperText, Grid, Switch, Typography } from '@material-ui/core';
import {
  defaultNumberFormatProps,
  defaultPriceNumberProps,
} from 'common/components/NumberController/NumberController';
import { getSplitsPayment } from 'modules/services/utils/billingUtils';
import { NumberTextField } from 'common/components';
import { IEditable } from 'modules/services/interfaces/packages';
import inputLabels from 'common/messages/inputLabels';
import servicesMessages from 'modules/services/messages/messages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getAccessByPropPath } from 'common/utils/errorObject';

// TODO - PRM-1810 tmp form interface
interface IFormValues {
  outOfTermBillingOption?: {
    splitting: {
      paymentEditableSplits: Record<string, any>[];
      [field: string]: any;
    };
    [field: string]: any;
  };
  splitting: {
    paymentEditableSplits: Record<string, any>[];
    [field: string]: any;
  };
  [field: string]: any;
}

const SplitPaymentSection = ({ isOutOfTerm }: { isOutOfTerm: boolean }): JSX.Element => {
  const { control, watch, trigger, setValue, formState } = useFormContext<IFormValues>();
  const { errors } = formState;
  const valuePath = isOutOfTerm ? 'outOfTermBillingOption.' : '';
  const isAllow = watch(`${valuePath}splitting.allow`);
  const renderIntlMessage = useRenderIntlMessage();
  const isInitialRender = useRef(true);

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${valuePath}splitting.paymentEditableSplits`,
  });

  const splitNumber = useWatch({
    control,
    name: `${valuePath}splitting.paymentEditableNumber`,
  });

  const totalAmount = useWatch({
    control,
    name: `${valuePath}totalAmount`,
  });

  const paymentEditableSplits = useWatch<any>({
    control,
    name: `${valuePath}splitting.paymentEditableSplits`,
  });

  const paymentsNumberError = getAccessByPropPath(
    errors,
    `${valuePath}splitting.paymentEditableNumber.value`,
  );
  const splitsError = getAccessByPropPath(errors, `${valuePath}splitting.paymentEditableSplits`);

  const reRenderSplits = useCallback(
    ({ updatedNumber = splitNumber.value, updatedAmount = totalAmount }): void => {
      if (updatedNumber && updatedAmount) {
        const updatedSplits: IEditable[] = getSplitsPayment(updatedNumber, updatedAmount, false);

        const amountDifference: number = updatedSplits.length - fields.length;

        if (amountDifference > 0) {
          append(updatedSplits.slice(-amountDifference));
        } else if (amountDifference < 0) {
          const removableIndexes: number[] = Array.from(Array(fields.length).keys()).slice(
            amountDifference,
          );

          remove(removableIndexes);
        }
      }
    },
    [append, fields.length, remove, splitNumber.value, totalAmount],
  );

  const handleSplitsNumberChanged = (val: IEditable, onChange: (...event: any[]) => void) => {
    onChange(val);
  };

  useEffect(() => {
    if (paymentEditableSplits?.length && isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    const updatedSplits: IEditable[] = getSplitsPayment(splitNumber.value, totalAmount, false);

    setValue(`${valuePath}splitting.paymentEditableSplits`, updatedSplits);

    fields.forEach(() => remove(0));

    updatedSplits.forEach(item => append(item));

    reRenderSplits({ updatedNumber: splitNumber.value });
  }, [
    append,
    remove,
    fields,
    totalAmount,
    splitNumber.value,
    reRenderSplits,
    setValue,
    valuePath,
    paymentEditableSplits?.length,
  ]);

  return (
    <>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={`${valuePath}splitting.allow`}
          render={({ field }) => (
            <FormControlLabel
              labelPlacement="start"
              style={{ marginLeft: 0 }}
              label={
                <Typography variant="h5">
                  <FormattedMessage {...servicesMessages.splitEachPaymentLabel} />
                </Typography>
              }
              control={
                <Switch
                  size="small"
                  color="primary"
                  checked={field.value}
                  onChange={(e, checked) => field.onChange(checked)}
                />
              }
            />
          )}
        />
      </Grid>

      {!!isAllow && (
        <>
          <Grid item sm={6}>
            <Controller
              name={`${valuePath}splitting.paymentEditableNumber`}
              control={control}
              render={({ field }) => {
                const { value: paymentsNumber, onChange, onBlur } = field;

                return (
                  <NumberTextField
                    disabled={!paymentsNumber?.editable}
                    fullWidth
                    value={paymentsNumber?.value}
                    variant="outlined"
                    label={<FormattedMessage {...inputLabels.payments} />}
                    onChange={value =>
                      handleSplitsNumberChanged({ ...paymentsNumber, value }, onChange)
                    }
                    onBlur={onBlur}
                    numberFormatProps={{
                      ...defaultNumberFormatProps,
                      maxLength: 2,
                    }}
                    error={!!paymentsNumberError}
                    helperText={renderIntlMessage(paymentsNumberError?.message, {
                      value: paymentsNumberError?.message?.value,
                    })}
                  />
                );
              }}
            />
          </Grid>

          {fields.map((field, index) => (
            <Grid item xs={4} key={field.id}>
              <Controller
                name={`${valuePath}splitting.paymentEditableSplits.${index}.value`}
                control={control}
                defaultValue={field.value}
                render={({ field: formField }) => (
                  <NumberTextField
                    value={formField.value}
                    onChange={formField.onChange}
                    onBlur={() => {
                      formField.onBlur();
                      void trigger(`${valuePath}splitting.paymentEditableSplits`);
                    }}
                    fullWidth
                    label={`#${index + 1}`}
                    variant="outlined"
                    numberFormatProps={defaultPriceNumberProps}
                    // error={!!splitsError}
                  />
                )}
              />
            </Grid>
          ))}

          {!!splitsError && (
            <Grid item xs={12}>
              <FormHelperText error>{renderIntlMessage(splitsError?.message)}</FormHelperText>
            </Grid>
          )}

          {/* {!!paymentEditableSplits?.length &&
            paymentEditableSplits.map((splitItem: IEditable, index) => (
              <Grid item xs={6} key={`${splitItem.value}-${splitItem.order}`}>
                <Controller
                  name={`splitting.paymentEditableSplits.${index}.value`}
                  control={control}
                  render={({ value: paymentSplit }) => (
                    <NumberTextField
                      value={paymentSplit}
                      fullWidth
                      variant="outlined"
                      disabled={!splitItem?.editable}
                      label={`#${index + 1}`}
                      numberFormatProps={{
                        ...defaultNumberFormatProps,
                        prefix: '$',
                        decimalScale: 2,
                        maxLength: 10,
                      }}
                    />
                  )}
                />
              </Grid>
            ))} */}
        </>
      )}
    </>
  );
};

export default React.memo(SplitPaymentSection);
