import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../constants';
import insuranceInfoReducer from 'modules/members/state/insuranceInfo/reducers';
import allergiesReducer from 'modules/members/state/allergies/reducers';

const initStateMembers = fromJS({
  list: [],
  meta: {},
  listLoading: false,
  memberChangeStatusLoading: false,
  memberChangeStatusResult: {},
});

function membersListReducer(state = initStateMembers, action) {
  switch (action.type) {
    case actionTypes.FETCH_MEMBERS_LOADING:
      return state.set('listLoading', action.payload);
    case actionTypes.FETCH_MEMBERS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    default:
      return state;
  }
}

// const initStateMemberCreateForm = fromJS({
//   createdMember: null,
//   memberCreating: false,
// });

// function createMemberReducer(state = initStateMemberCreateForm, action) {
//   switch (action.type) {
//     case actionTypes.RESET_MEMBER_FORM:
//       return initStateMemberCreateForm;
//     case actionTypes.CREATE_MEMBER_LOADING:
//       return state.set('memberCreating', action.payload);
//     case actionTypes.CREATE_MEMBER:
//       return state.set('createdMember', fromJS(action.payload)).set('memberCreating', false);
//     default:
//       return state;
//   }
// }

// const initStateMemberUpdateForm = fromJS({
//   member: {},
//   memberLoading: false,
//   updatedMember: null,
//   memberUpdating: false,
// });

// function updateMemberReducer(state = initStateMemberUpdateForm, action) {
//   switch (action.type) {
//     case actionTypes.RESET_MEMBER_FORM:
//       return initStateMemberUpdateForm;
//     case actionTypes.FETCH_MEMBER_LOADING:
//       return state.set('memberLoading', action.payload);
//     case actionTypes.FETCH_MEMBER:
//       return state.set('member', fromJS(action.payload)).set('memberLoading', false);
//     case actionTypes.UPDATE_MEMBER_LOADING:
//       return state.set('memberUpdating', action.payload);
//     case actionTypes.UPDATE_MEMBER:
//       return state.set('updatedMember', fromJS(action.payload)).set('memberUpdating', false);
//     default:
//       return state;
//   }
// }

export default combineReducers({
  members: membersListReducer,
  insuranceInfo: insuranceInfoReducer,
  allergies: allergiesReducer,
  // createMemberForm: createMemberReducer,
  // updateMemberForm: updateMemberReducer,
});
