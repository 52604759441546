import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import messages from 'modules/kid-zone/messages';
import inputLabels from 'common/messages/inputLabels';
import { BarcodeScannerField, DialogComponent, LoadingBackdrop } from 'common/components';
import { getRequiredErrorMessage } from 'common/utils/validation';

interface IBarcodeFieldModal {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSearchByBarcode: (barcode: string) => void;
}

const SEARCH_BY_BARCODE_FORM_ID = 'search-by-barcode-form';

const validationSchema = yup.object().shape({
  barcode: yup
    .string()
    .trim()
    .required(getRequiredErrorMessage),
});

const SearchByBarcodeModal = (props: IBarcodeFieldModal): JSX.Element => {
  const { isOpen, onClose, onSearchByBarcode, isLoading } = props;
  const formMethods = useForm<any>({
    defaultValues: {
      barcode: '',
    },
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit } = formMethods;

  const onSubmit = ({ barcode }: { barcode: string }) => {
    onSearchByBarcode(barcode);
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      size="xs"
      submitBtnTitle={<FormattedMessage {...inputLabels.search} />}
      formId={SEARCH_BY_BARCODE_FORM_ID}
      title={<FormattedMessage {...messages.barcodeCheckInTitle} />}
      onSubmit={handleSubmit(onSubmit)}
    >
      <>
        {isLoading && <LoadingBackdrop isLoading />}
        <form id={SEARCH_BY_BARCODE_FORM_ID}>
          <FormProvider {...formMethods}>
            <BarcodeScannerField name="barcode" />
          </FormProvider>
        </form>
      </>
    </DialogComponent>
  );
};
export default SearchByBarcodeModal;
