import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box } from '@material-ui/core';
import { CustomTheme } from 'common/ui/interfaces';
import { getInitials } from 'helpers/common';
import cx from 'classnames';
import profilePlaceholder from 'img/photo-placeholder.png';
import SearchUsersAutocompleteSelect from '../../../SearchUsersAutocomplete/SearchUsersAutocomplete';
import { IInvoiceDetailsImt } from '../../../../interfaces/invoices';
import * as actions from '../../../../state/invoice/actions';
import inputLabels from '../../../../messages/inputLabels';
import searchInputMessages from '../../../SearchUsersAutocomplete/messages';
import IntlService from 'services/intl/IntlService';
import { PeakModules } from 'common/constants/peakModules';
import { useLazyGetSalespersons } from 'modules/members/api/member.api';
import { INamedEntity } from '../../../../interfaces/common';

interface IInvoiceSalespersonSelector {
  selectedInvoice?: IInvoiceDetailsImt;
  module: PeakModules;
  isPaymentStep?: boolean;
  disabled: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  optionAvatar: {
    height: 24,
    width: 24,
    marginRight: theme.spacing(1),
  },
  avatarWrap: {
    position: 'relative',
    flex: 'none',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.light,
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '12px',
    fontWeight: 700,
    color: theme.palette.darkBackground?.light,
  },
  text: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignSelf: 'center',
    fontSize: theme.typography.h5.fontSize,
  },
}));

const InvoiceSalespersonSelector: FC<IInvoiceSalespersonSelector> = props => {
  const { selectedInvoice, module, isPaymentStep = false, disabled } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();

  const [options, setOptions] = useState<INamedEntity<number>[]>([]);
  const [getSalespersons, { isLoading }] = useLazyGetSalespersons();

  const handleSelectSalesperson = (selectedSales): void => {
    dispatch(
      actions.setSalespersonToInvoice(
        selectedInvoice?.get('id') || '',
        selectedSales.id,
        isPaymentStep,
        module,
      ),
    );
  };

  const getOptionsByValue = (search: string): void => {
    getSalespersons(search)
      .unwrap()
      .then(salespersons => setOptions(salespersons));
  };

  const resetOptions = (): void => {
    setOptions([]);
  };

  const renderOption = (option: INamedEntity<number>): JSX.Element => {
    const [firstName, lastName] = option.title.split(' ');
    const initials = getInitials(firstName, lastName);

    return (
      <>
        <Box mr={12} className={cx(classes.avatarWrap, classes.optionAvatar)}>
          {initials && !option.imageUrl ? (
            <Box className={classes.avatarInitial}>{initials}</Box>
          ) : (
            <Avatar
              src={option.imageUrl || profilePlaceholder}
              alt="avatar-icon"
              className={classes.optionAvatar}
            />
          )}
        </Box>
        <Box className={classes.text}>{`${firstName} ${lastName}`}</Box>
      </>
    );
  };

  return (
    <SearchUsersAutocompleteSelect
      renderOption={renderOption}
      selectedValue={selectedInvoice?.get('salesperson')?.toJS()}
      loading={isLoading}
      onChange={handleSelectSalesperson}
      getOptionsByValue={getOptionsByValue}
      hasStartSearchMode
      resetOptions={resetOptions}
      hideNOS
      label={IntlService.formatMessage(inputLabels.salesperson)}
      searchPlaceholder={IntlService.formatMessage(searchInputMessages.searchSalesperson)}
      options={options}
      error={!selectedInvoice?.get('salesperson')}
      disabled={disabled}
    />
  );
};

export default InvoiceSalespersonSelector;
