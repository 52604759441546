import inputErrors from 'common/messages/inputErrors';
import {
  stringNumberSchema,
  nullableStringNumberSchema,
} from 'common/validationSchemas/stringNumberSchema';
import * as yup from 'yup';
import { getRequiredErrorMessage } from 'common/utils/validation';

export const cacheValidationSchema: yup.AnyObjectSchema = yup.object().shape({
  tendered: stringNumberSchema
    .min(yup.ref('amount'), () => inputErrors.tenderedLowerThanAmount)
    .required(getRequiredErrorMessage),
  onAccount: nullableStringNumberSchema,
});
