import { createAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import { actionTypes } from 'modules/services/constants/documentTemplates';
import { ITableParams } from 'common/interfaces/table';
import Services from 'services/network';
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from 'common/state/notifications/actions';
import { IPaginatedDocumentTemplate } from 'modules/services/interfaces/documentTemplates';
import messages from 'common/messages/messages';
import { TemplatePurpose, TemplateTypes } from 'common/constants/documentTemplate';
import { GeneralThunkAction } from 'common/state/interfaces';
import {
  IUpdateDocumentTemplateStatusRequest,
  ICreateDocumentTemplateDto,
  IDocumentTemplateDto,
} from 'common/interfaces/documentTemplate';
import { ActionResult } from 'common/constants';

const fetchDocumentTemplatesAction = createAction<IPaginatedDocumentTemplate>(
  actionTypes.FETCH_DOCUMENT_TEMPLATES,
);
const fetchDocumentTemplatesLoadingAction = createAction<boolean>(
  actionTypes.FETCH_DOCUMENT_TEMPLATES_LOADING,
);

export const resetDocumentTemplatesReset = createAction(actionTypes.RESET_DOCUMENT_TEMPLATES);

export const fetchDocumentTemplates = (
  type: TemplateTypes,
  purpose: TemplatePurpose,
  requestOptions?: ITableParams,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchDocumentTemplatesLoadingAction(true));

    try {
      const paginatedData = await Services.ServicesList.getDocumentTemplates(
        type,
        purpose,
        requestOptions,
      );
      dispatch(fetchDocumentTemplatesAction(paginatedData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchDocumentTemplatesLoadingAction(false));
    }
  };
};

export const resetSaveDocumentActionResult = createAction<void>(
  actionTypes.RESET_SAVE_TEMPLATE_RESULT,
);
export const saveTemplateActionResult = createAction<ActionResult | null>(
  actionTypes.SAVE_TEMPLATE_ACTION_RESULT,
);
const saveDocumentLoadingAction = createAction<boolean>(actionTypes.SAVE_TEMPLATE_LOADING);

export const saveDocumentCreator = (params: ICreateDocumentTemplateDto): GeneralThunkAction => {
  return async dispatch => {
    dispatch(saveDocumentLoadingAction(true));
    try {
      await Services.ServicesList.saveDocumentTemplate(params);

      batch(() => {
        dispatch(saveTemplateActionResult(ActionResult.SUCCESS_ACTION));
        dispatch(enqueueSuccessNotification(messages.templateCreatedMessage));
      });
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(saveDocumentLoadingAction(false));
    }
  };
};

export const updateDocumentTemplate = (params: IDocumentTemplateDto): GeneralThunkAction => {
  return async dispatch => {
    dispatch(saveDocumentLoadingAction(true));
    try {
      await Services.ServicesList.updateDocumentTemplate(params);

      batch(() => {
        dispatch(saveTemplateActionResult(ActionResult.SUCCESS_ACTION));
        dispatch(enqueueSuccessNotification(messages.templateCreatedMessage));
      });
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(saveDocumentLoadingAction(false));
    }
  };
};

const getTemplateLoading = createAction<boolean>(actionTypes.GET_TEMPLATE_LOADING);
const getTemplateAction = createAction<IDocumentTemplateDto>(actionTypes.GET_TEMPLATE);

export const resetTemplate = createAction(actionTypes.RESET_TEMPLATE);

export const getTemplate = (id: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getTemplateLoading(true));
    try {
      const document = await Services.ServicesList.getDocumentTemplate(id);

      dispatch(getTemplateAction(document));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getTemplateLoading(false));
    }
  };
};

const setUpdateDocumentStatusIsLoading = createAction<boolean>(
  actionTypes.SET_UPDATE_DOCUMENT_STATUS_IS_LOADING,
);
const updateDocumentStatus = createAction<IUpdateDocumentTemplateStatusRequest>(
  actionTypes.UPDATE_DOCUMENT_STATUS,
);
export const updateDocumentStatusActionResult = createAction<ActionResult | null>(
  actionTypes.UPDATE_DOCUMENT_STATUS_ACTION_RESULT,
);

export const updateDocumentTemplateStatus = (
  params: IUpdateDocumentTemplateStatusRequest,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(setUpdateDocumentStatusIsLoading(true));
    try {
      await Services.ServicesList.updateDocumentStatus(params);

      batch(() => {
        dispatch(updateDocumentStatus(params));
        dispatch(updateDocumentStatusActionResult(ActionResult.SUCCESS_ACTION));
      });
    } catch (error) {
      enqueueErrorNotification(error);
    } finally {
      dispatch(setUpdateDocumentStatusIsLoading(false));
    }
  };
};
