import React from 'react';
import { IBundleView } from 'modules/services/interfaces/packages';
import { DialogComponent, LoadingBackdrop, BundleView } from 'common/components';
import ServicesHistoryInventoryModal from '../ServicesHistoryInventoryModal/ServicesHistoryInventoryModal';
import { IInventoryDetailedInfo } from 'modules/reports/interfaces/invoiceHistory';

interface IProps {
  onClose: () => void;
  packageInfo?: IBundleView;
  isPackageInfoLoading;
  setInventoryId: (id: string | null) => void;
  inventoryId: string | null;
  inventoryInfo?: IInventoryDetailedInfo;
  isInventoryInfoLoading: boolean;
}

const ServicesHistoryPackageModal = ({
  onClose,

  packageInfo,
  isPackageInfoLoading,
  setInventoryId,
  inventoryId,
  inventoryInfo,
  isInventoryInfoLoading,
}: IProps): JSX.Element => {
  return (
    <DialogComponent
      hideFooterButtons
      title={packageInfo?.title}
      isOpen
      onClose={onClose}
      size="md"
    >
      {!!packageInfo && (
        <BundleView bundle={packageInfo} onViewInventoryInfo={(id: string) => setInventoryId(id)} />
      )}

      {!!inventoryId && (
        <ServicesHistoryInventoryModal
          isOpen
          onClose={() => setInventoryId(null)}
          inventoryInfo={inventoryInfo}
          isInventoryInfoLoading={isInventoryInfoLoading}
        />
      )}

      <LoadingBackdrop isLoading={isPackageInfoLoading} />
    </DialogComponent>
  );
};

export default ServicesHistoryPackageModal;
