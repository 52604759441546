import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';

import { fetchModificationHistory } from 'common/components/PersonProfile/state/actions';

import { PeakModules } from 'common/constants/peakModules';
import { TableOrderByParams } from 'common/constants';

import { IModificationHistoryImt } from 'common/components/PersonProfile/interfaces';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { FilterTypes, IFilterSettings, IPaginateFilterReturnType } from 'common/interfaces/filter';
import { ITableParams } from 'common/interfaces/table';

import { DialogComponent } from 'common/components/index';
import { ModificationHistoryTable } from 'common/components/PersonProfile/components/index';

import { makeTableParams } from 'common/utils/http';
import { useAppDispatch } from 'store/hooks';

import tableFilters from 'common/messages/tableFilters';
import messages from 'common/components/PersonProfile/messages';
import Services from 'services/network';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  modificationHistory?: List<IModificationHistoryImt>;
  modificationHistoryMeta?: IPageMetaImt;
  modificationHistoryLoading?: boolean;
  onSelectHistoryItem: (revisionId: number) => void;
  personId: number;
  module?: PeakModules;
}

const ModificationHistoryModal = ({
  isOpen,
  onClose,
  modificationHistory,
  modificationHistoryMeta,
  modificationHistoryLoading,
  onSelectHistoryItem,
  personId,
  module,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const tableFilterSettings: IFilterSettings[] = useMemo(
    () => [
      {
        name: '',
        title: <FormattedMessage {...tableFilters.period} />,
        type: FilterTypes.DATE_RANGE,
        options: {
          startDate: null,
          endDate: null,
        },
      },
      {
        name: 'employeeIds',
        title: <FormattedMessage {...tableFilters.employee} />,
        type: FilterTypes.MULTIPLE_WITH_PAGINATE,
        perPage: 20,
        fetchItemTitle: async (id: number): Promise<string | null> => {
          try {
            const employee = await Services.Employees.getEmployeeById(id);
            return `${employee.firstName} ${employee.lastName}`;
          } catch (e) {
            return null;
          }
        },
        fetchList: async (search: string, page: number): Promise<IPaginateFilterReturnType> => {
          try {
            const { meta, data } = await Services.DictionaryLists.getEmployeesList({
              search,
              page,
              perPage: 20,
              module,
            });

            return {
              pageElements: data.map(item => ({ key: item.id, label: item.title, value: item.id })),
              itemCount: meta.total,
            };
          } catch (e) {
            return { pageElements: [], itemCount: 0 };
          }
        },
      },
    ],
    [module],
  );

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilterSettings, null, {
      orderBy: TableOrderByParams.DATE,
      order: 'desc',
    }),
  );

  useEffect(() => {
    if (module) {
      dispatch(fetchModificationHistory(personId, module, tableParams));
    }
  }, [dispatch, module, personId, tableParams]);

  const handleChangeTableProps = useCallback((tableProps: ITableParams): void => {
    setTableParams(tableProps);
  }, []);

  return (
    <DialogComponent
      title={<FormattedMessage {...messages.memberModificationHistoryTitle} />}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      hideFooterButtons
    >
      <ModificationHistoryTable
        modificationHistory={modificationHistory}
        modificationHistoryMeta={modificationHistoryMeta}
        onSelectHistoryItem={onSelectHistoryItem}
        modificationHistoryLoading={modificationHistoryLoading}
        hideSearchInput
        onChangeTableParams={handleChangeTableProps}
        tableParams={tableParams}
        tableFilterSettings={tableFilterSettings}
      />
    </DialogComponent>
  );
};

export default ModificationHistoryModal;
