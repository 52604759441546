import React, { useState } from 'react';
import { Popover, makeStyles, IconButton } from '@material-ui/core';
import { InsertEmoticon } from '@material-ui/icons';
import EmojiPicker from 'emoji-picker-react';
import { EditorState, Modifier } from 'draft-js';

const useStyles = makeStyles(() => ({
  emojiButton: {
    padding: 0,
    width: '24px',
  },
  emojiIcon: {
    fontSize: '1.1rem',
  },
  emojiPicker: {
    '& .emoji-picker-react .emoji.has-skin-variation:before': {
      display: 'none',
    },
  },
}));

interface IProps {
  editorState?: any;
  className?: string;
  onChange?: (param) => void;
}

const EmojiTool = (props: IProps): JSX.Element => {
  const { editorState, onChange, className } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpenPicker = (e: React.MouseEvent<any>): void => {
    setAnchorEl(e.currentTarget);
  };

  const onClosePicker = () => {
    setAnchorEl(null);
  };

  const onSelectEmoji = (e, data) => {
    if (editorState) {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        data.emoji,
        editorState.getCurrentInlineStyle(),
      );

      setTimeout(() => {
        if (onChange) {
          onChange(EditorState.push(editorState, contentState, 'insert-characters'));
        }
      }, 0);
    } else {
      if (onChange) {
        onChange(data.emoji);
      }
    }
  };

  return (
    <div className={className} aria-label="rdw-emoji-picker">
      <IconButton className="rdw-option-wrapper" onClick={onOpenPicker}>
        <InsertEmoticon className={classes.emojiIcon} />
      </IconButton>

      <Popover
        id="emoji-picker-popover"
        className={classes.emojiPicker}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClosePicker}
      >
        <EmojiPicker onEmojiClick={onSelectEmoji} />
      </Popover>
    </div>
  );
};

export default EmojiTool;
