/* eslint-disable react-hooks/exhaustive-deps */
// TODO: inspect this dependencies
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import GrapesJS from 'grapesjs';
import newsletterPlugin from 'grapesjs-preset-newsletter';
import touchPlugin from 'grapesjs-touch';

import {
  CKEEditorOptions,
  CKEEditorPlugin,
  TablePlugin,
  EmployeesPlugin,
  ProductsPlugin,
  ServicesPlugin,
  TextPlugin,
  ImagePlugin,
  ReplyContentPlugin,
  EmbedYoutubePlugin,
} from './plugins';
import SearchComponents from './components/SearchComponents';
import { UploadImageModal } from 'common/modals';
import { IAttachmentImt } from 'common/interfaces/uploadFile';
import { EditorBlockViews, ExcludedBlocks, GrapesCustomCommands } from './constants';
import { useEmailEditorIframeStyles, useEmailEditorStyles } from './styling';
import EmbeddedIframeModal from './components/EmbeddedIframeModal';

interface IEditorProps {
  id: string;
  width?: string | number;
  height?: string | number;
  onInit?: (editor: any) => void;
  onDestroy?: (editor: any) => void;
  onEditorUpdate?: () => void;
  onEditorContentChanged?: () => void;
}

const EmailEditor = forwardRef<any, IEditorProps>((props: IEditorProps, ref) => {
  const { id, width, height, onInit, onDestroy, onEditorUpdate, onEditorContentChanged } = props;

  const theme = useTheme();
  const classes = useEmailEditorStyles();
  const editorStyles = useEmailEditorIframeStyles(theme);
  const [editor, setEditor] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [activeCommand, setActiveCommand] = useState<GrapesCustomCommands | null>(null);

  const [videoModel, setVideoModel] = useState(null);

  const [isUploadImageShown, setIsUploadImageShown] = useState<boolean>(false);
  const [imageModel, setImageModel] = useState<any>(null);

  useEffect(() => {
    const grapesEditor = (GrapesJS as any).init({
      container: `#${id}`,
      fromElement: true,
      storageManager: {
        type: null,
      },
      blockManager: {
        appendOnClick: true,
      },
      width,
      height,
      plugins: [
        newsletterPlugin,
        touchPlugin,
        TextPlugin,
        TablePlugin,
        ImagePlugin,
        EmbedYoutubePlugin,
        CKEEditorPlugin,
        ProductsPlugin,
        ServicesPlugin,
        EmployeesPlugin,
        ReplyContentPlugin,
      ],
      pluginsOpts: {
        'gjs-plugin-ckeditor': {
          ...CKEEditorOptions,
        },
      },
      canvasCss: editorStyles,
    });

    grapesEditor.Css.setRule('.email-template-tag', {
      padding: '2px 4px 3px 4px',
      'border-radius': '14px',
    });

    const blManager = grapesEditor.BlockManager;

    ExcludedBlocks.forEach(c => blManager.remove(c));

    Object.entries(EditorBlockViews).forEach(([key, config]) => {
      blManager.get(key).set(config);
    });

    const onRunSearchCommand = (command: GrapesCustomCommands) => {
      const target = document.querySelector('.gjs-toolbar');
      setAnchorEl(target);
      setActiveCommand(command);
    };

    grapesEditor.on('component:mount', () => {
      grapesEditor.refresh();
      if (onEditorUpdate) {
        onEditorUpdate();
      }
    });

    grapesEditor.on('change:changesCount', () => {
      if (onEditorContentChanged) {
        onEditorContentChanged();
      }
    });

    grapesEditor.on(`run:${GrapesCustomCommands.SearchProducts}`, () =>
      onRunSearchCommand(GrapesCustomCommands.SearchProducts),
    );
    grapesEditor.on(`run:${GrapesCustomCommands.SearchEmployees}`, () =>
      onRunSearchCommand(GrapesCustomCommands.SearchEmployees),
    );
    grapesEditor.on(`run:${GrapesCustomCommands.SearchServices}`, () =>
      onRunSearchCommand(GrapesCustomCommands.SearchServices),
    );

    grapesEditor.on(`run:${GrapesCustomCommands.EditVideo}`, (cmd, params) => {
      setActiveCommand(GrapesCustomCommands.EditVideo);
      setVideoModel(params.model);
    });

    grapesEditor.on(`run:${GrapesCustomCommands.EmbedIframe}`, (cmd, params) => {
      setActiveCommand(GrapesCustomCommands.EmbedIframe);

      const model = params?.model || grapesEditor.getSelected();
      setVideoModel(model);
    });

    grapesEditor.on(`run:${GrapesCustomCommands.AddImage}`, (cmd, params) => {
      setActiveCommand(GrapesCustomCommands.AddImage);

      // we take model here from params, because the item is not selected yet
      setImageModel(params.model);
      setIsUploadImageShown(true);
    });

    grapesEditor.on(`run:${GrapesCustomCommands.EditImage}`, () => {
      setActiveCommand(GrapesCustomCommands.EditImage);

      // There is no way to send model from tooltip
      setImageModel(grapesEditor.getSelected());
      setIsUploadImageShown(true);
    });

    // support blocks touch on mobile
    setTimeout(() => {
      const blockElements = document.getElementsByClassName(
        'gjs-block gjs-one-bg gjs-four-color-h',
      );
      Array.from(blockElements).forEach(el =>
        el.addEventListener('touchstart', e => {
          e.preventDefault();
          (el as HTMLElement).click();
        }),
      );
    }, 300);

    setEditor(grapesEditor);

    if (typeof onInit === 'function') {
      onInit(grapesEditor);
    }

    return function cleanup() {
      if (editor) {
        if (typeof onDestroy === 'function') {
          onDestroy(editor);
        }
        (GrapesJS as any).editors = (GrapesJS as any).editors.filter((e: any) => e !== editor);
        editor.destroy();
        const container: HTMLDivElement = document.getElementById(id) as HTMLDivElement;
        if (container) {
          container.innerHTML = '';
        }
      }
    };
  }, [id, width, height]);

  useEffect(() => {
    if (editor) {
      const rule = editor.CssComposer.getRule('.email-template-tag');

      if (rule) {
        rule.addStyle({ 'background-color': theme.palette.primary.main, color: 'white' });
      }
    }
  }, [editor, theme]);

  const onSelectComponent = component => {
    editor.stopCommand(activeCommand, { component });
    setAnchorEl(null);
  };

  const onCloseComponentsSearch = () => {
    editor.stopCommand(activeCommand);
    setAnchorEl(null);
    setActiveCommand(null);
  };

  const onSaveEmbeddedIframe = (embeddingCode: string): void => {
    editor.stopCommand(activeCommand, {
      model: videoModel,
      embeddingCode,
    });
    setVideoModel(null);
  };

  const onSaveImage = (attachment: IAttachmentImt): void => {
    editor.stopCommand(activeCommand, { model: imageModel, attachment });
    setIsUploadImageShown(false);
    setActiveCommand(null);
    setImageModel(null);
  };

  const onToggleImageDialog = () => {
    if (isUploadImageShown) {
      editor.stopCommand(activeCommand);
      setActiveCommand(null);
    }

    setIsUploadImageShown(prev => !prev);
  };

  useImperativeHandle(ref, () => {
    return editor;
  });

  return (
    <div id={id} className={classes.root}>
      <SearchComponents
        anchorEl={anchorEl}
        commandType={activeCommand}
        onClose={onCloseComponentsSearch}
        onSelect={onSelectComponent}
      />

      <EmbeddedIframeModal
        isOpen={!!videoModel}
        onClose={() => {
          editor.stopCommand(activeCommand);
          setVideoModel(null);
        }}
        onSubmit={onSaveEmbeddedIframe}
      />

      {isUploadImageShown && (
        <UploadImageModal
          id="email-editor-image-loader"
          isOpen={isUploadImageShown}
          src={imageModel?.getAttributes()?.set ? imageModel.getAttributes().src : null}
          onToggle={onToggleImageDialog}
          onSubmit={onSaveImage}
        />
      )}
    </div>
  );
});

EmailEditor.defaultProps = {
  width: 'auto',
  height: '100%',
};

export default EmailEditor;
