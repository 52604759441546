/* eslint @typescript-eslint/no-unused-vars: 0 */

import React from 'react';
// material components
import { CircularProgress, MenuItem, StyledComponentProps, Typography } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
// custom components
import { FilterViewTypes, IFilterOption, ISingleFilterProps } from 'common/interfaces/filter';
import FilterMenuBody from '../FilterMenuBody/FilterMenuBody';
import DropdownFilter from '../DropdownFilter/DropdownFilter';
import HoverFilter from '../HoverFilter/HoverFilter';

import commonMessages from 'common/messages/messages';
import cx from 'classnames';

const styles = (theme: Theme) =>
  createStyles({
    option: {
      padding: `${theme.spacing(1, 1.5)} !important`,
    },
    selectedOption: {
      '&:not(:hover)': {
        color: theme.palette.primary.main,
      },
    },
    loader: {
      '& svg': {
        margin: 0,
      },
    },
  });

type ISingleFilterComponentProps = ISingleFilterProps & StyledComponentProps;

interface ISingleFilterComponentState {
  selectedOption: IFilterOption;
}

@(withStyles(styles) as any)
class SingleFilter extends React.Component<
  ISingleFilterComponentProps,
  ISingleFilterComponentState
> {
  constructor(props: ISingleFilterComponentProps) {
    super(props);

    this.state = {
      selectedOption: props.value as any, // TODO - PRM-3575 need types
    };
  }

  componentDidUpdate(prevProps: ISingleFilterComponentProps): void {
    const { value } = this.props;
    if (prevProps.value && !value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedOption: null as any }); // TODO - PRM-3575 need types
    }
  }

  private get filtersTotal(): number {
    const { value } = this.props;
    return value ? 1 : 0;
  }

  private handleOptionClick = (option: IFilterOption | null, closeFilterBody: () => void): void => {
    // TODO - PRM-3575 need types
    this.setState({ selectedOption: option as any }, () => {
      const {
        onChange,
        dialog,
        settings: { name, type },
      } = this.props;

      if (dialog) return; // dialog closes and changes only on apply

      onChange(
        {
          name,
          type,
          value: option,
        },
        name,
      );

      if (closeFilterBody) closeFilterBody();
    });
  };

  private handleFilterApply = (handleCloseMenu?: () => void): void => {
    const {
      onChange,
      settings: { name, type },
    } = this.props;
    const { selectedOption } = this.state;

    onChange(
      {
        name,
        type,
        value: selectedOption,
      },
      name,
    );
    if (handleCloseMenu) handleCloseMenu();
  };

  private renderFilterTitle = (): string | JSX.Element => {
    const {
      value,
      settings: { title, isLoading, options },
      classes,
    } = this.props;

    const selectedOption = options.find((option: IFilterOption) => option?.value === value?.value);

    if (value) {
      return isLoading ? (
        <CircularProgress size="16px" color="inherit" className={classes?.loader} />
      ) : (
        <Typography noWrap component="span" color="inherit">
          {selectedOption?.label || value.label}
        </Typography>
      );
    }

    return (
      <>
        <Typography noWrap component="span">
          {title}: <FormattedMessage {...commonMessages.allOption} />
        </Typography>
      </>
    );
  };

  private renderFilterBody = (closeFilterBody?: () => void): JSX.Element => {
    const {
      dialog,
      classes,
      settings: { options },
    } = this.props;
    const { selectedOption } = this.state;

    return (
      <FilterMenuBody dialog={dialog}>
        <MenuItem
          key="empty-option"
          disableRipple
          className={cx(classes?.option, { [classes?.selectedOption || '']: !selectedOption })}
          onClick={() => this.handleOptionClick(null, closeFilterBody || (() => {}))}
        >
          <Typography color="inherit">
            <FormattedMessage {...commonMessages.allOption} />
          </Typography>
        </MenuItem>
        {options.map(option => (
          <MenuItem
            key={option.key}
            onClick={() => this.handleOptionClick(option, closeFilterBody || (() => {}))}
            disableRipple
            className={cx(classes?.option, {
              [classes?.selectedOption || '']:
                selectedOption && selectedOption.value === option.value,
            })}
          >
            <Typography color="inherit">{option.label}</Typography>
          </MenuItem>
        ))}
      </FilterMenuBody>
    );
  };

  private renderDropdownFilter = (): JSX.Element => {
    const {
      dialog,
      settings: { name },
    } = this.props;

    return (
      <DropdownFilter
        name={name}
        title={this.renderFilterTitle()}
        dialogMenu={dialog}
        filtersTotal={this.filtersTotal}
        renderBody={this.renderFilterBody}
        onDialogApply={this.handleFilterApply}
      />
    );
  };

  private renderHoverMenuFilter = (): JSX.Element => {
    const {
      dialog,
      settings: { name },
    } = this.props;

    return (
      <HoverFilter
        name={name}
        dialogMenu={dialog}
        title={this.renderFilterTitle()}
        filtersTotal={this.filtersTotal}
        renderBody={this.renderFilterBody}
        onDialogApply={this.handleFilterApply}
      />
    );
  };

  render(): JSX.Element {
    const { viewType } = this.props;

    return viewType === FilterViewTypes.DROPDOWN
      ? this.renderDropdownFilter()
      : this.renderHoverMenuFilter();
  }
}

export default SingleFilter;
