import React, { FC, useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { List as ImmutableList } from 'immutable';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { ICameFromIndicatorDictionaryItemImt } from 'common/interfaces/dictionary';
import { IImmutablePrimaryMemberInfo } from 'common/components/PersonProfile/interfaces';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { selectLoading } from 'common/state/newPerson/primaryInfo/selectors';
import { useAppDispatch } from 'store/hooks';
import { DialogComponent, LoadingBackdrop, MemberPrimaryInfoStep } from 'common/components';
import { DictionaryList } from 'common/constants/dictionaryConstants';
import commonMessages from 'common/messages/messages';
import { selectCurrentUser } from 'modules/authentication/state/selectors';
import { PeakModules } from 'common/constants/peakModules';
import { IObject } from 'common/interfaces/common';
import { useGetSalespersons } from 'modules/members/api/member.api';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      maxWidth: '480px',

      '& form': {
        padding: 0,
      },
    },
  },
});

interface INewMemberModal {
  isOpen: boolean;
  isLoading?: boolean;
  selectedMembers?: IObject[];
  onClose: () => void;
  onSubmit: (data) => void;
  onSelectMember?: (person: IImmutablePrimaryMemberInfo) => void;
  module?: PeakModules;
}

const NewMemberModal: FC<INewMemberModal> = ({
  isLoading = false,
  isOpen,
  onSelectMember,
  selectedMembers,
  onClose,
  onSubmit,
  module,
}) => {
  const dispatch = useAppDispatch();
  const { data: employees = [] } = useGetSalespersons(undefined, { skip: !isOpen });

  const cameFromIndicators: ImmutableList<ICameFromIndicatorDictionaryItemImt> = useRootSelector(
    selectDictionaryList(DictionaryList.CAME_FROM),
  );

  const isCreateMemberLoading = useRootSelector(selectLoading);
  const currentUser = useRootSelector(selectCurrentUser);
  const classes = useStyles();

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchDictionaryList(DictionaryList.CAME_FROM, { module }));
    }
  }, [dispatch, isOpen, module]);

  return (
    <DialogComponent
      className={classes.root}
      title={<FormattedMessage {...commonMessages.newMemberCommonTitle} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.saveBtn} />}
      isOpen={isOpen}
      onClose={onClose}
      size="xs"
      formId="primary-info-form"
      disableFullScreenBehavior
      loading={isCreateMemberLoading}
    >
      <LoadingBackdrop isLoading={isLoading} />
      <MemberPrimaryInfoStep
        currentUser={currentUser}
        hasHiddenFooter
        handleOnSubmit={onSubmit}
        employees={employees}
        cameFromIndicators={cameFromIndicators}
        setPrimaryInfo={onSelectMember}
        hideAutocomplete
        isActiveMembersRestricted
        selectedMembers={selectedMembers}
        module={module}
      />
    </DialogComponent>
  );
};

export default React.memo(NewMemberModal);
