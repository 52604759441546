import * as yup from 'yup';
import { denominations } from 'modules/pos-kiosk/constants/registers';
import { getRequiredErrorMessage } from 'common/utils/validation';

const schema = {};

denominations.forEach(denominationItem => {
  schema[denominationItem.key] = yup
    .number()
    .typeError(getRequiredErrorMessage)
    .required(getRequiredErrorMessage);
});

export const ReconcileBillsValidationSchema = yup.object().shape(schema);
