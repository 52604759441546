import React, { FC, useEffect } from 'react';
import { AddressBlock, DialogComponent } from 'common/components';
import { getAlertAddressSchema } from 'common/validationSchemas/addressSchema';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AlertCondition } from 'common/interfaces/alerts';
import modalTitles from 'common/messages/modalTitles';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from 'store/hooks';
import useRootSelector from 'common/hooks/useRootSelector';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import { IProfileInfo, IProfileInfoImt } from 'common/components/PersonProfile/interfaces';
import { IFormAddress } from 'common/interfaces/additionalInfo';
import { useGetAddress } from 'modules/members/api/member.api';

interface IAddressModal {
  alert: AlertCondition;
  isOpen: boolean;
  switchingModal: (alertId: string) => void;
  onSubmit: (profileInfo: Partial<IProfileInfo>, alertId: string) => void;
  profile: IProfileInfoImt;
}

const AddressModal: FC<IAddressModal> = ({ isOpen, onSubmit, switchingModal, alert, profile }) => {
  const dispatch = useAppDispatch();
  const profileRequiredFields = useRootSelector(selectProfileRequiredFields);
  const isSubmitted = useRootSelector(selectIsSubmittedRecommendedFieldsModal);
  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();
  const { data: address } = useGetAddress(String(profile.get('id')));

  const updateAddress = (values: IFormAddress): void => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty({ address: values });

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit({ address: values }, alert);
      switchingModal(alert);
    }
  };

  const formMethods = useForm<IFormAddress>({
    defaultValues: {
      line1: '',
      line2: '',
      zipCode: '',
      city: '',
      state: null,
      country: null,
    },
    resolver: yupResolver(getAlertAddressSchema(profileRequiredFields)),
    mode: 'onBlur',
  });

  const { reset, handleSubmit, getValues } = formMethods;

  useEffect(() => {
    if (address) {
      reset({
        line1: address.line1 || '',
        line2: address.line2 || '',
        city: address.city || '',
        zipCode: address.zipCode || '',
        state: address.state || null,
        country: address.country || null,
      });
    }
  }, [reset, address]);

  useEffect(() => {
    if (isSubmitted) {
      onSubmit({ address: getValues() }, alert);
      switchingModal(alert);
      dispatch(showRecommendedFieldsModal(false));
    }
  }, [alert, dispatch, getValues, isSubmitted, onSubmit, switchingModal]);

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...modalTitles.addressInfo} />}
      isOpen={isOpen}
      onClose={() => switchingModal(alert)}
      onSubmit={handleSubmit(updateAddress)}
    >
      <form>
        <FormProvider {...formMethods}>
          <AddressBlock recommendedFields={profileRecommendedFields} />
        </FormProvider>
      </form>
    </DialogComponent>
  );
};

export default AddressModal;
