import { FC } from 'react';
import { useIntl } from 'react-intl';
import IntlService from 'services/intl/IntlService';

interface IIntlServiceProvider {
  children: JSX.Element;
}

const IntlServiceProvider: FC<IIntlServiceProvider> = ({ children }) => {
  const intl = useIntl();
  IntlService.setIntl(intl);

  return children;
};

export default IntlServiceProvider;
