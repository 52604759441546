import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import {
  ICustomerEmergencyContactDto,
  IFormEmergencyContact,
} from 'common/interfaces/additionalInfo';
import { useAppDispatch } from 'store/hooks';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { getEmergencyContactValidationSchema } from 'common/validationSchemas/emergencyContactValidationSchema';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { DialogComponent } from 'common/components';
import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';
import IntlService from 'services/intl/IntlService';
import useRootSelector from 'common/hooks/useRootSelector';
import EmergencyContactName from './EmergencyContactName';
import EmergencyContactPhone from './EmergencyContactPhone';
import EmergencyContactRelationship from './EmergencyContactRelationship';

interface IEmergencyContactForm {
  onSubmit: (values: IFormEmergencyContact) => void;
  onClose: () => void;
  emergencyContact?: ICustomerEmergencyContactDto;
  loading?: boolean;
}

const EmergencyContactForm: FC<IEmergencyContactForm> = ({
  onSubmit,
  onClose,
  emergencyContact: eContact,
  loading = false,
}) => {
  const dispatch = useAppDispatch();
  const { checkRecommendedFieldsAreNotEmpty } = useRecommendedFields();
  const profileRequiredFields = useRootSelector(selectProfileRequiredFields);
  const isSubmitted = useRootSelector(selectIsSubmittedRecommendedFieldsModal);
  let initialValue: IFormEmergencyContact;

  if (eContact) {
    initialValue = {
      emergencyName: eContact.emergencyName || '',
      emergencyPhone: eContact.emergencyPhone || '',
      emergencyRelationship: eContact.emergencyRelationship || '',
    };
  } else {
    initialValue = {
      emergencyName: '',
      emergencyPhone: '',
      emergencyRelationship: '',
    };
  }

  const formMethods = useForm<IFormEmergencyContact>({
    defaultValues: initialValue,
    resolver: yupResolver(getEmergencyContactValidationSchema(profileRequiredFields)),
    mode: 'all',
  });

  const {
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = formMethods;

  const onSubmitForm = (values: IFormEmergencyContact) => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty({
      emergencyContactDto: values,
    });

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      if (isDirty) {
        onSubmit(values);
      } else {
        onClose();
      }
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      dispatch(showRecommendedFieldsModal(false));

      if (isDirty) {
        onSubmit(getValues());
      } else {
        onClose();
      }
    }
  }, [dispatch, getValues, isSubmitted, onSubmit, isDirty, onClose]);

  return (
    <DialogComponent
      size="xs"
      title={IntlService.formatMessage(messages.emergencyContactBlockTitle)}
      submitBtnTitle={IntlService.formatMessage(commonMessages.saveBtn)}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmitForm)}
      disableFullScreenBehavior
      loading={loading}
      isOpen
    >
      <FormProvider {...formMethods}>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <EmergencyContactName />
            </Grid>

            <Grid item xs={12}>
              <EmergencyContactPhone />
            </Grid>

            <Grid item xs={12}>
              <EmergencyContactRelationship />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </DialogComponent>
  );
};

export default EmergencyContactForm;
