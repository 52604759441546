import React from 'react';
import { Button, Grid, SvgIcon } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';

import commonMessages from 'common/messages/messages';
import { NewLeadSteps } from 'modules/crm/constants/leads';
import { ArrowCircleIcon, ArrowCircleLeftIcon } from 'img/icons';
import { ButtonWithCircularProgress } from 'common/components';
import { CustomTheme } from 'common/ui/interfaces';

interface IFooterProps {
  goBack: () => void;
  onNext: () => void;
  onBack: () => void;
  currentStep: NewLeadSteps | null;
  createLeadLoading: boolean;
  hasChangePackagePlanMode?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  footer: {
    padding: theme.spacing(2),
    flexWrap: 'nowrap',
  },
  btnIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

const Footer = (props: IFooterProps) => {
  const {
    goBack,
    onNext,
    onBack,
    createLeadLoading,
    currentStep,
    hasChangePackagePlanMode,
  } = props;

  const classes = useStyles();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    }
  };

  const handleOnBack = () => {
    if (onBack) {
      onBack();
    }
  };

  const handleOnNext = () => {
    if (onNext) {
      onNext();
    }
  };

  const hasHiddenBackBtn =
    (currentStep === NewLeadSteps.TrialMembership && hasChangePackagePlanMode) ||
    currentStep === NewLeadSteps.PrimaryInfo;

  const canShowCancelBtn =
    currentStep === NewLeadSteps.PrimaryInfo ||
    (hasChangePackagePlanMode && currentStep === NewLeadSteps.TrialMembership);

  return (
    <Grid container className={classes.footer} justifyContent="flex-end" spacing={2}>
      <Grid item>
        <Button color="primary" onClick={handleGoBack}>
          {canShowCancelBtn ? (
            <FormattedMessage {...commonMessages.cancelBtn} />
          ) : (
            <FormattedMessage {...commonMessages.continueLaterBtn} />
          )}
        </Button>
      </Grid>

      {hasHiddenBackBtn || (
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            startIcon={
              <SvgIcon className={classes.btnIcon}>
                <ArrowCircleLeftIcon />
              </SvgIcon>
            }
            onClick={handleOnBack}
          >
            <FormattedMessage {...commonMessages.backBtn} />
          </Button>
        </Grid>
      )}

      <Grid item>
        {currentStep === NewLeadSteps.ScheduleTour ? (
          <ButtonWithCircularProgress
            type="submit"
            onClick={handleOnNext}
            isSubmitting={createLeadLoading}
          >
            <FormattedMessage {...commonMessages.doneBtn} />
          </ButtonWithCircularProgress>
        ) : (
          <ButtonWithCircularProgress
            type="submit"
            endIcon={
              <SvgIcon className={classes.btnIcon}>
                <ArrowCircleIcon />
              </SvgIcon>
            }
            onClick={handleOnNext}
            isSubmitting={createLeadLoading}
          >
            {currentStep === NewLeadSteps.PrimaryInfo ? (
              <FormattedMessage {...commonMessages.saveContinueBtn} />
            ) : (
              <FormattedMessage {...commonMessages.nextBtn} />
            )}
          </ButtonWithCircularProgress>
        )}
      </Grid>
    </Grid>
  );
};

export default Footer;
