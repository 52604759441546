import * as yup from 'yup';
import { getEmailErrorMessage, getRequiredErrorMessage } from 'common/utils/validation';
import messages from 'modules/crm/messages/templates';

export const EmailTemplateSchema = yup.object().shape({
  name: yup
    .string()
    .max(32, () => messages.emailTemplateName)
    .required(getRequiredErrorMessage),
  subject: yup
    .string()
    .max(70, () => messages.emailTemplateSubject)
    .required(getRequiredErrorMessage),
  from: yup
    .string()
    .email(getEmailErrorMessage)
    .required(getRequiredErrorMessage),
  fromName: yup
    .string()
    .max(32, () => messages.emailTemplateFromName)
    .required(getRequiredErrorMessage),
});
