import React, { Dispatch, SetStateAction, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { List as ImmutableList } from 'immutable';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { getInitials } from '../../../../../helpers/common';
import SearchUsersAutocompleteSelect from '../../../SearchUsersAutocomplete/SearchUsersAutocomplete';
import { CartUnitTypes, IInvoiceDetailsImt, MenuItemTypes } from '../../../../interfaces/invoices';
import searchInputMessages from '../../../SearchUsersAutocomplete/messages';
import { GuestStatus } from 'modules/front-desk/components';
import { ICustomerShort } from 'common/components/PersonProfile/interfaces';

import * as actions from '../../../../state/invoice/actions';
import * as selectors from '../../../../state/invoice/selectors';

import inputLabels from '../../../../messages/inputLabels';
import useRootSelector from 'common/hooks/useRootSelector';
import { PeakModules } from 'common/constants/peakModules';
import { AvatarWithInitials } from 'common/components/index';

interface IInvoiceCustomerSelectorProps {
  selectedInvoice?: IInvoiceDetailsImt;

  onOpenNewModal: () => void;
  setIsSelectCustomerAlertShown: Dispatch<SetStateAction<boolean>>;
  module: PeakModules;
  isPaymentStep: boolean;
  disabled: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  text: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignSelf: 'center',
    fontSize: theme.typography.h5.fontSize,
  },
}));

const InvoiceCustomerSelector: React.FC<IInvoiceCustomerSelectorProps> = (
  props: IInvoiceCustomerSelectorProps,
): JSX.Element => {
  const classes = useStyles(props);
  const intl = useIntl();
  const {
    selectedInvoice,
    onOpenNewModal,
    disabled,
    module,
    isPaymentStep,
    setIsSelectCustomerAlertShown,
  } = props;

  const dispatch = useDispatch();

  const optionsCustomer: ImmutableList<ICustomerShort> = useRootSelector(
    selectors.selectCustomerData(),
  );
  const isUsersListLoading: boolean = useRootSelector(selectors.selectUsersListLoading());

  const hasNOSOptionRef = useRef(false);

  const convertedInvoice = useMemo(() => selectedInvoice?.toJS(), [selectedInvoice]);

  const isCustomerSelectDisabled =
    convertedInvoice?.invoiceUnits?.some(
      invoiceUnit =>
        invoiceUnit.type === CartUnitTypes.MEMBERSHIP_BUNDLE &&
        invoiceUnit.bundle.menuItemType === MenuItemTypes.Membership,
    ) || disabled;

  const handleSelectCustomer = (selectedCustomer): void => {
    if (selectedInvoice?.size) {
      dispatch(
        actions.setCustomerToInvoice(
          selectedInvoice.get('id'),
          selectedCustomer?.id || null,
          isPaymentStep,
          module,
        ),
      );

      if (selectedCustomer?.id) {
        setIsSelectCustomerAlertShown(false);
      }
    }
  };

  const isNOSOptionDisabled = useMemo(() => {
    return selectedInvoice
      ?.get('invoiceUnits')
      .some(
        invoiceUnit =>
          invoiceUnit.get('type') === CartUnitTypes.SERVICE_BUNDLE ||
          invoiceUnit.get('type') === CartUnitTypes.TOP_UP_BALANCE,
      );
  }, [selectedInvoice]);

  return (
    <SearchUsersAutocompleteSelect
      renderOption={option => {
        const initials = getInitials(option.firstName, option.lastName);
        return (
          <>
            <AvatarWithInitials
              initials={initials}
              imageUrl={option.imageUrl}
              width="24px"
              height="24px"
              variant="circular"
            />

            <Box className={classes.text}>
              <Box>{`${option.firstName} ${option.lastName}`}</Box>
              <GuestStatus type={option.type} />
            </Box>
          </>
        );
      }}
      selectedValue={convertedInvoice?.customer}
      loading={isUsersListLoading}
      onChange={selectedCustomer => {
        if (!selectedCustomer) {
          hasNOSOptionRef.current = true;
        }
        handleSelectCustomer(selectedCustomer);
      }}
      getOptionsByValue={event => {
        if (event) dispatch(actions.fetchDataMembers(event, module));
        else dispatch(actions.resetDataMembers());
      }}
      isNOSOptionDisabled={isNOSOptionDisabled}
      label={<FormattedMessage {...inputLabels.customer} />}
      searchPlaceholder={intl.formatMessage({ ...searchInputMessages.searchMembers })}
      onCreateNew={onOpenNewModal}
      options={optionsCustomer.toJS()}
      disabled={isCustomerSelectDisabled}
    />
  );
};

export default React.memo(InvoiceCustomerSelector);
