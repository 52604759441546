import React, { useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useNavigationType, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, createStyles, Grid, MenuItem, TextField } from '@material-ui/core';
import { FormContainer, Select } from 'common/components';
import { RouteBackground } from 'components';
import { FeeType, IFee } from '../../../interfaces/fees';
import * as actions from '../../../state/fees/actions';
import * as selectors from '../../../state/fees/selectors';
import { feeValidationSchema } from './feeValidationSchema';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import messages from '../../../messages/fees';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import { ActiveInactiveStatus } from 'common/constants';
import { FeeTypeOptions } from '../../../constants/fees';
import { useAppDispatch } from 'store/hooks';

type TRouteParams = { feeId: string };

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

const defaultFeeValues: IFee = {
  title: '',
  active: true,
  type: FeeType.Regular,
};

const EditFee: React.FC = (): JSX.Element => {
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const { feeId } = useParams<TRouteParams>();
  const classes = useStyles();
  const renderIntlMessage = useRenderIntlMessage();
  const dispatch = useAppDispatch();
  const fee = useRootSelector(selectors.selectFeeDetails);
  const isFeeLoading = useRootSelector(selectors.selectFeeDetailsLoading);
  const actionResult = useRootSelector(selectors.selectFeeActionResult);
  const actionResultLoading = useRootSelector(selectors.selectFeeActionLoading);

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<IFee>({
    mode: 'onSubmit',
    defaultValues: defaultFeeValues,
    resolver: yupResolver(feeValidationSchema) as any, // TODO - PRM-1810 need resolver type
  });

  useEffect(() => {
    if (feeId) {
      dispatch(actions.fetchFeesDetails(feeId));
    }

    return () => {
      dispatch(actions.resetFeesDetails());
    };
  }, [dispatch, feeId]);

  useEffect(() => {
    if (fee) {
      reset(fee.toJS());
    }

    return () => reset(defaultFeeValues);
  }, [reset, fee]);

  useEffect(() => {
    if (actionResult?.get('id')) {
      if (navigationType === 'PUSH') navigate(-1);
      else navigate('/services/fees');
      dispatch(feeId ? actions.resetUpdateFee() : actions.resetCreateFee());
    }
  }, [actionResult, dispatch, feeId, navigate, navigationType]);

  const onSubmit = (data): void => {
    if (fee?.get('id')) {
      dispatch(actions.updateFee(fee.get('id'), data));
    } else {
      dispatch(actions.createFee(data));
    }
  };

  const onCancel = () => {
    if (navigationType === 'PUSH') navigate(-1);
    else navigate('/services/fees');
  };

  const Title = (): JSX.Element => (
    <FormattedMessage {...(fee ? messages.editFeePageTitle : messages.newFeePageTitle)} />
  );

  return (
    <RouteBackground>
      <Box className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
          <FormContainer
            title={<Title />}
            onCancel={onCancel}
            isSubmitting={isFeeLoading || actionResultLoading}
            maxWidth={625}
          >
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      inputRef={field.ref}
                      fullWidth
                      variant="outlined"
                      label={<FormattedMessage {...inputLabels.title} />}
                      error={!!errors.title}
                      helperText={renderIntlMessage(errors.title?.message)}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <Controller
                  name="active"
                  control={control}
                  render={({ field }) => (
                    <Select
                      fullWidth
                      label={<FormattedMessage {...commonMessages.statusTitle} />}
                      variant="outlined"
                      name={field.name}
                      value={field.value}
                      onChange={e => {
                        if (e === 'true') {
                          field.onChange(true);
                        } else {
                          field.onChange(false);
                        }
                      }}
                      onBlur={field.onBlur}
                    >
                      {ActiveInactiveStatus.map(item => (
                        <MenuItem key={item.key} value={`${item.value}`}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Select
                      fullWidth
                      label={<FormattedMessage {...inputLabels.type} />}
                      variant="outlined"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    >
                      {FeeTypeOptions.getSelectOptions<FeeType>()}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
          </FormContainer>
        </form>
      </Box>
    </RouteBackground>
  );
};

export default EditFee;
