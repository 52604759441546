import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { DialogComponent } from 'common/components/index';

import { INote } from 'common/components/PersonProfile/interfaces';

import notes from '../messages/notes';
import messages from 'common/messages/messages';

interface IProps {
  noteToDelete: INote;
  isNoteModalOpen: boolean;
  deletePersonNote: (id: string) => void;
  loading: boolean;
  onCloseModal: () => void;
}

const DeleteNoteModal: FC<IProps> = ({
  noteToDelete,
  isNoteModalOpen,
  deletePersonNote,
  loading,
  onCloseModal,
}) => {
  const onCloseModalHandler = () => {
    onCloseModal();
  };

  const onSubmitDeletingNote = () => {
    deletePersonNote(noteToDelete.id || '');
  };

  if (!isNoteModalOpen) {
    return null;
  }

  return (
    <DialogComponent
      title={<FormattedMessage {...notes.deleteNote} />}
      submitBtnTitle={<FormattedMessage {...messages.deleteBtn} />}
      size="xs"
      isOpen={isNoteModalOpen}
      onClose={onCloseModalHandler}
      onSubmit={onSubmitDeletingNote}
      disableFullScreenBehavior
      loading={loading}
    />
  );
};

export default DeleteNoteModal;
