import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { ReactComponent as Shutter } from 'img/icons/shutter.svg';
import Button from 'common/components/Button/Button';
import { Loader } from 'common/components';
import { CustomTheme } from 'common/ui/interfaces';
import { ImageFormatType } from 'common/interfaces/uploadFile';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import CameraErrorType from 'common/components/Camera/constants';
import messages from 'common/components/Camera/messages';

interface ICameraProps {
  onCapturePhoto: (img: string | null) => void;
  width: number;
  height: number;
  screenshotFormat?: ImageFormatType.WEBP | ImageFormatType.PNG | ImageFormatType.JPEG;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  camera: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: theme.palette.text.primary,
    borderRadius: '5px',

    '& > button': {
      position: 'absolute',
      bottom: '20px',
      color: theme.palette.background.paper,
    },

    '& video': {
      objectFit: 'cover',
      borderRadius: '5px',
    },
  },
  error: {
    position: 'absolute',
    fontFamily: 'Roboto-Regular',
    color: theme.palette.background.paper,
    fontSize: '16px',
  },
}));

export const Camera = (props: ICameraProps): JSX.Element => {
  const { screenshotFormat = ImageFormatType.PNG, onCapturePhoto, width, height } = props;

  const [isLoadingCamera, setIsLoadingCamera] = useState(true);
  const [errorMessageDescriptor, setErrorMessageDescriptor] = useState<MessageDescriptor>();

  const webcamRef = useRef<any>(null);
  const classes = useStyles();

  const onCaptureClick = () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    onCapturePhoto(imageSrc);
  };

  const onUserMedia = () => {
    setIsLoadingCamera(false);
  };

  const onUserMediaError = e => {
    switch (e.name) {
      case CameraErrorType.NotFoundError:
        setErrorMessageDescriptor(messages.noFoundCameraError);
        break;
      case CameraErrorType.NotAllowedError:
        setErrorMessageDescriptor(messages.cameraAccessIsRequiredError);
        break;
      default:
    }

    setIsLoadingCamera(false);
  };

  return (
    <Box className={classes.camera}>
      {isLoadingCamera && <Loader />}
      {errorMessageDescriptor && (
        <Box className={classes.error}>
          <FormattedMessage {...errorMessageDescriptor} />
        </Box>
      )}
      <Webcam
        onUserMedia={onUserMedia}
        onUserMediaError={onUserMediaError}
        height={width}
        width={height}
        screenshotQuality={1}
        forceScreenshotSourceSize
        screenshotFormat={screenshotFormat}
        audio={false}
        ref={webcamRef}
        videoConstraints={{
          facingMode: 'user',
          height,
          width,
        }}
      />
      <Button disabled={Boolean(errorMessageDescriptor)} size="large" onClick={onCaptureClick}>
        <Shutter />
      </Button>
    </Box>
  );
};

export default Camera;
