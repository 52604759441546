import React from 'react';
import { Grid } from '@material-ui/core';

import { IKidProfileImt } from 'modules/kid-zone/interfaces';

import { Guardians } from 'modules/kid-zone/containers/index';

interface IProps {
  isBiggerThanXs: boolean | null;
  isLessThanTablet: boolean | null;
  profile: IKidProfileImt;
  onCloseManualCheckinPanel: () => void;
}

const ServicesSection = ({
  isBiggerThanXs,
  isLessThanTablet,
  profile,
  onCloseManualCheckinPanel,
}: IProps): JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Guardians
          onCloseManualCheckinPanel={onCloseManualCheckinPanel}
          profile={profile}
          isSmallScreen={!!isBiggerThanXs && !!isLessThanTablet}
        />
      </Grid>
    </Grid>
  );
};

export default ServicesSection;
