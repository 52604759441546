import { ReceiptFieldType } from '../interfaces/receipts';
import { FormattedMessage } from 'react-intl';
import receiptsMessages from 'modules/pos-settings/messages/receipts';
import React from 'react';

export const actionTypes = {
  FETCH_RECEIPTS_LIST: 'FETCH_RECEIPTS_LIST',
  FETCH_RECEIPTS_LIST_LOADING: 'FETCH_RECEIPTS_LIST_LOADING',
  RESET_RECEIPTS_LIST: 'RESET_RECEIPTS_LIST',

  CHANGE_RECEIPT_STATUS: 'CHANGE_RECEIPT_STATUS',
  CHANGE_RECEIPT_STATUS_LOADING: 'CHANGE_RECEIPT_STATUS_LOADING',
  RESET_RECEIPT_CHANGE_STATUS: 'RESET_RECEIPT_CHANGE_STATUS',

  FETCH_DETAILED_RECEIPT: 'FETCH_DETAILED_RECEIPT',
  FETCH_DETAILED_RECEIPT_LOADING: 'FETCH_DETAILED_RECEIPT_LOADING',
  RESET_DETAILED_RECEIPT: 'RESET_DETAILED_RECEIPT',

  CREATE_RECEIPT: 'CREATE_RECEIPT',
  CREATE_RECEIPT_LOADING: 'CREATE_RECEIPT_LOADING',
  RESET_CREATE_RECEIPT: 'RESET_CREATE_RECEIPT',

  UPDATE_RECEIPT: 'UPDATE_RECEIPT',
  UPDATE_RECEIPT_LOADING: 'UPDATE_RECEIPT_LOADING',
  RESET_UPDATE_RECEIPT: 'RESET_UPDATE_RECEIPT',

  FETCH_RECEIPT_CLUBS: 'FETCH_RECEIPT_CLUBS',
  FETCH_RECEIPT_CLUBS_LOADING: 'FETCH_RECEIPT_CLUBS_LOADING',
  RESET_RECEIPT_CLUBS: 'RESET_RECEIPT_CLUBS',

  ADD_RECEIPT_CLUBS: 'ADD_RECEIPT_CLUBS',
  ADD_RECEIPT_CLUBS_LOADING: 'ADD_RECEIPT_CLUBS_LOADING',
  RESET_ADD_RECEIPT_CLUBS: 'RESET_ADD_RECEIPT_CLUBS',

  DELETE_RECEIPT_CLUBS: 'DELETE_RECEIPT_CLUBS',
  DELETE_RECEIPT_CLUBS_LOADING: 'DELETE_RECEIPT_CLUBS_LOADING',
  RESET_DELETE_RECEIPT_CLUBS: 'RESET_DELETE_RECEIPT_CLUBS',

  FETCH_SIMPLE_RECEIPT: 'FETCH_SIMPLE_RECEIPT',
  RESET_SIMPLE_RECEIPT: 'RESET_SIMPLE_RECEIPT',
};

export const ReceiptFieldTypeLabels: Record<ReceiptFieldType, JSX.Element> = {
  [ReceiptFieldType.Text]: <FormattedMessage {...receiptsMessages.textFieldLabel} />,
  [ReceiptFieldType.Image]: <FormattedMessage {...receiptsMessages.imageFieldLabel} />,
  [ReceiptFieldType.DiscountQRCode]: (
    <FormattedMessage {...receiptsMessages.discountQrFieldLabel} />
  ),
  [ReceiptFieldType.CustomPaymentMethod]: (
    <FormattedMessage {...receiptsMessages.customPaymentMethodsFieldLabel} />
  ),

  [ReceiptFieldType.ClientName]: <FormattedMessage {...receiptsMessages.clubNameFieldLabel} />,
  [ReceiptFieldType.ClientAddress]: (
    <FormattedMessage {...receiptsMessages.clubAddressFieldLabel} />
  ),

  [ReceiptFieldType.Customer]: <FormattedMessage {...receiptsMessages.customerFieldLabel} />,
  [ReceiptFieldType.Email]: <FormattedMessage {...receiptsMessages.emailFieldLabel} />,
  [ReceiptFieldType.Phone]: <FormattedMessage {...receiptsMessages.phoneFieldLabel} />,
  [ReceiptFieldType.Address]: <FormattedMessage {...receiptsMessages.addressFieldLabel} />,
  [ReceiptFieldType.Salesperson]: <FormattedMessage {...receiptsMessages.salespersonFieldLabel} />,
  [ReceiptFieldType.InsuranceCompany]: (
    <FormattedMessage {...receiptsMessages.insuranceCompanyFieldLabel} />
  ),
  [ReceiptFieldType.InsuranceMemberId]: (
    <FormattedMessage {...receiptsMessages.insuranceMemberFieldLabel} />
  ),
  [ReceiptFieldType.Membership]: <FormattedMessage {...receiptsMessages.membershipFieldLabel} />,
  [ReceiptFieldType.Waiver]: <FormattedMessage {...receiptsMessages.waiverFieldLabel} />,
};
