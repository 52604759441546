import { Component } from 'grapesjs';

export function removeActions(component: Component): void {
  component.set({
    editable: false,
    draggable: false,
    droppable: false,
    highlightable: false,
    resizable: false,
    toolbar: [],
  });

  component.get('components')?.forEach(item => {
    removeActions(item);
  });
}
