import moment from 'moment-timezone';

export const getTimezoneOffset = (timezone: string) => {
  const timezoneLabel = timezone
    ?.split('/')
    ?.slice(1)
    ?.map(labelItem => ` ${labelItem.replaceAll('_', ' ')}`);

  return `${moment.tz(timezone).format('h:mm A')} (GMT ${moment
    .tz(timezone)
    .format('Z')}) ${timezoneLabel}`;
};
