import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import commonMessages from 'common/messages/messages';
import menuItems from 'common/messages/menuItems';
import { DialogComponent } from 'common/components';
import { Controller, useForm } from 'react-hook-form';
import inputLabels from 'common/messages/inputLabels';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeSatusSchema } from 'common/components/ChangeStatusModal/ChangeStatusSchema';
import { AdaptiveDatePicker } from 'common/components/index';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentAdapter from '@date-io/moment';
import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import IntlService from 'services/intl/IntlService';

interface IChangeStatusModal {
  itemTitle: JSX.Element | string;
  isActive: boolean;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (data?) => void;
  terminationDate?: string;
  hasTerminationDate?: boolean;
}

const ChangeStatusModal: FC<IChangeStatusModal> = ({
  itemTitle,
  isActive,
  isOpen,
  isLoading,
  terminationDate,
  hasTerminationDate = false,
  onClose,
  onSubmit,
}) => {
  const renderIntlMessage = useRenderIntlMessage();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      terminationDate: terminationDate ?? '',
    },
    resolver: yupResolver(ChangeSatusSchema),
    mode: 'onSubmit',
  });

  const hideTerminationDateField = useMemo(
    () => terminationDate && moment(terminationDate).isAfter(moment().format()),
    [terminationDate],
  );

  const submitCondition = useMemo(
    () => !isActive && hasTerminationDate && !hideTerminationDateField,
    [isActive, hasTerminationDate, hideTerminationDateField],
  );

  return (
    <DialogComponent
      title={
        itemTitle ? (
          <FormattedMessage
            values={{ name: itemTitle }}
            {...(isActive ? commonMessages.deactivateItem : commonMessages.activateItem)}
          />
        ) : (
          ''
        )
      }
      submitBtnTitle={
        <FormattedMessage {...(isActive ? menuItems.deactivate : menuItems.activate)} />
      }
      size="xs"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={submitCondition ? handleSubmit(onSubmit) : onSubmit}
      disableFullScreenBehavior
      loading={isLoading}
    >
      {submitCondition && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item md={6} sm={6}>
            <MuiPickersUtilsProvider utils={MomentAdapter}>
              <Controller
                name="terminationDate"
                control={control}
                defaultValue={terminationDate}
                render={({ field }) => (
                  <AdaptiveDatePicker
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disablePast
                    label={IntlService.formatMessage(inputLabels.terminationDate)}
                    inputVariant="outlined"
                    error={!!errors.terminationDate}
                    helperText={renderIntlMessage(errors.terminationDate?.message)}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </form>
      )}
    </DialogComponent>
  );
};
export default ChangeStatusModal;
