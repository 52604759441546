import { batch } from 'react-redux';
import { createAction } from '@reduxjs/toolkit';
import Services from 'services/network';
import { actionTypes } from 'common/state/newPerson/contract/constants';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { IServerError } from 'common/interfaces/http';
import {
  IPersonDocumentItemDto,
  IPersonDocumentToSignDto,
  IReqContract,
  IResContract,
} from 'common/interfaces/contract';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { GeneralThunkAction } from 'common/state/interfaces';
import { PeakModules } from 'common/constants/peakModules';
import { ActionResult } from 'common/constants';
import { IUploadFileResult as IFile } from 'common/interfaces/uploadFile';
import { printHTML } from 'common/state/printHTML/actions';
import { SignType } from 'common/constants/contract';

export const setSignContractActionResult = createAction<ActionResult | null>(
  actionTypes.SIGN_CONTRACT_ACTION_RESULT,
);

// contract
export const getContractsAction = createAction<IPersonDocumentItemDto[]>(actionTypes.GET_CONTRACTS);
export const getContractAction = createAction<IPersonDocumentItemDto | null>(
  actionTypes.GET_CONTRACT,
);
export const signContractAction = createAction<IPersonDocumentItemDto>(actionTypes.SIGN_CONTRACT);
export const applyContractAction = createAction<IPersonDocumentItemDto | null>(
  actionTypes.APPLY_CONTRACT,
);

export const clearContractDataAction = createAction(actionTypes.CLEAR_CONTRACT);
export const setErrorContractAction = createAction<IServerError>(actionTypes.SET_ERROR);
export const setIsLoadingContractAction = createAction<boolean>(actionTypes.SET_IS_LOADING);

export const getSignatureRequests = (reqContracts: IReqContract[]): Promise<IResContract>[] => {
  const requests: Promise<IResContract>[] = [];

  for (const reqContract of reqContracts) {
    if (reqContract.documentSignature) {
      const file = reqContract.documentSignature.file;
      const documentId = reqContract.documentSignature.documentId;

      const request = new Promise<IResContract>((resolve, reject) => {
        Services.FileUpload.uploadFile(file)
          .then((result: IFile) => {
            resolve({ documentId, file: result });
          })
          .catch(() => {
            reject(null);
          });
      });

      requests.push(request);
    }
  }

  return requests;
};

export const mapSignatureContracts = async (
  reqContracts: IReqContract[],
  requests: Promise<IResContract>[],
): Promise<IPersonDocumentToSignDto[]> => {
  const contracts: IPersonDocumentToSignDto[] = [];

  if (requests.length) {
    const results: IResContract[] = await Promise.all(requests);

    for (const reqContract of reqContracts) {
      if (reqContract.contract.signType === SignType.ESignature) {
        const contract: IPersonDocumentToSignDto = { ...reqContract.contract };

        if (reqContract.documentSignature) {
          const documentId = reqContract.documentSignature.documentId;
          const result = results.find(item => item.documentId === documentId);

          if (result) {
            contract.signature = { ...result.file };
          }
        }

        contracts.push(contract);
      } else {
        contracts.push({ ...reqContract.contract });
      }
    }
  } else {
    for (const reqContract of reqContracts) {
      contracts.push({ ...reqContract.contract });
    }
  }

  return contracts;
};

export const signContractThunk = (
  personId: number,
  reqContracts: IReqContract[],
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
  onSuccess?: () => void,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(setIsLoadingContractAction(true));

    const requests = getSignatureRequests(reqContracts);
    const contracts = await mapSignatureContracts(reqContracts, requests);
    let contractData: IPersonDocumentItemDto;

    switch (module) {
      case PeakModules.FrontDesk:
        contractData = await Services.FrontDesk.signContract(personId, contracts);
        break;
      case PeakModules.Members:
        contractData = await Services.Members.signContract(personId, contracts);
        break;
      case PeakModules.Crm:
        contractData = await Services.Leads.signContract(personId, contracts);
        break;
      case PeakModules.PersonalTrainingCrm:
        contractData = await Services.PTLeads.signContract(personId, contracts);
        break;
      default:
    }

    batch(() => {
      dispatch(signContractAction(contractData));
      dispatch(setSignContractActionResult(ActionResult.SUCCESS_ACTION));
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    dispatch(setErrorContractAction(e));
    dispatch(enqueueErrorNotification(e));
  } finally {
    dispatch(setIsLoadingContractAction(false));
  }
};

export const getPersonContractThunk = (
  personId: number,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
  packageInstanceIds: number[],
  onSuccess?: (contracts: IPersonDocumentItemDto[]) => void,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(setIsLoadingContractAction(true));

    let contracts: IPersonDocumentItemDto[];

    switch (module) {
      case PeakModules.FrontDesk: {
        contracts = await Services.FrontDesk.getPersonContract(personId, packageInstanceIds);
        break;
      }
      case PeakModules.Members: {
        contracts = await Services.Members.getPersonContract(personId, packageInstanceIds);
        break;
      }
      case PeakModules.Crm: {
        contracts = await Services.Leads.getPersonContract(personId, packageInstanceIds);
        break;
      }
      case PeakModules.PersonalTrainingCrm: {
        contracts = await Services.PTLeads.getPersonContract(personId, packageInstanceIds);
        break;
      }
      default: {
        contracts = [];
      }
    }

    if (onSuccess) {
      onSuccess(contracts);
    }

    dispatch(getContractsAction(contracts));
  } catch (e) {
    dispatch(setErrorContractAction(e));
    dispatch(enqueueErrorNotification(e));
  } finally {
    dispatch(setIsLoadingContractAction(false));
  }
};

export const getPersonDocumentById = (
  personId: number,
  documentId: string,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(setIsLoadingContractAction(true));

    let document: IPersonDocumentItemDto | null = null;

    switch (module) {
      case PeakModules.FrontDesk:
        document = await Services.FrontDesk.getPersonDocumentById(personId, documentId);
        break;
      case PeakModules.Members:
        document = await Services.Members.getPersonDocumentById(personId, documentId);
        break;
      case PeakModules.Crm:
        document = await Services.Leads.getPersonDocumentById(personId, documentId);
        break;
      case PeakModules.PersonalTrainingCrm:
        document = await Services.PTLeads.getPersonDocumentById(personId, documentId);
        break;
      default:
    }

    dispatch(getContractAction(document));
  } catch (e) {
    dispatch(setErrorContractAction(e));
    dispatch(enqueueErrorNotification(e));
  } finally {
    dispatch(setIsLoadingContractAction(false));
  }
};

export const applyContractSignatureThunk = (
  personId: number,
  documentId: string,
  file: File,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(setIsLoadingContractAction(true));

    let contractData: IPersonDocumentItemDto | null = null;

    switch (module) {
      case PeakModules.FrontDesk:
        contractData = await Services.FrontDesk.applyContractSignature(personId, documentId, file);
        break;
      case PeakModules.Members:
        contractData = await Services.Members.applyContractSignature(personId, documentId, file);
        break;
      case PeakModules.Crm:
        contractData = await Services.Leads.applyContractSignature(personId, documentId, file);
        break;
      case PeakModules.PersonalTrainingCrm:
        contractData = await Services.PTLeads.applyContractSignature(personId, documentId, file);
        break;
      default:
    }

    dispatch(applyContractAction(contractData));
  } catch (e) {
    dispatch(setErrorContractAction(e));
    dispatch(enqueueErrorNotification(e));
  } finally {
    dispatch(setIsLoadingContractAction(false));
  }
};

const fetchContractHTMLLoading = createAction<boolean>(actionTypes.FETCH_CONTRACT_HTML_LOADING);

export const printContract = (
  personId: number,
  contractId: string,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(fetchContractHTMLLoading(true));

    let result: string;

    switch (module) {
      case PeakModules.FrontDesk:
        result = await Services.FrontDesk.printContract(personId, contractId);
        break;
      case PeakModules.Members:
        result = await Services.Members.printContract(personId, contractId);
        break;
      case PeakModules.Crm:
        result = await Services.Leads.printContract(personId, contractId);
        break;
      default:
        result = await Services.PTLeads.printContract(personId, contractId);
    }

    dispatch(printHTML(result));
  } catch (e) {
    dispatch(setErrorContractAction(e));
    dispatch(enqueueErrorNotification(e));
  } finally {
    dispatch(fetchContractHTMLLoading(false));
  }
};
