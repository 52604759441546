import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ClockRotateIcon } from 'img/icons/clock-rotate.svg';
import commonMessages from 'common/messages/messages';
import { ActionItem, ActionsMenu } from 'common/components';

interface IUnredeemedActionsMenuProps {
  sessionId: string;
  disabled: boolean;
  onUnredeemed?: (taskId: string) => void;
}

const UnredeemedActionsMenu = (props: IUnredeemedActionsMenuProps): JSX.Element => {
  const { sessionId, onUnredeemed, disabled } = props;

  const handleUnredeemedClick = () => {
    if (onUnredeemed) {
      onUnredeemed(sessionId);
    }
  };

  return (
    <ActionsMenu horizontal tableActionsMode>
      <ActionItem
        icon={<ClockRotateIcon width={16} height={16} />}
        disabled={disabled}
        onClick={handleUnredeemedClick}
      >
        <FormattedMessage {...commonMessages.unredeemBtn} />
      </ActionItem>
    </ActionsMenu>
  );
};

export default UnredeemedActionsMenu;
