import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';

import { ReactComponent as ClubsIcon } from 'img/icons/sidebar/dumbbell.svg';
import { ReactComponent as CalendarIcon } from 'img/icons/calendar.svg';
import { ReactComponent as ShareIcon } from 'img/icons/share.svg';
import { ReactComponent as DocumentChartIcon } from 'img/icons/document-chart.svg';

import commonMessages from 'common/messages/messages';
import { PermissionLevels } from 'common/constants/permissions';
import { Message } from 'common/interfaces/common';

export interface PageRouteSetting {
  path: string;
  title: Message;
  icon: OverridableComponent<SvgIconTypeMap>;
  isDisabled?: boolean;
  isDivider?: boolean;
  isGroupTitle?: boolean;
  permissionsSet?: PermissionLevels[];
}

export const commonRoutes: PageRouteSetting[] = [
  {
    path: '/profile',
    title: commonMessages.profileHeader,
    icon: ShareIcon as any, // TODO - PRM-3575 need types
  },
  {
    path: '/booking',
    title: commonMessages.booking,
    icon: CalendarIcon as any, // TODO - PRM-3575 need types
  },
  {
    path: '/reports/overview',
    title: commonMessages.reports,
    icon: DocumentChartIcon as any, // TODO - PRM-3575 need types
    isDisabled: true,
  },
  {
    path: '/clubs',
    title: commonMessages.clubsLabel,
    icon: ClubsIcon as any, // TODO - PRM-3575 need types
    isDisabled: true,
  },
];
