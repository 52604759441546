import { ConstOptions } from '../../constants/classes';

export enum CreditCardTypes {
  Visa = 'VISA',
  MasterCard = 'MASTERCARD',
  Amex = 'AMERICAN EXPRESS',
  Discover = 'DISCOVER',
}

// payment dependencies types, for e.g. from method Payment.fns.cardType(value);
export enum PDCreditCardType {
  visa = 'visa',
  mastercard = 'mastercard',
  discover = 'discover',
  amex = 'amex',
}

export const PDCreditCardTypes = new ConstOptions([
  { key: PDCreditCardType.visa, value: PDCreditCardType.visa, message: null as any }, // TODO - PRM-3575 need types
  { key: PDCreditCardType.amex, value: PDCreditCardType.amex, message: null as any }, // TODO - PRM-3575 need types
  { key: PDCreditCardType.mastercard, value: PDCreditCardType.mastercard, message: null as any }, // TODO - PRM-3575 need types
  { key: PDCreditCardType.discover, value: PDCreditCardType.discover, message: null as any }, // TODO - PRM-3575 need types
]);

export enum CreditCardItems {
  CardNumber = 'CARD_NUMBER',
  CardExpired = 'CARD_EXPIRED',
  CardCVV = 'CARD_CVV',
}

export interface IAddCreditCard {
  cardNumber: string;
  cardHolderName: string;
  expiredIn: string;
  code: string;
}
