import { combineReducers } from 'redux-immutable';
import user, { STATE_KEY as USER_STATE_KEY } from 'glacial/modules/user';
import person, { STATE_KEY as PERSON_STATE_KEY } from 'glacial/modules/person';
import client, { STATE_KEY as CLIENT_STATE_KEY } from 'glacial/modules/client';
import clientPortalAuthenticationReducer from 'modules/authentication/state/reducers';
import memberPortalAuthenticationReducer from 'memberPortalModules/authentication/state/reducers';
import dictionary from 'common/state/dictionary/reducers';
import { queryPageListReducer } from 'common/state/queryPage-lists/reducers';
import invoiceReducer from 'common/state/invoice/reducers';
import { uploadImageReducer } from 'common/state/uploadImage/reducers';
import { uploadFileReducer } from 'common/state/uploadFile/reducers';
import personProfileReducer from 'common/components/PersonProfile/state/reducers';
import { rootBookingModuleReducer } from 'modules/booking/state';
import { crmModuleReducer } from 'modules/crm/state';
import employeesModule from 'modules/employees/state/reducers';
import peakEmployeesModule from 'modules/peakEmployees/state/reducers';
import membersModule from 'modules/members/state/reducers';
import myProfile from 'modules/my-profile/state/index';
import frontDesk from 'modules/front-desk/state/reducers';
import reports from 'modules/reports/state';
import posSettings from 'modules/pos-settings/state';
import services from 'modules/services/state';
import posKiosk from 'modules/pos-kiosk/state';
import dictionaries from 'modules/dictionaries/state';
import notifications from 'common/state/notifications/reducers';
import settings from 'common/state/settings/reducers';
import permissions from 'modules/permissions/state';
import corporations from 'modules/corporations/state/reducers';
import clubs from 'modules/clubs/state/reducers';
import alerts from 'common/state/alerts/reducers';
import newPerson from 'common/state/newPerson';
import globalSearch from 'common/state/globalSearch/reducers';
import peakContactsReducer from 'common/state/peakContacts/reducers';
import { kioskModeReducer } from 'common/state/kioskMode/reducers';
import kidZone from 'modules/kid-zone/state/reducers';
import peakSettings from 'modules/peak-settings/state/reducers';
import { printHTMLReducer } from 'common/state/printHTML/reducers';
import timeclock from 'modules/timeclock/state/reducers';
import { modalsReducer } from 'common/state/modals/reducers';
import { memberPortalProfileReducer } from 'memberPortalModules/MemberProfile/state';
import { userRoleReducer } from 'common/state/userRole/reducer';
import communicationReducer from 'modules/communication/state/reducers';
import daySheetReducer from 'modules/day-sheet/state';
import permissionReducer from 'common/state/permission/reducer';
import { memberPortalBookingReducer } from 'memberPortalModules/booking/state/reducers';

const rootReducer = combineReducers({
  userRole: userRoleReducer,
  clientPortalAuthentication: clientPortalAuthenticationReducer,
  memberPortalAuthentication: memberPortalAuthenticationReducer,
  memberPortalProfile: memberPortalProfileReducer,
  memberPortalBooking: memberPortalBookingReducer,
  [USER_STATE_KEY]: user,
  [PERSON_STATE_KEY]: person,
  [CLIENT_STATE_KEY]: client,
  dictionary,
  queryPageList: queryPageListReducer,
  invoice: invoiceReducer,
  uploadImage: uploadImageReducer,
  uploadFile: uploadFileReducer,
  persons: personProfileReducer,
  bookingModule: rootBookingModuleReducer,
  crmModule: crmModuleReducer,
  newPerson,
  employeesModule,
  peakEmployeesModule,
  membersModule,
  frontDesk,
  reports,
  posSettings,
  services,
  posKiosk,
  dictionaries,
  notifications,
  settings,
  permissions,
  corporations,
  clubs,
  alerts,
  globalSearch,
  peakContactsReducer,
  kioskMode: kioskModeReducer,
  modals: modalsReducer,
  kidZone,
  myProfile,
  peakSettings,
  printHTML: printHTMLReducer,
  timeclock,
  communication: communicationReducer,
  daySheet: daySheetReducer,
  permission: permissionReducer,
});

export default rootReducer;
