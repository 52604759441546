import React from 'react';
import { Grid, Button, Typography, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as PaperIcon } from 'img/icons/big-paper.svg';

import { ReactComponent as TrashIcon } from 'img/icons/trash_deprecated.svg';
import commonMessages from 'common/messages/messages';
import { checkingSavingsOptions } from 'common/components/PersonProfile/constants';
import { IPaymentAccountImt } from '../../interfaces';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    lineHeight: 1,
    opacity: 0.7,
  },
  titleValue: {
    marginTop: 4,
    lineHeight: 1,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  container: {
    marginTop: theme.spacing(1.5),
  },
  btnRemove: {
    height: '24px',
    padding: '4px',
    '& span': {
      width: '16px',
      height: '16px',
    },
    '& svg': {
      margin: '0 -1px 0 0',
    },
  },
  ownerNameBlock: {
    display: 'flex',
  },
  paperIconWrapper: {
    marginRight: theme.spacing(1.5),
  },
}));

interface IProps {
  item: IPaymentAccountImt;
  onDelete: () => void;
  hideDeleteBtn?: boolean;
}

const CheckingSavingsItem = ({ item, onDelete, hideDeleteBtn }: IProps) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid container xs={3}>
        <Box className={classes.ownerNameBlock}>
          <Box className={classes.paperIconWrapper}>
            <PaperIcon />
          </Box>
          <Box>
            <Typography variant="body2" component="p" className={classes.title}>
              <FormattedMessage {...commonMessages.ownerNameLabel} />
            </Typography>
            <Typography component="p" className={classes.titleValue}>
              {item.get('ownerName')}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={hideDeleteBtn ? 3 : 2}>
        <Typography variant="body2" component="p" className={classes.title}>
          <FormattedMessage {...commonMessages.routingNumberLabel} />
        </Typography>
        <Typography component="p" className={classes.titleValue}>
          {item.get('routingNumber')}
        </Typography>
      </Grid>
      <Grid item xs={hideDeleteBtn ? 3 : 2}>
        <Typography variant="body2" component="p" className={classes.title}>
          <FormattedMessage {...commonMessages.accountNumberLabel} />
        </Typography>
        <Typography component="p" className={classes.titleValue}>
          {item.get('accountNumber')}
        </Typography>
      </Grid>
      {/* TODO: Further use is possible  */}
      {/* <Grid item xs={2}> */}
      {/*  <Typography variant="body2" component="p" className={classes.title}> */}
      {/*    <FormattedMessage {...commonMessages.checkNumberLabel} /> */}
      {/*  </Typography> */}
      {/*  <Typography component="p" className={classes.titleValue}> */}
      {/*    <>-</> */}
      {/*  </Typography> */}
      {/* </Grid> */}
      <Grid item xs={hideDeleteBtn ? 3 : 2}>
        <Typography variant="body2" component="p" className={classes.title}>
          <FormattedMessage {...commonMessages.typeLabel} />
        </Typography>
        <Typography component="p" className={classes.titleValue}>
          {checkingSavingsOptions.translate(item.get('checkType'))}
        </Typography>
      </Grid>
      {hideDeleteBtn || (
        <Grid item xs={3} className={classes.iconWrapper}>
          <Button className={classes.btnRemove} color="secondary" size="small" onClick={onDelete}>
            <TrashIcon />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default CheckingSavingsItem;
