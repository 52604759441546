import React, { ReactNode } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ButtonWithCircularProgress, ScrollBox } from 'common/components';
import messages from 'common/messages/messages';
import useShowAlert from 'common/hooks/useShowAlert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardContent: {
      overflow: 'hidden',
      padding: ({ spacing }: IProps) => theme.spacing(spacing || 1, 2),
      '&:last-child': {
        padding: ({ spacing }: IProps) => theme.spacing(spacing || 1, 2),
      },
    },
    bodyWrapper: {
      flex: 1,
      minHeight: 0,
    },
  }),
);

interface IProps {
  title?: React.ReactNode;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  maxWidth?: number | string;
  spacing?: number;
  children?: ReactNode;
  isDirty?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
}

const FormContainer: React.FunctionComponent<IProps> = (props: IProps): JSX.Element => {
  const {
    title,
    isDisabled,
    isSubmitting,
    maxWidth,
    children,
    isDirty,
    onSubmit,
    onCancel = () => {},
  } = props;

  const handleCancel = useShowAlert(!!isDirty, onCancel);
  const classes = useStyles(props);
  return (
    <Card
      className={classes.card}
      style={{
        maxWidth: maxWidth || 'initial',
      }}
    >
      <Box>
        <Box p={2}>
          <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item>
              <Typography component="h1" variant="h2">
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={classes.bodyWrapper}>
        <ScrollBox hasShadowsOnScroll>
          <CardContent className={classes.cardContent}>{children}</CardContent>
        </ScrollBox>
      </Box>
      <Box>
        <Box p={2}>
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button color="primary" onClick={handleCancel}>
                <FormattedMessage {...messages.cancelBtn} />
              </Button>
            </Grid>

            <Grid item>
              <ButtonWithCircularProgress
                disabled={isDisabled || isSubmitting}
                type="submit"
                onClick={onSubmit}
                isSubmitting={!!isSubmitting}
              >
                <FormattedMessage {...messages.saveBtn} />
              </ButtonWithCircularProgress>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
};

export default FormContainer;
