import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

import { StepContext } from 'common/createContext/stepContext';

import messages from 'common/messages/messages';

interface IProps {
  reportId: string;
}

const LinkToReportStep = ({ reportId }: IProps): JSX.Element => {
  const { onBack, renderFooter, onNext } = useContext(StepContext);

  return (
    <>
      <Link to={`/reports/z-out/${reportId}`}>
        <Typography color="primary" component="span">
          <FormattedMessage {...messages.linkToReportTitle} />
        </Typography>
      </Link>

      {renderFooter ? renderFooter(onBack, onNext) : null}
    </>
  );
};

export default LinkToReportStep;
