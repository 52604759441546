import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
import messages from 'modules/services/messages/messages';
import { IMembershipSection } from '../../../../interfaces/packages';
import InitialFee from 'modules/services/components/EditPackageForm/MembershipStep/ConditionsForPurchaseSelector/InitialFee';

const useStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    opacity: '0.3',
  },
}));

interface IConditionsForPurchaseSelectorProps {
  formMethods: UseFormReturn<IMembershipSection>;
}

const ConditionsForPurchaseSelector = ({
  formMethods,
}: IConditionsForPurchaseSelectorProps): JSX.Element => {
  const classes = useStyles();
  const {
    control,
    watch,
    trigger,
    formState: { errors },
  } = formMethods;

  const { fields } = useFieldArray({
    control,
    name: `initialFees`,
    keyName: 'key',
  });

  const initialFeesWatch = watch('initialFees');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...initialFeesWatch[index],
    };
  });

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.sectionTitle}>
            <FormattedMessage {...messages.conditionsForPurchaseTitle} />
          </Typography>
        </Grid>
        {controlledFields.map((initialFee, index) => (
          <InitialFee
            key={initialFee.key}
            control={control}
            initialFee={initialFee}
            index={index}
            errors={errors}
            trigger={trigger}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default ConditionsForPurchaseSelector;
