import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MessageDescriptor } from 'react-intl';
import moment, { Moment } from 'moment-timezone';
import { Grid } from '@material-ui/core';
import { DatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentAdapter from '@date-io/moment';
import { GridSize, GridSpacing } from '@material-ui/core/Grid/Grid';

import { TimePicker } from 'common/components/index';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import inputLabels from 'common/messages/inputLabels';
import { getAccessByPropPath } from 'common/utils/errorObject';
import DatePickerLayer from './DatePickerLayer';
import { getHoursAndMinutesFromDate } from 'common/utils/time';

type OnChangeType = {
  onChange: (date: string | null) => void;
};

interface IProps {
  disablePast?: boolean;
  spacing?: GridSpacing;
  dateFieldSize?: GridSize;
  timeFieldSize?: GridSize;
  dateLabelMessageDescriptor?: MessageDescriptor;
  timeLabelMessageDescriptor?: MessageDescriptor;
  timePickerName?: string;
  datePickerName?: string;
  timePickerProps?: Partial<DatePickerProps & OnChangeType>;
  datePickerProps?: Partial<DatePickerProps & OnChangeType>;
  smallTimePicker?: boolean;
  smallDatePicker?: boolean;
}

const convertToFullDateFormat = (date: string, time: string) => {
  const isValidDate = moment(date, 'YYYY-MM-DD', true).isValid();
  const isValidTime = moment(time, 'HH:mm', true).isValid();
  let convertedDate: string | null;

  if (isValidDate && isValidTime) {
    convertedDate = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm')
      .utc(true)
      .format();
  } else if (isValidTime) {
    convertedDate = moment(time, 'HH:mm')
      .utc(true)
      .format();
  } else {
    convertedDate = null;
  }

  return convertedDate;
};

const DateTimePicker = ({
  disablePast,
  spacing,
  dateFieldSize,
  timeFieldSize,
  timePickerName = 'time',
  datePickerName = 'date',
  dateLabelMessageDescriptor,
  timeLabelMessageDescriptor,
  datePickerProps,
  timePickerProps,
  smallDatePicker,
  smallTimePicker,
}: IProps): JSX.Element => {
  const renderErrorMessage = useRenderIntlMessage();
  const { trigger, formState, watch, setValue } = useFormContext();
  const { errors } = formState;

  const timePickerValue = watch(timePickerName);
  const datePickerValue = watch(datePickerName);

  const hasTimeError = Boolean(getAccessByPropPath(errors, timePickerName));
  const hasDateError = Boolean(getAccessByPropPath(errors, datePickerName));

  const timeHelperText = renderErrorMessage(getAccessByPropPath(errors, timePickerName)?.message);
  const dateHelperText = renderErrorMessage(getAccessByPropPath(errors, datePickerName)?.message);

  return (
    <Grid container spacing={spacing}>
      <Grid item xs={dateFieldSize}>
        <MuiPickersUtilsProvider utils={MomentAdapter}>
          <Controller
            name={datePickerName}
            defaultValue={null}
            render={({ field }) => {
              return (
                <DatePickerLayer
                  name={datePickerName}
                  timePickerValue={timePickerValue}
                  datePickerProps={datePickerProps}
                  setValue={setValue}
                  hasDateError={hasDateError}
                  value={field.value}
                  onChange={date => {
                    let datePickerVal: string | null;
                    const isValidTimePickerValue = moment(timePickerValue, 'HH:mm', true).isValid();
                    const isValidDate = moment(date).isValid();

                    if (isValidDate && isValidTimePickerValue) {
                      const timeWithTimezone = moment
                        .utc(timePickerValue, 'HH:mm')
                        .tz((moment as any).defaultZone.name)
                        .format('HH:mm');

                      datePickerVal = moment(`${date} ${timeWithTimezone}`, 'YYYY-MM-DD HH:mm')
                        .utc()
                        .format('YYYY-MM-DD');
                    } else if (isValidDate) {
                      datePickerVal = date;
                    } else {
                      datePickerVal = null;
                    }

                    field.onChange(datePickerVal);
                    void trigger(timePickerName);
                  }}
                  onBlur={field.onBlur}
                  dateHelperText={dateHelperText || ''}
                  dateLabelMessageDescriptor={dateLabelMessageDescriptor}
                  smallDatePicker={smallDatePicker}
                  disablePast={disablePast}
                />
              );
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={timeFieldSize}>
        <Controller
          name={timePickerName}
          defaultValue={null}
          render={({ field }) => {
            const convertedDate = convertToFullDateFormat(datePickerValue, field.value);
            return (
              <TimePicker
                {...timePickerProps}
                name={field.name}
                small={smallTimePicker}
                value={convertedDate || ''}
                error={hasTimeError}
                helperText={timeHelperText}
                onBlur={field.onBlur}
                onChange={(val: string | null, sourceDate) => {
                  let timePickerUTCDate: Moment | null;
                  const isValidTimePickerValue = moment(val).isValid();
                  const isValidDate = moment(datePickerValue).isValid();

                  if (isValidDate && isValidTimePickerValue && !timePickerValue) {
                    const { hours, minutes } = getHoursAndMinutesFromDate(sourceDate as any); // TODO - PRM-3575 need fix

                    timePickerUTCDate = moment(datePickerValue, 'YYYY-MM-DD')
                      .set({ hours, minutes })
                      .utc();
                  } else if (isValidTimePickerValue) {
                    timePickerUTCDate = moment.utc(val);
                  } else {
                    timePickerUTCDate = null;
                  }

                  const [dateVal, timeVal] = timePickerUTCDate
                    ? timePickerUTCDate.format('YYYY-MM-DD HH:mm').split(' ')
                    : [null, null];

                  if (dateVal && datePickerValue) {
                    setValue(datePickerName, dateVal);
                  }

                  field.onChange(timeVal);

                  if (timePickerProps?.onChange) {
                    timePickerProps.onChange(timeVal);
                  }

                  void trigger(datePickerName);
                }}
                labelMessageDescriptor={timeLabelMessageDescriptor}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

DateTimePicker.defaultProps = {
  spacing: 1,
  dateFieldSize: 6,
  timeFieldSize: 6,
  dateLabelMessageDescriptor: inputLabels.date,
  timeLabelMessageDescriptor: inputLabels.time,
};

export default DateTimePicker;
