import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';

import { fetchCheckInsHistory } from 'modules/kid-zone/state/actions';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';
import {
  selectCheckInsHistory,
  selectCheckInsHistoryLoading,
  selectCheckInsHistoryMeta,
} from 'modules/kid-zone/state/selectors';

import { RouteBackground } from 'components';
import { KidZoneCheckInsHistoryTable } from 'common/components/PersonProfile/components';

import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
import { IKidZoneCheckInHistoryImt } from 'common/components/PersonProfile/interfaces';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { ITableParams } from 'common/interfaces/table';

import { useAppDispatch } from 'store/hooks';
import { makeTableParams, pushQueryToUrl } from 'common/utils/http';

import tableFilters from 'common/messages/tableFilters';
import commonMessages from 'common/messages/messages';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { KidZonePaths } from 'modules/kid-zone/constants';
import { TableOrderByParams } from 'common/constants';

const CheckInsHistory = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const checkInsHistory: ImmutableList<IKidZoneCheckInHistoryImt> = useRootSelector(
    selectCheckInsHistory,
  );
  const checkInsHistoryMeta: IPageMetaImt = useRootSelector(selectCheckInsHistoryMeta);
  const isCheckInsHistoryLoading: boolean = useRootSelector(selectCheckInsHistoryLoading);
  const selectedClubId: string = useRootSelector(selectUserSelectedClubId);

  const selectedClubIdRef = useRef<string | null>(null);
  selectedClubIdRef.current = selectedClubId;

  const navigate = useNavigate();
  const location = useLocation();

  const tableFilterSettings: IFilterSettings[] = useMemo(
    () => [
      {
        name: '',
        title: <FormattedMessage {...tableFilters.period} />,
        type: FilterTypes.DATE_RANGE,
        options: {
          startDate: null,
          endDate: null,
        },
      },
    ],
    [],
  );

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilterSettings, location.search, {
      orderBy: TableOrderByParams.DATE,
      order: 'desc',
    }),
  );

  useEffect(() => {
    dispatch(fetchCheckInsHistory(tableParams, selectedClubIdRef.current || undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useComponentDidUpdate(() => {
    navigate(KidZonePaths.KID_ZONE);
  }, [selectedClubId, navigate]);

  const handleChangeTableProps = useCallback(
    (tableProps: ITableParams): void => {
      setTableParams(tableProps);
      dispatch(fetchCheckInsHistory(tableProps, selectedClubIdRef.current || undefined));
      pushQueryToUrl(navigate, tableProps);
    },
    [dispatch, navigate],
  );

  return (
    <RouteBackground>
      <KidZoneCheckInsHistoryTable
        title={<FormattedMessage {...commonMessages.historyBtn} />}
        tableParams={tableParams}
        backRedirectLink="/kid-zone"
        checkInsHistory={checkInsHistory}
        isCheckInsHistoryLoading={isCheckInsHistoryLoading}
        onChangeTableParams={handleChangeTableProps}
        checkInsHistoryMeta={checkInsHistoryMeta}
        tableFilterSettings={tableFilterSettings}
        isCommonCheckInsHistory
      />
    </RouteBackground>
  );
};

export default CheckInsHistory;
