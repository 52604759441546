import React, { useCallback, useMemo } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import { selectSearchResults } from 'common/state/globalSearch/selectors';
import { selectCurrentUserSelectedLevel } from 'modules/authentication/state/selectors';

import {
  IClubsSearchResult,
  ICustomersSearchResult,
  IEmployeesSearchResult,
  IGlobalSearchResult,
  IGlobalSearchResultImt,
  IInventoriesSearchResult,
} from 'common/interfaces/search';

import { IGroupTotalResultsCount } from 'components/SearchContainer/SearchContainer';

import { SearchContext } from 'components/SearchContainer/searchContext';

import { PermissionLevels } from 'common/constants/permissions';

import { GlobalSearchGroups, GlobalSearchResultGroups } from 'components/GlobalSearch/constants';
import { SearchContainer } from 'components/index';
import { SearchResultItem, SearchResultSection } from 'components/SearchContainer/components';
import { GuestStatus } from 'modules/front-desk/components';
import { ReactComponent as DefaultIcon } from 'img/icons/default-icon.svg';
import { ReactComponent as ClubDefaultIcon } from 'img/icons/club-default-icon.svg';
import { INamedEntity } from 'common/interfaces/common';

import inputLabels from 'common/messages/inputLabels';
import clubsMessages from 'modules/clubs/messages';
import employeesMessages from 'common/messages/employeesMessages';
import posSettingsMessages from 'modules/pos-settings/messages/messages';
import membersMessages from 'modules/members/messages';
import corporationsMessages from 'modules/corporations/messages';

const useStyles = makeStyles((theme: Theme) => ({
  guestStatus: {
    margin: 0,
  },
  clubLabel: {
    fontSize: '0.75rem',
    marginTop: theme.spacing(0.5),
  },
  addressText: {
    lineHeight: '1',
    opacity: 1,
  },
}));

interface IProps {
  anchorEl: null | Element;
  onClose: () => void;
}

const transformSearchResults = (results: IGlobalSearchResult): IGroupTotalResultsCount => {
  const { total, ...groups } = results;

  return {
    groups: { ...groups },
    total,
  };
};

const GlobalSearch = ({ anchorEl, onClose }: IProps): JSX.Element => {
  const searchResult: IGlobalSearchResultImt = useRootSelector(selectSearchResults);
  const selectedPermissionsLevel = useRootSelector(selectCurrentUserSelectedLevel);
  const isPeakLevel = selectedPermissionsLevel === PermissionLevels.PEAK;

  const convertedSearchResult = useMemo(() => searchResult.toJS(), [searchResult]);

  const classes = useStyles();

  const getViewAllPath = useCallback(
    (groupResultsCount: number, searchStr: string, path: string) =>
      groupResultsCount > 10 ? `${path}?searchStr=${searchStr}` : null,
    [],
  );

  const totalCustomersCount = convertedSearchResult.customers?.total;
  const totalEmployeesCount = convertedSearchResult.employees?.total;
  const totalInventoriesCount = convertedSearchResult.inventories?.total;
  const totalClubsCount = convertedSearchResult.clubs?.total;
  const totalCorporationsCount = convertedSearchResult.corporations?.total;
  const prefixRoute = isPeakLevel ? 'peak-' : '';

  return (
    <SearchContainer
      anchorEl={anchorEl}
      title={<FormattedMessage {...inputLabels.search} />}
      onClose={onClose}
      searchGroupsLabels={GlobalSearchResultGroups.values}
      searchGroupsResults={transformSearchResults(convertedSearchResult)}
      searchEntity="global"
      isPeakLevel={isPeakLevel}
    >
      <SearchContext.Consumer>
        {({ search }) => (
          <>
            {!!convertedSearchResult.total && (
              <>
                {!!totalCustomersCount && (
                  <SearchResultSection
                    sectionId={GlobalSearchGroups.Customers}
                    viewAllPath={
                      getViewAllPath(totalCustomersCount, search, '/members') || undefined
                    }
                    sectionTitle={<FormattedMessage {...membersMessages.membersTitle} />}
                  >
                    {convertedSearchResult.customers.data.map(
                      ({ id, title, type, club, membership, imageUrl }: ICustomersSearchResult) => (
                        <SearchResultItem
                          key={id}
                          label={title}
                          imageUrl={imageUrl}
                          viewDetailsPath={`/members/${id}`}
                          description={<GuestStatus type={type} className={classes.guestStatus} />}
                          additionalElement={
                            <>
                              {club && (
                                <Box display="flex" flexDirection="column" alignItems="flex-end">
                                  <Typography>{club}</Typography>
                                  {membership && (
                                    <Typography color="textSecondary" className={classes.clubLabel}>
                                      {membership}
                                    </Typography>
                                  )}
                                </Box>
                              )}
                            </>
                          }
                        />
                      ),
                    )}
                  </SearchResultSection>
                )}

                {!!totalEmployeesCount && (
                  <SearchResultSection
                    sectionId={GlobalSearchGroups.Employees}
                    viewAllPath={
                      getViewAllPath(totalEmployeesCount, search, `/${prefixRoute}employees`) ||
                      undefined
                    }
                    sectionTitle={<FormattedMessage {...employeesMessages.employeesTitle} />}
                  >
                    {convertedSearchResult.employees.data.map(
                      ({ id, title, imageUrl, employeeId }: IEmployeesSearchResult) => (
                        <SearchResultItem
                          key={id}
                          label={title}
                          imageUrl={imageUrl}
                          viewDetailsPath={`/${prefixRoute}employees/${id}`}
                          description={String(employeeId)}
                        />
                      ),
                    )}
                  </SearchResultSection>
                )}

                {!!totalCorporationsCount && (
                  <SearchResultSection
                    sectionId={GlobalSearchGroups.Corporations}
                    viewAllPath={
                      getViewAllPath(totalCorporationsCount, search, '/corporations') || undefined
                    }
                    sectionTitle={<FormattedMessage {...corporationsMessages.corporationsTitle} />}
                  >
                    {convertedSearchResult.corporations.data.map(
                      ({ id, title, imageUrl }: INamedEntity) => (
                        <SearchResultItem
                          key={id}
                          label={title}
                          imageUrl={imageUrl}
                          defaultIcon={<DefaultIcon />}
                          viewDetailsPath={`/corporations/${id}/edit`}
                        />
                      ),
                    )}
                  </SearchResultSection>
                )}

                {!!totalInventoriesCount && (
                  <SearchResultSection
                    sectionId={GlobalSearchGroups.Inventories}
                    viewAllPath={
                      getViewAllPath(
                        totalInventoriesCount,
                        search,
                        '/pos-settings/inventory-items',
                      ) || undefined
                    }
                    sectionTitle={<FormattedMessage {...posSettingsMessages.inventory} />}
                  >
                    {convertedSearchResult.inventories.data.map(
                      ({ id, title, imageUrl, barcode }: IInventoriesSearchResult) => (
                        <SearchResultItem
                          key={id}
                          viewDetailsPath={`/pos-settings/inventory-items/${id}/configuration`}
                          label={title}
                          imageUrl={imageUrl}
                          defaultIcon={<DefaultIcon />}
                          description={barcode}
                        />
                      ),
                    )}
                  </SearchResultSection>
                )}

                {!!totalClubsCount && (
                  <SearchResultSection
                    sectionId={GlobalSearchGroups.Clubs}
                    viewAllPath={getViewAllPath(totalClubsCount, search, '/clubs') || undefined}
                    sectionTitle={<FormattedMessage {...clubsMessages.clubsTitle} />}
                  >
                    {convertedSearchResult.clubs.data.map(
                      ({ id, title, addressLine, imageUrl }: IClubsSearchResult) => (
                        <SearchResultItem
                          key={id}
                          label={title}
                          imageUrl={imageUrl}
                          viewDetailsPath={`/clubs/${id}/bank-info`}
                          defaultIcon={<ClubDefaultIcon />}
                          description={
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              className={classes.addressText}
                            >
                              {addressLine}
                            </Typography>
                          }
                        />
                      ),
                    )}
                  </SearchResultSection>
                )}
              </>
            )}
          </>
        )}
      </SearchContext.Consumer>
    </SearchContainer>
  );
};

export default GlobalSearch;
