import React, { useEffect, useState } from 'react';
import {
  Paper,
  InputBase,
  Tooltip,
  IconButton,
  makeStyles,
  Theme,
  SvgIcon,
} from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'img/closeIcon.svg';
import { Search as SearchIcon } from '@material-ui/icons';
import cx from 'classnames';
import useDebounce from 'common/hooks/useDebounce';

const useStyles = makeStyles((theme: Theme) => ({
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    border: '1px solid transparent',
    height: 32,
    boxShadow: theme.shadows[3],

    '&:hover': {
      boxShadow: theme.shadows[4],
    },
  },
  searchContainerWithBg: {
    boxShadow: 'none',
    backgroundColor: theme.palette.secondary.light,
  },
  activeSearchInput: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
  },
  searchButton: {
    padding: theme.spacing(0.75, 0.5),
    marginLeft: theme.spacing(0.5),
  },
  searchIcon: {
    width: '20px',
    height: '20px',
    color: theme.palette.text.secondary,
  },
  searchInputRoot: {
    width: '100%',
    paddingRight: theme.spacing(4.5),
    backgroundColor: 'transparent',
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      // eslint-disable-next-line no-useless-computed-key
      ['-webkit-appearance']: 'none',
      margin: 0,
    },
    '& input::placeholder': {
      opacity: 1,
      color: theme.palette.text.secondary,
    },
  },
  searchInput: {
    fontSize: '0.865rem',
  },
  clearButton: {
    fontSize: '0.625rem',
    padding: 2,
    borderRadius: '50%',
    backgroundColor: theme.palette.darkBackground?.main,
    position: 'absolute',
    right: 5,
    top: '50%',
    transform: 'translateY(-50%)',
    '&:hover': {
      backgroundColor: theme.palette.darkBackground?.main,
    },
  },
  clearIcon: {
    color: theme.palette.primary.contrastText,
  },
}));

interface IProps {
  placeholder: string;
  onChange: (value: string) => void;
  hasBackground?: boolean;
  hasActiveBorder?: boolean;
  value: string;
  inputType?: string;
}

export default function SearchInput(props: IProps): JSX.Element {
  const classes = useStyles();
  const {
    placeholder,
    onChange,
    value,
    hasBackground,
    hasActiveBorder = true,
    inputType = 'text',
  } = props;
  const [searchTerm, setSearchTerm] = useState<string>(value);
  const [isFocused, setIsFocused] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (searchTerm !== value) onChange(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, onChange]);

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  const onSetIsFocused = (param: boolean) => {
    if (hasActiveBorder) {
      setIsFocused(param);
    }
  };

  const onKeyDown = e => {
    if (inputType === 'number' && ['e', 'E', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Paper
      className={cx(classes.searchContainer, {
        [classes.activeSearchInput]: hasActiveBorder && isFocused,
        [classes.searchContainerWithBg]: hasBackground,
      })}
      elevation={2}
      variant="outlined"
    >
      <IconButton
        disabled
        color="secondary"
        className={classes.searchButton}
        onClick={() => setSearchTerm('')}
      >
        <SearchIcon className={classes.searchIcon} />
      </IconButton>
      <InputBase
        placeholder={placeholder}
        onFocus={() => onSetIsFocused(true)}
        onBlur={() => onSetIsFocused(false)}
        classes={{
          root: classes.searchInputRoot,
          input: classes.searchInput,
        }}
        type={inputType}
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        onKeyDown={onKeyDown}
      />
      {searchTerm.length > 0 && (
        <Tooltip title="Clear search">
          <IconButton className={classes.clearButton} onClick={() => setSearchTerm('')}>
            <SvgIcon component={CloseIcon} className={classes.clearIcon} />
          </IconButton>
        </Tooltip>
      )}
    </Paper>
  );
}
