import axios from 'axios';

import tokenService from 'services/auth/TokenService';
import Services from '../network';
import TokenService from './TokenService';
import { SIGN_IN_PATH } from 'common/constants/globalConstants';

import { ServerError } from 'common/errors/serverErrors';
import qs from 'qs';
import store from 'store';
import AppService from 'services/application/AppService';
import { resetUserRole } from 'common/state/userRole/actions';

let isRefreshingNew = false;
let refreshingCall;
const refreshToken = () => {
  if (isRefreshingNew && refreshingCall) {
    return refreshingCall;
  }
  isRefreshingNew = true;

  let userData;
  try {
    const tokenData = TokenService.getTokenData(tokenService.getRefreshToken());
    userData = JSON.parse(tokenData?.userData);
  } catch (error) {
    userData = null;
  }

  const isMemberPortal = Boolean(userData?.memberPortal);

  const AuthenticationService = isMemberPortal
    ? Services.MemberPortalAuthentication
    : Services.Authentication;

  const refreshCall = AuthenticationService.refreshToken(tokenService.getRefreshToken()).then(
    updatedToken => {
      tokenService.setAccessToken(updatedToken.accessToken);
      tokenService.setRefreshToken(updatedToken.refreshToken);
      isRefreshingNew = false;
      refreshingCall = undefined;
      return Promise.resolve(updatedToken);
    },
  );
  refreshingCall = refreshCall;
  return refreshCall;
};

/*
This service is working with axios global instance
 */
export default class AxiosService {
  public static initiate = (): void => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL; // the prefix of the URL
    axios.defaults.headers.get.Accept = 'application/json'; // default header for all get request
    axios.defaults.headers.post.Accept = 'application/json';
    axios.defaults.headers.put['Content-Type'] = 'application/json';
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.common['ngrok-skip-browser-warning'] = 'true';

    axios.interceptors.request.use(config => {
      const newConfig = { ...config };
      newConfig.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'brackets' });
      newConfig.headers.Authorization = TokenService.getAccessToken();

      return newConfig;
    });

    axios.interceptors.response.use(
      AxiosService.successResponseHandler,
      AxiosService.errorResponseHandler,
    );
  };

  private static successResponseHandler = response => response.data;

  private static errorResponseHandler = async error => {
    // logging
    AxiosService.logIfDev(error);

    if (!error.response) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ codes: [ServerError.SERVER_NOT_RESPONDING] });
    }

    const { status, data } = error.response;
    const originalRequest = error.config;

    // refresh token logics
    if (
      status === 401 &&
      data.codes?.includes(ServerError.TOKEN_EXPIRED_ERROR) &&
      !originalRequest.retry
    ) {
      originalRequest.retry = true;

      if (tokenService.isTokenExpired(tokenService.getRefreshToken())) {
        AxiosService.logOutUser();
      } else {
        try {
          return refreshToken()
            .then(updatedToken => {
              originalRequest.headers.Authorization = updatedToken.accessToken;
              return axios(originalRequest);
            })
            .catch(catchError => {
              AxiosService.logIfDev(catchError);
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject({ codes: [ServerError.SOMETHING_WENT_WRONG] });
            });
        } catch (refreshError) {
          TokenService.clearTokens();
          return Promise.reject(refreshError.response.data);
        }
      }
    } else if (
      (status === 401 && data.codes?.includes(ServerError.WRONG_CREDENTIALS_ERROR)) ||
      data.codes?.includes(ServerError.EMPLOYEE_INACTIVE_ERROR)
    ) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ codes: [ServerError.WRONG_CREDENTIALS_ERROR] });
    } else if (
      status === 401 &&
      data.codes?.includes(ServerError.EMPLOYEE_HAVE_NOT_ACTIVE_CLUB_OR_CORPORATE_PERMISSIONS)
    ) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        codes: [ServerError.EMPLOYEE_HAVE_NOT_ACTIVE_CLUB_OR_CORPORATE_PERMISSIONS],
      });
    } else if (status === 401) {
      AxiosService.logOutUser();
    }

    if (!Array.isArray(data.codes) || !data.codes.length) {
      if (status === 400) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ codes: [ServerError.BAD_REQUEST] });
      }
      if (status === 404) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ codes: [ServerError.NOT_FOUND] });
      }
      if (status === 500) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ codes: [ServerError.INTERNAL_SERVER_ERROR] });
      }
      if (status === 504) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ codes: [ServerError.GATEWAY_TIMEOUT] });
      }
    }

    return Promise.reject(error.response.data);
  };

  private static logIfDev = (str: unknown) => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(str);
    }
  };

  private static logOutUser = () => {
    tokenService.clearTokens();
    AppService.clearAppSelectedRegisters();
    AppService.clearAppSelectedEntrance();

    store.dispatch(resetUserRole());
    window.location.href = SIGN_IN_PATH;
  };
}
