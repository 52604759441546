import isNumber from 'lodash/isNumber';

export function isOutOfStockProduct({
  stockTracking = false,
  onHandAmount,
}: {
  stockTracking?: boolean;
  onHandAmount?: number | null;
}): boolean {
  return stockTracking && isNumber(onHandAmount) && onHandAmount <= 0;
}
