import * as yup from 'yup';
import { getEmailErrorMessage, getRequiredErrorMessage } from 'common/utils/validation';

export const getMainLoginValidationSchema = (isCorporationRequired: boolean) =>
  yup.object().shape({
    username: yup
      .string()
      .trim()
      .email(getEmailErrorMessage)
      .required(getRequiredErrorMessage),
    corporation: isCorporationRequired
      ? yup
          .object()
          .nullable()
          .required(getRequiredErrorMessage)
      : yup.object().nullable(),
    password: yup
      .string()
      .trim()
      .when('corporation', {
        is: value => !!value,
        then: yup.string().required(getRequiredErrorMessage),
        otherwise: yup.string().notRequired(),
      }),
  });
