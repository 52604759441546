import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { useAppDispatch } from 'store/hooks';
import { DialogComponent } from 'common/components/index';
import TimeclockHistoryTable from './TimeclockHistoryTable';
import { selectCurrentUserAvailableClubs } from 'modules/authentication/state/selectors';
import { fetchDictionaryList, resetDictionaryListAction } from 'common/state/dictionary/actions';
import { getProfileTimeclockHistory } from 'modules/my-profile/state/profile/actions';
import { selectMyProfileTimeclockHistoryMeta } from 'modules/my-profile/state/profile/selectors';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { INamedEntityImt } from 'common/interfaces/common';
import { ITimeclockListItem } from 'modules/timeclock/interfaces/timeclock';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
import { ITableParams } from 'common/interfaces/table';
import { DictionaryList, TableOrderByParams } from 'common/constants';
import { payTypeLabels } from 'modules/employees/constants';
import tableFilters from 'common/messages/tableFilters';
import messages from 'common/components/EmployeeProfile/messages';
import tableHeaders from 'common/messages/tableHeaders';
import commonMessages from 'common/messages/messages';
import { makeTableParams } from 'common/utils/http';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';
import { selectDictionaryList } from 'common/state/dictionary/selectors';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  checkInsHistoryLoading?: boolean;
  timeclockHistory?: ITimeclockListItem[];
  isTimeclockHistoryLoading: boolean;
}

const TimeclockHistoryModal = ({
  isOpen,
  onClose,
  timeclockHistory,
  isTimeclockHistoryLoading,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const timeclockHistoryMeta: IPageMetaImt = useRootSelector(selectMyProfileTimeclockHistoryMeta);
  const departments: ImmutableList<IDictionaryCommonItem> = useRootSelector(
    selectDictionaryList(DictionaryList.DEPARTMENTS),
  );
  const clubs: ImmutableList<INamedEntityImt> = useRootSelector(selectCurrentUserAvailableClubs);

  const filterSettings: IFilterSettings[] = useMemo(
    () => [
      {
        title: <FormattedMessage {...tableHeaders.period} />,
        name: 'range',
        type: FilterTypes.DATE_RANGE,
        options: {
          startDate: null,
          endDate: null,
        },
      },
      {
        title: <FormattedMessage {...commonMessages.clubsLabel} />,
        name: 'clubIds',
        type: FilterTypes.MULTIPLE,
        options: clubs.toJS().map(club => ({
          key: club.id,
          label: club.title,
          value: club.id,
        })),
      },
      {
        name: 'departmentIds',
        title: <FormattedMessage {...tableFilters.department} />,
        type: FilterTypes.MULTIPLE,
        options: departments?.toJS().map(department => ({
          key: department.id,
          label: department.title,
          value: department.id,
        })),
      },
      {
        name: 'payTypes',
        title: <FormattedMessage {...tableFilters.payType} />,
        type: FilterTypes.MULTIPLE,
        options: payTypeLabels.getFilterOptions(),
      },
    ],
    [clubs, departments],
  );

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(filterSettings, null, {
      orderBy: TableOrderByParams.CLOCK_IN,
      order: 'desc',
    }),
  );

  useEffect(() => {
    dispatch(getProfileTimeclockHistory(tableParams));
  }, [dispatch, tableParams]);

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.DEPARTMENTS));
    return () => {
      dispatch(resetDictionaryListAction({ dictionary: DictionaryList.DEPARTMENTS }));
    };
  }, [dispatch]);

  const handleChangeTableProps = useCallback((tableProps: ITableParams): void => {
    setTableParams(tableProps);
  }, []);

  return (
    <DialogComponent
      title={<FormattedMessage {...messages.timeclockHistory} />}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      hideFooterButtons
    >
      <TimeclockHistoryTable
        timeclockHistory={timeclockHistory}
        timeclockHistoryMeta={timeclockHistoryMeta}
        isTimeclockHistoryLoading={isTimeclockHistoryLoading}
        onChangeTableParams={handleChangeTableProps}
        tableParams={tableParams}
        tableFilterSettings={filterSettings}
      />
    </DialogComponent>
  );
};

export default TimeclockHistoryModal;
