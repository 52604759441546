import React from 'react';
import useRootSelector from 'common/hooks/useRootSelector';

import { selectSelectedRegisterId } from 'modules/pos-kiosk/state/register/selectors';
import { selectInvoicesForRegisterList } from 'common/state/invoice/selectors';
import { selectPrimaryInfo } from 'common/state/newPerson/primaryInfo/selectors';
import { selectCreatedLead } from 'modules/crm/state/leads/selectors';

import { PaymentStep } from 'common/components';

import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { ILeadProfileImt } from 'modules/crm/interfaces/leads';

interface IPaymentStepContainerProps {
  module: PeakModuleForNewPersonType;
}

const PaymentStepContainer = (props: IPaymentStepContainerProps): JSX.Element => {
  const { module } = props;
  // const dispatch = useAppDispatch();
  // const selectedPermissionsLevel = useRootSelector(selectCurrentUserSelectedLevel);
  // const selectedOrg = useRootSelector(selectUserSelectedOrganization);
  // const selectedOrgId = selectedOrg?.get('id');
  const primaryInfo = useRootSelector(selectPrimaryInfo);
  const selectedRegisterId = useRootSelector(selectSelectedRegisterId);
  const currentRegisterInvoicesList = useRootSelector(selectInvoicesForRegisterList);
  const leadPrimaryInfo: ILeadProfileImt = useRootSelector(selectCreatedLead);

  // TODO need refine
  // reset selected register id for corp level user
  /* useEffect(() => {
    return () => {
      if (selectedPermissionsLevel === PermissionLevels.CORPORATE && selectedRegisterId) {
        dispatch(resetSelectedRegisterId(selectedOrgId));
      }
    };
  }, [selectedRegisterId, selectedOrgId, selectedPermissionsLevel, dispatch]); */

  return (
    <PaymentStep
      module={module}
      person={leadPrimaryInfo || primaryInfo}
      registerId={selectedRegisterId}
      currentRegisterInvoicesList={currentRegisterInvoicesList}
    />
  );
};

export default PaymentStepContainer;
