import { PoliciesPermissionSetItems, PermissionLevels } from 'common/constants/permissions';
import { IEmployeeRole } from 'common/interfaces/employee';
import {
  PermissionGroupsClubLevel,
  PermissionGroupsCorporateLevel,
  permissionsArray,
} from 'modules/permissions/constants';
import { IPermissionItem, IPolicyFormData } from 'modules/permissions/interfaces/permissions';
import { ISecurityPolicyDto } from '../../../common/interfaces/permissions';

export const filterPermissionSet = (data: IPolicyFormData): IPolicyFormData => {
  const { permissionSet } = data;

  const filteredPermissionSet = permissionSet.filter(permission =>
    Object.keys(PoliciesPermissionSetItems).includes(permission),
  );

  return {
    ...data,
    permissionSet: filteredPermissionSet,
  };
};

export const getOrderPolicyList = (
  roleItem: IEmployeeRole,
  permissionGroupItem: PermissionGroupsClubLevel | PermissionGroupsCorporateLevel,
): IEmployeeRole => {
  const { securityPolicyDtoSet = [] } = roleItem;
  const orderedPolicyList: ISecurityPolicyDto[] = [];

  for (const policy of Object.keys(permissionGroupItem)) {
    const item = securityPolicyDtoSet.find(({ permissionGroup }) => permissionGroup === policy);

    if (item) {
      orderedPolicyList.push(item);
    }
  }

  return {
    ...roleItem,
    securityPolicyDtoSet: orderedPolicyList,
  };
};

export const getPermissionsFilteredArray = (
  permissionLevel: PermissionLevels,
  filter?: boolean,
  permissionGoups?: Array<string>,
): Array<IPermissionItem> => {
  const permissions = permissionsArray[permissionLevel];

  if (!permissions) {
    return [];
  }

  return filter && permissionGoups
    ? Object.values(permissions)
        .flat()
        .filter((item: IPermissionItem) => permissionGoups.includes(item.key))
    : Object.values(permissions).flat();
};
