import React, { useEffect, useState } from 'react';
import isNumber from 'lodash/isNumber';
import useRootSelector from 'common/hooks/useRootSelector';
import { List as ImmutableList } from 'immutable';
import * as selectors from 'modules/kid-zone/state/selectors';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';
import * as actions from 'modules/kid-zone/state/actions';
import { useAppDispatch } from 'store/hooks';
import { ActionResult } from 'common/constants';
import { IKidProfileImt, IKidZoneAutocompleteCustomerImt } from 'modules/kid-zone/interfaces';
import { DaycareRedeemModal, GuardiansBlock } from 'modules/kid-zone/components';
import AddDateOfBirthdayModal from 'modules/kid-zone/containers/ManualCheckIn/components/AddDateOfBirthdayModal/AddDateOfBirthdayModal';
import { fetchKidProfileInfo } from 'modules/kid-zone/state/actions';

interface IProps {
  profile: IKidProfileImt;
  isSmallScreen: boolean;
  onCloseManualCheckinPanel: () => void;
}

const Guardians = ({ profile, isSmallScreen, onCloseManualCheckinPanel }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const rightPanelChildId = useRootSelector(selectors.selectKidZoneRightPanelPersonId);
  const selectedClubId: string = useRootSelector(selectUserSelectedClubId);
  const isCheckInActionLoading: boolean = useRootSelector(
    selectors.selectCheckInChildActionLoading,
  );
  const checkInActionResult: ActionResult = useRootSelector(
    selectors.selectCheckInChildActionResult,
  );
  const addDOBActionResult = useRootSelector(selectors.selectAddDOBActionResult);
  const isAddDOBActionLoading: boolean = useRootSelector(selectors.selectAddDOBActionLoading);

  const [addBirthdayModalData, setAddBirthdayModalData] = useState<{ id: number | null }>({
    id: null,
  });

  const isCheckInPanel = rightPanelChildId === profile.get('id') && rightPanelChildId;

  const guardians: ImmutableList<IKidZoneAutocompleteCustomerImt> = profile.get('guardians');
  const other: ImmutableList<IKidZoneAutocompleteCustomerImt> = profile.get('other');
  const profileId = profile.get('id');

  const [guardianId, setGuardianId] = useState<number | null>(null);

  useEffect(() => {
    if (checkInActionResult === ActionResult.SUCCESS_ACTION) {
      setGuardianId(null);
    }
  }, [checkInActionResult]);

  useEffect(() => {
    if (addDOBActionResult === ActionResult.SUCCESS_ACTION) {
      if (onCloseManualCheckinPanel && addBirthdayModalData.id) {
        onCloseManualCheckinPanel();
      }

      setAddBirthdayModalData({ id: null });

      if (profileId) {
        dispatch(fetchKidProfileInfo(profileId));
      }

      dispatch(actions.resetAddDateOfBirthActionResult());
    }
  }, [
    dispatch,
    addDOBActionResult,
    setAddBirthdayModalData,
    onCloseManualCheckinPanel,
    addBirthdayModalData.id,
    other,
    profileId,
  ]);

  const handleOpenRedeemModal = (personId: number) => {
    setGuardianId(personId);
  };

  const handleCheckInKid = (serviceId: string) => {
    if (profileId && guardianId) {
      dispatch(actions.checkInKid(profileId, guardianId, serviceId, selectedClubId));
    }
  };

  const handleCheckOutKid = (personId: number) => {
    if (profileId) {
      dispatch(actions.checkOutKid(profileId, personId));
    }
  };

  const addDateOfBirth = (date: string) => {
    if (isNumber(addBirthdayModalData.id)) {
      dispatch(actions.addDateOfBirth(addBirthdayModalData.id, null, date));
    }
  };

  const onAddBirthday = (id: number) => {
    setAddBirthdayModalData({ id });
  };

  return (
    <>
      <GuardiansBlock
        onAddBirthday={onAddBirthday}
        guardians={guardians}
        otherMembers={other}
        isSmallScreen={isSmallScreen}
        isCheckInPanel={!!isCheckInPanel}
        isActionLoading={isCheckInActionLoading}
        onCheckInKid={handleOpenRedeemModal}
        onCheckOutKid={handleCheckOutKid}
      />

      {!!guardianId && (
        <DaycareRedeemModal
          isOpen={!!guardianId}
          onClose={() => setGuardianId(null)}
          guardianId={guardianId}
          isRedeemActionLoading={isCheckInActionLoading}
          onSubmit={handleCheckInKid}
        />
      )}
      {addBirthdayModalData.id && (
        <AddDateOfBirthdayModal
          isOpen
          onClose={() => setAddBirthdayModalData({ id: null })}
          onSubmit={addDateOfBirth}
          isAddDOBActionLoading={isAddDOBActionLoading}
        />
      )}
    </>
  );
};

export default Guardians;
