import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { getQueueColor } from 'common/utils';
import {
  ChartLegendLabels,
  MemberCountChartFilters,
  MembershipMixChartFilters,
  ReportType,
  SalesDrivenChartFilters,
} from '../../../constants/overviewReport';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { ChartFilter } from 'modules/reports/components/index';

interface IOverviewChartLegendProps {
  reportType: ReportType;

  // TODO: replace with typing
  onChange?: (filters: string[]) => void;
}

const getReportLines = (reportType: ReportType): string[] => {
  switch (reportType) {
    case ReportType.SalesDriven:
      return Object.values(SalesDrivenChartFilters);
    case ReportType.MemberCount:
      return Object.values(MemberCountChartFilters);
    case ReportType.MembershipMix:
      return Object.values(MembershipMixChartFilters);
    default:
      return [];
  }
};

const OverviewChartLegend: React.FC<IOverviewChartLegendProps> = (
  props: IOverviewChartLegendProps,
): JSX.Element => {
  const { reportType, onChange } = props;
  const [filters, setFilters] = useState<string[]>(getReportLines(reportType));

  useEffect(() => {
    if (onChange) {
      onChange(filters);
    }
  }, [onChange, filters]);

  useEffect(() => setFilters(getReportLines(reportType)), [reportType]);

  const renderIntlMessage = useRenderIntlMessage();

  const handleFilterStateChange = (filterType: string): void => {
    if (filters.includes(filterType)) {
      setFilters(filters.filter(f => f !== filterType));
    } else {
      setFilters([...filters, filterType]);
    }
  };

  return (
    <Grid container spacing={3} justifyContent="flex-end">
      {getReportLines(reportType).map((type: string, index: number) => (
        <Grid item key={type}>
          <ChartFilter
            label={renderIntlMessage(ChartLegendLabels[type]) || ''}
            color={getQueueColor(index)}
            isSelected={filters.includes(type)}
            onChange={() => handleFilterStateChange(type)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default OverviewChartLegend;
