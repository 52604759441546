import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

import { StepContext } from 'common/createContext/stepContext';

import messages from 'modules/pos-kiosk/messages/messages';

interface IProps {
  emptyInvoiceCount: number;
}

const CloseRegisterConfirmationStep = ({ emptyInvoiceCount }: IProps): JSX.Element => {
  const { onBack, renderFooter, onNext } = useContext(StepContext);

  return (
    <>
      <Typography>
        <FormattedMessage
          {...messages.closeRegisterText}
          values={{ emptyInvoiceCount: emptyInvoiceCount ?? 0 }}
        />
      </Typography>

      {renderFooter ? renderFooter(onBack, onNext) : null}
    </>
  );
};

export default CloseRegisterConfirmationStep;
