import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import DayTimeAvailabilitySection from './DayTimeAvailabilitySection';
import { IDayTimeAvailabilityDto } from 'common/interfaces/common';
import { VALID_RANGE_ERROR_TYPE } from 'common/validationSchemas/dayTimeAvailabilitySchema';
import { AvailabilitiesResultBlock } from 'common/components/index';
import { getString } from 'common/utils/typeUtils';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

interface IDayTimeAvailabilitySelector {
  rangesFieldName: string;
  weekdaysFieldName?: string;
  validAvailabilityRange?: IDayTimeAvailabilityDto[];
}

const DayTimeAvailabilitySelector: FC<IDayTimeAvailabilitySelector> = ({
  rangesFieldName,
  weekdaysFieldName,
  validAvailabilityRange,
}) => {
  const renderIntlMessage = useRenderIntlMessage();
  const { formState } = useFormContext();
  const { errors } = formState;

  return (
    <Grid container spacing={2}>
      {!!errors && errors[rangesFieldName]?.type === VALID_RANGE_ERROR_TYPE && (
        <Grid item xs={12}>
          <AvailabilitiesResultBlock
            variant="error"
            errorMessage={getString(renderIntlMessage(errors[rangesFieldName]?.message))}
            availabilities={validAvailabilityRange || []}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <DayTimeAvailabilitySection
          rangesFieldName={rangesFieldName}
          weekdaysFieldName={weekdaysFieldName}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(DayTimeAvailabilitySelector);
