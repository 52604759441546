import React, { forwardRef, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  defaultEditPackageFormData,
  EditPackageStep,
  PackageCostType,
  PackageType,
  StepToggleAction,
} from 'modules/services/constants/packages';
import { deepClone } from 'common/utils';
import { generalInfoValidationSchema } from './generalInfoValidationSchema';
import { IGeneralSection, IPackageStepProps } from 'modules/services/interfaces/packages';
import { ScrollBox } from 'common/components';
import GeneralInfoStepForm from './GeneralInfoStepForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStepsLogic } from 'modules/services/hooks/useStepLogics';
import { sortAvailabilities } from '../../../utils/servicePackage';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

const GeneralInfoStep = forwardRef(
  (
    {
      editPackageSectionFormData,
      renderFooter,
      handleChange,
      submitAttempted,
      disabledSteps,
      toggleStep,
      onPackageSubmit,
    }: IPackageStepProps<IGeneralSection>,
    ref,
  ): JSX.Element => {
    const [, getUTCDate] = useTimezoneMoment();

    const defaultValues = useMemo((): IGeneralSection => {
      if (!editPackageSectionFormData) {
        const date = getUTCDate({ format: 'YYYY-MM-DD' });
        const time = getUTCDate({ format: 'HH:mm' });

        return {
          ...defaultEditPackageFormData.generalSection,
          startDate: date,
          startTime: time,
          endDate: date,
          endTime: time,
        };
      }

      const defaultGeneral = deepClone(defaultEditPackageFormData.generalSection);

      Object.keys(editPackageSectionFormData).forEach(key => {
        if (
          editPackageSectionFormData[key] !== null &&
          editPackageSectionFormData[key] !== undefined
        ) {
          defaultGeneral[key] = editPackageSectionFormData[key];
        }
      });

      defaultGeneral.dayTimeAvailDtoList = sortAvailabilities(defaultGeneral.dayTimeAvailDtoList);

      return defaultGeneral;
    }, [editPackageSectionFormData, getUTCDate]);

    const formMethods = useForm<any>({
      defaultValues,
      resolver: yupResolver(generalInfoValidationSchema),
      mode: 'all',
    });

    const { trigger, watch, getValues } = formMethods;

    const packageType = watch('type');
    const costType = watch('costType');
    const membership = watch('membership');

    const { onSubmit, onNext, onBack } = useStepsLogic(
      'generalSection',
      ref,
      getValues,
      trigger,
      onPackageSubmit,
      handleChange,
      submitAttempted,
    );

    useEffect(() => {
      if (toggleStep) {
        toggleStep(
          EditPackageStep.Membership,
          membership ? StepToggleAction.ENABLE : StepToggleAction.DISABLE,
        );
      }
    }, [membership, toggleStep]);

    useEffect(() => {
      if (toggleStep) {
        if (PackageType.Recurring === packageType) {
          toggleStep(EditPackageStep.Billing, StepToggleAction.ENABLE);
        } else if (PackageCostType.Free === costType) {
          if (!disabledSteps?.includes(EditPackageStep.Billing)) {
            toggleStep(EditPackageStep.Billing, StepToggleAction.DISABLE);
          }
        } else {
          toggleStep(EditPackageStep.Billing, StepToggleAction.ENABLE);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packageType, costType]);

    return (
      <FormProvider {...formMethods}>
        <ScrollBox hasShadowsOnScroll>
          <form autoComplete="none">
            <GeneralInfoStepForm defaultValues={defaultValues} />
          </form>
        </ScrollBox>
        {renderFooter(onSubmit, onNext, onBack)}
      </FormProvider>
    );
  },
);

export default GeneralInfoStep;
