import React from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { makeStyles, Theme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import inputLabels from 'common/messages/inputLabels';

import { TSenderAvailabilityImt } from 'modules/booking/interfaces';

import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import NotifyControlFields from 'common/components/ReactHookForm/NotifyControlFields/NotifyControlFields';
import { selectSenderAvailability } from 'memberPortalModules/MemberProfile/state/profile/selectors';

const useStyles = makeStyles((theme: Theme) => ({
  receiptViaTitle: {
    color: theme.palette.secondary.dark,
  },
}));

const CheckOutResponsePaymentNotifyControl = (): JSX.Element => {
  const senderAvailability: TSenderAvailabilityImt = useRootSelector(
    selectSenderAvailability(SenderAvailabilityTypeList.POS),
  );

  const classes = useStyles();

  return (
    <NotifyControlFields
      label={<FormattedMessage {...inputLabels.sendReceipt} />}
      labelClassName={classes.receiptViaTitle}
      emailFieldName="sendByEmail"
      smsFieldName="sendByText"
      notificationName="sendNotification"
      senderAvailability={senderAvailability}
      fullContent
    />
  );
};

export default CheckOutResponsePaymentNotifyControl;
