import { createAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import Services from 'services/network';
import { actionTypes } from '../../constants/registers';
import {
  ICloseRegisterInvoices,
  ICloseRegisterResult,
  IPosKioskOpenRegisterDTO,
  IPosKioskReconcileDTO,
  IPosKioskRegisterData,
  IPosKioskRegisterTotals,
  TCashDeskInfo,
} from 'modules/pos-kiosk/interfaces/registers';
import { GeneralThunkAction } from 'common/state/interfaces';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { ISuccessResponse } from 'common/interfaces/http';
import AppService from 'services/application/AppService';
import {
  createInvoiceAction,
  createInvoiceRequest,
  fetchOpenInvoicesListAction,
  selectInvoice,
} from 'common/state/invoice/actions';
import { PeakModules } from 'common/constants/peakModules';
import { IObject } from 'common/interfaces/common';
import { IInvoiceDetailsDto, InvoiceStatus } from 'common/interfaces/invoices';
import { fetchInvoicesList } from 'common/httpHooks/useFetchInvoicesList';
import { IRegisterPosClubItem } from '../../../pos-settings/interfaces/register';
import StorageServices from 'services/storage';
import {
  ADD_TOP_UP_BALANCE,
  CREATE_INVOICE,
  EXECUTE_MEMBERSHIP_ACTIONS,
  SELECT_MEMBER_INVOICE,
} from 'common/constants/delayedActionsKeys';

// register data
const fetchRegisterDataLoading = createAction<boolean>(actionTypes.FETCH_REGISTER_DATA_LOADING);
const fetchRegisterDataAction = createAction<IPosKioskRegisterData>(
  actionTypes.FETCH_REGISTER_DATA,
);
export const setSelectedRegisterIdAction = createAction<IObject>(
  actionTypes.SET_SELECTED_REGISTER_ID,
);

export const resetSelectedRegisterIdAction = createAction<string>(
  actionTypes.RESET_SELECTED_REGISTER_ID,
);

export const resetPosKioskRegisterData = createAction<void>(actionTypes.RESET_REGISTER_DATA);
export const registerSelectionProcessLoading = createAction<boolean>(
  actionTypes.REGISTER_SELECTION_PROCESS_LOADING,
);

export const updateSocketInventoriesListAction = createAction<IRegisterPosClubItem[]>(
  actionTypes.UPDATE_REGISTER_INVENTORIES_DATA,
);

const { isExitDelayedAction } = StorageServices.delayedActions;

export const setSelectedRegisterId = (
  clubId: string,
  registerId: string,
  isRegisterOpen: boolean,
  isInvoiceView: boolean,
  canceledInvoice: IInvoiceDetailsDto | null,
  module: PeakModules,
  invoiceUnitsForDrilling?: Array<string>,
  allowedInvoiceStatuses?: InvoiceStatus[],
): GeneralThunkAction => {
  return async dispatch => {
    AppService.setAppSelectedRegister(clubId, registerId);
    dispatch(setSelectedRegisterIdAction({ [clubId]: registerId }));

    if (!isRegisterOpen) {
      return;
    }

    if (isExitDelayedAction(SELECT_MEMBER_INVOICE)) {
      return;
    }

    try {
      dispatch(registerSelectionProcessLoading(true));
      const invoicesList = await fetchInvoicesList(module, registerId, isInvoiceView);
      dispatch(fetchOpenInvoicesListAction(invoicesList));

      const invoice = invoicesList.find(invoiceItem => {
        const expectedCustomerId = canceledInvoice?.customer.id;
        const customer = invoiceItem.customer;
        const isSameCustomer = customer && customer.id === expectedCustomerId;

        if (isSameCustomer) {
          return allowedInvoiceStatuses
            ? allowedInvoiceStatuses.includes(invoiceItem.status)
            : true;
        }

        return false;
      });

      if (invoiceUnitsForDrilling?.length) {
        let updatedInvoice;

        switch (module) {
          case PeakModules.FrontDesk: {
            if (canceledInvoice) {
              let invoiceId: string | null = null;

              if (invoice) {
                invoiceId = invoice.id;
              } else {
                const newInvoice = await createInvoiceRequest(
                  module,
                  registerId,
                  canceledInvoice?.customer?.id,
                  true,
                );

                if (canceledInvoice.salesperson) {
                  await Services.FrontDesk.setSalespersonToInvoice(
                    newInvoice.id,
                    String(canceledInvoice.salesperson.id),
                  );
                }

                invoiceId = newInvoice.id;
              }

              updatedInvoice = await Services.FrontDesk.changeInvoiceUnitsRegister(
                canceledInvoice.id,
                canceledInvoice.customer?.id,
                canceledInvoice.salesperson?.id,
                invoiceUnitsForDrilling,
                registerId,
                invoiceId,
              );
            }

            break;
          }
          case PeakModules.Members: {
            if (canceledInvoice) {
              let invoiceId: string | null = null;

              if (invoice) {
                invoiceId = invoice.id;
              } else {
                const newInvoice = await createInvoiceRequest(
                  module,
                  registerId,
                  canceledInvoice?.customer?.id,
                  true,
                );

                if (canceledInvoice.salesperson) {
                  await Services.Members.setSalespersonToInvoice(
                    newInvoice.id,
                    String(canceledInvoice.salesperson.id),
                  );
                }

                invoiceId = newInvoice.id;
              }

              updatedInvoice = await Services.Members.changeInvoiceUnitsRegister(
                canceledInvoice.id,
                canceledInvoice.customer?.id,
                canceledInvoice.salesperson?.id,
                invoiceUnitsForDrilling,
                registerId,
                invoiceId,
              );
            }

            break;
          }
          case PeakModules.Crm: {
            if (canceledInvoice) {
              let invoiceId: string | null = null;

              if (invoice) {
                invoiceId = invoice.id;
              } else {
                const newInvoice = await createInvoiceRequest(
                  module,
                  registerId,
                  canceledInvoice?.customer?.id,
                  true,
                );

                if (canceledInvoice.salesperson) {
                  await Services.Leads.setSalespersonToInvoice(
                    newInvoice.id,
                    String(canceledInvoice.salesperson.id),
                  );
                }

                invoiceId = newInvoice.id;
              }

              updatedInvoice = await Services.Leads.changeInvoiceUnitsRegister(
                canceledInvoice.id,
                canceledInvoice.customer?.id,
                canceledInvoice.salesperson?.id,
                invoiceUnitsForDrilling,
                registerId,
                invoiceId,
              );
            }

            break;
          }
          default: {
            if (canceledInvoice) {
              let invoiceId: string | null = null;

              if (invoice) {
                invoiceId = invoice.id;
              } else {
                const newInvoice = await createInvoiceRequest(
                  module,
                  registerId,
                  canceledInvoice?.customer?.id,
                  true,
                );

                if (canceledInvoice.salesperson) {
                  await Services.PTLeads.setSalespersonToInvoice(
                    newInvoice.id,
                    String(canceledInvoice.salesperson.id),
                  );
                }

                invoiceId = newInvoice.id;
              }

              updatedInvoice = await Services.PTLeads.changeInvoiceUnitsRegister(
                canceledInvoice.id,
                canceledInvoice.customer?.id,
                canceledInvoice.salesperson?.id,
                invoiceUnitsForDrilling,
                registerId,
                invoiceId,
              );
            }
          }
        }

        batch(() => {
          dispatch(createInvoiceAction(updatedInvoice));
          dispatch(selectInvoice(updatedInvoice.id));
        });
      } else if (invoice) {
        dispatch(selectInvoice(invoice.id));
      } else if (
        !invoice &&
        !isExitDelayedAction(CREATE_INVOICE) &&
        !isExitDelayedAction(ADD_TOP_UP_BALANCE) &&
        !isExitDelayedAction(EXECUTE_MEMBERSHIP_ACTIONS)
      ) {
        const createInvoiceResult = await createInvoiceRequest(
          module,
          registerId,
          canceledInvoice?.customer?.id,
          true,
        );

        batch(() => {
          dispatch(createInvoiceAction(createInvoiceResult));
          dispatch(selectInvoice(createInvoiceResult.id));
        });
      }
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(registerSelectionProcessLoading(false));
    }
  };
};

export const resetSelectedRegisterId = (clubId: string): GeneralThunkAction => {
  return dispatch => {
    AppService.deletAppSelectedRegister(clubId);
    dispatch(resetSelectedRegisterIdAction(clubId));
  };
};

export const fetchPosKioskRegisterData = (registerId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRegisterDataLoading(true));

    try {
      const registerData = await Services.PosKiosk.getRegisterData(registerId);
      dispatch(fetchRegisterDataAction(registerData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRegisterDataLoading(false));
    }
  };
};

// register totals

const fetchRegisterTotalsLoading = createAction<boolean>(actionTypes.FETCH_REGISTER_TOTALS_LOADING);
const fetchRegisterTotalsAction = createAction<IPosKioskRegisterTotals>(
  actionTypes.FETCH_REGISTER_TOTALS,
);
export const resetPosKioskRegisterTotals = createAction<void>(actionTypes.RESET_REGISTER_TOTALS);

export const fetchPosKioskRegisterTotals = (
  registerId: string,
  cashAmount: number,
  checkAmount: number,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRegisterTotalsLoading(true));

    try {
      let registerTotals;

      switch (module) {
        case PeakModules.PosKiosk:
          registerTotals = await Services.PosKiosk.getRegisterTotals(
            registerId,
            cashAmount,
            checkAmount,
          );
          break;
        default:
          registerTotals = await Services.Reports.getRegisterTotalSales(
            registerId,
            cashAmount,
            checkAmount,
          );
      }

      dispatch(fetchRegisterTotalsAction(registerTotals));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRegisterTotalsLoading(false));
    }
  };
};

const fetchRegisterCashDeskInfoLoadingAction = createAction<boolean>(
  actionTypes.FETCH_REGISTER_CASH_DESK_INFO_LOADING,
);
const fetchRegisterCashDeskInfoAction = createAction<TCashDeskInfo>(
  actionTypes.FETCH_REGISTER_CASH_DESK_INFO,
);

export const fetchRegisterCashDeskInfo = (registerId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRegisterCashDeskInfoLoadingAction(true));

    try {
      const cashDeskInfo = await Services.PosKiosk.getCashDeskInfo(registerId);

      dispatch(fetchRegisterCashDeskInfoAction(cashDeskInfo));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRegisterCashDeskInfoLoadingAction(false));
    }
  };
};

// register actions

const openRegisterItemLoading = createAction<boolean>(actionTypes.OPEN_REGISTER_ITEM_LOADING);
const openRegisterItemAction = createAction<ISuccessResponse>(actionTypes.OPEN_REGISTER_ITEM);

export const resetPosKioskOpenRegisterResult = createAction<void>(
  actionTypes.RESET_OPEN_REGISTER_ITEM_ACTION_RESULT,
);

export const openPosKioskRegisterItem = (
  module: PeakModules,
  registerId: string,
  data: IPosKioskOpenRegisterDTO,
  isCustomer: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(openRegisterItemLoading(true));

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDesk.openRegister(registerId, data, isCustomer);
          break;
        case PeakModules.Members:
          await Services.Members.openRegister(registerId, data);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PTLeads.openRegister(registerId, data);
          break;
        case PeakModules.Crm:
          await Services.Leads.openRegister(registerId, data);
          break;
        default:
          await Services.PosKiosk.openRegister(registerId, data);
      }

      dispatch(openRegisterItemAction({ success: true }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(openRegisterItemLoading(false));
    }
  };
};

const closeRegisterItemLoading = createAction<boolean>(actionTypes.CLOSE_REGISTER_ITEM_LOADING);
const closeRegisterItemAction = createAction<ICloseRegisterResult>(actionTypes.CLOSE_REGISTER_ITEM);

export const resetPosKioskCloseRegisterItemResult = createAction<void>(
  actionTypes.RESET_CLOSE_REGISTER_ITEM_ACTION_RESULT,
);

export const closePosKioskRegisterItem = (
  registerId: string,
  data: IPosKioskReconcileDTO,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(closeRegisterItemLoading(true));

    try {
      const closeRegisterActionResult = await Services.PosKiosk.closeRegister(registerId, data);
      dispatch(closeRegisterItemAction({ reportId: closeRegisterActionResult }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(closeRegisterItemLoading(false));
    }
  };
};

const reconcileRegisterItemLoading = createAction<boolean>(
  actionTypes.RECONCILE_REGISTER_ITEM_LOADING,
);
const reconcileRegisterItemAction = createAction<ISuccessResponse>(
  actionTypes.RECONCILE_REGISTER_ITEM,
);

export const resetPosKioskReconcileRegisterItemResult = createAction<void>(
  actionTypes.RESET_RECONCILE_REGISTER_ITEM_ACTION_RESULT,
);

export const reconcilePosKioskRegisterItem = (
  registerId: string,
  data: Partial<IPosKioskReconcileDTO>,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(reconcileRegisterItemLoading(true));

    try {
      await Services.PosKiosk.reconcileRegister(registerId, data);
      dispatch(reconcileRegisterItemAction({ success: true }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(reconcileRegisterItemLoading(false));
    }
  };
};

const closeRegisterInvoicesLoading = createAction<boolean>(
  actionTypes.CLOSE_REGISTER_INVOICES_LOADING,
);
const closeRegisterInvoicesItemAction = createAction<ICloseRegisterInvoices>(
  actionTypes.CLOSE_REGISTER_INVOICES_RESULT,
);

export const resetCloseRegisterInvoicesResult = createAction<void>(
  actionTypes.RESET_CLOSE_REGISTER_INVOICES,
);

export const getCloseRegisterInvoicesResult = (registerId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(closeRegisterInvoicesLoading(true));

    try {
      const results = await Services.PosKiosk.getRegisterInvoices(registerId);
      dispatch(closeRegisterInvoicesItemAction(results));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(closeRegisterInvoicesLoading(false));
    }
  };
};

export const selectInvoiceForMember = (
  memberId: number,
  registerId: string,
  invoicesList: Array<IInvoiceDetailsDto>,
): GeneralThunkAction => {
  return async dispatch => {
    const invoice = invoicesList.find(invoiceItem => {
      return invoiceItem.customer && invoiceItem.customer.id === memberId;
    });

    if (invoice?.id) {
      dispatch(selectInvoice(invoice.id));
    } else {
      const createInvoiceResult = await createInvoiceRequest(
        PeakModules.PosKiosk,
        registerId,
        memberId,
        false,
      );

      batch(() => {
        dispatch(createInvoiceAction(createInvoiceResult));
        dispatch(selectInvoice(createInvoiceResult.id));
      });
    }
  };
};
