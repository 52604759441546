import React, { useCallback, useEffect, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { makeStyles, Paper, Theme } from '@material-ui/core';
import { IInvoiceDetailsImt } from 'common/interfaces/invoices';

import * as actions from '../../state/invoice/actions';
import * as selectors from '../../state/invoice/selectors';

import { useAppDispatch } from 'store/hooks';

import { SearchInvoiceProductsContextProvider } from 'common/createContext/searchInvoiceProductsContext';
import { LoadingBackdrop } from 'common/components/index';
import {
  CancelInvoiceModal,
  VoidInvoiceModal,
} from 'common/components/InvoiceOperating/InvoiceModals';
import InvoicesForm from './InvoiceForm/InvoicesForm';
import CheckOutResponseModal from './InvoiceForm/InvoiceModals/CheckOutResponseModal/CheckOutResponseModal';

const useStyles = makeStyles((theme: Theme) => ({
  paper: isMemberCard => ({
    display: 'flex',
    height: '100%',
    flex: 1,
    boxShadow: isMemberCard ? 'none' : theme.shadows[3],
    position: 'relative',
  }),
  newInvoiceButton: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: theme.typography.h6.fontSize,
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
    },
  },
  buttonHeight: {
    height: 'auto',
  },
  disabledNewInvoice: {
    color: theme.palette.secondary.main,
    opacity: 0.4,
    pointerEvents: 'none',
  },
  noFoundText: {
    fontSize: theme.spacing(2),
    color: theme.palette.secondary.dark,
  },
}));

interface IInvoiceOperating {
  shouldGoBackAfterRevokeInvoice?: boolean;
  onBack?: () => void;
  onNext?: () => void;
  profileId?: number;
  isMemberCard?: boolean;
}

export default function InvoiceOperating({
  shouldGoBackAfterRevokeInvoice,
  onBack,
  onNext,
  profileId,
  isMemberCard,
}: IInvoiceOperating): JSX.Element {
  const classes = useStyles(isMemberCard);
  // local state
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isVoidModalOpen, setIsVoidModalOpen] = useState<boolean>(false);

  // global state
  const dispatch = useAppDispatch();

  const memberInvoice: IInvoiceDetailsImt = useRootSelector(selectors.selectMemberInvoice);
  const cancelInvoiceResult: actions.IVoidInvoiceSuccessResponseImt = useRootSelector(
    selectors.selectCancelResult,
  );
  const isCancelInvoiceLoading: boolean = useRootSelector(selectors.selectCancelResultLoading);
  const checkOutResponse: IInvoiceDetailsImt = useRootSelector(
    selectors.selectInvoiceCheckoutResult,
  );
  const isCheckoutResponseModalOpen = checkOutResponse?.size;

  // const updateInvoiceCountOfRegister = useCallback(() => {
  //   const updatedRegisterList = registersList.toJS().map(item => {
  //     if (item.id === register.get('id')) {
  //       return { ...item, openInvoiceCount: item.openInvoiceCount + 1 };
  //     }

  //     return item;
  //   });

  //   dispatch(
  //     updateDictionaryList({
  //       dictionary: DictionaryList.REGISTERS,
  //       list: updatedRegisterList,
  //       meta: registersListMeta?.toJS(),
  //     }),
  //   );
  // }, [dispatch, registersListMeta, register, registersList]);

  const onSubmitCancel = (): void => {
    dispatch(
      actions.cancelInvoice(
        memberInvoice.get('id'),
        Number(profileId),
        shouldGoBackAfterRevokeInvoice,
      ),
    );
  };

  const onVoidInvoice = (reason: string): void => {
    dispatch(
      actions.voidInvoice({
        invoiceId: memberInvoice?.get('id'),
        reason,
        shouldGoPrevStep: shouldGoBackAfterRevokeInvoice,
      }),
    );
  };

  const handleNotificationSubmit = useCallback(
    ({ checkoutData, notificationData }): void => {
      // const paidInvoiceStatus = checkoutData.get('status');
      // const paidInvoiceId = checkoutData.get('id');
      // let selectedInvoiceId;
      dispatch(actions.sendNotifications(checkoutData.get('id'), notificationData));
      // if (module === PeakModules.FrontDesk && profileId) {
      //   dispatch(personActions.fetchPersonSuggestedProducts(profileId, selectedOrg.get('id')));
      // }
      // dispatch(registerActions.fetchPosKioskRegisterData(selectedRegisterId));
      // remove from the list after successful updateInvoiceWithSync execution
      // if (paidInvoiceStatus === InvoiceStatus.PAID) {
      //   selectedInvoiceId = firstInvoiceId === paidInvoiceId ? secondInvoiceId : firstInvoiceId;
      //   dispatch(removeInvoice(paidInvoiceId));
      // } else {
      //   selectedInvoiceId = firstInvoiceId;
      // }
      if (onNext) onNext();
    },
    [dispatch, onNext],
  );

  useEffect(() => {
    if (cancelInvoiceResult?.get('success')) {
      if (isCancelModalOpen) {
        setIsCancelModalOpen(false);
      }

      if (isVoidModalOpen) {
        setIsVoidModalOpen(false);
      }

      if (cancelInvoiceResult?.get('shouldGoBack') && onBack) {
        onBack();
      }

      dispatch(actions.resetCancelInvoice());
    }
  }, [dispatch, isCancelModalOpen, onBack, cancelInvoiceResult, isVoidModalOpen]);

  const renderedModals = (
    <>
      {isCheckoutResponseModalOpen && (
        <CheckOutResponseModal
          checkOutResponse={checkOutResponse}
          onSubmit={handleNotificationSubmit}
        />
      )}

      {isCancelModalOpen && (
        <CancelInvoiceModal
          isLoading={isCancelInvoiceLoading}
          isOpen={isCancelModalOpen}
          onClose={() => setIsCancelModalOpen(false)}
          onSubmit={onSubmitCancel}
        />
      )}

      {isVoidModalOpen && (
        <VoidInvoiceModal
          isLoading={isCancelInvoiceLoading}
          isOpen={isVoidModalOpen}
          onClose={() => setIsVoidModalOpen(false)}
          onSubmit={onVoidInvoice}
        />
      )}
    </>
  );

  return (
    <Paper className={classes.paper}>
      {!memberInvoice && <LoadingBackdrop isLoading />}
      {memberInvoice && (
        <SearchInvoiceProductsContextProvider>
          <InvoicesForm
            memberInvoice={memberInvoice}
            setIsCancelInvoiceModalOpen={setIsCancelModalOpen}
            setIsVoidInvoiceModalOpen={setIsVoidModalOpen}
          />
        </SearchInvoiceProductsContextProvider>
      )}
      {renderedModals}
    </Paper>
  );
}
