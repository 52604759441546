import * as yup from 'yup';
import inputErrors from 'common/messages/inputErrors';
import { getRequiredErrorMessage } from 'common/utils/validation';

export const NewEmailValidationSchema = yup.object().shape({
  subject: yup
    .string()
    .trim()
    .max(70, () => inputErrors.subjectMaxLengthError)
    .required(getRequiredErrorMessage),
  emailSenderName: yup
    .string()
    .trim()
    .required(getRequiredErrorMessage),
  campaign: yup.string(),
});
