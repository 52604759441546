import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Tooltip } from '@material-ui/core';

import { ReactComponent as HistoryIcon } from 'img/icons/clock-rotate_deprecated.svg';

import commonMessages from 'common/messages/messages';
import { ActionItem, ActionsMenu, Button } from 'common/components/index';
import messages from 'common/components/PersonProfile/messages';

interface IServiceSectionActionsProps {
  readOnly?: boolean;

  onAddNewClick: () => void;
  onHistoryClick: () => void;
  onCancelClick: () => void;
  onFreezeClick: () => void;
  onReactivateClick: () => void;
}

const useStyles = makeStyles({
  actionButton: {
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
});

const ServiceSectionActions: React.FC<IServiceSectionActionsProps> = (
  props: IServiceSectionActionsProps,
): JSX.Element => {
  const {
    readOnly,
    onAddNewClick,
    onHistoryClick,
    onCancelClick,
    onFreezeClick,
    onReactivateClick,
  } = props;

  const classes = useStyles(props);

  return (
    <Box display="flex" alignItems="center" gridGap={12}>
      <Tooltip title={<FormattedMessage {...commonMessages.historyBtn} />}>
        <Button
          type="button"
          onClick={onHistoryClick}
          className={classes.actionButton}
          hasHoverStyle
          color="primary"
        >
          <HistoryIcon />
        </Button>
      </Tooltip>

      {!readOnly && (
        <ActionsMenu horizontal tableActionsMode iconColor="primary">
          <ActionItem onClick={onAddNewClick}>
            <FormattedMessage {...messages.addBundleTitle} />
          </ActionItem>

          <ActionItem onClick={onCancelClick}>
            <FormattedMessage {...messages.cancelBundleTitle} />
          </ActionItem>

          <ActionItem onClick={onFreezeClick}>
            <FormattedMessage {...messages.freezeBundleTitle} />
          </ActionItem>

          <ActionItem onClick={onReactivateClick}>
            <FormattedMessage {...messages.reactivateBundleTitle} />
          </ActionItem>
        </ActionsMenu>
      )}
    </Box>
  );
};

export default ServiceSectionActions;
