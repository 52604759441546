import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import inputErrors from 'common/messages/inputErrors';

export const phoneSchema = yup.string().test(
  'phone-valid',
  () => inputErrors.phoneLengthError,
  value => {
    if (value && value.length > 0) {
      return isValidPhoneNumber(value);
    }

    return true;
  },
);
