import baseApi from 'api/baseApi';
import { IProfileInfo } from 'common/components/PersonProfile/interfaces';
import {
  IFormEditMemberPortalPasswordValues,
  IFormEditMemberPortalValues,
} from 'memberPortalModules/MemberProfile/interface';
import { IRequiredFieldInfo } from 'modules/corporate-settings/interfaces';
import { updatePersonPhotoAction, updateProfileInfo } from './actions';
import { IStoredFileSimpleDto } from 'common/interfaces/uploadFile';

const MemberPortalProfileApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    updateProfile: builder.mutation<Partial<IProfileInfo>, { data: IFormEditMemberPortalValues }>({
      query: ({ data }) => ({
        url: '/api/v1/member-portal/profile',
        method: 'PUT',
        data,
      }),

      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        if (arg) {
          const result = await cacheDataLoaded;

          dispatch(updateProfileInfo(result.data));
        }
      },
    }),
    updateProfilePhoto: builder.mutation<IStoredFileSimpleDto, IStoredFileSimpleDto>({
      query: data => ({
        url: '/api/v1/member-portal/profile/avatar',
        method: 'PUT',
        data,
      }),

      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        if (arg) {
          const result = await cacheDataLoaded;

          dispatch(updatePersonPhotoAction(result.data));
        }
      },
    }),
    deleteProfilePhoto: builder.mutation({
      query: () => ({
        url: '/api/v1/member-portal/profile/avatar',
        method: 'DELETE',
      }),

      async onCacheEntryAdded(arg, { dispatch }) {
        if (arg) {
          dispatch(updatePersonPhotoAction(null));
        }
      },
    }),
    getRequiredFields: builder.query<IRequiredFieldInfo[], void>({
      query: () => ({
        url: '/api/v1/member-portal/profile/required-info',
      }),
    }),
    updateProfilePassword: builder.mutation<void, IFormEditMemberPortalPasswordValues>({
      query: data => ({
        url: '/api/v1/member-portal/profile/credentials',
        method: 'PUT',
        data,
      }),
    }),
  }),
});

export const {
  useUpdateProfileMutation,
  useGetRequiredFieldsQuery,
  useUpdateProfilePhotoMutation,
  useDeleteProfilePhotoMutation,
  useUpdateProfilePasswordMutation,
} = MemberPortalProfileApi;
