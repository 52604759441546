import { fromJS } from 'immutable';
import {
  RESET_UPLOAD_IMAGE,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_ERROR,
  UPLOAD_IMAGE_LOADING,
} from 'common/state/uploadImage/constants';
import { RequestActions } from 'common/state/interfaces';

const initialState = fromJS({
  image: null,
  dialogId: null,
  loading: false,
  error: null,
});

export function uploadImageReducer(state = initialState, action: RequestActions) {
  switch (action.type) {
    case UPLOAD_IMAGE:
      return state
        .set('image', fromJS(action.payload.image))
        .set('dialogId', action.payload.dialogId)
        .set('loading', false)
        .set('error', null);
    case UPLOAD_IMAGE_LOADING:
      return state.set('loading', action.payload);
    case UPLOAD_IMAGE_ERROR:
      return state.set('error', action.payload);
    case RESET_UPLOAD_IMAGE:
      return initialState;
    default:
      return state;
  }
}
