import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { batch } from 'react-redux';

import serviceMessages from 'modules/services/messages/messages';
import { DialogComponent, LoadingBackdrop } from 'common/components/index';
import RedeemSubHeader from '../RedeemSubHeader/RedeemSubHeader';
import { PeakModules } from 'common/constants/peakModules';
import * as selectors from 'common/components/PersonProfile/state/servicesModals/selectors';
import * as actions from 'common/components/PersonProfile/state/servicesModals/actions';
import { useAppDispatch } from 'store/hooks';
import InventoryDetailsView from './InventoryDetailsView';
import useRootSelector from 'common/hooks/useRootSelector';

interface IInventoryDetailsModalProps {
  profileId: number;
  module: PeakModules;
}

const InventoryDetailsModal: FC<IInventoryDetailsModalProps> = props => {
  const { profileId, module } = props;

  const dispatch = useAppDispatch();

  const isOpen = useRootSelector(selectors.selectIsInventoryDetailsShown);
  const selectedInventoryInstanceId = useRootSelector(selectors.selectSelectedInventoryId);
  const selectedInventoryDetails = useRootSelector(selectors.selectInventoryDetails);
  const selectedInventoryRedeemHistory = useRootSelector(
    selectors.selectInventoryRedeemRecentHistory,
  );
  const isLoadingInventoryDetails = useRootSelector(selectors.selectInventoryDetailsLoading);
  const isLoadingRedeemHistory = useRootSelector(
    selectors.selectInventoryRedeemRecentHistoryLoading,
  );

  const handleOnClose = () => {
    batch(() => {
      dispatch(actions.selectInventoryId(null));
      dispatch(actions.setIsInventoryDetailsShown(false));
      dispatch(actions.resetInventoryDetails());
      dispatch(actions.resetInventoryRedeemRecentHistory());
    });
  };

  const handleOnRedeemClick = () => {
    batch(() => {
      dispatch(actions.setIsInventoryDetailsShown(false));
      dispatch(actions.setIsInventoryRedeemShown(true));
    });
  };

  useEffect(() => {
    if (isOpen && selectedInventoryInstanceId) {
      dispatch(actions.fetchInventoryDetails(profileId, selectedInventoryInstanceId, module));
      dispatch(
        actions.fetchInventoryRedeemRecentHistory(profileId, selectedInventoryInstanceId, module),
      );
    }
  }, [selectedInventoryInstanceId, profileId, module, isOpen, dispatch]);

  if (!isOpen) {
    return null;
  }

  const subHeader = Boolean(selectedInventoryDetails) && (
    <RedeemSubHeader
      limited
      totalToRedeem={selectedInventoryDetails.get('totalNumber')}
      availableToRedeem={selectedInventoryDetails.get('leftNumber')}
      handleOnRedeemClick={handleOnRedeemClick}
    />
  );

  return (
    <DialogComponent
      title={
        selectedInventoryDetails?.get('title') || (
          <FormattedMessage {...serviceMessages.inventoryLabel} />
        )
      }
      subHeader={subHeader || undefined}
      isOpen={isOpen}
      loading={isLoadingInventoryDetails}
      cancelBtn={false}
      submitBtn={false}
      onClose={handleOnClose}
      disableFullScreenBehavior
      supressBottomShadow
      hasCustomFooter
    >
      {selectedInventoryDetails ? (
        <InventoryDetailsView
          module={module}
          profileId={profileId}
          inventoryInstance={selectedInventoryDetails}
          redeemHistory={selectedInventoryRedeemHistory}
        />
      ) : null}
      <LoadingBackdrop isLoading={isLoadingInventoryDetails || isLoadingRedeemHistory} />
    </DialogComponent>
  );
};

export default InventoryDetailsModal;
