import { useState } from 'react';

interface IMenuStateProps {
  menuAnchorEl: HTMLElement | null;
  handleShowMenu: (event) => void;
  handleCloseMenu: () => void;
}

const useMenuState = (): IMenuStateProps => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  return {
    menuAnchorEl,
    handleShowMenu: event => {
      setMenuAnchorEl(event.currentTarget);
    },
    handleCloseMenu: () => {
      if (menuAnchorEl) {
        setTimeout(() => {
          menuAnchorEl.blur();
        }, 0);
      }

      setMenuAnchorEl(null);
    },
  };
};

export default useMenuState;
