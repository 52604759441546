import React, { FC, useMemo } from 'react';
import { makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { WatchLater, CheckCircle, Cancel } from '@material-ui/icons';
import { InvoiceStatus } from 'common/interfaces/invoices';
import { InvoiceStatuses } from 'common/constants/invoices';

const useStyles = makeStyles((theme: Theme) => ({
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    whiteSpace: 'nowrap',
    '& svg': {
      width: 12,
      height: 12,
    },
  },
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  iconDanger: {
    marginTop: '-1px',
  },
}));

interface IStatusCell {
  status: string;
}

const StatusCell: FC<IStatusCell> = ({ status }) => {
  const classes = useStyles();

  const renderCellIcon = useMemo(() => {
    switch (status) {
      case InvoiceStatus.PENDING:
      case InvoiceStatus.IN_PROGRESS:
      case InvoiceStatus.OPEN:
        return <WatchLater />;
      case InvoiceStatus.VOIDED:
      case InvoiceStatus.UNPAID:
      case InvoiceStatus.DEFERRED:
        return <Cancel className={classes.iconDanger} />;
      case InvoiceStatus.CHECK_OUT:
      default:
        return <CheckCircle />;
    }
  }, [status, classes]);

  return (
    <Tooltip title={InvoiceStatuses.translate(status)} aria-label="Status">
      <Typography
        component="p"
        className={classes.statusContainer}
        style={{ color: InvoiceStatuses.color(status) }}
      >
        {renderCellIcon}
        <div>{InvoiceStatuses.translate(status)}</div>
      </Typography>
    </Tooltip>
  );
};

export default StatusCell;
