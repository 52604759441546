import {
  FilterTypes,
  IDateRangeFilterValue,
  IFilter,
  IMultipleFilterValue,
} from 'common/interfaces/filter';
import { ITableParams } from 'common/interfaces/table';
import moment from 'moment-timezone';

export const makeArrayFilters = (filters: IFilter[]) => {
  const searchParams: Record<string, unknown> = {};

  filters.forEach(filter => {
    if (
      filter.type === FilterTypes.MULTIPLE ||
      filter.type === FilterTypes.MULTIPLE_WITH_PAGINATE
    ) {
      const filterValue = filter.value as IMultipleFilterValue;

      if (filterValue.length) {
        searchParams[filter.name] = filterValue.map(option => option.value).join(`,`);
      }
    } else if (filter.type === FilterTypes.SINGLE) {
      if (filter.value && 'value' in filter.value) {
        searchParams[filter.name] = filter.value.value;
      }
    } else if (filter.type === FilterTypes.DATE_RANGE) {
      const value: IDateRangeFilterValue = filter.value as IDateRangeFilterValue;

      if (value.startDate) {
        searchParams[`${filter.name}StartDate`] = moment(value.startDate)
          .startOf('day')
          .utc(true)
          .format();
      }

      if (value.endDate) {
        searchParams[`${filter.name}EndDate`] = moment(value.endDate)
          .endOf('day')
          .utc(true)
          .format();
      }
    }
  });

  return searchParams;
};

export const makeTableParams = (
  tableParams?: ITableParams,
  disablePaginationAndSorting?: boolean,
): Record<string, unknown> => {
  if (!tableParams) {
    return {};
  }

  let searchParams: Record<string, unknown> = disablePaginationAndSorting
    ? {}
    : {
        page: tableParams.page,
        perPage: tableParams.perPage,
        sortBy: (tableParams.orderBy as string).toUpperCase(),
        sortDirection: (tableParams.order as string).toUpperCase(),
      };

  if (tableParams.searchStr) {
    searchParams.search = tableParams.searchStr;
  }

  if (tableParams.filters?.length) {
    const transformedFilters = makeArrayFilters(tableParams.filters);

    searchParams = { ...searchParams, ...transformedFilters };
  }

  return searchParams;
};
