import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@material-ui/core';
import { getRequiredErrorMessage } from 'common/utils/validation';
import { regExp } from 'common/constants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { IProfileInfo } from 'common/components/PersonProfile/interfaces';
import { AlertCondition } from 'common/interfaces/alerts';
import { DialogComponent } from 'common/components';
import inputLabels from 'common/messages/inputLabels';
import inputErrors from 'common/messages/inputErrors';

const validationSchema = yup.object().shape({
  lastName: yup
    .string()
    .trim()
    .matches(regExp.NAME, () => inputErrors.lastNameMatchError)
    .max(50, () => inputErrors.lastNameLengthError)
    .required(getRequiredErrorMessage),
});

interface IProps {
  alert: AlertCondition | null;
  isOpen: boolean;
  onSubmit: (info: IProfileInfo, alertId: string | null) => void;
  switchingModal: (alertCondition: AlertCondition | null) => void;
}

const LastNameModal = ({ isOpen, switchingModal, onSubmit, alert }: IProps): JSX.Element => {
  const formMethods = useForm<any>({
    defaultValues: {
      lastName: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const renderIntlMessage = useRenderIntlMessage();

  const onSubmitForm = (values: Partial<IProfileInfo>): void => {
    onSubmit(values, alert);
    switchingModal(alert);
  };

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...inputLabels.lastName} />}
      isOpen={isOpen}
      onClose={() => switchingModal(alert)}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <Controller
        name="lastName"
        control={control}
        render={({ field: { value, onBlur, onChange } }) => (
          <TextField
            variant="outlined"
            label={<FormattedMessage {...inputLabels.lastName} />}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            fullWidth
            error={!!errors.lastName}
            helperText={renderIntlMessage(errors.lastName?.message)}
          />
        )}
      />
    </DialogComponent>
  );
};

export default LastNameModal;
