/* eslint-disable no-bitwise */
import * as React from 'react';
import * as iterall from 'iterall';

export type ForCallback<T, K> = (
  item: T,
  {
    index,
    length,
    key,
    isFirst,
    isLast,
    childrenProps,
  }: {
    index: number;
    length: number;
    key: K;
    isFirst: boolean;
    isLast: boolean;
    childrenProps: any;
  },
) => JSX.Element | JSX.Element[];

export type ArrayLike<T> = { length: number; number: T } | T[];

export interface ForProps<T> {
  children?: ForCallback<T, number>;
  as?: ForCallback<T, number>;
  of?: ArrayLike<T> | null | void;
  in?: 0 | null | void | any;
  childrenProps?: any;
  getKey?: (item: any) => string;
}

function mapIteration(resultRenderElem, item, index, length, key, childrenProps) {
  const result =
    resultRenderElem.length === 1
      ? resultRenderElem(item)
      : resultRenderElem(item, {
          index,
          length,
          key,
          isFirst: index === 0,
          isLast: index === length - 1,
          childrenProps,
        });

  if (React.isValidElement(result) && !Object.prototype.hasOwnProperty.call(result.props, 'key')) {
    return React.cloneElement(result, { key: String(key) });
  }

  return result;
}

function transformCollectionIntoArray<T>(collection): Array<T> {
  const array: any[] = [];
  iterall.forEach(collection, item => array.push(item));
  return array;
}

export class For<T> extends React.Component<ForProps<T>> {
  shouldComponentUpdate(nextProps: ForProps<T>): boolean {
    const { of: propsOf, in: inProps, childrenProps } = this.props;

    return !(
      nextProps.of === propsOf &&
      nextProps.in === inProps &&
      nextProps.childrenProps === childrenProps
    );
  }

  render() {
    const { children, as, in: propsIn, of: propsOf, childrenProps, getKey } = this.props;

    const resultRenderElem = children || as;

    if (!resultRenderElem) {
      return null;
    }

    const hasAs = Object.prototype.hasOwnProperty.call(this.props, 'as');
    const hasChildren = Object.prototype.hasOwnProperty.call(this.props, 'children');
    const hasIn = Object.prototype.hasOwnProperty.call(this.props, 'in');
    const hasOf = Object.prototype.hasOwnProperty.call(this.props, 'of');

    if (typeof resultRenderElem !== 'function' || (hasAs ^ hasChildren) === 0) {
      throw new TypeError('<For> expects either a render-prop child or a Function `as` prop.');
    }

    if ((hasOf ^ hasIn) === 0) {
      throw new TypeError('<For> expects either a Collection `of` or Object `in` prop.');
    }

    // Iterate through object fields
    if (hasIn) {
      const iterableObject = propsIn;

      // Accept null / false as nothing to loop.
      if (!iterableObject) {
        return null;
      }

      if (iterall.isCollection(iterableObject) || typeof iterableObject !== 'object') {
        throw new TypeError(
          `<For in={}> expects a non-collection Object. Perhaps you meant to use <For of={}> with a Collection?`,
        );
      }

      // Map each iterableObject property into a React child, provide additional info if requested
      const keys = Object.keys(iterableObject);
      const { length } = keys;
      const resultCollection: any[] = [];

      for (let i = 0; i < length; i += 1) {
        const key = keys[i];
        resultCollection.push(
          mapIteration(resultRenderElem, iterableObject[key], i, length, key, childrenProps),
        );
      }

      return resultCollection;
    }

    // Iterate through collection-like object
    let list = propsOf;

    if (!list) {
      return null;
    }

    // Convert non-Array collections to an Array.
    if (!Array.isArray(list)) {
      if (!iterall.isCollection(list)) {
        throw new TypeError(
          '<For of={}> expects an Array, Array-like, or Iterable collection. ' +
            'Perhaps you meant to use <For in={}> with an Object?',
        );
      }

      list = transformCollectionIntoArray(list);
    }

    // Map each list item into a React child, provide additional info if requested
    const { length } = list;
    const resultCollection: any[] = [];

    for (let i = 0; i < length; i += 1) {
      const key: string = getKey ? getKey(list[i]) : `${i}`;
      resultCollection.push(mapIteration(resultRenderElem, list[i], i, length, key, childrenProps));
    }

    return resultCollection;
  }
}
