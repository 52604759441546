import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { getStepStyles } from '../styleConstants';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { IInvoicePaymentSplit, PaymentsType } from 'common/interfaces/invoices';
import { FormattedMessage } from 'react-intl';
import commonMessages from 'common/messages/messages';
import { Add as AddIcon } from '@material-ui/icons';
import { Button, LoadingBackdrop, PaymentField } from 'common/components';
import * as actions from 'memberPortalModules/MemberProfile/state/invoice/actions';
import inputLabels from 'common/messages/inputLabels';
import * as selectors from 'memberPortalModules/MemberProfile/state/invoice/selectors';
import { CreditCardTypes } from 'common/components/CreditCardData/interfaces';
import { ActionResult } from 'common/constants';
import { PaymentOptionType } from 'common/components/InvoiceOperating/constants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getExpiryDate } from 'common/utils/time';
import { defaultPriceNumberProps } from 'common/components/NumberController/NumberController';
import { getCreditCardIcon } from 'common/components/CreditCardData/constants';
import {
  resetUpdateInvoiceWithSync,
  updateInvoiceWithSync,
} from 'memberPortalModules/MemberProfile/state/invoice/actions';
import { useUpdatePaymentData } from '../useUpdatePaymentData';
import TokenizeCreditCard from '../../../../../../../../common/components/TokenizeCreditCard/TokenizeCreditCard';
import Alert from '../../../../../../../../common/components/Alert/Alert';
import { AlertTypes } from '../../../../../../../../common/interfaces/alerts';
import { BankCode } from '../../../../../../../../modules/corporate-settings/interfaces';
import { snackbar } from '../../../../../../../../common/utils/snackbarUtils';
import { ICorporationItemImt } from '../../../../../../../../modules/authentication/interfaces';
import { selectMemberProfileCorporation } from '../../../../../../state/profile/selectors';
import useRootSelector from 'common/hooks/useRootSelector';

interface ICreditCardPaymentStepProps {
  memberId?: number;
  leftToPay: number;
  creditCardType: PaymentsType | null;
  paymentTypeId?: string;
  onClose: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  ...getStepStyles(theme),
  cardNumber: {
    margin: theme.spacing(0, 1, 0, 1.5),
  },
  addNewBtn: {
    paddingLeft: theme.spacing(0.375),
    paddingRight: theme.spacing(0.375),
  },
  radioLabel: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2.5),
    },
  },
  radio: {
    '&:hover': {
      boxShadow: 'none',
      borderRadius: '50%',
    },
  },
}));

interface IPaymentOption {
  type: PaymentOptionType;
  id?: string;
  lastFour?: string;
  creditCardType?: CreditCardTypes;
  expired?: boolean;
  cardNumber?: string;
  expDate?: string;
}

const CreditCardPaymentStep: React.FC<ICreditCardPaymentStepProps> = (
  props: ICreditCardPaymentStepProps,
): JSX.Element => {
  const classes = useStyles(props);
  const { memberId, creditCardType, leftToPay, onClose, paymentTypeId } = props;

  const [paymentOptions, setPaymentOptions] = useState<IPaymentOption[]>([]);
  const [isAddNewCardOpen, setIsAddNewCardOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<IPaymentOption | null>(null);
  const [amountToPay, setAmountToPay] = useState<string | number>(leftToPay);
  const [isIFrameModalOpen, setIsIFrameModalOpen] = useState<boolean>(false);

  // global state
  const dispatch = useDispatch();

  const storedCreditCardsResult = useRootSelector(selectors.selectStoredCreditCardsResult);
  const storedCreditCardsLoading = useRootSelector(selectors.selectStoredCreditCardsLoading);
  const addCreditCardLoading = useRootSelector(selectors.selectAddCreditCardLoading);
  const addCreditCardResult = useRootSelector(selectors.selectAddCreditCardResult);
  const memberInvoice = useRootSelector(selectors.selectMemberInvoice);
  const isUpdatingInvoiceLoading = useRootSelector(selectors.selectUpdatedInvoiceLoading);
  const corporation: ICorporationItemImt = useRootSelector(selectMemberProfileCorporation);
  const paymentProcessorType = corporation.get('paymentProcessorType');
  const corporationId = corporation.get('id');
  const renderIntlMessage = useRenderIntlMessage();

  const handleProceed = (): void => {
    const invoiceData = memberInvoice.toJS();
    const invoicePaymentSplit: IInvoicePaymentSplit = {
      paymentMethodId: paymentTypeId,
      type: creditCardType,
      paymentAmount: amountToPay,
      creditCardPaymentOptionType: selectedOption?.type,
      paymentAccountId:
        selectedOption?.type === PaymentOptionType.StoredCard ? selectedOption?.id : undefined,
    };

    dispatch(updateInvoiceWithSync(invoiceData, invoicePaymentSplit));
  };

  // const handleAddCard = (data: IAddCreditCard): void => {
  //   dispatch(actions.storeCreditCard(data));
  // };

  const handleSubmitTokenize = (data: any): void => {
    switch (paymentProcessorType) {
      case BankCode.COMMERCEHUB:
        dispatch(actions.tokenizeCommerceHubEncryptedCard(data));
        break;
      case BankCode.ITRANSACT:
        // we do nothing, because we don't have response for the action.
        // ITransact will trigger backend api endpoint with memberId and xid to tokenize cc
        break;
      default:
        snackbar.warning(<FormattedMessage {...commonMessages.creditCardBankNotConfigured} />);
    }

    setIsIFrameModalOpen(false);
  };

  useEffect(() => {
    dispatch(actions.resetStoredCreditCardsReducer());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.fetchStoredCreditCards());
  }, [dispatch]);

  useEffect(() => {
    const cards = storedCreditCardsResult || [];
    setPaymentOptions(prev => [
      ...prev,
      ...cards.map(card => ({
        type: PaymentOptionType.StoredCard,
        creditCardType: card.get('creditCardType'),
        lastFour: card.get('lastFour'),
        expDate: card.get('expDate'),
        expired: card.get('expired'),
        id: card.get('id'),
      })),
    ]);

    return () => setPaymentOptions([]);
  }, [storedCreditCardsResult]);

  useEffect(() => {
    if (addCreditCardResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.fetchStoredCreditCards());
      dispatch(actions.resetStoredCreditCardResult());
      setIsAddNewCardOpen(false);
    }
  }, [addCreditCardResult, dispatch]);

  const resetStep = () => {
    dispatch(resetUpdateInvoiceWithSync());
  };

  useUpdatePaymentData(onClose);

  useEffect(() => {
    return resetStep;
    // Should perform reset only on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container spacing={2} className={classes.body}>
        <Grid item sm={12} xs={12}>
          <FormControl component="fieldset" disabled={isAddNewCardOpen}>
            <RadioGroup aria-label="cardPaymentSelector" name="cardPaymentSelector">
              {paymentOptions.map(option => (
                <>
                  {option.type === PaymentOptionType.StoredCard && (
                    <FormControlLabel
                      key={option.id}
                      value={option}
                      disabled={option.expired}
                      checked={option === selectedOption}
                      onClick={() => !option.expired && setSelectedOption(option)}
                      className={classes.radioLabel}
                      control={
                        <Radio
                          className={classes.radio}
                          color="primary"
                          disabled={option.expired}
                        />
                      }
                      label={
                        <Box key={option.id} display="flex" alignItems="center">
                          <SvgIcon
                            fontSize="small"
                            component={getCreditCardIcon(option.creditCardType || undefined)}
                          />
                          <Typography
                            className={classes.cardNumber}
                            color={option.expired ? 'error' : 'initial'}
                          >
                            {`**** ${option.lastFour} ${getExpiryDate(option.expDate || '')} ${
                              option.expired ? renderIntlMessage(commonMessages.expired) : ''
                            }`}
                          </Typography>
                        </Box>
                      }
                    />
                  )}
                </>
              ))}
            </RadioGroup>
          </FormControl>
          <LoadingBackdrop isLoading={storedCreditCardsLoading} />
        </Grid>

        {memberId && (
          <Grid item xs={12}>
            {!paymentProcessorType ? (
              <Alert
                title={<FormattedMessage {...commonMessages.creditCardBankNotConfigured} />}
                severity={AlertTypes.Warning}
              />
            ) : (
              <Button
                color="primary"
                startIcon={<AddIcon />}
                className={classes.addNewBtn}
                // onClick={() => setIsAddNewCardOpen(true)}
                onClick={() => setIsIFrameModalOpen(true)}
              >
                <FormattedMessage {...commonMessages.addCardBtn} />
              </Button>
            )}

            {/* TODO remove probably some time later, if we will not enter credit card data except iFrame */}
            {/* {isAddNewCardOpen ? ( */}
            {/*  <CreditCardData */}
            {/*    onClose={() => setIsAddNewCardOpen(false)} */}
            {/*    onCardAdd={handleAddCard} */}
            {/*  /> */}
            {/* ) : ( */}
            {/*  <Button */}
            {/*    color="primary" */}
            {/*    startIcon={<AddIcon />} */}
            {/*    className={classes.addNewBtn} */}
            {/*    onClick={() => setIsAddNewCardOpen(true)} */}
            {/*  > */}
            {/*    <FormattedMessage {...commonMessages.addCardBtn} /> */}
            {/*  </Button> */}
            {/* )} */}

            {isIFrameModalOpen && (
              <TokenizeCreditCard
                paymentProcessorType={paymentProcessorType}
                corporationId={corporationId}
                memberId={memberId}
                isOpen={isIFrameModalOpen}
                onClose={() => setIsIFrameModalOpen(false)}
                onSubmit={data => handleSubmitTokenize(data)}
                isMemberPortal
              />
            )}

            <LoadingBackdrop isLoading={addCreditCardLoading} />
          </Grid>
        )}

        <Grid item xs={12}>
          <PaymentField
            fullWidth
            variant="outlined"
            defaultValue={leftToPay}
            value={amountToPay}
            onChange={setAmountToPay}
            onBlur={setAmountToPay}
            label={<FormattedMessage {...inputLabels.amountToPay} />}
            numberFormatProps={{
              ...defaultPriceNumberProps,
              max: leftToPay,
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.footerActions}>
        <Grid item sm={12} xs={12}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disabled={!selectedOption || isUpdatingInvoiceLoading}
            onClick={handleProceed}
          >
            <FormattedMessage {...commonMessages.proceedBtn} />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CreditCardPaymentStep;
