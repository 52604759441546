import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { batch, useDispatch } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import { Box, DialogActions, Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CustomTheme } from 'common/ui/interfaces';
import { INoteCodeDictionaryItemImt } from 'common/interfaces/dictionary';
import {
  IRedeemResourceTagImt,
  IScheduledServiceRedeemDTO,
  ServiceRedeemStatus,
} from 'common/components/PersonProfile/interfaces';
import { FontWeightProperty } from 'csstype';
import { IServiceItemDetailsImt } from 'common/interfaces/service';
import { IBookingEventImt } from 'modules/booking/interfaces';
import { PeakModules } from 'common/constants/peakModules';
import * as actions from 'common/components/PersonProfile/state/servicesModals/actions';
import {
  resetNoteCodes,
  searchNoteCodesThunk,
} from 'common/components/PersonProfile/state/actions';
import * as selectors from 'common/components/PersonProfile/state/servicesModals/selectors';
import {
  selectNoteCodes,
  selectNoteCodesLoading,
} from 'common/components/PersonProfile/state/selectors';
import {
  selectCurrentUserClubs,
  selectUserSelectedClubId,
} from 'modules/authentication/state/selectors';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import {
  Button,
  ButtonWithCircularProgress,
  DialogComponent,
  LoadingBackdrop,
  MultipleSelect,
  PasswordField,
  PinCodeMaskedInput,
  TextArea,
} from 'common/components';
import RedeemProgress from '../../RedeemProgress/RedeemProgress';
import ScheduledRedeemForm from './Scheduled/ScheduledRedeemForm';
import UnscheduledRedeemForm from './Unscheduled/UnscheduledRedeemForm';
import messages from 'common/components/PersonProfile/messages';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import notes from 'common/components/PersonProfile/components/Notes/messages/notes';
import inputErrors from 'common/messages/inputErrors';
import { INamedEntity } from 'common/interfaces/common';
import useRootSelector from 'common/hooks/useRootSelector';
import { getRequiredErrorMessage } from 'common/utils/validation';

interface IFormValues {
  club: INamedEntity | null;
  pinCode: string;
  note: {
    alertAtNextCheckIn: boolean;
    noteCodeId: string | null;
    text: string;
  };
}

interface IRedeemModalProps {
  module?: PeakModules;
  profileId: number;
  isOpen: boolean;
  onClose?: () => void;
  appointmentId?: string;
  appointmentStartDate?: string;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  header: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  blockTitle: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
    color: theme.palette.text.secondary,
    opacity: 0.4,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    gap: theme.spacing(1),
  },
  serviceTitle: {
    marginBottom: theme.spacing(1.5),
  },
}));

const baseInitialValues = {
  club: null,
  pinCode: '',
  note: {
    alertAtNextCheckIn: false,
    noteCodeId: null,
    text: '',
  },
};

const getValidationSchema = (isScheduled: boolean) =>
  yup.object().shape({
    club: yup
      .object()
      .nullable()
      .required(getRequiredErrorMessage),
    ...(!isScheduled && {
      date: yup
        .string()
        .nullable()
        .required(getRequiredErrorMessage),
      time: yup
        .string()
        .nullable()
        .required(getRequiredErrorMessage),
      durationInMinutes: yup.string().required(getRequiredErrorMessage),
    }),

    pinCode: yup
      .string()
      .nullable()
      .required(getRequiredErrorMessage)
      .when({
        is: val => val?.length,
        then: rule => rule.min(4, () => inputErrors.pinCodeLengthError),
      }),
  });

const RedeemModal: React.FC<IRedeemModalProps> = ({
  module,
  profileId,
  isOpen,
  onClose,
  appointmentId,
  appointmentStartDate,
}: IRedeemModalProps): JSX.Element => {
  const dispatch = useDispatch();

  const selectedServiceItemId = useRootSelector(selectors.selectSelectedServiceItemId);
  const serviceInstanceDetails: IServiceItemDetailsImt = useRootSelector(
    selectors.selectServiceItemDetails,
  );

  const isDetailsLoading = useRootSelector(selectors.selectServiceItemDetailsLoading);
  const eventDetails: IBookingEventImt = useRootSelector(selectors.selectServiceEventDetails);
  const isRedeemActionLoading: boolean = useRootSelector(
    selectors.selectServiceRedeemActionLoading,
  );

  const initialSelectedClubId: string = useRootSelector(selectUserSelectedClubId);
  const resourceTags: ImmutableList<IRedeemResourceTagImt> = useRootSelector(
    selectors.selectServiceResources,
  );

  const isResourcesLoading: boolean = useRootSelector(selectors.selectServiceResourcesLoading);
  const clubs = useRootSelector(selectCurrentUserClubs);
  const noteCodes: ImmutableList<INoteCodeDictionaryItemImt> = useRootSelector(
    selectNoteCodes(profileId),
  );

  const isNoteCodesLoading: boolean = useRootSelector(selectNoteCodesLoading(profileId));
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();
  const totalToRedeem = serviceInstanceDetails?.get('totalNumber');
  const availableNumber = serviceInstanceDetails?.get('leftNumber');
  const isScheduled = serviceInstanceDetails?.get('scheduled');

  const unscheduledRedeemInitialValues = useMemo(() => {
    const nowDate = moment.tz('UTC');

    return {
      date: nowDate.format('YYYY-MM-DD'),
      time: '',
      durationInMinutes: '',
      redeemResourceCreateDtoList: [],
    };
  }, []);

  const initialClub = clubs.find(clubItem => clubItem.get('id') === initialSelectedClubId).toJS();

  const formMethods = useForm<IFormValues>({
    defaultValues: isScheduled
      ? { ...baseInitialValues, club: initialClub }
      : { ...baseInitialValues, club: initialClub, ...unscheduledRedeemInitialValues },
    resolver: yupResolver(getValidationSchema(isScheduled)) as any, // TODO - PRM-1810 need resolver type
    mode: 'all',
  });

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    trigger,
    watch,
  } = formMethods;

  const redeemData: Omit<IScheduledServiceRedeemDTO, 'pinCode' | 'note' | 'clubId'> = {
    eventId: eventDetails?.get('id') || '',
    eventDateTime: moment
      .utc(`${eventDetails?.get('date')} ${eventDetails?.get('time')}`, 'YYYY-MM-DD HH:mm')
      .format(),
    status: null,
  };

  const selectedClub = watch('club');

  const handleNoShown = async () => {
    await trigger();

    const isFormValid = !Object.keys(errors).length;

    if (isFormValid) {
      const formValues = getValues();

      dispatch(
        actions.redeemScheduledService(
          profileId,
          selectedServiceItemId,
          {
            ...formValues,
            clubId: formValues.club?.id || '',
            note: {
              ...formValues.note,
              noteCodeId: (formValues.note?.noteCodeId as any)?.id ?? null, // TODO - PRM-1810 need type
            },
            ...redeemData,
            status: ServiceRedeemStatus.NoShown,
          },
          module,
          appointmentId || undefined,
        ),
      );
    }
  };

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    } else {
      batch(() => {
        dispatch(actions.setIsRedeemShown(false));
        dispatch(actions.setIsDetailsShown(true));
      });
    }
  };

  const onNoteCodeSearch = (search: string): void => {
    dispatch(searchNoteCodesThunk(search, profileId));
  };

  const clearNoteCodeSearchResults = (): void => {
    dispatch(resetNoteCodes(null, profileId));
  };

  const handleFetchServiceResources = useCallback(
    (date: string) => {
      const tagsIds = serviceInstanceDetails
        .get('resourceTags')
        .map(resourceTag => resourceTag.get('id'))
        .toJS();

      if (tagsIds.length) {
        dispatch(
          actions.fetchServiceResourceTags(
            profileId,
            selectedClub?.id || '',
            date,
            tagsIds,
            module,
          ),
        );
      }
    },
    [dispatch, module, profileId, selectedClub, serviceInstanceDetails],
  );

  const handleFormSubmit = (formValues): void => {
    if (isScheduled) {
      dispatch(
        actions.redeemScheduledService(
          profileId,
          selectedServiceItemId,
          {
            ...formValues,
            clubId: formValues.club.id,
            note: {
              ...formValues.note,
              noteCodeId: formValues.note?.noteCodeId?.id ?? null,
            },
            ...redeemData,
            status: ServiceRedeemStatus.Redeemed,
          },
          module,
          appointmentId || undefined,
        ),
      );
    } else {
      dispatch(
        actions.redeemUnScheduledService(
          profileId,
          selectedServiceItemId,
          {
            ...formValues,
            note: {
              ...formValues.note,
              noteCodeId: formValues.note?.noteCodeId?.id ?? null,
            },
            time: moment(formValues.time)
              .utc(true)
              .format('HH:mm'),
            clubId: formValues.club.id,
            durationInMinutes: moment.duration(formValues.durationInMinutes).asMinutes(),
            redeemResourceCreateDtoList: formValues.redeemResourceCreateDtoList?.length
              ? formValues.redeemResourceCreateDtoList.filter(resource => !!resource)
              : [],
          },
          module,
        ),
      );
    }
  };

  useEffect(() => {
    if (isOpen && selectedServiceItemId && !serviceInstanceDetails) {
      dispatch(actions.fetchServiceItemDetails(profileId, selectedServiceItemId, module));
    }
  }, [dispatch, isOpen, module, profileId, selectedServiceItemId, serviceInstanceDetails]);

  const renderBody = () => {
    return (
      !!serviceInstanceDetails && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              name="club"
              control={control}
              render={({ field }) => (
                <MultipleSelect
                  label={<FormattedMessage {...inputLabels.club} />}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  fullWidth
                  options={clubs.toJS()}
                  error={!!errors.club}
                  helperText={renderIntlMessage(errors?.club?.message)}
                />
              )}
            />
          </Grid>

          {!!selectedClub?.id && (
            <>
              {isScheduled ? (
                <ScheduledRedeemForm
                  personId={profileId}
                  serviceInstance={serviceInstanceDetails}
                  module={module}
                  appointmentId={appointmentId}
                  appointmentStartDate={appointmentStartDate}
                />
              ) : (
                <UnscheduledRedeemForm
                  loadServiceResources={handleFetchServiceResources}
                  resourceTags={resourceTags}
                  serviceInstance={serviceInstanceDetails}
                />
              )}

              <Grid item xs={12}>
                <Typography className={classes.blockTitle}>
                  <FormattedMessage {...messages.redeemDescriptionTitle} />
                </Typography>

                <Controller
                  name="note.text"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      value={field.value}
                      label={<FormattedMessage {...messages.redeemNoteLabel} />}
                      variant="outlined"
                      fullWidth
                      minRows={3}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="note.noteCodeId"
                  control={control}
                  render={({ field }) => (
                    <MuiAutocomplete
                      options={noteCodes?.toJS()}
                      loading={isNoteCodesLoading}
                      value={field.value}
                      multiple={false}
                      getOptionLabel={option => (option ? option.title : '')}
                      onChange={(e, option) => field.onChange(option)}
                      onBlur={field.onBlur}
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            label={<FormattedMessage {...notes.noteCode} />}
                            variant="outlined"
                            fullWidth
                            onChange={e => {
                              onNoteCodeSearch(e.target.value);
                            }}
                            onFocus={e => {
                              onNoteCodeSearch(e.target.value);
                            }}
                            onBlur={clearNoteCodeSearchResults}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'off',
                            }}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.blockTitle}>
                  <FormattedMessage {...messages.redeemPinTitle} />
                </Typography>

                <Controller
                  name="pinCode"
                  control={control}
                  render={({ field }) => (
                    <PasswordField
                      autoComplete="new-password"
                      variant="outlined"
                      label={<FormattedMessage {...inputLabels.pinCode} />}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      fullWidth
                      value={field.value}
                      InputProps={{ inputComponent: PinCodeMaskedInput }}
                      error={!!errors.pinCode}
                      helperText={renderIntlMessage(errors.pinCode?.message)}
                    />
                  )}
                />

                {/* <PinCodeRowInput length={4} onChange={setPinCode} />  // TODO need refine */}
              </Grid>
            </>
          )}
        </Grid>
      )
    );
  };

  const renderSubHeader = () =>
    !!serviceInstanceDetails && (
      <Box className={classes.header}>
        <Typography variant="h5" className={classes.serviceTitle}>
          {serviceInstanceDetails?.get('title')}
        </Typography>

        <RedeemProgress
          limited={serviceInstanceDetails?.get('limited')}
          redeemType={serviceInstanceDetails?.get('redeemType')}
          redeemUnit={serviceInstanceDetails?.get('redeemDurationUnit')}
          totalToRedeem={totalToRedeem}
          availableToRedeem={availableNumber}
        />
      </Box>
    );

  const renderFooter = () => (
    <DialogActions>
      <Box className={classes.footer}>
        <Button color="primary" variant="text" size="medium" onClick={handleOnClose}>
          <FormattedMessage {...commonMessages.cancelBtn} />
        </Button>
        {isScheduled && (
          <ButtonWithCircularProgress
            variant="outlined"
            disabled={!eventDetails?.size}
            type="button"
            isSubmitting={isRedeemActionLoading}
            onClick={handleNoShown}
          >
            <FormattedMessage {...commonMessages.noShownBtn} />
          </ButtonWithCircularProgress>
        )}
        <ButtonWithCircularProgress
          disabled={isScheduled ? !eventDetails?.size : false}
          isSubmitting={isRedeemActionLoading}
          form="redeem-form"
        >
          <FormattedMessage {...commonMessages.redeemBtn} />
        </ButtonWithCircularProgress>
      </Box>
    </DialogActions>
  );

  if (!isOpen) {
    return <></>;
  }

  return (
    <DialogComponent
      title={<FormattedMessage {...commonMessages.serviceLabel} />}
      subHeader={renderSubHeader()}
      isOpen={isOpen}
      loading={isDetailsLoading}
      cancelBtn={false}
      submitBtn={false}
      size="sm"
      onClose={handleOnClose}
      disableFullScreenBehavior
      hasCustomFooter
      footer={renderFooter()}
    >
      <FormProvider {...formMethods}>
        <form id="redeem-form" onSubmit={handleSubmit(handleFormSubmit)}>
          {renderBody()}
        </form>
      </FormProvider>
      <LoadingBackdrop isLoading={isDetailsLoading || isResourcesLoading} />
    </DialogComponent>
  );
};

export default RedeemModal;
