import React from 'react';
import cx from 'classnames';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: 300,
    justifyContent: 'space-between',
  },
  single: {
    display: 'flex',
    width: 150,
    marginBottom: theme.spacing(1),
  },
  mainInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  mainInfoTitle: {
    opacity: 0.5,
  },
  marginBottom: {
    marginBottom: theme.spacing(0.5),
  },
  marginRight: {
    marginRight: theme.spacing(0.5),
  },
}));

interface IInfoItemProps {
  title: JSX.Element;
  value: string | JSX.Element;
  vertical?: boolean;
  className?: string;
  align?: 'center' | 'start';
  isSingleItem?: boolean;
}

const InfoItem = ({
  title,
  value,
  vertical,
  align,
  className = '',
  isSingleItem = false,
}: IInfoItemProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box
      className={cx({
        [classes.single]: isSingleItem,
        [classes.root]: !isSingleItem,
        [className]: Boolean(className),
      })}
      alignItems={align}
      flexDirection={vertical ? 'column' : 'row'}
    >
      <Typography
        component="p"
        className={cx(classes.mainInfo, classes.mainInfoTitle, 'mainInfoTitle', {
          [classes.marginBottom]: vertical,
          [classes.marginRight]: !vertical,
        })}
      >
        {title}
      </Typography>
      <Typography component="p" className={cx(classes.mainInfo, 'mainInfoAmount')}>
        {value}
      </Typography>
    </Box>
  );
};

export default InfoItem;
