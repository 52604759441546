import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Avatar, Typography, Box } from '@material-ui/core';
import cx from 'classnames';

import { getInitials } from 'helpers/common';
import profilePlaceholder from 'img/photo-placeholder.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    employeeCell: {
      cursor: 'pointer',
    },
    small: {
      fontSize: '0.75rem',
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    avatarWrap: {
      position: 'relative',
      flex: 'none',
      borderRadius: '50%',
      backgroundColor: theme.palette.secondary.light,
    },
    avatarInitial: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      fontSize: '6px',
      fontWeight: 700,
      color: theme.palette.darkBackground?.light,
    },
  }),
);

interface IProps {
  firstName: string;
  lastName: string;
  imageUrl: string;
  onClick: () => void;
}

const EmployeeCell = (props: IProps) => {
  const classes = useStyles();
  const { firstName, lastName, imageUrl, onClick } = props;
  const initials = getInitials(firstName, lastName);

  return (
    <>
      {firstName || lastName || imageUrl ? (
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          spacing={1}
          onClick={onClick}
          className={classes.employeeCell}
        >
          <Grid item>
            <Grid className={cx(classes.avatarWrap, classes.small)}>
              {initials && !imageUrl ? (
                <Box className={classes.avatarInitial}>{initials}</Box>
              ) : (
                <Avatar
                  src={imageUrl || profilePlaceholder}
                  className={classes.small}
                  alt={firstName}
                />
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Typography>{`${firstName} ${lastName}`}</Typography>
          </Grid>
        </Grid>
      ) : (
        '-'
      )}
    </>
  );
};

export default React.memo(EmployeeCell);
