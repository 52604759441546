import { IMembershipFormNew } from 'common/interfaces/membership';
import {
  CreditCardFeeUnit,
  DurationType,
  FrequencyType,
} from 'modules/services/constants/packages';

export const initialValues: IMembershipFormNew = {
  club: '',
  membership: '',
  packageConfiguration: {
    id: '',
    title: '',
    durationType: DurationType.Weeks,
    durationEditableNumber: {
      editable: false,
      value: 0,
    },
    totalAmount: 0,
    billingOption: '',
    paymentEditableSchedule: {
      editable: false,
      value: FrequencyType.Weekly,
    },
    splitting: {
      allow: false,
      paymentEditableSplits: [],
      paymentNumber: 0,
      splitPaymentEditableNumber: { editable: false, value: 0 },
      paymentEditableNumber: { editable: false, value: 0 },
      splitPaymentSchedule: FrequencyType.Weekly,
    },
    downPayment: {
      allow: false,
      editableAmount: {
        editable: false,
        value: 0,
      },
      editableNumber: {
        editable: false,
        value: 0,
      },
      editableSplits: [],
    },
    paymentGrace: {
      allow: false,
      duration: DurationType.Days,
      editableDurationNumber: {
        editable: false,
        value: 0,
      },
    },
    renewalNumber: 0,
    freeze: {
      editableDaysNumber: {
        editable: false,
        value: 0,
      },
      billingFrozenMembership: false,
    },
    creditCardServiceFee: {
      enabled: false,
      value: 0,
      unit: CreditCardFeeUnit.PERCENTAGE,
    },
    services: [],
    inventories: [],
    clubs: [],
    dayTimeAvailDtoList: null,
    feeSection: {
      fees: [],
      hasEditable: false,
    },
    description: '',
  },
};
