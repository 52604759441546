import React, { useState } from 'react';
import { EditorState, Modifier } from 'draft-js';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Menu, MenuItem } from '@material-ui/core';
import { MoreVert, NavigateNext } from '@material-ui/icons';
import cx from 'classnames';

import { addBlocksFromHtml } from 'common/utils';
import commonMessages from 'common/messages/messages';
import { DocumentClubTags, DocumentCustomerTags } from 'common/constants/documentTemplate';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: '7px',
    right: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  relative: {
    position: 'relative',
    top: 'auto',
    right: 'auto',
  },
  toolbarIcon: {
    fontSize: '22px',
  },
  menuItem: {
    justifyContent: 'space-between',
  },
}));

interface IProps {
  editorState?: any;
  onChange?: (changeFn) => void;
  relative?: boolean;
  hasHiddenAddSignatureMenu?: boolean;
  hasHiddenAddWaiverNameMenu?: boolean;
  hasHiddenAddWaiverLinkMenu?: boolean;
  hasHiddenAddContractNameMenu?: boolean;
  hasHiddenAddContractLinkMenu?: boolean;
  hasHiddenAddSignedWaiversMenu?: boolean;
  hasHiddenAddUnsignedWaiversMenu?: boolean;
  hasHiddenAddSignedContractMenu?: boolean;
  hasHiddenAddUnsignedContractMenu?: boolean;
}

const EditorMenu = ({
  editorState,
  onChange,
  relative,
  hasHiddenAddSignatureMenu,
  hasHiddenAddWaiverNameMenu,
  hasHiddenAddWaiverLinkMenu,
  hasHiddenAddContractNameMenu,
  hasHiddenAddContractLinkMenu,
  hasHiddenAddSignedWaiversMenu,
  hasHiddenAddUnsignedWaiversMenu,
  hasHiddenAddSignedContractMenu,
  hasHiddenAddUnsignedContractMenu,
}: IProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [anchorElCustomer, setAnchorElCustomer] = useState<any>(null);
  const [anchorElClub, setAnchorElClub] = useState<any>(null);

  const onOpenMenu = (event: React.MouseEvent<any>): void => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const onSelectTag = type => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `{{${type}}}`,
      editorState.getCurrentInlineStyle(),
    );

    setAnchorEl(null);
    setAnchorElCustomer(null);
    setAnchorElClub(null);
    setTimeout(() => {
      if (onChange) {
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
      }
    }, 0);
  };

  const addSignature = (): void => {
    setAnchorEl(null);
    setAnchorElCustomer(null);
    setAnchorElClub(null);

    if (onChange) {
      onChange(addBlocksFromHtml(editorState, ` [[SIGNATURE]] `));
    }
  };

  return (
    <Box
      onBlur={e => e.stopPropagation()}
      className={cx(classes.root, relative && classes.relative)}
    >
      <MoreVert className={classes.toolbarIcon} onClick={onOpenMenu} />

      <Menu
        id="select-tag-type"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MenuItem className={classes.menuItem} onClick={e => setAnchorElCustomer(e.currentTarget)}>
          <FormattedMessage {...commonMessages.customerFieldLabel} />
          <NavigateNext />
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={e => setAnchorElClub(e.currentTarget)}>
          <FormattedMessage {...commonMessages.clubFieldLabel} />

          <NavigateNext />
        </MenuItem>
        {hasHiddenAddSignatureMenu || (
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              addSignature();
            }}
          >
            <FormattedMessage {...commonMessages.signatureBlockLabel} />
          </MenuItem>
        )}
      </Menu>

      <Menu
        id="select-tag-customer"
        anchorEl={anchorElCustomer}
        getContentAnchorEl={null}
        open={Boolean(anchorElCustomer)}
        onClose={() => {
          setAnchorElCustomer(null);
          setAnchorEl(null);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {Object.keys(DocumentCustomerTags).reduce((acc, tag) => {
          if (
            hasHiddenAddWaiverNameMenu &&
            DocumentCustomerTags[tag] === DocumentCustomerTags.waiver
          ) {
            return acc;
          }

          if (
            hasHiddenAddWaiverLinkMenu &&
            DocumentCustomerTags[tag] === DocumentCustomerTags.waiverLink
          ) {
            return acc;
          }

          if (
            hasHiddenAddContractNameMenu &&
            DocumentCustomerTags[tag] === DocumentCustomerTags.contract
          ) {
            return acc;
          }

          if (
            hasHiddenAddContractLinkMenu &&
            DocumentCustomerTags[tag] === DocumentCustomerTags.contractLink
          ) {
            return acc;
          }

          if (
            hasHiddenAddSignedWaiversMenu &&
            DocumentCustomerTags[tag] === DocumentCustomerTags.signedWaivers
          ) {
            return acc;
          }

          if (
            hasHiddenAddUnsignedWaiversMenu &&
            DocumentCustomerTags[tag] === DocumentCustomerTags.unsignedWaivers
          ) {
            return acc;
          }

          if (
            hasHiddenAddSignedContractMenu &&
            DocumentCustomerTags[tag] === DocumentCustomerTags.signedContract
          ) {
            return acc;
          }

          if (
            hasHiddenAddUnsignedContractMenu &&
            DocumentCustomerTags[tag] === DocumentCustomerTags.unsignedContract
          ) {
            return acc;
          }

          return [
            ...acc,
            <MenuItem key={tag} onClick={() => onSelectTag(tag)}>
              {DocumentCustomerTags[tag]}
            </MenuItem>,
          ];
        }, [])}
      </Menu>

      <Menu
        id="select-tag-club"
        anchorEl={anchorElClub}
        getContentAnchorEl={null}
        open={Boolean(anchorElClub)}
        onClose={() => {
          setAnchorElClub(null);
          setAnchorEl(null);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {Object.keys(DocumentClubTags).map(tag => (
          <MenuItem key={tag} onClick={() => onSelectTag(tag)}>
            {DocumentClubTags[tag]}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default EditorMenu;
