import React, { ReactNode } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { List as ImmutableList } from 'immutable';
import { MultipleSelect } from 'common/components';
import { DictionaryList } from 'common/constants';
import { INamedEntity } from 'common/interfaces/common';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import messages from 'modules/services/messages/packages';
import commonMessages from 'common/messages/messages';

interface IClubsSelectorProps {
  control: Control<any>;
  fieldName?: string;
  label: ReactNode;
}

const ClubsSelector: React.FC<IClubsSelectorProps> = (props: IClubsSelectorProps): JSX.Element => {
  const { control, label, fieldName = 'clubs' } = props;

  // form

  const selectedClubs: Array<INamedEntity> = useWatch({
    control,
    name: fieldName,
  });

  const clubsList: ImmutableList<INamedEntity> = useRootSelector(
    selectDictionaryList(DictionaryList.CLUBS),
  );

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{label}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Controller
          name={fieldName}
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <MultipleSelect
              multiple
              fullWidth
              value={value}
              onChange={onChange}
              options={clubsList?.toJS()}
              onBlur={onBlur}
              label={
                <FormattedMessage
                  {...(selectedClubs?.length > 0
                    ? commonMessages.clubsLabel
                    : messages.limitClubsAllAllowed)}
                />
              }
            />
          )}
        />
      </Grid>
    </>
  );
};

export default ClubsSelector;
