import React, { FC, useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { List as ImmutableList } from 'immutable';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { FrequencyTypes } from 'modules/services/constants/packages';
import { ActionResult } from 'common/constants';
import { CustomTheme } from 'common/ui/interfaces';
import { ITableRow } from 'common/interfaces/table';
import { PaymentsType } from 'common/interfaces/invoices';
import {
  IPackageInstanceSubscription,
  IPaymentAccountImt,
  IShortClubDTO,
} from 'common/components/PersonProfile/interfaces';
import { IPaymentMethodItemImt } from 'modules/pos-settings/interfaces/paymentMethods';
import { IPackageInstanceDetails } from 'common/interfaces/service';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';
import { Table } from 'common/components/index';
import { useAppDispatch } from 'store/hooks';
import { deleteCheckingSavingsItemActionResult } from 'common/components/PersonProfile/state/actions';
import PaymentMethodField from './PaymentMethodField';
import PaymentAccountField from './PaymentAccountField';
import CheckingField from 'common/components/PersonProfile/modals/PaymentMethodsModal/CheckingField';
import cx from 'classnames';
import { SubscriptionIcon } from 'img/icons';
import { LocationOn } from '@material-ui/icons';

const useStyles = makeStyles((theme: CustomTheme) => ({
  tableWrapper: {
    marginTop: theme.spacing(2),

    '& .MuiTableContainer-root.scrollbar-border-radius': {
      boxShadow: 'none',
    },

    '& tr': {
      verticalAlign: 'top',
    },
  },
  packageTitle: {
    marginRight: theme.spacing(1.5),
  },

  fieldContainer: {
    marginBottom: theme.spacing(0.5),
  },

  fieldRowNumber: {
    textAlign: 'end',
    marginRight: theme.spacing(1),
  },

  paymentMethodField: { paddingRight: theme.spacing(0.5) },

  contactInfoIcon: {
    width: 18,
    height: 18,
    verticalAlign: 'middle',
    color: theme.palette.text.secondary,
  },
}));

interface IPackageNameCell {
  packageInstance: Partial<IPackageInstanceDetails>;
  club: IShortClubDTO;
}

const PackageNameCell: FC<IPackageNameCell> = ({ packageInstance, club }) => {
  const { startDate, endDate, title, paymentSchedule, pricePerBilling } = packageInstance;
  const classes = useStyles();
  const renderIntlMessage = useRenderIntlMessage();
  const [timezoneMoment] = useTimezoneMoment();
  const formattedStartDate = timezoneMoment(startDate).format(DEFAULT_DATE_FORMAT);
  const formattedEndDate = timezoneMoment(endDate).format(DEFAULT_DATE_FORMAT);
  const period = `(${formattedStartDate} - ${formattedEndDate})`;
  const billingParts: string[] = [];
  const contentParts: string[] = [period];

  if (pricePerBilling) {
    billingParts.push(`$${pricePerBilling.toFixed(2)}`);
  }

  if (paymentSchedule) {
    const message = renderIntlMessage(FrequencyTypes.message(paymentSchedule));

    if (message) {
      billingParts.push(message);
    }
  }

  if (billingParts.length) {
    contentParts.unshift(billingParts.join(' / '));
  }

  return (
    <Box>
      <Box mb={0.75}>
        <Typography variant="h5" component="span" className={classes.packageTitle}>
          {title}
        </Typography>
      </Box>
      <Box mb={0.5} display="flex">
        <Box mr={0.75}>
          <SubscriptionIcon />
        </Box>
        <Typography component="span">{contentParts.join(' ')}</Typography>
      </Box>
      {club && (
        <Box display="flex">
          <Box mr={0.75}>
            <LocationOn className={classes.contactInfoIcon} />
          </Box>
          <Typography component="span">{club.title}</Typography>
        </Box>
      )}
    </Box>
  );
};

interface IProps {
  isNewCardInputDisabled: boolean;
  paymentMethods: ImmutableList<IPaymentMethodItemImt>;
  storedCreditCards: ImmutableList<IPaymentAccountImt>;
  checkingSavingsData: ImmutableList<IPaymentAccountImt>;
  deleteCardActionResult: ActionResult;
  checkingSavingsDeleteActionResult?: ActionResult;
  personId: number;
  setIsOpenAddCreditCardModal: (isOpen: boolean) => void;
  setIsOpenCheckingSavingsModal: (isOpen: boolean) => void;
}

const SubscriptionsTable: FC<IProps> = ({
  paymentMethods,
  storedCreditCards,
  isNewCardInputDisabled,
  deleteCardActionResult,
  checkingSavingsData,
  checkingSavingsDeleteActionResult,
  personId,
  setIsOpenAddCreditCardModal,
  setIsOpenCheckingSavingsModal,
}) => {
  const dispatch = useAppDispatch();
  const { control, watch, setValue } = useFormContext();
  const classes = useStyles();
  const subscriptions: IPackageInstanceSubscription[] = watch('subscriptions');

  // TODO after refactoring, this seems to much code for so simple check, need to prettify it
  const checkIsCardPaymentOption = useCallback(
    (index: number): boolean => {
      return subscriptions[index]?.paymentMethod?.type === PaymentsType.CREDIT_CARD;
    },
    [subscriptions],
  );

  const checkIsSecondaryCardPaymentOption = useCallback(
    (index: number): boolean => {
      return subscriptions[index]?.secondaryPaymentMethod?.type === PaymentsType.CREDIT_CARD;
    },
    [subscriptions],
  );

  useEffect(() => {
    if (deleteCardActionResult === ActionResult.SUCCESS_ACTION && subscriptions.length) {
      subscriptions.forEach((subscription, index) => {
        const creditCardType = subscription.paymentAccount?.creditCardType;
        const isCreditCardExist = storedCreditCards.find(
          card => card.get('id') === subscription.paymentAccount?.id,
        );

        const secondaryCreditCardType = subscription.secondaryPaymentAccount?.creditCardType;
        const isSecondaryCreditCardExist = storedCreditCards.find(
          card => card.get('id') === subscription.secondaryPaymentAccount?.id,
        );

        if (checkIsCardPaymentOption(index) && creditCardType && !isCreditCardExist) {
          setValue(`subscriptions.${index}.paymentAccount`, {
            ...subscription.paymentAccount,
            deleted: true,
          });
        }

        if (
          checkIsSecondaryCardPaymentOption(index) &&
          secondaryCreditCardType &&
          !isSecondaryCreditCardExist
        ) {
          setValue(`subscriptions.${index}.secondaryPaymentAccount`, {
            ...subscription.secondaryPaymentAccount,
            deleted: true,
          });
        }
      });
    }
  }, [
    checkIsCardPaymentOption,
    checkIsSecondaryCardPaymentOption,
    deleteCardActionResult,
    setValue,
    storedCreditCards,
    subscriptions,
  ]);

  useEffect(() => {
    if (checkingSavingsDeleteActionResult === ActionResult.SUCCESS_ACTION && subscriptions.length) {
      subscriptions.forEach((subscription, index) => {
        const checkingId = subscription.checking?.id;
        const isCheckingExist = checkingSavingsData.some(item => {
          return checkingId === item.get('id');
        });

        const secondaryCheckingId = subscription.secondaryChecking?.id;
        const isSecondaryCheckingExist = checkingSavingsData.some(item => {
          return secondaryCheckingId === item.get('id');
        });

        if (checkingId && !isCheckingExist) {
          setValue(`subscriptions.${index}.checking`, {
            ...subscription.checking,
            deleted: true,
          });
        }

        if (secondaryCheckingId && !isSecondaryCheckingExist) {
          setValue(`subscriptions.${index}.secondaryChecking`, {
            ...subscription.secondaryChecking,
            deleted: true,
          });
        }
      });

      dispatch(deleteCheckingSavingsItemActionResult(null, personId));
    }
  }, [
    checkingSavingsDeleteActionResult,
    subscriptions,
    checkingSavingsData,
    setValue,
    dispatch,
    personId,
  ]);

  const tableRows: ITableRow[] = subscriptions.map(
    (subscription: IPackageInstanceSubscription, i) => {
      const isCheckType = subscription.paymentMethod?.type === PaymentsType.CHECKING_SAVINGS;
      const isSecondaryCheckType =
        subscription.secondaryPaymentMethod?.type === PaymentsType.CHECKING_SAVINGS;

      const isPrimaryHasAdditionalField = isCheckType || checkIsCardPaymentOption(i);

      const isSecondaryHasAdditionalField =
        isSecondaryCheckType || checkIsSecondaryCardPaymentOption(i);

      return {
        id: subscription.packageInstance?.packageInstanceId || '',
        cells: [
          {
            align: 'left',
            label: '-',
            width: '50%',
            cellComponent: (
              <Controller
                name={`subscriptions.${i}.packageInstance`}
                control={control}
                defaultValue={subscription.packageInstance}
                render={({ field }) => (
                  <PackageNameCell packageInstance={field.value} club={subscription.club} />
                )}
              />
            ),
          },
          {
            label: '',
            width: '50%',
            cellComponent: (
              <Grid container xs={12}>
                <Grid container item xs={12} alignItems="center" className={classes.fieldContainer}>
                  <Grid item xs={1}>
                    <Typography color="textSecondary" className={classes.fieldRowNumber}>
                      1.
                    </Typography>
                  </Grid>
                  <Grid container item xs={11}>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={isPrimaryHasAdditionalField ? 7 : 12}
                        className={cx({
                          [classes.paymentMethodField]: isPrimaryHasAdditionalField,
                        })}
                      >
                        <PaymentMethodField
                          paymentMethods={paymentMethods}
                          subscription={subscription}
                          isNewCardInputDisabled={isNewCardInputDisabled}
                          index={i}
                        />
                      </Grid>
                      {isCheckType && (
                        <Grid item xs={5}>
                          <CheckingField
                            checkingSavingsData={checkingSavingsData}
                            subscription={subscription}
                            index={i}
                            setIsOpenCheckingSavingsModal={setIsOpenCheckingSavingsModal}
                          />
                        </Grid>
                      )}
                      {checkIsCardPaymentOption(i) && (
                        <Grid item xs={5}>
                          <PaymentAccountField
                            subscription={subscription}
                            storedCreditCards={storedCreditCards}
                            isNewCardInputDisabled={isNewCardInputDisabled}
                            index={i}
                            setIsOpenAddCreditCardModal={setIsOpenAddCreditCardModal}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center">
                  <Grid item xs={1}>
                    <Typography color="textSecondary" className={classes.fieldRowNumber}>
                      2.
                    </Typography>
                  </Grid>
                  <Grid container item xs={11}>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={isSecondaryHasAdditionalField ? 7 : 12}
                        className={cx({
                          [classes.paymentMethodField]: isSecondaryHasAdditionalField,
                        })}
                      >
                        <PaymentMethodField
                          paymentMethods={paymentMethods}
                          subscription={subscription}
                          isNewCardInputDisabled={isNewCardInputDisabled}
                          index={i}
                          isSecondary
                        />
                      </Grid>
                      {isSecondaryCheckType && (
                        <Grid item xs={5}>
                          <CheckingField
                            checkingSavingsData={checkingSavingsData}
                            subscription={subscription}
                            index={i}
                            setIsOpenCheckingSavingsModal={setIsOpenCheckingSavingsModal}
                            isSecondary
                          />
                        </Grid>
                      )}
                      {checkIsSecondaryCardPaymentOption(i) && (
                        <Grid item xs={5}>
                          <PaymentAccountField
                            subscription={subscription}
                            storedCreditCards={storedCreditCards}
                            isNewCardInputDisabled={isNewCardInputDisabled}
                            index={i}
                            setIsOpenAddCreditCardModal={setIsOpenAddCreditCardModal}
                            isSecondary
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ),
          },
        ],
      };
    },
  );

  return (
    <Box className={classes.tableWrapper}>
      <Table
        hideToolbar
        hideSearchInput
        isLoading={false}
        rows={tableRows}
        hidePagination
        hidePadding
      />
    </Box>
  );
};

export default SubscriptionsTable;
