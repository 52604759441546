import * as React from 'react';
import cx from 'classnames';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Button as MaterialButton, ButtonProps, PropTypes } from '@material-ui/core';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { CustomColor, CustomTheme } from 'common/ui/interfaces';

const DISABLED_OPACITY = 0.5;
const ACTIVE_OPACITY = 0.16;
const HOVER_OPACITY = 0.08;

const buildContainedBtnStyle = (color?: PaletteColor) => ({
  backgroundColor: color?.main,
  color: color?.contrastText,
  '&:hover': {
    backgroundColor: color?.main,
  },
  '&:active': {
    backgroundColor: color?.dark,
  },
  '&:disabled': {
    backgroundColor: color?.main,
    color: color?.contrastText,
    opacity: DISABLED_OPACITY,
  },
});

const buildOutlinedBtnStyle = (color: PaletteColor | null, disabledColor: string) => ({
  borderColor: color?.main,
  color: color?.main,
  '&:hover': {
    borderColor: color?.dark,
    color: color?.dark,
    backgroundColor: alpha(color?.main || '', DISABLED_OPACITY),
  },
  '&:disabled': {
    backgroundColor: disabledColor,
    color: color?.main,
    opacity: DISABLED_OPACITY,
  },
});

const buildTextBtnStyle = (color?: PaletteColor) => ({
  borderColor: color?.main,
  color: color?.main,
  '&:hover': {
    backgroundColor: alpha(color?.main || '', HOVER_OPACITY),
  },
  '&:active': {
    backgroundColor: alpha(color?.main || '', ACTIVE_OPACITY),
  },
  '&:disabled': {
    color: color?.main,
    opacity: DISABLED_OPACITY,
  },
});

const useStyles = makeStyles((theme: CustomTheme) => ({
  containedBtnInfo: buildContainedBtnStyle(theme.palette.info),
  containedBtnWarning: buildContainedBtnStyle(theme.palette.warning),
  containedBtnError: buildContainedBtnStyle(theme.palette.error),
  containedBtnCelebration: buildContainedBtnStyle(theme.palette.celebration),

  outlinedBtnInfo: buildOutlinedBtnStyle(theme.palette.info, theme.palette.text.disabled),
  outlinedBtnWarning: buildOutlinedBtnStyle(theme.palette.warning, theme.palette.text.disabled),
  outlinedBtnError: buildOutlinedBtnStyle(theme.palette.error, theme.palette.text.disabled),
  outlinedBtnCelebration: buildOutlinedBtnStyle(
    theme.palette.celebration || null,
    theme.palette.text.disabled,
  ),

  textBtnInfo: buildTextBtnStyle(theme.palette.info),
  textBtnWarning: buildTextBtnStyle(theme.palette.warning),
  textBtnError: buildTextBtnStyle(theme.palette.error),
  textBtnCelebration: buildTextBtnStyle(theme.palette.celebration),

  noSpacing: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    minWidth: 'unset',
  },
  hoverIcons: {
    '&.MuiButton-text': {
      padding: '10px 8px',
    },
    '&.MuiButton-root': {
      minWidth: '10px',
      position: 'relative',
      left: theme.spacing(1),
    },
  },
}));

const getCustomButtonClass = (classes, variant, color): string => {
  if (variant === 'contained') {
    if (color === 'info') return classes.containedBtnInfo;
    if (color === 'warning') return classes.containedBtnWarning;
    if (color === 'error') return classes.containedBtnError;
    if (color === 'celebration') return classes.containedBtnCelebration;
  }

  if (variant === 'outlined') {
    if (color === 'info') return classes.outlinedBtnInfo;
    if (color === 'warning') return classes.outlinedBtnWarning;
    if (color === 'error') return classes.outlinedBtnError;
    if (color === 'celebration') return classes.outlinedBtnCelebration;
  }

  if (variant === 'text') {
    if (color === 'info') return classes.textBtnInfo;
    if (color === 'warning') return classes.textBtnWarning;
    if (color === 'error') return classes.textBtnError;
    if (color === 'celebration') return classes.textBtnCelebration;
  }

  return '';
};

export interface IButtonProps extends Omit<ButtonProps, 'color'> {
  color?: CustomColor;
  noSpacing?: boolean;
  hasHoverStyle?: boolean;
}

const Button = ({
  children,
  className,
  color = 'default',
  variant = 'text',
  noSpacing,
  hasHoverStyle = false,
  ...props
}: IButtonProps): JSX.Element => {
  const classes = useStyles();

  const hasExcludedColor: boolean = ['info', 'warning', 'error'].includes(color);

  const customClass = getCustomButtonClass(classes, variant, color);

  const materialColor: PropTypes.Color = hasExcludedColor ? 'default' : (color as PropTypes.Color);

  return (
    <MaterialButton
      variant={variant}
      color={materialColor}
      className={cx(customClass, className, {
        [classes.noSpacing]: noSpacing,
        [classes.hoverIcons]: hasHoverStyle,
      })}
      {...props}
    >
      {children}
    </MaterialButton>
  );
};

export default Button;
