/* eslint-disable react/no-array-index-key */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { Box } from '@material-ui/core';
import cx from 'classnames';
import { colors } from 'common/ui/theme/default';

type IStepperProgressSize = 'small' | 'medium' | 'large';

interface IStepProgressProps {
  done: number;
  total: number;

  // relates to stepper height
  // eslint-disable-next-line react/no-unused-prop-types
  size?: IStepperProgressSize;

  // display progress in red color
  error?: boolean;
}

const getStepperHeightForSize = (theme: CustomTheme, size?: IStepperProgressSize): number => {
  switch (size) {
    case 'small':
      return theme.spacing(0.125);
    case 'large':
      return theme.spacing(0.5);
    case 'medium':
    default:
      return theme.spacing(0.25);
  }
};

const useStyles = makeStyles((theme: CustomTheme) => ({
  stepper: {
    width: '100%',
    height: ({ size }: IStepProgressProps) => getStepperHeightForSize(theme, size),
    display: 'flex',
    flexDirection: 'row',
  },

  stepLine: {
    width: ({ total, size }: IStepProgressProps) =>
      `calc((100% - ${total - getStepperHeightForSize(theme, size)}px) / ${total})`,
    height: '100%',
    backgroundColor: ({ error }: IStepProgressProps) =>
      error ? theme.palette.error.main : theme.palette.borderColor?.main,
    padding: 0,
    marginRight: theme.spacing(0.125),

    '&.active': {
      backgroundColor: colors.primary,
    },

    '&:first-child': {
      borderRadius: theme.spacing(0.25, 0, 0, 0.25),
      marginRight: theme.spacing(0.125),
    },

    '&:last-child': {
      borderRadius: theme.spacing(0, 0.25, 0.25, 0),
      marginRight: 0,
    },
  },
}));

const StepProgress: React.FC<IStepProgressProps> = (props: IStepProgressProps): JSX.Element => {
  const { done, total, error } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.stepper}>
      {Array.from(Array(total)).map((step, index) => (
        <Box
          key={`step-${index}`}
          className={cx(classes.stepLine, {
            active: !error && index < done,
          })}
        />
      ))}
    </Box>
  );
};

export default StepProgress;
