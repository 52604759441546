import { combineReducers } from 'redux-immutable';
import { profileReducer } from './profile/reducers';
import insuranceInfoReducer from './insuranceInfo/reducers';
import allergiesReducer from './allergies/reducers';
import checkInsHistoryReducer from './checkInsHistory/reducers';
import paymentsReducer from './payments/reducers';
import familyMembersReducer from './familyMembers/reducers';
import appointmentsReducer from './appointments/reducers';
import membershipReducer from './membership/reducers';
import servicesReducer from './services/reducers';
import documentsReducer from './documents/reducers';
import ticketsReducer from './tickets/reducers';
import billingActionsReducer from './billingActions/reducers';
import dictionariesReducer from './dictionaries/reducers';
import invoicesReducer from './invoice/reducers';
import referralsReducer from './referrals/reducers';

export const memberPortalProfileReducer = combineReducers({
  profile: profileReducer,
  insuranceInfo: insuranceInfoReducer,
  allergies: allergiesReducer,
  checkInsHistory: checkInsHistoryReducer,
  payments: paymentsReducer,
  familyMembers: familyMembersReducer,
  appointments: appointmentsReducer,
  membership: membershipReducer,
  services: servicesReducer,
  documents: documentsReducer,
  tickets: ticketsReducer,
  billingActions: billingActionsReducer,
  dictionaries: dictionariesReducer,
  invoices: invoicesReducer,
  referrals: referralsReducer,
});
