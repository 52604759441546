import { actionTypes } from './constants';
import { v4 as uuidv4 } from 'uuid';
import { ServerError } from 'common/errors/serverErrors';

const initNotificsState: Record<string, any> = {
  errors: [],
  successes: [],
  checkins: [],
  custom: undefined,
};

export default (state = initNotificsState, action) => {
  switch (action.type) {
    case actionTypes.ENQUEUE_ERROR_NOTIFIC:
      const isJSIssue = action.payload.message && action.payload.stack;
      const errorProps = isJSIssue
        ? {
            codes: [ServerError.SOMETHING_WENT_WRONG_ON_FRONT_END],
            jsErrorMessage: action.payload.message,
            jsErrorStack: action.payload.stack,
          }
        : { ...action.payload };
      return { ...state, errors: [...state.errors, { key: uuidv4(), ...errorProps }] };
    case actionTypes.CLOSE_ERROR_NOTIFIC:
      return {
        ...state,
        errors: state.errors.filter(error => error.key !== action.payload),
      };
    case actionTypes.ENQUEUE_SUCCESS_NOTIFIC:
      return { ...state, successes: [...state.successes, { key: uuidv4(), ...action.payload }] };
    case actionTypes.CLOSE_SUCCESS_NOTIFIC:
      return {
        ...state,
        successes: state.successes.filter(success => success.key !== action.payload),
      };
    case actionTypes.REMOVE_ERROR_NOTIFICS:
      return {
        ...state,
        error: [],
      };
    case actionTypes.REMOVE_SUCCESS_NOTIFICS:
      return {
        ...state,
        successes: [],
      };
    case actionTypes.ENQUEUE_CHECKIN_NOTIFIC:
      return {
        ...state,
        checkins: [...state.checkins, action.payload],
      };
    case actionTypes.REMOVE_CHECKIN_NOTIFIC_BY_ID:
      return {
        ...state,
        checkins: state.checkins.filter(el => action.payload !== el.id),
      };
    case actionTypes.REMOVE_CHECKIN_NOTIFIC:
      return {
        ...state,
        checkins: [],
      };
    default:
      return state;
  }
};
