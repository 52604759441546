import React, { useEffect, useRef } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, MenuItem } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { countPricePerBilling } from 'modules/services/utils/billingUtils';

import { initialValues } from 'common/components/Steps/TrialMembershipStep/initialValues';
import {
  MembershipConfigurationSchema,
  OutOfTermBillingOptionSchema,
} from 'common/components/Steps/TrialMembershipStep/ValidationSchema';

import { IEditablePackageConfiguration } from 'common/interfaces/membership';

import { DialogComponent, FieldInfo, Select } from 'common/components/index';
import AmountSection from './AmountSection/AmountSection';
import FreezeSection from './FreezeSection/FreezeSection';
import CreditCardFeeSection from './CreditCardFeeSection/CreditCardFeeSection';
import GracePeriodSection from './GracePeriodSection/GracePeriodSection';
import SplitPaymentSection from './SplitPaymentSection/SplitPaymentSection';

import servicesMessages from 'modules/services/messages/messages';
import packagesMessages from 'modules/services/messages/packages';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { IBillingOption, IPaymentEditableSchedule } from 'modules/services/interfaces/packages';

interface IProps {
  isOpen: boolean;
  isIncludedBilling?: boolean;
  onClose: () => void;
  onSubmit: (packageItem: IEditablePackageConfiguration) => void;
  membershipPackage: IEditablePackageConfiguration | null;
  isOutOfTerm?: boolean;
}

const EditBillingOptionModal = ({
  isOpen,
  isIncludedBilling,
  onClose,
  onSubmit,
  membershipPackage,
  isOutOfTerm,
}: IProps): JSX.Element => {
  const currentBillingOptionRef = useRef<IBillingOption | null>(null);

  const formMethods = useForm<IEditablePackageConfiguration>({
    defaultValues: { ...initialValues.packageConfiguration },
    resolver: yupResolver(
      isOutOfTerm ? (OutOfTermBillingOptionSchema as any) : (MembershipConfigurationSchema as any),
    ) as any, // TODO - PRM-1810 need resolver type
    mode: 'onBlur',
    shouldUnregister: false,
  });

  const {
    reset,
    control,
    formState: { errors },
    watch,
    handleSubmit,
    getValues,
    setValue,
  } = formMethods;

  const fieldsPath = isOutOfTerm ? 'outOfTermBillingOption.' : '';

  const billingOptionId = watch(`${fieldsPath}billingOption` as any); // TODO - PRM-1810 need type
  const paymentSchedule: IPaymentEditableSchedule = watch(`${fieldsPath}paymentEditableSchedule`);

  const { billingOptions, durationType, durationEditableNumber, customDates, revenueCode } =
    membershipPackage || {};

  const { totalTax } = revenueCode || {};

  if (billingOptions) {
    currentBillingOptionRef.current =
      billingOptions.find(option =>
        isOutOfTerm ? option.isOutOfTerm : billingOptionId === option.id,
      ) || null;
  }

  useEffect(() => {
    reset({
      ...initialValues.packageConfiguration,
      ...membershipPackage,
    });
  }, [membershipPackage, reset, isOutOfTerm]);

  useComponentDidUpdate(() => {
    const { totalAmount } = currentBillingOptionRef.current || {};

    const countedTaxedPricePerBilling = countPricePerBilling(
      totalAmount,
      totalTax,
      {
        type: durationType,
        amount: durationEditableNumber?.value,
        fromDate: customDates?.startDate,
        toDate: customDates?.endDate,
      },
      paymentSchedule?.value,
    );

    setValue('pricePerBilling', countedTaxedPricePerBilling || undefined);
  }, [paymentSchedule, setValue, durationType, durationEditableNumber, customDates, totalTax]);

  const renderIntlMessage = useRenderIntlMessage();

  const handleChangeBillingOption = (optionId: string) => {
    if (billingOptions) {
      currentBillingOptionRef.current =
        billingOptions.find(option => optionId === option.id) || null;
    }

    const {
      totalAmount,
      paymentSplitting,
      splitting,
      downPayment,
      paymentGrace,
      creditCardServiceFee,
      freeze,
      paymentEditableSchedule,
    } = currentBillingOptionRef.current || {};

    const countedTaxedPricePerBilling = countPricePerBilling(
      totalAmount,
      totalTax,
      {
        type: durationType,
        amount: durationEditableNumber?.value,
        fromDate: customDates?.startDate,
        toDate: customDates?.endDate,
      },
      paymentEditableSchedule?.value,
    );

    reset({
      ...getValues(),
      billingOption: optionId,
      totalAmount,
      splitting: splitting || paymentSplitting,
      downPayment,
      paymentGrace,
      paymentEditableSchedule,
      creditCardServiceFee,
      freeze,
      pricePerBilling: countedTaxedPricePerBilling || undefined,
    });
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={
        <FormattedMessage
          {...(isOutOfTerm
            ? packagesMessages.editOutOfTermBillingModalTitle
            : packagesMessages.editPaymentOptionModalTitle)}
        />
      }
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormProvider {...formMethods}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FieldInfo
                inputMode
                grayedOut
                label={<FormattedMessage {...servicesMessages.reVenueCodeTaxLabel} />}
                description={`${totalTax}%`}
              />
            </Grid>
            {!isOutOfTerm && (
              <Grid item xs={12}>
                <Controller
                  name="billingOption"
                  control={control}
                  render={({ field: { name, value, onBlur, onChange } }) => (
                    <Select
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      onChange={optionId => {
                        handleChangeBillingOption(optionId);
                        onChange(optionId);
                      }}
                      fullWidth
                      label={<FormattedMessage {...servicesMessages.billingOptionLabel} />}
                      disabled={isIncludedBilling || isOutOfTerm}
                      error={!!errors?.billingOption}
                      helperText={renderIntlMessage(errors.billingOption?.message)}
                    >
                      {billingOptions
                        ?.filter(el => isOutOfTerm || !el.isOutOfTerm)
                        .map(el => (
                          <MenuItem key={el.id} value={el.id}>
                            {el.title}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </Grid>
            )}
            {Boolean(currentBillingOptionRef.current) && (
              <>
                <AmountSection
                  membershipPackage={membershipPackage}
                  billingOption={currentBillingOptionRef.current}
                  isOutOfTerm={!!isOutOfTerm}
                />

                {currentBillingOptionRef.current?.freeze && (
                  <FreezeSection isOutOfTerm={!!isOutOfTerm} />
                )}

                {currentBillingOptionRef.current?.splitting?.allow && (
                  <SplitPaymentSection isOutOfTerm={!!isOutOfTerm} />
                )}

                {currentBillingOptionRef.current?.paymentGrace?.allow && (
                  <GracePeriodSection isOutOfTerm={!!isOutOfTerm} />
                )}

                {currentBillingOptionRef.current?.creditCardServiceFee?.enabled && (
                  <CreditCardFeeSection isOutOfTerm={!!isOutOfTerm} />
                )}
              </>
            )}
          </Grid>
        </form>
      </FormProvider>
    </DialogComponent>
  );
};

export default EditBillingOptionModal;
