import React, { useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Box, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import useRootSelector from 'common/hooks/useRootSelector';

import { PeakModules } from 'common/constants/peakModules';
import { NumberTextField } from 'common/components/index';
import commonMessages from 'common/messages/messages';
import personProfileMessages from 'common/components/PersonProfile/messages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { selectCurrentInvoice, selectUpdatedInvoiceLoading } from 'common/state/invoice/selectors';
import Button from 'common/components/Button/Button';
import { IInvoicePaymentSplit, PaymentsType } from 'common/interfaces/invoices';
import { updateInvoiceWithSync } from 'common/state/invoice/actions';
import { useAppDispatch } from 'store/hooks';
import { StepContext } from 'common/createContext/stepContext';
import { useUpdatePaymentData } from '../useUpdatePaymentData';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { fetchProfileInfoView } from 'common/components/PersonProfile/state/actions';

import { defaultNumberFormatProps } from 'common/components/NumberController/NumberController';
import { getClientRewardsValidationSchema } from './clientRewardsValidationSchema';

interface IOnAccountStepProps {
  module: PeakModules;
  isPaymentStep: boolean;
  paymentTypeId: string;
  rewards: number;
  leftToPay: number;
  memberId: number;
  clubId: string;
  onClose: () => void;
}

const ClientRewardsStep = (props: IOnAccountStepProps): JSX.Element => {
  const {
    module,
    memberId,
    isPaymentStep,
    paymentTypeId,
    onClose,
    rewards,
    clubId,
    leftToPay,
  } = props;

  const dispatch = useAppDispatch();
  const currentInvoice = useRootSelector(selectCurrentInvoice);
  const isUpdatingInvoiceLoading = useRootSelector(selectUpdatedInvoiceLoading);
  const renderIntlMessage = useRenderIntlMessage();
  const { helperData } = useContext(StepContext);
  const { profileId } = helperData || {};

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      amount: 0,
    },
    resolver: yupResolver(getClientRewardsValidationSchema(rewards)),
  });

  const updatedInvoiceResult = useUpdatePaymentData(module, profileId, onClose);

  useComponentDidUpdate(() => {
    if (updatedInvoiceResult && profileId === memberId && module === PeakModules.FrontDesk) {
      dispatch(fetchProfileInfoView(profileId, module));
    }
  }, [updatedInvoiceResult, profileId, memberId, module, dispatch]);

  const handleProceed = data => {
    const invoiceData = currentInvoice.toJS();
    const invoicePaymentSplit: IInvoicePaymentSplit = {
      paymentMethodId: paymentTypeId,
      paymentAmount: leftToPay,
      type: PaymentsType.MEMBER_REWARDS,
      rewardPoints: data.amount,
    };

    dispatch(
      updateInvoiceWithSync(
        module,
        clubId,
        invoiceData,
        invoicePaymentSplit,
        profileId,
        isPaymentStep,
      ),
    );
  };

  return (
    <Box>
      <Box pb={2} display="flex" justifyContent="space-between">
        <Typography color="primary">
          <FormattedMessage {...commonMessages.clientRewardsLabel} />
        </Typography>
        <Typography color="primary">{rewards}</Typography>
      </Box>
      <Box pt={1} pb={3}>
        <Controller
          control={control}
          name="amount"
          render={({ field }) => (
            <NumberTextField
              value={field.value}
              onChange={field.onChange}
              variant="outlined"
              onBlur={field.onBlur}
              fullWidth
              label={<FormattedMessage {...personProfileMessages.amountRewardsLabel} />}
              numberFormatProps={{
                ...defaultNumberFormatProps,
                maxLength: 11,
              }}
              error={!!errors.amount}
              helperText={renderIntlMessage(errors.amount?.message)}
            />
          )}
        />
      </Box>
      <Box pb={2}>
        <Button
          disabled={isUpdatingInvoiceLoading}
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleSubmit(handleProceed)}
        >
          <FormattedMessage {...commonMessages.proceedBtn} />
        </Button>
      </Box>
    </Box>
  );
};

export default ClientRewardsStep;
