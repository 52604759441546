import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm, FormProvider } from 'react-hook-form';
import useRootSelector from 'common/hooks/useRootSelector';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment-timezone';
import * as yup from 'yup';
import { Grid, makeStyles, Typography, Theme } from '@material-ui/core';

import { useAppDispatch } from 'store/hooks';

import {
  fetchSenderAvailabilityThunk,
  resetBookingEvents,
} from 'modules/booking/state/senderAvailability/actions';
import {
  selectSenderAvailability,
  selectSenderAvailabilityLoading,
} from 'modules/booking/state/senderAvailability/selectors';

import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { PeakModules } from 'common/constants/peakModules';
import {
  DefaultNotificationsEvents,
  EShortNotificationType,
} from 'modules/booking/constants/notificationType';

import {
  IBookingEventImt,
  INotifyVariant,
  IRemindAppointmentDto,
} from 'modules/booking/interfaces';

import { DialogComponent, LoadingBackdrop } from 'common/components';
import NotifyVariant from 'modules/booking/components/Modals/NotifyVariant';
import { ReactComponent as CalendarIcon } from 'img/icons/calendar.svg';

import messages from 'modules/booking/messages';
import commonMessages from 'common/messages/messages';
import { DATE_FORMAT } from 'common/hooks/useTimezoneMoment';

const useStyles = makeStyles((theme: Theme) => ({
  eventName: {
    display: 'flex',
    alignItems: 'center',
  },
  calendarIcon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.darkBackground?.light,
  },
}));

interface IProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onRemind: (formValues: IRemindAppointmentDto) => void;
  type: SenderAvailabilityTypeList;
  module?: PeakModules;
  // TODO Fix event field type. The field can be appointment and bookingEvent
  event: IBookingEventImt | null;
}

const ValidationSchema = yup.object().shape({
  notifyViaEmail: yup.boolean().defined(),
  notifyViaSms: yup.boolean().defined(),
  notifyViaNotification: yup.boolean().defined(),
});

const RemindParticipantsModal = ({
  isOpen,
  onClose,
  onRemind,
  event,
  type,
  module,
  isLoading = false,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const isSenderAvailabilityLoading: boolean = useRootSelector(
    selectSenderAvailabilityLoading(type),
  );
  const senderAvailability = useRootSelector(selectSenderAvailability(type));

  const formMethods = useForm<any>({
    defaultValues: {
      notifyViaEmail: false,
      notifyViaSms: false,
      notifyViaNotification: false,
    },
    resolver: yupResolver(ValidationSchema),
    mode: 'onBlur',
  });

  const { handleSubmit } = formMethods;

  const classes = useStyles();

  useEffect(() => {
    dispatch(
      fetchSenderAvailabilityThunk([type], {
        module,
        events: [DefaultNotificationsEvents[EShortNotificationType.Remind]],
      }),
    );

    return () => {
      dispatch(resetBookingEvents({ type }));
    };
  }, [dispatch, module, type]);

  const onFormSubmit = (formValues: INotifyVariant) => {
    const eventData = event?.toJS();
    let fullDate = eventData?.date;

    if (eventData && 'time' in eventData) {
      fullDate = moment
        .utc(`${eventData.date} ${eventData.time}`, 'YYYY-MM-DD HH:mm')
        .format(DATE_FORMAT);
    }

    onRemind({ ...formValues, date: fullDate || '' });
  };

  const emailAvailable = senderAvailability?.get('emailAvailable');
  const smsAvailable = senderAvailability?.get('smsAvailable');
  const notificationAvailable = senderAvailability?.get('notificationAvailable');

  const disabled = !emailAvailable && !smsAvailable && !notificationAvailable;

  return (
    <DialogComponent
      size="xs"
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage {...messages.remindParticipantsModalTitle} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.sendBtn} />}
      disabled={disabled}
      onSubmit={handleSubmit(onFormSubmit)}
      loading={isLoading}
      formId="remind-event-form"
    >
      <FormProvider {...formMethods}>
        <form id="remind-event-form">
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.eventName}>
              <CalendarIcon className={classes.calendarIcon} />

              <Typography color="textSecondary">{event?.get('title')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <NotifyVariant senderAvailability={senderAvailability} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <LoadingBackdrop isLoading={isSenderAvailabilityLoading} />
    </DialogComponent>
  );
};

export default RemindParticipantsModal;
