import React, { useCallback, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { batch, useDispatch } from 'react-redux';
import isNumber from 'lodash/isNumber';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { IAllergiesFormValues } from 'modules/members/interfaces';
import { IKidProfileImt } from 'modules/kid-zone/interfaces';
import { IBlocksSectionProps } from 'common/components/PersonProfile/interfaces';
import {
  IFormEmergencyContact,
  IReqPutEmergencyPersonPayload,
  TAllergyImtList,
} from 'common/interfaces/additionalInfo';
import { DictionaryList } from 'common/constants';
import { fetchDictionaryList, resetDictionaryListAction } from 'common/state/dictionary/actions';
import {
  fetchAllergiesThunk,
  resetAllergies,
  updateAllergiesThunk,
} from 'modules/kid-zone/state/actions';
import { KidZoneCheckInsHistory, Notes } from 'common/components/PersonProfile/components';
import { Allergies, AllergiesModal } from 'modules/members/components';
import { selectAllergies, selectAllergiesIsLoading } from 'modules/kid-zone/state/selectors';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { PeakModules } from 'common/constants/peakModules';
import { useGetEmergencyContact, usePutEmergencyContact } from 'modules/members/api/member.api';
import useRootSelector from 'common/hooks/useRootSelector';
import EmergencyContact from 'modules/members/components/EmergencyContact/EmergencyContact';
import EmergencyContactForm from 'modules/members/components/EmergrncyContactForm/EmergencyContactForm';

const BlocksSection = ({
  profile,
  module,
  isLessThanLaptop,
}: IBlocksSectionProps<IKidProfileImt>): JSX.Element => {
  const dispatch = useDispatch();
  const personId = profile?.get('id');
  const reqPersonId = isNumber(personId) ? personId.toString() : '';
  const [putEmergencyContact, { isLoading: putECLoading }] = usePutEmergencyContact();

  const { data: eContact, isLoading: getEContactLoading } = useGetEmergencyContact(reqPersonId, {
    skip: !reqPersonId,
  });

  const allergyDictionaryList: TAllergyImtList = useRootSelector(
    selectDictionaryList(DictionaryList.ALLERGIES),
  );
  const selectedAllergies = useRootSelector(selectAllergies);
  const isLoadingAllergies = useRootSelector(selectAllergiesIsLoading);

  const [isOpenAllergiesModal, setIsOpenAllergiesModal] = useState(false);
  const [isOpenEmergencyContactModal, setIsOpenEmergencyContactModal] = useState(false);

  useEffect(() => {
    if (profile?.size) {
      if (personId) {
        dispatch(fetchAllergiesThunk(personId));
      }

      dispatch(fetchDictionaryList(DictionaryList.ALLERGIES));
    }
  }, [dispatch, module, profile, personId]);

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(resetAllergies());
        dispatch(resetDictionaryListAction({ dictionary: DictionaryList.ALLERGIES }));
      });
    };
  }, [dispatch]);

  const onOpenAllergiesModal = useCallback(() => {
    setIsOpenAllergiesModal(true);
  }, []);

  const onCloseAllergiesModal = useCallback(() => {
    setIsOpenAllergiesModal(false);
  }, []);

  const onSubmitAllergiesModal = (values: IAllergiesFormValues) => {
    if (personId) {
      dispatch(updateAllergiesThunk(personId, values));
    }
  };

  const onOpenEmergencyContactModal = () => {
    setIsOpenEmergencyContactModal(true);
  };

  const onCloseEmergencyContactForm = useCallback(() => {
    setIsOpenEmergencyContactModal(false);
  }, []);

  const onSubmitEmergencyContactForm = useCallback(
    (values: IFormEmergencyContact) => {
      if (isNumber(personId)) {
        const reqPayload: IReqPutEmergencyPersonPayload = {
          personId,
          emergencyContact: {
            emergencyName: values.emergencyName || null,
            emergencyPhone: values.emergencyPhone || null,
            emergencyRelationship: values.emergencyRelationship || null,
          },
        };

        putEmergencyContact(reqPayload)
          .unwrap()
          .then(onCloseEmergencyContactForm);
      }
    },
    [personId, putEmergencyContact, onCloseEmergencyContactForm],
  );

  useComponentDidUpdate(() => {
    if (selectedAllergies) {
      onCloseAllergiesModal();
    }
  }, [selectedAllergies, onCloseAllergiesModal]);

  return (
    <>
      <Grid item xs={6}>
        <EmergencyContact
          emergencyContact={eContact}
          onOpenModal={onOpenEmergencyContactModal}
          loading={getEContactLoading}
        />
      </Grid>

      <Grid item xs={6}>
        <Allergies allergies={selectedAllergies} onOpenModal={onOpenAllergiesModal} />
      </Grid>

      {module ? (
        <Grid item xs={12}>
          <Notes module={module} customerId={Number(personId)} isMobile={!!isLessThanLaptop} />
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <KidZoneCheckInsHistory personId={Number(personId)} module={PeakModules.KidZone} />
      </Grid>

      {isOpenAllergiesModal && (
        <AllergiesModal
          allergies={selectedAllergies}
          allergyDictionaryList={allergyDictionaryList}
          onSubmit={onSubmitAllergiesModal}
          onClose={onCloseAllergiesModal}
          isOpen={isOpenAllergiesModal}
          isLoading={isLoadingAllergies}
        />
      )}

      {isOpenEmergencyContactModal && (
        <EmergencyContactForm
          onSubmit={onSubmitEmergencyContactForm}
          onClose={onCloseEmergencyContactForm}
          emergencyContact={eContact}
          loading={putECLoading}
        />
      )}
    </>
  );
};

export default BlocksSection;
