import {
  CheckInListPanel,
  CheckInProfilePanel,
  KidProfile,
  ManualCheckIn,
} from 'modules/kid-zone/containers';
import { Grid, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { batch } from 'react-redux';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';
import {
  selectCheckInChildActionResult,
  selectKidZoneMainPanelPersonId,
  selectKidZoneRightPanelPersonId,
} from 'modules/kid-zone/state/selectors';
import { ActionResult } from 'common/constants';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import {
  resetCheckInActionStatus,
  setKidZoneRightPanelPersonAction,
} from 'modules/kid-zone/state/actions';
import useRootSelector from 'common/hooks/useRootSelector';

const useStyles = makeStyles(() => ({
  checkInProfilePanel: {
    height: '100%',
    minWidth: 0,
    flex: 1,
  },
  rightPanel: {
    height: '100%',
  },
}));

interface IKidZoneMain {
  isGuardianProfileOpen: boolean;
  isManualCheckInPanelOpen: boolean;
  setIsManualCheckInPanelOpen: (val: boolean) => void;
  onScanningBarcodeSuccess: (scannerString: string) => void;
}

const KidZoneMain = ({
  isGuardianProfileOpen,
  isManualCheckInPanelOpen,
  setIsManualCheckInPanelOpen,
  onScanningBarcodeSuccess,
}: IKidZoneMain): JSX.Element => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const selectedClubId: string = useRootSelector(selectUserSelectedClubId);
  const mainPanelPersonId = useRootSelector(selectKidZoneMainPanelPersonId);
  const rightPanelPersonId = useRootSelector(selectKidZoneRightPanelPersonId);
  const checkInChildActionResult: ActionResult = useRootSelector(selectCheckInChildActionResult);

  useComponentDidUpdate(() => {
    if (checkInChildActionResult === ActionResult.SUCCESS_ACTION) {
      batch(() => {
        dispatch(setKidZoneRightPanelPersonAction(null));
        dispatch(resetCheckInActionStatus());
      });
    }
  }, [checkInChildActionResult]);

  const onCloseSecondMemberPanel = useCallback(() => {
    dispatch(setKidZoneRightPanelPersonAction(null));
  }, [dispatch]);

  const onCloseManualCheckinPanel = useCallback(() => {
    setIsManualCheckInPanelOpen(false);
  }, [setIsManualCheckInPanelOpen]);

  return (
    <>
      <CheckInListPanel onScanningBarcodeSuccess={onScanningBarcodeSuccess} />

      <Grid container spacing={2} style={{ overflow: 'auto' }}>
        <Grid item xs={isGuardianProfileOpen ? 6 : 12} className={classes.checkInProfilePanel}>
          <CheckInProfilePanel
            onCloseManualCheckinPanel={onCloseManualCheckinPanel}
            clientId={selectedClubId}
            personId={mainPanelPersonId}
          />
        </Grid>

        {isManualCheckInPanelOpen && (
          <Grid item xs={6} className={classes.rightPanel}>
            <ManualCheckIn profileId={mainPanelPersonId} onClose={onCloseManualCheckinPanel} />
          </Grid>
        )}

        {!isManualCheckInPanelOpen && rightPanelPersonId && (
          <Grid item xs={6} className={classes.rightPanel}>
            <KidProfile
              personId={rightPanelPersonId}
              clientId={selectedClubId}
              closable
              onCloseManualCheckinPanel={onCloseManualCheckinPanel}
              onClose={onCloseSecondMemberPanel}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default KidZoneMain;
