import { defineMessages } from 'react-intl';

export default defineMessages({
  newTaxRateTitle: {
    id: 'app.modules.pos-settings.TaxRates.newTaxRate.title',
    defaultMessage: 'New Tax Rate',
  },
  editTaxRateTitle: {
    id: 'app.modules.pos-settings.TaxRates.editTaxRate.title',
    defaultMessage: 'Edit tax rate',
  },
  editTaxesTitle: {
    id: 'app.modules.pos-settings.TaxRates.editTaxes.title',
    defaultMessage: 'Edit Taxes',
  },
  bundleOptionTitle: {
    id: 'app.modules.pos-settings.TaxRates.bundleOption.title',
    defaultMessage: ' (Bundle)',
  },
  singleLabel: {
    id: 'app.modules.pos-settings.TaxRates.labels.single',
    defaultMessage: 'Single',
  },
  bundleLabel: {
    id: 'app.modules.pos-settings.TaxRates.labels.bundle',
    defaultMessage: 'Bundle',
  },
  makeCorporateLabel: {
    id: 'app.modules.pos-settings.TaxRates.labels.makeCorporate',
    defaultMessage: 'Make corporate (Available in all clubs)',
  },
  deleteTaxRateLabel: {
    id: 'app.modules.pos-settings.TaxRates.labels.deleteTaxRate',
    defaultMessage: 'Delete Tax Rate',
  },
  noTaxRateOverridesMsg: {
    id: 'app.modules.pos-settings.taxRates.noOverridesMsg',
    defaultMessage: 'No tax rate overrides created yet.',
  },
  noTaxRateAvailabilitiesMsg: {
    id: 'app.modules.pos-settings.taxRates.noAvailabilitiesMsg',
    defaultMessage: 'No tax rates created yet.',
  },
  // change type modal
  changeTypeModalTitle: {
    id: 'app.modules.pos-settings.taxRates.changeTypeModal.title',
    defaultMessage: 'Change type to {count, plural, =0 {Single} other {Bundle}}',
  },
  changeTypeModalMessage1: {
    id: 'app.modules.pos-settings.taxRates.changeTypeModal.msg1',
    defaultMessage: 'Are you sure you want to change tax type to ',
  },
  changeTypeModalMessage2: {
    id: 'app.modules.pos-settings.taxRates.changeTypeModal.msg2',
    defaultMessage: 'All previously entered clubs settings data will be lost.',
  },
  totalTaxLabel: {
    id: 'app.modules.pos-settings.TaxRates.labels.totalTax',
    defaultMessage: 'Total tax',
  },
  searchTaxesPlaceholder: {
    id: 'app.modules.pos-settings.TaxRates.labels.searchTaxes',
    defaultMessage: 'Search to add Taxes',
  },
  inheritedFromCorporationLabel: {
    id: 'app.modules.pos-settings.TaxRates.labels.inheritedFromCorporation',
    defaultMessage: 'Inherited from corporation',
  },
  taxRateTitleMaxLength: {
    id: 'app.modules.pos-settings.TaxRates.taxRateTitleMaxLength',
    defaultMessage: 'Name must be 100 characters or less',
  },
  taxRateCodeMaxLength: {
    id: 'app.modules.pos-settings.TaxRates.taxRateCodeMaxLength',
    defaultMessage: 'Code must be 100 characters or less',
  },
});
