import React from 'react';
import { Box, Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import classnames from 'classnames';

import { ReactComponent as ReplayIcon } from 'img/icons/reset.svg';

import FiltersCounter from '../FiltersCounter/FiltersCounter';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 1.5),
    background: 'white',
    fontWeight: 400,
    textTransform: 'capitalize',
    maxWidth: 200,
    height: 32,
    color: theme.palette.text.primary,
  },
  bold: {
    fontWeight: 500,
  },
  selected: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  label: {
    whiteSpace: 'nowrap',
    lineHeight: '20px',
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  replayContainer: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    zIndex: 1,
    right: 0,
    top: 0,
    borderRadius: 3,
    cursor: 'pointer',
    height: 18,
    width: 20,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconReplay: {
    fill: theme.palette.background.paper,
    height: 16,
    width: 16,
    padding: '0 2px 0px',
  },
}));

interface IProps {
  bold?: boolean;
  filtersTotal?: number;
  active?: boolean;
  isShowReset?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  reset?: (event: React.MouseEvent<HTMLElement>) => void;
}

const FilterButton = (props: React.PropsWithChildren<IProps>) => {
  const classes = useStyles();
  const { bold, filtersTotal, active, onClick, children, isShowReset, reset } = props;

  const selected = filtersTotal || active;

  return (
    <Button
      variant="contained"
      className={classnames('filter-button', classes.root, {
        [classes.bold]: bold,
        [classes.selected]: selected,
        selected,
      })}
      classes={{ label: classes.label }}
      endIcon={
        <div className={classes.iconsContainer}>
          <FiltersCounter count={Number(filtersTotal)} />
          <ArrowDropDownIcon fontSize="small" />
        </div>
      }
      onClick={onClick}
    >
      {isShowReset && (
        <Box className={classes.replayContainer} onClick={reset}>
          <ReplayIcon className={classes.iconReplay} />
        </Box>
      )}
      {children}
    </Button>
  );
};

export default React.memo(FilterButton);
