import { array, boolean, object, string } from 'yup';
import { getRequiredErrorMessage } from 'common/utils/validation';
import {
  getMinMaxNumberSchema,
  minMaxNumberSchema,
} from 'common/validationSchemas/minMaxNumberSchema';
import { FrequencyType } from '../../../constants/packages';
import { FeeType, IFee } from '../../../interfaces/fees';

export const includedFeeTypeSchema = object().shape({
  id: string().required(getRequiredErrorMessage),
  type: string().oneOf(Object.values(FeeType)),
});

export const includedFeeSchema = object().shape({
  id: string().nullable(),
  excludable: boolean().required(getRequiredErrorMessage),
  fee: includedFeeTypeSchema.required(getRequiredErrorMessage),
  totalAmount: getMinMaxNumberSchema(1).required(getRequiredErrorMessage),
  chargeAfterDays: minMaxNumberSchema.required(getRequiredErrorMessage),
  allowSplitting: boolean()
    .nullable()
    .when('fee', {
      is: fee => fee.type === FeeType.OneTime,
      then: boolean().required(getRequiredErrorMessage),
    }),
  paymentSchedule: string()
    .nullable()
    .when('fee', {
      is: (fee: IFee): boolean => fee.type === FeeType.Regular,
      then: string()
        .oneOf(Object.values(FrequencyType))
        .required(getRequiredErrorMessage),
    }),
  paymentsNumber: object()
    .nullable()
    .when(['fee', 'allowSplitting'], {
      is: (fee: IFee, allowSplitting: boolean): boolean =>
        fee.type === FeeType.OneTime && allowSplitting,
      then: minMaxNumberSchema.required(getRequiredErrorMessage),
    }),
});

export const feesSchema = object().shape({
  includedFees: array()
    .of(includedFeeSchema)
    .notRequired(),
});
