import { createAction } from '@reduxjs/toolkit';
import Services from 'services/network';
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from 'common/state/notifications/actions';
import { actionTypes } from 'modules/crm/constants/campaigns';
import { ActionResult } from 'common/constants';
import { IPaginatedCampaigns } from 'services/network/CampaignsService';
import { ITableParams } from 'common/interfaces/table';
import { ICampaignDto } from 'common/interfaces/campaign';
import { ICampaignForm } from 'modules/crm/interfaces/campaigns';
import { GeneralThunkAction } from 'common/state/interfaces';
import messages from 'modules/crm/messages/campaigns';
import { PeakModules } from 'common/constants/peakModules';

const resetCampaignFormAction = createAction(actionTypes.RESET_CAMPAIGN_FORM);

const fetchCampaignsListLoadingAction = createAction<boolean>(
  actionTypes.FETCH_CAMPAIGNS_LIST_LOADING,
);
const fetchCampaignsListSuccessAction = createAction<IPaginatedCampaigns | null>(
  actionTypes.FETCH_CAMPAIGNS_LIST_SUCCESS,
);

export const fetchCampaigns = (
  pageOptions?: ITableParams,
  module?: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchCampaignsListLoadingAction(true));
    try {
      let paginatedData: IPaginatedCampaigns | null;

      switch (module) {
        case PeakModules.Crm:
          paginatedData = await Services.CampaignsService.getCampaigns(pageOptions);
          break;
        case PeakModules.PersonalTrainingCrm:
          paginatedData = await Services.PtCampaignsService.getCampaigns(pageOptions);
          break;
        default:
          paginatedData = null;
      }
      dispatch(fetchCampaignsListSuccessAction(paginatedData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchCampaignsListLoadingAction(false));
    }
  };
};

const fetchCampaignLoadingAction = createAction<boolean>(actionTypes.FETCH_CAMPAIGN_LOADING);
const fetchCampaignSuccessAction = createAction<ICampaignDto | null>(
  actionTypes.FETCH_CAMPAIGN_SUCCESS,
);

export const fetchCampaignById = (
  campaignId: string,
  cb?: (data) => void,
  module?: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchCampaignLoadingAction(true));
    try {
      let campaign: ICampaignDto | null;

      switch (module) {
        case PeakModules.Crm:
          campaign = await Services.CampaignsService.getCampaignById(campaignId);
          break;
        case PeakModules.PersonalTrainingCrm:
          campaign = await Services.PtCampaignsService.getCampaignById(campaignId);
          break;
        default:
          campaign = null;
      }
      dispatch(fetchCampaignSuccessAction(campaign));
      if (cb) {
        cb(campaign);
      }
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchCampaignLoadingAction(false));
    }
  };
};

const createCampaignLoading = createAction<boolean>(actionTypes.CREATE_CAMPAIGN_LOADING);
const createCampaignAction = createAction<ICampaignDto | null>(actionTypes.CREATE_CAMPAIGN_SUCCESS);

export const createCampaign = (
  campaignForm: ICampaignForm,
  module?: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createCampaignLoading(true));
    try {
      let newCampaign: ICampaignDto | null;

      switch (module) {
        case PeakModules.Crm:
          newCampaign = await Services.CampaignsService.createCampaign(campaignForm);
          break;
        case PeakModules.PersonalTrainingCrm:
          newCampaign = await Services.PtCampaignsService.createCampaign(campaignForm);
          break;
        default:
          newCampaign = null;
      }
      dispatch(createCampaignAction(newCampaign));
      dispatch(enqueueSuccessNotification(messages.campaignCreatedMessage));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createCampaignLoading(false));
    }
  };
};

const createCampaignUpdatingAction = createAction<boolean>(actionTypes.UPDATE_CAMPAIGN_LOADING);
const createCampaignUpdatedSuccess = createAction<ActionResult>(
  actionTypes.UPDATE_CAMPAIGN_SUCCESS,
);

export const updateCampaign = (
  campaignId: string,
  campaignForm: ICampaignForm,
  module?: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createCampaignUpdatingAction(true));
    try {
      switch (module) {
        case PeakModules.Crm:
          await Services.CampaignsService.updateCampaign(campaignId, campaignForm);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PtCampaignsService.updateCampaign(campaignId, campaignForm);
          break;
        default:
      }
      dispatch(createCampaignUpdatedSuccess(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createCampaignUpdatingAction(false));
    }
  };
};

export const resetCampaignForm = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(resetCampaignFormAction());
  };
};
