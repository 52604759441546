import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, MenuItem } from '@material-ui/core';

import { Select } from 'common/components';
import { IDayTimeAvailabilityDto } from 'common/interfaces/common';
import { DayTimeAvailabilityModal } from 'common/modals';
import AvailabilitiesResultBlock from './AvailabilitiesResultBlock';

import messages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';

interface IAvailabilityScheduleSelectorProps {
  defaultValue?: IDayTimeAvailabilityDto[];
  label?: string | JSX.Element;
  defaultOptionLabel?: string | JSX.Element;
  validAvailabilityRange?: IDayTimeAvailabilityDto[];
  showSelect?: boolean;
  disableSelect?: boolean;

  onChange: (availabilities: IDayTimeAvailabilityDto[]) => void;
}

enum AvailabilityType {
  Default = 'DEFAULT',
  Custom = 'CUSTOM',
}

const AvailabilityScheduleSelector: React.FC<IAvailabilityScheduleSelectorProps> = ({
  defaultValue,
  label = <FormattedMessage {...inputLabels.availability} />,
  defaultOptionLabel = <FormattedMessage {...inputLabels.availabilityNoLimit} />,
  validAvailabilityRange,
  onChange,
  disableSelect = false,
  showSelect = true,
}: IAvailabilityScheduleSelectorProps): JSX.Element => {
  const [isOpenAvailabilityModal, setIsOpenAvailabilityModal] = useState<boolean>(false);
  const [availabilities, setAvailabilities] = useState<IDayTimeAvailabilityDto[]>(
    defaultValue || [],
  );

  const availabilitiesExist = availabilities && !!availabilities.length;

  const handleAvailabilityTypeChange = useCallback(
    (type: AvailabilityType) => {
      if (type === AvailabilityType.Default) {
        setAvailabilities([]);
        onChange([]);
      }

      if (type === AvailabilityType.Custom && !availabilitiesExist) {
        setIsOpenAvailabilityModal(true);
      }
    },
    [availabilitiesExist, onChange],
  );

  const handleAvailabilityModalSubmit = useCallback(
    (updatedAvailabilities: IDayTimeAvailabilityDto[]): void => {
      setAvailabilities(updatedAvailabilities);
      setIsOpenAvailabilityModal(false);
    },
    [],
  );

  const handleAvailabilityModalCancel = useCallback(() => setIsOpenAvailabilityModal(false), []);
  const handleEditAvailability = useCallback(() => setIsOpenAvailabilityModal(true), []);

  useEffect(() => {
    if (availabilities.length) {
      onChange(availabilities);
    }
  }, [availabilities, onChange]);

  useEffect(() => {
    setAvailabilities(defaultValue || []);
  }, [defaultValue]);

  return (
    <Grid container spacing={2} direction="column">
      {showSelect && (
        <Grid item xs={12}>
          <Select
            fullWidth
            variant="outlined"
            label={label}
            disabled={disableSelect}
            onChange={handleAvailabilityTypeChange}
            value={availabilitiesExist ? AvailabilityType.Custom : AvailabilityType.Default}
          >
            <MenuItem value={AvailabilityType.Default}>{defaultOptionLabel}</MenuItem>

            <MenuItem value={AvailabilityType.Custom}>
              <FormattedMessage {...messages.customTitle} />
            </MenuItem>
          </Select>
        </Grid>
      )}

      {availabilitiesExist && (
        <Grid item xs={12}>
          <AvailabilitiesResultBlock
            availabilities={availabilities}
            onEdit={handleEditAvailability}
          />
        </Grid>
      )}

      {isOpenAvailabilityModal && (
        <DayTimeAvailabilityModal
          open={isOpenAvailabilityModal}
          availabilitySettings={availabilities}
          validAvailabilityRange={validAvailabilityRange}
          onSubmit={handleAvailabilityModalSubmit}
          onCancel={handleAvailabilityModalCancel}
        />
      )}
    </Grid>
  );
};

export default React.memo(AvailabilityScheduleSelector);
