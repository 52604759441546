import React, { useEffect, useMemo } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Chip, Grid, makeStyles, Theme } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import {
  IEditablePackageConfiguration,
  IPackageConfigurationImt,
} from 'common/interfaces/membership';

import { DurationType, DurationTypes, PackageTypes } from 'modules/services/constants/packages';
import { initialValues } from 'common/components/Steps/TrialMembershipStep/initialValues';
import { minMaxNumberSchema } from 'common/validationSchemas/minMaxNumberSchema';

import {
  AvailabilityScheduleSelector,
  DialogComponent,
  FieldInfo,
  NumberTextField,
} from 'common/components/index';

import inputLabels from 'common/messages/inputLabels';
import packagesMessages from 'modules/services/messages/packages';
import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    height: 'initial',
    padding: theme.spacing(0.25, 1),
    marginBottom: theme.spacing(0.5),

    color: theme.palette.primary.contrastText,
    background: theme.palette.darkBackground?.light,
    fontWeight: 700,

    '& .MuiChip-label': {
      padding: 0,
      lineHeight: '1rem',
    },

    '&:not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
  },
}));

const ValidationSchema = yup
  .object()
  .shape({ durationEditableNumber: minMaxNumberSchema.nullable() });

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (packageItem: IEditablePackageConfiguration) => void;
  membershipPackage: IEditablePackageConfiguration | null;
  initialMembershipPackage: IPackageConfigurationImt;
}

const EditGeneralPackageModal = ({
  isOpen,
  onClose,
  membershipPackage,
  onSubmit,
  initialMembershipPackage,
}: IProps): JSX.Element => {
  const formMethods = useForm<IEditablePackageConfiguration>({
    defaultValues: { ...initialValues.packageConfiguration },
    resolver: yupResolver(ValidationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onBlur',
    shouldUnregister: false,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = formMethods;

  const renderIntlMessage = useRenderIntlMessage();

  useEffect(() => {
    reset({
      ...initialValues.packageConfiguration,
      ...membershipPackage,
    });
  }, [membershipPackage, reset]);

  const transformedInitialPackage = useMemo(() => initialMembershipPackage.toJS(), [
    initialMembershipPackage,
  ]);

  const classes = useStyles();

  const customPeriodDescription: string = useMemo(() => {
    if (!membershipPackage?.customDates) {
      return '';
    }

    const {
      customDates: { startDate, endDate },
    } = membershipPackage;

    return `${moment(startDate).format('MMM D')} - ${moment(endDate).format('MMM D')}`;
  }, [membershipPackage]);

  const transformedAvailabilityList = membershipPackage?.dayTimeAvailDtoList;

  const durationEditableNumberError = errors.durationEditableNumber?.value;

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage {...packagesMessages.editGeneralPackageModalTitle} />}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormProvider {...formMethods}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FieldInfo
                inputMode
                grayedOut
                label={<FormattedMessage {...inputLabels.packageType} />}
                description={PackageTypes.translate(membershipPackage?.type || '')}
              />
            </Grid>

            <Grid item xs={6} sm={4}>
              <FieldInfo
                inputMode
                grayedOut
                label={<FormattedMessage {...inputLabels.duration} />}
                description={DurationTypes.translate(membershipPackage?.durationType || '')}
              />
            </Grid>

            <Grid item xs={6} sm={4}>
              {membershipPackage?.durationType === DurationType.Custom ? (
                <FieldInfo
                  inputMode
                  grayedOut
                  label={<FormattedMessage {...inputLabels.period} />}
                  description={customPeriodDescription}
                />
              ) : (
                <Controller
                  name="durationEditableNumber"
                  control={control}
                  defaultValue={0 as any} // TODO - PRM-1810 need type
                  render={({ field }) => {
                    const { value: duration, onChange, onBlur } = field;

                    return (
                      <NumberTextField
                        numberFormatProps={{ min: 0 }}
                        label={<FormattedMessage {...inputLabels.amount} />}
                        variant="outlined"
                        value={duration?.value}
                        onBlur={onBlur}
                        onChange={value => onChange({ ...duration, value })}
                        error={!!durationEditableNumberError}
                        helperText={renderIntlMessage(durationEditableNumberError?.message, {
                          value:
                            durationEditableNumberError?.type === 'max'
                              ? Number(duration.maxValue)
                              : Number(duration.minValue),
                        })}
                        fullWidth
                        disabled={!duration?.editable}
                      />
                    );
                  }}
                />
              )}
            </Grid>

            <Grid item xs={8}>
              <Controller
                name="dayTimeAvailDtoList"
                control={control}
                defaultValue={transformedAvailabilityList}
                render={({ field }) => (
                  <AvailabilityScheduleSelector
                    defaultValue={transformedAvailabilityList || undefined}
                    defaultOptionLabel={<FormattedMessage {...inputLabels.accessAsClub} />}
                    onChange={field.onChange}
                    validAvailabilityRange={
                      transformedInitialPackage.dayTimeAvailDtoList || undefined
                    }
                    disableSelect={!!transformedInitialPackage?.dayTimeAvailDtoList?.length}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FieldInfo
                label={<FormattedMessage {...commonMessages.clubsAvailabilityLabel} />}
                labelVariant="subtitle2"
                labelColor="textPrimary"
                description={
                  !membershipPackage?.clubs?.length ? (
                    <FormattedMessage {...packagesMessages.limitClubsAllAllowed} />
                  ) : (
                    undefined
                  )
                }
                content={
                  membershipPackage?.clubs?.length ? (
                    <Box display="flex" flexWrap="wrap">
                      {membershipPackage.clubs.map(clubItem => (
                        <Chip key={clubItem.id} label={clubItem.title} className={classes.chip} />
                      ))}
                    </Box>
                  ) : (
                    undefined
                  )
                }
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </DialogComponent>
  );
};

export default EditGeneralPackageModal;
