import { List as ImmutableList } from 'immutable';
import { AlertCondition, AlertTypes, IAlertSettingsImt } from 'common/interfaces/alerts';
import { IAlertSoundItemImt } from 'modules/dictionaries/interfaces/interfaces';

export const getAlertType = (
  alert: AlertCondition,
  alertList: ImmutableList<IAlertSettingsImt>,
): AlertTypes | null =>
  alertList.find(alertItem => alertItem.get('condition') === alert)?.get('type') || null;

const findAlertByType = (
  alerts: ImmutableList<IAlertSettingsImt>,
  alertItem: AlertCondition,
  alertType: AlertTypes,
) => getAlertType(alertItem, alerts) === alertType;

export const hasErrorAlert = (
  alertList: ImmutableList<IAlertSettingsImt>,
  alerts: AlertCondition[],
): boolean =>
  alerts?.some((alertItem: AlertCondition) =>
    findAlertByType(alertList, alertItem, AlertTypes.Danger),
  );

export const hasWarningAlert = (
  alertList: ImmutableList<IAlertSettingsImt>,
  alerts: AlertCondition[],
): boolean =>
  alerts?.some((alertItem: AlertCondition) =>
    findAlertByType(alertList, alertItem, AlertTypes.Warning),
  );

export function hasCelebrationAlert(alerts: AlertCondition[]): boolean {
  return alerts?.some(
    (alertItem: AlertCondition) =>
      alertItem === AlertCondition.Birthday || alertItem === AlertCondition.Anniversary,
  );
}

export const getTheMostImportantAlert = (
  alertList: ImmutableList<IAlertSettingsImt>,
  alerts: AlertCondition[],
): AlertCondition | null => {
  const dangerAlert = alerts.find((alertItem: AlertCondition) =>
    findAlertByType(alertList, alertItem, AlertTypes.Danger),
  );

  if (dangerAlert) {
    return dangerAlert;
  }

  const warningAlert = alerts.find((alertItem: AlertCondition) =>
    findAlertByType(alertList, alertItem, AlertTypes.Warning),
  );

  if (warningAlert) {
    return warningAlert;
  }

  return null;
};

export const getAlertSound = (
  alertList: ImmutableList<IAlertSettingsImt>,
  alertSounds: ImmutableList<IAlertSoundItemImt>,
  alerts: AlertCondition[],
): IAlertSoundItemImt | null => {
  const playableAlert = getTheMostImportantAlert(alertList, alerts);

  return alertSounds.find(soundItem => soundItem.get('condition') === playableAlert) || null;
};

export const getAlertColor = (
  alertList: ImmutableList<IAlertSettingsImt>,
  alerts: AlertCondition[],
): string => {
  if (hasErrorAlert(alertList, alerts)) {
    return 'error.main';
  }

  if (hasWarningAlert(alertList, alerts)) {
    return 'warning.main';
  }

  if (hasCelebrationAlert(alerts)) {
    return 'celebration.main';
  }

  return 'primary.main';
};
