import React, { useEffect, useState } from 'react';
// custom interfaces
import { EventUpdateType } from '../../../interfaces';
import { DialogComponent } from 'common/components';
import { FormattedMessage } from 'react-intl/lib';

import messages from '../../../messages';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

interface IUpdateTypeModalProps {
  open: boolean;

  onSubmit: (updateType: EventUpdateType) => void;
  onCancel: () => void;
}

const UpdateTypeModal: React.FC<IUpdateTypeModalProps> = ({
  open,
  onSubmit,
  onCancel,
}: IUpdateTypeModalProps): JSX.Element => {
  const [updateType, setUpdateType] = useState<EventUpdateType>(
    EventUpdateType.OnlyCurrentInstance,
  );

  const handleOnClose = () => onCancel();
  const handleOnSubmit = () => onSubmit(updateType);

  useEffect(() => {
    if (!open) {
      setUpdateType(EventUpdateType.OnlyCurrentInstance);
    }
  }, [open]);

  return (
    <DialogComponent
      isOpen={open}
      onClose={handleOnClose}
      onSubmit={handleOnSubmit}
      size="xs"
      title={<FormattedMessage {...messages.editRecurringModalTitle} />}
    >
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="recurringEventUpdateType"
          name="eventUpdateType"
          value={updateType}
          onChange={e => setUpdateType(e.target.value as EventUpdateType)}
        >
          <FormControlLabel
            value={EventUpdateType.OnlyCurrentInstance}
            control={<Radio />}
            label={<FormattedMessage {...messages.editRecurringThisEventOption} />}
          />
          <FormControlLabel
            value={EventUpdateType.CurrentAndAllNext}
            control={<Radio />}
            label={<FormattedMessage {...messages.editRecurringFutureEventOption} />}
          />
          <FormControlLabel
            value={EventUpdateType.All}
            control={<Radio />}
            label={<FormattedMessage {...messages.editRecurringAllEventOption} />}
          />
        </RadioGroup>
      </FormControl>
    </DialogComponent>
  );
};

export default React.memo(UpdateTypeModal);
