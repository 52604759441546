import * as yup from 'yup';
import { regExp } from 'common/constants/regExp';
import { pastOrTodayDateSchema } from 'common/validationSchemas/dateSchemas';
import { getAddressSchema } from 'common/validationSchemas/addressSchema';
import { phoneSchema } from 'common/validationSchemas/phoneSchema';
import { cameFromFieldsSchema } from 'common/validationSchemas/cameFromFieldsSchema';
import message from 'modules/crm/messages/leads';
import inputErrors from 'common/messages/inputErrors';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';
import { INamedEntity } from '../../../../interfaces/common';
import { getEmailErrorMessage, getRequiredErrorMessage } from 'common/utils/validation';

export const PrimaryInfoStepValidationSchema = (
  employees: INamedEntity<number>[],
  requiredFields: TShortProfileFieldInfoImt,
): yup.AnyObjectSchema => {
  return yup.object().shape(
    {
      image: yup
        .object()
        .shape({ id: yup.string() })
        .nullable()
        .when(RequiredProfileInfo.Photo, {
          is: () => requiredFields.get(RequiredProfileInfo.Photo) === FieldNecessity.Required,
          then: schema => schema.required(getRequiredErrorMessage),
          otherwise: schema => schema,
        }),

      firstName: yup
        .string()
        .trim()
        .matches(regExp.NAME, () => inputErrors.firstNameMatchError)
        .required(getRequiredErrorMessage),
      lastName: yup
        .string()
        .trim()
        .matches(regExp.NAME, () => inputErrors.lastNameMatchError)
        .required(getRequiredErrorMessage),
      birthday: pastOrTodayDateSchema.when(RequiredProfileInfo.Birthday, {
        is: () => requiredFields.get(RequiredProfileInfo.Birthday) === FieldNecessity.Required,
        then: schema => schema.required(getRequiredErrorMessage),
        otherwise: schema => schema,
      }),
      gender: yup
        .string()
        .nullable()
        .required(getRequiredErrorMessage),

      barcode: yup
        .string()
        .nullable()
        .notRequired()
        .when('barcode', {
          is: val => val?.length,
          then: rule =>
            rule
              .max(20, () => inputErrors.barcodeLengthError)
              .matches(regExp.BARCODE, () => inputErrors.barcodeMatchError),
        }),
      email: yup
        .string()
        .trim()
        .email(getEmailErrorMessage)
        .when(RequiredProfileInfo.Email, {
          is: () => requiredFields.get(RequiredProfileInfo.Email) === FieldNecessity.Required,
          then: schema => schema.required(getRequiredErrorMessage),
          otherwise: schema => schema,
        }),
      address: getAddressSchema(requiredFields),
      phones: yup
        .array(
          yup.object().shape({
            phone: phoneSchema.nullable().required(getRequiredErrorMessage),
          }),
        )
        .test({
          message: getRequiredErrorMessage,
          test: arr =>
            requiredFields.get(RequiredProfileInfo.PhoneNumber) !== FieldNecessity.Required ||
            !!arr?.length,
        }),
      salesperson: yup.mixed().when((employ, schema) => {
        if (employ?.id) {
          const isExistEmployee = Boolean(
            employees.find(item => {
              return item.id === Number(employ.id);
            }),
          );

          if (!isExistEmployee) {
            return schema.test(
              'salesperson',
              () => message.selectedSalespersonNotListAvailable,
              () => false,
            );
          }
        }

        if (requiredFields.get(RequiredProfileInfo.Salesperson) === FieldNecessity.Required) {
          return schema
            .notOneOf(['', null], getRequiredErrorMessage)
            .required(getRequiredErrorMessage);
        }

        return schema;
      }),
      ...cameFromFieldsSchema,
    },
    [['barcode', 'barcode']],
  );
};
