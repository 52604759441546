import React, { forwardRef, HTMLAttributes, PropsWithChildren, useEffect, useRef } from 'react';
import { FixedSizeList } from 'react-window';
import { Box } from '@material-ui/core';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import usePreviousValue from 'common/hooks/usePreviousValue';
import { LoadingBackdrop } from 'common/components/index';

interface IListboxComponentProps {
  itemSize?: number;
  isLoading?: boolean;
  limitCount: number;
  onScroll?: (event: React.BaseSyntheticEvent, list: FixedSizeList) => void;
}

const renderRow = props => {
  const { data, index, style } = props;

  if (!data[index]) {
    return null;
  }

  return React.cloneElement(data[index], {
    style,
  });
};

const ListboxComponent = forwardRef(
  (props: PropsWithChildren<HTMLAttributes<HTMLElement>> & IListboxComponentProps, ref) => {
    const {
      children,
      role,
      // additional props
      itemSize = 50,
      limitCount,
      isLoading,
      onScroll,

      ...other
    } = props;
    const outerRef = useRef<any>(null);
    const listRef = useRef<any>(null);

    const hasPaginate = Boolean(onScroll);

    useEffect(() => {
      if (onScroll) {
        const el = outerRef.current;

        const handleScroll = (e: React.BaseSyntheticEvent) => {
          onScroll(e, listRef.current);
        };

        el.addEventListener('scroll', handleScroll);

        return () => {
          el.removeEventListener('scroll', handleScroll);
        };
      }

      return () => {};
    }, [onScroll]);

    const itemCount = Array.isArray(children) ? children.length : 0;

    const itemData = React.Children.toArray(children);
    const getHeight = () => {
      if (itemCount > limitCount) {
        return itemSize * limitCount;
      }
      return itemData.map(() => itemSize).reduce((a, b) => a + b, 0);
    };

    const previousScrollHeight = usePreviousValue(outerRef.current?.scrollHeight || 0);
    const previousDataLength = usePreviousValue(itemData.length);

    useComponentDidUpdate(() => {
      if (hasPaginate && listRef && outerRef && itemData.length !== previousDataLength) {
        listRef.current.scrollTo(previousScrollHeight - outerRef.current.clientHeight);
      }
    }, [itemData, previousScrollHeight, hasPaginate]);

    return (
      <Box role={role} {...other} maxHeight="100% !important" {...{ ref }}>
        <LoadingBackdrop isLoading={isLoading} />
        <FixedSizeList
          className="List"
          ref={listRef}
          outerRef={outerRef}
          itemData={itemData}
          height={getHeight()}
          width="100%"
          key={itemCount}
          itemSize={itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </Box>
    );
  },
);

export default ListboxComponent;
