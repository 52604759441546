import baseApi from 'api/baseApi';
import { ITableParams } from 'common/interfaces/table';
import { IServisecHistoryItem } from 'modules/booking/interfaces';
import { IBundleView } from 'modules/services/interfaces/packages';
import { IInventoryDetailedInfo } from 'modules/reports/interfaces/invoiceHistory';
import { IPageMeta } from 'common/interfaces/pagination';
import { makeTableParams } from 'utils/api.utils';

const ServiceApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getServicesHistgory: builder.query<
      { data: IServisecHistoryItem[]; meta: IPageMeta },
      { params: ITableParams; personId: number }
    >({
      query: ({ params, personId }) => ({
        url: `/api/v1/members/${personId}/service-section/history`,
        params: makeTableParams(params),
      }),
    }),
    getServicesHistgoryPackage: builder.query<
      IBundleView,
      { personId: number; packageId: string | null }
    >({
      query: ({ personId, packageId }) => ({
        url: `/api/v1/members/${personId}/service-section/history/packages/${packageId}`,
      }),
    }),

    getServicesInventoryInfo: builder.query<
      IInventoryDetailedInfo,
      { personId: number; inventoryId: string | null }
    >({
      query: ({ personId, inventoryId }) => ({
        url: `/api/v1/members/${personId}/service-section/history/packages/inventories/${inventoryId}`,
      }),
    }),
  }),
});

export const {
  useGetServicesHistgoryQuery,
  useGetServicesHistgoryPackageQuery,
  useGetServicesInventoryInfoQuery,
} = ServiceApi;
