import { createAction } from '@reduxjs/toolkit';

import Services from 'services/network';

import { PeakModules } from 'common/constants/peakModules';
import { ActionResult } from 'common/constants';
import { actionTypes } from 'common/state/newPerson/membership/constants';

import { enqueueErrorNotification } from 'common/state/notifications/actions';

import {
  IConfiguredPackage,
  IMembershipTransformed,
  IPackageConfiguration,
  IPackageInventoryViewDto,
  IShortPackages,
} from 'common/interfaces/membership';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { GeneralThunkAction } from 'common/state/interfaces';

const fetchMembershipListIsLoading = createAction<boolean>(
  actionTypes.FETCH_MEMBERSHIP_PACKAGES_LIST_IS_LOADING,
);

const updateMembershipInfoLoading = createAction<boolean>(
  actionTypes.FETCH_UPDATE_MEMBERSHIP_INFO_LOADING,
);
export const updateMembershipInfoResult = createAction<ActionResult | null>(
  actionTypes.FETCH_UPDATE_MEMBERSHIP_INFO_RESULT,
);

export const setMembershipInfo = (
  memberId: number,
  data: IMembershipTransformed,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updateMembershipInfoLoading(true));

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDesk.updateMembershipInfo(memberId, data);
          break;
        case PeakModules.Members:
          await Services.Members.updateMembershipInfo(memberId, data);
          break;
        case PeakModules.Crm:
          await Services.Leads.updateMembershipInfo(memberId, data);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PTLeads.updateMembershipInfo(memberId, data);
          break;
        default:
      }

      dispatch(updateMembershipInfoResult(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
      dispatch(updateMembershipInfoResult(ActionResult.FAIL_ACTION));
    } finally {
      dispatch(updateMembershipInfoLoading(false));
    }
  };
};

const fetchMembershipPackagesListAction = createAction<IShortPackages>(
  actionTypes.FETCH_MEMBERSHIP_PACKAGES_LIST,
);

const fetchMembershipPackageLoadingAction = createAction<boolean>(
  actionTypes.FETCH_MEMBERSHIP_PACKAGE_LOADING,
);

export const fetchMembershipPackages = (
  clubId: string,
  module: PeakModuleForNewPersonType,
  search?: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchMembershipListIsLoading(true));

    let packages;

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          packages = await Services.FrontDesk.getMembershipPackages(clubId, search);
          break;
        case PeakModules.Crm:
          packages = await Services.Leads.getMembershipPackages(clubId, search);
          break;
        case PeakModules.Members:
          packages = await Services.Members.getMembershipPackages(clubId, search);
          break;
        case PeakModules.PersonalTrainingCrm:
          packages = await Services.PTLeads.getMembershipPackages(clubId, search);
          break;
        default:
      }

      dispatch(fetchMembershipPackagesListAction(packages));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchMembershipListIsLoading(false));
    }
  };
};

const fetchMembershipPackageAction = createAction<IPackageConfiguration | null>(
  actionTypes.FETCH_MEMBERSHIP_PACKAGE,
);

export const resetMembershipPackageItem = createAction(actionTypes.RESET_MEMBERSHIP_PACKAGE_ITEM);

export const fetchMembershipPackageConfiguration = (
  membershipId: string,
  clubId: string,
  module: PeakModules = PeakModules.FrontDesk,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchMembershipPackageLoadingAction(true));

    let membershipConfiguration: IPackageConfiguration | null = null;

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          membershipConfiguration = await Services.FrontDesk.getMembershipConfiguration(
            membershipId,
            clubId,
          );

          break;
        case PeakModules.Members:
          membershipConfiguration = await Services.Members.getMembershipConfiguration(
            membershipId,
            clubId,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          membershipConfiguration = await Services.PTLeads.getMembershipConfiguration(
            membershipId,
            clubId,
          );
          break;
        case PeakModules.Crm:
          membershipConfiguration = await Services.Leads.getMembershipConfiguration(
            membershipId,
            clubId,
          );
          break;
        default:
      }

      dispatch(fetchMembershipPackageAction(membershipConfiguration));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchMembershipPackageLoadingAction(false));
    }
  };
};

const fetchPackageInventoryAction = createAction<IPackageInventoryViewDto | null>(
  actionTypes.FETCH_MEMBERSHIP_PACKAGE_INVENTORY,
);

const fetchPackageInventoryLoadingAction = createAction<boolean>(
  actionTypes.FETCH_MEMBERSHIP_PACKAGE_INVENTORY_LOADING,
);

export const resetPackageInventoryAction = createAction(
  actionTypes.RESET_MEMBERSHIP_PACKAGE_INVENTORY,
);

export const fetchPackageInventory = (
  packageId: string,
  inventoryId: string,
  module: PeakModules = PeakModules.FrontDesk,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPackageInventoryLoadingAction(true));

    let inventory: IPackageInventoryViewDto | null = null;

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          inventory = await Services.FrontDesk.getPackageInventory(packageId, inventoryId);

          break;
        case PeakModules.Members:
          inventory = await Services.Members.getPackageInventory(packageId, inventoryId);
          break;
        case PeakModules.PersonalTrainingCrm:
          inventory = await Services.PTLeads.getPackageInventory(packageId, inventoryId);
          break;
        case PeakModules.Crm:
          inventory = await Services.Leads.getPackageInventory(packageId, inventoryId);
          break;
        default:
      }

      dispatch(fetchPackageInventoryAction(inventory));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPackageInventoryLoadingAction(false));
    }
  };
};

// Person membership for payment step
const fetchPersonMembershipLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PERSON_MEMBERSHIP_LOADING,
);

const fetchPersonMembershipAction = createAction<IConfiguredPackage | null>(
  actionTypes.FETCH_PERSON_MEMBERSHIP,
);

export const resetPersonMembership = createAction(actionTypes.RESET_PERSON_MEMBERSHIP);

export const fetchPersonMembership = (
  memberId: number,
  salespersonId: string | null,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonMembershipLoadingAction(true));

    let membershipConfiguration: IConfiguredPackage | null = null;

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          membershipConfiguration = await Services.FrontDesk.getPersonMembership(
            memberId,
            salespersonId,
          );
          break;
        case PeakModules.Members:
          membershipConfiguration = await Services.Members.getPersonMembership(
            memberId,
            salespersonId,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          membershipConfiguration = await Services.PTLeads.getPersonMembership(
            memberId,
            salespersonId,
          );
          break;
        case PeakModules.Crm:
          membershipConfiguration = await Services.Leads.getPersonMembership(
            memberId,
            salespersonId,
          );
          break;
        default:
      }

      dispatch(fetchPersonMembershipAction(membershipConfiguration));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPersonMembershipLoadingAction(false));
    }
  };
};
