import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { List as ImmutableList } from 'immutable';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage } from 'react-intl';
import { DialogComponent, LeadSettingsSection, ScrollBox } from 'common/components';
import { StepContext } from 'common/createContext/stepContext';
import { ICameFromIndicatorDictionaryItemImt } from 'common/interfaces/dictionary';
import { ILeadProfile, ILeadProfileImt, INewLeadProfileData } from 'modules/crm/interfaces/leads';
import {
  IDoNotContactHours,
  IImmutablePrimaryMemberInfo,
  IPersonAdditionalFormFields,
  IPersonSalesInfoImt,
} from 'common/components/PersonProfile/interfaces';
import { Communications, LeadValue } from 'modules/crm/constants/leads';
import { LeadStatus } from 'common/constants/personConstants';
import GeneralInfoSection from './GenerealInfoSection/GeneralInfoSection';
import ContactSection from './ContactSection/ContactSection';
import InterestsSection from './InterestsSection/InterestsSection';
import SalesInfoSection from './SalesInfoSection/SalesInfoSection';
import StatusSection from './StatusSection/StatusSection';
import { PrimaryInfoStepValidationSchema } from './ValidationSchema/ValidationSchema';
import { PersonSearchParamsType } from 'common/interfaces/http';
import {
  resetPersonsFound,
  searchMembers,
  searchReferralMembers,
} from 'common/state/newPerson/primaryInfo/actions';
import useRootSelector from 'common/hooks/useRootSelector';
import {
  selectPersonsFoundList,
  selectPersonsFoundListLoading,
} from 'common/state/newPerson/primaryInfo/selectors';
import { selectCreatedLead, selectCreateLeadActionResult } from 'modules/crm/state/leads/selectors';
import { ActionResult } from 'common/constants';
import { createLeadActivity, createLeadProfileActionResult } from 'modules/crm/state/leads/actions';
import { INamedEntity, INamedEntityImt, IObject } from 'common/interfaces/common';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';
import { useAppDispatch } from 'store/hooks';
import messages from 'modules/crm/messages/leads';
import ptCrmMessages from 'modules/personal-training-crm/messages/messages';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { PeakModules } from 'common/constants/peakModules';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import isFormDirty from 'common/hooks/isFormDirty';
import useDiscardChangesContext from 'common/hooks/useDiscardChangesContext';
import DiscardChangesModalProvider from 'common/modals/DiscardChangesModal/DiscardChangesModalProvider';
import LeadHomeClubModal from 'common/components/Steps/MemberPrimaryInfoStep/LeadHomeClubModal/LeadHomeClubModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    flex: 1,
    height: '100%',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
}));

interface ILeadPrimaryInfoStep {
  hideAutocomplete?: boolean;
  employees: INamedEntity<number>[];
  cameFromIndicators: ImmutableList<ICameFromIndicatorDictionaryItemImt>;
  primaryInfo: ILeadProfileImt;
  salesInfo?: IPersonSalesInfoImt;
  setPrimaryInfo?: (person: IImmutablePrimaryMemberInfo) => void;
  onClose?: () => void;
  isOpen?: boolean;
  isNewLeadPage?: boolean;
  /*
   *  It is necessary to make a separate save to go to the next step
   *  only if the answer is successful
   * */
  saveLeadProfile?: (data: INewLeadProfileData, homeClubId?: string) => void;
  module: PeakModules.Crm | PeakModules.PersonalTrainingCrm | PeakModules.Members;
  selectedClubId?: string;
}

const initialValues: IObject = {
  firstName: '',
  lastName: '',
  birthday: null,
  image: null,
  email: '',
  phones: [],
  address: {
    city: '',
    line1: '',
    zipCode: '',
    country: null,
    state: null,
  },
  potentialClubId: '',
  tags: [],
  activitiesIds: [],
  limitations: '',

  createdDate: null,
  salesValue: LeadValue.LOW,
  preferableWayOfCommunication: Communications.ANY,
  assignedSalesperson: null,
  cameFromValue: '',
  cameFromIndicatorId: '',
  referralMember: null,
  leadMember: false,
  ptLeadMember: false,
  barcode: '',
  doNotContactHours: {
    from: null,
    to: null,
  },
  salesStatus: LeadStatus.Interested,
};

const LeadPrimaryInfoStep: FC<ILeadPrimaryInfoStep> = ({
  hideAutocomplete,
  employees,
  primaryInfo,
  salesInfo,
  setPrimaryInfo,
  saveLeadProfile,
  cameFromIndicators,
  onClose,
  isOpen,
  module,
  isNewLeadPage = false,
  selectedClubId,
}) => {
  const dispatch = useAppDispatch();

  const leadsAutocompleteList: ImmutableList<ILeadProfile | INamedEntityImt> = useRootSelector(
    selectPersonsFoundList,
  );
  const isLoading = useRootSelector(selectPersonsFoundListLoading);
  const actionResult = useRootSelector(selectCreateLeadActionResult);
  const leadPrimaryInfo: ILeadProfileImt = useRootSelector(selectCreatedLead);
  const profileRequiredFields = useRootSelector(selectProfileRequiredFields);
  const isSubmitted = useRootSelector(selectIsSubmittedRecommendedFieldsModal);

  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();
  const [, getUTCDate] = useTimezoneMoment();
  const classes = useStyles();
  const { onNext, onBack, renderFooter } = useContext(StepContext);
  const dcContext = useDiscardChangesContext();
  const isCrmModule = module === PeakModules.Crm;
  const [isOpenLeadHomeClubModal, setIsOpenLeadHomeClubModal] = useState(false);
  const [homeClub, setHomeClub] = useState<string | null>(null);

  const convertedPrimaryInfo = useMemo(() => {
    const primaryInfoObj = primaryInfo.toJS();
    const doNotContactHours: IDoNotContactHours | undefined = salesInfo
      ?.get('doNotContactHours')
      ?.toJS();
    return { ...primaryInfoObj, doNotContactHours };
  }, [primaryInfo, salesInfo]);

  const modifiedPrimaryInfo: IObject = convertedPrimaryInfo;
  modifiedPrimaryInfo.assignedSalesperson = convertedPrimaryInfo.assignedSalesperson
    ? {
        id: convertedPrimaryInfo.assignedSalesperson.id,
        name: `${convertedPrimaryInfo.assignedSalesperson.firstName} ${convertedPrimaryInfo.assignedSalesperson.lastName}`,
        title: `${convertedPrimaryInfo.assignedSalesperson.firstName} ${convertedPrimaryInfo.assignedSalesperson.lastName}`,
      }
    : initialValues.assignedSalesperson;

  const formMethods = useForm<IObject>({
    defaultValues: {
      ...initialValues,
      ...modifiedPrimaryInfo,
      tags: modifiedPrimaryInfo.tags || [],
    },
    resolver: yupResolver(PrimaryInfoStepValidationSchema(employees, profileRequiredFields)),
    mode: 'onBlur',
  });

  const { handleSubmit, reset, getValues, formState } = formMethods;
  const isDirty = isFormDirty(formState);

  useEffect(() => {
    if (modifiedPrimaryInfo) {
      const { cameFromIndicator: cameFrom, phones } = modifiedPrimaryInfo;

      const { from, to } = modifiedPrimaryInfo.doNotContactHours || {};

      const leadMember = module === PeakModules.Crm || modifiedPrimaryInfo.leadMember;
      const isPTCRMModule = module === PeakModules.PersonalTrainingCrm;
      const ptLeadMember = isPTCRMModule || modifiedPrimaryInfo.ptLeadMember;

      reset({
        ...initialValues,
        ...modifiedPrimaryInfo,
        leadMember,
        ptLeadMember,
        phones: phones?.length ? phones : [],
        salesperson: modifiedPrimaryInfo.assignedSalesperson,
        cameFromIndicatorId: cameFrom?.id,
        cameFromValue: modifiedPrimaryInfo.cameFromValue || '',
        cameFromType: cameFrom?.type,
        optional: cameFrom?.optional,
        potentialClubId: modifiedPrimaryInfo.potentialClub?.id,
        activitiesIds: modifiedPrimaryInfo.activities || [],
        tags: modifiedPrimaryInfo.tags || [],
        doNotContactHours: {
          from: from ? getUTCDate({ date: from }) : null,
          to: to ? getUTCDate({ date: to }) : null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, salesInfo, primaryInfo, module]);

  useEffect(() => {
    if (actionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(createLeadProfileActionResult(null));

      if (onNext) {
        onNext(leadPrimaryInfo.toJS());
      }
    }
  }, [actionResult, leadPrimaryInfo, onNext, dispatch]);

  useEffect(() => {
    if (isSubmitted) {
      const { cameFromType, optional, referralMember: refMember, ...data } = getValues();
      const onClickNext = saveLeadProfile || onNext;

      if (onClickNext) {
        onClickNext({ ...data, referralMemberId: refMember?.id });
      }

      dispatch(showRecommendedFieldsModal(false));
    }
  }, [dispatch, getValues, isSubmitted, onNext, saveLeadProfile]);

  const getOptionsByValue = useCallback(
    (searchString: string, customerSearchSortBy: PersonSearchParamsType) => {
      dispatch(searchMembers(searchString, customerSearchSortBy, module));
    },
    [dispatch, module],
  );

  const clearSearchResults = useCallback(() => {
    dispatch(resetPersonsFound());
  }, [dispatch]);

  const getReferralMembersByValue = useCallback(
    (searchString: string) => {
      dispatch(searchReferralMembers(searchString, module));
    },
    [dispatch, module],
  );

  const onActivityCreate = useCallback(
    (activity: IDictionaryCommonItem) => {
      dispatch(createLeadActivity(activity, module));
    },
    [dispatch, module],
  );

  const onSubmit = (values: ILeadProfile & IPersonAdditionalFormFields) => {
    const { cameFromType, optional, referralMember: refMember, ...data } = values;

    if (
      (module === PeakModules.Crm || module === PeakModules.PersonalTrainingCrm) &&
      !selectedClubId
    ) {
      setIsOpenLeadHomeClubModal(true);
    } else {
      const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty({
        ...values,
        salesperson: values.assignedSalesperson,
      });

      if (hasEmptyRecommendedFields) {
        dispatch(showRecommendedFieldsModal(true));
      } else {
        const onClickNext = saveLeadProfile || onNext;

        if (onClickNext) {
          onClickNext({ ...data, referralMemberId: refMember?.id });
        }
      }
    }
  };

  const onCloseLeadHomeClubModal = () => {
    setIsOpenLeadHomeClubModal(false);
    setHomeClub(null);
  };

  const onSelectClubModalSubmit = (clubId: string) => {
    setHomeClub(clubId);
  };

  useEffect(() => {
    if (homeClub) {
      const { cameFromType, optional, referralMember: refMember, ...data } = getValues();

      const onClickNext = saveLeadProfile || onNext;

      if (onClickNext) {
        onClickNext({ ...data, referralMemberId: refMember?.id }, homeClub);
      }
    }
  }, [homeClub, getValues, onNext, saveLeadProfile]);

  const renderContent = () => {
    return (
      <FormProvider {...formMethods}>
        <ScrollBox
          paddingX={isNewLeadPage ? '10px' : ''}
          hasShadowsOnScroll
          suppressScrollX
          suppressScrollY={!isNewLeadPage}
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <GeneralInfoSection<ImmutableList<ILeadProfile>>
                persons={leadsAutocompleteList as ImmutableList<ILeadProfile>}
                isPersonSearchLoading={isLoading}
                getOptionsByValue={getOptionsByValue}
                clearSearchResults={clearSearchResults}
                setPrimaryInfo={setPrimaryInfo}
                hideAutocomplete={hideAutocomplete}
                recommendedFields={profileRecommendedFields}
              />
            </Grid>

            <Grid item xs={12}>
              <ContactSection<ImmutableList<ILeadProfile>>
                persons={leadsAutocompleteList as ImmutableList<ILeadProfile>}
                isPersonSearchLoading={isLoading}
                getOptionsByValue={getOptionsByValue}
                clearSearchResults={clearSearchResults}
                setPrimaryInfo={setPrimaryInfo}
                hideAutocomplete={hideAutocomplete}
                recommendedFields={profileRecommendedFields}
                requiredFields={profileRequiredFields}
              />
            </Grid>

            <Grid item xs={12}>
              <InterestsSection onActivityCreate={onActivityCreate} />
            </Grid>

            <Grid item xs={12}>
              <SalesInfoSection
                primaryInfo={primaryInfo}
                getReferralMembersByValue={getReferralMembersByValue}
                persons={leadsAutocompleteList as ImmutableList<INamedEntityImt>}
                isPersonSearchLoading={isLoading}
                clearSearchResults={clearSearchResults}
                employees={employees}
                cameFromIndicators={cameFromIndicators}
                recommendedFields={profileRecommendedFields}
              />
            </Grid>

            <Grid item xs={12}>
              <LeadSettingsSection />
            </Grid>

            <Grid item xs={12}>
              <StatusSection module={module} />
            </Grid>
          </Grid>
        </ScrollBox>

        {!!renderFooter && renderFooter(onBack, handleSubmit(onSubmit))}
      </FormProvider>
    );
  };

  return (
    <>
      {isNewLeadPage && renderContent()}
      {!isNewLeadPage && (
        <DialogComponent
          isOpen={!!isOpen}
          title={
            <FormattedMessage
              {...(isCrmModule ? messages.editLeadTitle : ptCrmMessages.editPtLeadProfile)}
            />
          }
          onClose={onClose || (() => {})}
          loading={isLoading}
          size="md"
          className={classes.root}
          formId="primary-info-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {renderContent()}
        </DialogComponent>
      )}

      {dcContext && isDirty ? <DiscardChangesModalProvider {...dcContext} /> : null}

      {isOpenLeadHomeClubModal && (
        <LeadHomeClubModal onClose={onCloseLeadHomeClubModal} onSubmit={onSelectClubModalSubmit} />
      )}
    </>
  );
};

export default LeadPrimaryInfoStep;
