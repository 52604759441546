import { ConstOptions, IConstOption } from 'common/constants/classes';
import {
  BillingType,
  ITotalScheduledBillingsStatistics,
  PackageType,
  PaymentStatus,
  ScheduledBillingsActivityType,
} from 'modules/reports/interfaces/scheduledBillingsReport';

import messages from 'modules/reports/messages/messages';
import commonMessages from 'common/messages/messages';

export const PaymentStatuses = new ConstOptions([
  { key: PaymentStatus.PAID, value: PaymentStatus.PAID, message: messages.paid },
  { key: PaymentStatus.UNPAID, value: PaymentStatus.UNPAID, message: messages.unpaid },
]);

export const PackageTypes = new ConstOptions([
  { key: PackageType.BUNDLE, value: PackageType.BUNDLE, message: commonMessages.bundleLabel },
  { key: PackageType.FEE, value: PackageType.FEE, message: commonMessages.feeLabel },
]);

export const BillingTypes = new ConstOptions([
  { key: BillingType.PAST_DUE, value: BillingType.PAST_DUE, message: messages.pastDue },
  {
    key: BillingType.CHARGE,
    value: BillingType.CHARGE,
    message: messages.billingSchedule,
  },
]);

export const BillingsChartLegendLabels = {
  [ScheduledBillingsActivityType.PAID_BILLING]: messages.paidBillingSchedulesLabel,
  [ScheduledBillingsActivityType.UNPAID_BILLING]: messages.unpaidBillingSchedulesLabel,
  [ScheduledBillingsActivityType.PAID_PAST_DUE]: messages.paidPastDuesLabel,
  [ScheduledBillingsActivityType.UNPAID_PAST_DUE]: messages.unpaidPastDuesLabel,
};

export const TotalScheduledBillingsItemsLabels = new ConstOptions([
  { key: 'amount', value: 'amount', message: messages.total },
  {
    key: 'billingScheduleAmount',
    value: 'billingScheduleAmount',
    message: messages.totalBillingSchedulesLabel,
  },
  {
    key: 'paidBillingScheduleAmount',
    value: 'paidBillingScheduleAmount',
    message: messages.paidBillingSchedulesLabel,
  },
  { key: 'paidPastDueAmount', value: 'paidPastDueAmount', message: messages.paidPastDuesLabel },
  { key: 'pastDueAmount', value: 'pastDueAmount', message: messages.totalPastDuesLabel },
  {
    key: 'unpaidBillingScheduleAmount',
    value: 'unpaidBillingScheduleAmount',
    message: messages.unpaidBillingSchedulesLabel,
  },
  {
    key: 'unpaidPastDueAmount',
    value: 'unpaidPastDueAmount',
    message: messages.unpaidPastDuesLabel,
  },
] as Array<IConstOption<keyof ITotalScheduledBillingsStatistics, keyof ITotalScheduledBillingsStatistics>>);

export const actionTypes = {
  FETCH_TOTAL_SCHEDULED_BILLINGS_STATISTICS: 'FETCH_SCHEDULED_BILLINGS_STATISTICS',
  FETCH_TOTAL_SCHEDULED_BILLINGS_STATISTICS_LOADING: 'FETCH_SCHEDULED_BILLINGS_STATISTICS_LOADING',
  RESET_TOTAL_SCHEDULED_BILLINGS_STATISTICS: 'RESET_TOTAL_SCHEDULED_BILLINGS_STATISTICS',

  FETCH_SCHEDULED_BILLING_REPORT_ITEMS: 'FETCH_SCHEDULED_BILLING_REPORT_ITEMS',
  FETCH_SCHEDULED_BILLING_REPORT_ITEMS_LOADING: 'FETCH_SCHEDULED_BILLING_REPORT_ITEMS_LOADING',
  RESET_SCHEDULED_BILLING_REPORT_ITEMS: 'RESET_SCHEDULED_BILLING_REPORT_ITEMS',

  FETCH_SCHEDULED_BILLINGS_ACTIVITY_REPORT: 'FETCH_SCHEDULED_BILLINGS_ACTIVITY_REPORT',
  FETCH_SCHEDULED_BILLINGS_ACTIVITY_REPORT_LOADING:
    'FETCH_SCHEDULED_BILLINGS_ACTIVITY_REPORT_LOADING',
  RESET_SCHEDULED_BILLINGS_ACTIVITY_REPORT: 'RESET_SCHEDULED_BILLINGS_ACTIVITY_REPORT',
};
