import React from 'react';
import {
  IInventoryDetailedInfo,
  IInventoryDetailedInfoImt,
} from 'modules/reports/interfaces/invoiceHistory';
import { InventoryViewModal } from 'common/components';
import { fromJS } from 'immutable';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  inventoryInfo?: IInventoryDetailedInfo;
  isInventoryInfoLoading: boolean;
}

const ServicesHistoryInventoryModal = ({
  isOpen,
  onClose,
  inventoryInfo,
  isInventoryInfoLoading,
}: IProps): JSX.Element => {
  return (
    <InventoryViewModal<IInventoryDetailedInfoImt>
      inventoryInfo={fromJS(inventoryInfo)}
      onClose={onClose}
      isOpen={isOpen}
      isLoading={isInventoryInfoLoading}
    />
  );
};

export default ServicesHistoryInventoryModal;
