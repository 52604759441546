import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CheckInBlock } from 'common/components/PersonProfile/components';
import messages from 'common/components/PersonProfile/messages';
import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';
import inputLabels from 'common/messages/inputLabels';
import { FieldInfo } from 'common/components';
import { ICustomerEmergencyContactDto } from 'common/interfaces/additionalInfo';
import commonMessages from 'common/messages/messages';
import { Button } from 'common/components/index';
import { convertPhoneNumber } from 'helpers/common';
import IntlService from 'services/intl/IntlService';

interface IEmergencyContact {
  emergencyContact?: ICustomerEmergencyContactDto;
  onOpenModal: () => void;
  loading?: boolean;
}

const useStyles = makeStyles(() => ({
  editIcon: {
    width: '15px',
    height: '15px',
  },
}));

const EmergencyContact: FC<IEmergencyContact> = ({
  emergencyContact,
  onOpenModal,
  loading = false,
}) => {
  const classes = useStyles();
  const ecName = emergencyContact?.emergencyName;
  const ecPhone = emergencyContact?.emergencyPhone;
  const ecRelationship = emergencyContact?.emergencyRelationship;

  return (
    <CheckInBlock
      title={IntlService.formatMessage(messages.emergencyContactBlockTitle)}
      buttonTitle={
        <Button hasHoverStyle color="primary" disabled={loading}>
          <EditIcon className={classes.editIcon} />
        </Button>
      }
      onClickButton={onOpenModal}
    >
      {ecName || ecPhone || ecRelationship ? (
        <Grid xs={12} container item spacing={2}>
          <Grid item xs={6}>
            <FieldInfo
              label={IntlService.formatMessage(inputLabels.name)}
              description={ecName || '-'}
            />
          </Grid>

          <Grid item xs={6}>
            <FieldInfo
              label={IntlService.formatMessage(inputLabels.phone)}
              description={ecPhone ? convertPhoneNumber(ecPhone) : '-'}
            />
          </Grid>

          <Grid item xs={12}>
            <FieldInfo
              hasNotEllipsesDescription
              label={IntlService.formatMessage(inputLabels.emergencyContactRelationship)}
              description={ecRelationship || '-'}
            />
          </Grid>
        </Grid>
      ) : (
        <Typography variant="body2" color="textSecondary">
          <FormattedMessage {...commonMessages.notSpecified} />
        </Typography>
      )}
    </CheckInBlock>
  );
};

export default EmergencyContact;
