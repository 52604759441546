import React, { FC } from 'react';
import { Autocomplete as MuiAutocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Grid, Avatar, Typography, CircularProgress, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import profilePlaceholder from 'img/photo-placeholder.png';
import { getInitials } from 'helpers/common';
import { INamedEntity } from 'common/interfaces/common';
import { TextField } from 'common/components/index';
import { useGetSalespersons } from 'modules/members/api/member.api';

interface ISalespersonAutocomplete {
  label: string | JSX.Element;
  value: any;
  onChange: (value: any) => void;
  helperText?: React.ReactNode;
  fullWidth?: boolean;
  error?: boolean;
  recommended?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  textFieldRoot: {
    '&.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      padding: '0 65px 0 0',
    },
    '& > .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input': {
      padding: '22px 12px 10px 12px',
    },
  },
  avatarWrap: {
    position: 'relative',
    flex: 'none',
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.light,
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '16px',
    fontWeight: 700,
    color: theme.palette.darkBackground?.light,
  },
}));

const SalespersonAutocomplete: FC<ISalespersonAutocomplete> = ({
  label,
  value,
  onChange,
  helperText,
  fullWidth = false,
  error = false,
  recommended = false,
}) => {
  const { data: employees = [], isLoading } = useGetSalespersons();
  const classes = useStyles();

  const getOptionSelected = (option: INamedEntity<number>, valueEmployee: INamedEntity<number>) => {
    return option.id === valueEmployee.id;
  };

  const getOptionLabel = (employee: INamedEntity<number> | null): string => {
    return employee ? employee.title : '';
  };

  const onChangeOption = (event: React.ChangeEvent, data: any): void => {
    onChange(data);
  };

  const renderOption = (option: INamedEntity<number>): JSX.Element => {
    const [firstName, lastName] = option.title.split(' ');
    const initials = getInitials(firstName, lastName);

    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Box className={cx(classes.avatarWrap)}>
            {initials && !option.imageUrl ? (
              <Box className={classes.avatarInitial}>{initials}</Box>
            ) : (
              <Avatar src={option.imageUrl || profilePlaceholder} alt={firstName} />
            )}
          </Box>
        </Grid>

        <Grid item>
          <Typography variant="subtitle2">{option.title}</Typography>
        </Grid>
      </Grid>
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams): JSX.Element => {
    return (
      <TextField
        {...params}
        label={label}
        variant="outlined"
        fullWidth={fullWidth}
        error={error}
        helperText={helperText}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password',
          form: {
            autoComplete: 'off',
          },
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          classes: {
            root: classes.textFieldRoot,
          },
          ...params.InputProps,
          endAdornment: (
            <>
              {isLoading ? <CircularProgress color="primary" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
        recommended={recommended}
      />
    );
  };

  return (
    <MuiAutocomplete
      value={value}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      onChange={onChangeOption}
      options={employees}
      loading={isLoading}
      renderInput={renderInput}
    />
  );
};

export default SalespersonAutocomplete;
