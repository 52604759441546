import React, { FC, useEffect, useRef, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import {
  Box,
  Chip,
  Grid,
  IconButton,
  makeStyles,
  SvgIcon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Delete, Event, EventAvailable, EventBusy, FileCopy, Schedule } from '@material-ui/icons';
import { ResizeObserver } from 'resize-observer';
import { FormattedMessage } from 'react-intl';
import { fromJS } from 'immutable';
import cx from 'classnames';
import moment from 'moment-timezone';
import { ReactComponent as Times } from 'img/times.svg';
import { ReactComponent as Repeat } from 'img/repeat.svg';
import { ReactComponent as Building } from 'img/icons/sidebar/building.svg';
import { ReactComponent as Package } from 'img/box.svg';
import { ReactComponent as TagsIcon } from 'img/icons/tasks.svg';
import { ReactComponent as BellIcon } from 'img/icons/bell.svg';
import { ReactComponent as AlignLeftIcon } from 'img/icons/align-left.svg';
import { ReactComponent as UserIcon } from 'img/icons/user_deprecated.svg';
import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';
import { ReactComponent as WarningIcon } from 'img/icons/warning.svg';
import { ReactComponent as UsersCancelIcon } from 'img/icons/users-cancel.svg';
import { ReactComponent as UsersAddIcon } from 'img/icons/users-add.svg';
import { ReactComponent as ServicesIcon } from 'img/icons/boxes.svg';
import { ReactComponent as UsersJoinIcon } from 'img/icons/user-join.svg';
import {
  CancelEventModal,
  DeleteEventModal,
  DuplicateParticipantsModal,
  RemindParticipantsModal,
} from 'modules/booking/components';
import ScrollBox from 'common/components/ScrollBox/ScrollBox';
import ParticipantsList from './ParticipantsList/ParticipantsList';
import ModificationHistory from './ModificationHistory/ModificationHistory';
import { ActionItem, ActionsMenu, TooltipTypography } from 'common/components';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import {
  EventOverbookingType,
  EventRepeatFrequency,
  IBookingEventImt,
  IEventAction,
  IRemindAppointmentDto,
  PersonAppointmentStatus,
} from 'modules/booking/interfaces';
import commonMessages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import messages from 'modules/booking/messages';
import { getWeekdaysLabel } from 'common/utils/labelComposer';
import { PeakModules } from 'common/constants/peakModules';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { EShortNotificationType } from 'modules/booking/constants/notificationType';
import { selectEventActionResult } from 'modules/booking/state/events/selectors';
import { transformMinutesToTimeFormat } from 'common/utils/time';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import ParticipationModal, {
  ICancelParticipationFormValues,
} from 'common/components/PersonProfile/components/Appointments/ParticipationModal/ParticipationModal';
import { selectCurrentUserId } from 'modules/authentication/state/selectors';
import usePermissionContext from 'common/hooks/context/usePermissionContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingBottom: theme.spacing(1),
  },
  colorLine: {
    height: '4px',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
  },
  titleWrapper: {
    padding: theme.spacing(2),
  },
  actionsWrapper: {
    width: 'auto',
    '& > div:first-child': {
      marginRight: theme.spacing(0.5),
    },
  },
  actionsMenuItem: {
    '&:hover': {
      '& .MuiListItemIcon-root svg, & .MuiTypography-root': {
        color: '#fff',
      },
    },
  },
  scrollbarWrapper: {
    flex: 1,
    minHeight: 0,
  },
  detailsWrapper: {
    padding: theme.spacing(1, 2),
  },
  closeBtn: {
    width: '14px',
    height: '14px',
  },
  closeBtnIcon: {
    fontSize: '14px',
    opacity: '0.3',
  },
  title: {
    lineHeight: '21px',
    letterSpacing: 0,
  },
  row: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    color: theme.palette.warning.main,
    pointerEvents: 'auto',
  },
  rowIcon: {
    marginRight: theme.spacing(1),
    fontSize: '1.1rem',
    width: '18px',
    color: theme.palette.text.primary,
    opacity: '0.4',
  },
  editIcon: {
    cursor: 'pointer',
    width: '16px',
    height: '16px',
  },
  editEventMenu: {
    opacity: '1 !important',
    '&:hover': {
      backgroundColor: 'inherit',
      color: 'inherit',

      '& .MuiListItemIcon-root>svg': {
        fill: theme.palette.text.primary,
      },
    },
    '& .MuiListItemIcon-root>svg': {
      fill: theme.palette.text.primary,
    },
  },
  tag: {
    margin: theme.spacing(0.5, 1, 0.5, 0),
    padding: theme.spacing(0.25, 1),
    height: 'auto',
    backgroundColor: theme.palette.darkBackground?.light,
    color: theme.palette.background.paper,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 700,
  },
  inactive: {
    opacity: 0.5,
  },
  canceledStatus: {
    textTransform: 'uppercase',
    marginLeft: theme.spacing(1),
    fontSize: '1rem',
  },
}));

interface IEventDetailsProps {
  event?: IBookingEventImt | null;
  onClose?: () => void;
  onEdit?: (event: IBookingEventImt) => void;
  onCancelParticipation?: (formData: ICancelParticipationFormValues) => void;
  onConfirmParticipation?: (formData: ICancelParticipationFormValues) => void;
  onDelete?: (formValues: IEventAction) => void;
  onCancel?: (formValues: IEventAction) => void;
  onRestore?: (event: IBookingEventImt) => void;
  onDuplicate?: (isDuplicateParticipants: boolean) => void;
  onRemind?: (formValues: IRemindAppointmentDto) => void;
  handleOverflow?: (value) => void;
  appointmentStatus?: PersonAppointmentStatus;
  readOnly?: boolean;
  module?: PeakModules;
  isEventActionLoading: boolean;
  type: SenderAvailabilityTypeList;
  onLoadSenderAvailability?: (
    type: SenderAvailabilityTypeList,
    shortTypeEvent: EShortNotificationType,
  ) => void;
  onJoinClass?: (formData: ICancelParticipationFormValues) => void;
}

const EventDetails: FC<IEventDetailsProps> = ({
  event,
  onEdit,
  onClose,
  onDelete,
  onCancel,
  onRestore,
  onDuplicate,
  onRemind,
  handleOverflow,
  readOnly,
  module,
  appointmentStatus,
  isEventActionLoading,
  type,
  onCancelParticipation,
  onConfirmParticipation,
  onLoadSenderAvailability,
  onJoinClass,
}) => {
  const eventActionResult = useRootSelector(selectEventActionResult);
  const userId = useRootSelector(selectCurrentUserId);

  const [detailsHeight, setDetailsHeight] = useState<number>(0);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDuplicateParticipantsModal, setShowDuplicateParticipantsModal] = useState(false);
  const [showRemindParticipantsModal, setShowRemindParticipantsModal] = useState(false);
  const [showCancelParticipationModal, setShowCancelParticipationModal] = useState(false);
  const [showConfirmParticipationModal, setShowConfirmParticipationModal] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);

  const detailsRef = useRef(null);

  const [timezoneMoment] = useTimezoneMoment();

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const {
    BOOKING_EVENTS_EDIT,
    BOOKING_EVENTS_CANCEL,
    BOOKING_EVENTS_DELETE,
    BOOKING_EVENTS_DUPLICATE,
    BOOKING_EVENTS_REMIND_PARTICIPANTS,
  } = usePermissionContext();

  useEffect(() => {
    const observedRef = detailsRef.current;

    const resizeObserver = new ResizeObserver(entries => {
      const boxHeight = entries[0].contentRect.height;

      const isBlockOverflow = window.innerHeight - 134 < boxHeight;
      if (isBlockOverflow) {
        setDetailsHeight(boxHeight);
      }

      if (handleOverflow) {
        handleOverflow(isBlockOverflow);
      }
    });

    if (observedRef) {
      resizeObserver.observe(observedRef);
    }

    return () => {
      if (observedRef) {
        resizeObserver.unobserve(observedRef);
      }
    };
  });

  const resizeFunction = () => {
    if (window.innerHeight - 134 < detailsHeight) {
      if (handleOverflow) {
        handleOverflow(true);
      }
    } else if (handleOverflow) {
      handleOverflow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeFunction);
    return () => {
      window.removeEventListener('resize', resizeFunction);
    };
  });

  useEffect(() => {
    if (eventActionResult?.size) {
      setShowDuplicateParticipantsModal(false);
    }
  }, [eventActionResult]);

  if (!event?.size) {
    return null;
  }

  const currentDate = timezoneMoment(
    `${event.get('date')} ${event.get('time')}`,
    'YYYY-MM-DD HH:mm',
    true,
  );

  const isCurrentUserInEvent = event
    ?.getIn(['persons'])
    ?.toJS()
    .find(item => item.customer?.id === userId);

  const endTime = currentDate.clone().add(event.get('durationInMinutes'), 'minutes');

  const resources = event.get('resources');

  const trainersResources = resources
    ?.filter(resourceItem => !!resourceItem.get('employee'))
    .map(resourceItem =>
      fromJS({
        id: resourceItem.get('id'),
        salesperson: resourceItem.get('employee').toJS(),
        eventDate: currentDate.format(),
        joinedDate: null,
      }),
    );

  const persons = event.get('persons');

  let countOfConfirmedParticipants = persons?.reduce((acc, item) => {
    const isConfirmedStatus = item.get('status') === PersonAppointmentStatus.Confirmed;
    return isConfirmedStatus ? acc + 1 : acc;
  }, 0);

  countOfConfirmedParticipants = countOfConfirmedParticipants || 0;

  const countOfParticipants = persons?.size || 0;

  const isPastEvent = currentDate.isSameOrBefore(timezoneMoment(), 'minutes');

  const canDuplicate = module === PeakModules.Booking;

  const durationInMinutes = event.get('durationInMinutes');

  const eventDate = moment.utc(`${event.get('date')} ${event.get('time')}`, 'YYYY-MM-DD HH:mm');

  const hoursBeforeEvent = eventDate.diff(moment(), 'hours');

  const showWarning = hoursBeforeEvent <= 2 && !isPastEvent;

  const spotLimited = event.get('spotLimited');

  const overbookingType = event.get('overbookingType');

  const spotAmount = event.get('spotAmount') || 0;

  const bookedSpots = event.get('bookedSpots') || 0;

  const isNotAllowSelfBooking = !event.get('allowSelfBooking');

  const isDisabledJoinButton =
    isNotAllowSelfBooking ||
    !!isCurrentUserInEvent ||
    (spotLimited && overbookingType === EventOverbookingType.DO_NOT_ALLOW_OVERBOOKING);

  const isWhaitLIstModal =
    (spotLimited && bookedSpots === spotAmount) ||
    overbookingType === EventOverbookingType.DUPLICATE_EVENT_FOR_EXTRA_PARTICIPANTS;

  const onSubmitCancelEvent = (formValues: IEventAction) => {
    setShowCancelModal(false);

    if (onCancel) {
      onCancel(formValues);
    }
  };

  const handleDuplicateParticipants = () => {
    if (event.get('persons')?.size) {
      setShowDuplicateParticipantsModal(true);
    } else if (onDuplicate) {
      onDuplicate(false);
    }
  };

  const isEditingProhibited =
    event.get('preventEditingAfterDays') &&
    hoursBeforeEvent <= Number(event.get('editRestrictionBeforeDays') || 0) * 24;

  const disableEdit = showWarning || isEditingProhibited;

  const getcantCancelEvent = () => {
    if (event.get('canceled')) {
      return false;
    }

    return (
      event.get('preventCancelingAfterDays') &&
      hoursBeforeEvent <= Number(event.get('cancelRestrictionBeforeDays') || 0) * 24
    );
  };

  const cantCancelEvent = showWarning || getcantCancelEvent();

  const cantJoinEvent =
    event.get('preventJoiningAfterDays') &&
    hoursBeforeEvent <= Number(event.get('joinRestrictionBeforeDays') || 0) * 24;

  return (
    <Box className={classes.root}>
      <Box
        className={classes.colorLine}
        style={!readOnly ? { backgroundColor: event.get('color') } : {}}
      />

      <Grid container wrap="nowrap" justifyContent="space-between" className={classes.titleWrapper}>
        <Grid item>
          <Grid container wrap="nowrap" alignItems="flex-end">
            <Typography className={classes.title} variant="h4">
              {event.get('title')}
            </Typography>
            {event.get('canceled') && (
              <Typography component="span" color="textSecondary" className={classes.canceledStatus}>
                <FormattedMessage {...commonMessages.canceled} />
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container wrap="nowrap" className={classes.actionsWrapper}>
          {!readOnly && !isPastEvent ? (
            <Grid className={classes.row} item>
              {(onConfirmParticipation ||
                onCancelParticipation ||
                onCancel ||
                onRemind ||
                canDuplicate ||
                onEdit ||
                onDelete ||
                onJoinClass) && (
                <ActionsMenu horizontal>
                  {onEdit && BOOKING_EVENTS_EDIT && (
                    <ActionItem
                      key="editEvent"
                      disabled={disableEdit}
                      className={cx(classes.actionsMenuItem, {
                        [classes.editEventMenu]: disableEdit,
                      })}
                      icon={
                        <EditIcon
                          className={cx(classes.editIcon, {
                            [classes.inactive]: disableEdit,
                          })}
                        />
                      }
                      onClick={() => onEdit(event)}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        flex={1}
                      >
                        <Typography className={cx({ [classes.inactive]: disableEdit })}>
                          <FormattedMessage {...messages.editEvent} />
                        </Typography>
                        {disableEdit && (
                          <Tooltip
                            title={
                              <Typography variant="inherit">
                                <FormattedMessage
                                  {...(showWarning
                                    ? messages.canNotEditEventMsg
                                    : messages.restricted)}
                                />
                              </Typography>
                            }
                          >
                            <WarningIcon className={classes.warningIcon} />
                          </Tooltip>
                        )}
                      </Box>
                    </ActionItem>
                  )}

                  {canDuplicate && BOOKING_EVENTS_DUPLICATE && (
                    <ActionItem
                      key="duplicateEventAction"
                      className={classes.actionsMenuItem}
                      icon={<FileCopy fontSize="small" />}
                      onClick={handleDuplicateParticipants}
                    >
                      <FormattedMessage {...commonMessages.duplicateButton} />
                    </ActionItem>
                  )}

                  {module !== PeakModules.Booking && (
                    <>
                      {onConfirmParticipation && (
                        <ActionItem
                          key="confirmParticipationAction"
                          className={classes.actionsMenuItem}
                          disabled={
                            isNotAllowSelfBooking ||
                            appointmentStatus === PersonAppointmentStatus.Confirmed
                          }
                          icon={<UsersAddIcon />}
                          onClick={() => setShowConfirmParticipationModal(true)}
                        >
                          <FormattedMessage {...messages.confirmParticipation} />
                        </ActionItem>
                      )}

                      {onCancelParticipation && (
                        <ActionItem
                          key="cancelParticipationAction"
                          className={classes.actionsMenuItem}
                          disabled={
                            isNotAllowSelfBooking ||
                            appointmentStatus === PersonAppointmentStatus.Canceled
                          }
                          icon={<UsersCancelIcon />}
                          onClick={() => setShowCancelParticipationModal(true)}
                        >
                          <FormattedMessage {...messages.cancelParticipation} />
                        </ActionItem>
                      )}
                    </>
                  )}

                  {onRemind && BOOKING_EVENTS_REMIND_PARTICIPANTS && (
                    <ActionItem
                      key="remindEventAction"
                      className={classes.actionsMenuItem}
                      icon={<BellIcon className={classes.editIcon} />}
                      onClick={() => setShowRemindParticipantsModal(true)}
                    >
                      <FormattedMessage {...messages.remindParticipantsModalTitle} />
                    </ActionItem>
                  )}

                  {onJoinClass && (
                    <ActionItem
                      key="confirmParticipationAction"
                      disabled={cantJoinEvent || isDisabledJoinButton}
                      onClick={() => setShowJoinModal(true)}
                      className={cx(classes.actionsMenuItem, {
                        [classes.editEventMenu]: cantJoinEvent,
                      })}
                      icon={
                        <UsersJoinIcon
                          className={cx(classes.editIcon, {
                            [classes.inactive]: cantJoinEvent,
                          })}
                        />
                      }
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        flex={1}
                      >
                        <Typography className={cx({ [classes.inactive]: cantJoinEvent })}>
                          <FormattedMessage {...messages.joinClass} />
                        </Typography>
                        {cantJoinEvent && (
                          <Tooltip
                            title={
                              <Typography variant="inherit">
                                <FormattedMessage {...messages.restricted} />
                              </Typography>
                            }
                          >
                            <WarningIcon className={classes.warningIcon} />
                          </Tooltip>
                        )}
                      </Box>
                    </ActionItem>
                  )}

                  {onCancel && BOOKING_EVENTS_CANCEL && (
                    <ActionItem
                      key={event.get('canceled') ? 'restoreEventAction' : 'cancelEventAction'}
                      icon={
                        event.get('canceled') ? (
                          <EventAvailable fontSize="small" />
                        ) : (
                          <EventBusy
                            fontSize="small"
                            className={cx(classes.editIcon, {
                              [classes.inactive]: cantCancelEvent,
                            })}
                          />
                        )
                      }
                      disabled={cantCancelEvent}
                      onClick={
                        event.get('canceled')
                          ? () => onRestore && onRestore(event)
                          : () => setShowCancelModal(true)
                      }
                      className={cx(classes.actionsMenuItem, {
                        [classes.editEventMenu]: cantCancelEvent,
                      })}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        flex={1}
                      >
                        <Typography className={cx({ [classes.inactive]: cantCancelEvent })}>
                          <FormattedMessage
                            {...(event.get('canceled')
                              ? messages.restoreEventBtn
                              : messages.cancelEventBtn)}
                          />
                        </Typography>
                        {cantCancelEvent && (
                          <Tooltip
                            title={
                              <Typography variant="inherit">
                                <FormattedMessage
                                  {...(showWarning
                                    ? messages.canNotEditEventMsg
                                    : messages.restricted)}
                                />
                              </Typography>
                            }
                          >
                            <WarningIcon className={classes.warningIcon} />
                          </Tooltip>
                        )}
                      </Box>
                    </ActionItem>
                  )}

                  {onDelete && BOOKING_EVENTS_DELETE && (
                    <ActionItem
                      key="deleteEventAction"
                      className={classes.actionsMenuItem}
                      icon={<Delete fontSize="small" />}
                      onClick={() => setShowDeleteModal(true)}
                    >
                      <FormattedMessage {...commonMessages.deleteBtn} />
                    </ActionItem>
                  )}
                </ActionsMenu>
              )}
            </Grid>
          ) : null}
          <Grid item>
            <IconButton className={classes.closeBtn} size="small" onClick={onClose}>
              <SvgIcon className={classes.closeBtnIcon}>
                <Times />
              </SvgIcon>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Box className={classes.scrollbarWrapper}>
        <ScrollBox hasShadowsOnScroll suppressBottomShadow>
          <div ref={detailsRef} className={classes.detailsWrapper}>
            <Grid container justifyContent="space-between">
              <Grid className={classes.row} item>
                <Event className={classes.rowIcon} />
                <Typography>{currentDate.format('dddd, MMMM D')}</Typography>
              </Grid>
              {event.get('repeated') &&
                event.get('repeatingFrequency') === EventRepeatFrequency.WEEKLY &&
                event.get('repeatingWeekdays') && (
                  <Grid className={classes.row} item>
                    <SvgIcon className={classes.rowIcon}>
                      <Repeat />
                    </SvgIcon>
                    <Typography>
                      {getWeekdaysLabel(event.get('repeatingWeekdays').toJS())}
                    </Typography>
                  </Grid>
                )}
            </Grid>
            <Grid className={classes.row} container>
              <Schedule className={classes.rowIcon} />
              <Typography>{`${currentDate.format('LT')} - ${endTime.format('LT')} (${
                durationInMinutes ? transformMinutesToTimeFormat(durationInMinutes) : ''
              })`}</Typography>
            </Grid>
            {!!event.get('club') && (
              <Grid className={classes.row} container>
                <SvgIcon className={classes.rowIcon}>
                  <Building />
                </SvgIcon>
                <Typography>
                  {event
                    .get('club')
                    .getIn(['title'], event.get('club').get('name'))
                    .toUpperCase()}
                </Typography>
              </Grid>
            )}
            {!!resources && !!resources.size && (
              <Box display="flex" alignItems="center" mt={-0.5} mb={1}>
                <SvgIcon className={classes.rowIcon}>
                  <Package />
                </SvgIcon>
                <Box display="flex" flexWrap="wrap">
                  {resources.map(resourceItem => {
                    const isActiveResource = resourceItem.get('active');
                    const inactiveTitle = `${resourceItem.get('title')} (${renderIntlMessage(
                      commonMessages.inactiveOption,
                    )})`;

                    return (
                      <Chip
                        key={resourceItem.get('id')}
                        label={isActiveResource ? resourceItem.get('title') : inactiveTitle}
                        className={cx(classes.tag, {
                          [classes.inactive]: !isActiveResource,
                        })}
                      />
                    );
                  })}
                </Box>
              </Box>
            )}
            <Grid className={classes.row} item>
              <UserIcon className={classes.rowIcon} />

              <Typography>{`${countOfConfirmedParticipants}/${countOfParticipants}`}</Typography>
            </Grid>
            {!!event.get('description') && (
              <Grid className={classes.row} item>
                <AlignLeftIcon className={classes.rowIcon} />

                <Typography>{event.get('description')}</Typography>
              </Grid>
            )}
            {!!event.get('tags')?.size && (
              <Box display="flex" alignItems="center" mt={-0.5} mb={1}>
                <SvgIcon className={classes.rowIcon}>
                  <TagsIcon />
                </SvgIcon>

                <Box display="flex" flexWrap="wrap">
                  {event.get('tags').map(tagItem => {
                    const isActiveTag = tagItem.get('active');
                    const inactiveTitle = `${tagItem.get('title')} (${renderIntlMessage(
                      commonMessages.inactiveOption,
                    )})`;

                    return (
                      <Chip
                        key={tagItem.get('id')}
                        label={isActiveTag ? tagItem.get('title') : inactiveTitle}
                        className={cx(classes.tag, {
                          [classes.inactive]: !isActiveTag,
                        })}
                      />
                    );
                  })}
                </Box>
              </Box>
            )}
            {!!event.get('service')?.size && (
              <Box display="flex" alignItems="center">
                <ServicesIcon className={classes.rowIcon} />

                <TooltipTypography ellipsized>
                  {event.getIn(['service', 'title'])}
                </TooltipTypography>
              </Box>
            )}
            {!!trainersResources.size && (
              <ParticipantsList
                title={<FormattedMessage {...commonMessages.trainersLabel} />}
                data={trainersResources}
              />
            )}
            {!!persons?.size && (
              <ParticipantsList
                title={<FormattedMessage {...inputLabels.participants} />}
                data={persons.filter(item => !item.get('waitlist'))}
                isParticipants
              />
            )}

            {overbookingType === EventOverbookingType.WAIT_LIST && !!persons?.size && (
              <ParticipantsList
                title={<FormattedMessage {...messages.overbookingTypeWaitlist} />}
                data={persons.filter(item => item.get('waitlist'))}
              />
            )}

            {!!event.get('modificationHistory')?.size && (
              <ModificationHistory eventHistory={event.get('modificationHistory')} />
            )}
            {showCancelModal && (
              <CancelEventModal
                isUpdateEventLoading={isEventActionLoading}
                isOpen={showCancelModal}
                onSubmit={onSubmitCancelEvent}
                onClose={() => setShowCancelModal(false)}
                type={type}
                onLoadSenderAvailability={onLoadSenderAvailability}
                isRepeatedEvent={event.get('repeated')}
              />
            )}
            {showDeleteModal && onDelete && (
              <DeleteEventModal
                isOpen={showDeleteModal}
                onSubmit={onDelete}
                onClose={() => setShowDeleteModal(false)}
                isUpdateEventLoading={isEventActionLoading}
                type={type}
                onLoadSenderAvailability={onLoadSenderAvailability}
                isRepeatedEvent={event.get('repeated')}
              />
            )}
            {showDuplicateParticipantsModal && onDuplicate && (
              <DuplicateParticipantsModal
                isLoading={isEventActionLoading}
                isOpen={showDuplicateParticipantsModal}
                onClose={() => setShowDuplicateParticipantsModal(false)}
                onDuplicate={onDuplicate}
              />
            )}
            {showRemindParticipantsModal && onRemind && (
              <RemindParticipantsModal
                isLoading={isEventActionLoading}
                isOpen={showRemindParticipantsModal}
                onClose={() => setShowRemindParticipantsModal(false)}
                onRemind={onRemind}
                type={SenderAvailabilityTypeList.REMIND}
                module={PeakModules.Booking}
                event={event}
              />
            )}
            {showCancelParticipationModal && onCancelParticipation && (
              <ParticipationModal
                isUpdateEventLoading={isEventActionLoading}
                dialogTitle={<FormattedMessage {...messages.cancelParticipation} />}
                isRepeatedEvent={!!event.get('repeated')}
                isOpen={showCancelParticipationModal}
                onSubmit={onCancelParticipation}
                onClose={() => setShowCancelParticipationModal(false)}
              />
            )}
            {showConfirmParticipationModal && onConfirmParticipation && (
              <ParticipationModal
                isUpdateEventLoading={isEventActionLoading}
                dialogTitle={<FormattedMessage {...messages.confirmParticipation} />}
                isRepeatedEvent={!!event.get('repeated')}
                isOpen={showConfirmParticipationModal}
                onSubmit={onConfirmParticipation}
                onClose={() => setShowConfirmParticipationModal(false)}
              />
            )}
            {showJoinModal && onJoinClass && (
              <ParticipationModal
                isUpdateEventLoading={isEventActionLoading}
                dialogTitle={
                  <FormattedMessage
                    {...(isWhaitLIstModal ? messages.joinWaitlist : messages.joinClass)}
                  />
                }
                isRepeatedEvent={!!event.get('repeated')}
                isOpen={showJoinModal}
                onSubmit={onJoinClass}
                onClose={() => setShowJoinModal(false)}
                isWaitlist={isWhaitLIstModal}
              />
            )}
          </div>
        </ScrollBox>
      </Box>
    </Box>
  );
};

export default EventDetails;
