// eslint-disable-next-line import/extensions
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'reporting/sentry'; // Error reporting first!
import App from 'App';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './common/polyfills';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import './styles/index.scss';
import AxiosService from './services/auth/AxiosService';

declare global {
  interface Window {
    __MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__: boolean;
    attachEvent: any;
    removeEvent: any;
  }
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
};
firebase.initializeApp(firebaseConfig);

// Setting defaults for global axios instance
AxiosService.initiate();

const rootElement = createRoot(document.getElementById('root') as HTMLElement); // TODO - PRM-3575 need types

rootElement.render(<App />);

if (module.hot) {
  module.hot.accept('./App', () => {
    rootElement.render(<App />);
  });
}
