/* eslint no-param-reassign: 0 */
import baseApi from 'api/baseApi';
import { IPaginatedData } from 'common/interfaces/pagination';
import { IAddress, INamedEntity } from 'common/interfaces/common';
import {
  ICustomerEmergencyContactDto as IEmergencyContact,
  ICustomerInsuranceDto,
  IReqPutEmergencyPersonPayload,
} from 'common/interfaces/additionalInfo';

const memberApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getSalespersons: builder.query<INamedEntity<number>[], string | void>({
      query: search => ({
        url: '/api/v1/members/salespersons',
        method: 'GET',
        params: search ? { search } : undefined,
      }),
      transformResponse: (result: IPaginatedData<INamedEntity<number>>) => result.data,
    }),
    getEmergencyContact: builder.query<IEmergencyContact, string>({
      query: personId => ({
        url: `/api/v1/members/${personId}/info/emergency-contact`,
        method: 'GET',
      }),
    }),
    putEmergencyContact: builder.mutation<IEmergencyContact, IReqPutEmergencyPersonPayload>({
      query: ({ personId, emergencyContact }) => ({
        url: `/api/v1/members/${personId}/info/emergency-contact`,
        method: 'PUT',
        data: emergencyContact,
      }),
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const result = await cacheDataLoaded;

        dispatch(
          memberApi.util.updateQueryData('getEmergencyContact', arg.personId.toString(), draft => {
            draft.emergencyName = result.data.emergencyName;
            draft.emergencyPhone = result.data.emergencyPhone;
            draft.emergencyRelationship = result.data.emergencyRelationship;
          }),
        );
      },
    }),
    getInsurance: builder.query<ICustomerInsuranceDto, string>({
      query: personId => ({
        url: `/api/v1/members/${personId}/info/insurance`,
        method: 'GET',
      }),
    }),
    getAddress: builder.query<IAddress, string>({
      query: personId => ({
        url: `/api/v1/members/${personId}/info/address`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetSalespersonsQuery: useGetSalespersons,
  useLazyGetSalespersonsQuery: useLazyGetSalespersons,
  useGetEmergencyContactQuery: useGetEmergencyContact,
  usePutEmergencyContactMutation: usePutEmergencyContact,
  useGetInsuranceQuery: useGetInsurance,
  useGetAddressQuery: useGetAddress,
} = memberApi;

export default memberApi;
