import React from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Box, Grid, Typography, MenuItem, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import MomentAdapter from '@date-io/moment';
import moment from 'moment-timezone';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getRequiredErrorMessage } from 'common/utils/validation';
import { AdaptiveDatePicker, Select, TooltipTypography } from 'common/components';
import ClockInClockOutField, {
  CLOCK_IN_CLOCK_OUT_TIME,
} from '../ClockinClockOutField/ClockInClockOutField';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import useRootSelector from 'common/hooks/useRootSelector';
import inputLabels from 'common/messages/inputLabels';
import tableHeaders from 'common/messages/tableHeaders';
import {
  selectAllTimeclockEvents,
  selectPayrollJobPositions,
} from 'modules/timeclock/state/selectors';
import { payTypeLabels } from 'modules/employees/constants';
import { IAddClockInManuallyData } from 'modules/timeclock/interfaces/timeclock';
import { getTimeclockEvents } from 'modules/timeclock/state/actions';
import { minutesOfDay } from 'common/utils/time';
import { CustomTheme } from 'common/ui/interfaces';

// TODO - PRM-1810 tmp form interface
interface IFormValues {
  jobPositionId: any;
  date: any;
  eventId: any;
  clockIn: any;
  clockOut: any;
}

interface IProps {
  onSubmit: (data: IAddClockInManuallyData) => void;
  formId: string;
  employeeId: number;
}

const getValidationSchema = yup.object().shape({
  jobPositionId: yup
    .string()
    .nullable()
    .required(getRequiredErrorMessage),
  date: yup
    .string()
    .nullable()
    .required(getRequiredErrorMessage),
  eventId: yup
    .string()
    .nullable()
    .required(getRequiredErrorMessage),
  clockIn: yup
    .string()
    .nullable()
    .test('clockIn', (clockInTime, context) => {
      const clockOutTime = context.parent.clockOut;

      if (
        clockInTime &&
        clockOutTime &&
        minutesOfDay(moment(clockInTime)) >= minutesOfDay(moment(clockOutTime))
      ) {
        return context.createError({ type: CLOCK_IN_CLOCK_OUT_TIME });
      }

      return true;
    })
    .required(getRequiredErrorMessage),
  clockOut: yup
    .string()
    .nullable()
    .test('clockOut', (clockOutTime, context) => {
      const clockInTime = context.parent.clockIn;

      if (
        clockOutTime &&
        clockInTime &&
        minutesOfDay(moment(clockInTime)) >= minutesOfDay(moment(clockOutTime))
      ) {
        return context.createError({ type: CLOCK_IN_CLOCK_OUT_TIME });
      }

      return true;
    })
    .required(getRequiredErrorMessage),
});

const useStyles = makeStyles((theme: CustomTheme) => ({
  optionTitle: {
    marginRight: theme.spacing(1),
  },
}));

const AddUnitDataForm = ({ onSubmit, formId, employeeId }: IProps): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();
  const dispatch = useDispatch();
  const [timezoneMoment] = useTimezoneMoment();

  const jobPositionsOptions = useRootSelector(selectPayrollJobPositions);
  const events = useRootSelector(selectAllTimeclockEvents);

  const classes = useStyles();

  const formMethods = useForm<IFormValues>({
    defaultValues: {
      jobPositionId: null,
      date: null,
      eventId: null,
      clockIn: null,
      clockOut: null,
    },
    resolver: yupResolver<IFormValues>(getValidationSchema),
    mode: 'onTouched',
  });

  const { control, formState, handleSubmit, getValues } = formMethods;
  const { errors } = formState;

  const getEvents = (dateValue: string): void => {
    const selectedJobPosition = jobPositionsOptions.find(
      jobPosition => jobPosition.id === getValues().jobPositionId,
    );

    const clubId = selectedJobPosition?.club?.id;

    const requestData = {
      rangeEndDate: timezoneMoment(dateValue)
        .endOf('day')
        .utc()
        .format(),
      rangeStartDate: timezoneMoment(dateValue)
        .startOf('day')
        .utc()
        .format(),
      clubIds: clubId ? [clubId] : [],
    };
    dispatch(getTimeclockEvents(employeeId, requestData));
  };

  const handleSubmitData = data => {
    const selectedEvent = events?.data?.find(event => event.id === data.eventId);
    const newData = {
      ...data,
      date: timezoneMoment(data.date, 'YYYY-MM-DD')
        .utc()
        .format(),
      eventDate: timezoneMoment(selectedEvent?.date)
        .utc()
        .format(),
      clockIn: timezoneMoment(data.clockIn).format('HH:mm:ss'),
      clockOut: timezoneMoment(data.clockOut).format('HH:mm:ss'),
    };

    onSubmit(newData);
  };

  return (
    <FormProvider {...formMethods}>
      <form id={formId} onSubmit={handleSubmit(handleSubmitData)}>
        <Box overflow="hidden">
          <Box mb={1}>
            <Controller
              control={control}
              name="jobPositionId"
              render={({ field: { value, onChange } }) => (
                <Select
                  label={<FormattedMessage {...tableHeaders.jobPosition} />}
                  variant="outlined"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!errors.jobPositionId}
                  helperText={renderIntlMessage(errors.jobPositionId?.message)}
                >
                  {jobPositionsOptions?.map(position => (
                    <MenuItem key={position.id} value={position.id}>
                      <TooltipTypography className={classes.optionTitle} ellipsized>
                        {`${position.jobTitle} at ${position.club.title} (${position.department.title})`}
                      </TooltipTypography>
                      <Typography variant="body1" color="primary">
                        {payTypeLabels.translate(position.payType)}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={4}>
              <MuiPickersUtilsProvider utils={MomentAdapter}>
                <Controller
                  name="date"
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <AdaptiveDatePicker
                      value={value}
                      onChange={v => {
                        onChange(v);
                        getEvents(v);
                      }}
                      onBlur={onBlur}
                      disableFuture
                      label={<FormattedMessage {...inputLabels.date} />}
                      error={!!errors.date}
                      helperText={renderIntlMessage(errors.date?.message)}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <ClockInClockOutField />
          </Grid>

          <Box mt={1}>
            <Controller
              control={control}
              name="eventId"
              render={({ field: { value, onChange } }) => (
                <Select
                  label={<FormattedMessage {...inputLabels.eventLabel} />}
                  variant="outlined"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  disabled={!events?.data?.length}
                  error={!!errors.eventId}
                  helperText={renderIntlMessage(errors.eventId?.message)}
                >
                  {events?.data?.map(event => (
                    <MenuItem key={event.id} value={event.id}>
                      {event.title}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default AddUnitDataForm;
