import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { IServicesListItemImt } from '../../interfaces/services';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
import { ReactComponent as EditIcon } from 'img/icons/edit.svg';
import {
  ActionItem,
  ActionsMenu,
  DialogComponent,
  ScrollBox,
  StatusLabel,
  Table,
} from 'common/components';
import commonMessages from 'common/messages/messages';
import tableHeaders from 'common/messages/tableHeaders';
import menuItems from 'common/messages/menuItems';
import messages from 'modules/services/messages/messages';
import { IFilterSettings } from 'common/interfaces/filter';
import { resetServiceAction } from '../../state/services/actions';
import { ReactComponent as XDeleteIcon } from 'img/icons/times.svg';
import { RedeemTypeOptions, ServiceTypeOptions } from 'modules/services/constants/services';

interface IServicesListTableProps {
  items: ImmutableList<IServicesListItemImt>;
  totalRows: number;
  isLoading: boolean;
  isChangeServiceStatusLoading?: boolean;
  changeServiceStatusResult: string;
  meta: IPageMetaImt;
  tableParams: ITableParams;
  changeServiceStatus: (id: string, isActive: boolean) => void;
  onChangeParams: (tableParams: ITableParams) => void;
  tableFilterSettings: IFilterSettings[];
}

const headerOptions: IHeadCell[] = [
  {
    id: 'title',
    label: <FormattedMessage {...tableHeaders.name} />,
    sort: true,
    align: 'left',
    padding: 'none',
  },
  { id: 'redeem_type', label: <FormattedMessage {...tableHeaders.redeemType} />, sort: true },
  { id: 'type', label: <FormattedMessage {...tableHeaders.type} />, sort: true },
  { id: 'active', label: <FormattedMessage {...tableHeaders.status} />, sort: true },
  {
    id: 'actions',
    label: '',
    sort: false,
    align: 'center',
    padding: 'none',
  },
];

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
});

const ServicesListTable = (props: IServicesListTableProps): JSX.Element => {
  const {
    isLoading,
    isChangeServiceStatusLoading,
    items,
    totalRows,
    tableParams,
    changeServiceStatusResult,
    onChangeParams,
    changeServiceStatus,
    tableFilterSettings,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedService, setSelectedService] = useState<{
    id: string;
    title: string;
  } | null>(null);
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
  const [serviceNewStatus, setServiceNewStatus] = useState<boolean>(false);

  const classes = useStyles();

  const onChangeServiceStatus = () => {
    if (selectedService) {
      changeServiceStatus(selectedService.id, serviceNewStatus);
    }
  };

  const onCloseChangeStatusModal = useCallback(() => {
    setSelectedService(null);
    setIsChangeStatusModalOpen(false);
  }, [setIsChangeStatusModalOpen]);

  useEffect(() => {
    onCloseChangeStatusModal();
  }, [changeServiceStatusResult, onCloseChangeStatusModal]);

  const onEditAction = (itemId: string) => {
    dispatch(resetServiceAction());
    navigate(`/services/services-list/${itemId}`);
  };

  const createRows = (): ITableRow[] => {
    return items
      .map(
        (service: IServicesListItemImt): ITableRow => {
          const itemId = service.get('id');
          return {
            id: itemId,
            cells: [
              {
                label: service.get('title'),
                className: 'body-cell',
                variant: 'h5',
              },
              {
                label: '',
                className: 'body-cell',
                cellComponent: (
                  <Typography variant="body1">
                    {RedeemTypeOptions.translate(service.get('redeemType'))}
                  </Typography>
                ),
              },
              {
                label: '',
                className: 'body-cell',
                cellComponent: (
                  <Typography variant="body1">
                    {ServiceTypeOptions.translate(service.get('type'))}
                  </Typography>
                ),
              },
              {
                label: 'status',
                className: 'body-cell',
                cellComponent: <StatusLabel isActive={service.get('active')} />,
              },
              {
                label: 'menu',
                className: 'body-actions-cell',
                cellComponent: (
                  <ActionsMenu horizontal tableActionsMode>
                    <ActionItem
                      key="edit"
                      icon={<EditIcon className={classes.icon} />}
                      onClick={() => onEditAction(itemId)}
                    >
                      <FormattedMessage {...menuItems.edit} />
                    </ActionItem>
                    <ActionItem
                      key="2"
                      icon={
                        service.get('active') ? (
                          <XDeleteIcon className={classes.icon} />
                        ) : (
                          <CheckIcon className={classes.icon} />
                        )
                      }
                      onClick={() => {
                        setIsChangeStatusModalOpen(true);
                        setServiceNewStatus(!service.get('active'));
                        setSelectedService({ id: service.get('id'), title: service.get('title') });
                      }}
                    >
                      {service.get('active') ? (
                        <FormattedMessage {...menuItems.deactivate} />
                      ) : (
                        <FormattedMessage {...menuItems.activate} />
                      )}
                    </ActionItem>
                  </ActionsMenu>
                ),
              },
            ],
          };
        },
      )
      .toJS();
  };

  const renderModals = () => {
    return (
      <DialogComponent
        size="xs"
        title={<FormattedMessage {...messages.changeStatusTitle} />}
        submitBtnTitle={
          serviceNewStatus ? (
            <FormattedMessage {...commonMessages.activateBtn} />
          ) : (
            <FormattedMessage {...commonMessages.deactivateBtn} />
          )
        }
        isOpen={isChangeStatusModalOpen}
        onClose={onCloseChangeStatusModal}
        onSubmit={onChangeServiceStatus}
        disableFullScreenBehavior
        loading={isChangeServiceStatusLoading}
      />
    );
  };

  return (
    <>
      <ScrollBox suppressScrollY>
        <Box style={{ minWidth: 500, height: '100%', overflow: 'hidden' }}>
          <Table
            addButton={
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon className={classes.icon} />}
                onClick={() => {
                  navigate('/services/services-list/new');
                }}
              >
                <FormattedMessage {...commonMessages.newBtn} />
              </Button>
            }
            suppressSmartBehavior
            suppressFiltersMobilePadding
            showPerPageSelect
            headerOptions={headerOptions}
            isLoading={isLoading}
            rows={createRows()}
            totalRows={totalRows}
            onChangeParams={onChangeParams}
            filters={tableFilterSettings}
            tableParams={tableParams}
          />
        </Box>
      </ScrollBox>
      {renderModals()}
    </>
  );
};

export default ServicesListTable;
