import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ITableParams } from 'common/interfaces/table';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { List as ImmutableList } from 'immutable';
import * as selectors from '../../state/services/selectors';
import * as actions from '../../state/services/actions';
import { ServicesListTable } from '../../components';
import ServicesPageWrapper from '../../components/ServicesPageWrapper/ServicesPageWrapper';
import { IServicesListItemImt } from '../../interfaces/services';
import { ActionResult, ActiveInactiveStatus, QueryPageList } from 'common/constants';
import messages from '../../messages/messages';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
import { FormattedMessage } from 'react-intl';
import tableFilters from 'common/messages/tableFilters';
import { makeTableParams, pushQueryToUrl } from 'common/utils/http';
import { updateQueryParams } from 'common/state/queryPage-lists/actions';
import { RedeemTypeOptions, ServiceTypeOptions } from 'modules/services/constants/services';
import { useAppDispatch } from 'store/hooks';
import useRootSelector from 'common/hooks/useRootSelector';

const tableFilterSettings: IFilterSettings[] = [
  {
    name: 'active',
    title: <FormattedMessage {...tableFilters.status} />,
    type: FilterTypes.SINGLE,
    options: ActiveInactiveStatus.map(status => ({
      key: status.key,
      label: status.label,
      value: status.value,
    })),
    defaultValue: ActiveInactiveStatus[0],
  },
  {
    name: 'redeemType',
    title: <FormattedMessage {...tableFilters.redeemType} />,
    type: FilterTypes.SINGLE,
    options: RedeemTypeOptions.getFilterOptions(),
  },
  {
    name: 'type',
    title: <FormattedMessage {...tableFilters.type} />,
    type: FilterTypes.SINGLE,
    options: ServiceTypeOptions.getFilterOptions(),
  },
];

const ServicesList: FC = () => {
  const dispatch = useAppDispatch();

  const isServicesListLoading: boolean = useRootSelector(selectors.selectServicesListLoading());
  const servicesList: ImmutableList<IServicesListItemImt> = useRootSelector(
    selectors.selectServicesList(),
  );
  const servicesListMeta: IPageMetaImt = useRootSelector(selectors.selectServicesListMeta());
  const isChangeServiceStatusLoading: boolean = useRootSelector(
    selectors.selectChangeServiceStatusLoading(),
  );
  const changeServiceStatusResult: ActionResult = useRootSelector(
    selectors.selectChangeServiceStatusResult(),
  );

  const location = useLocation();
  const navigate = useNavigate();

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilterSettings, location.search, {
      orderBy: 'TITLE',
    }),
  );

  useEffect(() => {
    dispatch(actions.fetchServicesList(tableParams));
  }, [dispatch, tableParams]);

  useEffect(() => {
    if (changeServiceStatusResult) {
      dispatch(actions.fetchServicesList(tableParams));
    }
  }, [changeServiceStatusResult, dispatch, tableParams]);

  const updateQueryFunction = useCallback(
    query => dispatch(updateQueryParams({ page: QueryPageList.SERVICES, query })),
    [dispatch],
  );

  const handleChangeTableProps = useCallback(
    (tableProps: ITableParams): void => {
      setTableParams(tableProps);
      pushQueryToUrl(navigate, tableProps, updateQueryFunction);
    },
    [navigate, updateQueryFunction],
  );

  const changeStatus = useCallback(
    (serviceId: string, isActive: boolean): void => {
      dispatch(actions.changeServiceStatus(serviceId, isActive));
    },
    [dispatch],
  );

  return (
    <ServicesPageWrapper titleMessage={messages.services} backRedirectLink="/services">
      <ServicesListTable
        items={servicesList}
        totalRows={servicesListMeta.get('total') || servicesList.size}
        isLoading={isServicesListLoading}
        isChangeServiceStatusLoading={isChangeServiceStatusLoading}
        changeServiceStatusResult={changeServiceStatusResult}
        meta={servicesListMeta}
        onChangeParams={handleChangeTableProps}
        changeServiceStatus={changeStatus}
        tableFilterSettings={tableFilterSettings}
        tableParams={tableParams}
      />
    </ServicesPageWrapper>
  );
};

export default ServicesList;
