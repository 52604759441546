import React, { useEffect, useMemo } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { ServiceType } from 'common/constants/service';
import {
  IncludedServicePackageType,
  IPackageService,
  IPackageServiceBundle,
  IPackageServicesPackagesListImt,
} from 'modules/services/interfaces/packages';
import { SearchInputWithOptions } from 'common/components';
import * as actions from 'modules/services/state/packages/actions';
import * as selectors from 'modules/services/state/packages/selectors';
import { useAppDispatch } from 'store/hooks';
import messages from 'modules/services/messages/messages';
import { colors } from 'common/ui/theme/default';
import { checkObjectPropertyExist } from 'common/utils';

interface IServicesAndPackagesSearchProps {
  packageId?: string;
  servicePackageList: string[];
  onInclude: (serviceOrPackage: IPackageService, type: IncludedServicePackageType) => void;
}

const useStyles = makeStyles({
  option: {
    '& h5': {
      color: colors.primary,
    },
    '&:hover h5, &[data-focus="true"] h5': {
      color: 'inherit',
    },
  },
});

const ServicesAndPackagesSearch: React.FC<IServicesAndPackagesSearchProps> = (
  props: IServicesAndPackagesSearchProps,
): JSX.Element => {
  const intl = useIntl();
  const { packageId, onInclude, servicePackageList } = props;
  const dispatch = useAppDispatch();

  const serviceAndPackageSearchResult: IPackageServicesPackagesListImt = useRootSelector(
    selectors.selectPackageServicesPackagesList,
  );
  const isServicesListLoading: boolean = useRootSelector(
    selectors.selectPackageServicesPackagesLoading,
  );

  const { watch } = useFormContext();
  const classes = useStyles();

  const optionsList: Array<IPackageService & IPackageServiceBundle> = useMemo(() => {
    if (!serviceAndPackageSearchResult) {
      return [];
    }

    return [
      ...serviceAndPackageSearchResult.get('services').toJS(),
      ...serviceAndPackageSearchResult
        .get('packages')
        .filter(p => p.get('id') !== packageId)
        .toJS(),
    ];
  }, [packageId, serviceAndPackageSearchResult]);

  const includedServices = watch('includedServices');

  const isFamilyMemberServiceAdded = !!includedServices.find(
    ({ service }) => service?.type === ServiceType.FamilyMember,
  );

  const servicesGroupBy = (option: IPackageService): string => {
    if (option.type) return intl.formatMessage(messages.servicesLabel);
    return intl.formatMessage(messages.servicePackages);
  };

  const uploadServicesBySearch = (search: string): void => {
    dispatch(
      actions.fetchPackageServicesAndPackages(search, packageId || null, servicePackageList),
    );
  };

  const handleServiceOrPackageSelect = (
    event,
    option: IPackageService & IPackageServiceBundle,
  ): void => {
    onInclude(
      option,
      option.type ? IncludedServicePackageType.Service : IncludedServicePackageType.Package,
    );
  };

  useEffect(() => {
    return () => {
      dispatch(actions.resetPackageServicesAndPackages());
    };
  }, [dispatch]);

  return (
    <SearchInputWithOptions<IPackageService & IPackageServiceBundle>
      fullWidth
      classes={{ option: classes.option }}
      isAutoDeselect
      options={optionsList}
      loading={isServicesListLoading}
      placeholder={intl.formatMessage(messages.searchServicesLabel)}
      renderOption={(item: IPackageService & IPackageServiceBundle) => {
        const { title, hasOwnBillingSettings } = item;
        return (
          <Box display="flex" alignItems="center" width="100%" height="100%">
            {title}
            {checkObjectPropertyExist(item, 'hasOwnBillingSettings') && !hasOwnBillingSettings && (
              <>
                &nbsp;
                <Typography variant="h5">
                  <FormattedMessage {...messages.freeOption} />
                </Typography>
              </>
            )}
          </Box>
        );
      }}
      getOptionLabel={option => option && option.title}
      getOptionDisabled={option =>
        option.type === ServiceType.FamilyMember && isFamilyMemberServiceAdded
      }
      groupBy={servicesGroupBy}
      getOptionsByValue={uploadServicesBySearch}
      onChange={handleServiceOrPackageSelect}
    />
  );
};

export default ServicesAndPackagesSearch;
