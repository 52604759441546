import React, { FC, lazy, Suspense, useContext, useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { Navigate, Route, Routes as SwitchRoutes, useNavigate } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { selectIsUserLoggedIn } from 'modules/authentication/state/selectors';

import { SIGN_IN_PATH, PRIVACY_POLICY_PATH } from 'common/constants/globalConstants';
import { NavigationContext } from 'common/createContext/navigationContext';

import { Header, Sidebar } from 'components';
import { LoadingBackdrop } from 'common/components';
import Main from 'modules/authentication/Main';
import ClientRoutes from 'routes/ClientRoutes/ClientRoutes';
import MemberPortalRoutes from 'routes/MemberRoutes/MemberRoutes';
import { useUpdateUserRole } from 'common/hooks/useUpdateUserRole';
import { selectIsMemberPortalUserLoggedIn } from 'memberPortalModules/authentication/state/selectors';
import MemberPortalSidebar from 'components/MemberPortalSidebar/MemberPortalSidebar';
import PermissionContextProvider from 'common/containers/PermissionContextProvider/PermissionContextProvider';

const PrivacyPolicy = lazy(() =>
  import('modules/privacy-policy/containers/PrivacyPolicy/PrivacyPolicy'),
);
const CatchAllRoute = lazy(() => import('./CatchAll'));

const Routes: FC = () => {
  const clientPortalUserLogged = useRootSelector(selectIsUserLoggedIn);
  const memberPortalUserLogged = useRootSelector(selectIsMemberPortalUserLoggedIn);

  const userLogged = clientPortalUserLogged || memberPortalUserLogged;

  const { showNavigation } = useContext(NavigationContext);

  const navigate = useNavigate();

  const userRole = useUpdateUserRole();

  const isClientPortal = userRole === 'client';
  const isMemberPortal = userRole === 'member';

  useEffect(() => {
    if (!userLogged) {
      navigate(SIGN_IN_PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogged]);

  return (
    <PermissionContextProvider skip={!clientPortalUserLogged}>
      <Box display="flex" height="100vh" width="100vw" flexDirection="column">
        {showNavigation && userLogged && <Header />}

        <Box display="flex" flexGrow={1} minHeight={0} minWidth={0} position="relative">
          {showNavigation && clientPortalUserLogged && <Sidebar />}
          {showNavigation && memberPortalUserLogged && <MemberPortalSidebar />}
          <Box width="100%" className="pageWrapper" height="100%" flex={1}>
            <Suspense fallback={<LoadingBackdrop />}>
              <SwitchRoutes>
                <Route path="/commerceHubIFrame.html" />
                <Route path="/ITransactIFrame.html" />
                <Route path={SIGN_IN_PATH} element={<Main />} />
                <Route path={PRIVACY_POLICY_PATH} element={<PrivacyPolicy />} />
                {isClientPortal && <Route path="/*" element={<ClientRoutes />} />}
                {isMemberPortal && <Route path="/*" element={<MemberPortalRoutes />} />}
                {!userLogged && <Route path="*" element={<Navigate replace to={SIGN_IN_PATH} />} />}
                <Route path="*" element={<CatchAllRoute />} />
              </SwitchRoutes>
            </Suspense>
          </Box>
        </Box>
      </Box>
    </PermissionContextProvider>
  );
};

export default Routes;
