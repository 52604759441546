import React, { FC, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { DictionaryList } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';
import { insuranceValidationSchema } from 'common/validationSchemas/InsuranceValidationSchema';
import { AlertCondition } from 'common/interfaces/alerts';
import { IProfileInfoImt } from 'common/components/PersonProfile/interfaces';
import { ICustomerInsuranceDto, IFormInsurance } from 'common/interfaces/additionalInfo';
import { DialogComponent } from 'common/components';
import InsuranceSection from 'common/components/Steps/AdditionalInfoStep/InsuranceSection/InsuranceSection';
import { useAppDispatch } from 'store/hooks';
import messages from 'common/components/Steps/AdditionalInfoStep/messages';
import useRootSelector from 'common/hooks/useRootSelector';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import { useGetInsurance } from 'modules/members/api/member.api';

interface IInsuranceInfoModal {
  alert: AlertCondition | null;
  isOpen: boolean;
  onSubmit: (info: Omit<ICustomerInsuranceDto, 'id'>, alertId: string | null) => void;
  switchingModal: (alertCondition: AlertCondition | null) => void;
  profile: IProfileInfoImt;
}

const InsuranceInfoModal: FC<IInsuranceInfoModal> = ({
  isOpen,
  switchingModal,
  onSubmit,
  alert,
  profile,
}) => {
  const dispatch = useAppDispatch();
  const profileRequiredFields = useRootSelector(selectProfileRequiredFields);
  const isSubmitted = useRootSelector(selectIsSubmittedRecommendedFieldsModal);
  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();
  const { data: insuranceInfo } = useGetInsurance(String(profile.get('id')));

  const formMethods = useForm<IFormInsurance>({
    defaultValues: {
      insuranceCompany: null,
      insuranceMemberId: '',
    },
    resolver: yupResolver(yup.object(insuranceValidationSchema(profileRequiredFields))),
    mode: 'onBlur',
  });

  const { handleSubmit, getValues, reset } = formMethods;

  useEffect(() => {
    if (insuranceInfo) {
      reset({
        insuranceCompany: insuranceInfo.insuranceCompany,
        insuranceMemberId: insuranceInfo.insuranceMemberId,
      });
    }
  }, [reset, insuranceInfo]);

  useEffect(() => {
    if (isSubmitted) {
      const values = getValues();

      onSubmit(values, alert);
      switchingModal(alert);

      dispatch(showRecommendedFieldsModal(false));
    }
  }, [alert, dispatch, getValues, isSubmitted, onSubmit, switchingModal, insuranceInfo]);

  useEffect(() => {
    if (isOpen) {
      dispatch(
        fetchDictionaryList(DictionaryList.INSURANCE_COMPANY, { module: PeakModules.FrontDesk }),
      );
    }
  }, [dispatch, isOpen]);

  const onSubmitForm = (values: ICustomerInsuranceDto): void => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty({ insuranceDto: values });

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(values, alert);
      switchingModal(alert);
    }
  };

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...messages.newMemberAdditionalInfoInsurance} />}
      isOpen={isOpen}
      onClose={() => switchingModal(alert)}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <FormProvider {...formMethods}>
        <InsuranceSection recommendedFields={profileRecommendedFields} />
      </FormProvider>
    </DialogComponent>
  );
};

export default InsuranceInfoModal;
