import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import { Button, makeStyles, Typography } from '@material-ui/core';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { IModificationHistoryImt, RevisionType } from 'common/components/PersonProfile/interfaces';
import { IFilterSettings } from 'common/interfaces/filter';

import { Table } from 'common/components/index';

import tableHeaders from 'common/messages/tableHeaders';
import messages from 'common/components/PersonProfile/messages';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';

const useStyles = makeStyles(() => ({
  detailsCell: {
    fontWeight: 700,
  },
}));

interface IProps {
  modificationHistory?: List<IModificationHistoryImt>;
  modificationHistoryMeta?: IPageMetaImt;
  modificationHistoryLoading?: boolean;
  hideSearchInput?: boolean;
  hidePagination?: boolean;
  onSelectHistoryItem: (revisionId: number) => void;
  onChangeTableParams?: (tableParams: ITableParams) => void;
  tableFilterSettings?: IFilterSettings[];
  tableParams?: ITableParams;
  disableSorting?: boolean;
  hidePadding?: boolean;
  suppressFiltersMobilePadding?: boolean;
}

const ModificationHistoryTable = ({
  modificationHistory,
  modificationHistoryMeta,
  modificationHistoryLoading,
  hideSearchInput = false,
  hidePagination = false,
  onSelectHistoryItem,
  onChangeTableParams,
  tableParams,
  tableFilterSettings,
  disableSorting,
  hidePadding = false,
  suppressFiltersMobilePadding = false,
}: IProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();
  const classes = useStyles();

  const headerOptions = useMemo(
    (): IHeadCell[] => [
      {
        id: 'date',
        label: <FormattedMessage {...tableHeaders.date} />,
        sort: !disableSorting,
      },
      {
        id: 'change',
        label: <FormattedMessage {...tableHeaders.change} />,
        sort: !disableSorting && false,
        padding: 'none',
      },
      {
        id: 'changedBy',
        label: <FormattedMessage {...tableHeaders.changedBy} />,
        sort: !disableSorting && false,
        align: 'left',
      },
    ],
    [disableSorting],
  );

  const rows = useMemo(() => {
    return (
      modificationHistory
        ?.map(
          (historyItem: IModificationHistoryImt): ITableRow => ({
            id: `${historyItem.get('revisionId')}`,
            cells: [
              {
                label: '',
                cellComponent: (
                  <Button
                    variant="text"
                    className="btn-text"
                    type="button"
                    onClick={() => onSelectHistoryItem(historyItem.get('revisionId'))}
                  >
                    <Typography variant="h6" color="primary">
                      {`${timezoneMoment(historyItem.get('timestamp')).format(
                        DEFAULT_DATE_TIME_FORMAT,
                      )}`}
                    </Typography>
                  </Button>
                ),
              },
              {
                label: '',
                cellComponent: (
                  <Typography variant="caption" className={classes.detailsCell}>
                    <FormattedMessage
                      {...(historyItem.get('revisionType') === RevisionType.ADD
                        ? messages.memberCreateChangeTypeLabel
                        : messages.memberEditChangeTypeLabel)}
                    />
                  </Typography>
                ),
              },
              {
                label: '',
                cellComponent: (
                  <Typography variant="caption">
                    {historyItem.getIn(['lastModifiedBy', 'title'])}
                  </Typography>
                ),
              },
            ],
          }),
        )
        .toJS() || []
    );
  }, [classes, modificationHistory, onSelectHistoryItem, timezoneMoment]);

  return (
    <Table
      rows={rows}
      headerOptions={headerOptions}
      tableParams={tableParams}
      filters={tableFilterSettings}
      hideSearchInput={hideSearchInput}
      showPerPageSelect={!hidePagination}
      hidePagination={hidePagination}
      hideToolbar
      totalRows={modificationHistoryMeta?.get('total')}
      page={modificationHistoryMeta?.get('page')}
      isLoading={modificationHistoryLoading}
      onChangeParams={onChangeTableParams}
      borderShadow={false}
      hidePadding={hidePadding}
      suppressFiltersMobilePadding={suppressFiltersMobilePadding}
    />
  );
};

export default ModificationHistoryTable;
