import React, { FC, useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { List as ImmutableList } from 'immutable';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import some from 'lodash/some';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { payTypeLabels } from 'modules/employees/constants';
import { EmployeePayTypes } from 'common/constants/employee';
import { DictionaryList } from 'common/constants';
import employeesMessages from 'common/messages/employeesMessages';
import { ServerError } from 'common/errors/serverErrors';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { selectCurrentCorporateUserAvailableClubs } from 'modules/authentication/state/selectors';
import { selectUpdateEmployeeError } from 'modules/employees/state/selectors';
import { useAppDispatch } from 'store/hooks';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';
import { IServerError } from 'common/interfaces/http';
import { AlertTypes } from 'common/interfaces/alerts';
import { MultipleSelect, NumberTextField, Select, Checkbox, Alert } from 'common/components';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import errorMessages from 'common/errors/messages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getString } from 'common/utils/typeUtils';
import IntlService from 'services/intl/IntlService';

const useStyles = makeStyles(() => ({
  alert: {
    margin: '4px',
  },
  btnRemoveContainer: {
    paddingTop: '11px',
    '& button': {
      padding: '6px',
      fontSize: '16px',
    },
  },
}));

// TODO - PRM-1810 tmp form interface
interface IFormValues {
  jobPositions: Record<string, any>[];
  [field: string]: any;
}

interface IJobSectionProps {
  isVisibleTitle?: boolean;
  disableEditableFields?: boolean;
}

const JobSection: FC<IJobSectionProps> = ({ isVisibleTitle = true, disableEditableFields }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { control, formState, watch, trigger } = useFormContext<IFormValues>();
  const { errors } = formState;

  const { fields = [], append, remove } = useFieldArray({
    control,
    name: 'jobPositions',
    keyName: 'key',
  });

  const clubs = useRootSelector(selectCurrentCorporateUserAvailableClubs);
  const departments: ImmutableList<IDictionaryCommonItem> = useRootSelector(
    selectDictionaryList(DictionaryList.DEPARTMENTS),
  );
  const updateEmployeeError: IServerError = useRootSelector(selectUpdateEmployeeError);
  const renderIntlMessage = useRenderIntlMessage();

  const hasDuplicateJobPositionError = !!updateEmployeeError?.codes?.find(
    errorItem => errorItem === ServerError.DUPLICATE_JOB_POSITION_ERROR,
  );

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.DEPARTMENTS));
  }, [dispatch]);

  return (
    <Grid container spacing={1}>
      {isVisibleTitle && (
        <Grid item xs={12}>
          <Typography variant="button" color="textSecondary" gutterBottom>
            <FormattedMessage {...employeesMessages.job} />
          </Typography>
        </Grid>
      )}

      {hasDuplicateJobPositionError ? (
        <Alert
          title={getString(renderIntlMessage(errorMessages.duplicateJobPositionError))}
          severity={AlertTypes.Danger}
          extraClassName={classes.alert}
        />
      ) : null}

      {fields.length && errors?.jobPositionsRoot?.message ? (
        <Alert
          title={getString(renderIntlMessage(errors.jobPositionsRoot.message))}
          severity={AlertTypes.Danger}
          extraClassName={classes.alert}
        />
      ) : null}

      {fields.map(({ salary, club, jobTitle, department, payType, id, key }, index) => {
        const disableFields = disableEditableFields && Boolean(id);

        return (
          <Grid item xs={12} key={key}>
            <Box pt={index === 0 && !isVisibleTitle ? 0 : 3} width="100%">
              <Grid item container xs={12} spacing={1} wrap="nowrap">
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {id && (
                      <Controller
                        name={`jobPositions.${index}.id`}
                        defaultValue={id}
                        control={control}
                        render={() => <></>}
                      />
                    )}
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        defaultValue={jobTitle}
                        name={`jobPositions.${index}.jobTitle`}
                        render={({ field }) => (
                          <TextField
                            disabled={disableFields}
                            variant="outlined"
                            autoComplete="off"
                            label={<FormattedMessage {...inputLabels.title} />}
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                            fullWidth
                            error={!!errors.jobPositions?.[index]?.jobTitle}
                            helperText={renderIntlMessage(
                              errors.jobPositions?.[index]?.jobTitle?.message,
                            )}
                            value={field.value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name={`jobPositions.${index}.club`}
                        control={control}
                        defaultValue={club}
                        render={({ field }) => (
                          <MultipleSelect
                            disabled={disableFields}
                            fullWidth
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            options={clubs?.toJS()}
                            label={<FormattedMessage {...inputLabels.club} />}
                            error={!!errors.jobPositions?.[index]?.club}
                            helperText={renderIntlMessage(
                              errors.jobPositions?.[index]?.club?.message,
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`jobPositions.${index}.department`}
                        control={control}
                        defaultValue={department}
                        render={({ field }) => (
                          <MultipleSelect
                            disabled={disableFields}
                            fullWidth
                            onBlur={field.onBlur}
                            value={field.value}
                            onChange={field.onChange}
                            options={departments?.toJS()}
                            label={<FormattedMessage {...inputLabels.department} />}
                            error={!!errors.jobPositions?.[index]?.department}
                            helperText={renderIntlMessage(
                              errors.jobPositions?.[index]?.department?.message,
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Controller
                        control={control}
                        name={`jobPositions.${index}.payType`}
                        defaultValue={payType}
                        render={({ field }) => (
                          <Select
                            disabled={disableFields}
                            label={<FormattedMessage {...inputLabels.payType} />}
                            value={field.value}
                            name={field.name}
                            variant="outlined"
                            fullWidth
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                            error={!!errors.jobPositions?.[index]?.payType}
                            helperText={renderIntlMessage(
                              errors.jobPositions?.[index]?.payType?.message,
                            )}
                          >
                            {payTypeLabels.values.map(type => (
                              <MenuItem key={type.key} value={type.value}>
                                {payTypeLabels.translate(type.value)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Controller
                        control={control}
                        name={`jobPositions.${index}.salary`}
                        defaultValue={salary}
                        render={({ field }) => {
                          const positionPayType = watch(`jobPositions.${index}.payType`);
                          const isUnitPayType = positionPayType === EmployeePayTypes.UNIT;

                          return (
                            <NumberTextField
                              label={
                                <FormattedMessage
                                  {...payTypeLabels.find(positionPayType)?.message}
                                />
                              }
                              disabled={disableFields}
                              value={field.value}
                              variant="outlined"
                              fullWidth
                              onChange={field.onChange}
                              onBlur={field.onBlur}
                              numberFormatProps={{
                                maxLength: 10,
                                decimalScale: isUnitPayType ? 0 : 2,
                              }}
                              InputProps={{
                                startAdornment: !isUnitPayType ? (
                                  <InputAdornment position="start">$</InputAdornment>
                                ) : null,
                              }}
                              error={!!errors.jobPositions?.[index]?.salary}
                              helperText={renderIntlMessage(
                                errors.jobPositions?.[index]?.salary?.message,
                              )}
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <Box display="flex">
                        <Controller
                          control={control}
                          name={`jobPositions.${index}.primary`}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="medium"
                                  color="primary"
                                  checked={field.value}
                                  onChange={event => {
                                    field.onChange(event.target.checked);
                                    void trigger('jobPositionsRoot');
                                  }}
                                  onBlur={field.onBlur}
                                  disabled={disableFields}
                                />
                              }
                              label={IntlService.formatMessage(inputLabels.primary)}
                            />
                          )}
                        />
                      </Box>
                    </Grid>

                    <Grid item>
                      <Box display="flex">
                        <Controller
                          control={control}
                          name={`jobPositions.${index}.salesperson`}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="medium"
                                  color="primary"
                                  checked={field.value}
                                  onChange={event => {
                                    field.onChange(event.target.checked);
                                  }}
                                  onBlur={field.onBlur}
                                  disabled={disableFields}
                                />
                              }
                              label={IntlService.formatMessage(inputLabels.salesperson)}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Box pt={1} className={classes.btnRemoveContainer}>
                    <IconButton
                      onClick={() => {
                        remove(index);
                        void trigger('jobPositionsRoot');
                      }}
                    >
                      <DeleteIcon fontSize="small" color="secondary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Button
          type="button"
          variant="text"
          className="btn-text"
          disabled={!!errors.jobPositions}
          onClick={() => {
            const hasPrimaryPosition = some(fields, item => item.primary);

            append({
              club: null,
              salary: '',
              payType: EmployeePayTypes.SALARY,
              department: null,
              primary: !hasPrimaryPosition,
              salesperson: false,
            });

            void trigger('jobPositionsRoot');
          }}
          color="primary"
        >
          <AddIcon />
          <FormattedMessage {...commonMessages.addBtn} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default React.memo(JobSection);
