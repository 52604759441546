import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormProvider, useForm } from 'react-hook-form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import isString from 'lodash/isString';
import { SignWaiversStep } from 'common/components';
import {
  selectAppliedSignWaiver,
  selectIsWaiverHTMLLoading,
  selectSetApplyWaiverActionResult,
  selectSetSignWaiversActionResult,
  selectWaivers,
  selectWaiversIsLoading,
} from 'common/state/newPerson/waiver/selectors';
import {
  applyWaiverThunk,
  getWaiversThunk,
  printWaiver,
  resetWaivers,
  setSignWaiversActionResult,
  signWaiversThunk,
} from 'common/state/newPerson/waiver/actions';
import { selectRightPanelPersonId } from 'modules/front-desk/state/selectors';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { getContactInfoThunk } from 'common/state/newPerson/contactInfo/actions';
import { selectContactInfo } from 'common/state/newPerson/contactInfo/selectors';
import { selectCreatedLead } from 'modules/crm/state/leads/selectors';
import { selectMemberId } from 'common/state/newPerson/primaryInfo/selectors';
import {
  fetchSenderAvailabilityThunk,
  resetBookingEvents,
} from 'modules/booking/state/senderAvailability/actions';
import { closeSigWebTablet, getFieldName } from 'helpers';
import { SignType } from 'common/constants/contract';
import { ActionResult } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { ENotificationType } from 'modules/booking/constants/notificationType';
import commonFileErrorMessages from 'common/messages/fileErrors';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { useAppDispatch } from 'store/hooks';
import { IPersonDocumentToSignDto, IReqContract } from 'common/interfaces/contract';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { ILeadProfileImt } from 'modules/crm/interfaces/leads';
import { IAttachment } from 'common/interfaces/uploadFile';
import { StepContext } from 'common/createContext/stepContext';
import { ServerError } from 'common/errors/serverErrors';
import { useCombinePrintLoading } from 'common/hooks/useCombinePrintLoading';
import { WaiverStatus } from 'modules/crm/constants/leads';
import isFormDirty from 'common/hooks/isFormDirty';
import useDiscardChangesContext from 'common/hooks/useDiscardChangesContext';
import useSignatureContext from 'common/hooks/context/useSignatureContext';
import DiscardChangesModalProvider from 'common/modals/DiscardChangesModal/DiscardChangesModalProvider';

interface SignWaiversStepContainerProps {
  module: PeakModuleForNewPersonType;
}

interface IWaiverStepFormValues {
  [key: string]: string | IAttachment | IAttachment[] | null;
}

const useStyles = makeStyles(() => ({
  signWaiverStepForm: {
    height: 'calc(100% - 180px)',
  },
}));

const SignWaiversStepContainer = ({ module }: SignWaiversStepContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isSignedWaiverData, setIsSignedWaiverData] = useState<{ [key: string]: boolean }>({});
  const { documentSignatures } = useSignatureContext();

  const contactInfo = useRootSelector(selectContactInfo);
  const waivers = useRootSelector(selectWaivers);
  const rightPanelPersonId = useRootSelector(selectRightPanelPersonId);
  const memberProfileId = useRootSelector(selectMemberId);
  const signWaiversActionResult = useRootSelector(selectSetSignWaiversActionResult);
  const applyWaiverActionResult = useRootSelector(selectSetApplyWaiverActionResult);
  const appliedSignWaiver = useRootSelector(selectAppliedSignWaiver);
  const isLoading = useRootSelector(selectWaiversIsLoading);
  const isWaiverHTMLLoading = useRootSelector(selectIsWaiverHTMLLoading);
  const leadPrimaryInfo: ILeadProfileImt = useRootSelector(selectCreatedLead);

  const waiversList = waivers.toJS();

  const isCrmOrPTCrmModule =
    module === PeakModules.Crm || module === PeakModules.PersonalTrainingCrm;

  const isFormInitializedRef = useRef(false);
  const { onNext } = useContext(StepContext);
  const dcContext = useDiscardChangesContext();
  const renderIntlMessage = useRenderIntlMessage();

  const formMethods = useForm<{ [key: string]: any }>({
    defaultValues: {},
    shouldUnregister: false,
    mode: 'onBlur',
  });

  const classes = useStyles();

  const personId = isCrmOrPTCrmModule
    ? leadPrimaryInfo.get('id')
    : rightPanelPersonId || memberProfileId;

  const { reset, handleSubmit, setValue, setError, formState } = formMethods;
  const isDirty = isFormDirty(formState);

  const signValues = Object.values(isSignedWaiverData);
  const noSignValues = waiversList.sort(item => item.status === WaiverStatus.NO_SIGN);

  const isSubmitEnabled =
    (waiversList.length === signValues.length && signValues.every(item => item)) ||
    noSignValues.length === waiversList.length;

  useEffect(() => {
    if (signWaiversActionResult === ActionResult.SUCCESS_ACTION) {
      if (onNext) {
        onNext({ waivers });
      }

      dispatch(setSignWaiversActionResult(null));
    }
  }, [signWaiversActionResult, waivers, onNext, dispatch]);

  useEffect(() => {
    if (applyWaiverActionResult === ActionResult.SUCCESS_ACTION) {
      const convertedWaiver = appliedSignWaiver.toJS();
      if (convertedWaiver.signature) {
        const topazSignatureFieldName = getFieldName('topazSignature', convertedWaiver.documentId);

        setValue(topazSignatureFieldName, convertedWaiver.signature);
      }
    }
  }, [applyWaiverActionResult, appliedSignWaiver, setValue]);

  useEffect(() => {
    dispatch(getWaiversThunk(personId, module));
    dispatch(getContactInfoThunk(personId, module));
  }, [personId, dispatch, module]);

  useEffect(() => {
    return () => {
      closeSigWebTablet({ shouldCallReject: false });
      dispatch(resetWaivers());
    };
  }, [dispatch]);

  const getFieldNames = useCallback((documentId: string) => {
    const signTypeFieldName = getFieldName('signType', documentId);
    const signInPersonFieldName = getFieldName('files', documentId);
    const signViaSmsFieldName = getFieldName('phone', documentId);
    const signViaEmailFieldName = getFieldName('email', documentId);
    const topazSignatureFieldName = getFieldName('topazSignature', documentId);

    return {
      signTypeFieldName,
      signInPersonFieldName,
      signViaSmsFieldName,
      signViaEmailFieldName,
      topazSignatureFieldName,
    };
  }, []);

  useEffect(() => {
    const { email, phones = [] } = contactInfo;

    if (waiversList.length && email && phones[0] && !isFormInitializedRef.current) {
      isFormInitializedRef.current = true;

      const initFormData = waiversList.reduce((acc, { documentId }) => {
        const {
          signTypeFieldName,
          signInPersonFieldName,
          signViaSmsFieldName,
          signViaEmailFieldName,
          topazSignatureFieldName,
        } = getFieldNames(documentId);

        return {
          ...acc,
          [signViaEmailFieldName]: email,
          [signViaSmsFieldName]: phones[0].phoneId,
          [signInPersonFieldName]: [],
          [signTypeFieldName]: SignType.ViaTopaz,
          [topazSignatureFieldName]: {},
        };
      }, {});

      reset(initFormData);
    }
  }, [waiversList, reset, contactInfo, getFieldNames]);

  const isPrinting = useCombinePrintLoading(isWaiverHTMLLoading);

  const onSubmitForm = (values: IWaiverStepFormValues) => {
    const contracts: IPersonDocumentToSignDto[] = waivers.toJS();
    const reqContracts: IReqContract[] = [];

    for (const contract of contracts) {
      const documentId = contract.documentId;

      const {
        signViaEmailFieldName,
        topazSignatureFieldName,
        signTypeFieldName,
        signInPersonFieldName,
        signViaSmsFieldName,
      } = getFieldNames(documentId);

      const email = values[signViaEmailFieldName];
      const phone = values[signViaSmsFieldName];
      const signType = values[signTypeFieldName] as SignType;
      const topazSignature = values[topazSignatureFieldName] as IAttachment | undefined;
      const files = values[signInPersonFieldName] as IAttachment[] | undefined;

      const defaultSignContract: IPersonDocumentToSignDto = {
        attachments: [],
        documentId,
        signType,
        signed: true,
      };

      if (signType === SignType.SendViaEmail) {
        if (isString(email)) {
          reqContracts.push({
            contract: {
              ...defaultSignContract,
              email,
            },
          });
        }
      } else if (signType === SignType.SendViaSMS) {
        if (isString(phone)) {
          reqContracts.push({
            contract: {
              ...defaultSignContract,
              phoneId: phone,
            },
          });
        }
      } else if (signType === SignType.InPerson) {
        if (files?.length) {
          reqContracts.push({
            contract: {
              ...defaultSignContract,
              attachments: files,
            },
          });
        } else {
          setError(signInPersonFieldName, {
            message: renderIntlMessage(commonFileErrorMessages.fileCountError),
          });
        }
      } else if (signType === SignType.ViaTopaz) {
        if (topazSignature?.id) {
          reqContracts.push({
            contract: {
              ...defaultSignContract,
              signature: topazSignature,
            },
          });
        }
      } else if (signType === SignType.ESignature) {
        const docSignature = documentSignatures.find(item => item.documentId === documentId);

        reqContracts.push({
          contract: { ...defaultSignContract },
          documentSignature: docSignature || null,
        });
      } else {
        reqContracts.push({
          contract: { ...defaultSignContract },
        });
      }
    }

    if (isSubmitEnabled) {
      dispatch(signWaiversThunk(personId, reqContracts, module));
    } else {
      dispatch(
        enqueueErrorNotification({
          codes: [ServerError.UNSIGNED_DOCUMENTS_ERROR],
          message: undefined,
        }),
      );
    }
  };

  const applyWaiverSignature = useCallback(
    (file: File, documentId: string) => {
      dispatch(applyWaiverThunk(personId, documentId, file, module));
    },
    [dispatch, personId, module],
  );

  const getEventWaiver = useCallback((): ENotificationType => {
    switch (module) {
      case PeakModules.Crm:
        return ENotificationType.CRM_LEAD_WAIVER_SENDING;
      case PeakModules.PersonalTrainingCrm:
        return ENotificationType.PT_CRM_LEAD_WAIVER_SENDING;
      case PeakModules.FrontDesk:
        return ENotificationType.FRONTDESK_WAIVER_SENDING;
      case PeakModules.Members:
        return ENotificationType.MEMBER_WAIVER_SENDING;
      default:
        return ENotificationType.WAIVER;
    }
  }, [module]);

  useEffect(() => {
    dispatch(
      fetchSenderAvailabilityThunk([SenderAvailabilityTypeList.DOCUMENTS], {
        module,
        events: [getEventWaiver()],
      }),
    );
    return () => {
      dispatch(resetBookingEvents({ type: SenderAvailabilityTypeList.DOCUMENTS }));
    };
  }, [dispatch, getEventWaiver, module]);

  const onPrintWaiver = useCallback(
    (waiverId: string) => {
      dispatch(printWaiver(personId, waiverId, module));
    },
    [dispatch, personId, module],
  );

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          className={classes.signWaiverStepForm}
          id="waiver-form"
          onSubmit={handleSubmit(onSubmitForm)}
          autoComplete="none"
        >
          <SignWaiversStep
            contactInfo={contactInfo}
            fetchApplySignature={applyWaiverSignature}
            isLoading={isPrinting || isLoading}
            waivers={waivers}
            setIsSignedWaiverData={setIsSignedWaiverData}
            onPrintWaiver={onPrintWaiver}
            isSignedWaiverData={isSignedWaiverData}
          />
        </form>
      </FormProvider>

      {dcContext && isDirty ? <DiscardChangesModalProvider {...dcContext} /> : null}
    </>
  );
};

export default SignWaiversStepContainer;
