import * as yup from 'yup';
import {
  EventAttendeeType,
  EventOverbookingType,
  EventRepeatEnd,
  EventRepeatFrequency,
} from 'modules/booking/interfaces';
import inputErrors from 'common/messages/inputErrors';
import {
  dateSchema,
  getDisablePastTimeSchema,
  getFutureDateTimeSchema,
} from 'common/validationSchemas/dateSchemas';
import { getRequiredErrorMessage } from 'common/utils/validation';
import { Weekday } from 'common/interfaces/common';
import { nullableStringNumberSchema } from 'common/validationSchemas/stringNumberSchema';

export const BookingEventValidationSchema = (isClubPermission: boolean): yup.AnyObjectSchema =>
  yup.object().shape({
    title: yup
      .string()
      .trim()
      .max(32, () => inputErrors.eventNameLengthError)
      .required(getRequiredErrorMessage),
    color: yup.string().required(getRequiredErrorMessage),
    description: yup
      .string()
      .nullable()
      .max(1000, () => inputErrors.descriptionLengthError),
    date: getFutureDateTimeSchema('time'),
    time: getDisablePastTimeSchema('date'),
    durationInMinutes: yup.string().required(getRequiredErrorMessage),
    changeDuration: yup.boolean(),

    // recurring
    repeated: yup.boolean().required(getRequiredErrorMessage),
    repeatingFrequency: yup
      .string()
      .nullable()
      .when('repeated', {
        is: true,
        then: yup
          .string()
          .oneOf(Object.values(EventRepeatFrequency))
          .required(getRequiredErrorMessage),
      }),
    repeatingWeekdays: yup
      .array()
      .nullable()
      .when(['repeated', 'repeatingFrequency'], {
        is: (repeated: boolean, repeatingFrequency: EventRepeatFrequency): boolean =>
          repeated && repeatingFrequency === EventRepeatFrequency.WEEKLY,
        then: yup
          .array()
          .of(yup.string().oneOf(Object.values(Weekday)))
          .min(1, getRequiredErrorMessage),
      }),
    repeatingDurationType: yup
      .string()
      .nullable()
      .when('repeated', {
        is: true,
        then: yup
          .string()
          .oneOf(Object.values(EventRepeatEnd))
          .required(getRequiredErrorMessage),
      }),
    repeatingTimesNumber: nullableStringNumberSchema.when(['repeated', 'repeatingDurationType'], {
      is: (repeated, repeatingDurationType) =>
        repeated && repeatingDurationType === EventRepeatEnd.N_TIMES,
      then: nullableStringNumberSchema.min(1, () => inputErrors.countMinLengthError),
    }),
    repeatingEndDate: yup
      .string()
      .nullable()
      .when(['repeated', 'repeatingDurationType'], {
        is: (repeated, repeatingDurationType) =>
          repeated && repeatingDurationType === EventRepeatEnd.ON_DATE,
        then: dateSchema.required(getRequiredErrorMessage),
      }),

    club: yup
      .mixed()
      .when({
        is: () => !isClubPermission,
        then: yup
          .mixed()
          .notOneOf(['', null], getRequiredErrorMessage)
          .required(getRequiredErrorMessage),
      })
      .required(getRequiredErrorMessage),
    service: yup
      .object()
      .nullable()
      .shape({
        id: yup.string(),
      }),
    resources: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
        }),
      )
      .test(
        'isActiveResources',
        () => inputErrors.inactiveResourceError,
        value => {
          if (value && value.length) {
            return value.every(resource => {
              return !Object.prototype.hasOwnProperty.call(resource, 'active') || resource.active;
            });
          }

          return true;
        },
      ),
    tags: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
        }),
      )
      .test(
        'isActiveEventTags',
        () => inputErrors.inactiveEventTagError,
        value => {
          if (value && value.length) {
            return value.every(eventTag => {
              return !Object.prototype.hasOwnProperty.call(eventTag, 'active') || eventTag.active;
            });
          }

          return true;
        },
      ),

    // participants
    allowSelfBooking: yup.boolean(),
    spotLimited: yup.boolean(),
    spotAmount: nullableStringNumberSchema.when('spotLimited', {
      is: true,
      then: nullableStringNumberSchema
        .min(1, () => inputErrors.spotsMinLengthError)
        .required(getRequiredErrorMessage),
    }),
    allowedParticipantTypes: yup
      .array()
      .of(yup.string().oneOf(Object.values(EventAttendeeType)))
      .required(getRequiredErrorMessage),
    // TODO: add overbooking validation when integrated
    overbookingType: yup
      .string()
      .nullable()
      .oneOf(Object.values(EventOverbookingType)),
    persons: yup.array().when('overbookingType', {
      is: EventOverbookingType.DO_NOT_ALLOW_OVERBOOKING,
      then: yup.array().when('spotLimited', {
        is: true,
        then: yup
          .array()
          .max(yup.ref('spotAmount'), () => inputErrors.participantsNumberMatchError),
      }),
    }),

    // action restrictions
    preventJoiningAfterDays: yup.boolean().required(getRequiredErrorMessage),
    joinRestrictionBeforeDays: nullableStringNumberSchema.when('preventJoiningAfterDays', {
      is: true,
      then: nullableStringNumberSchema
        .min(1, () => inputErrors.greaterThanZero)
        .required(getRequiredErrorMessage),
    }),
    preventCancelingAfterDays: yup.boolean().required(getRequiredErrorMessage),
    cancelRestrictionBeforeDays: nullableStringNumberSchema.when('preventCancelingAfterDays', {
      is: true,
      then: nullableStringNumberSchema
        .min(1, () => inputErrors.greaterThanZero)
        .required(getRequiredErrorMessage),
    }),
    preventEditingAfterDays: yup.boolean().required(getRequiredErrorMessage),
    editRestrictionBeforeDays: nullableStringNumberSchema.when('preventEditingAfterDays', {
      is: true,
      then: nullableStringNumberSchema
        .min(1, () => inputErrors.greaterThanZero)
        .required(getRequiredErrorMessage),
    }),

    reminder: yup.boolean().nullable(),
    reminderPeriodType: yup.string().nullable(),
    reminderPeriodNumber: nullableStringNumberSchema,

    notifyViaEmail: yup.boolean().defined(),
    notifyViaSms: yup.boolean().defined(),
    notifyViaNotification: yup.boolean().defined(),
  });
