import * as yup from 'yup';
import { getRequiredErrorMessage } from 'common/utils/validation';
import messages from 'common/messages/validation';

export const FamilyMembersStepValidationSchema = yup.object().shape({
  familyMembers: yup.array().of(
    yup.object().shape({
      leftRightRelation: yup
        .string()
        .nullable()
        .max(100, () => messages.familyMemberRelationMexLength)
        .required(getRequiredErrorMessage),
      rightLeftRelation: yup.string().max(100, () => messages.familyMemberRelationMexLength),
    }),
  ),
});
