import React from 'react';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import cx from 'classnames';
import {
  Box,
  Card,
  Grid,
  IconButton,
  makeStyles,
  SvgIcon,
  Theme,
  Typography,
} from '@material-ui/core';

import { PeakModules } from 'common/constants/peakModules';

import { IMemberAutocompleteSearchImt } from 'common/components/PersonProfile/interfaces';
import { ICheckInManuallySearchImt } from 'modules/kid-zone/interfaces';
import { IPersonSearchBarValues } from 'common/components/ManualCheckInPanel/components/PersonSearchBar/interfaces';

import { LoadingBackdrop, ScrollBox } from 'common/components/index';
import { ReactComponent as CloseIcon } from 'img/closeIcon.svg';
import { PersonSearchBar } from 'common/components/ManualCheckInPanel/index';

import messages from 'modules/front-desk/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  grid: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    boxShadow: '0px 1px 12px 0px rgba(0, 0, 0, 0.16)',
  },
  iconButton: {
    padding: '5px',
    width: '20px',
    height: '20px',
    fontWeight: 'bold',
    opacity: 0.5,
    margin: '-5px -5px 0 0',
    '& svg': {
      fontSize: '0.875rem',
    },
  },
  listWrapper: {
    margin: theme.spacing(0),
    flex: 1,
    height: '100%',
    minHeight: 0,
    overflow: 'hidden',
    position: ({ isMembersLoading }: IProps) => (isMembersLoading ? 'relative' : 'static'),
    '& .MuiList-root': {
      padding: 0,
    },
  },
  list: {
    height: 'calc(100% - 17px)',
    paddingLeft: theme.spacing(2),
  },
  createButton: {
    textTransform: 'uppercase',
    color: theme.palette.info.contrastText,
    padding: theme.spacing(1, 2),
  },
  noMembers: {
    opacity: 0.5,
    textAlign: 'center',
    margin: theme.spacing(3, 'auto'),
  },
}));

interface IProps {
  members: ImmutableList<IMemberAutocompleteSearchImt | ICheckInManuallySearchImt>;
  isMembersLoading: boolean;
  needToRefetch?: boolean;
  isCheckInActionLoading?: boolean;
  isSearchTyped: boolean;
  onClose: () => void;
  onSearchFieldChange: (search: IPersonSearchBarValues) => void;
  setIsSearchTyped: (isFilled: boolean) => void;
  renderMemberItem: (data: Partial<ListChildComponentProps>) => JSX.Element | null;
  module: PeakModules;
}

const ManualCheckInPanel = (props: IProps): JSX.Element => {
  const {
    onClose,
    members,
    isMembersLoading,
    isCheckInActionLoading,
    isSearchTyped,
    onSearchFieldChange,
    setIsSearchTyped,
    renderMemberItem,
    module,
    needToRefetch = false,
  } = props;

  const classes = useStyles(props);

  return (
    <Card classes={{ root: classes.root }}>
      <ScrollBox>
        <Grid className={classes.grid}>
          <Box className={classes.header}>
            <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
              <Typography variant="h3" component="h3">
                <FormattedMessage {...messages.checkInManuallyText} />
              </Typography>

              <IconButton aria-label="close" className={classes.iconButton} onClick={onClose}>
                <SvgIcon>
                  <CloseIcon />
                </SvgIcon>
              </IconButton>
            </Box>

            <PersonSearchBar
              onSearchFieldChange={onSearchFieldChange}
              setIsSearchTyped={setIsSearchTyped}
              needToRefetch={needToRefetch}
            />
          </Box>

          <Box className={classes.listWrapper}>
            <LoadingBackdrop isLoading={isMembersLoading || isCheckInActionLoading} />

            <ScrollBox>
              <Box className={cx({ [classes.list]: Boolean(members.size) })}>
                {members.size ? (
                  <>
                    {/* // TODO recheck conditions */}
                    {module === PeakModules.FrontDesk && (
                      <AutoSizer disableWidth>
                        {({ width, height }: Size) => (
                          <List
                            height={height}
                            width={width}
                            overscanCount={5}
                            itemCount={members.size}
                            itemSize={81}
                          >
                            {({ index, style }) => renderMemberItem({ index, style })}
                          </List>
                        )}
                      </AutoSizer>
                    )}

                    {module === PeakModules.KidZone &&
                      members.map((_, index) => renderMemberItem({ index }))}
                  </>
                ) : (
                  <Box className={classes.noMembers}>
                    <Typography align="center" component="p">
                      {!isMembersLoading && (
                        <FormattedMessage
                          {...(isSearchTyped
                            ? messages.membersNotFoundText
                            : messages.typeInCredentialsText)}
                        />
                      )}
                    </Typography>
                  </Box>
                )}
              </Box>
            </ScrollBox>
          </Box>
        </Grid>
      </ScrollBox>
    </Card>
  );
};

export default React.memo(ManualCheckInPanel);
