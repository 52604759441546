import React, { memo, useEffect, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Typography, TextField } from '@material-ui/core';
import {
  fetchRecentPersonAppointments,
  fetchServicesForEventRedeem,
  resetPersonServicesForEventRedeemAction,
} from 'common/components/PersonProfile/state/appointments/actions';
import { useAppDispatch } from 'store/hooks';
import { PeakModules } from 'common/constants/peakModules';
import { DialogComponent, LoadingBackdrop } from 'common/components';
import modalTitles from 'common/messages/modalTitles';
import commonMessages from 'common/messages/messages';
import personProfileMessages from '../../../messages';
import * as selectors from 'common/components/PersonProfile/state/appointments/selectors';
import { IServiceItemDetailsImt } from 'common/interfaces/service';
import { ActionResult } from 'common/constants/globalConstants';
import { Autocomplete } from '@material-ui/lab';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import RedeemModal from '../../Services/modals/RedeemModal/RedeemModal';
import * as actions from 'common/components/PersonProfile/state/servicesModals/actions';
import { selectServiceRedeemActionResult } from 'common/components/PersonProfile/state/servicesModals/selectors';
import { getRequiredErrorMessage } from 'common/utils/validation';

// TODO - PRM-1810 tmp form interface
interface IFormValues {
  service: Record<string, any> | null;
}

const ValidationSchema = yup.object().shape({
  service: yup
    .object()
    .shape({
      serviceId: yup.string(),
    })
    .nullable()
    .required(getRequiredErrorMessage),
});

interface IProps {
  isOpen: boolean;
  isUpdateEventLoading: boolean;
  onClose: () => void;
  personId: number;
  appointmentId: string;
  appointmentStartDate: string;
  serviceId: string;
  module?: PeakModules;
}

const RedeemEventModal = ({
  isOpen,
  isUpdateEventLoading,
  onClose,
  personId,
  appointmentId,
  appointmentStartDate,
  serviceId,
  module,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const servicesForEventRedeem: ImmutableList<IServiceItemDetailsImt> = useRootSelector(
    selectors.selectServicesForEventRedeem(personId),
  );
  const servicesForEventRedeemLoading: boolean = useRootSelector(
    selectors.selectServicesForEventRedeemLoading(personId),
  );
  const redeemActionResult: ActionResult = useRootSelector(selectServiceRedeemActionResult);

  const [isScheduledRedeemModalOpen, setIsScheduledRedeemModalOpen] = useState(false);

  const formMethods = useForm<IFormValues>({
    defaultValues: {
      service: null,
    },
    resolver: yupResolver(ValidationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onChange',
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const renderIntlMessage = useRenderIntlMessage();

  useEffect(() => {
    void dispatch(fetchServicesForEventRedeem(personId, appointmentId, serviceId, module));
  }, [dispatch, appointmentId, personId, serviceId, module]);

  useEffect(() => {
    if (redeemActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(fetchRecentPersonAppointments(personId, module));
      onClose();
    }
  }, [dispatch, redeemActionResult, personId, module, onClose]);

  useEffect(() => {
    return () => {
      dispatch(resetPersonServicesForEventRedeemAction(null, personId));
    };
  }, [dispatch, personId]);

  const handleSubmitForm = values => {
    setIsScheduledRedeemModalOpen(true);
    dispatch(actions.selectServiceItemId(values.service.packageServiceInstanceId));
  };

  const formId = 'redeem-appointment';

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...modalTitles.redeemAppointmentTitle} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.redeemBtn} />}
      isOpen={isOpen}
      onClose={onClose}
      disableFullScreenBehavior
      formId={formId}
      disabled={!servicesForEventRedeem?.size}
      loading={isUpdateEventLoading}
    >
      <FormProvider {...formMethods}>
        <form id={formId} onSubmit={handleSubmit(handleSubmitForm)}>
          {!!servicesForEventRedeem?.size && (
            <Controller
              name="service"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  value={field.value}
                  onBlur={field.onBlur}
                  options={servicesForEventRedeem?.toJS()}
                  getOptionLabel={option => option.title || ''}
                  onChange={(e: React.BaseSyntheticEvent, val) => {
                    field.onChange(val);
                  }}
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        label={<FormattedMessage {...commonMessages.serviceLabel} />}
                        variant="outlined"
                        fullWidth
                        error={!!errors.service}
                        helperText={renderIntlMessage(errors.service?.message)}
                      />
                    );
                  }}
                />
              )}
            />
          )}

          {!servicesForEventRedeem?.size && !servicesForEventRedeemLoading && (
            <Typography color="textSecondary" align="center">
              <FormattedMessage {...personProfileMessages.noServicesMessage} />
            </Typography>
          )}

          <LoadingBackdrop isLoading={servicesForEventRedeemLoading} />
        </form>
      </FormProvider>

      {isScheduledRedeemModalOpen && (
        <RedeemModal
          profileId={personId}
          module={module}
          isOpen
          onClose={() => setIsScheduledRedeemModalOpen(false)}
          appointmentId={appointmentId}
          appointmentStartDate={appointmentStartDate}
        />
      )}
    </DialogComponent>
  );
};

export default memo(RedeemEventModal);
