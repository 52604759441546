export const formatPrice = (amount: number | string, fractionDigits = 2): string => {
  const defaultValue = 0;
  return amount
    ? `${amount < 0 ? '-' : ''}$${Math.abs(Number(amount)).toFixed(fractionDigits)}`
    : `$${defaultValue.toFixed(fractionDigits)}`;
};

export const formatNumberToPrice = (price: string | number) => {
  if (price === undefined) return '-';
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(price));
};

export const formatFileSize = (size: number) => {
  const formatToMb = size / 1048576;
  let formatToKb: number | null = null;

  if (formatToMb < 1) {
    formatToKb = formatToMb * 1024;
  }
  return formatToKb ? `${Math.trunc(formatToKb)} KB` : `${formatToMb.toFixed(2)} MB`;
};

export const formatBase64ToFile = (base64: string, filename: string) => {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n) {
    n -= 1;
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const formatPersonalCode = (personalCode: number): string => {
  return personalCode?.toString()?.padStart(6, '0') || '-';
};
