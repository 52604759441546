import { ImmutableObject } from 'common/state/interfaces';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';

export enum IntegrateServices {
  SENDGRID = 'SENDGRID',
  TWILIO = 'TWILIO',
  PAYEEZY = 'PAYEEZY',
  ZENDESK_API = 'ZENDESK_API',
  ZENDESK_WIDGET = 'ZENDESK_WIDGET',
  ITRANSACT = 'ITRANSACT',
  COMMERCEHUB = 'COMMERCEHUB',
}

export enum BankCode {
  COMMERCEHUB = 'COMMERCEHUB',
  ITRANSACT = 'ITRANSACT',
  ZIONS = 'ZIONS',
}

export interface IMessagingSettings {
  // Sendgrid
  email: string;
  emailEnabled: boolean;
  emailSenderName: string;
  sendgridApiKey: string;

  // Twilio
  smsEnabled: boolean;
  phoneNumber: string;
  sid: string;
  token: string;

  bankCode: BankCode;

  // Payeezy
  payeezyEnabled: boolean;
  payeezyUrl: string;
  payeezyApiKey: string;
  payeezyApiSecret: string;
  payeezyMerchantId: string;
  payeezyTransarmorToken: string;
  payeezyPzSecret: string;
  payeezyReportToken: string;
  payeezyUsePeakSettings: boolean;

  // iTransact
  iTransactUsePeakSettings: boolean;
  iTransactApiKey: string;
  iTransactApiUsername: string;

  // Campaign Settings
  automaticCampaignEnabled: boolean;
  campaignEnabled: boolean;
  manualCampaignEnabled: boolean;
  scheduledCampaignEnabled: boolean;
  triggeredCampaignEnabled: boolean;

  // Commercehub
  commerceHubUsePeakSettings: boolean;
  commerceHubApiKey: string | null;
  commerceHubApiSecret: string | null;
  commerceHubMerchantId: string | null;
  commerceHubTerminalId: string | null;
}

export interface IMessagingSettingsImt extends ImmutableObject<IMessagingSettings> {
  get<K extends keyof IMessagingSettings>(key: K): IMessagingSettings[K];
}

export interface IRequiredFieldInfo {
  id?: string;
  fieldType: RequiredProfileInfo;
  necessity: FieldNecessity;
}
export type TShortProfileFieldInfo = {
  [key in RequiredProfileInfo]: FieldNecessity;
};

export type TShortProfileFieldInfoImt = ImmutableObject<TShortProfileFieldInfo>;

export interface IRequiredFieldUpdateDto {
  fieldId: string;
  fieldNecessity: FieldNecessity;
}

export type IRequiredFieldInfoImt = ImmutableObject<IRequiredFieldInfo>;
