import * as yup from 'yup';
import inputErrors from 'common/messages/inputErrors';
import { regExp } from 'common/constants';
import { phoneSchema as basePhoneSchema } from 'common/validationSchemas/phoneSchema';
import { getRequiredErrorMessage } from 'common/utils/validation';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';

export function getEmergencyContactValidationSchema(requiredFields: TShortProfileFieldInfoImt) {
  const profileRequiredField = requiredFields.get(RequiredProfileInfo.EmergencyContactInfo);
  const required = profileRequiredField === FieldNecessity.Required;

  let nameSchema = yup
    .string()
    .matches(regExp.NAME, () => inputErrors.emergencyNameMatchesError)
    .max(50, () => inputErrors.emergencyNameLengthError)
    .defined();

  let phoneSchema = basePhoneSchema.defined();
  let relationshipSchema = yup.string().defined();

  if (required) {
    nameSchema = nameSchema.required(getRequiredErrorMessage);
    phoneSchema = phoneSchema.required(getRequiredErrorMessage);
    relationshipSchema = relationshipSchema.required(getRequiredErrorMessage);
  }

  return yup.object({
    emergencyName: nameSchema,
    emergencyPhone: phoneSchema,
    emergencyRelationship: relationshipSchema,
  });
}
