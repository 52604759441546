import React, { useCallback, useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  selectScript,
  selectScriptLoading,
  selectScriptUpdating,
  selectUpdatedScript,
} from 'modules/crm/state/scripts/selectors';
import { selectQueryPageList } from 'common/state/queryPage-lists/selectors';
import { QueryPageList } from 'common/constants';
import { IScriptDetailed, IScriptDetailedImt } from 'modules/crm/interfaces/scripts';
import { RouteBackground } from 'components';
import { LoadingBackdrop } from 'common/components';
import { ScriptForm } from 'modules/crm/components';
import { useAppDispatch } from 'store/hooks';
import * as scriptActions from '../../state/scripts/actions';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages/scripts';
import { PeakModules } from 'common/constants/peakModules';

const EditScript = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const script: IScriptDetailedImt = useRootSelector(selectScript());
  const isScriptLoading: boolean = useRootSelector(selectScriptLoading());
  const isScriptUpdating: boolean = useRootSelector(selectScriptUpdating());
  const updatedScript: IScriptDetailedImt | null = useRootSelector(selectUpdatedScript());
  const queryScripts: string = useRootSelector(selectQueryPageList(QueryPageList.SCRIPTS));

  const { id: scriptId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const module =
    location.pathname.indexOf('personal-training-crm') === -1
      ? PeakModules.Crm
      : PeakModules.PersonalTrainingCrm;

  useEffect(() => {
    if (scriptId) {
      dispatch(scriptActions.fetchScriptById(scriptId, module));
    }
  }, [dispatch, module, scriptId]);

  useEffect(() => {
    if (updatedScript !== null) {
      navigate(`/${location.pathname.split('/')[1]}/scripts${queryScripts}`);
    }
  }, [location.pathname, navigate, queryScripts, updatedScript]);

  useEffect(() => {
    return () => {
      dispatch(scriptActions.resetScriptForm());
    };
  }, [dispatch]);

  const handleSubmitForm = useCallback(
    (formValues: IScriptDetailed): void => {
      if (scriptId) {
        dispatch(scriptActions.updateScript(scriptId, formValues, module));
      }
    },
    [dispatch, module, scriptId],
  );

  return (
    <RouteBackground>
      {script && !!script.size && (
        <ScriptForm
          title={<FormattedMessage {...messages.editScript} />}
          isLoading={isScriptUpdating}
          script={script}
          onSubmit={handleSubmitForm}
        />
      )}
      <LoadingBackdrop isLoading={isScriptLoading} />
    </RouteBackground>
  );
};

export default EditScript;
