import React from 'react';
import { FormattedMessage } from 'react-intl';

import commonMessages from 'common/messages/messages';
import { PaymentsType } from 'common/interfaces/invoices';

export const actionTypes = {
  // Payment methods
  FETCH_PAYMENT_METHODS_POS_LOADING: 'FETCH_PAYMENT_METHODS_POS_LOADING',
  FETCH_PAYMENT_METHODS_POS: 'FETCH_PAYMENT_METHODS_POS',
  RESET_PAYMENT_METHODS_POS: 'RESET_PAYMENT_METHODS_POS',
  // Edit/Create
  EDIT_PAYMENT_METHOD_POS_LOADING: 'EDIT_PAYMENT_METHOD_POS_LOADING',
  EDIT_PAYMENT_METHOD_POS: 'EDIT_PAYMENT_METHOD_POS',
  RESET_EDIT_PAYMENT_METHOD_POS_RESULT: 'RESET_EDIT_PAYMENT_METHOD_POS_RESULT',
  CREATE_PAYMENT_METHOD_POS_LOADING: 'CREATE_PAYMENT_METHOD_POS_LOADING',
  CREATE_PAYMENT_METHOD_POS: 'CREATE_PAYMENT_METHOD_POS',
  RESET_CREATE_PAYMENT_METHOD_POS_RESULT: 'RESET_CREATE_PAYMENT_METHOD_POS_RESULT',
  // Payment methods custom
  FETCH_PAYMENT_METHODS_CUSTOM_TITLE_POS: 'FETCH_PAYMENT_METHODS_CUSTOM_TITLE_POS',
  FETCH_PAYMENT_METHODS_CUSTOM_POS_LOADING: 'FETCH_PAYMENT_METHODS_CUSTOM_POS_LOADING',
  FETCH_PAYMENT_METHODS_CUSTOM_POS: 'FETCH_PAYMENT_METHODS_CUSTOM_POS',
  // Payment method custom list
  FETCH_PAYMENT_METHOD_CUSTOM_POS_LOADING: 'FETCH_PAYMENT_METHOD_CUSTOM_POS_LOADING',
  FETCH_PAYMENT_METHOD_CUSTOM_POS: 'FETCH_PAYMENT_METHOD_CUSTOM_POS',
  FETCH_BLOCK_PAYMENT_METHOD_CUSTOM_ITEM_POS_LOADING:
    'FETCH_BLOCK_PAYMENT_METHOD_CUSTOM_ITEM_POS_LOADING',
  FETCH_BLOCK_PAYMENT_METHOD_CUSTOM_ITEM_POS: 'FETCH_BLOCK_PAYMENT_METHOD_CUSTOM_ITEM_POS',
  RESET_BLOCK_PAYMENT_METHOD_CUSTOM_ITEM_POS_SUCCESS:
    'RESET_BLOCK_PAYMENT_METHOD_CUSTOM_ITEM_POS_SUCCESS',
  FETCH_PAYMENT_METHOD_CUSTOM_ITEM_MULTI_EDIT_LOADING:
    'FETCH_PAYMENT_METHOD_CUSTOM_ITEM_MULTI_EDIT_LOADING',
  FETCH_PAYMENT_METHOD_CUSTOM_ITEM_MULTI_EDIT: 'FETCH_PAYMENT_METHOD_CUSTOM_ITEM_MULTI_EDIT',
  RESET_PAYMENT_METHOD_CUSTOM_ITEM_MULTI_EDIT: 'RESET_PAYMENT_METHOD_CUSTOM_ITEM_MULTI_EDIT',
  // Payment Method Custom item
  FETCH_PAYMENT_METHOD_CUSTOM_ITEM_POS_LOADING: 'FETCH_PAYMENT_METHOD_CUSTOM_ITEM_POS_LOADING',
  FETCH_PAYMENT_METHOD_CUSTOM_ITEM_POS_PAGINATE: 'FETCH_PAYMENT_METHOD_CUSTOM_ITEM_POS_PAGINATE',
  CREATE_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS: 'CREATE_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS',
  UPDATE_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS: 'UPDATE_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS',
  DELETE_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS: 'DELETE_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS',
  RESET_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS_ACTION:
    'RESET_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS_ACTION',
  CREATE_PAYMENT_METHOD_CUSTOM_ITEM_POS: 'CREATE_PAYMENT_METHOD_CUSTOM_ITEM_POS',
  RESET_PAYMENT_METHOD_CUSTOM_ITEM_POS: 'RESET_PAYMENT_METHOD_CUSTOM_ITEM_POS',
  FETCH_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS_MULTI_EDIT:
    'FETCH_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS_MULTI_EDIT',
  RESET_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS_MULTI_EDIT:
    'RESET_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS_MULTI_EDIT',
  // Payment Method Custom Item Clubs Configuration
  FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_POS_LOADING:
    'FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_POS_LOADING',
  FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_POS:
    'FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_POS',
  FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_POS_LOADING:
    'FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_POS_LOADING',
  FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_POS:
    'FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_POS',
  FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_ACTION_LOADING:
    'FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_ACTION_LOADING',
  CREATE_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS:
    'CREATE_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS',
  DELETE_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS:
    'DELETE_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS',
  RESET_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_RESULT:
    'RESET_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_RESULT',
  FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_MULTI_EDIT_LOADING:
    'FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_MULTI_EDIT_LOADING',
  FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_MULTI_EDIT:
    'FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_MULTI_EDIT',
  RESET_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_MULTI_EDIT:
    'RESET_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_MULTI_EDIT',
  RESET_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_POS:
    'RESET_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_POS',
};

export const PaymentsTypeLabels = {
  [PaymentsType.CASH]: <FormattedMessage {...commonMessages.cashLabel} />,
  [PaymentsType.MONEY_ORDER]: <FormattedMessage {...commonMessages.moneyOrderLabel} />,
  [PaymentsType.ON_ACCOUNT]: <FormattedMessage {...commonMessages.onAccountLabel} />,
  [PaymentsType.TAB_ON_A_CORPORATE_ACCOUNT]: (
    <FormattedMessage {...commonMessages.tabOnCorporateAccountLabel} />
  ),
  [PaymentsType.CHECKING_SAVINGS]: <FormattedMessage {...commonMessages.checkingSavingsLabel} />,
  [PaymentsType.MEMBER_REWARDS]: <FormattedMessage {...commonMessages.clientRewardsLabel} />,
  [PaymentsType.CUSTOM]: <FormattedMessage {...commonMessages.giftCardLabel} />,
  [PaymentsType.CREDIT_CARD]: <FormattedMessage {...commonMessages.creditCardLabel} />,
};

export enum StatusPaymentMethodsCustomItemType {
  New = 'NEW',
  Blocked = 'BLOCKED',
  Used = 'USED',
}

export const StatusPaymentMethodsCustomItemTypeLabels = {
  [StatusPaymentMethodsCustomItemType.New]: <FormattedMessage {...commonMessages.newBtn} />,
  [StatusPaymentMethodsCustomItemType.Blocked]: (
    <FormattedMessage {...commonMessages.blockedTitle} />
  ),
  [StatusPaymentMethodsCustomItemType.Used]: <FormattedMessage {...commonMessages.usedTitle} />,
};
