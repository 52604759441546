import React, { useEffect, useMemo, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { Box, Grid, makeStyles, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';

import { Select } from 'common/components';
import { ReactComponent as InfoIcon } from 'img/icons/info.svg';

import { SignType } from 'common/constants/contract';
import { QuestionnaireModal } from 'common/modals';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';

import messages from 'common/components/Steps/SignDocumentStep/messages';
import commonMessages from 'common/messages/messages';

import { ISenderAvailability, TSenderAvailabilityImt } from 'modules/booking/interfaces';

import { colors } from 'common/ui/theme/default';
import { selectSenderAvailability } from 'modules/booking/state/senderAvailability/selectors';
import { ReactComponent as SignatureTopaz } from 'img/icons/signature-topaz.svg';
import { ReactComponent as SignatureOnline } from 'img/icons/signature.svg';
import { ReactComponent as SignatureInPerson } from 'img/icons/signature-in-person.svg';
import { ReactComponent as SignatureEmail } from 'img/icons/signature-email.svg';
import { ReactComponent as SignatureSms } from 'img/icons/signature-sms.svg';

interface ISigningTypeSelectorProps {
  fieldName: string;
}

const useStyles = makeStyles({
  infoIconWrapper: {
    lineHeight: 0,
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& svg': {
      width: '16px',
      height: '16px',
      color: colors.buttons.iconButton,
    },
  },
  select: {
    '& .MuiSelect-selectMenu': {
      '& svg': {
        width: '16px',
        height: '16px',
        color: colors.buttons.iconButton,
        marginRight: '8px',
        marginBottom: '-3px',
      },
    },
  },
});

const SigningTypeSelector = (props: ISigningTypeSelectorProps): JSX.Element => {
  const { fieldName } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOk, setIsOk] = useState(false);
  const [signType, setSignType] = useState<SignType>();
  const senderAvailability: TSenderAvailabilityImt = useRootSelector(
    selectSenderAvailability(SenderAvailabilityTypeList.DOCUMENTS),
  );

  const { control, setValue, formState, clearErrors } = useFormContext();
  const { errors } = formState;
  const classes = useStyles();

  const signingTypeOptions = useMemo(() => {
    return [
      {
        value: SignType.ViaTopaz,
        messageDescriptor: messages.signViaTopaz,
        icon: <SignatureTopaz />,
      },
      {
        value: SignType.ESignature,
        messageDescriptor: messages.signOnline,
        icon: <SignatureOnline />,
      },
      {
        value: SignType.InPerson,
        messageDescriptor: messages.signInPerson,
        icon: <SignatureInPerson />,
      },
      {
        value: SignType.SendViaEmail,
        messageDescriptor: messages.signViaEmail,
        icon: <SignatureEmail />,
        name: 'emailAvailable',
      },
      {
        value: SignType.SendViaSMS,
        messageDescriptor: messages.signViaSMS,
        icon: <SignatureSms />,
        name: 'smsAvailable',
      },
    ];
  }, []);

  useEffect(() => {
    if (isOk && signType) {
      setValue(fieldName, signType);
      setIsOpenModal(false);
      setIsOk(false);
    }
  }, [isOk, signType, setIsOpenModal, setIsOk, setValue, fieldName]);

  const transformedSenderAvailability = useMemo<Partial<ISenderAvailability>>(
    () => senderAvailability?.toJS() || {},
    [senderAvailability],
  );

  const onClose = () => {
    setIsOpenModal(false);
  };

  const onSubmit = () => {
    if (Object.keys(errors).length) {
      clearErrors();
    }
    setIsOk(true);
  };

  return (
    <Grid container alignItems="center" item xs={6}>
      <Controller
        name={fieldName}
        defaultValue={SignType.ViaTopaz}
        control={control}
        render={({ field }) => (
          <Select
            onChange={(val: SignType) => {
              if (val) {
                setIsOpenModal(true);
                setSignType(val);
              }
            }}
            className={classes.select}
            value={field.value}
            fullWidth
            variant="outlined"
            size="small"
          >
            {signingTypeOptions.map(({ value: item, messageDescriptor, name, icon }) => {
              let tooltipMessage: string | null = null;

              const menuItem = (
                <MenuItem
                  key={item}
                  value={item}
                  className={classes.menuItem}
                  disabled={name ? !transformedSenderAvailability[name] : false}
                >
                  {icon}
                  <FormattedMessage {...messageDescriptor} />
                </MenuItem>
              );

              if (name === 'emailAvailable' && transformedSenderAvailability.emailMessage) {
                tooltipMessage = transformedSenderAvailability.emailMessage;
              }

              if (name === 'smsAvailable' && transformedSenderAvailability.smsMessage) {
                tooltipMessage = transformedSenderAvailability.smsMessage;
              }

              return tooltipMessage ? (
                <Box display="flex" alignItems="center" justifyContent="space-between" pr={2}>
                  {menuItem}
                  <Tooltip title={tooltipMessage}>
                    <Typography className={classes.infoIconWrapper} color="textSecondary">
                      <InfoIcon width={16} height={16} />
                    </Typography>
                  </Tooltip>
                </Box>
              ) : (
                menuItem
              );
            })}
          </Select>
        )}
      />
      {isOpenModal && (
        <QuestionnaireModal
          onClose={onClose}
          onSubmit={onSubmit}
          submitBtnTitle={<FormattedMessage {...commonMessages.okBtn} />}
          title={<FormattedMessage {...messages.changeTypeQuestionnaireModalTitle} />}
          isOpen={isOpenModal}
          body={<FormattedMessage {...messages.changeTypeQuestion} />}
        />
      )}
    </Grid>
  );
};

export default SigningTypeSelector;
