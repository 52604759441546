import { createAction } from '@reduxjs/toolkit';

import { IContactInfo } from 'common/interfaces/contract';
import { actionTypes } from 'common/state/newPerson/contactInfo/constants';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { PeakModules } from 'common/constants/peakModules';

import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { GeneralThunkAction } from 'common/state/interfaces';

export const getContactInfoAction = createAction<IContactInfo | null>(actionTypes.GET_CONTACT_INFO);
export const clearContactInfoAction = createAction<boolean>(actionTypes.CLEAR_CONTACT_INFO);
export const setContactInfoIsLoadingAction = createAction<boolean>(
  actionTypes.SET_CONTACT_INFO_IS_LOADING,
);
export const setContactInfoErrorAction = createAction<boolean>(actionTypes.SET_CONTACT_INFO_ERROR);

export const getContactInfoThunk = (
  personId: number,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(setContactInfoIsLoadingAction(true));

    let contactInfoData: IContactInfo | null = null;

    switch (module) {
      case PeakModules.FrontDesk:
        contactInfoData = await Services.FrontDesk.getContactInfo(personId);
        break;
      case PeakModules.Members:
        contactInfoData = await Services.Members.getContactInfo(personId);
        break;
      case PeakModules.Crm:
        contactInfoData = await Services.Leads.getContactInfo(personId);
        break;
      case PeakModules.PersonalTrainingCrm:
        contactInfoData = await Services.PTLeads.getContactInfo(personId);
        break;
      default:
    }

    dispatch(getContactInfoAction(contactInfoData));
  } catch (e) {
    dispatch(setContactInfoErrorAction(e));
    dispatch(enqueueErrorNotification(e));
  } finally {
    dispatch(setContactInfoIsLoadingAction(false));
  }
};
