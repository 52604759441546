import Services from 'services/network';
import { createAction } from '@reduxjs/toolkit';
import * as actionTypes from './constants';
import {
  CancelMembershipData,
  FreezeMembershipData,
  IFamilyMember,
  IFreezeHistoryPackage,
  IFreezeItem,
  IMembershipActionDTO,
} from '../../interfaces';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { GeneralThunkAction } from 'common/state/interfaces';
import { PeakModules } from 'common/constants/peakModules';
import { ActionResult } from 'common/constants';
import { IPackageInstanceDetails } from 'common/interfaces/service';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { IBundleView } from 'modules/services/interfaces/packages';
import { IPackageInventoryViewDto } from 'common/interfaces/membership';

const cancelMembershipAction = createAction<ActionResult>(actionTypes.CANCEL_PERSON_MEMBERSHIP);
const cancelMembershipLoadingAction = createAction<boolean>(
  actionTypes.CANCEL_PERSON_MEMBERSHIP_LOADING,
);
export const resetCancelMembership = createAction(actionTypes.RESET_CANCEL_PERSON_MEMBERSHIP);
export const cancelMembership = (
  personId: number,
  membershipId: string,
  data: CancelMembershipData,
  module: PeakModules,
  isMembershipCard?: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(cancelMembershipLoadingAction(true));

    try {
      if (isMembershipCard) {
        switch (module) {
          case PeakModules.FrontDesk:
            await Services.FrontDesk.cancelPersonMembership(personId, membershipId, data);
            break;
          case PeakModules.Members:
            await Services.Members.cancelPersonMembership(personId, membershipId, data);
            break;
          case PeakModules.Crm:
            await Services.Leads.cancelPersonMembership(personId, membershipId, data);
            break;
          case PeakModules.PersonalTrainingCrm:
            await Services.PTLeads.cancelPersonMembership(personId, membershipId, data);
            break;
          default:
        }
      } else {
        switch (module) {
          case PeakModules.FrontDesk:
            await Services.FrontDesk.cancelServicePackage(personId, membershipId, data);
            break;
          case PeakModules.Members:
            await Services.Members.cancelServicePackage(personId, membershipId, data);
            break;
          case PeakModules.Crm:
            await Services.Leads.cancelServicePackage(personId, membershipId, data);
            break;
          case PeakModules.PersonalTrainingCrm:
            await Services.PTLeads.cancelServicePackage(personId, membershipId, data);
            break;
          default:
        }
      }

      dispatch(cancelMembershipAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(cancelMembershipLoadingAction(false));
    }
  };
};

// freeze membership

const fetchFreezableItemsAction = createAction<IFreezeItem[]>(actionTypes.FETCH_FREEZING_ITEMS);
const fetchFreezableItemsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_FREEZING_ITEMS_LOADING,
);
export const resetFreezableItemsAction = createAction(actionTypes.RESET_FREEZING_ITEMS);

export const fetchFreezableItems = (
  personId: number,
  module: PeakModuleForNewPersonType,
  isMembershipCard: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchFreezableItemsLoadingAction(true));
    try {
      let result;

      if (isMembershipCard) {
        switch (module) {
          case PeakModules.FrontDesk:
            result = await Services.FrontDesk.fetchFreezeItems(personId);
            break;
          case PeakModules.Members:
            result = await Services.Members.fetchFreezeItems(personId);
            break;
          case PeakModules.Crm:
            result = await Services.Leads.fetchFreezeItems(personId);
            break;
          case PeakModules.PersonalTrainingCrm:
            result = await Services.PTLeads.fetchFreezeItems(personId);
            break;
          default:
        }
      } else {
        switch (module) {
          case PeakModules.FrontDesk:
            result = await Services.FrontDesk.fetchServicePackageFreezeInformation(personId);
            break;
          case PeakModules.Members:
            result = await Services.Members.fetchServicePackageFreezeInformation(personId);
            break;
          case PeakModules.Crm:
            result = await Services.Leads.fetchServicePackageFreezeInformation(personId);
            break;
          case PeakModules.PersonalTrainingCrm:
            result = await Services.PTLeads.fetchServicePackageFreezeInformation(personId);
            break;
          default:
        }
      }

      dispatch(fetchFreezableItemsAction(result));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchFreezableItemsLoadingAction(false));
    }
  };
};

const freezeMembershipAction = createAction<ActionResult>(actionTypes.FREEZE_PERSONS_MEMBERSHIP);
const freezeMembershipLoadingAction = createAction<boolean>(
  actionTypes.FREEZE_PERSONS_MEMBERSHIP_LOADING,
);
const freezeMembershipErrorAction = createAction<any>(actionTypes.FREEZE_PERSONS_MEMBERSHIP_ERROR);
export const resetFreezeMembership = createAction(actionTypes.RESET_FREEZE_PERSONS_MEMBERSHIP);
export const freezeMembership = (
  personId: number,
  data: FreezeMembershipData,
  module: PeakModuleForNewPersonType,
  isMembershipCard: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(freezeMembershipLoadingAction(true));

    try {
      if (isMembershipCard) {
        switch (module) {
          case PeakModules.FrontDesk:
            await Services.FrontDesk.freezeMembershipPackageServices(personId, data);
            break;
          case PeakModules.Members:
            await Services.Members.freezeMembershipPackageServices(personId, data);
            break;
          case PeakModules.PersonalTrainingCrm:
            await Services.PTLeads.freezeMembershipPackageServices(personId, data);
            break;
          case PeakModules.Crm:
            await Services.Leads.freezeMembershipPackageServices(personId, data);
            break;
          default:
        }
      } else {
        switch (module) {
          case PeakModules.FrontDesk:
            await Services.FrontDesk.freezePackageServices(personId, data);
            break;
          case PeakModules.Members:
            await Services.Members.freezePackageServices(personId, data);
            break;
          case PeakModules.PersonalTrainingCrm:
            await Services.PTLeads.freezePackageServices(personId, data);
            break;
          case PeakModules.Crm:
            await Services.Leads.freezePackageServices(personId, data);
            break;
          default:
        }
      }

      dispatch(freezeMembershipAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(freezeMembershipErrorAction(error));
    } finally {
      dispatch(freezeMembershipLoadingAction(false));
    }
  };
};

export const unfreezeMembership = (
  personId: number,
  module: PeakModuleForNewPersonType,
  packageId: string,
  isMembershipCard: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(freezeMembershipLoadingAction(true));

    try {
      if (isMembershipCard) {
        switch (module) {
          case PeakModules.FrontDesk:
            await Services.FrontDesk.unfreezeMembershipPackageServices(personId, packageId);
            break;
          case PeakModules.Members:
            await Services.Members.unfreezeMembershipPackageServices(personId, packageId);
            break;
          case PeakModules.PersonalTrainingCrm:
            await Services.PTLeads.unfreezeMembershipPackageServices(personId, packageId);
            break;
          case PeakModules.Crm:
            await Services.Leads.unfreezeMembershipPackageServices(personId, packageId);
            break;
          default:
        }
      } else {
        switch (module) {
          case PeakModules.FrontDesk:
            await Services.FrontDesk.unfreezePackageServices(personId, packageId);
            break;
          case PeakModules.Members:
            await Services.Members.unfreezePackageServices(personId, packageId);
            break;
          case PeakModules.PersonalTrainingCrm:
            await Services.PTLeads.unfreezePackageServices(personId, packageId);
            break;
          case PeakModules.Crm:
            await Services.Leads.unfreezePackageServices(personId, packageId);
            break;
          default:
        }
      }

      dispatch(freezeMembershipAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(freezeMembershipErrorAction(error));
    } finally {
      dispatch(freezeMembershipLoadingAction(false));
    }
  };
};

const fetchFreezeHistoryAction = createAction<IFreezeHistoryPackage[]>(
  actionTypes.FETCH_FREEZE_HISTORY,
);
const fetchFreezeHistoryLoadingAction = createAction<boolean>(
  actionTypes.FETCH_FREEZE_HISTORY_LOADING,
);
const fetchFreezeHistoryErrorAction = createAction<any>(actionTypes.FETCH_FREEZE_HISTORY_LOADING);
export const fetchFreezeHistory = (
  personId: number,
  module: PeakModuleForNewPersonType,
  isMembershipCard: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchFreezeHistoryLoadingAction(true));

    try {
      let result;

      if (isMembershipCard) {
        switch (module) {
          case PeakModules.FrontDesk:
            result = await Services.FrontDesk.freezeHistory(personId);
            break;
          case PeakModules.Members:
            result = await Services.Members.freezeHistory(personId);
            break;
          case PeakModules.Crm:
            result = await Services.Leads.freezeHistory(personId);
            break;
          case PeakModules.PersonalTrainingCrm:
            result = await Services.PTLeads.freezeHistory(personId);
            break;
          default:
        }
      } else {
        switch (module) {
          case PeakModules.FrontDesk:
            result = await Services.FrontDesk.getServicePackageFreezeHistory(personId);
            break;
          case PeakModules.Members:
            result = await Services.Members.getServicePackageFreezeHistory(personId);
            break;
          case PeakModules.Crm:
            result = await Services.Leads.getServicePackageFreezeHistory(personId);
            break;
          case PeakModules.PersonalTrainingCrm:
            result = await Services.PTLeads.getServicePackageFreezeHistory(personId);
            break;
          default:
        }
      }

      dispatch(fetchFreezeHistoryAction(result));
    } catch (e) {
      dispatch(fetchFreezeHistoryErrorAction(false));
    } finally {
      dispatch(fetchFreezeHistoryLoadingAction(false));
    }
  };
};

// reactivate membership

const fetchReactivateItemsAction = createAction<IPackageInstanceDetails[]>(
  actionTypes.FETCH_REACTIVATE_ITEMS,
);
const fetchReactivateItemsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_REACTIVATE_ITEMS_LOADING,
);
export const resetReactivateItems = createAction(actionTypes.RESET_REACTIVATE_ITEMS);

export const fetchReactivateMembershipItems = (
  personId: number,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchReactivateItemsLoadingAction(true));

    try {
      let result: IPackageInstanceDetails[] = [];

      switch (module) {
        case PeakModules.FrontDesk:
          result = await Services.FrontDesk.fetchReactivateMembershipItems(personId);
          break;
        case PeakModules.Members:
          result = await Services.Members.fetchReactivateMembershipItems(personId);
          break;
        case PeakModules.Crm:
          result = await Services.Leads.fetchReactivateMembershipItems(personId);
          break;
        case PeakModules.PersonalTrainingCrm:
          result = await Services.PTLeads.fetchReactivateMembershipItems(personId);
          break;
        default:
      }

      dispatch(fetchReactivateItemsAction(result));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchReactivateItemsLoadingAction(false));
    }
  };
};

export const fetchReactivateServiceItems = (
  personId: number,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchReactivateItemsLoadingAction(true));

    try {
      let result: IPackageInstanceDetails[] = [];

      switch (module) {
        case PeakModules.FrontDesk:
          result = await Services.FrontDesk.fetchReactivateServiceItems(personId);
          break;
        case PeakModules.Members:
          result = await Services.Members.fetchReactivateServiceItems(personId);
          break;
        case PeakModules.Crm:
          result = await Services.Leads.fetchReactivateServiceItems(personId);
          break;
        case PeakModules.PersonalTrainingCrm:
          result = await Services.PTLeads.fetchReactivateServiceItems(personId);
          break;
        default:
      }

      dispatch(fetchReactivateItemsAction(result));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchReactivateItemsLoadingAction(false));
    }
  };
};

const fetchCancelItemsAction = createAction<IPackageInstanceDetails[]>(
  actionTypes.FETCH_CANCEL_ITEMS,
);
const fetchCancelItemsLoadingAction = createAction<boolean>(actionTypes.FETCH_CANCEL_ITEMS_LOADING);
export const resetCancelItems = createAction(actionTypes.RESET_CANCEL_ITEMS);

export const fetchCancelItems = (personId: number, module: PeakModules): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchCancelItemsLoadingAction(true));

    try {
      let result: IPackageInstanceDetails[] = [];

      switch (module) {
        case PeakModules.FrontDesk:
          result = await Services.FrontDesk.fetchCancelItems(personId);
          break;
        case PeakModules.Members:
          result = await Services.Members.fetchCancelItems(personId);
          break;
        case PeakModules.Crm:
          result = await Services.Leads.fetchCancelItems(personId);
          break;
        case PeakModules.PersonalTrainingCrm:
          result = await Services.PTLeads.fetchCancelItems(personId);
          break;
        default:
      }

      dispatch(fetchCancelItemsAction(result));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchCancelItemsLoadingAction(false));
    }
  };
};

const fetchSubMembersListAction = createAction<IFamilyMember[]>(actionTypes.FETCH_SUB_MEMBERS_LIST);
const fetchSubMembersListLoadingAction = createAction<boolean>(
  actionTypes.FETCH_SUB_MEMBERS_LIST_LOADING,
);
export const fetchSubMembersList = (
  personId: number,
  reactivatableItemsIds: string[],
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchSubMembersListLoadingAction(true));
    try {
      const result = await Services.Persons.fetchSubMembersList(personId, reactivatableItemsIds);
      dispatch(fetchSubMembersListAction(result));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchSubMembersListLoadingAction(false));
    }
  };
};

// Membership actions

const fetchAvailableMembershipPackageActionsAction = createAction<IMembershipActionDTO>(
  actionTypes.FETCH_AVAILABLE_MEMBERSHIP_PACKAGE_ACTIONS,
);
const fetchAvailableMembershipPackageActionsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_AVAILABLE_MEMBERSHIP_PACKAGE_ACTIONS_LOADING,
);
export const resetAvailableMembershipPackageActionsAction = createAction(
  actionTypes.RESET_AVAILABLE_MEMBERSHIP_PACKAGE_ACTIONS,
);

export const fetchAvailableMembershipPackageActions = (
  personId: number,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchAvailableMembershipPackageActionsLoadingAction(true));

    try {
      let membershipPackageActions;

      switch (module) {
        case PeakModules.FrontDesk:
          membershipPackageActions = await Services.FrontDesk.fetchAvailableMembershipPackageActions(
            personId,
          );
          break;
        case PeakModules.Members:
          membershipPackageActions = await Services.Members.fetchAvailableMembershipPackageActions(
            personId,
          );
          break;
        case PeakModules.Crm:
          membershipPackageActions = await Services.Leads.fetchAvailableMembershipPackageActions(
            personId,
          );
          break;
        default:
          membershipPackageActions = await Services.PTLeads.fetchAvailableMembershipPackageActions(
            personId,
          );
      }

      dispatch(fetchAvailableMembershipPackageActionsAction(membershipPackageActions));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchAvailableMembershipPackageActionsLoadingAction(false));
    }
  };
};

// Membership actions

const fetchMembershipPackageSuccessAction = createAction<IBundleView>(
  actionTypes.FETCH_MEMBERSHIP_SUCCESS,
);
const fetchMembershipPackageLoadingAction = createAction<boolean>(
  actionTypes.FETCH_MEMBERSHIP_LOADING,
);
export const resetMembershipPackageAction = createAction(actionTypes.RESET_MEMBERSHIP);

export const fetchMembershipPackage = (
  personId: number,
  packageId: string,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchMembershipPackageLoadingAction(true));

    try {
      let membershipPackage;

      switch (module) {
        case PeakModules.FrontDesk:
          membershipPackage = await Services.FrontDesk.fetchMembership(personId, packageId);
          break;
        case PeakModules.Members:
          membershipPackage = await Services.Members.fetchMembership(personId, packageId);
          break;
        case PeakModules.Crm:
          membershipPackage = await Services.Leads.fetchMembership(personId, packageId);
          break;
        default:
          membershipPackage = await Services.PTLeads.fetchMembership(personId, packageId);
      }

      dispatch(fetchMembershipPackageSuccessAction(membershipPackage));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchMembershipPackageLoadingAction(false));
    }
  };
};

const fetchPersonMembershipPackageInventorySuccessAction = createAction<IPackageInventoryViewDto>(
  actionTypes.FETCH_PERSON_MEMBERSHIP_PACKAGE_INVENTORY_SUCCESS,
);
const fetchPersonMembershipPackageInventoryLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PERSON_MEMBERSHIP_PACKAGE_INVENTORY_LOADING,
);
export const resetPersonMembershipPackageInventoryAction = createAction(
  actionTypes.RESET_PERSON_MEMBERSHIP_PACKAGE_INVENTORY,
);

export const fetchMembershipPackageInventory = (
  personId: number,
  packageId: string,
  inventoryId: string,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonMembershipPackageInventoryLoadingAction(true));

    try {
      let inventory: IPackageInventoryViewDto;

      switch (module) {
        case PeakModules.FrontDesk:
          inventory = await Services.FrontDesk.getPersonPackageInventory(
            personId,
            packageId,
            inventoryId,
          );
          break;
        case PeakModules.Members:
          inventory = await Services.Members.getPersonPackageInventory(
            personId,
            packageId,
            inventoryId,
          );
          break;
        case PeakModules.Crm:
          inventory = await Services.Leads.getPersonPackageInventory(
            personId,
            packageId,
            inventoryId,
          );
          break;
        default:
          inventory = await Services.PTLeads.getPersonPackageInventory(
            personId,
            packageId,
            inventoryId,
          );
      }

      dispatch(fetchPersonMembershipPackageInventorySuccessAction(inventory));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPersonMembershipPackageInventoryLoadingAction(false));
    }
  };
};
