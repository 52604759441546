import { defineMessages } from 'react-intl';

export default defineMessages({
  familyMemberRelationMexLength: {
    id: 'app.common.validation.familyMemberRelation',
    defaultMessage: 'Field must be 100 characters or less',
  },
  availabilityRange: {
    id: 'app.common.validation.availabilityRange',
    defaultMessage: 'Availability exceeds its valid range',
  },
  nameLength: {
    id: 'app.common.validation.nameLength',
    defaultMessage: 'Value must be 32 characters or less',
  },
  nameLettersOnly: {
    id: 'app.common.validation.nameLettersOnly',
    defaultMessage: 'Value must contain only letters',
  },
  exemptionMinVal: {
    id: 'app.common.validation.exemptionMinVal',
    defaultMessage: 'Value must be greater than or equal to 0',
  },
  exemptionMaxVal: {
    id: 'app.common.validation.exemptionMaxVal',
    defaultMessage: 'Value must be less than 99',
  },
  idMaxLength: {
    id: 'app.common.validation.idMaxLength',
    defaultMessage: 'Value must be 50 characters or less',
  },
  salaryMinVal: {
    id: 'app.common.validation.salaryMinVal',
    defaultMessage: 'Salary should be greater than 0',
  },
  numberPositive: {
    id: 'app.common.validation.numberPositive',
    defaultMessage: 'Value must be a positive number',
  },
  numberIntegerType: {
    id: 'app.common.validation.numberIntegerType',
    defaultMessage: 'Value must be an integer',
  },
  numberType: {
    id: 'app.common.validation.numberType',
    defaultMessage: 'Value must be a number',
  },
  charMax100: {
    id: 'app.common.validation.charMax100',
    defaultMessage: 'Must be 100 characters or less',
  },
  invoiceAmountMoreThanZero: {
    id: 'app.common.validation.invoiceAmountMoreThanZero',
    defaultMessage: 'Required field more than 0',
  },
  clubMoreThanOne: {
    id: 'app.common.validation.clubMoreThanOne',
    defaultMessage: 'Clubs must be 1 or more',
  },
  minClubRate: {
    id: 'app.common.validation.minClubRate',
    defaultMessage: 'Rate must be 0 or more',
  },
  lettersOnly: {
    id: 'app.common.validation.lettersOnly',
    defaultMessage: 'Must contain only letters',
  },
  passwordLength: {
    id: 'app.common.validation.passwordLength',
    defaultMessage:
      'Password must contain a length of at least 8 characters and a maximum of 20 characters',
  },
  passwordAllowedSymbols: {
    id: 'app.common.validation.passwordAllowedSymbols',
    defaultMessage:
      "Please use only letters (a-z and A-Z), numbers (0-9), and special characters like !@#$%^&*.()'",
  },
  passwordDigitRequired: {
    id: 'app.common.validation.passwordDigitRequired',
    defaultMessage: 'Password must contain at least one digit',
  },
  passwordLowerCaseCharRequired: {
    id: 'app.common.validation.passwordLowercaseCharRequired',
    defaultMessage: 'Password must contain at least one lowercase Latin character [a-z]',
  },
  passwordUpperCaseCharRequired: {
    id: 'app.common.validation.passwordLowercaseCharRequired',
    defaultMessage: 'Password must contain at least one lowercase Latin character [a-z]',
  },
  passwordSpecialCharRequired: {
    id: 'app.common.validation.passwordSpecialCharRequired',
    defaultMessage: "Password must contain at least one special character like !@#$%^&*.()'",
  },
  passwordHintLength: {
    id: 'app.common.validation.passwordHintLength',
    defaultMessage: 'Password Hint must contain a length maximum of 50 characters',
  },
});
