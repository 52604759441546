import { defineMessages } from 'react-intl';

export default defineMessages({
  archiveEmployeeQuestion: {
    id: 'app.routes.Employees.archiveEmployeeQuestion',
    defaultMessage: 'Archive Employee?',
  },
  archiveEmployeeQuestionBody: {
    id: 'app.routes.Employees.archiveEmployeeQuestionBody',
    defaultMessage: 'Are you sure you want to archive {name} employee?',
  },
  employeesTitle: {
    id: 'app.routes.Employees.employeesTitle',
    defaultMessage: 'Employees',
  },
  clearAll: {
    id: 'app.routes.Employees.clearAll',
    defaultMessage: 'Clear All',
  },
  newEmployeeTitle: {
    id: 'app.routes.Employees.newEmployeeTitle',
    defaultMessage: 'New Employee',
  },
  editEmployeeTitle: {
    id: 'app.routes.Employees.editEmployeeTitle',
    defaultMessage: 'Edit Employee',
  },
  contact: {
    id: 'app.routes.Employees.contact',
    defaultMessage: 'Contact',
  },
  attachedFiles: {
    id: 'app.routes.Employees.attachedFiles',
    defaultMessage: 'Attached Files',
  },
  job: {
    id: 'app.routes.Employees.job',
    defaultMessage: 'Job Positions',
  },
  permissions: {
    id: 'app.routes.Employees.permissions',
    defaultMessage: 'Permissions',
  },
  account: {
    id: 'app.routes.Employees.account',
    defaultMessage: 'Account',
  },
  allowEmailReports: {
    id: 'app.routes.Employees.allowEmailReports',
    defaultMessage: 'Allow email reports',
  },
  primaryContact: {
    id: 'app.routes.Employees.primaryContact',
    defaultMessage: 'Primary Contact',
  },
  federalWithholding: {
    id: 'app.routes.Employees.federalWithholding',
    defaultMessage: 'Federal withholding',
  },
  exemptionsNumber: {
    id: 'app.routes.Employees.exemptionsNumber',
    defaultMessage: 'Number of exemptions',
  },
  employeeCreatedMessage: {
    id: 'app.routes.Employees.employeeCreatedMessage',
    defaultMessage: 'New employee has been created',
  },
  corporateAccess: {
    id: 'app.routes.Employees.corporateAccess',
    defaultMessage: 'Corporate Access',
  },
  viewPermissions: {
    id: 'app.routes.Employees.viewPermissions',
    defaultMessage: 'View Permissions',
  },
  willBeDisplayedInsteadOfText: {
    id: 'app.routes.Employees.willBeDisplayedInsteadOfText',
    defaultMessage: '{replacedField} will be displayed instead of {newField}',
  },
  personal: {
    id: 'app.routes.Employees.personal',
    defaultMessage: 'Personal',
  },
  primaryJobPosition: {
    id: 'app.routes.Employees.primaryJobPosition',
    defaultMessage: 'Please mark primary job position',
  },
  onlyOnePrimaryJobPosition: {
    id: 'app.routes.Employees.onlyOnePrimaryJobPosition',
    defaultMessage: 'Please mark only one job position as primary',
  },
});
