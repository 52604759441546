import React from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import inputLabels from 'common/messages/inputLabels';
import { Select } from 'common/components';
import { ActiveInactiveStatus } from 'common/constants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import IntlService from 'services/intl/IntlService';

const EmailFormFields = (): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={1}>
        <Grid item xs={9}>
          <Controller
            name="name"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                label={IntlService.formatMessage(inputLabels.templateName)}
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!errors.name}
                helperText={renderIntlMessage(errors.name?.message)}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="status"
            control={control}
            render={({ field: { name, value, onChange, onBlur } }) => (
              <Select
                fullWidth
                label={IntlService.formatMessage(inputLabels.status)}
                variant="outlined"
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              >
                {ActiveInactiveStatus.map(item => (
                  <MenuItem key={item.key} value={`${item.value}`}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="from"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextField
              label={IntlService.formatMessage(inputLabels.from)}
              variant="outlined"
              value={value}
              onChange={onChange}
              error={!!errors.from}
              helperText={renderIntlMessage(errors.from?.message)}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="fromName"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextField
              label={IntlService.formatMessage(inputLabels.fromName)}
              variant="outlined"
              value={value}
              onChange={onChange}
              error={!!errors.fromName}
              helperText={renderIntlMessage(errors.fromName?.message)}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="subject"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextField
              label={IntlService.formatMessage(inputLabels.subject)}
              variant="outlined"
              value={value}
              onChange={onChange}
              error={!!errors.subject}
              helperText={renderIntlMessage(errors.subject?.message)}
              fullWidth
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default EmailFormFields;
