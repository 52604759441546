import React, { forwardRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IInventorySection, IPackageStepProps } from 'modules/services/interfaces/packages';
import { ScrollBox } from 'common/components';
import InventoryStepForm from './InventoryStepForm';
import { useStepsLogic } from 'modules/services/hooks/useStepLogics';
import { defaultEditPackageFormData } from 'modules/services/constants/packages';

const InventoryStep = forwardRef(
  (
    {
      editPackageSectionFormData,
      renderFooter,
      handleChange,
      onPackageSubmit,
      submitAttempted,
    }: IPackageStepProps<IInventorySection>,
    ref,
  ): JSX.Element => {
    const formMethods = useForm({
      defaultValues: {
        ...defaultEditPackageFormData.inventorySection,
        ...editPackageSectionFormData,
      },
      // resolver: yupResolver(NewPackageValidationSchema),
      mode: 'onBlur',
    });

    const { trigger, getValues } = formMethods;

    const { onSubmit, onNext, onBack } = useStepsLogic(
      'inventorySection',
      ref,
      getValues,
      trigger,
      onPackageSubmit,
      handleChange,
      submitAttempted,
    );

    return (
      <FormProvider {...formMethods}>
        <ScrollBox hasShadowsOnScroll>
          <form autoComplete="none">
            <InventoryStepForm formMethods={formMethods} />
          </form>
        </ScrollBox>
        {renderFooter(onSubmit, onNext, onBack)}
      </FormProvider>
    );
  },
);

export default InventoryStep;
