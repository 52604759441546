import React, { PropsWithChildren } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import ClearButton from '../ClearFilterButton/ClearFilterButton';
import ApplyFilterButton from '../ApplyFilterButton/ApplyFilterButton';
import { ScrollBox } from 'common/components';

interface IFilterMenuBodyProps {
  dialog?: boolean;

  showClear?: boolean;
  showApply?: boolean;

  disableClear?: boolean;
  disableApply?: boolean;

  onClearFilter?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onApplyFilter?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxHeight: ({ dialog }: IFilterMenuBodyProps) => (dialog ? undefined : 'calc(100vh - 180px)'),
    height: ({ dialog }: IFilterMenuBodyProps) => (dialog ? '100%' : 'auto'),
  },
}));

const FilterMenuBody: React.FC<PropsWithChildren<IFilterMenuBodyProps>> = (
  props: PropsWithChildren<IFilterMenuBodyProps>,
): JSX.Element => {
  const styles = useStyles(props);
  const {
    dialog,
    showClear,
    showApply,
    disableClear,
    disableApply,
    onApplyFilter,
    onClearFilter,
    children,
  } = props;

  return (
    <>
      <ScrollBox suppressScrollX className={styles.root}>
        {children}
      </ScrollBox>
      {!dialog && (showClear || showApply) && (
        <Box p={1} display="flex" justifyContent="flex-end">
          {showClear && <ClearButton disabled={disableClear} onClick={onClearFilter} small />}
          {showApply && <ApplyFilterButton disabled={disableApply} onClick={onApplyFilter} small />}
        </Box>
      )}
    </>
  );
};

export default FilterMenuBody;
