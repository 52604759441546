import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { ITask, ITaskImt } from 'modules/crm/interfaces/tasks';
import {
  IBodyCell,
  IHeadCell,
  IMultipleSelectData,
  ITableParams,
  ITableRow,
} from 'common/interfaces/table';
import { IFilterSettings } from 'common/interfaces/filter';
import { TASK_DATE_FORMAT } from 'modules/crm/constants/tasks';
import { DialogComponent, MemberName, Table } from 'common/components';
import { ReassignData } from '../../state/tasks/actions';
import { ReactComponent as UserIcon } from 'img/icons/user.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
import TasksActionsMenu from './ActionsMenu/ActionsMenu';
import SalespersonName from './SalespersonName/SalespersonName';
import SmallScreenCell from './SmallScreenCell/SmallScreenCell';
import ActivityTypeChip from '../ActivityTypeChip/ActivityTypeChip';
import ReassignSalespersonModal from './ReassignSalespersonModal/ReassignSalespersonModal';
import tableHeaders from 'common/messages/tableHeaders';
import tasks from '../../messages/tasks';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { PeakModules } from 'common/constants/peakModules';
import { IObject } from 'common/interfaces/common';
import messages from 'modules/crm/messages/common';
import commonMessages from 'common/messages/messages';
import ptCrmMessages from 'modules/personal-training-crm/messages/messages';
import usePermissionContext from 'common/hooks/context/usePermissionContext';
import { CRMTemplatesRoutes } from 'modules/crm/routes';
import { PtCrmRoutes } from 'modules/personal-training-crm/routes';

interface IProps {
  items: ImmutableList<ITaskImt>;
  tableParams: ITableParams;
  tableFilterSettings: IFilterSettings[];
  totalRows: number;
  isLoading: boolean;
  isTaskAssignLoading: boolean;
  tasksActionResult: Record<string, boolean>;
  onChangeParams: (tableParams: ITableParams) => void;
  onAssignSalespersonTasks: (options: ReassignData) => void;
  onCompleteTasks: (data) => void;
  resetTasksActionResult: () => void;
  module: PeakModules.Crm | PeakModules.PersonalTrainingCrm;
}

interface IMultipleSelectTaskTableData extends IMultipleSelectData {
  allSelected: boolean;
}

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
});

const TasksTable: FC<IProps> = ({
  isLoading,
  items,
  tableParams,
  tableFilterSettings,
  totalRows,
  tasksActionResult,
  isTaskAssignLoading,
  onChangeParams,
  onAssignSalespersonTasks,
  onCompleteTasks,
  resetTasksActionResult,
  module,
}) => {
  const [showReassignDialog, setShowReassignDialog] = useState<boolean>(false);
  const [showCompleteDialog, setShowCompleteDialog] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<IMultipleSelectTaskTableData>({
    excludedIds: [],
    includedIds: [],
    tableParams: {
      tableFilters: tableParams.filters || [],
      searchStr: tableParams.searchStr || '',
      sortBy: String(tableParams.orderBy),
      sortDirection: tableParams.order,
      page: tableParams.page,
      perPage: tableParams.perPage,
    },
    allSelected: false,
  });

  const [timezoneMoment] = useTimezoneMoment();
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const isCrmModule = module === PeakModules.Crm;
  const isPTCrmModule = module === PeakModules.PersonalTrainingCrm;
  let hasEditSalespersonPermission = false;
  let hasEditStatusPermission = false;

  const {
    CRM_TASK_EDIT_SALESPERSON,
    CRM_TASK_EDIT_STATUS,
    PT_CRM_TASK_EDIT_SALESPERSON,
    PT_CRM_TASK_EDIT_STATUS,
  } = usePermissionContext();

  if (isCrmModule) {
    hasEditSalespersonPermission = CRM_TASK_EDIT_SALESPERSON;
    hasEditStatusPermission = CRM_TASK_EDIT_STATUS;
  } else if (isPTCrmModule) {
    hasEditSalespersonPermission = PT_CRM_TASK_EDIT_SALESPERSON;
    hasEditStatusPermission = PT_CRM_TASK_EDIT_STATUS;
  }

  const hasActions = hasEditSalespersonPermission || hasEditStatusPermission;

  const headerOptions = useMemo((): IHeadCell[] => {
    const headerOps: IHeadCell[] = [
      { id: 'TYPE', label: <FormattedMessage {...tableHeaders.type} />, sort: true },
      {
        id: 'LEAD_NAME',
        label: <FormattedMessage {...(isCrmModule ? tableHeaders.lead : tableHeaders.ptLead)} />,
        sort: true,
      },
      {
        id: 'SALESPERSON_NAME',
        label: <FormattedMessage {...tableHeaders.salesperson} />,
        sort: true,
      },
      { id: 'DATE', label: <FormattedMessage {...tableHeaders.date} />, sort: true },
      { id: 'STATUS', label: <FormattedMessage {...tableHeaders.status} />, sort: true },
    ];

    if (hasActions) {
      headerOps.push({
        id: 'actions',
        label: '',
        sort: false,
        align: 'center',
        padding: 'none',
      });
    }

    return headerOps;
  }, [isCrmModule, hasActions]);

  const handleReassignDialogShow = useCallback(
    (selected: string | string[]) => {
      setSelectedRows(Array.isArray(selected) ? selected : [selected]);
      setShowReassignDialog(true);
    },
    [setSelectedRows, setShowReassignDialog],
  );

  const handleReassignDialogClose = useCallback(() => {
    setShowReassignDialog(false);
    setSelectedRows([]);
  }, [setShowReassignDialog]);

  const handleAssignSalesperson = useCallback(
    (person: ITask) => {
      if (selectedRows.length && !selectedRowsData.includedIds?.length) {
        onAssignSalespersonTasks({
          includedIds: selectedRows,
          salespersonId: person.salesperson.id,
        });
      } else {
        onAssignSalespersonTasks({
          salespersonId: person.salesperson.id,
          ...selectedRowsData,
        });
      }
    },
    [onAssignSalespersonTasks, selectedRows, selectedRowsData],
  );

  const handleCompleteDialogShow = useCallback(
    (selected: string | string[]) => {
      setSelectedRows(Array.isArray(selected) ? selected : [selected]);
      setShowCompleteDialog(true);
    },
    [setSelectedRows, setShowCompleteDialog],
  );
  const handleSelect = useCallback(
    (selectedData: IObject) => {
      setSelectedRowsData({
        excludedIds: selectedData.excludedIds,
        includedIds: selectedData.selectedIds,
        allSelected: selectedData.isAllSelected,
        tableParams: selectedData.tableParams,
      });
    },
    [setSelectedRowsData],
  );

  const handleCompleteDialogClose = useCallback(() => {
    setShowCompleteDialog(false);
    setSelectedRows([]);
  }, [setShowCompleteDialog]);

  const handleCompleteTaskList = useCallback(() => {
    if (selectedRows.length) {
      onCompleteTasks({ includedIds: selectedRows });
      return;
    }
    onCompleteTasks(selectedRowsData);
  }, [selectedRowsData, selectedRows, onCompleteTasks]);

  useEffect(() => {
    if (tasksActionResult.success) {
      handleReassignDialogClose();
      handleCompleteDialogClose();
      resetTasksActionResult();
    }
  }, [
    tasksActionResult,
    handleReassignDialogClose,
    handleCompleteDialogClose,
    resetTasksActionResult,
  ]);

  const createRows = (): ITableRow[] => {
    return items
      .map(
        (task: ITaskImt): ITableRow => {
          const lead = task.getIn(['lead']).toJS();
          const viewLeadPath = isCrmModule
            ? `/${CRMTemplatesRoutes.CRM}/${CRMTemplatesRoutes.LEADS}/${lead.id}`
            : `/${PtCrmRoutes.PT_CRM}/${PtCrmRoutes.LEADS}/${lead.id}`;

          const bodyCells: IBodyCell[] = [
            {
              label: '-',
              className: 'body-tag-cell',
              cellComponent: <ActivityTypeChip name={task.get('type')} />,
            },
            {
              label: '-',
              className: 'body-cell',
              cellComponent: (
                <MemberName
                  firstName={lead.firstName}
                  lastName={lead.lastName}
                  photoUrl={lead.imageUrl}
                  status={lead.salesStatus || '-'}
                  onClick={() => navigate(viewLeadPath)}
                />
              ),
            },
            {
              label: '-',
              className: 'body-cell',
              cellComponent: (
                <SalespersonName
                  firstName={task.getIn(['salesperson', 'firstName'])}
                  lastName={task.getIn(['salesperson', 'lastName'])}
                />
              ),
            },
            {
              label: '-',
              className: 'body-cell',
              cellComponent: (
                <Typography>{timezoneMoment(task.get('date')).format(TASK_DATE_FORMAT)}</Typography>
              ),
            },
            {
              label: '-',
              className: 'body-cell',
              cellComponent: (
                <Typography>
                  {task.get('completed') ? (
                    <FormattedMessage {...tasks.completed} />
                  ) : (
                    <FormattedMessage {...tasks.notCompleted} />
                  )}
                </Typography>
              ),
            },
          ];

          const bodyCellsMini: IBodyCell[] = [
            {
              label: '-',
              cellComponent: (
                <SmallScreenCell
                  taskType={task.get('type')}
                  lead={task.get('lead').toJS()}
                  leadStatus={task.getIn(['lead', 'salesStatus'])}
                  salesperson={task.get('salesperson').toJS()}
                  date={task.get('date')}
                  isCrmModule={isCrmModule}
                />
              ),
            },
          ];

          if (hasActions) {
            bodyCells.push({
              label: 'actions',
              className: 'body-actions-cell',
              cellComponent: (
                <TasksActionsMenu
                  taskId={task.get('id')}
                  completeBtnDisabled={
                    !selectedRowsData.includedIds?.length && task.get('completed')
                  }
                  onReassign={handleReassignDialogShow}
                  onComplete={handleCompleteDialogShow}
                  hasEditSalespersonPermission={hasEditSalespersonPermission}
                  hasEditStatusPermission={hasEditStatusPermission}
                />
              ),
            });

            bodyCellsMini.push({
              label: 'actions',
              align: 'center',
              padding: 'none',
              cellComponent: (
                <TasksActionsMenu
                  taskId={task.get('id')}
                  onReassign={handleReassignDialogShow}
                  onComplete={handleCompleteDialogShow}
                  hasEditSalespersonPermission={hasEditSalespersonPermission}
                  hasEditStatusPermission={hasEditStatusPermission}
                />
              ),
            });
          }

          return {
            id: task.get('id'),
            cells: bodyCells,
            cellsMini: bodyCellsMini,
          };
        },
      )
      .toJS();
  };

  const multipleSelectActions = [
    {
      id: 'reassign',
      tooltip: 'Assign Salesperson',
      ButtonIcon: UserIcon,
      className: classes.icon,
      onClick: () => {
        setShowReassignDialog(true);
      },
    },
    {
      id: 'complete',
      tooltip: 'Complete',
      ButtonIcon: CheckIcon,
      className: classes.icon,
      onClick: () => {
        setShowCompleteDialog(true);
      },
    },
  ];

  const modals = (
    <>
      {showReassignDialog && (
        <ReassignSalespersonModal
          module={module}
          isOpen={showReassignDialog}
          onClose={handleReassignDialogClose}
          onSubmit={handleAssignSalesperson}
          isLoading={isTaskAssignLoading}
        />
      )}
      {showCompleteDialog && (
        <DialogComponent
          title={
            <FormattedMessage
              {...(isCrmModule ? tasks.completeTasksLabel : ptCrmMessages.completePtTasksLabel)}
            />
          }
          submitBtnTitle={<FormattedMessage {...commonMessages.completeBtn} />}
          size="xs"
          isOpen={showCompleteDialog}
          onClose={handleCompleteDialogClose}
          onSubmit={handleCompleteTaskList}
          disableFullScreenBehavior
        />
      )}
    </>
  );

  return (
    <>
      {modals}
      <Table
        activeSelect
        activeSelectBodyClassName="body-checkbox-cell"
        title={<FormattedMessage {...(isCrmModule ? messages.tasks : ptCrmMessages.ptTasks)} />}
        backRedirectLink={`/${location.pathname.split('/')[1]}`}
        headerOptions={headerOptions}
        rows={createRows()}
        successAction={tasksActionResult}
        multipleSelectActions={multipleSelectActions}
        filters={tableFilterSettings}
        showPerPageSelect
        totalRows={totalRows}
        isLoading={isLoading}
        onChangeParams={onChangeParams}
        onSelect={handleSelect}
        tableParams={tableParams}
      />
    </>
  );
};

export default TasksTable;
