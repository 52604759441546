import React, { memo, useCallback, useState } from 'react';
import { TableOrderByParams } from 'common/constants';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
import tableFilters from 'common/messages/tableFilters';
import { ITableParams } from 'common/interfaces/table';
import { useUpdateFiltersOnChangeClub } from 'common/hooks/useUpdateFiltersOnChangeClub';
import {
  useGetServicesHistgoryPackageQuery,
  useGetServicesHistgoryQuery,
  useGetServicesInventoryInfoQuery,
} from 'common/components/PersonProfile/state/services/service.api';
import ServicesHistoryModal from './ServicesHistoryModal/ServicesHistoryModal';
import IntlService from 'services/intl/IntlService';

interface IProps {
  onClose: () => void;
  personId: number;
}

const ServicesHistory = ({ onClose, personId }: IProps) => {
  const [packageId, setPackageId] = useState<string | null>(null);
  const [inventoryId, setInventoryId] = useState<string | null>(null);

  const tableFilterSettings: IFilterSettings[] = [
    {
      name: 'range',
      title: IntlService.formatMessage(tableFilters.period),
      type: FilterTypes.DATE_RANGE,
      options: {
        startDate: null,
        endDate: null,
      },
    },
  ];

  const [tableParams, setTableParams] = useUpdateFiltersOnChangeClub(
    { filterSettings: tableFilterSettings },
    null,
    {
      orderBy: TableOrderByParams.DATE,
      order: 'desc',
    },
  );

  const {
    data: servicesHistory,
    isLoading: isServicesHistoryLoading,
  } = useGetServicesHistgoryQuery({
    personId,
    params: tableParams,
  });

  const { data: packageInfo, isLoading: isPackageInfoLoading } = useGetServicesHistgoryPackageQuery(
    {
      personId,
      packageId,
    },
    { skip: !packageId },
  );

  const {
    data: inventoryInfo,
    isLoading: isInventoryInfoLoading,
  } = useGetServicesInventoryInfoQuery(
    {
      personId,
      inventoryId,
    },
    { skip: !inventoryId },
  );
  const handleChangeTableProps = useCallback(
    (tableProps: ITableParams): void => {
      setTableParams(tableProps);
    },
    [setTableParams],
  );

  return (
    <ServicesHistoryModal
      onClose={onClose}
      inventoryId={inventoryId}
      packageId={packageId}
      setPackageId={setPackageId}
      setInventoryId={setInventoryId}
      servicesHistory={servicesHistory}
      isServicesHistoryLoading={isServicesHistoryLoading}
      packageInfo={packageInfo}
      isPackageInfoLoading={isPackageInfoLoading}
      inventoryInfo={inventoryInfo}
      isInventoryInfoLoading={isInventoryInfoLoading}
      tableParams={tableParams}
      handleChangeTableProps={handleChangeTableProps}
      tableFilterSettings={tableFilterSettings}
    />
  );
};

export default memo(ServicesHistory);
