import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IFormEmergencyContact } from 'common/interfaces/additionalInfo';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { TextField } from 'common/components';
import { RequiredProfileInfo } from 'modules/corporate-settings/constants/requiredFields';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import inputLabels from 'common/messages/inputLabels';

const EmergencyContactRelationship: FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormEmergencyContact>();

  const error = errors.emergencyRelationship;
  const renderIntlMessage = useRenderIntlMessage();
  const { profileRecommendedFields: recommendedFields } = useRecommendedFields();
  const recommended = Boolean(recommendedFields?.get(RequiredProfileInfo.EmergencyContactInfo));

  return (
    <Controller
      control={control}
      name="emergencyRelationship"
      defaultValue=""
      render={({ field }) => (
        <TextField
          {...field}
          label={renderIntlMessage(inputLabels.emergencyContactRelationship)}
          variant="outlined"
          fullWidth
          error={!!error}
          helperText={error ? renderIntlMessage(error.message) : undefined}
          recommended={recommended}
        />
      )}
    />
  );
};

export default EmergencyContactRelationship;
