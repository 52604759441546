import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAppDispatch } from 'store/hooks';
import { colors } from 'common/ui/theme/default';
import { resetUploadFile } from 'common/state/uploadFile/actions';
import eSignMessages from 'common/messages/signature';
import IntlService from 'services/intl/IntlService';
import useSignatureContext from 'common/hooks/context/useSignatureContext';
import Button from 'common/components/Button/Button';

const useStyles = makeStyles(() => ({
  signatureContainer: ({ width, height }: Pick<IESignature, 'width' | 'height'>) => ({
    border: `1px dashed ${colors.primary}`,
    borderRadius: '4px',
    position: 'relative',
    width,
    height,
  }),
  signature: {
    backgroundColor: 'white',
  },
  actionsContainer: {
    position: 'absolute',
    top: '-45px',
    right: '0',
    display: 'flex',
    gap: '16px',
    '& button': {
      padding: '0',
      margin: '8px 0',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  },
}));

interface IMousePos {
  x: number;
  y: number;
}

interface IESignature {
  width: number;
  height: number;
  documentId: string;
}

const ESignature: FC<IESignature> = ({ width, height, documentId }) => {
  const [hasSignature, setHasSignature] = useState<boolean>(false);
  const { addDocumentSignature } = useSignatureContext();
  const dispatch = useAppDispatch();
  const classes = useStyles({ width, height });
  const refCanvas = useRef<HTMLCanvasElement | null>(null);
  const refCtx = useRef<CanvasRenderingContext2D | null>(null);
  const refDrawing = useRef<boolean>(false);
  const refX = useRef<number>(0);
  const refY = useRef<number>(0);
  const canvasWidth = width - 2;
  const canvasHeight = height - 2;

  const draw = useCallback((startX: number, startY: number, endX: number, endY: number): void => {
    if (refCtx.current) {
      refCtx.current.beginPath();
      refCtx.current.strokeStyle = 'black';
      refCtx.current.lineWidth = 1;
      refCtx.current.moveTo(startX, startY);
      refCtx.current.lineTo(endX, endY);
      refCtx.current.stroke();
      refCtx.current.closePath();
    }
  }, []);

  const getMousePos = useCallback(
    (event: React.MouseEvent<HTMLCanvasElement>): IMousePos | null => {
      if (refCanvas.current) {
        const rect = refCanvas.current.getBoundingClientRect();

        return {
          x: ((event.clientX - rect.left) / (rect.right - rect.left)) * refCanvas.current.width,
          y: ((event.clientY - rect.top) / (rect.bottom - rect.top)) * refCanvas.current.height,
        };
      }

      return null;
    },
    [],
  );

  const onDrawStart = useCallback(
    (event): void => {
      const pos = getMousePos(event);

      if (pos) {
        refX.current = pos.x;
        refY.current = pos.y;
        refDrawing.current = true;
      }
    },
    [getMousePos],
  );

  const onDrawing = useCallback(
    (event): void => {
      if (refDrawing.current) {
        const pos = getMousePos(event);

        if (pos) {
          draw(refX.current, refY.current, pos.x, pos.y);
          refX.current = pos.x;
          refY.current = pos.y;
        }
      }
    },
    [getMousePos, draw],
  );

  const onDrawEnd = useCallback(
    (event): void => {
      if (refDrawing.current) {
        const pos = getMousePos(event);

        if (pos) {
          draw(refX.current, refY.current, pos.x, pos.y);
        }

        refX.current = 0;
        refY.current = 0;
        refDrawing.current = false;

        if (!hasSignature) {
          setHasSignature(true);
        }

        if (refCanvas.current) {
          refCanvas.current.toBlob(blob => {
            if (blob) {
              const fileName = `eSignature-${documentId}.png`;
              const file = new File([blob], fileName, { type: 'image/png' });

              addDocumentSignature(file, documentId);
            }
          });
        }
      }
    },
    [getMousePos, draw, hasSignature, documentId, addDocumentSignature],
  );

  const onClear = (): void => {
    if (refCtx.current) {
      refCtx.current.clearRect(0, 0, canvasWidth, canvasHeight);
      dispatch(resetUploadFile());
      setHasSignature(false);
    }
  };

  useEffect(() => {
    if (refCanvas.current) {
      refCtx.current = refCanvas.current.getContext('2d');
    }
  }, []);

  useEffect(() => {
    const canvas = refCanvas.current;

    if (canvas) {
      canvas.addEventListener('mousedown', onDrawStart);
      canvas.addEventListener('mousemove', onDrawing);
      canvas.addEventListener('mouseout', onDrawing);
      document.addEventListener('mouseup', onDrawEnd);
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener('mousedown', onDrawStart);
        canvas.removeEventListener('mousemove', onDrawing);
        canvas.removeEventListener('mouseout', onDrawing);
        document.removeEventListener('mouseup', onDrawEnd);
      }
    };
  }, [onDrawStart, onDrawing, onDrawEnd]);

  return (
    <div className={classes.signatureContainer}>
      <canvas
        ref={refCanvas}
        width={canvasWidth}
        height={canvasHeight}
        className={classes.signature}
      />

      <div className={classes.actionsContainer}>
        <Button color="primary" onClick={onClear} disabled={!hasSignature}>
          {IntlService.formatMessage(eSignMessages.clearButton)}
        </Button>
      </div>
    </div>
  );
};

export default ESignature;
