import React, { useCallback, useEffect, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import { IModificationHistoryImt } from 'common/components/PersonProfile/interfaces';
import { IPageMetaImt } from 'common/interfaces/pagination';
import {
  CheckInBlock,
  ModificationHistoryItemModal,
  ModificationHistoryModal,
  ModificationHistoryTable,
} from 'common/components/PersonProfile/components/index';
import { Button } from 'common/components/index';
import { fetchRecentModificationHistory } from 'common/components/PersonProfile/state/actions';
import {
  selectModificationHistory,
  selectModificationHistoryLoading,
  selectModificationHistoryMeta,
  selectRecentModificationHistory,
  selectRecentModificationHistoryLoading,
} from 'common/components/PersonProfile/state/selectors';
import { selectActionUpdateResult } from 'common/state/newPerson/primaryInfo/selectors';
import { useAppDispatch } from 'store/hooks';
import { PeakModules } from 'common/constants/peakModules';
import { ActionResult } from 'common/constants';
import bookingMessages from 'modules/booking/messages';
import permissionsMessages from 'modules/permissions/messages/messages';

interface IProps {
  personId: number;
  module?: PeakModules;
}

const ModificationHistory = ({ personId, module }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const modificationHistoryList: List<IModificationHistoryImt> = useRootSelector(
    selectModificationHistory(personId),
  );
  const recentModificationHistoryList: List<IModificationHistoryImt> = useRootSelector(
    selectRecentModificationHistory(personId),
  );
  const isRecentModificationHistoryListLoading: boolean = useRootSelector(
    selectRecentModificationHistoryLoading(personId),
  );
  const modificationHistoryLoading: boolean = useRootSelector(
    selectModificationHistoryLoading(personId),
  );
  const modificationHistoryMeta: IPageMetaImt = useRootSelector(
    selectModificationHistoryMeta(personId),
  );
  const updatePersonActionResult: ActionResult = useRootSelector(selectActionUpdateResult);

  const [isModificationHistoryModalOpen, setIsModificationHistoryModalOpen] = useState(false);
  const [revisionId, setRevisionId] = useState<number | null>(null);

  const fetchModificationHistory = useCallback(() => {
    if (module) {
      dispatch(fetchRecentModificationHistory(personId, module));
    }
  }, [dispatch, module, personId]);

  useEffect(() => {
    fetchModificationHistory();
  }, [fetchModificationHistory]);

  useEffect(() => {
    if (updatePersonActionResult === ActionResult.SUCCESS_ACTION) {
      fetchModificationHistory();
    }
  }, [fetchModificationHistory, updatePersonActionResult]);

  return (
    <>
      <CheckInBlock
        headerDivider
        title={<FormattedMessage {...bookingMessages.modificationHistory} />}
        buttonTitle={
          <Button color="primary" hasHoverStyle>
            <FormattedMessage {...permissionsMessages.viewAll} />
          </Button>
        }
        onClickButton={() => setIsModificationHistoryModalOpen(true)}
        tableContainer
      >
        <ModificationHistoryTable
          disableSorting
          modificationHistory={recentModificationHistoryList}
          modificationHistoryLoading={isRecentModificationHistoryListLoading}
          hideSearchInput
          hidePagination
          onSelectHistoryItem={setRevisionId}
          hidePadding
          suppressFiltersMobilePadding
        />
      </CheckInBlock>

      {isModificationHistoryModalOpen && (
        <ModificationHistoryModal
          isOpen={isModificationHistoryModalOpen}
          modificationHistory={modificationHistoryList}
          modificationHistoryLoading={modificationHistoryLoading}
          modificationHistoryMeta={modificationHistoryMeta}
          onClose={() => setIsModificationHistoryModalOpen(false)}
          onSelectHistoryItem={setRevisionId}
          personId={personId}
          module={module}
        />
      )}

      {!!revisionId && (
        <ModificationHistoryItemModal
          isOpen={!!revisionId}
          revisionId={revisionId}
          personId={personId}
          module={module}
          onClose={() => setRevisionId(null)}
        />
      )}
    </>
  );
};

export default ModificationHistory;
