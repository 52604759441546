import baseApi from 'api/baseApi';
import { TClubListItem } from 'common/interfaces/dictionary';

const LeadApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getClubsDictionary: builder.query<{ data: TClubListItem[] }, void>({
      query: () => ({
        url: '/api/v1/clubs/dictionary',
      }),
    }),
  }),
});

export const { useGetClubsDictionaryQuery } = LeadApi;
