import React, { useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { useAppDispatch } from 'store/hooks';
import {
  selectPackageInfo,
  selectPackageInfoLoading,
} from 'modules/services/state/packages/selectors';
import { fetchPackageInfo, resetPackageInfo } from 'modules/services/state/packages/actions';
import { IBundleView } from 'modules/services/interfaces/packages';
import { DialogComponent } from 'common/components';
import BundleView from 'common/components/BundleView/BundleView';

interface IProps {
  open: boolean;
  packageId: string | null;
  onClose: () => void;
}

const PackageInfoDialog: React.FC<IProps> = ({ open, packageId, onClose }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const packageInfo: IBundleView = useRootSelector(selectPackageInfo);
  const isLoading: boolean = useRootSelector(selectPackageInfoLoading);

  useEffect(() => {
    if (open && packageId) {
      dispatch(fetchPackageInfo(packageId));
    }
  }, [dispatch, open, packageId]);

  useEffect(() => {
    if (!open) {
      dispatch(resetPackageInfo());
    }
  }, [dispatch, open]);

  return (
    <DialogComponent
      isOpen={open}
      loading={isLoading}
      onClose={onClose}
      submitBtn={false}
      cancelBtn={false}
      enableBackdropClick
      supressBottomShadow
      size="md"
      title={packageInfo?.title}
    >
      {!!packageInfo && <BundleView bundle={packageInfo} />}
    </DialogComponent>
  );
};

export default PackageInfoDialog;
