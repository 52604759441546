import React, { useCallback } from 'react';

import useMenuState from 'common/hooks/useMenuState';

import { DialogComponent } from 'common/components';
import FilterButton from '../FilterButton/FilterButton';
import FilterMenu from '../FilterMenu/FilterMenu';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import commonMessages from '../../../messages/messages';

interface IProps {
  name: string;
  title: string | JSX.Element | React.ReactNode;
  titleBold?: boolean;
  filtersTotal?: number;
  dialogMenu?: boolean;
  onClose?: () => void;
  className?: string;
  dropdownListClassName?: string;
  isShowReset?: boolean;
  reset?: (event: React.MouseEvent<HTMLElement>) => void;

  renderBody?: (closeMenu?: () => void) => JSX.Element;

  clearBtn?: boolean;
  clearDisabled?: boolean;
  onClear?: () => void;

  onDialogApply?: (closeMenu?: () => void) => void;
  suppressOnKeyDownEvent?: boolean;
}

const DropdownFilter = (props: React.PropsWithChildren<IProps>) => {
  const {
    name,
    titleBold = false,
    title,
    filtersTotal,
    dialogMenu = false,
    onClose,
    children,
    renderBody,
    clearBtn,
    clearDisabled,
    onClear,
    onDialogApply,
    className,
    dropdownListClassName,
    isShowReset,
    reset,
    suppressOnKeyDownEvent,
  } = props;

  const { menuAnchorEl, handleShowMenu, handleCloseMenu } = useMenuState();

  const handleCloseFilter = useCallback(() => {
    handleCloseMenu();
    if (onClose) onClose();
  }, [handleCloseMenu, onClose]);

  return (
    <div className={className}>
      <FilterButton
        bold={titleBold}
        filtersTotal={filtersTotal}
        active={Boolean(menuAnchorEl)}
        onClick={handleShowMenu}
        isShowReset={isShowReset}
        reset={reset}
      >
        {title}
      </FilterButton>

      {dialogMenu ? (
        <DialogComponent
          title={title}
          isOpen={Boolean(menuAnchorEl)}
          submitBtnTitle="Apply"
          size="md"
          onClose={handleCloseFilter}
          onSubmit={() => {
            if (onDialogApply) {
              onDialogApply(handleCloseMenu);
            }
          }}
          additionalActionButtons={
            clearBtn ? (
              <Button color="primary" disabled={clearDisabled} onClick={onClear}>
                <FormattedMessage {...commonMessages.clearBtn} />
              </Button>
            ) : (
              undefined
            )
          }
        >
          {renderBody ? renderBody(handleCloseMenu) : children}
        </DialogComponent>
      ) : (
        <FilterMenu
          suppressOnKeyDownEvent={suppressOnKeyDownEvent}
          id={name}
          dropdownListClassName={dropdownListClassName || ''}
          anchorEl={menuAnchorEl}
          onClose={handleCloseFilter}
        >
          {renderBody ? renderBody(handleCloseMenu) : children}
        </FilterMenu>
      )}
    </div>
  );
};

export default React.memo(DropdownFilter);
