import React, { FC } from 'react';
import { TextField } from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { DialogComponent } from 'common/components';
import { FormattedMessage } from 'react-intl';
import messages from 'common/messages/messages';
import reportsMessages from 'modules/reports/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import { getEmailErrorMessage, getRequiredErrorMessage } from 'common/utils/validation';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

interface ISendReportModal {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (data) => void;
}

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email(getEmailErrorMessage)
    .required(getRequiredErrorMessage),
});

const SendReportModal: FC<ISendReportModal> = ({ isOpen, onClose, onSubmit, isLoading }) => {
  const renderIntlMessage = useRenderIntlMessage();

  const initFormValues = {
    email: '',
  };

  const formMethods = useForm<any>({
    defaultValues: initFormValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = formMethods;

  return (
    <DialogComponent
      title={<FormattedMessage {...reportsMessages.exportReportTitle} />}
      submitBtnTitle={<FormattedMessage {...messages.exportBtn} />}
      size="xs"
      isOpen={isOpen}
      onClose={() => {
        reset({});
        onClose();
      }}
      onSubmit={handleSubmit(onSubmit)}
      disabled={isLoading}
    >
      <form>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              name={field.name}
              variant="outlined"
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              label={<FormattedMessage {...inputLabels.email} />}
              error={!!errors.email}
              helperText={renderIntlMessage(errors.email?.message)}
              inputProps={{
                autoComplete: 'off',
              }}
            />
          )}
        />
      </form>
    </DialogComponent>
  );
};

export default React.memo(SendReportModal);
