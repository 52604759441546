import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Controller, FieldArray } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
// custom interfaces components
import CheckBoxWithReset from 'common/components/CheckBoxWithReset/CheckBoxWithReset';
import { CustomTheme } from 'common/ui/interfaces';
import { NumberTextField, Select } from 'common/components/index';
import FeeInstanceSplitSection from './FeeInstanceSplitSection';
import { FeeType } from 'modules/services/interfaces/fees';

import {
  defaultNumberFormatProps,
  defaultPriceNumberProps,
} from 'common/components/NumberController/NumberController';
import { FeeTypeOptions } from 'modules/services/constants/fees';
import { FrequencyTypes } from 'modules/services/constants/packages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import inputLabels from 'common/messages/inputLabels';

interface IFeeInstanceProps {
  formName: string;
  packageFee: FieldArray;
  control: Control<any>;
  watch: any;
  errors: any;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  feeHeader: {
    marginLeft: 0,
    '& h5': {
      color: `${theme.palette.text.primary} !important`,
    },
  },
}));

const FeeInstance: React.FC<IFeeInstanceProps> = (props: IFeeInstanceProps): JSX.Element => {
  const classes = useStyles(props);
  const { formName, packageFee: pf, control, watch, errors } = props;
  const packageFee = pf as any; // TODO - PRM-1810 need types
  const renderIntlMessage = useRenderIntlMessage();
  const included = watch(`${formName}.included`);
  const isSplit = watch(`${formName}.split`);
  const isRegularFeeItem = packageFee.fee?.type === FeeType.Regular;

  const FeeTitle = ({ title, type }: { title: string; type: FeeType }): JSX.Element => (
    <>
      <Typography variant="h5">{title}</Typography>
      <Typography variant="caption" color="textSecondary">
        {FeeTypeOptions.translate(type)}
      </Typography>
    </>
  );

  return (
    <Grid item xs={12} key={packageFee.packageFeeKey}>
      {/* Title */}

      <Grid container wrap="nowrap" spacing={2} direction="column">
        <Grid item xs={12}>
          <Controller
            control={control}
            name={`${formName}.included`}
            defaultValue
            render={({ field }) => (
              <FormControlLabel
                labelPlacement="start"
                className={classes.feeHeader}
                label={<FeeTitle title={packageFee.fee?.title} type={packageFee.fee?.type} />}
                control={
                  <Switch
                    color="primary"
                    size="small"
                    checked={field.value}
                    disabled={!packageFee.excludable}
                    onChange={(e, checked) => field.onChange(checked)}
                  />
                }
              />
            )}
          />
        </Grid>

        {/* Config */}

        {included && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name={`${formName}.totalAmount`}
                  defaultValue={packageFee.totalAmount}
                  render={({ field }) => (
                    <NumberTextField
                      fullWidth
                      variant="outlined"
                      name={field.name}
                      value={field.value?.value}
                      label={<FormattedMessage {...inputLabels.amount} />}
                      numberFormatProps={defaultPriceNumberProps}
                      disabled={!field.value?.editable}
                      error={!!errors?.totalAmount}
                      helperText={renderIntlMessage(errors?.totalAmount?.value?.message, {
                        value: errors?.totalAmount?.value?.message?.value,
                      })}
                      onChange={value => field.onChange({ ...field.value, value })}
                      onBlur={field.onBlur}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  control={control}
                  name={`${formName}.chargeAfterDays`}
                  defaultValue={packageFee.chargeAfterDays}
                  render={({ field }) => (
                    <NumberTextField
                      fullWidth
                      variant="outlined"
                      name={field.name}
                      value={field.value?.value}
                      label={<FormattedMessage {...inputLabels.chargeAfterDays} />}
                      numberFormatProps={defaultNumberFormatProps}
                      disabled={!field.value?.editable}
                      error={!!errors?.chargeAfterDays}
                      helperText={renderIntlMessage(errors?.chargeAfterDays?.value?.message, {
                        value: errors?.chargeAfterDays?.value?.message?.value,
                      })}
                      onChange={value => field.onChange({ ...field.value, value })}
                      onBlur={field.onBlur}
                    />
                  )}
                />
              </Grid>

              {isRegularFeeItem && (
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name={`${formName}.paymentSchedule`}
                    defaultValue={packageFee.paymentSchedule}
                    render={({ field }) => (
                      <Select
                        fullWidth
                        variant="outlined"
                        label={<FormattedMessage {...inputLabels.paymentSchedule} />}
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        disabled
                        error={errors?.paymentSchedule}
                        helperText={renderIntlMessage(errors?.paymentSchedule?.message)}
                      >
                        {FrequencyTypes.getSelectOptions()}
                      </Select>
                    )}
                  />
                </Grid>
              )}

              {packageFee.fee?.type === FeeType.OneTime && packageFee.allowSplitting && (
                <>
                  <Grid item xs={4}>
                    <Controller
                      name={`${formName}.split`}
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <CheckBoxWithReset
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          label={<FormattedMessage {...inputLabels.splitPayment} />}
                        />
                      )}
                    />
                  </Grid>

                  {isSplit && (
                    <FeeInstanceSplitSection
                      control={control}
                      packageFee={packageFee}
                      formName={formName}
                      errors={errors}
                    />
                  )}
                </>
              )}

              <Controller
                control={control}
                name={`${formName}.fee`}
                defaultValue={packageFee.fee}
                render={() => null as any} // TODO - PRM-3575 need types
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(FeeInstance);
