import React from 'react';
import { Box, Divider, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';

import { conditionList } from 'common/constants/condition';

import RemoveIcon from '../RemoveIcon/RemoveIcon';
import { DateTimePicker, NumberTextField, Select } from 'common/components';

import { FormattedMessage, useIntl } from 'react-intl';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import campaigns from 'modules/crm/messages/campaigns';
import { Controller, FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';
import { ConstOptions } from 'common/constants/classes';
import { CampaignCounterType, ConditionType } from 'common/constants/campaign';
import { TConditionSettings } from 'common/interfaces/campaign';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { ICampaignForm } from '../../../interfaces/campaigns';

const durations = new ConstOptions([
  {
    message: commonMessages.minutesOption,
    value: CampaignCounterType.MINUTE,
  },
  {
    message: commonMessages.daysOption,
    value: CampaignCounterType.DAY,
  },
  {
    message: commonMessages.weeksOption,
    value: CampaignCounterType.WEEK,
  },
  {
    message: commonMessages.monthsOption,
    value: CampaignCounterType.MONTH,
  },
]);

const getConditionTitle = conditionSettings => {
  if (!conditionSettings?.message) {
    const listItem = conditionList.find(condition => condition.type === conditionSettings.type);

    if (listItem) {
      const { message } = listItem;
      return <FormattedMessage {...message} />;
    }
  }
  return <FormattedMessage {...conditionSettings?.message} />;
};

const ConditionsSection = (): JSX.Element => {
  const intl = useIntl();
  const { control, formState, setValue } = useFormContext<ICampaignForm>();
  const { errors } = formState;

  const { fields, remove } = useFieldArray<ICampaignForm, 'runningConditionList'>({
    control,
    name: 'runningConditionList',
  });

  const renderIntlMessage = useRenderIntlMessage();

  const renderCondition = (
    conditionSettings: FieldArrayWithId<ICampaignForm, 'runningConditionList'>,
    index: number,
  ) => {
    switch (conditionSettings.type) {
      case ConditionType.MEMBERS_CARDS_EXPIRING_IN_N_DAYS:
      case ConditionType.MEMBERS_EXPIRING_IN_N_DAYS:
      case ConditionType.UPCOMING_APPOINTMENTS_IN_N_DAYS_N_HOURS:
        return (
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Controller
                name={`runningConditionList.${index}.counter` as any}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <NumberTextField
                    numberFormatProps={{
                      maxLength: 3,
                    }}
                    onChange={onChange}
                    variant="outlined"
                    value={value}
                    fullWidth
                    size="small"
                    style={{ maxWidth: 60 }}
                    placeholder={intl.formatMessage(inputLabels.value)}
                    error={!!errors.runningConditionList?.[index]?.counter}
                    helperText={renderIntlMessage(
                      errors.runningConditionList?.[index]?.counter?.message,
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                name={`runningConditionList.${index}.counterType` as any} // TODO - PRM-3575 need types
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    className="select--small"
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    error={!!errors.runningConditionList?.[index]?.counterType}
                    helperText={renderIntlMessage(
                      errors.runningConditionList?.[index]?.counterType?.message,
                    )}
                  >
                    {durations.getSelectOptions()}
                  </Select>
                )}
              />
            </Grid>
          </Grid>
        );

      case ConditionType.SPECIFIC_DATE_AND_TIME:
        return (
          <DateTimePicker
            datePickerName={`runningConditionList.${index}.specificDate`}
            timePickerName={`runningConditionList.${index}.specificTime`}
            spacing={1}
            datePickerProps={{
              style: { maxWidth: 135 },
            }}
            timePickerProps={{
              style: { maxWidth: 95 },
            }}
            smallDatePicker
            smallTimePicker
            disablePast
          />
        );
      case ConditionType.LOW_USAGE_MEMBERS_CONTACTED_AFTER_XX:
      case ConditionType.USED_THEIR_PASS_LESS_THAN_X_NUMBER_OF_TIMES_WITHIN_THEIR_PASS_LIMITATIONS:
      case ConditionType.REGULAR_EACH_DAY_OR_NUMBER_OF_DAYS:
        return (
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Controller
                name={`runningConditionList.${index}.counter` as any} // TODO - PRM-3575 need types
                control={control}
                render={({ field: { value, onChange } }) => (
                  <NumberTextField
                    numberFormatProps={{
                      maxLength: 3,
                    }}
                    onChange={onChange}
                    variant="outlined"
                    value={value}
                    fullWidth
                    size="small"
                    style={{ maxWidth: 60 }}
                    error={!!errors.runningConditionList?.[index]?.counter}
                    helperText={renderIntlMessage(
                      errors.runningConditionList?.[index]?.counter?.message,
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  const renderConditions = () => {
    return fields.map((conditionSettings, index) => (
      <Grid key={conditionSettings.id} item xs={12}>
        <div style={{ padding: 16 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={5} container alignContent="center">
              <Typography component="span">{getConditionTitle(conditionSettings)}</Typography>
            </Grid>

            <Grid item xs={10} sm={6} style={{ marginRight: 'auto' }}>
              <Controller
                name={`runningConditionList.${index}.type`}
                control={control}
                defaultValue={
                  ('type' in conditionSettings ? conditionSettings.type : '') as ConditionType
                }
                render={(() => null) as any} // TODO - PRM-3575 need fix
              />
              {renderCondition(conditionSettings, index)}
            </Grid>

            <Grid item xs={1}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton size="small" onClick={() => remove(index)}>
                  <RemoveIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </div>
        <Divider />
      </Grid>
    ));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="button" color="textSecondary" gutterBottom>
          <FormattedMessage {...campaigns.runningConditions} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MuiAutocomplete
          multiple
          disableCloseOnSelect
          disableClearable
          value={fields.map(({ type }) => ({
            type,
          }))}
          options={conditionList}
          getOptionLabel={(option: any) => {
            if (option.message) return intl.formatMessage(option.message);
            return '';
          }}
          getOptionSelected={(option: TConditionSettings, value: TConditionSettings) =>
            value?.type === option?.type
          }
          filterSelectedOptions
          renderTags={() => <></>}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label={<FormattedMessage {...inputLabels.addCondition} />}
            />
          )}
          onChange={(e, conditions) => {
            const conditionToAppend = conditions.find(
              ({ type }) => !fields.find(condition => type === condition.type),
            );
            setValue('runningConditionList', [...fields, conditionToAppend]);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container>{renderConditions()}</Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(ConditionsSection);
