import React, { memo, useMemo } from 'react';
import { DialogComponent, StatusLabel, Table } from 'common/components/index';
import messages from 'common/components/PersonProfile/messages';
import { IFilterSettings } from 'common/interfaces/filter';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { IServisecHistoryItem } from 'modules/booking/interfaces';
import { IPageMeta } from 'common/interfaces/pagination';
import tableHeaders from 'common/messages/tableHeaders';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { Typography } from '@material-ui/core';
import ServicesHistoryPackageModal from '../ServicesHistoryPackageModal/ServicesHistoryPackageModal';
import { IBundleView } from 'modules/services/interfaces/packages';
import { IInventoryDetailedInfo } from 'modules/reports/interfaces/invoiceHistory';
import { DefaultStatus } from 'common/constants/campaign';
import IntlService from 'services/intl/IntlService';

const getHeaderOptions = (): IHeadCell[] => [
  {
    id: 'title',
    label: IntlService.formatMessage(tableHeaders.title),
    sort: false,
  },
  {
    id: 'date',
    label: IntlService.formatMessage(tableHeaders.sellDate),
    sort: true,
  },
  {
    id: 'startDate',
    label: IntlService.formatMessage(tableHeaders.startDate),
    sort: false,
  },
  {
    id: 'endDate',
    label: IntlService.formatMessage(tableHeaders.endDate),
    sort: false,
  },
  {
    id: 'salesperson',
    label: IntlService.formatMessage(tableHeaders.salesperson),
    sort: false,
  },
  {
    id: 'status',
    label: IntlService.formatMessage(tableHeaders.status),
    sort: true,
  },
];

interface IProps {
  onClose: () => void;
  packageId: string | null;
  inventoryId: string | null;
  servicesHistory?: {
    data: IServisecHistoryItem[];
    meta: IPageMeta;
  };
  isServicesHistoryLoading: boolean;
  setPackageId: (id: string | null) => void;
  setInventoryId: (id: string | null) => void;
  packageInfo?: IBundleView;
  isPackageInfoLoading: boolean;
  inventoryInfo?: IInventoryDetailedInfo;
  isInventoryInfoLoading: boolean;
  handleChangeTableProps: (tableProps: ITableParams) => void;
  tableParams: ITableParams;
  tableFilterSettings: IFilterSettings[];
}

const ServicesHistoryModal = ({
  onClose,
  inventoryId,
  packageId,
  setPackageId,
  setInventoryId,
  servicesHistory,
  isServicesHistoryLoading,
  packageInfo,
  isPackageInfoLoading,
  inventoryInfo,
  isInventoryInfoLoading,
  handleChangeTableProps,
  tableParams,
  tableFilterSettings,
}: IProps) => {
  const [timezoneMoment] = useTimezoneMoment();

  const dateTimeFormat = 'MMM D, YYYY, h:mm A';

  const rows = useMemo(
    () =>
      servicesHistory?.data
        ? servicesHistory.data.map(
            (item: IServisecHistoryItem): ITableRow => ({
              id: item.id.toString(),
              cells: [
                {
                  label: '',
                  cellComponent: (
                    <Typography
                      color="primary"
                      variant="button"
                      onClick={() => {
                        setPackageId(item.id);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {item.title}
                    </Typography>
                  ),
                },
                {
                  label: timezoneMoment(item.date).format(dateTimeFormat),
                },
                {
                  label: timezoneMoment(item.startDate).format(dateTimeFormat),
                },
                {
                  label: timezoneMoment(item.endDate).format(dateTimeFormat),
                },
                {
                  label: item.salesperson
                    ? `${item.salesperson?.firstName} ${item.salesperson?.lastName}`
                    : '-',
                },
                { label: <StatusLabel isActive={item.status === DefaultStatus.Active} /> },
              ],
            }),
          )
        : [],
    [servicesHistory, timezoneMoment, setPackageId],
  );

  return (
    <>
      <DialogComponent
        title={IntlService.formatMessage(messages.servicesHistoryTitle)}
        isOpen
        onClose={onClose}
        size="xl"
        hideFooterButtons
        loading={isServicesHistoryLoading}
      >
        <Table
          rows={rows}
          headerOptions={getHeaderOptions()}
          isLoading={isServicesHistoryLoading}
          page={servicesHistory?.meta.page}
          totalRows={servicesHistory?.meta.total}
          onChangeParams={handleChangeTableProps}
          tableParams={tableParams}
          filters={tableFilterSettings}
        />
      </DialogComponent>
      {!!packageId && (
        <ServicesHistoryPackageModal
          onClose={() => setPackageId(null)}
          packageInfo={packageInfo}
          isPackageInfoLoading={isPackageInfoLoading}
          setInventoryId={setInventoryId}
          inventoryId={inventoryId}
          inventoryInfo={inventoryInfo}
          isInventoryInfoLoading={isInventoryInfoLoading}
        />
      )}
    </>
  );
};

export default memo(ServicesHistoryModal);
