import React, { useCallback, useContext, useEffect, useRef } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { useNavigate } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { LoadingBackdrop } from 'common/components';
import TokenService from 'services/auth/TokenService';
import {
  selectCurrentUserSelectedLevel,
  selectCurrentUserLoading,
  selectIsUserLoggedIn,
  selectIsExistCurrentUser,
  selectCurrentUserCorporation,
} from 'modules/authentication/state/selectors';
import { selectAlertsListLoading } from 'common/state/alerts/selectors';
import { fetchUserProfile, logOut } from 'modules/authentication/state/actions';
import { fetchAlertsList } from 'common/state/alerts/actions';
import { useAppDispatch } from 'store/hooks';
import useZendeskWidget from 'common/hooks/useZendeskWidget';
import { HOME_PATH, SIGN_IN_PATH } from 'common/constants/globalConstants';
import { NavigationContext } from 'common/createContext/navigationContext';
import { PermissionLevels } from 'common/constants/permissions';
import { fetchPeakSystemSettings, fetchProfileFieldsInfo } from 'common/state/settings/actions';
import { selectProfileFieldsInfo } from 'common/state/settings/selectors';

interface ICustomRouteProps {
  secure?: boolean;
  publicAccess?: boolean;
  hideNavigation?: boolean;
  children: React.ReactElement;
  levels: PermissionLevels[]; // Displays on which levels should we show the page
}

const CustomRoute = ({
  secure,
  levels,
  publicAccess,
  hideNavigation,
  children,
}: ICustomRouteProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const isInitialDataFetched = useRef<boolean>(false);
  const navigate = useNavigate();
  const { toggleShowNavigation } = useContext(NavigationContext);

  const isExistProfile = useRootSelector(selectIsExistCurrentUser);
  const isProfileLoading: boolean = useRootSelector(selectCurrentUserLoading());
  const isAlertsListLoading: boolean = useRootSelector(selectAlertsListLoading);
  const isUserLoggedIn: boolean = useRootSelector(selectIsUserLoggedIn);
  const userLevel: PermissionLevels = useRootSelector(selectCurrentUserSelectedLevel);
  const currentUserCorporation = useRootSelector(selectCurrentUserCorporation);
  const profileFieldsInfo = useRootSelector(selectProfileFieldsInfo);

  const routePreActions = useCallback(() => {
    const refreshToken = TokenService.getRefreshToken();
    const isRefreshExpired = TokenService.isTokenExpired(refreshToken);

    if (isRefreshExpired && isUserLoggedIn) {
      dispatch(logOut());
    }

    if (secure && !publicAccess && !isUserLoggedIn) {
      navigate(SIGN_IN_PATH);
      return;
    }

    if (
      !isInitialDataFetched.current &&
      secure &&
      isUserLoggedIn &&
      !isExistProfile &&
      !isRefreshExpired &&
      !isProfileLoading
    ) {
      isInitialDataFetched.current = true;
      dispatch(fetchUserProfile());
      dispatch(fetchAlertsList());
      dispatch(fetchPeakSystemSettings());
    }

    if (currentUserCorporation?.get('id') && !profileFieldsInfo?.size) {
      dispatch(fetchProfileFieldsInfo(currentUserCorporation.get('id')));
    }

    if (secure && userLevel && levels && !levels.includes(userLevel)) {
      navigate(HOME_PATH);
    }
  }, [
    isUserLoggedIn,
    secure,
    isExistProfile,
    isProfileLoading,
    publicAccess,
    userLevel,
    levels,
    dispatch,
    navigate,
    currentUserCorporation,
    profileFieldsInfo?.size,
  ]);

  useEffect(() => {
    routePreActions();

    if (hideNavigation) {
      toggleShowNavigation(false);
    }
  }, [hideNavigation, routePreActions, toggleShowNavigation]);

  useEffect(() => {
    if (secure && !isUserLoggedIn && !publicAccess) {
      navigate(SIGN_IN_PATH);
    }

    if (levels && userLevel && !levels.includes(userLevel)) {
      navigate(HOME_PATH);
    }
  }, [isUserLoggedIn, levels, navigate, secure, userLevel, publicAccess]);

  useZendeskWidget();

  const canRenderRouter = !secure || (secure && isExistProfile) || publicAccess;

  return (
    <Box height="100%">
      {/*  {canRenderRouter && <Route path={path} element={element} />} */}
      {canRenderRouter && children}
      {(isProfileLoading || isAlertsListLoading) && <LoadingBackdrop isLoading />}
    </Box>
  );
};

export default CustomRoute;
