import React from 'react';
import {
  Box,
  alpha,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SvgIcon,
  Theme,
} from '@material-ui/core';
import { AllowedTo } from 'common/components';
import { snackbar } from 'common/utils/snackbarUtils';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { PageRouteSetting } from 'components/Sidebar/constants';
import { commonRoutes } from './constants';
import CustomNavLink from 'components/Sidebar/CustomNavLink';

interface ISidebarRoutesProps {
  isTextVisible: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  routesList: {
    margin: 0,
    padding: theme.spacing(2, 1),
    overflowX: 'hidden',
  },
  routeItem: {
    borderRadius: 3,
    height: 40,
    width: '100%',
    padding: 11,
    color: alpha(theme.palette.common.white, 0.8),
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
  routeItemIcon: {
    margin: '0 auto 0 0',
    '& svg': {
      color: theme.palette.common.white,
      height: 20,
    },
  },
  routeItemTextWrapper: {
    overflow: 'hidden',
    marginLeft: theme.spacing(1.5),
    flex: 1,
  },
  routeItemText: {
    color: theme.palette.common.white,
    fontWeight: 700,
  },
  divider: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    margin: theme.spacing(1, 0),
  },
  groupTitle: {
    color: alpha(theme.palette.common.white, 0.45),
    fontWeight: 700,
    margin: theme.spacing(2.5, 0, 1, 1.5),
  },
  groupTitleClosed: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    fontSize: 0,
    margin: theme.spacing(2.75, 0),
  },
}));

const MemberPortalSidebarRoutes = ({ isTextVisible }: ISidebarRoutesProps): JSX.Element => {
  const classes = useStyles();

  const renderIntlMessage = useRenderIntlMessage();

  // TODO now we use permissions level instead permissions set
  // neeed to use permissions set in future
  return (
    <List className={classes.routesList}>
      {commonRoutes.map(({ isDivider, isDisabled, path, title, icon }: PageRouteSetting) => {
        if (isDivider) {
          return <Box key={title.id} className={classes.divider} />;
        }

        const listItemTitle = renderIntlMessage(title);

        return (
          <ListItem
            key={title.id}
            title={listItemTitle}
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            component={CustomNavLink}
            to={path}
            className={classes.routeItem}
            onClick={event => {
              if (isDisabled) {
                event.preventDefault();
                event.stopPropagation();
                snackbar.warning('Not implemented yet on this permissions level.');
              }
            }}
          >
            <ListItemIcon className={classes.routeItemIcon}>
              {icon ? <SvgIcon viewBox="0 0 20 20" component={icon} /> : null}
            </ListItemIcon>

            <AllowedTo perform={isTextVisible}>
              <ListItemText
                className={classes.routeItemTextWrapper}
                primary={listItemTitle}
                primaryTypographyProps={{ className: classes.routeItemText }}
              />
            </AllowedTo>
          </ListItem>
        );
      })}
    </List>
  );
};

export default MemberPortalSidebarRoutes;
