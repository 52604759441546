import { validators } from 'common/constants/validators';
import * as yup from 'yup';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';
import { getRequiredErrorMessage } from '../utils/validation';

export const addressSchema = yup.object().shape({
  // TODO uncomment after adding countries
  /* country: yup
    .mixed()
    .nullable()
    .notOneOf(['', null, undefined], requiredMessage)
    .required(requiredMessage), */
  state: yup
    .mixed()
    .nullable()
    .notOneOf(['', null], getRequiredErrorMessage)
    .required(getRequiredErrorMessage),
  city: yup
    .string()
    .nullable()
    .required(getRequiredErrorMessage),
  line1: yup
    .string()
    .nullable()
    .required(getRequiredErrorMessage),
  zipCode: validators.ZIP_CODE,
});

export const getAddressSchema = (requiredFields: TShortProfileFieldInfoImt) => {
  return yup.object().shape({
    state: yup
      .mixed()
      .nullable()
      .when(RequiredProfileInfo.Address, {
        is: () => requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required,
        then: schema => schema.required(getRequiredErrorMessage),
        otherwise: schema => schema,
      }),
    city: yup
      .string()
      .nullable()
      .when(RequiredProfileInfo.Address, {
        is: () => requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required,
        then: schema => schema.required(getRequiredErrorMessage),
        otherwise: schema => schema,
      }),
    line1: yup
      .string()
      .nullable()
      .when(RequiredProfileInfo.Address, {
        is: () => requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required,
        then: schema => schema.required(getRequiredErrorMessage),
        otherwise: schema => schema,
      }),
    zipCode: yup
      .string()
      .nullable()
      .when((val, schema) => {
        if (requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required) {
          return validators.ZIP_CODE.required(getRequiredErrorMessage);
        }

        if (val?.trim() === '') {
          return schema;
        }

        return validators.ZIP_CODE;
      }),
  });
};

export const getAlertAddressSchema = (requiredFields: TShortProfileFieldInfoImt) => {
  return yup.object({
    line1: yup
      .string()
      .defined()
      .when(RequiredProfileInfo.Address, {
        is: () => requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required,
        then: schema => schema.required(getRequiredErrorMessage),
        otherwise: schema => schema,
      }),
    line2: yup
      .string()
      .defined()
      .when(RequiredProfileInfo.Address, {
        is: () => requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required,
        then: schema => schema.required(getRequiredErrorMessage),
        otherwise: schema => schema,
      }),
    zipCode: yup
      .string()
      .defined()
      .when((val, schema) => {
        if (requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required) {
          return validators.ZIP_CODE.required(getRequiredErrorMessage);
        }

        if (val?.trim() === '') {
          return schema;
        }

        return validators.ZIP_CODE;
      }),
    city: yup
      .string()
      .defined()
      .when(RequiredProfileInfo.Address, {
        is: () => requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required,
        then: schema => schema.required(getRequiredErrorMessage),
        otherwise: schema => schema,
      }),
    country: yup
      .object()
      .shape({
        id: yup.string(),
        imageUrl: yup.string().optional(),
        title: yup.string(),
        active: yup.boolean().optional(),
      })
      .nullable()
      .when(RequiredProfileInfo.Address, {
        is: () => requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required,
        then: schema => schema.required(getRequiredErrorMessage),
        otherwise: schema => schema,
      }),
    state: yup
      .object()
      .shape({
        id: yup.string(),
        imageUrl: yup.string().optional(),
        title: yup.string(),
        active: yup.boolean().optional(),
      })
      .nullable()
      .when(RequiredProfileInfo.Address, {
        is: () => requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required,
        then: schema => schema.required(getRequiredErrorMessage),
        otherwise: schema => schema,
      }),
  });
};
