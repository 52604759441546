import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { formatPrice } from 'common/utils';

import {
  BillingTypes,
  PackageTypes,
  PaymentStatuses,
} from 'modules/reports/constants/scheduledBillingsReport';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';

import { IPageMetaImt } from 'common/interfaces/pagination';
import {
  IScheduledBillingItemImt,
  PaymentStatus,
} from 'modules/reports/interfaces/scheduledBillingsReport';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';

import { Table } from 'common/components';

import tableHeaders from 'common/messages/tableHeaders';
import commonMessages from 'common/messages/messages';
import { useNavigate } from 'react-router-dom';
import TableProfileLink from 'common/components/Table/TableProfileLink/TableProfileLink';

const useStyles = makeStyles(() => ({
  sbReportTableContainer: {
    '& .MuiBox-root[class*="item"]': {
      paddingBottom: 0,
    },
    '& .MuiGrid-root[class*="toolbarContainer"]': {
      margin: 0,
      width: 'auto',
    },
  },
}));

interface IProps {
  scheduledBillingsReportItemsList: ImmutableList<IScheduledBillingItemImt>;
  isScheduledBillingsReportItemsListLoading: boolean;
  scheduledBillingsReportItemsListMeta: IPageMetaImt;
  onChangeTableParams: (tableParams: ITableParams) => void;
  tableParams: ITableParams;
}

const headerOptions: IHeadCell[] = [
  {
    id: 'number',
    label: <FormattedMessage {...tableHeaders.invoiceNumberShort} />,
    sort: true,
  },
  { id: 'date', label: <FormattedMessage {...tableHeaders.date} />, sort: true },
  { id: 'name', label: <FormattedMessage {...tableHeaders.name} />, sort: true },
  { id: 'billing', label: <FormattedMessage {...commonMessages.billingLabel} />, sort: true },
  { id: 'type', label: <FormattedMessage {...tableHeaders.type} />, sort: true },
  { id: 'member', label: <FormattedMessage {...tableHeaders.member} />, sort: true },
  { id: 'price', label: <FormattedMessage {...tableHeaders.price} />, sort: true },
  { id: 'status', label: <FormattedMessage {...tableHeaders.status} />, sort: true },
];

const BillingsReportItemsTable = ({
  scheduledBillingsReportItemsList,
  isScheduledBillingsReportItemsListLoading,
  scheduledBillingsReportItemsListMeta,
  onChangeTableParams,
  tableParams,
}: IProps): JSX.Element => {
  const navigate = useNavigate();
  const [timezoneMoment] = useTimezoneMoment();
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();

  const handleChangeTableProps = useCallback(
    (tableProps: ITableParams): void => {
      onChangeTableParams(tableProps);
    },
    [onChangeTableParams],
  );

  const rows = useMemo(
    () =>
      scheduledBillingsReportItemsList
        .map(
          (billingItem): ITableRow => {
            const member = billingItem.getIn(['member']).toJS();
            return {
              id: billingItem.get('id'),
              cells: [
                {
                  label: '',
                  className: 'body-cell',
                  cellComponent: (
                    <>
                      {billingItem.get('number') ? (
                        <Typography
                          color="primary"
                          style={{ cursor: 'pointer' }}
                          component="p"
                          variant="h5"
                          onClick={() =>
                            navigate(`/reports/invoice-history/${billingItem.get('invoiceId')}`)
                          }
                        >
                          {billingItem.get('number')}
                        </Typography>
                      ) : (
                        '-'
                      )}
                    </>
                  ),
                },
                {
                  label: timezoneMoment(billingItem.get('date')).format(DEFAULT_DATE_TIME_FORMAT),
                  className: 'body-cell',
                },
                { label: billingItem.get('title'), className: 'body-cell' },
                {
                  label: renderIntlMessage(BillingTypes.message(billingItem.get('type'))) || '',
                  className: 'body-cell',
                },
                {
                  label:
                    renderIntlMessage(PackageTypes.message(billingItem.get('packageType'))) || '',
                  className: 'body-cell',
                },
                {
                  label: '',
                  className: 'body-cell',
                  cellComponent: (
                    <TableProfileLink
                      to={`/members/${member.id}`}
                      id={member.id}
                      title={`${member.firstName} ${member.lastName}`}
                    />
                  ),
                },
                { label: formatPrice(billingItem.get('amount')), className: 'body-cell' },
                {
                  label: '',
                  className: 'body-cell',
                  cellComponent: (
                    <Typography
                      color={billingItem.get('status') === PaymentStatus.PAID ? 'primary' : 'error'}
                      component="p"
                      variant="h5"
                    >
                      {renderIntlMessage(PaymentStatuses.message(billingItem.get('status')))}
                    </Typography>
                  ),
                },
              ],
            };
          },
        )
        .toJS(),
    [navigate, renderIntlMessage, scheduledBillingsReportItemsList, timezoneMoment],
  );

  return (
    <Box height="auto" className={classes.sbReportTableContainer}>
      <Table
        showPerPageSelect
        rows={rows}
        headerOptions={headerOptions}
        hideSearchInput
        isLoading={isScheduledBillingsReportItemsListLoading}
        totalRows={scheduledBillingsReportItemsListMeta?.get('total')}
        onChangeParams={handleChangeTableProps}
        tableParams={tableParams}
      />
    </Box>
  );
};

export default BillingsReportItemsTable;
