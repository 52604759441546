import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { List as ImmutableList } from 'immutable';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, makeStyles, SvgIcon, Typography } from '@material-ui/core';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';
import useRootSelector from 'common/hooks/useRootSelector';
import * as actions from 'common/components/PersonProfile/state/actions';
import {
  addCheckingSavingsDataActionResult,
  addCheckingSavingsDataThunk,
} from 'common/components/PersonProfile/state/actions';
import * as selectors from 'common/components/PersonProfile/state/selectors';
import {
  selectAddCheckingSavingsDataActionResult,
  selectAddCheckingSavingsDataLoading,
} from 'common/components/PersonProfile/state/selectors';

import {
  IPackageInstanceSubscription,
  IPackageInstanceSubscriptionImt,
  IPackageInstanceSubscriptionUpdateDTO,
  IPaymentAccountImt,
} from 'common/components/PersonProfile/interfaces';
import { CustomTheme } from 'common/ui/interfaces';
import { AlertCondition, AlertTypes } from 'common/interfaces/alerts';
import { IPaymentMethodItemImt } from 'modules/pos-settings/interfaces/paymentMethods';
import { ActionResult } from 'common/constants';
import { usePersonSelectorTemplate } from 'common/components/PersonProfile/hooks/usePersonSelector';
import { getCreditCardIcon } from 'common/components/CreditCardData/constants';
import { getExpiryDate } from 'common/utils/time';
import { Button, DialogComponent, LoadingBackdrop } from 'common/components';
import SubscriptionsTable from './SubscriptionsTable';
import messages from 'common/components/PersonProfile/messages';
import inputErrors from 'common/messages/inputErrors';
import commonMessages from 'common/messages/messages';
import AddCheckingSavingsModal from '../AddCheckingSavingsModal/AddCheckingSavingsModal';
import CheckingSavingsItem from '../../components/CheckingSavingsItem/CheckingSavingsItem';
import { PaymentsType } from 'common/interfaces/invoices';
import { PeakModules } from 'common/constants/peakModules';
import { BankCode } from '../../../../../modules/corporate-settings/interfaces';
import {
  selectCurrentPaymentProcessor,
  selectCurrentUserCorporation,
} from '../../../../../modules/authentication/state/selectors';
import TokenizeCreditCard from '../../../TokenizeCreditCard/TokenizeCreditCard';
import { snackbar } from '../../../../utils/snackbarUtils';
import Alert from '../../../Alert/Alert';
import { ReactComponent as TrashIcon } from 'img/icons/trash_deprecated.svg';
import { getRequiredErrorMessage } from 'common/utils/validation';
import { colors } from 'common/ui/theme/default';

const useStyles = makeStyles((theme: CustomTheme) => ({
  modal: {
    padding: '24px',
    '& .MuiDialogTitle-root': {
      padding: '24px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .btn-close': {
        position: 'unset',
        padding: '5px',
        '&:hover': {
          backgroundColor: colors.buttons.contained.active,
        },
      },
    },
    '& .modal-content': {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    '& .MuiDialogActions-root': {
      padding: '24px',
    },
  },
  noPaymentMethodsBox: {
    height: '200px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardNumber: {
    margin: theme.spacing(0, 1, 0, 1.5),
  },
  expDate: {
    marginRight: theme.spacing(1),
  },
  expTitle: {
    marginLeft: theme.spacing(1),
  },
  addIcon: {
    width: '16px',
    height: '16px',
  },
  addButton: {
    marginTop: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.63),
  },
  btnRemove: {
    padding: '4px',
    '& span': {
      width: '16px',
      height: '16px',
    },
    '& svg': {
      margin: '0 -1px 0 0',
    },
  },
  cardUsed: {
    marginLeft: theme.spacing(1.25),
  },
}));

const ValidationSchema = yup.object().shape({
  subscriptions: yup.array().of(
    yup.object().shape({
      paymentMethod: yup
        .object()
        .nullable()
        .required(getRequiredErrorMessage),
      paymentAccount: yup
        .object()
        .when('paymentMethod', {
          is: paymentMethod => paymentMethod?.type === PaymentsType.CREDIT_CARD,
          then: yup
            .object()
            .test(
              'isInactiveCreditCard',
              () => inputErrors.inactiveCardError,
              value => {
                if (value) {
                  return !value.deleted;
                }

                return true;
              },
            )
            .required(getRequiredErrorMessage)
            .nullable(),
          otherwise: yup.object().nullable(),
        })
        .test(
          'expired',
          () => inputErrors.expiredCardError,
          value => {
            if (value) {
              return !value.expired;
            }

            return true;
          },
        ),
      checking: yup.object().when('paymentMethod', {
        is: paymentMethod => paymentMethod?.type === PaymentsType.CHECKING_SAVINGS,
        then: yup
          .object()
          .test(
            'isInactiveChecking',
            () => inputErrors.inactiveCheckingSavingsError,
            value => {
              if (value) {
                return !value.deleted;
              }

              return true;
            },
          )
          .nullable()
          .required(getRequiredErrorMessage),
        otherwise: yup.object().nullable(),
      }),
    }),
  ),
});

// TODO - PRM-1810 tmp form interface
interface IFormValues {
  subscriptions: IPackageInstanceSubscription[];
}

interface IPaymentMethodsModal {
  personId: number;
  onSuccess: () => void;
  onCancel: () => void;
  module?: PeakModules;
  alert?: AlertCondition;
}

const PaymentMethodsModal: FC<IPaymentMethodsModal> = ({
  personId,
  onSuccess,
  onCancel,
  alert,
  module,
}) => {
  const dispatch = useDispatch();
  const usePersonSelector = usePersonSelectorTemplate(personId);

  const storedCreditCards: ImmutableList<IPaymentAccountImt> = usePersonSelector(
    selectors.selectStoredCreditCards,
  );
  const personSubscriptions: ImmutableList<IPackageInstanceSubscriptionImt> = usePersonSelector(
    selectors.selectPersonSubscriptions,
  );
  const paymentMethods: ImmutableList<IPaymentMethodItemImt> = usePersonSelector(
    selectors.selectPersonPaymentMethods,
  );
  const isPaymentMethodsModalDataLoading: boolean = usePersonSelector(
    selectors.selectPaymentMethodsModalDataLoading,
  );
  const addCreditCardResult: ActionResult = usePersonSelector(selectors.selectAddCreditCardResult);
  const addCreditCardResultLoading: boolean = usePersonSelector(
    selectors.selectAddCreditCardResultLoading,
  );
  const deleteCardResult: ActionResult = usePersonSelector(selectors.selectDeleteCardResult);
  const deleteCardResultLoading: boolean = usePersonSelector(
    selectors.selectDeleteCardResultLoading,
  );
  const updateSubscriptionsLoading: boolean = usePersonSelector(
    selectors.selectUpdatePersonSubscriptionsActionLoading,
  );
  const updateSubscriptionsActionResult: ActionResult = usePersonSelector(
    selectors.selectUpdatePersonSubscriptionsActionResult,
  );
  const checkingSavingsData = useRootSelector(selectors.selectCheckingSavingsData(personId));
  const isFetchCheckingSavingsDataLoading = useRootSelector(
    selectors.selectFetchCheckingSavingsDataLoading(personId),
  );
  const isDeleteCheckingSavingsDataLoading = useRootSelector(
    selectors.selectDeleteCheckingSavingsItemLoading(personId),
  );
  const checkingSavingsDeleteActionResult = useRootSelector(
    selectors.selectDeleteCheckingSavingsItemActionResult(personId),
  );
  const isAddCheckingSavingsDataLoading = useRootSelector(
    selectAddCheckingSavingsDataLoading(personId),
  );
  const checkingSavingsAddActionResult = useRootSelector(
    selectAddCheckingSavingsDataActionResult(personId),
  );
  const paymentProcessorType = useRootSelector(selectCurrentPaymentProcessor);
  const currentCorporationId = useRootSelector(selectCurrentUserCorporation)?.get('id');

  const [isNewCardInputDisabled, setIsNewCardInputDisabled] = useState<boolean>(false);
  const [isOpenCheckingSavingsModal, setIsOpenCheckingSavingsModal] = useState<boolean>(false);
  const [isIFrameModalOpen, setIsIFrameModalOpen] = useState<boolean>(false);

  const formMethods = useForm<IFormValues>({
    defaultValues: { subscriptions: [] },
    resolver: yupResolver(ValidationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onChange',
  });

  const { reset, handleSubmit } = formMethods;
  const classes = useStyles();

  useEffect(() => {
    if (personId) {
      dispatch(actions.fetchPaymentMethodsModalData(personId, module));
    }

    return () => {
      dispatch(actions.resetPersonSubscriptionsAction(null, personId));
      dispatch(actions.resetCheckingSavingsData(null, personId));
    };
  }, [dispatch, personId, module]);

  useEffect(() => {
    if (checkingSavingsAddActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(addCheckingSavingsDataActionResult(null, personId));
      setIsOpenCheckingSavingsModal(false);
    }
  }, [setIsOpenCheckingSavingsModal, personId, dispatch, checkingSavingsAddActionResult]);

  useEffect(() => {
    if (addCreditCardResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.fetchPersonStoredCreditCards(personId));
      dispatch(actions.storeCreditCardResultAction(null, personId));

      if (alert) {
        dispatch(actions.resolvePersonMissingBillingInfoAction(alert, personId));
      }

      setIsNewCardInputDisabled(false);
    }
  }, [addCreditCardResult, alert, dispatch, personId]);

  useEffect(() => {
    if (updateSubscriptionsActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.resetUpdatePersonSubscriptionsActionResult(null, personId));
      onSuccess();
    }
  }, [dispatch, onSuccess, personId, updateSubscriptionsActionResult]);

  useEffect(() => {
    if (deleteCardResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.resetDeletePaymentMethodCardResultAction(null, personId));
    }

    if (deleteCardResult === ActionResult.SUCCESS_ACTION && !storedCreditCards.size) {
      dispatch(actions.fetchMemberAlerts(personId));
    }
  }, [deleteCardResult, dispatch, personId, storedCreditCards]);

  useEffect(() => {
    if (personSubscriptions?.size) {
      reset({ subscriptions: personSubscriptions.toJS() });
    }
  }, [personSubscriptions, reset]);

  const handleDeleteCard = cardId => {
    dispatch(actions.deletePaymentMethodCard(personId, cardId, module));
  };

  const onSubmitForm = formValues => {
    if (formValues.subscriptions?.length) {
      const transformedDTO: IPackageInstanceSubscriptionUpdateDTO[] = formValues.subscriptions.map(
        ({
          packageInstance,
          paymentMethod,
          paymentAccount,
          checking,
          secondaryPaymentAccount,
          secondaryPaymentMethod,
          secondaryChecking,
        }: IPackageInstanceSubscription) => {
          return {
            paymentAccountId: checking?.id || paymentAccount?.id || null,
            packageInstanceId: packageInstance.packageInstanceId,
            paymentMethodId: paymentMethod.id,
            secondaryPaymentAccountId: secondaryChecking?.id || secondaryPaymentAccount?.id || null,
            secondaryPaymentMethodId: secondaryPaymentMethod?.id,
          };
        },
      );

      dispatch(actions.updatePersonSubscriptions(personId, transformedDTO, module));
    } else {
      onSuccess();
    }
  };

  const onAddCheckingSavingsSubmit = values => {
    dispatch(addCheckingSavingsDataThunk(personId, values, module));
  };

  const onDeleteCheckingSavingsItem = (checkingId: string) => {
    dispatch(actions.deleteCheckingSavingsItemThunk(personId, checkingId, module));
  };

  const handleSubmitTokenize = (data: any): void => {
    switch (paymentProcessorType) {
      case BankCode.COMMERCEHUB:
        dispatch(actions.tokenizeCommerceHubEncryptedCard(personId, data));
        break;
      case BankCode.ITRANSACT:
        // we do nothing, because we don't have response for the action.
        // ITransact will trigger backend api endpoint with memberId and xid to tokenize cc
        break;
      default:
        snackbar.warning(<FormattedMessage {...commonMessages.creditCardBankNotConfigured} />);
    }

    setIsIFrameModalOpen(false);
  };

  return (
    <DialogComponent
      isOpen
      className={classes.modal}
      size="lg"
      onSubmit={handleSubmit(onSubmitForm)}
      onClose={onCancel}
      title={<FormattedMessage {...messages.paymentMethodsModalTitle} />}
      formId="subscriptions-form"
    >
      <Typography component="p" variant="button" color="textSecondary">
        <FormattedMessage {...messages.creditCardsLabel} />
      </Typography>

      {storedCreditCards?.map(cardItem => {
        return (
          <Box
            key={cardItem.get('id')}
            display="flex"
            alignItems="center"
            mt={2}
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <SvgIcon
                fontSize="large"
                component={getCreditCardIcon(cardItem.get('creditCardType'))}
              />
              <Typography className={classes.cardNumber}>{`**** ${cardItem.get(
                'lastFour',
              )}`}</Typography>

              <Typography className={classes.expDate}>
                <Typography component="span" color={cardItem.get('expired') ? 'error' : 'initial'}>
                  {getExpiryDate(cardItem.get('expDate'))}{' '}
                </Typography>
                {cardItem.get('expired') && (
                  <Typography component="span" color="error" className={classes.expTitle}>
                    <FormattedMessage {...commonMessages.expired} />
                  </Typography>
                )}
                {cardItem.get('inUse') && (
                  <Typography component="span" className={classes.cardUsed} color="textSecondary">
                    <FormattedMessage {...messages.cardUsedInPaymentsMessage} />
                  </Typography>
                )}
              </Typography>
            </Box>

            <div>
              <Button
                className={classes.btnRemove}
                disabled={isNewCardInputDisabled}
                color="secondary"
                size="small"
                onClick={() => handleDeleteCard(cardItem.get('id'))}
              >
                <TrashIcon />
              </Button>
            </div>
          </Box>
        );
      })}

      {!paymentProcessorType ? (
        <Alert
          title={<FormattedMessage {...commonMessages.creditCardBankNotConfigured} />}
          severity={AlertTypes.Warning}
        />
      ) : (
        <Button
          startIcon={<AddIcon className={classes.addIcon} />}
          color="primary"
          onClick={() => setIsIFrameModalOpen(true)}
          className={classes.addButton}
        >
          <FormattedMessage {...commonMessages.addCreditCardLabel} />
        </Button>
      )}

      {isIFrameModalOpen && (
        <TokenizeCreditCard
          paymentProcessorType={paymentProcessorType}
          corporationId={currentCorporationId}
          memberId={personId}
          isOpen={isIFrameModalOpen}
          onClose={() => setIsIFrameModalOpen(false)}
          onSubmit={data => handleSubmitTokenize(data)}
        />
      )}

      <Box mt={3}>
        <Typography component="p" variant="button" color="textSecondary">
          <FormattedMessage {...commonMessages.checkingSavingsLabel} />
        </Typography>

        {Boolean(checkingSavingsData?.size) && (
          <Box mt={2}>
            {checkingSavingsData?.map(item => (
              <CheckingSavingsItem
                onDelete={() => onDeleteCheckingSavingsItem(item.get('id'))}
                item={item}
              />
            ))}
          </Box>
        )}

        <Button
          startIcon={<AddIcon className={classes.addIcon} />}
          color="primary"
          onClick={() => setIsOpenCheckingSavingsModal(true)}
          className={classes.addButton}
        >
          <FormattedMessage {...commonMessages.addCheckingSavingsLabel} />
        </Button>
      </Box>

      <Box mt={3}>
        <Typography variant="button" color="textSecondary">
          <FormattedMessage {...messages.regularPaymentsLabel} />
        </Typography>

        <FormProvider {...formMethods}>
          <form id="subscriptions-form">
            <SubscriptionsTable
              isNewCardInputDisabled={isNewCardInputDisabled}
              paymentMethods={paymentMethods}
              storedCreditCards={storedCreditCards}
              deleteCardActionResult={deleteCardResult}
              checkingSavingsDeleteActionResult={checkingSavingsDeleteActionResult}
              checkingSavingsData={checkingSavingsData}
              personId={personId}
              setIsOpenAddCreditCardModal={setIsIFrameModalOpen}
              setIsOpenCheckingSavingsModal={setIsOpenCheckingSavingsModal}
            />
          </form>
        </FormProvider>
      </Box>
      <LoadingBackdrop
        isLoading={
          isPaymentMethodsModalDataLoading ||
          addCreditCardResultLoading ||
          deleteCardResultLoading ||
          updateSubscriptionsLoading ||
          isFetchCheckingSavingsDataLoading ||
          isDeleteCheckingSavingsDataLoading
        }
      />

      {isOpenCheckingSavingsModal && (
        <AddCheckingSavingsModal
          onSubmit={onAddCheckingSavingsSubmit}
          isOpen={isOpenCheckingSavingsModal}
          onClose={() => setIsOpenCheckingSavingsModal(false)}
          isLoading={isAddCheckingSavingsDataLoading}
        />
      )}
    </DialogComponent>
  );
};

export default PaymentMethodsModal;
