import React from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { ThemeProvider } from '@material-ui/core';

import { CustomTheme } from 'common/ui/interfaces';
import { createTheme, colors, createPalette } from 'common/ui/theme/default';
import { selectSelectedOrganizationColor } from 'modules/authentication/state/selectors';
import * as selectors from 'modules/peak-settings/state/selectors';
import { selectUserRole } from 'common/state/userRole/selectors';
import { selectMemberOrganizationColor } from 'memberPortalModules/authentication/state/selectors';

interface IProps {
  children: React.ReactNode;
}

const ThemeProviderWrapper = ({ children }: IProps): JSX.Element => {
  const userRole = useRootSelector(selectUserRole);
  const isClientPortal = userRole === 'client';

  const orgPrimaryColor = useRootSelector(
    isClientPortal ? selectSelectedOrganizationColor : selectMemberOrganizationColor,
  );
  const peakPrimaryColor: string = useRootSelector(selectors.selectPeakPrimaryColor);

  const palette = createPalette(orgPrimaryColor || peakPrimaryColor || colors.primary);
  const theme: CustomTheme = createTheme(palette);

  return <ThemeProvider<CustomTheme> theme={theme}>{children}</ThemeProvider>;
};

export default ThemeProviderWrapper;
