import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import FilterMenuBody from 'common/components/FiltersContainer/FilterMenuBody/FilterMenuBody';
import PaginatedFilterBody from 'common/components/FiltersContainer/PaginatedFilterBody/PaginatedFilterBody';
import DropdownFilter from 'common/components/FiltersContainer/DropdownFilter/DropdownFilter';
import HoverFilter from 'common/components/FiltersContainer/HoverFilter/HoverFilter';
import {
  FilterViewTypes,
  IFilterOption,
  IMultipleWithPaginateFilterProps,
} from 'common/interfaces/filter';
import messages from 'common/components/FiltersContainer/messages';

const useStyles = makeStyles(() => ({
  loader: {
    '& svg': {
      margin: 0,
    },
  },
  hoverFilter: {
    '& .MuiMenu-list': {
      paddingTop: '0 !important',
    },
  },
}));

const MultipleFilterWithPaginate = (props: IMultipleWithPaginateFilterProps): JSX.Element => {
  const classes = useStyles();
  const {
    viewType,
    value,
    settings: { name, title, type, fetchList, perPage, isLoading, fetchItemTitle, params },
    dialog,
    onChange,
  } = props;

  const initialState = value || [];
  const [selectedOptions, setSelectedOptions] = useState<IFilterOption[]>(initialState);
  const [firstOption, setFirstOption] = useState<IFilterOption | null>(null);
  const fetchItemTitleRef = useRef(fetchItemTitle);
  const valueRef = useRef(value);

  const selectedTotal = selectedOptions.length;
  const isOptionSelected = (option: IFilterOption): boolean =>
    selectedOptions.findIndex(opt => opt.key === option.key) !== -1;

  const handleOptionClick = (option: IFilterOption): void => {
    let newSelectedOptions: IFilterOption[];

    if (isOptionSelected(option)) {
      newSelectedOptions = selectedOptions.filter(
        selectedOption => selectedOption.key !== option.key,
      );
    } else {
      newSelectedOptions = [...selectedOptions, option];
    }

    // Sort options to optimize compare in container
    newSelectedOptions.sort((a, b) => String(a.key).localeCompare(String(b.key)));

    setSelectedOptions(newSelectedOptions);
  };

  const handleApplyFilter = useCallback(
    (closeMenu?: () => void): void => {
      const selected = selectedOptions.length > 0 ? selectedOptions : null;

      if (selected?.length === 1) {
        setFirstOption(selected[0]);
      } else {
        setFirstOption(null);
      }

      onChange(
        {
          name,
          type,
          value: selected || [],
        },
        name,
      );
      if (closeMenu) closeMenu();
    },
    [name, type, selectedOptions, onChange],
  );

  const handleClearFilter = useCallback(() => setSelectedOptions([]), [setSelectedOptions]);

  const handleResetFilter = useCallback(() => setSelectedOptions(value || []), [value]);

  useEffect(() => {
    (async () => {
      if (valueRef.current?.length === 1) {
        const label = await fetchItemTitleRef.current(valueRef.current[0].key);
        setFirstOption({ ...valueRef.current[0], label: label || '' });
      }
    })();
  }, [setFirstOption]);

  useEffect(() => {
    setSelectedOptions(value || []);
  }, [value]);

  const renderFilterTitle = (): JSX.Element | string | React.ReactNode => {
    if (dialog) {
      return title;
    }

    if (!value || value.length === 0) {
      return (
        <Typography noWrap component="span" color="inherit">
          <FormattedMessage {...messages.filterTitle} values={{ title, filter: 'All' }} />
        </Typography>
      );
    }

    if (firstOption) {
      return isLoading ? (
        <CircularProgress size="16px" color="inherit" className={classes.loader} />
      ) : (
        <Typography noWrap component="span" color="inherit">
          {firstOption?.label || value[0].label}
        </Typography>
      );
    }

    return title;
  };
  const renderPaginatedFilterBody = (closeMenu?: () => void) => {
    return (
      <FilterMenuBody
        dialog={dialog}
        showApply
        showClear
        disableClear={selectedTotal === 0}
        onClearFilter={handleClearFilter}
        onApplyFilter={() => handleApplyFilter(closeMenu)}
      >
        <PaginatedFilterBody
          fetchList={fetchList}
          elementsPerPage={perPage}
          handleOptionClick={handleOptionClick}
          isOptionSelected={isOptionSelected}
          params={params || {}}
        />
      </FilterMenuBody>
    );
  };

  const renderDropdownFilter = () => {
    return (
      <DropdownFilter
        dialogMenu={dialog}
        clearBtn
        name={name}
        suppressOnKeyDownEvent
        title={renderFilterTitle()}
        filtersTotal={value ? value.length : 0}
        clearDisabled={selectedTotal === 0}
        renderBody={renderPaginatedFilterBody}
        onClear={handleClearFilter}
        onClose={handleResetFilter}
        onDialogApply={handleApplyFilter}
      />
    );
  };

  const renderHoverMenuFilter = () => {
    return (
      <HoverFilter
        name={name}
        title={renderFilterTitle()}
        filtersTotal={value ? value.length : 0}
        dialogMenu={dialog}
        clearBtn
        hoverMenuClassName={classes.hoverFilter}
        clearDisabled={selectedTotal === 0}
        onClear={handleClearFilter}
        onClose={handleResetFilter}
        renderBody={renderPaginatedFilterBody}
        onDialogApply={handleApplyFilter}
      />
    );
  };

  return viewType === FilterViewTypes.DROPDOWN ? renderDropdownFilter() : renderHoverMenuFilter();
};

export default MultipleFilterWithPaginate;
