import React from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme, Typography } from '@material-ui/core';

import {
  selectUpdateImage,
  selectUpdateImageLoading,
} from 'common/components/PersonProfile/state/selectors';
import { selectLeadAvatar, updateLeadAvatarLoading } from 'modules/crm/state/leads/selectors';

import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { PeakModules } from 'common/constants/peakModules';

import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';

import { DialogComponent } from 'common/components';

import messages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  questionText: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
}));

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  module?: PeakModuleForNewPersonType;
  personId?: number;
}

const DeleteImageModal = ({ isOpen, onClose, onSubmit, personId, module }: IProps): JSX.Element => {
  const loading = useRootSelector(
    module === PeakModules.Crm
      ? updateLeadAvatarLoading()
      : selectUpdateImageLoading(Number(personId)),
  );
  const updatedImage = useRootSelector(
    module === PeakModules.Crm ? selectLeadAvatar : selectUpdateImage(Number(personId)),
  );

  useComponentDidUpdate(() => {
    onClose();
  }, [updatedImage]);

  const classes = useStyles();

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...messages.deletePhotoTitle} />}
      submitBtnTitle={<FormattedMessage {...messages.deleteBtn} />}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      disableFullScreenBehavior
      loading={loading}
    >
      <Typography color="textSecondary" className={classes.questionText}>
        <FormattedMessage {...messages.questionDeletePhotoBody} />
      </Typography>
    </DialogComponent>
  );
};

export default DeleteImageModal;
