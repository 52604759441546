import { createAction } from '@reduxjs/toolkit';

import { IEmployeeForm, IEmployeeProfile, IEmployeeRole } from 'common/interfaces/employee';
import { IPaginatedEmployees } from 'modules/employees/interfaces';
import { GeneralThunkAction } from 'common/state/interfaces';
import { IServerError, ISuccessResponse } from 'common/interfaces/http';
import { ITableParams } from 'common/interfaces/table';

import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from 'common/state/notifications/actions';

import { actionTypes } from 'modules/employees/constants';
import Services from 'services/network';
import employeesMessages from 'common/messages/employeesMessages';
import { setUserAvatar } from 'modules/authentication/state/actions';

const resetEmployeeFormAction = createAction(actionTypes.RESET_EMPLOYEE_FORM);

const fetchRolesLoading = createAction<boolean>(actionTypes.FETCH_ROLES_LOADING);
const fetchRolesAction = createAction<IEmployeeRole[]>(actionTypes.FETCH_ROLES);

export const fetchRolesList = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRolesLoading(true));
    const roles = await Services.Employees.getSecurityRoles();
    dispatch(fetchRolesAction(roles));
  };
};
const fetchEmployeesLoading = createAction<boolean>(actionTypes.FETCH_EMPLOYEES_LOADING);
const fetchEmployeesAction = createAction<IPaginatedEmployees>(actionTypes.FETCH_EMPLOYEES);
export const resetEmployeesListAction = createAction(actionTypes.RESET_EMPLOYEES_LIST);

export const fetchEmployeesList = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchEmployeesLoading(true));

    try {
      const paginatedData = await Services.Employees.getEmployees(requestOptions);
      dispatch(fetchEmployeesAction(paginatedData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchEmployeesLoading(false));
    }
  };
};

const fetchEmployeeLoading = createAction<boolean>(actionTypes.FETCH_EMPLOYEE_LOADING);
const fetchEmployeeAction = createAction<IEmployeeProfile>(actionTypes.FETCH_EMPLOYEE);

export const fetchEmployeeById = (employeeId: number): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchEmployeeLoading(true));
    try {
      const employeeProfile = await Services.Employees.getEmployeeById(employeeId);
      dispatch(fetchEmployeeAction(employeeProfile));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchEmployeeLoading(false));
    }
  };
};

const createEmployeeLoading = createAction<boolean>(actionTypes.CREATE_EMPLOYEE_LOADING);
const createEmployeeAction = createAction<IEmployeeProfile>(actionTypes.CREATE_EMPLOYEE);

export const createEmployee = (newEmployeeProfile: IEmployeeForm): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createEmployeeLoading(true));
    try {
      const newEmployee = await Services.Employees.createEmployee(newEmployeeProfile);
      dispatch(createEmployeeAction(newEmployee));
      dispatch(enqueueSuccessNotification(employeesMessages.employeeCreatedMessage));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createEmployeeLoading(false));
    }
  };
};

const updateEmployeeLoading = createAction<boolean>(actionTypes.UPDATE_EMPLOYEE_LOADING);
const updateEmployeeAction = createAction<IEmployeeProfile>(actionTypes.UPDATE_EMPLOYEE);
export const updateEmployeeError = createAction<IServerError>(actionTypes.UPDATE_EMPLOYEE_ERROR);

export const updateEmployee = (
  employeeId: number,
  updatedEmployeeProfile: IEmployeeForm,
  isCurrentUser: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updateEmployeeLoading(true));
    try {
      const updatedEmployee = await Services.Employees.updateEmployee(
        employeeId,
        updatedEmployeeProfile,
      );
      dispatch(updateEmployeeAction(updatedEmployee));

      if (isCurrentUser) {
        dispatch(
          setUserAvatar({
            avatar: updatedEmployee.image?.url || '',
            preferredName: updatedEmployee.preferredName,
          }),
        );
      }
    } catch (error) {
      dispatch(updateEmployeeError(error));
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updateEmployeeLoading(false));
    }
  };
};

const changeEmployeeStatusLoading = createAction<boolean>(
  actionTypes.EMPLOYEE_CHANGE_STATUS_LOADING,
);
const changeEmployeeStatusAction = createAction<ISuccessResponse>(
  actionTypes.EMPLOYEE_CHANGE_STATUS,
);

export const resetChangeEmployeeStatusResult = createAction<void>(
  actionTypes.RESET_EMPLOYEE_CHANGE_STATUS_RESULT,
);
export const changeEmployeeStatus = (
  employeeId: number,
  active: boolean,
  terminationDate?: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(changeEmployeeStatusLoading(true));
    try {
      const result = await Services.Employees.changeEmployeeStatus(
        employeeId,
        active,
        terminationDate,
      );
      dispatch(changeEmployeeStatusAction({ success: !!result.id }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(changeEmployeeStatusLoading(false));
    }
  };
};

export const resetEmployeeForm = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(resetEmployeeFormAction());
  };
};
