import { createTheme as createMUITheme, PopoverClassKey } from '@material-ui/core';
// import { PaletteColor } from '@material-ui/core/styles/createPalette';
// import { Block } from '@material-ui/icons';
// import { NONAME } from 'dns';
import { alpha, darken } from '@material-ui/core/styles/colorManipulator';
import { AlertClassKey } from '@material-ui/lab/Alert';
import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import { CustomTheme } from '../../interfaces';
import { IObject } from 'common/interfaces/common';
import { getDefaultSelectMenuPosition } from 'common/ui/utils';

declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey {
    MuiAutocomplete: AutocompleteClassKey;
    MuiAlert: AlertClassKey;
    MuiPopover: PopoverClassKey;
  }
}

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    laptop: true;
    md: true;
    lg: true;
    desktop: true;
    xl: true;
  }
}
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    celebration?: PaletteColor;
    darkBackground?: PaletteColor;
    lightBackground?: PaletteColor;
  }

  interface PaletteOptions {
    celebration?: PaletteColor;
    darkBackground?: PaletteColor;
    lightBackground?: PaletteColor;
    borderColor?: Partial<PaletteColor>;
  }
}

export const sidebarClubSelector = {
  border: 'rgba(255, 255, 255, 0.12)',
  background: '#282828',
  boxShadow: '0 4px 8px 0 #212121',
};

export const colors: IObject = {
  input: {
    borderColor: '#d6d6d6',
    disabled: {
      backgroundColor: '#f9f9f9',
    },
  },
  primary: '#66cd00',
  secondary: 'rgba(50, 50, 50, 0.3)',
  disabled: {
    opacity3: 'rgba(50, 50, 50, 0.3)',
    opacity4: 'rgba(50, 50, 50, 0.4)',
    opacity6: 'rgba(50, 50, 50, 0.6)',
  },

  disabledLight: 'rgba(255, 255, 255, 0.3)',

  info: '#2eabff',
  guest: '#ffa610',
  warning: '#f49b03',
  warningBorder: '#edbd6b',
  error: '#ff2634',

  celebration: '#d141ff',
  celebrationLight: '#ff78ff',
  celebrationDark: '#9b00cb',

  lightGray: '#ededed',

  background: {
    page: '#f7f8f8',
    paper: '#ffffff',
    dark: '#707070',
    darkLighter: '#ADADAD',
  },

  table: {
    borderColor: 'rgba(224, 224, 224, 1)',
  },

  tab: {
    hover: 'rgba(50, 50, 50, 0.04)',
  },

  text: {
    primary: '#323232',
    secondary: '#575757',
    mediumGray: '#8E8E8E',
    contrast: '#FFFFFF',
  },
  buttons: {
    contained: {
      active: '#f5f5f5',
      primary: {
        active: '#458802',
      },
    },
    text: {
      hover: 'rgba(102, 205, 0, 0.08)',
      active: {
        backgroundColor: 'rgba(102, 205, 0, 0.16)',
        textColor: '#458802',
      },
    },
    iconButton: '#8C8C8C',
  },

  errorSadIcon: '#9f9f9f',

  coldBlue: '#3d7690',

  colorPicker: {
    plumPerfect: '#ad1457',
    dogwoodRose: '#d81b60',
    rossoCorsa: '#d50000',
    geraldine: '#e67c73',

    scarletIbis: '#f4511e',
    royalFlycatcherCrest: '#ef6c00',
    saffronGold: '#f09300',
    goldenLock: '#f6bf26',

    superLemon: '#e4c441',
    fluorescentLime: '#c0ca33',
    sparklingApple: '#7cb342',
    buddhaGreen: '#33b679',

    pharmaceuticalGreen: '#0b8043',
    intenseTeal: '#009688',
    pervenche: '#039be5',
    seljukBlue: '#4285f4',

    earlySpringNight: '#3f51b5',
    violetsAreBlue: '#7986cb',
    violetVelvet: '#b39ddb',
    softPurple: '#9e69af',

    passionatePurple: '#8e24aa',
    vandyckBrown: '#795548',
    shadesOn: '#616161',
    foggyNight: '#a79b8e',

    coralReef: '#ff7f50',
    oceanBreeze: '#00bfff',
    lavenderMist: '#e6e6fa',
    electricSunrise: '#ffdb58',

    defaultColor: '#66cd00',
    solarFlare: '#ffcc33',
  },
};

export const typography: IObject = {
  headers: {
    fontWeight: 700,
    h2: {
      fontSize: '1.5rem',
    },
    h3: {
      fontSize: '1.25rem',
    },
    h4: {
      fontSize: '1.125rem',
      letterSpacing: 0,
    },
    h5: {
      fontSize: '0.875rem',
    },
  },
  body1: {
    fontSize: '0.875rem',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  body2: {
    fontSize: '0.75rem',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  buttons: {
    padding: '8px 20px',
    lineHeight: 'normal',
    text: {
      large: {
        fontSize: '1rem',
      },
    },
    root: {
      fontWeight: 700,
    },
    large: {
      fontSize: '1rem',
    },
  },
};

const shadows: any = [
  'none',
  '0px 1px 2px rgba(0, 0, 0, 0.3)',
  '0px 2px 4px rgba(0, 0, 0, 0.16)',
  '0px 1px 5px 0px rgba(153, 153, 153, 0.12), 0px 1px 3px -2px rgba(153, 153, 153, 0.20), 0px 2px 2px 0px rgba(153, 153, 153, 0.14)',
  '0px 2px 6px rgba(0, 0, 0, 0.24)',
  '0px 6px 12px 4px rgba(0, 0, 0, 0.12)',
  '0px 8px 16px 3px rgba(0, 0, 0, 0.08), 0px 9px 16px 0px rgba(0, 0, 0, 0.08), 0px 5px 9px -4px rgba(0, 0, 0, 0.06)',
  '0px 12px 20px 3px rgba(0, 0, 0, 0.12), 0px 9px 46px 0px rgba(0, 0, 0, 0.08), 0px 11px 15px -7px rgba(0, 0, 0, 0.16)',
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
];

export enum ThemeBreakpoints {
  XS = 0,
  SM = 600,
  LAPTOP = 768,
  MD = 960,
  LG = 1024,
  DESKTOP = 1200,
  XL = 1600,
}

const DISABLED_OPACITY = 0.2;
const ACTIVE_OPACITY = 0.16;
const HOVER_OPACITY = 0.08;

export const createPalette = primaryColor => ({
  primaryColor,
  primaryLightest: alpha(primaryColor, HOVER_OPACITY),
  primaryLighter: alpha(primaryColor, ACTIVE_OPACITY),
  primaryDarker: darken(primaryColor, DISABLED_OPACITY),
});

export const createTheme = ({
  primaryColor,
  primaryLighter,
  primaryLightest,
  primaryDarker,
}): CustomTheme =>
  createMUITheme({
    props: {
      MuiSelect: {
        MenuProps: getDefaultSelectMenuPosition(),
      },
    },
    breakpoints: {
      values: {
        xs: ThemeBreakpoints.XS,
        sm: ThemeBreakpoints.SM,
        laptop: ThemeBreakpoints.LAPTOP,
        md: ThemeBreakpoints.MD,
        lg: ThemeBreakpoints.LG,
        desktop: ThemeBreakpoints.DESKTOP,
        xl: ThemeBreakpoints.XL,
      },
    },
    palette: {
      primary: {
        main: primaryColor,
        contrastText: colors.text.contrast,
        light: primaryLightest,
      },
      secondary: {
        light: colors.lightGray,
        main: colors.text.secondary,
        contrastText: colors.secondary,
        dark: colors.buttons.iconButton,
      },
      info: {
        main: colors.info,
        contrastText: colors.text.contrast,
      },
      warning: {
        main: colors.warning,
        contrastText: colors.text.contrast,
      },
      error: {
        main: colors.error,
        contrastText: colors.text.contrast,
      },
      celebration: {
        main: colors.celebration,
        dark: colors.celebrationDark,
        light: colors.celebrationLight,
        contrastText: colors.text.contrast,
      },
      background: {
        default: colors.background.page,
        paper: colors.background.paper,
      },
      success: {
        main: colors.primary,
      },
      text: {
        primary: colors.text.primary,
        secondary: colors.text.mediumGray,
        // disabled: 'rgba(0, 0, 0, 0.38)',
        // hint: rgba(0, 0, 0, 0.38)
      },
      darkBackground: {
        main: colors.background.dark,
        dark: colors.text.secondary,
        light: colors.background.darkLighter,
        contrastText: colors.text.contrast,
      },
      lightBackground: colors.disabledLight,
      borderColor: {
        light: colors.table.borderColor,
        main: colors.input.borderColor,
      },
    },
    typography: {
      h1: {
        fontWeight: typography.headers.fontWeight,
      },
      h2: {
        fontWeight: typography.headers.fontWeight,
        fontSize: typography.headers.h2.fontSize,
      },
      h3: {
        fontWeight: typography.headers.fontWeight,
        fontSize: typography.headers.h3.fontSize,
      },
      h4: {
        fontWeight: typography.headers.fontWeight,
        fontSize: typography.headers.h4.fontSize,
        letterSpacing: typography.headers.h4.letterSpacing,
      },
      h5: {
        fontWeight: 700,
        fontSize: typography.headers.h5.fontSize,
      },
      h6: {
        fontSize: '0.875rem',
        fontWeight: typography.headers.fontWeight,
        lineHeight: 'normal',
      },
      body1: {
        fontSize: typography.body1.fontSize,
        lineHeight: typography.body1.lineHeight,
        letterSpacing: typography.body1.letterSpacing,
      },
      body2: {
        fontSize: typography.body2.fontSize,
        lineHeight: typography.body2.lineHeight,
        letterSpacing: typography.body2.letterSpacing,
      },
      subtitle2: {
        lineHeight: 'normal',
      },
      allVariants: {
        color: colors.text.primary,
      },
    },
    shadows,
    overrides: {
      MuiAlert: {
        standardWarning: {
          color: colors.warning,
          border: `1px solid ${colors.warningBorder}`,
        },
        message: {
          lineHeight: 1.45,
        },
      },
      MuiTypography: {
        /* colorTextSecondary: {
          color: colors.text.secondary,
          opacity: 0.4,
        }, */
        button: {
          fontWeight: 700,
          lineHeight: '1',
        },
      },
      MuiTablePagination: {
        root: {
          padding: 0,
        },
        spacer: {
          display: 'none',
        },
        caption: {
          minWidth: 40,
        },
        toolbar: {
          minHeight: 'initial',
          padding: 0,
        },
        actions: {
          marginLeft: 4,
          '& .MuiIconButton-root': {
            background: 'white',
            borderRadius: '3px',
            padding: '6px',
            marginLeft: 8,
          },
          '&:not(.Mui-disabled)': {
            color: colors.text.secondary,
          },
        },
      },
      MuiInputBase: {
        input: {
          height: 'initial',
          padding: '8px 0',
          '&::placeholder, &::-webkit-input-placeholder, ::-ms-input-placeholder': {
            color: colors.text.primary,
            opacity: 0.4,
          },
          '&$disabled': {
            // opacity: 0.4,
            color: colors.text.primary,
          },
        },
        root: {
          background: '#fff',
          '&$error': {
            '& .MuiSelect-icon': {
              fill: colors.error,
            },
          },
          '&.select--small': {
            '& .MuiSelect-select.MuiSelect-select': {
              padding: '8px 32px 8px 12px',
            },
          },
        },
      },
      MuiInputAdornment: {
        root: {
          transform: 'translateY(6px)',
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: '3px',
          '&.MuiInputBase-root MuiAutocomplete-inputRoot MuiInputBase-formControl MuiInputBase-adornedEnd MuiOutlinedInput-adornedEnd': {
            paddingBottom: '25px',
          },
          '&:hover $notchedOutline': {
            borderColor: colors.input.borderColor,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
          },
          '&$marginDense': {
            borderRadius: '4px',
            '& .MuiInputAdornment-root': {
              transform: 'none',
            },
          },
          '&$disabled': {
            backgroundColor: colors.input.disabled.backgroundColor,
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 0,
            },
          },
        },
        input: {
          padding: '22px 12px 10px 12px',
          fontSize: typography.body1.fontSize,
          lineHeight: 'normal',
        },
        inputMarginDense: {
          paddingTop: 8,
          paddingBottom: 8,
        },
        multiline: {
          padding: '22px 12px 10px 12px',
        },
        notchedOutline: {
          borderColor: colors.input.borderColor,
          '& legend': {
            width: 0,
          },
        },
      },

      MuiFormLabel: {
        root: {
          color: colors.text.primary,
          lineHeight: 'normal',
          opacity: '0.4',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
          paddingRight: '16px',
          '&.smallInputLabel': {
            top: '50%',
            left: '12px',
            transform: 'translateY(-50%) !important',
            '&.MuiFormLabel-filled': {
              opacity: 0,
            },
          },
          '&$focused': {
            color: colors.text.primary,
          },
          '&$error': {
            opacity: '1',
          },
          '&$disabled': {
            color: colors.text.primary,
            opacity: '0.4',
          },
        },
      },
      MuiFormControlLabel: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
        label: {
          marginLeft: 4,
        },
      },
      MuiInputLabel: {
        root: {
          opacity: 1,
          color: colors.text.mediumGray,
          '&$focused': {
            color: colors.text.mediumGray,
          },
        },
        formControl: {
          transform: 'translate(12px, 16px)',
        },
        outlined: {
          transform: 'translate(12px, 16px)',
          '&$shrink': {
            transform: 'translate(12px, 8px) scale(0.675)',
          },
        },
        shrink: {
          transform: 'translate(12px, 8px) scale(0.675)',
        },
      },
      MuiSelect: {
        outlined: {
          // display: 'flex',
          // alignItems: 'center',
        },
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        selectMenu: {
          minHeight: 0,
        },
        icon: {
          width: '1rem',
          height: '1rem',
          top: '50%',
          transform: 'translateY(-50%)',
          '&$iconOpen': {
            transform: 'translateY(-50%) rotate(180deg)',
          },
        },
      },
      MuiAutocomplete: {
        paper: {
          boxShadow: shadows[5],
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        inputRoot: {
          '&[class*="MuiOutlinedInput-root"]': {
            paddingTop: 19,
            '&.MuiInputBase-adornedStart': {
              '&:not(.MuiOutlinedInput-marginDense)': {
                paddingTop: 17,
              },
            },
            '& .MuiAutocomplete-input': {
              padding: '4px 4px 0 4px;',
            },
          },
          '&.MuiInputBase-root': {
            alignItems: 'flex-end',
          },
        },
        option: {
          '&[data-focus="true"]': {
            backgroundColor: primaryColor,
            color: '#fff',

            '& .MuiBox-root .MuiTypography-root, & svg': { color: colors.text.contrast },
            '& svg path': { fill: colors.text.contrast },
          },
          '&:active': {
            backgroundColor: primaryDarker,
            color: '#fff',
          },
        },
        popupIndicator: {
          padding: '5px',
          marginRight: '-5px',
          '&:hover, &:active': {
            backgroundColor: 'transparent',
          },
          '& .MuiSvgIcon-root': {
            width: '15px',
            height: '15px',
          },
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
        },
        clearIndicator: {
          padding: '2px',
          // Change clear icon
          '& span': {
            '& svg': {
              '& path': {
                d:
                  "path('M12 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm3.923 10.1c.151.152.151.397 0 .548l-1.278 1.275c-.151.151-.397.151-.548 0L12 13.806l-2.1 2.117c-.152.151-.397.151-.548 0l-1.275-1.278c-.151-.151-.151-.397 0-.548L10.194 12 8.077 9.9c-.151-.152-.151-.397 0-.548l1.278-1.278c.151-.151.397-.151.548 0L12 10.194l2.1-2.117c.152-.151.397-.151.548 0l1.278 1.278c.151.151.151.397 0 .548L13.806 12l2.117 2.1z')",
              },
            },
          },
          '&:hover, &:active': {
            backgroundColor: 'transparent',
          },
          '& .MuiSvgIcon-root': {
            width: '22px',
            height: '22px',
          },
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
        },
        tag: {
          maxWidth: '135px',
          height: 'auto',
          color: colors.text.secondary,
          margin: '4px 4px 0px 4px',
          padding: '5px 8px 6px 8px',
          borderRadius: '12px',
          backgroundColor: colors.lightGray,
          '& .MuiChip-label': {
            fontSize: '0.8125rem',
            lineHeight: 1,
            padding: 0,
          },
          '& .MuiChip-deleteIcon': {
            width: '12px',
            height: '12px',
            fill: '#a2a2a2',
            margin: '0 -2px 0 2px',
          },
        },
        tagSizeSmall: {
          maxWidth: '100px',
        },
      },
      MuiCheckbox: {
        root: {
          padding: 0,
        },
      },
      MuiRadio: {
        root: {
          padding: 0,
        },
      },
      MuiButton: {
        root: {
          lineHeight: typography.buttons.lineHeight,
          padding: typography.buttons.padding,
          letterSpacing: typography.body1.letterSpacing,
          fontWeight: typography.buttons.root.fontWeight,
          transition:
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '& .MuiTouchRipple-root': {
            opacity: 0,
          },
          '&$disabled, &:disabled': {
            opacity: 0.3,
            color: colors.text.secondary,
          },
          '& .MuiSvgIcon-root, svg': {
            marginTop: '-4px',
            marginBottom: '-4px',
            fontSize: '20px',
          },
        },
        contained: {
          color: colors.text.secondary,
          boxShadow: shadows[3],
          backgroundColor: '#fff',
          '&:hover': {
            backgroundColor: '#fff',
            boxShadow: shadows[4],
          },
          '&:active': {
            boxShadow: shadows[3],
            backgroundColor: colors.buttons.contained.active,
          },
          '&.MuiButton-root:not(.MuiButton-containedPrimary)': {
            '@media (hover: none)': {
              '&:hover': {
                backgroundColor: '#fff',
              },
              '&:active': {
                backgroundColor: colors.buttons.contained.active,
              },
            },
          },
        },
        outlined: {
          padding: '7px 20px',
        },

        containedPrimary: {
          backgroundColor: primaryColor,
          '&:hover': {
            backgroundColor: primaryColor,
          },
          '&:active': {
            backgroundColor: primaryDarker,
          },
          '&$disabled': {
            backgroundColor: primaryColor,
            color: '#fff',
          },
        },
        outlinedPrimary: {
          padding: typography.buttons.padding,
        },
        sizeSmall: {
          fontSize: typography.body2.fontSize,
          minWidth: 0,
        },
        sizeLarge: {
          fontSize: typography.buttons.root.fontSize,
        },
        textSizeLarge: {
          fontSize: typography.buttons.text.large.fontSize,
        },
        containedSecondary: {
          color: colors.text.secondary,
          boxShadow: shadows[1],
          backgroundColor: '#fff',
          '&:hover': {
            backgroundColor: '#fff',
            boxShadow: shadows[4],
          },
          '&:active': {
            boxShadow: shadows[3],
            backgroundColor: colors.buttons.contained.active,
          },
          '&.MuiButton-root:not(.MuiButton-containedPrimary)': {
            '@media (hover: none)': {
              '&:hover': {
                backgroundColor: '#fff',
              },
              '&:active': {
                backgroundColor: colors.buttons.contained.active,
              },
            },
          },
          '&$disabled': {
            opacity: 0.5,
          },
        },
        text: {
          padding: typography.buttons.padding,
          '&:hover': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
        textPrimary: {
          '&:hover': {
            backgroundColor: primaryLighter,
          },
          '&:active': {
            backgroundColor: primaryLighter,
            color: colors.buttons.text.active.textColor,
          },
        },
        startIcon: {
          marginRight: '2px',
        },
        endIcon: {
          marginLeft: '2px',
        },
        iconSizeSmall: {
          '& > *:first-child': {
            fontSize: '14px',
          },
        },
        iconSizeMedium: {
          '& > *:first-child': {
            fontSize: '18px',
          },
        },
      },
      MuiIconButton: {
        root: {
          borderRadius: '3px',
          fontSize: 'inherit',
          fontWeight: 'bold',
          color: colors.buttons.iconButton,

          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: colors.buttons.contained.active,
          },
          '&:active': {
            backgroundColor: colors.lightGray,
          },
          '&$sizeSmall': {
            padding: '4px',
          },

          '& .MuiSvgIcon-root ': {
            fontSize: 'inherit',
          },
        },
        colorPrimary: {
          '&:hover': {
            boxShadow: shadows[3],
          },
        },
      },
      MuiList: {
        root: {
          outline: 'none',
          border: 'none',
        },
      },
      MuiSwitch: {
        root: {
          '& .MuiIconButton-root, & .MuiSwitch-colorSecondary.Mui-checked': {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
        switchBase: {
          color: colors.background.paper,
        },
        colorPrimary: {
          '&$checked': {
            color: primaryColor,
          },
          '&$checked&$disabled': {
            color: primaryColor,
            opacity: 0.5,
          },
          '&$disabled': {
            color: colors.background.paper,
          },
          '&$checked + $track': {
            backgroundColor: primaryColor,
            opacity: 0.5,
          },
          '&$checked&$disabled + $track': {
            opacity: 0.16,
            backgroundColor: primaryColor,
          },
          '&$disabled + $track': {
            opacity: 0.5,
            backgroundColor: colors.input.borderColor,
          },
        },
        thumb: {
          width: 20,
          height: 20,
          boxShadow:
            '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
        },
        track: {
          backgroundColor: colors.background.darkLighter,
          opacity: 1,
        },
      },
      MuiListItem: {
        root: {
          '&$selected, &$selected:hover': {
            backgroundColor: primaryColor,
            color: 'white',
          },
        },
      },
      MuiListItemIcon: {
        root: {
          color: colors.text.secondary,
          minWidth: 'auto',
          marginRight: '16px',
          '& .MuiIconButton-root, & .MuiSwitch-colorSecondary.Mui-checked': {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiLink: {
        root: {
          fontWeight: 'bold',
          lineHeight: 1,
          fontStretch: 'normal',
          transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          cursor: 'pointer',
          display: 'inline-flex',
          alignItems: 'center',

          '&$disabled, &:disabled': {
            cursor: 'default',
            opacity: 0.3,
            color: colors.text.primary,
          },
          '& .MuiSvgIcon-root, svg': {
            marginTop: '-4px',
            marginBottom: '-4px',
            fontSize: '20px',
          },

          '&:active': {
            textDecoration: 'none!important',
            color: colors.buttons.text.active.textColor,
          },
        },
        button: {
          textTransform: 'uppercase',
        },
      },
      MuiMenuItem: {
        root: {
          backgroundColor: '#fff',
          padding: '8px 24px !important',
          minHeight: '36px',
          transition: 'none',
          '&.Mui-focusVisible': {
            backgroundColor: '#fff',
          },
          '&:hover, &:active': {
            backgroundColor: primaryColor,
            color: '#fff',
            '& .MuiSvgIcon-root, & .MuiListItemIcon-roo, & .MuiTypography-colorPrimary': {
              color: primaryDarker,
            },
            '& span': { color: colors.text.contrast },
          },
          '&$selected': {
            color: primaryColor,
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: primaryColor,
              color: '#fff',
            },
          },
          '& .MuiListItemIcon-root': {
            minWidth: 'auto',
            '&:first-child': {
              marginRight: 14,
            },
            '&:last-child': {
              marginLeft: 14,
            },
            '& .MuiSvgIcon-root': {
              width: 16,
              height: 'auto',
              maxHeight: 17,
              margin: 0,
            },
          },
          '& .MuiSvgIcon-root': {
            color: colors.text.secondary,
            width: 16,
            height: 'auto',
            maxHeight: 17,
            '&:first-child': {
              marginRight: 14,
            },
            '&:last-child': {
              marginLeft: 14,
            },
          },
        },
      },
      MuiTableBody: {
        root: {
          '& > .MuiTableRow-root:last-child > .MuiTableCell-root': {
            borderBottom: 'none',
          },
        },
      },
      MuiTableContainer: {
        root: {
          position: 'relative',
          padding: '0',
          borderRadius: 4,
        },
      },
      MuiTableRow: {
        root: {
          '&.MuiTableRow-hover': {
            transition: 'all 0.2s 0.2s',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.02)',
            },
          },
        },
      },
      MuiTableCell: {
        root: {
          '&:first-child': {
            paddingLeft: '2px',
          },
          '&:last-child': {
            paddingRight: '2px',
          },
        },
        head: {
          padding: '12px 12px 12px 0',
        },
        body: {
          padding: '12px 12px 12px 0',
        },
        footer: {
          padding: '12px 12px 12px 0',
        },
        paddingCheckbox: {
          width: '24px',
          paddingRight: '16px',
        },
      },
      MuiTableSortLabel: {
        root: {
          paddingTop: '2px',
          lineHeight: 'normal',
          color: colors.text.mediumGray,
          '&:focus': {},
          '&:hover': {
            color: colors.text.secondary,
          },
          '&$active': {
            color: colors.text.primary,
            '&& $icon': {
              color: primaryColor,
            },
          },
        },
        icon: {
          fontSize: 14,
        },
      },
      MuiDialog: {
        paperWidthXs: {
          maxWidth: 400,
        },
        paperWidthSm: {
          maxWidth: 440,
        },
        paperWidthMd: {
          maxWidth: 648,
        },
        paperWidthLg: {
          maxWidth: 760,
        },
        paperWidthXl: {
          maxWidth: 1024,
        },
        paperFullScreen: {
          maxWidth: '100%!important',
        },
        container: {
          '& .MuiPickersBasePicker': {
            '&-container': {
              background: colors.background.paper,
              overflow: 'auto',
              '& .MuiPickersToolbar': {
                '&-toolbar': {
                  height: 'auto',
                  padding: 16,
                },
              },
            },
            '&-pickerView': {
              minWidth: 290,
              minHeight: 0,
              display: 'block',
              '@media (max-height: 500px)': {
                height: 'calc(100vh - 175px)',
                boxShadow: `inset ${shadows[5]}`,
                '& .MuiPickersYearSelection': {
                  '&-container': {
                    height: '100%',
                  },
                },
              },
              '& .MuiPickersCalendar': {
                '&-transitionContainer': {
                  minHeight: 185,
                },
              },
            },
          },
        },
      },
      MuiDialogContent: {
        root: {
          padding: '12px 24px',
          '&:first-child': {
            paddingTop: 0,
          },
        },
      },
      MuiDialogContentText: {
        root: {
          color: colors.text.primary,
          fontSize: '0.98rem',
          lineHeight: '1.5rem',
        },
      },
      MuiDialogActions: {
        root: {
          padding: 16,
        },
      },
      MuiFormHelperText: {
        root: {
          lineHeight: 1.25,
          '&$error': {
            fontSize: '0.75rem',
            opacity: 1,
          },
        },
        contained: {
          margin: '5px 0px 0px !important',
          opacity: 0.5,
          color: colors.text.primary,
        },
      },
      MuiTooltip: {
        tooltipPlacementBottom: {
          marginTop: '8px !important',
        },
      },

      MuiPopover: {
        paper: {
          '& .MuiPickersBasePicker': {
            '&-container': {
              background: colors.background.paper,
              overflow: 'auto',
              '& .MuiPickersToolbar': {
                '&-toolbar': {
                  height: 'auto',
                  padding: 16,
                },
              },
            },
            '&-pickerView': {
              minWidth: 290,
              minHeight: 0,
              display: 'block',
              '@media (max-height: 500px)': {
                height: 'calc(100vh - 175px)',
                '& .MuiPickersYearSelection': {
                  '&-container': {
                    height: '100%',
                  },
                },
              },
              '& .MuiPickersCalendar': {
                '&-transitionContainer': {
                  minHeight: 185,
                },
              },
            },
          },
        },
      },
      MuiTab: {
        root: {
          padding: '12px 20px',
          minHeight: 'initial',

          '&:hover': {
            backgroundColor: colors.tab.hover,
          },
        },
        wrapper: {
          textTransform: 'capitalize',
          fontSize: typography.buttons.text.large.fontSize,
          lineHeight: 1,
        },
        textColorInherit: {
          opacity: 0.6,
        },
      },
      MuiTabs: {
        root: {
          minHeight: 'initial',
        },
        indicator: {
          backgroundColor: primaryColor,
        },
        flexContainer: {
          borderBottom: `1px solid ${colors.lightGray}`,
        },
      },
      MuiBadge: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: '1.25rem',
          },
        },
        badge: {
          minWidth: 14,
          height: 14,
          fontSize: 10,
          padding: 0,
        },
      },
      MuiLinearProgress: {
        barColorPrimary: {
          backgroundColor: colors.primary,
        },
      },
      MuiPaper: {
        root: {
          boxShadow: shadows[5],
        },
      },
    },
  });
