import React, { useCallback, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { NumberFormatProps } from 'react-number-format';
import { NumberTextField } from 'common/components/index';
import { debounce } from 'common/utils';

interface INumberController {
  control: Control<any>;
  name: string;

  label: string | JSX.Element;
  numberFormatProps?: NumberFormatProps;
  disabled?: boolean;
  defaultValue: string | number;
  error?: boolean;
  helperText?: string;

  // Makes input debounced, but only applies to uncontrolled components
  debounced?: boolean;

  // making component controlled or uncontrolled
  isControlledComponent?: boolean;

  // triggers validation of other (related) fields, if provided
  trigger?: (name?: string | string[]) => Promise<boolean>;
  additionalTriggeredFields?: string | string[];
}

export const defaultNumberFormatProps: NumberFormatProps = {
  decimalScale: 0,
  fixedDecimalScale: true,
  allowLeadingZeros: false,
  allowNegative: false,
  isNumericString: true,
  maxLength: 8,
  type: 'text',
};

export const defaultPriceNumberProps: NumberFormatProps = {
  ...defaultNumberFormatProps,
  prefix: '$',
  decimalScale: 2,
  maxLength: 10,
};

const NumberController = ({
  control,
  name,
  label,
  numberFormatProps = {},
  disabled,
  defaultValue = '',
  error,
  helperText,
  debounced,
  trigger,
  additionalTriggeredFields,
  isControlledComponent = false,
}: INumberController): JSX.Element => {
  const handleChange = useCallback(
    (v: string | number, onChange: (value: string | number) => void) => {
      onChange(v);
      if (trigger && additionalTriggeredFields) {
        void trigger(additionalTriggeredFields);
      }
    },
    [trigger, additionalTriggeredFields],
  );

  const debouncedChange = useMemo(() => {
    return debounce((v: string | number, onChange: (value: string | number) => void) =>
      handleChange(v, onChange),
    );
  }, [handleChange]);

  const renderControlledComponent = (): JSX.Element => (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <NumberTextField
          fullWidth
          variant="outlined"
          numberFormatProps={{ ...defaultNumberFormatProps, ...numberFormatProps }}
          label={label}
          value={field.value}
          onBlur={field.onBlur}
          onChange={event => handleChange(event, field.onChange)}
          disabled={disabled}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );

  const renderUncontrolledComponent = (): JSX.Element => (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <NumberTextField
          numberFormatProps={{ ...defaultNumberFormatProps, ...numberFormatProps }}
          variant="outlined"
          label={label}
          defaultValue={defaultValue}
          onChange={event =>
            debounced ? debouncedChange(event, field.onChange) : handleChange(event, field.onChange)
          }
          fullWidth
          disabled={disabled}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );

  return isControlledComponent ? renderControlledComponent() : renderUncontrolledComponent();
};

export default NumberController;
