import { List } from 'immutable';

import { ActionResult } from 'common/constants';

import {
  IFamilyMemberServiceInstanceDetailsDtoImt,
  IFamilyMemberToRedeemImt,
  InventoryInstanceDetailsDtoImt,
  InventoryRedeemHistoryDtoImt,
  IServiceItemDetailsImt,
  IServiceRedeemHistoryImt,
} from 'common/interfaces/service';
import { IBookingEventImt, IBookingEventListItemImt } from 'modules/booking/interfaces';
import { IRedeemResourceTagImt } from 'common/components/PersonProfile/interfaces';
import { AppStoreTypeImt } from 'store';
import { IPaginatedDataImt } from 'common/interfaces/pagination';

const serviceModalsPath = ['persons', 'serviceModals'];
const inventoryModalsPath = ['persons', 'inventoryModals'];
const familyMemberServiceModalsPath = ['persons', 'familyMemberServiceModals'];

// modals isOpen flags

export const selectIsDetailsShown = (state): boolean =>
  state.getIn([...serviceModalsPath, 'isDetailsShown']);

export const selectIsRedeemShown = (state): boolean =>
  state.getIn([...serviceModalsPath, 'isRedeemShown']);

// service instance details

export const selectSelectedServiceItemId = (state): string =>
  state.getIn([...serviceModalsPath, 'selectedServiceItemId']);

export const selectServiceItemDetails = (state): IServiceItemDetailsImt =>
  state.getIn([...serviceModalsPath, 'selectedServiceItemDetails', 'result']);

export const selectServiceItemDetailsLoading = (state): boolean =>
  state.getIn([...serviceModalsPath, 'selectedServiceItemDetails', 'isLoading']);

// redeem recent history

export const selectServiceRedeemRecentHistory = (state): List<IServiceRedeemHistoryImt> =>
  state.getIn([...serviceModalsPath, 'redeemRecentHistory', 'result']);

export const selectServiceRedeemRecentHistoryLoading = (state): boolean =>
  state.getIn([...serviceModalsPath, 'redeemRecentHistory', 'isLoading']);

// redeem history

export const selectServiceRedeemHistory = (state): IPaginatedDataImt<IServiceRedeemHistoryImt> =>
  state.getIn([...serviceModalsPath, 'redeemHistory', 'result']);

export const selectServiceRedeemHistoryLoading = (state): boolean =>
  state.getIn([...serviceModalsPath, 'redeemHistory', 'isLoading']);

// service events

export const selectServiceEvents = (state): List<IBookingEventListItemImt> =>
  state.getIn(['persons', 'serviceEvents', 'result']);
export const selectServiceEventsLoading = (state): boolean =>
  state.getIn(['persons', 'serviceEvents', 'isLoading']);

export const selectServiceEventDetails = (state): IBookingEventImt =>
  state.getIn(['persons', 'serviceEventDetails', 'result']);
export const selectServiceEventDetailsLoading = (state): boolean =>
  state.getIn(['persons', 'serviceEventDetails', 'isLoading']);

// service resources

export const selectServiceResources = (state): List<IRedeemResourceTagImt> =>
  state.getIn(['persons', 'serviceResourceTags', 'result']);
export const selectServiceResourcesLoading = (state): boolean =>
  state.getIn(['persons', 'serviceResourceTags', 'isLoading']);

export const selectServiceRedeemActionResult = (state): ActionResult =>
  state.getIn([...serviceModalsPath, 'redeemAction', 'result']);

export const selectServiceRedeemActionLoading = (state): boolean =>
  state.getIn([...serviceModalsPath, 'redeemAction', 'isLoading']);

// inventory modals isOpen flags

export const selectIsInventoryDetailsShown = (state): boolean =>
  state.getIn([...inventoryModalsPath, 'isDetailsShown']);

export const selectIsInventoryRedeemShown = (state): boolean =>
  state.getIn([...inventoryModalsPath, 'isRedeemShown']);

// inventory instance details

export const selectSelectedInventoryId = (state): string =>
  state.getIn([...inventoryModalsPath, 'selectedInventoryId']);

export const selectInventoryDetails = (state): InventoryInstanceDetailsDtoImt =>
  state.getIn([...inventoryModalsPath, 'selectedInventoryDetails', 'result']);

export const selectInventoryDetailsLoading = (state): boolean =>
  state.getIn([...inventoryModalsPath, 'selectedInventoryDetails', 'isLoading']);

// redeem inventory recent history

export const selectInventoryRedeemRecentHistory = (state): List<InventoryRedeemHistoryDtoImt> =>
  state.getIn([...inventoryModalsPath, 'redeemRecentHistory', 'result']);

export const selectInventoryRedeemRecentHistoryLoading = (state): boolean =>
  state.getIn([...inventoryModalsPath, 'redeemRecentHistory', 'isLoading']);

// redeem inventory history

export const selectInventoryRedeemHistory = (
  state,
): IPaginatedDataImt<InventoryRedeemHistoryDtoImt> =>
  state.getIn([...inventoryModalsPath, 'redeemHistory', 'result']);

export const selectInventoryRedeemHistoryLoading = (state): boolean =>
  state.getIn([...inventoryModalsPath, 'redeemHistory', 'isLoading']);

// redeem inventory actions

export const selectInventoryRedeemActionResult = (state): ActionResult =>
  state.getIn([...inventoryModalsPath, 'redeemAction', 'result']);

export const selectInventoryRedeemActionLoading = (state): boolean =>
  state.getIn([...inventoryModalsPath, 'redeemAction', 'isLoading']);

// family member service modals isOpen flags

export const selectIsFamilyMemberServiceDetailsShown = (state): boolean =>
  state.getIn([...familyMemberServiceModalsPath, 'isDetailsShown']);

export const selectIsFamilyMemberServiceRedeemShown = (state): boolean =>
  state.getIn([...familyMemberServiceModalsPath, 'isRedeemShown']);

// family member service instance details

export const selectSelectedFamilyMemberServiceId = (state): string =>
  state.getIn([...familyMemberServiceModalsPath, 'selectedFamilyMemberServiceId']);

export const selectFamilyMemberServiceDetails = (
  state,
): IFamilyMemberServiceInstanceDetailsDtoImt =>
  state.getIn([...familyMemberServiceModalsPath, 'selectedFamilyMemberServiceDetails', 'result']);

export const selectFamilyMemberServiceDetailsTitle = (state): string =>
  state.getIn([
    ...familyMemberServiceModalsPath,
    'selectedFamilyMemberServiceDetails',
    'result',
    'title',
  ]);

export const selectFamilyMemberServiceDetailsLoading = (state): boolean =>
  state.getIn([
    ...familyMemberServiceModalsPath,
    'selectedFamilyMemberServiceDetails',
    'isLoading',
  ]);

export const selectFamilyMembersToRedeem = (
  state: AppStoreTypeImt,
): List<IFamilyMemberToRedeemImt> | null => {
  return state.getIn([...familyMemberServiceModalsPath, 'familyMembers', 'result']);
};

export const selectFamilyMembersToRedeemLoading = (state: AppStoreTypeImt): boolean => {
  return state.getIn([...familyMemberServiceModalsPath, 'familyMembers', 'isLoading']);
};

export const selectRedeemFamilyMembersLoading = (state: AppStoreTypeImt): boolean => {
  return state.getIn([...familyMemberServiceModalsPath, 'redeemAction', 'isLoading']);
};
export const selectRedeemFamilyMembersActionResult = (state: AppStoreTypeImt): ActionResult => {
  return state.getIn([...familyMemberServiceModalsPath, 'redeemAction', 'result']);
};
