// library
import React from 'react';
import { MenuItem } from '@material-ui/core';
import inputLabels from 'common/messages/inputLabels';
import { DialogComponent, Select } from 'common/components';
import messages from '../messages';
import IntlService from 'services/intl/IntlService';
import { useGetClubsDictionaryQuery } from 'modules/crm/state/leads/lead.api';
import { Controller, useForm } from 'react-hook-form';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import * as yup from 'yup';
import { getRequiredErrorMessage } from 'common/utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchema = yup.object({
  clubId: yup.string().required(getRequiredErrorMessage),
});

interface ILeadHomeClubModalProps {
  onClose: () => void;
  onSubmit: (clubId: string | number) => void;
}

function LeadHomeClubModal({ onClose, onSubmit }: ILeadHomeClubModalProps): JSX.Element {
  const renderIntlMessage = useRenderIntlMessage();

  const { data: clubsData } = useGetClubsDictionaryQuery();

  const formMethods = useForm<{ clubId: string }>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const { handleSubmit, control, formState } = formMethods;
  const { errors } = formState;

  return (
    <>
      <DialogComponent
        isOpen
        title={IntlService.formatMessage(messages.newMemberPrimaryInfoSelecthomeclub)}
        onClose={onClose}
        size="md"
        formId="primary-info-form"
        onSubmit={handleSubmit(data => onSubmit(data.clubId))}
      >
        <Controller
          name="clubId"
          control={control}
          render={({ field: { name, value, onChange, onBlur } }) => (
            <Select
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={(id: string) => {
                onChange(id);
              }}
              fullWidth
              label={IntlService.formatMessage(inputLabels.homeClub)}
              error={!!errors.clubId}
              helperText={renderIntlMessage(errors.clubId?.message)}
            >
              {clubsData?.data.map(clubItem => (
                <MenuItem key={clubItem.id} value={clubItem.id}>
                  {clubItem.title}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </DialogComponent>
    </>
  );
}

export default LeadHomeClubModal;
