import * as yup from 'yup';
import { getRequiredErrorMessage } from 'common/utils/validation';
import inputErrors from 'common/messages/inputErrors';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';

export const insuranceValidationSchema = (requiredFields: TShortProfileFieldInfoImt) => {
  return {
    insuranceCompany: yup
      .object()
      .shape({
        id: yup.string(),
        imageUrl: yup.string().optional(),
        title: yup.string(),
        active: yup.boolean().optional(),
      })
      .nullable()
      .when('insuranceCompany1', {
        is: () => {
          return requiredFields.get(RequiredProfileInfo.InsuranceInfo) === FieldNecessity.Required;
        },
        then: schema => schema.required(getRequiredErrorMessage),
        otherwise: schema => schema,
      }),
    insuranceMemberId: yup
      .string()
      .trim()
      .max(50, () => inputErrors.insuranceIdLengthError)
      .defined()
      .when('insuranceMemberId1', {
        is: () => requiredFields.get(RequiredProfileInfo.InsuranceInfo) === FieldNecessity.Required,
        then: schema => schema.required(getRequiredErrorMessage),
        otherwise: schema => schema,
      }),
  };
};
