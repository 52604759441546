import Services from 'services/network';
import baseApi from 'api/baseApi';
import { IProfileInfo, ITag } from 'common/components/PersonProfile/interfaces';
import { updatePersonTagsAction } from './actions';
import { PeakModules } from 'common/constants/peakModules';

const getSetTagsEndpoint = (module: PeakModules, personId: number) => {
  switch (module) {
    case PeakModules.FrontDesk:
      return `/api/v1/front-desk/${personId}/tags`;
    case PeakModules.Members:
      return `/api/v1/members/${personId}/tags`;
    case PeakModules.Crm:
      return `/api/v1/leads/${personId}/tags`;
    default:
      return `/api/v1/pt-leads/${personId}/tags`;
  }
};

const PersonProfileApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    updatePersonTags: builder.mutation<
      Partial<IProfileInfo>,
      { data: ITag[]; personId: number; module: PeakModules }
    >({
      query: ({ data, personId, module }) => ({
        url: getSetTagsEndpoint(module, personId),
        method: 'PUT',
        data: data.map(tag => tag.id),
      }),

      async onCacheEntryAdded(arg, { dispatch }) {
        if (arg) {
          const personId = Number(arg.personId);

          let profile;

          switch (arg.module) {
            case PeakModules.FrontDesk:
              profile = await Services.FrontDesk.getProfileInfo(personId);
              break;
            case PeakModules.Members:
              profile = await Services.Members.getProfileInfo(personId);
              break;
            case PeakModules.Crm:
              profile = await Services.Leads.getLead(personId);
              break;
            case PeakModules.PersonalTrainingCrm:
              profile = await Services.PTLeads.getLead(personId);
              break;
            default:
          }

          dispatch(updatePersonTagsAction(profile.tags, personId));
        }
      },
    }),
  }),
});

export const { useUpdatePersonTagsMutation } = PersonProfileApi;
