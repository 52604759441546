import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'common/modals/WebcamModal/messages';
import { Box } from '@material-ui/core';
import { Button, Camera, DialogComponent, ImageCropper } from 'common/components';
import { ReactComponent as UndoIcon } from 'img/icons/undo-solid.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CustomTheme } from 'common/ui/interfaces';
import { ICropResult } from 'common/interfaces/uploadFile';
import useWindowWidth from 'common/hooks/useWindowWidth';
import { generateFileName } from 'common/utils';

interface IWebcamModalProps {
  isOpen: boolean;
  onUpload: (file: File) => void;
  onToggle: () => void;
  isError?: boolean;
  isLoading?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  webcamImg: {
    width: 'fit-content',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  undoIcon: {
    width: '16px',
    height: '16px',
  },
  text: {
    fontSize: '14px',
  },
}));

const WebcamModal = (props: IWebcamModalProps) => {
  const { isOpen, isLoading, isError, onToggle, onUpload } = props;

  const [imgUrl, setImgUrl] = useState<string | null>(null);

  const croppedImageRef = useRef<ICropResult | null>(null);

  const classes = useStyles();

  const windowWidth = useWindowWidth(true);

  useEffect(() => {
    if (!isOpen) {
      croppedImageRef.current = null;
      setImgUrl(null);
    }
  }, [isOpen, setImgUrl]);

  const isSmallCamera = windowWidth < 400;
  const cameraSize = isSmallCamera ? 290 : 368;

  const imageCropperHeight = isSmallCamera ? '300px' : '368px';

  const onUploadImage = () => {
    const croppedImage = croppedImageRef.current;

    if (croppedImage) {
      const imageType = croppedImage.blob.type;

      const fileName = generateFileName(imageType);

      const file = new File([croppedImage.blob], fileName);

      onUpload(file);
    }
  };

  const onImageCropped = croppedImage => {
    croppedImageRef.current = croppedImage;
  };

  const onCloseWebcamModal = () => {
    setImgUrl(null);
    onToggle();
  };

  const onCapturePhoto = (imageUrl: string | null) => {
    setImgUrl(imageUrl);
  };

  const onUndo = () => {
    setImgUrl(null);
  };

  return (
    <DialogComponent
      size="xs"
      isOpen={isOpen}
      title={<FormattedMessage {...messages.modalWebcamImageTitle} />}
      disabled={!imgUrl && isError}
      loading={isLoading}
      onSubmit={onUploadImage}
      additionalLeftAlignedActionButtons={
        imgUrl ? (
          <Button
            color="primary"
            startIcon={<UndoIcon className={classes.undoIcon} />}
            onClick={onUndo}
          >
            <FormattedMessage {...messages.modalWebcamImageRetakePhotoTitle} />
          </Button>
        ) : (
          undefined
        )
      }
      onClose={onCloseWebcamModal}
    >
      <Box className={classes.webcamImg}>
        {imgUrl ? (
          <>
            <ImageCropper
              imageStyle={{ height: imageCropperHeight }}
              src={imgUrl}
              onCrop={onImageCropped}
            />
          </>
        ) : (
          <Camera width={cameraSize} height={cameraSize} onCapturePhoto={onCapturePhoto} />
        )}
      </Box>
    </DialogComponent>
  );
};

export default WebcamModal;
