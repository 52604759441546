import React from 'react';
import { Typography, Box, ListItem, Avatar, SvgIcon } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { PhoneIphone as PhoneIcon } from '@material-ui/icons';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { ICallNoteDtoImt } from 'common/components/PersonProfile/interfaces';

import { ReactComponent as ArrowForwardIcon } from 'img/arrow-right.svg';
import { convertPhoneNumber } from 'helpers/common';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    padding: theme.spacing(0, 2, 0, 0),
  },
  itemInner: {
    borderTop: `1px solid ${theme.palette.secondary.light}`,
    padding: theme.spacing(1.5, 0, 2, 0),
  },
  additionalInfoWrapper: {
    cursor: 'pointer',
  },
  phoneLabel: {
    fontWeight: 500,
  },
  phoneIcon: {
    width: '15px',
    height: '18px',
    marginRight: theme.spacing(0.5),
    color: theme.palette.text.secondary,
  },
  arrowIcon: {
    width: '12px',
    height: '12px',
    margin: '0 5px',
    color: theme.palette.text.secondary,
  },
  avatar: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
  },
  description: {
    lineHeight: '1.29',
    opacity: '0.7',
  },
}));

interface ICallsItemProps {
  call: ICallNoteDtoImt;
  onClick: (callId: string) => void;
}

const CallsItem = ({ call, onClick }: ICallsItemProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();
  const onCallClick = () => {
    onClick(call.get('id') || '');
  };

  return (
    <ListItem className={classes.root}>
      <Box className={classes.itemInner}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={0.5}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            flex={1}
          >
            <Box display="flex" alignItems="center">
              <Avatar src={call.getIn(['salesperson', 'imageUrl'])} className={classes.avatar} />
              <Typography component="span" variant="body2">
                {call.getIn(['salesperson', 'title'])}
              </Typography>
            </Box>

            <Box>
              <Typography color="textSecondary" variant="body2">
                {timezoneMoment(
                  `${call.get('date')} ${call.get('time')}`,
                  'YYYY-MM-DD HH:mm',
                  true,
                ).format('hh:mm A, MMM D')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          mb={1}
          className={classes.additionalInfoWrapper}
          onClick={onCallClick}
        >
          <PhoneIcon className={classes.phoneIcon} />

          <Typography
            className={classes.phoneLabel}
            color="secondary"
            component="span"
            variant="body2"
          >
            {convertPhoneNumber(call.get('salespersonPhone'))}
          </Typography>

          <SvgIcon className={classes.arrowIcon}>
            <ArrowForwardIcon />
          </SvgIcon>

          <Typography
            className={classes.phoneLabel}
            color="secondary"
            component="span"
            variant="body2"
          >
            {convertPhoneNumber(call.getIn(['leadPhone', 'title']))}
          </Typography>
        </Box>

        <Typography className={classes.description} component="span" variant="body2">
          {call.get('note')}
        </Typography>
      </Box>
    </ListItem>
  );
};

export default CallsItem;
