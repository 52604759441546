import React, { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'store/hooks';
import useRootSelector from 'common/hooks/useRootSelector';
import { getRequiredErrorMessage } from 'common/utils/validation';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { AlertCondition } from 'common/interfaces/alerts';
import { INamedEntity } from 'common/interfaces/common';
import { DialogComponent, MultipleSelect } from 'common/components';
import inputLabels from 'common/messages/inputLabels';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';
import { useGetSalespersons } from 'modules/members/api/member.api';

interface IFormValues {
  salesperson: INamedEntity<number> | null;
}

interface ISalespersonModal {
  alert: AlertCondition | null;
  isOpen: boolean;
  onSubmit: (salespersonId: string, alertId: string | null) => void;
  switchingModal: (alertCondition: AlertCondition | null) => void;
}

const SalespersonModal: FC<ISalespersonModal> = ({ isOpen, switchingModal, onSubmit, alert }) => {
  const { data: salespersons = [], isLoading } = useGetSalespersons(undefined, { skip: !isOpen });
  const dispatch = useAppDispatch();
  const profileRequiredFields = useRootSelector(selectProfileRequiredFields);
  const isSubmitted = useRootSelector(selectIsSubmittedRecommendedFieldsModal);
  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();

  const formMethods = useForm<IFormValues>({
    defaultValues: {
      salesperson: null,
    },
    resolver: yupResolver(
      yup.object({
        salesperson: yup
          .object()
          .shape({
            id: yup.string(),
          })
          .nullable()
          .when(RequiredProfileInfo.Salesperson, {
            is: () => {
              return (
                profileRequiredFields.get(RequiredProfileInfo.Salesperson) ===
                FieldNecessity.Required
              );
            },
            then: schema => schema.required(getRequiredErrorMessage),
            otherwise: schema => schema,
          }),
      }),
    ),
    mode: 'onBlur',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = formMethods;

  const renderIntlMessage = useRenderIntlMessage();

  useEffect(() => {
    if (isSubmitted) {
      const { salesperson } = getValues();

      if (salesperson) {
        onSubmit(String(salesperson.id), alert);
      }

      switchingModal(alert);

      dispatch(showRecommendedFieldsModal(false));
    }
  }, [alert, dispatch, getValues, isSubmitted, onSubmit, switchingModal]);

  const onSubmitForm = (values: { salesperson: INamedEntity<number> }): void => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty({
      emergencyContactDto: values,
    });

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(String(values.salesperson.id), alert);
      switchingModal(alert);
    }
  };

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...inputLabels.salesperson} />}
      isOpen={isOpen}
      onClose={() => {
        if (alert) {
          switchingModal(alert);
        }
      }}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <Controller
        name="salesperson"
        control={control}
        render={({ field }) => (
          <MultipleSelect
            fullWidth
            label={<FormattedMessage {...inputLabels.salesperson} />}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={!!errors.salesperson}
            helperText={renderIntlMessage(errors?.salesperson?.message)}
            options={salespersons}
            loading={isLoading}
            recommended={Boolean(profileRecommendedFields?.get(RequiredProfileInfo.Salesperson))}
          />
        )}
      />
    </DialogComponent>
  );
};

export default SalespersonModal;
