import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Grid, Box, Typography, Button, Avatar, makeStyles, Theme } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import cx from 'classnames';

import { DialogComponent } from 'common/components';

import { LeadStatuses } from 'modules/crm/constants/leads';

import { getInitials } from 'helpers/common';

import { ILeadImt } from 'modules/crm/interfaces/leads';
import { LeadStatus } from 'common/constants/personConstants';
import { PeakModules } from 'common/constants/peakModules';

import leadsMessages from 'modules/crm/messages/leads';
import ptCrmMessages from 'modules/personal-training-crm/messages/messages';
import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  avatarRow: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  avatarGroup: {
    flexWrap: 'wrap',
  },
  avatar: {
    width: '30px',
    height: '30px',
  },
  avatarWrap: {
    position: 'relative',
    flex: 'none',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.light,
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '0.675rem',
    fontWeight: 700,
    color: theme.palette.darkBackground?.light,
  },
  name: {
    marginLeft: theme.spacing(1),
  },
}));

interface IProps {
  openModal: boolean;
  selectedLeads: ImmutableList<ILeadImt>;
  onClose: () => void;
  onSubmit: (status: string) => void;
  module: PeakModules.Crm | PeakModules.PersonalTrainingCrm;
}

const ChangeStatusModal = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const { openModal, selectedLeads, onClose, onSubmit, module } = props;
  const [selectedStatus, setSelectedStatus] = useState<string>(
    selectedLeads.get(0) ? selectedLeads.get(0)?.get('status') || '' : LeadStatus.Active,
  );

  const isCrmModule = module === PeakModules.Crm;

  const onSelectStatus = s => {
    setSelectedStatus(s);
    onSubmit(s);
  };

  return (
    <DialogComponent
      title={
        <FormattedMessage
          {...(isCrmModule
            ? leadsMessages.changeStatusLeadProfileTitle
            : ptCrmMessages.changeStatusPtLeadProfileTitle)}
        />
      }
      submitBtnTitle={<FormattedMessage {...commonMessages.saveBtn} />}
      isOpen={openModal}
      onClose={onClose}
      footer={<Box pb={2} />}
      size="xs"
      disableFullScreenBehavior
    >
      <Box className={classes.avatarRow}>
        <AvatarGroup className={classes.avatarGroup} max={selectedLeads.size}>
          {selectedLeads.map(leadItem => {
            const initials = getInitials(
              leadItem.get('firstName') || '',
              leadItem.get('lastName') || '',
            );

            return (
              <Box className={cx(classes.avatar, classes.avatarWrap)}>
                {initials && !leadItem.get('imageUrl') ? (
                  <Box className={classes.avatarInitial}>{initials}</Box>
                ) : (
                  <Avatar
                    key={leadItem.get('id')}
                    className={classes.avatar}
                    alt={`${leadItem.get('firstName')} ${leadItem.get('lastName')}`}
                    src={leadItem.getIn(['image', 'filePath']) || leadItem.get('imageUrl')}
                  />
                )}
              </Box>
            );
          })}
        </AvatarGroup>
        {selectedLeads.size === 1 && (
          <Typography className={classes.name}>
            {`${selectedLeads.get(0)?.get('firstName')} ${selectedLeads.get(0)?.get('lastName')}`}
          </Typography>
        )}
      </Box>

      <Grid container spacing={1}>
        {LeadStatuses.values.map(statusItem => {
          const isSelected = statusItem.value === selectedStatus;

          return (
            <Grid item key={statusItem.value}>
              <Button
                style={{
                  color: isSelected ? '#fff' : statusItem.color,
                  backgroundColor: isSelected ? statusItem.color : '#fff',
                  border: `1px solid ${statusItem.color}`,
                  boxShadow: 'none',
                }}
                variant="contained"
                onClick={() => onSelectStatus(statusItem.value)}
              >
                <FormattedMessage {...statusItem.message} />
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </DialogComponent>
  );
};

export default React.memo(ChangeStatusModal);
