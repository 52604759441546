import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Box, Grid } from '@material-ui/core';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { ShortWeekdays, Weekday } from 'common/interfaces/common';
import FormHelperText from '@material-ui/core/FormHelperText';

interface IWeekdaysSelectorProps {
  selectedDays: Weekday[];
  errorMessage?: string | JSX.Element;

  onChange: (selectedDays: Weekday[]) => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  weekday: {
    flex: 1,

    '&:last-child': {
      '& .MuiBox-root': {
        marginRight: 0,
        borderTopRightRadius: '3px',
        borderBottomRightRadius: '3px',
      },
    },
    '&:first-child': {
      '& .MuiBox-root': {
        borderTopLeftRadius: '3px',
        borderBottomLeftRadius: '3px',
      },
    },
  },
  weekdayBtn: {
    height: '32px',
    minWidth: '32px',
    border: '1px solid',
    borderColor: theme.palette.borderColor?.main,
    marginRight: '-1px',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.contrastText,
  },
  weekdayBtnActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  weekdayBtnError: {
    borderColor: theme.palette.error.main,
  },
}));

const WeekdaysSelector: React.FC<IWeekdaysSelectorProps> = ({
  selectedDays = [],
  errorMessage,

  onChange,
}: IWeekdaysSelectorProps): JSX.Element => {
  const classes = useStyles();

  // handlers

  const isSelectedDay = (day: Weekday) => selectedDays.includes(day);

  const handleWeekdayButtonClick = (day: Weekday) => {
    const resultList = isSelectedDay(day)
      ? selectedDays.filter(d => d !== day)
      : [...selectedDays, day];

    resultList.sort((weekday1, weekday2) => {
      const sortedWeekdays = Object.values(Weekday);

      return (
        sortedWeekdays.findIndex(sWeekday => sWeekday === weekday1) -
        sortedWeekdays.findIndex(sWeekday => sWeekday === weekday2)
      );
    });

    onChange(resultList);
  };

  return (
    <Grid item md xs={12} container direction="column">
      <Grid container wrap="nowrap">
        {ShortWeekdays.values.map(option => (
          <Grid item key={option.key} className={classes.weekday}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={cx(classes.weekdayBtn, {
                [classes.weekdayBtnActive]: isSelectedDay(option.value as Weekday),
                [classes.weekdayBtnError]: !!errorMessage,
              })}
              onClick={() => handleWeekdayButtonClick(option.value as Weekday)}
            >
              {ShortWeekdays.translate(option.value)}
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid container>
        <FormHelperText error={!!errorMessage}>{errorMessage}</FormHelperText>
      </Grid>
    </Grid>
  );
};

export default WeekdaysSelector;
