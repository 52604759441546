import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import { Add as AddIcon } from '@material-ui/icons';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { Box, FormControlLabel, Grid, Radio, Typography } from '@material-ui/core';
import { Button, PaymentField } from 'common/components/index';

import invoiceMessages from 'common/components/InvoiceOperating/messages';
import inputLabels from 'common/components/../messages/inputLabels';
import commonMessages from 'common/messages/messages';

import { getStepStyles } from '../styleConstants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { PeakModules } from 'common/constants/peakModules';

import AddCheckingSavingsModal from 'common/components/PersonProfile/modals/AddCheckingSavingsModal/AddCheckingSavingsModal';
import * as actions from 'common/state/invoice/actions';
import {
  selectAddInvoiceCheckingSavingsDataActionResult,
  selectAddInvoiceCheckingSavingsDataLoading,
  selectCurrentInvoice,
  selectInvoiceCheckingSavingsData,
} from 'common/state/invoice/selectors';
import { ActionResult } from 'common/constants';
import { IInvoicePaymentSplit, PaymentsType } from 'common/interfaces/invoices';
import { CheckingSavingsTypes } from 'common/constants/invoices';
import { updateInvoiceWithSync } from 'common/state/invoice/actions';
import { StepContext } from '../../../../../createContext/stepContext';
import { IPaymentAccountImt } from '../../../../PersonProfile/interfaces';
import { useUpdatePaymentData } from '../useUpdatePaymentData';
import useRootSelector from 'common/hooks/useRootSelector';

interface ICheckingSavingsPaymentStepProps {
  leftToPay: number;
  memberId?: number;
  clubId: string;

  module: PeakModules;
  isPaymentStep: boolean;
  paymentTypeId?: string;

  onClose: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  ...getStepStyles(theme),
  radioLabel: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2.5),
    },
  },
  radio: {
    '&:hover': {
      boxShadow: 'none',
      borderRadius: '50%',
    },
  },
}));

const CheckingSavingsStep: React.FC<ICheckingSavingsPaymentStepProps> = (
  props: ICheckingSavingsPaymentStepProps,
): JSX.Element => {
  const { leftToPay, memberId, module, paymentTypeId, clubId, isPaymentStep, onClose } = props;
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const renderIntlMessage = useRenderIntlMessage();

  const { helperData } = useContext(StepContext);
  const { profileId } = helperData || {};

  const currentInvoice = useRootSelector(selectCurrentInvoice);
  const checkingSavingsData = useRootSelector(selectInvoiceCheckingSavingsData);
  const isAddCheckingSavingsDataLoading = useRootSelector(
    selectAddInvoiceCheckingSavingsDataLoading,
  );
  const checkingSavingsAddActionResult = useRootSelector(
    selectAddInvoiceCheckingSavingsDataActionResult,
  );

  const [selectedOption, setSelectedOption] = useState<IPaymentAccountImt | null>(null);
  const [isOpenCheckingSavingsModal, setIsOpenCheckingSavingsModal] = useState(false);

  const defaultValues = {
    amount: leftToPay,
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues,
  });

  const handleChangeAmount = (newAmount: number, onChange: (...event: any) => void): void => {
    onChange(newAmount);
  };

  const handleProceed = data => {
    const invoiceData = currentInvoice.toJS();
    const invoicePaymentSplit: IInvoicePaymentSplit = {
      paymentMethodId: paymentTypeId,
      type: PaymentsType.CHECKING_SAVINGS,
      paymentAmount: data.amount,
      paymentAccountId: selectedOption?.get('id'),
    };

    dispatch(
      updateInvoiceWithSync(
        module,
        clubId,
        invoiceData,
        invoicePaymentSplit,
        profileId,
        isPaymentStep,
      ),
    );
  };

  useEffect(() => {
    if (!selectedOption && checkingSavingsData?.size) {
      setSelectedOption(checkingSavingsData.get(0));
    }
  }, [selectedOption, setSelectedOption, checkingSavingsData]);

  useEffect(() => {
    if (memberId) {
      dispatch(actions.fetchInvoiceCheckingSavingsDataThunk(memberId, module));
    }
    return () => {
      dispatch(actions.resetInvoiceCheckingSavingsData());
    };
  }, [dispatch, memberId, module]);

  useEffect(() => {
    if (checkingSavingsAddActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.addInvoiceCheckingSavingsDataActionResult(null));
      setIsOpenCheckingSavingsModal(false);
    }
  }, [setIsOpenCheckingSavingsModal, dispatch, checkingSavingsAddActionResult]);

  useUpdatePaymentData(module, profileId, onClose);

  const onAddCheckingSavingsSubmit = values => {
    dispatch(actions.addInvoiceCheckingSavingsDataThunk(Number(memberId), values, module));
  };

  return (
    <>
      <Grid container spacing={2} className={classes.body}>
        {checkingSavingsData?.map(option => {
          return (
            <Grid item xs={12}>
              <FormControlLabel
                className={classes.radioLabel}
                control={<Radio className={classes.radio} color="primary" />}
                checked={option.get('id') === selectedOption?.get('id')}
                onClick={() => setSelectedOption(option)}
                label={
                  <Box display="flex" alignItems="center">
                    <FormattedMessage {...invoiceMessages.payWith} />
                    &nbsp;
                    <Typography variant="h5">
                      {option.get('checkType') === CheckingSavingsTypes.Checking ? (
                        <FormattedMessage {...commonMessages.checkingLabel} />
                      ) : (
                        <FormattedMessage {...commonMessages.savingsLabel} />
                      )}
                      &nbsp;
                      {option.get('routingNumber')}
                    </Typography>
                  </Box>
                }
              />
            </Grid>
          );
        })}

        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setIsOpenCheckingSavingsModal(true)}
        >
          <FormattedMessage {...commonMessages.addCheckingSavingsLabel} />
        </Button>

        <Grid item sm={12} xs={12}>
          <Controller
            control={control}
            name="amount"
            render={({ field }) => {
              return (
                <PaymentField
                  fullWidth
                  variant="outlined"
                  name={field.name}
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={val => handleChangeAmount(+val, field.onChange)}
                  defaultValue={defaultValues.amount}
                  label={<FormattedMessage {...inputLabels.amountToPay} />}
                  error={!!errors.amount}
                  helperText={renderIntlMessage(errors.amount?.message)}
                />
              );
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.footerActions}>
        <Grid item sm={12} xs={12}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleSubmit(handleProceed)}
            disabled={!checkingSavingsData?.size}
          >
            <FormattedMessage {...commonMessages.proceedBtn} />
          </Button>
        </Grid>
      </Grid>

      {isOpenCheckingSavingsModal && (
        <AddCheckingSavingsModal
          isLoading={isAddCheckingSavingsDataLoading}
          isOpen={isOpenCheckingSavingsModal}
          onClose={() => setIsOpenCheckingSavingsModal(false)}
          onSubmit={onAddCheckingSavingsSubmit}
        />
      )}
    </>
  );
};

export default CheckingSavingsStep;
