/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { Component } from 'react';
import { Box, List, StyledComponentProps, Typography, withStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { Connect } from 'common/decorators';
import { LoadingBackdrop, ScrollBox } from 'common/components';
import { CheckInBlock, Slider } from 'common/components/PersonProfile/components';
import { DispatchFunction } from 'common/state/interfaces';
import {
  IReminderRecent,
  IReminderRecentImt,
  IRequestResultImt,
} from 'common/components/PersonProfile/interfaces';
import {
  makeSelectAddPersonReminderActionResult,
  makeSelectPersonRecentReminders,
  makeSelectPersonRecentRemindersLoading,
} from 'common/components/PersonProfile/state/selectors';
import * as actions from 'common/components/PersonProfile/state/actions';
import ReminderItem from './ReminderItem/ReminderItem';
import ReminderModal from './modals/ReminderModal/ReminderModal';
import messages from 'common/components/PersonProfile/messages';
import ReminderDetailsModal from './modals/ReminderDetailsModal/ReminderDetailsModal';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import RemindersHistoryModal from './modals/RemindersHistoryModal/RemindersHistoryModal';
import RemindersBlockSectionActions from './RemindersBlockSectionActions/RemindersBlockSectionActions';
import { selectRoot } from 'common/hooks/useRootSelector';

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%',
      minHeight: '150px',
    },
    list: {
      padding: '0',
      marginRight: theme.spacing(-2),
      height: 'calc(100% - 30px)',
      overflow: 'hidden',
    },
    noteInput: {
      marginBottom: theme.spacing(0.5),
      '& .MuiOutlinedInput-multiline': {
        padding: theme.spacing(2, 1.5),
      },
      '& textarea': {
        minHeight: '113px',
        maxHeight: '200px',
        overflowY: 'auto!important',
      },
    },
    symbols: {
      textAlign: 'right',
      opacity: 0.4,
    },
    label: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(1.5, 0, -1, 0),
      '& span': {
        fontWeight: 500,
      },
    },
  });

interface IRemindersProps extends StyledComponentProps {
  personId: number;
  selectedClubId: string;

  reminders?: ImmutableList<IReminderRecentImt>;
  remindersLoading?: boolean;
  isMobile?: boolean;

  fetchPersonReminders?: (personId: number, module: PeakModuleForNewPersonType) => void;
  addPersonReminder?: (
    personId: number,
    formData: IReminderRecent,
    module: PeakModuleForNewPersonType,
  ) => void;
  resetAddPersonReminder?: (personId: number) => void;
  addReminderActionResult?: IRequestResultImt;
  module?: PeakModuleForNewPersonType;
}

interface IRemindersState {
  isReminderModalOpen: boolean;
  isReminderDetailsModalOpen: boolean;
  isRemindersHistoryModalOpen: boolean;
  selectedReminder: IReminderRecentImt | null;
}

@(Connect({
  makeStateToProps: () => {
    const selectPersonNotes = selectRoot(makeSelectPersonRecentReminders());
    const selectPersonNotesLoading = selectRoot(makeSelectPersonRecentRemindersLoading());
    const selectAddReminderActionResult = selectRoot(makeSelectAddPersonReminderActionResult());

    return (state, ownProps) => ({
      reminders: selectPersonNotes(state, ownProps),
      remindersLoading: selectPersonNotesLoading(state, ownProps),
      addReminderActionResult: selectAddReminderActionResult(state, ownProps),
    });
  },
  mapDispatchToProps: (dispatch: DispatchFunction) => ({
    fetchPersonReminders: (personId: number, module: PeakModuleForNewPersonType) => {
      dispatch(actions.fetchPersonRecentReminders(personId, module));
    },
    resetAddPersonReminder: (personId: number) => {
      dispatch(actions.resetAddPersonReminderAction(null, personId));
    },
    addPersonReminder: (
      personId: number,
      formData: IReminderRecent,
      module: PeakModuleForNewPersonType,
    ) => {
      dispatch(actions.addPersonReminder(personId, formData, module));
    },
  }),
}) as any)
@(withStyles(styles) as any)
class RemindersBlock extends Component<IRemindersProps, IRemindersState> {
  constructor(props: IRemindersProps) {
    super(props);
    this.state = {
      isReminderModalOpen: false,
      isReminderDetailsModalOpen: false,
      isRemindersHistoryModalOpen: false,
      selectedReminder: null,
    };
  }

  componentDidMount(): void {
    const { personId, fetchPersonReminders, module } = this.props;

    if (fetchPersonReminders && module) {
      fetchPersonReminders(personId, module);
    }
  }

  componentDidUpdate(prevProps: IRemindersProps): void {
    const {
      selectedClubId,
      personId,
      fetchPersonReminders,
      module,
      addReminderActionResult,
      resetAddPersonReminder,
    } = this.props;

    if (addReminderActionResult?.get('result')) {
      if (fetchPersonReminders && module) {
        fetchPersonReminders(personId, module);
      }

      if (resetAddPersonReminder) {
        resetAddPersonReminder(personId);
      }
    }

    if (prevProps.selectedClubId === selectedClubId && prevProps.personId === personId) {
      return;
    }

    if (fetchPersonReminders && module) {
      fetchPersonReminders(personId, module);
    }
  }

  private onToggleReminderModal = (): void => {
    const { isReminderModalOpen } = this.state;
    this.setState({ isReminderModalOpen: !isReminderModalOpen });
  };

  private onSelectReminderItem = (reminderItem: IReminderRecentImt): void => {
    this.setState({ selectedReminder: reminderItem, isReminderDetailsModalOpen: true });
  };

  private onCloseReminderDetailsModal = (): void => {
    this.setState({ selectedReminder: null, isReminderDetailsModalOpen: false });
  };

  private onCloseRemindersHistoryModal = (): void => {
    this.setState({ isRemindersHistoryModalOpen: false });
  };

  private onOpenRemindersHistoryModal = (): void => {
    this.setState({ isRemindersHistoryModalOpen: true });
  };

  private onSubmitReminderForm = (values: IReminderRecent) => {
    const { personId, addPersonReminder, module } = this.props;
    const formData = { ...values };

    if (addPersonReminder && module) {
      addPersonReminder(personId, formData, module);
    }

    this.onToggleReminderModal();
  };

  private renderReminders = (): JSX.Element | null => {
    const { reminders } = this.props;

    if (!!reminders && !!reminders.size) {
      return (
        <>
          {reminders.map((item: IReminderRecentImt) => (
            <ReminderItem
              key={item.get('text')}
              reminder={item}
              onClick={this.onSelectReminderItem}
            />
          ))}
        </>
      );
    }

    return null;
  };

  render(): JSX.Element {
    const { classes, isMobile, remindersLoading, personId, module } = this.props;
    const {
      isReminderModalOpen,
      isReminderDetailsModalOpen,
      selectedReminder,
      isRemindersHistoryModalOpen,
    } = this.state;
    const listItems = this.renderReminders();

    return (
      <CheckInBlock
        title={<FormattedMessage {...messages.remindersTitle} />}
        buttonGroup={
          <RemindersBlockSectionActions
            onHistoryClick={this.onOpenRemindersHistoryModal}
            onAddClick={this.onToggleReminderModal}
          />
        }
        className={classes?.wrapper}
      >
        {listItems ? (
          <List className={classes?.list}>
            {isMobile === true && <Slider rows={3}>{listItems}</Slider>}
            {isMobile === false && <ScrollBox>{listItems}</ScrollBox>}
          </List>
        ) : (
          <Box className="empty-section-placeholder">
            <Typography className="empty-text">
              <FormattedMessage {...messages.emptyReminderListTextMessage} />
            </Typography>
            <Typography className="empty-text">
              <FormattedMessage {...messages.emptyReminderListTextDescription} />
            </Typography>
          </Box>
        )}

        <LoadingBackdrop isLoading={remindersLoading} />

        {isReminderModalOpen && (
          <ReminderModal
            isOpen={isReminderModalOpen}
            onClose={this.onToggleReminderModal}
            onSubmit={this.onSubmitReminderForm}
          />
        )}

        {isReminderDetailsModalOpen && (
          <ReminderDetailsModal
            isOpen={isReminderDetailsModalOpen}
            onClose={this.onCloseReminderDetailsModal}
            reminderId={selectedReminder?.get('id')}
            personId={personId}
            module={module}
          />
        )}
        {isRemindersHistoryModalOpen && (
          <RemindersHistoryModal
            isOpen
            onClose={this.onCloseRemindersHistoryModal}
            personId={personId}
            module={module}
          />
        )}
      </CheckInBlock>
    );
  }
}

export default RemindersBlock;
