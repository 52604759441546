import useRootSelector from 'common/hooks/useRootSelector';
import moment from 'moment-timezone';

import { selectUserSelectedOrganization } from 'modules/authentication/state/selectors';

import { IUserOrganizationImt } from 'common/interfaces/clients';

interface ITimeFromCheckIn {
  seconds?: number | null;
  minutes?: number | null;
  hours?: number | null;
  elapsedTimeLabel?: string | null;
}

const useTimeFromCheckIn = (checkInTime?: string): ITimeFromCheckIn => {
  const selectedOrg: IUserOrganizationImt = useRootSelector(selectUserSelectedOrganization);

  if (!checkInTime) {
    return {
      elapsedTimeLabel: null,
      seconds: null,
      minutes: null,
      hours: null,
    };
  }

  const clubTime = moment.tz(selectedOrg.get('timezone'));

  const secondsFromCheckIn = clubTime.diff(`${checkInTime}Z`, 'seconds');
  const minutesFromCheckIn = clubTime.diff(`${checkInTime}Z`, 'minutes');
  const hoursFromCheckIn = clubTime.diff(`${checkInTime}Z`, 'hours');

  const minutesLabel = Math.trunc(minutesFromCheckIn % 60);
  const secondsLabel = secondsFromCheckIn % 60;

  const elapsedTimeLabel = hoursFromCheckIn
    ? `${hoursFromCheckIn}h ${minutesLabel}m`
    : `${minutesLabel}m  ${secondsLabel}s`;

  return {
    elapsedTimeLabel,
    seconds: secondsFromCheckIn,
    minutes: minutesFromCheckIn,
    hours: hoursFromCheckIn,
  };
};

export default useTimeFromCheckIn;
