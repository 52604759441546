import React, { FC } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid } from '@material-ui/core';
import { ScrollBox } from 'common/components';
import { CustomTheme } from 'common/ui/interfaces';
import { replaceSignatureImg, replaceSignatureTag } from '../helpers';

interface IContract {
  canClearSignature: boolean;
  documentText?: string;
  signatureId?: string;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  contract: {
    height: '100%',
    border: `1px solid ${theme.palette.borderColor?.main}`,
    borderRadius: '3px',
    padding: '16px',
    overflowY: 'auto',
    backgroundColor: 'white',
    '& a': {
      textDecoration: 'underline',
      color: theme.palette.info.main,
    },
  },
  scrollContainer: {
    padding: '6px 15px',
  },
  signature: {
    height: '100px',
    paddingTop: 0,
    paddingBottom: 0,

    '&>img': {
      width: '161px',
      height: '64px',
    },
  },
}));

const Contract: FC<IContract> = ({ documentText, canClearSignature, signatureId }) => {
  const classes = useStyles();

  const html =
    canClearSignature && signatureId
      ? replaceSignatureImg(documentText || '', signatureId)
      : replaceSignatureTag(documentText || '');

  return (
    <Grid className={classes.contract}>
      <ScrollBox suppressScrollX suppressScrollY>
        <div
          id="contract-template"
          dangerouslySetInnerHTML={{
            __html: html.replaceAll('\n', '<br>'),
          }}
        />
      </ScrollBox>
    </Grid>
  );
};

export default Contract;
