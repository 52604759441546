import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  SvgIcon,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import useRootSelector from 'common/hooks/useRootSelector';
import { setIsSidebarOpen } from 'common/state/settings/actions';
import { selectIsSidebarOpen } from 'common/state/settings/selectors';
import { selectCurrentUserSelectedLevel } from 'modules/authentication/state/selectors';
import { PermissionLevels } from 'common/constants/permissions';
import { HOME_PATH } from 'common/constants/globalConstants';
import useMenuState from 'common/hooks/useMenuState';
import { useAppDispatch } from 'store/hooks';
import {
  ClubSelector,
  HeaderClubLogo,
  LanguageSelector,
  UserMenu,
  UserTimezoneSelector,
  // UserNotifications, // TODO refactor notifications
} from './components';
import { GlobalSearch, HelpInfoSearch, ContactPopover } from 'components';
import { CustomTheme } from 'common/ui/interfaces';
import { ReactComponent as Hamburger } from 'img/icons/hamburger.svg';
import { ReactComponent as HelpIcon } from 'img/icons/help.svg';
import { ReactComponent as SearchIcon } from 'img/icons/search.svg';
import { ReactComponent as TimeclockIcon } from 'img/icons/hourglass.svg';
import { ReactComponent as PhoneIcon } from 'img/icons/phone.svg';
import { ClockInModal } from 'modules/timeclock/components';
import { selectUserRole } from 'common/state/userRole/selectors';
import MemberPortalCart from './components/MemberPortalCart';
import EditMemberProfilePasswordModal from 'memberPortalModules/MemberProfile/components/EditMemberProfilePasswordModal/EditMemberProfilePasswordModal';
import MemberPortalHeaderClubLogo from './components/MemberPortalHeaderClubLogo';
import peakLogo from 'img/peakFullLogo.png';

const useStyles = makeStyles((theme: CustomTheme) => ({
  iconButton: {
    width: '20px',
    height: '20px',
  },
  root: {
    boxShadow: theme.shadows[2],
  },
}));

const Header = (): JSX.Element => {
  const theme = useTheme();
  const isDesktopView = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    menuAnchorEl: globalSearchAnchorEl,
    handleShowMenu: handleShowGlobalSearch,
    handleCloseMenu: handleCloseGlobalSearch,
  } = useMenuState();

  const {
    menuAnchorEl: helpInfoSearchAnchorEl,
    handleShowMenu: handleShowHelpInfoSearch,
    handleCloseMenu: handleCloseHelpInfoSearch,
  } = useMenuState();

  const {
    menuAnchorEl: contactPopoverAnchorEl,
    handleShowMenu: handleShowContactPopover,
    handleCloseMenu: handleCloseContactPopover,
  } = useMenuState();

  const {
    menuAnchorEl: clockInModalAnchorEl,
    handleShowMenu: handleShowClockInModal,
    handleCloseMenu: handleCloseClockInModal,
  } = useMenuState();

  const dispatch = useAppDispatch();
  const userPermissionLevel: PermissionLevels = useRootSelector(selectCurrentUserSelectedLevel);
  const isSidebarOpen = useRootSelector(selectIsSidebarOpen);
  const userRole = useRootSelector(selectUserRole);
  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] = useState(false);
  const isClientPortal = userRole === 'client';
  const classes = useStyles();

  const toggleSidebar = () => {
    dispatch(setIsSidebarOpen(!isSidebarOpen));
  };

  const getLevelHeader = () => {
    if (isClientPortal && userPermissionLevel !== PermissionLevels.PEAK) {
      return (
        <>
          <Grid item>
            <NavLink to={HOME_PATH}>
              <HeaderClubLogo />
            </NavLink>
          </Grid>

          <Grid item>
            <ClubSelector />
          </Grid>
        </>
      );
    } else if (!isClientPortal) {
      return <MemberPortalHeaderClubLogo />;
    } else {
      return (
        <Grid item>
          <NavLink to={HOME_PATH}>
            <img height={25} src={peakLogo} alt="peak logo" />
          </NavLink>
        </Grid>
      );
    }
  };

  // TODO fix markup after UI KIT changes
  return (
    <>
      <Box position="relative" width="100%" zIndex={10}>
        <Paper className={classes.root}>
          <Box display="flex" justifyContent="space-between" p={1.25} overflow="hidden">
            <Grid container spacing={1} alignItems="center" justifyContent="space-between">
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <IconButton id="hamburger" onClick={toggleSidebar} color="secondary">
                      <SvgIcon component={Hamburger} />
                    </IconButton>
                  </Grid>
                  {getLevelHeader()}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={3} alignItems="center">
                  {isDesktopView && (
                    <>
                      <Grid item>
                        <UserTimezoneSelector />
                      </Grid>

                      {isClientPortal && userPermissionLevel !== PermissionLevels.PEAK && (
                        <Grid item>
                          <IconButton onClick={handleShowClockInModal} color="secondary">
                            <TimeclockIcon className={classes.iconButton} />
                          </IconButton>
                        </Grid>
                      )}

                      {isClientPortal && (
                        <Grid item>
                          <IconButton onClick={handleShowGlobalSearch} color="secondary">
                            <SearchIcon className={classes.iconButton} />
                          </IconButton>
                        </Grid>
                      )}

                      <Grid item>
                        <LanguageSelector />
                      </Grid>

                      <Grid item>
                        <IconButton onClick={handleShowContactPopover} color="secondary">
                          <PhoneIcon className={classes.iconButton} />
                        </IconButton>
                      </Grid>

                      <Grid item>
                        <IconButton
                          disabled={!isClientPortal}
                          onClick={handleShowHelpInfoSearch}
                          color="secondary"
                        >
                          <HelpIcon className={classes.iconButton} />
                        </IconButton>
                      </Grid>

                      {!isClientPortal && (
                        <Grid item>
                          <MemberPortalCart />
                        </Grid>
                      )}

                      {/* <Grid item> // TODO refactor notifications
                        <UserNotifications />
                      </Grid> */}
                    </>
                  )}

                  <Grid item>
                    <UserMenu onChangePasswordClick={() => setIsOpenChangePasswordModal(true)} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>

      {!!globalSearchAnchorEl && (
        <GlobalSearch anchorEl={globalSearchAnchorEl} onClose={handleCloseGlobalSearch} />
      )}

      {!!helpInfoSearchAnchorEl && (
        <HelpInfoSearch anchorEl={helpInfoSearchAnchorEl} onClose={handleCloseHelpInfoSearch} />
      )}

      {!!contactPopoverAnchorEl && (
        <ContactPopover
          isClientPortal={isClientPortal}
          currentUserLevel={userPermissionLevel}
          anchorEl={contactPopoverAnchorEl}
          onClose={handleCloseContactPopover}
        />
      )}

      {!!clockInModalAnchorEl && (
        <ClockInModal anchorEl={clockInModalAnchorEl} onClose={handleCloseClockInModal} />
      )}

      {isOpenChangePasswordModal && (
        <EditMemberProfilePasswordModal onClose={() => setIsOpenChangePasswordModal(false)} />
      )}
    </>
  );
};

export default Header;
