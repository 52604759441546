import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList, List } from 'immutable';

import * as actions from 'common/components/PersonProfile/state/actions';
import * as selectors from 'common/components/PersonProfile/state/selectors';
import { selectCurrentUserAvailableClubs } from 'modules/authentication/state/selectors';

import { useAppDispatch } from 'store/hooks';
import { makeTableParams } from 'common/utils/http';

import { PeakModules } from 'common/constants/peakModules';

import {
  ICheckInHistoryImt,
  IModificationHistoryImt,
} from 'common/components/PersonProfile/interfaces';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
import { ITableParams } from 'common/interfaces/table';

import { DialogComponent } from 'common/components/index';
import { CheckInsHistoryTable } from 'common/components/PersonProfile/components/index';

import commonMessages from 'common/messages/messages';
import tableFilters from 'common/messages/tableFilters';
import { TableOrderByParams } from 'common/constants';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  checkInsHistory?: List<IModificationHistoryImt>;
  checkInsHistoryMeta?: IPageMetaImt;
  checkInsHistoryLoading?: boolean;
  personId: number;
  module: PeakModules;
}

const CheckInsHistoryModal = ({ isOpen, onClose, personId, module }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const checkInsHistoryList: ImmutableList<ICheckInHistoryImt> = useRootSelector(
    selectors.selectCheckInsHistoryList(personId),
  );
  const isCheckInsHistoryLoading: boolean = useRootSelector(
    selectors.selectCheckInsHistoryLoading(personId),
  );
  const checkInsHistoryMeta: IPageMetaImt = useRootSelector(
    selectors.selectCheckInsHistoryMeta(personId),
  );
  const clubs = useRootSelector(selectCurrentUserAvailableClubs);

  const tableFilterSettings: IFilterSettings[] = useMemo(
    () => [
      {
        name: 'clubIds',
        title: <FormattedMessage {...tableFilters.club} />,
        type: FilterTypes.MULTIPLE,
        options: clubs?.toJS().map(club => ({
          key: club.id,
          label: club.title,
          value: club.id,
        })),
      },
      {
        name: '',
        title: <FormattedMessage {...tableFilters.period} />,
        type: FilterTypes.DATE_RANGE,
        options: {
          startDate: null,
          endDate: null,
        },
      },
    ],
    [clubs],
  );

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilterSettings, null, { orderBy: TableOrderByParams.DATE, order: 'desc' }),
  );

  useEffect(() => {
    dispatch(actions.fetchCheckInsHistory(personId, module, tableParams));
  }, [dispatch, personId, module, tableParams]);

  const handleChangeTableProps = useCallback((tableProps: ITableParams): void => {
    setTableParams(tableProps);
  }, []);

  return (
    <DialogComponent
      title={<FormattedMessage {...commonMessages.checkinHistoryTitle} />}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      hideFooterButtons
    >
      <CheckInsHistoryTable
        tableParams={tableParams}
        checkInsHistory={checkInsHistoryList}
        isCheckInsHistoryLoading={isCheckInsHistoryLoading}
        checkInsHistoryMeta={checkInsHistoryMeta}
        onChangeTableParams={handleChangeTableProps}
        tableFilterSettings={tableFilterSettings}
        hideSearchInput
      />
    </DialogComponent>
  );
};

export default CheckInsHistoryModal;
