import React from 'react';
import { FormattedMessage } from 'react-intl';

import commonMessages from 'common/messages/messages';
import { Typography } from '@material-ui/core';

interface IMultiTitlesLabelProps {
  titles: Array<string>;
  entityNamePlural: string | JSX.Element;
  maxVisible?: number;
}

const MultiTitlesLabel: React.FC<IMultiTitlesLabelProps> = ({
  titles,
  entityNamePlural,
  maxVisible = 5,
}: IMultiTitlesLabelProps): JSX.Element => {
  return (
    <>
      {titles.length <= maxVisible + 1 ? (
        titles.join(', ')
      ) : (
        <Typography>
          <FormattedMessage
            {...commonMessages.multiTitlesLabel}
            values={{
              firstTitles: titles.slice(0, maxVisible).join(', '),
              moreCount: titles.length - maxVisible,
            }}
          />
          &nbsp;
          <Typography component="span" style={{ textTransform: 'lowercase' }}>
            {entityNamePlural}
          </Typography>
          .
        </Typography>
      )}
    </>
  );
};

export default React.memo(MultiTitlesLabel);
