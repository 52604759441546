import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import CheckBoxWithReset from 'common/components/CheckBoxWithReset/CheckBoxWithReset';
import { IBillingOption } from 'modules/services/interfaces/packages';
import messages from 'modules/services/messages/messages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { MinMaxNumberInput, Select } from 'common/components';
import { FrequencyTypes } from 'modules/services/constants/packages';

interface ISplitPaymentSectionProps {
  formMethods: UseFormReturn<IBillingOption>;
  defaultValues?: IBillingOption;
}

const SplitPaymentSection: React.FC<ISplitPaymentSectionProps> = ({
  formMethods,
  defaultValues,
}: ISplitPaymentSectionProps): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();

  const {
    control,
    trigger,
    formState: { errors },
  } = formMethods;

  const isAllowedSplitting = useWatch({ control, name: 'paymentSplitting.allow' });

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="paymentSplitting.allow"
            defaultValue={defaultValues?.paymentSplitting?.allow}
            render={({ field }) => (
              <CheckBoxWithReset
                label={
                  <Typography variant="subtitle2">
                    <FormattedMessage {...messages.allowSplitEachPaymentLabel} />
                  </Typography>
                }
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Grid>

        {isAllowedSplitting && (
          <>
            <Grid item xs={4}>
              <MinMaxNumberInput
                control={control}
                errors={errors}
                trigger={trigger}
                baseName="paymentSplitting.splitPaymentEditableNumber"
                label={<FormattedMessage {...messages.paymentsLabel} />}
                numberFormatProps={{ maxLength: 3 }}
                baseDefaultValue={defaultValues?.paymentSplitting.splitPaymentEditableNumber}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                name="paymentSplitting.splitPaymentSchedule"
                control={control}
                defaultValue={defaultValues?.paymentSplitting.splitPaymentSchedule}
                render={({ field }) => (
                  <Select
                    fullWidth
                    label={<FormattedMessage {...messages.paymentScheduleLabel} />}
                    variant="outlined"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!errors.paymentSplitting?.splitPaymentSchedule}
                    helperText={renderIntlMessage(
                      errors.paymentSplitting?.splitPaymentSchedule?.message,
                    )}
                  >
                    {FrequencyTypes.getSelectOptions()}
                  </Select>
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default SplitPaymentSection;
