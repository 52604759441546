import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { List as ImmutableList } from 'immutable';
import isNumber from 'lodash/isNumber';
import { IFilterSettings } from 'common/interfaces/filter';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { ILeadImt } from 'modules/crm/interfaces/leads';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';
import { ReactComponent as EditIcon } from 'img/icons/edit.svg';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import commonMessages from 'common/messages/messages';
import tableHeaders from 'common/messages/tableHeaders';
import messages from 'modules/members/messages';
import { GuestStatus } from 'modules/front-desk/components';
import { ActionItem, ActionsMenu, MemberName, Table } from 'common/components';
import { IMemberPhoneItem } from 'common/components/PersonProfile/interfaces';
import { getArray } from 'common/utils/typeUtils';
import MemberPhoneItem from 'modules/members/components/MembersTable/MemberPhoneItem/MemberPhoneItem';

const headerOptions: IHeadCell[] = [
  {
    id: 'name',
    label: <FormattedMessage {...tableHeaders.name} />,
    sort: true,
    padding: 'none',
  },
  { id: 'type', label: <FormattedMessage {...tableHeaders.status} />, sort: true },
  { id: 'club', label: <FormattedMessage {...tableHeaders.homeClub} />, sort: true },
  { id: 'membership', label: <FormattedMessage {...tableHeaders.membership} />, sort: true },
  { id: 'phones', label: <FormattedMessage {...tableHeaders.phones} />, sort: false },
  { id: 'email', label: <FormattedMessage {...tableHeaders.emails} />, sort: true },
  {
    id: 'actions',
    padding: 'none',
    label: '',
    sort: false,
    align: 'center',
  },
];

interface IMembersTable {
  members: ImmutableList<ILeadImt>;
  tableParams: ITableParams;
  tableFilterSettings: IFilterSettings[];
  isLoading: boolean;
  totalRows: number;
  onChangeParams: (tableParams: ITableParams) => void;
}

const useStyles = makeStyles(theme => ({
  phoneItem: {
    padding: theme.spacing(0.25, 0),
  },
}));

const MembersTable: FC<IMembersTable> = ({
  members,
  isLoading,
  totalRows,
  tableParams,
  tableFilterSettings,
  onChangeParams,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleEditClick = useCallback(
    (memberId: number) => {
      navigate(`/members/${memberId}`);
    },
    [navigate],
  );

  const handleNewClick = useCallback(() => {
    navigate('/members/new');
  }, [navigate]);

  const createRows = (): ITableRow[] => {
    return members
      .map(
        (member: ILeadImt): ITableRow => {
          const phones = getArray<IMemberPhoneItem>(member.get('phones')?.toJS());

          const phoneList = phones.map((phone, i, array) => {
            const showComma = array.length > 1 && array.length - 1 !== i;

            return (
              <MemberPhoneItem
                showComma={showComma}
                key={phone.phone}
                phoneData={phone}
                className={classes.phoneItem}
              />
            );
          });

          return {
            id: String(member.get('id')),
            cells: [
              {
                label: '-',
                className: 'body-avatar-cell',
                cellComponent: (
                  <>
                    <MemberName
                      firstName={member.get('firstName') || ''}
                      lastName={member.get('lastName') || ''}
                      photoUrl={member.get('imageUrl')}
                      onClick={() => {
                        const memberId = member.get('id');

                        if (memberId) {
                          handleEditClick(memberId);
                        }
                      }}
                    />
                  </>
                ),
              },
              {
                label: member.get('type') || '',
                className: 'body-cell',
                cellComponent: member.get('type') ? (
                  <GuestStatus variant="body1" type={member.get('type') || ''} />
                ) : (
                  '-'
                ),
              },
              {
                label: member.getIn(['club', 'title']) || '-',
                className: 'body-cell',
              },
              {
                label: member.getIn(['membership', 'title']) || '-',
                className: 'body-cell',
              },
              {
                label: '',
                className: 'body-phones-cell',
                cellComponent: phoneList.length ? phoneList : '-',
              },
              {
                label: member.get('email') || '',
                className: 'body-cell',
                cellComponent: <Typography>{member.get('email') || '-'}</Typography>,
              },
              {
                label: 'actions',
                className: 'body-actions-cell',
                cellComponent: (
                  <>
                    <ActionsMenu horizontal tableActionsMode>
                      <ActionItem
                        key="1"
                        icon={<EditIcon width={16} height={16} />}
                        onClick={() => {
                          const memberId = member.get('id');

                          if (isNumber(memberId)) {
                            handleEditClick(memberId);
                          }
                        }}
                      >
                        <FormattedMessage {...commonMessages.editBtn} />
                      </ActionItem>
                    </ActionsMenu>
                  </>
                ),
              },
            ],
          };
        },
      )
      .toJS();
  };
  return (
    <Table
      title={<FormattedMessage {...messages.membersTitle} />}
      addButton={
        <Button
          variant="contained"
          color="primary"
          onClick={handleNewClick}
          startIcon={<AddIcon width={16} height={16} />}
        >
          <FormattedMessage {...commonMessages.newBtn} />
        </Button>
      }
      headerOptions={headerOptions}
      showPerPageSelect
      rows={createRows()}
      isLoading={isLoading}
      filters={tableFilterSettings}
      totalRows={totalRows}
      onChangeParams={onChangeParams}
      tableParams={tableParams}
    />
  );
};

export default MembersTable;
