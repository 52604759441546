import React, { useRef, useEffect, useState } from 'react';
import cx from 'classnames';
// material ui
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box/Box';

const useStyles = makeStyles(() => ({
  hasTopShadow: {
    boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.1)',
    height: 4,
    marginTop: -4,
    zIndex: 999,
    position: 'relative',
  },
  hasBottomShadow: {
    boxShadow: '0 -3px 3px 0 rgba(0, 0, 0, 0.1)',
    height: 4,
    marginBottom: -4,
    zIndex: 999,
    position: 'relative',
  },
  hidden: {
    visibility: 'hidden',
  },
  displayNone: {
    display: 'none!important',
  },
  scrollBox: {
    display: 'block',
    height: '100%',
    width: '100%',
    overflowX: 'auto',
    overflowY: 'auto',
    // eslint-disable-next-line no-useless-computed-key
    ['-webkit-overflow-scrolling']: 'touch',

    '&.overflowXHidden': {
      overflowX: 'hidden',
    },

    '&.overflowYHidden': {
      overflowY: 'hidden',
    },
  },
  scrollBoxRelative: {
    position: 'relative',
    zIndex: 5,
  },
}));

interface IProps extends BoxProps {
  suppressScrollX?: boolean;
  suppressScrollY?: boolean;
  hasShadowsOnScroll?: boolean;
  suppressBottomShadow?: boolean;
  onYReachEnd?: () => void;
  displayNone?: boolean;
  scrollToTopTrigger?: any;
}

const ScrollBox: React.FC<IProps> = ({
  children,
  suppressScrollY,
  suppressScrollX,
  className,
  hasShadowsOnScroll,
  suppressBottomShadow,
  displayNone,
  scrollToTopTrigger,
  onYReachEnd,
  ...boxProps
}: IProps): JSX.Element => {
  const classes = useStyles();

  const [hasScrollY, setHasScrollY] = useState(false);
  const [topShadow, setTopShadow] = useState(false);
  const [bottomShadow, setBottomShadow] = useState(false);

  const scrollBoxRef = useRef<HTMLDivElement>();
  const timerRef = useRef<any>();
  const scrollToTopTriggerRef = useRef<any>(null);

  useEffect(() => {
    if (!hasScrollY && hasShadowsOnScroll && scrollBoxRef.current) {
      timerRef.current = setTimeout(function() {
        if (scrollBoxRef.current) {
          const { clientHeight, scrollHeight } = scrollBoxRef.current;

          setBottomShadow(clientHeight < scrollHeight);
        }
      });
    }
  }, [hasScrollY, hasShadowsOnScroll, setBottomShadow]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!scrollBoxRef.current || !!scrollToTopTrigger) return;
    if (scrollToTopTriggerRef.current !== scrollToTopTrigger) {
      scrollToTopTriggerRef.current = scrollToTopTrigger;
      scrollBoxRef.current.scrollTo({ top: 0 });
    }
  }, [scrollToTopTrigger]);

  const handleScroll = () => {
    if (!scrollBoxRef.current) return;

    const { scrollTop, clientHeight, scrollHeight } = scrollBoxRef.current;
    if (hasShadowsOnScroll) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !hasScrollY && setHasScrollY(true);

      setTopShadow(scrollTop !== 0);
      setBottomShadow(scrollTop + clientHeight < scrollHeight);
    }
    if (onYReachEnd && scrollTop + clientHeight === scrollHeight) {
      onYReachEnd();
    }
  };

  return hasShadowsOnScroll || onYReachEnd ? (
    <>
      <div
        className={cx('scrollBoxTopShadow', classes.hasTopShadow, {
          [classes.hidden]: !topShadow,
          [classes.displayNone]: displayNone,
        })}
      />
      <Box
        {...{ ref: scrollBoxRef }}
        {...boxProps}
        onScroll={handleScroll}
        className={cx(className, 'scrollBox', classes.scrollBox, {
          overflowXHidden: suppressScrollX,
          overflowYHidden: suppressScrollY,
          [classes.displayNone]: displayNone,
        })}
      >
        <div>{children}</div>
      </Box>
      <div
        className={cx('scrollBoxBottomShadow', classes.hasBottomShadow, {
          [classes.hidden]: !bottomShadow,
          [classes.displayNone]: displayNone || suppressBottomShadow,
        })}
      />
    </>
  ) : (
    <Box
      {...boxProps}
      {...{ ref: scrollBoxRef }}
      className={cx(className, 'scrollBox', classes.scrollBox, classes.scrollBoxRelative, {
        overflowXHidden: suppressScrollX,
        overflowYHidden: suppressScrollY,
        [classes.displayNone]: displayNone,
      })}
    >
      {children}
    </Box>
  );
};

export default ScrollBox;
