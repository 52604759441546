import React, { useMemo } from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { convertPhoneNumber } from 'helpers/common';

import { IInvoiceDetailsImt } from 'common/interfaces/invoices';
import { Message } from 'common/interfaces/common';

import messages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';

const useStyles = makeStyles((theme: Theme) => ({
  descriptionBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
  },
  descriptionItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fieldDescription: {
    flex: 0.5,
    textAlign: 'end',
  },
}));

interface IProps {
  checkOutData: IInvoiceDetailsImt;
}

const CheckOutResponseNamesInfo = ({ checkOutData }: IProps): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();
  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  const transformedCheckOutData = useMemo(() => checkOutData.toJS(), [checkOutData]);

  const {
    club,
    register: { title: registerTitle },
    salesperson,
    customer,
    createdDate,
  } = transformedCheckOutData;

  const renderDescription = (field: Message, value: string) => (
    <Typography component="p" className={classes.descriptionItem}>
      <Typography component="span" color="textSecondary">
        {`${renderIntlMessage(field)}:`}
      </Typography>
      <Typography component="span" className={classes.fieldDescription}>
        {value ?? '-'}
      </Typography>
    </Typography>
  );

  const descriptionFields: Array<{ field: Message; description: string }> = [
    { field: messages.clubTitle, description: club ? club.title : '-' },
    {
      field: inputLabels.address,
      description: club ? club.addressLine || '' : '-',
    },
    {
      field: inputLabels.phone,
      description: club ? convertPhoneNumber(club.phone || '') : '-',
    },
    { field: messages.registerHeader, description: registerTitle },
    {
      field: messages.salesPersonHeader,
      description: salesperson ? `${salesperson.firstName} ${salesperson.lastName}` : '-',
    },
    {
      field: inputLabels.customer,
      description: customer ? `${customer.firstName} ${customer.lastName}` : '-',
    },
    {
      field: inputLabels.date,
      description: timezoneMoment(createdDate).format(DEFAULT_DATE_TIME_FORMAT),
    },
  ];

  return (
    <Box className={classes.descriptionBlock}>
      {descriptionFields.map(({ field, description }) => renderDescription(field, description))}
    </Box>
  );
};

export default CheckOutResponseNamesInfo;
