import * as yup from 'yup';
import { getEmailErrorMessage, getRequiredErrorMessage } from 'common/utils/validation';

export const loginToConfirmValidationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email(getEmailErrorMessage)
    .required(getRequiredErrorMessage),
  password: yup
    .string()
    .trim()
    .required(getRequiredErrorMessage),
});
