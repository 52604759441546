import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import ErrorPage from './ErrorPage';

interface IProps {
  children: JSX.Element;
}

interface IErrorState {
  error: Error | null;
}

class ErrorBoundary extends Component<IProps, IErrorState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: any): void {
    this.setState({ error });

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render(): JSX.Element {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return <ErrorPage />;
    }

    return children;
  }
}

export default ErrorBoundary;
