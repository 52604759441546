import { defineMessages } from 'react-intl';

export default defineMessages({
  scripts: {
    id: 'app.modules.crm.scripts.tableTitle',
    defaultMessage: 'Scripts',
  },
  changeStatus: {
    id: 'app.modules.crm.scripts.changeStatus',
    defaultMessage: 'Change script status',
  },
  changeScriptStatusText: {
    id: 'app.modules.crm.scripts.changeScriptStatusText',
    defaultMessage: 'Are you sure you want to change {name} status?',
  },
  newScript: {
    id: 'app.modules.crm.newScript',
    defaultMessage: 'New script',
  },
  editScript: {
    id: 'app.modules.crm.editScript',
    defaultMessage: 'Edit script',
  },
  scriptCreatedMessage: {
    id: 'app.modules.crm.scripts.scriptCreatedMessage',
    defaultMessage: 'New script has been created',
  },
  scriptNameLength: {
    id: 'app.modules.crm.scripts.scriptNameLength',
    defaultMessage: 'Script name must be 100 characters or less',
  },
  scriptStepNameLength: {
    id: 'app.modules.crm.scripts.scriptStepNameLength',
    defaultMessage: 'Step name must be 100 characters or less',
  },
  scriptInstructionLength: {
    id: 'app.modules.crm.scripts.scriptInstructionLength',
    defaultMessage: 'Instructions must be 5000 characters or less',
  },
});
