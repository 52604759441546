import { Message } from 'common/interfaces/common';
import inputErrors from 'common/messages/inputErrors';
import validationMessages from 'common/messages/validation';

export function getRequiredErrorMessage(): Message {
  return inputErrors.requiredFieldError;
}

export function getNumberErrorMessage(): Message {
  return inputErrors.numberFieldError;
}

export function getEmailErrorMessage(): Message {
  return inputErrors.emailInvalidError;
}

export function getPositiveNumberErrorMessage(): Message {
  return validationMessages.numberPositive;
}

export function getIntegerTypeErrorMessage(): Message {
  return validationMessages.numberIntegerType;
}

export function getNumberTypeErrorMessage(): Message {
  return validationMessages.numberType;
}

export function getMinErrorMessage(params: { min: number }): Message {
  return {
    ...inputErrors.valueMinLengthError,
    values: {
      value: params.min,
    },
  };
}

export function getMaxErrorMessage(params: { max: number }): Message {
  return {
    ...inputErrors.valueMaxLengthError,
    values: {
      value: params.max,
    },
  };
}

export function getEndTimeErrorMessage(): Message {
  return inputErrors.endTimeBeforeStartTimeError;
}

export function getNameLengthErrorMessage(): Message {
  return inputErrors.nameLengthError;
}
