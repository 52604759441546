import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Typography } from '@material-ui/core';

import { useAppDispatch } from 'store/hooks';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import * as selectors from 'common/components/PersonProfile/state/selectors';
import { FilterTypes, IFilterSettings, IPaginateFilterReturnType } from 'common/interfaces/filter';
import tableFilters from 'common/messages/tableFilters';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import { makeTableParams } from 'common/utils/http';
import { TableOrderByParams } from 'common/constants';
import * as actions from 'common/components/PersonProfile/state/actions';
import { DialogComponent, Table, TooltipTypography } from 'common/components/index';
import modalMessages from 'common/messages/modalTitles';
import Services from 'services/network';
import { PeakModules } from 'common/constants/peakModules';
import tableHeaders from 'common/messages/tableHeaders';
import inputLabels from 'common/messages/inputLabels';
import ReminderDetailsModal from 'common/components/PersonProfile/components/RemindersBlock/modals/ReminderDetailsModal/ReminderDetailsModal';

interface IRemindersHistoryModalProps {
  personId: number;
  isOpen: boolean;
  module?: PeakModules;
  onClose: () => void;
}

const headerOptions: IHeadCell[] = [
  {
    id: TableOrderByParams.CREATED_DATE,
    label: <FormattedMessage {...tableHeaders.date} />,
    sort: true,
  },
  {
    id: 'salesperson',
    label: <FormattedMessage {...tableHeaders.salesperson} />,
    sort: false,
  },
  {
    id: 'note',
    align: 'left',
    label: <FormattedMessage {...inputLabels.note} />,
    sort: false,
  },
];

const RemindersHistoryModal = (props: IRemindersHistoryModalProps): JSX.Element => {
  const { personId, isOpen, onClose, module } = props;

  const dispatch = useAppDispatch();

  const reminders = useRootSelector(selectors.selectPersonReminders(personId));
  const remindersLoading = useRootSelector(selectors.selectPersonRemindersLoading(personId));
  const remindersMeta = useRootSelector(selectors.selectPersonRemindersMeta(personId));

  const [{ isReminderDetailsModalOpen, reminderId }, setIsReminderDetailsModalOpen] = useState({
    isReminderDetailsModalOpen: false,
    reminderId: '',
  });

  const [timezoneMoment] = useTimezoneMoment();

  const tableFilterSettings: IFilterSettings[] = useMemo(
    () => [
      {
        name: 'range',
        title: <FormattedMessage {...tableFilters.period} />,
        type: FilterTypes.DATE_RANGE,
        options: {
          startDate: null,
          endDate: null,
        },
      },
      {
        name: 'employeeIds',
        title: <FormattedMessage {...tableFilters.salesperson} />,
        type: FilterTypes.MULTIPLE_WITH_PAGINATE,
        perPage: 20,
        fetchItemTitle: async (id: number): Promise<string | null> => {
          try {
            const employee = await Services.Employees.getEmployeeById(id);
            return `${employee.firstName} ${employee.lastName}`;
          } catch (e) {
            return null;
          }
        },
        fetchList: async (search: string, page: number): Promise<IPaginateFilterReturnType> => {
          try {
            const { meta, data } = await Services.DictionaryLists.getEmployeesList({
              search,
              page,
              perPage: 20,
              module,
            });

            return {
              pageElements: data.map(item => ({ key: item.id, label: item.title, value: item.id })),
              itemCount: meta.total,
            };
          } catch (e) {
            return { pageElements: [], itemCount: 0 };
          }
        },
      },
    ],
    [module],
  );

  const rows: ITableRow[] = useMemo(() => {
    return reminders
      ?.map(item => {
        const firstName = item.getIn(['salesperson', 'firstName']);
        const lastName = item.getIn(['salesperson', 'lastName']);

        return {
          id: item.get('id'),
          cells: [
            {
              label: '',
              width: '33%',
              cellComponent: (
                <Button
                  variant="text"
                  type="button"
                  className="btn-text"
                  onClick={() => {
                    setIsReminderDetailsModalOpen({
                      isReminderDetailsModalOpen: true,
                      reminderId: item.get('id'),
                    });
                  }}
                >
                  <Typography variant="h6" color="primary">
                    {timezoneMoment(item.get('createdDate')).format(DEFAULT_DATE_TIME_FORMAT)}
                  </Typography>
                </Button>
              ),
            },
            {
              width: '33%',
              label: firstName ? `${firstName} ${lastName}` : '-',
            },
            {
              width: '33%',
              maxWidth: '330px',
              label: '',
              cellComponent: item.get('note') ? (
                <TooltipTypography ellipsized title={item.get('note')}>
                  {item.get('note')}
                </TooltipTypography>
              ) : (
                '-'
              ),
            },
          ],
        };
      })
      .toJS();
  }, [reminders, timezoneMoment]);

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilterSettings, null, {
      orderBy: TableOrderByParams.CREATED_DATE,
      order: 'desc',
    }),
  );

  const onChangeTableParams = useCallback((tableProps: ITableParams): void => {
    setTableParams(tableProps);
  }, []);

  useEffect(() => {
    if (module) {
      dispatch(actions.fetchPersonReminders(personId, tableParams, module));
    }
  }, [dispatch, module, personId, tableParams]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetPersonRemindersAction(null, personId));
    };
  }, [dispatch, personId]);

  const onCloseReminderDetailsModal = () => {
    setIsReminderDetailsModalOpen({ isReminderDetailsModalOpen: false, reminderId: '' });
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage {...modalMessages.remindersHistory} />}
      size="xl"
      hideFooterButtons
    >
      <Box width="100%">
        <Table
          rows={rows}
          tableParams={tableParams}
          headerOptions={headerOptions}
          filters={tableFilterSettings}
          onChangeParams={onChangeTableParams}
          totalRows={remindersMeta?.get('total')}
          page={remindersMeta?.get('page')}
          isLoading={remindersLoading}
          hideToolbar
          showPerPageSelect
          hideSearchInput
        />
        {isReminderDetailsModalOpen && (
          <ReminderDetailsModal
            isOpen
            onClose={onCloseReminderDetailsModal}
            reminderId={reminderId}
            personId={personId}
            module={module}
          />
        )}
      </Box>
    </DialogComponent>
  );
};

export default RemindersHistoryModal;
