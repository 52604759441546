import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core';

import { IPosKioskOpenRegisterDTO } from 'modules/pos-kiosk/interfaces/registers';

import { OpenRegisterSteps } from 'modules/pos-kiosk/constants/registers';
import { StepContext } from 'common/createContext/stepContext';

import { DialogComponent } from 'common/components';
import ReconcileBillsStep from '../ReconcileModal/Steps/ReconcileBillsStep/ReconcileBillsStep';
import ReconcileChecksStep from '../ReconcileModal/Steps/ReconcileChecksStep/ReconcileChecksStep';
import Footer from './Footer';

import messages from 'modules/pos-kiosk/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiDialog-paper': {
      overflowY: 'hidden',
      paddingBottom: theme.spacing(9),
      position: 'relative',
      '& .MuiDialogActions-root': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        paddingRight: theme.spacing(2),
      },
    },
  },
}));

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  isOpenRegisterActionLoading?: boolean;
  onSubmit: (data: Partial<IPosKioskOpenRegisterDTO>) => void;
  registerId: string;
}

const steps: OpenRegisterSteps[] = Object.values(OpenRegisterSteps);

const { Provider: StepProvider } = StepContext;

const OpenRegisterModal = ({
  isOpen,
  onClose,
  onSubmit,
  isOpenRegisterActionLoading,
  registerId,
}: IProps): JSX.Element => {
  const [currentStep, setCurrentStep] = useState(OpenRegisterSteps.ReconcileBills);
  const [openRegisterData, setOpenRegisterData] = useState<Partial<IPosKioskOpenRegisterDTO>>({});

  const currentStepIndex: number = steps.indexOf(currentStep);
  const nextStep: OpenRegisterSteps | null =
    currentStepIndex + 1 < steps.length ? steps[currentStepIndex + 1] : null;
  const prevStep: OpenRegisterSteps | null =
    currentStepIndex - 1 < 0 ? null : steps[currentStepIndex - 1];

  const classes = useStyles();

  const goNextStep = useCallback(
    data => {
      if (currentStep === OpenRegisterSteps.ReconcileChecks) {
        onSubmit({
          ...openRegisterData,
          cashDesk: { ...openRegisterData.cashDesk, ...data.cashDesk },
        });
      } else {
        if (nextStep) {
          setCurrentStep(nextStep);
        }

        setOpenRegisterData(prevState => ({
          ...prevState,
          ...data,
        }));
      }
    },
    [currentStep, nextStep, onSubmit, openRegisterData],
  );

  const goPrevStep = useCallback(() => {
    if (prevStep) {
      setCurrentStep(prevStep);
    } else {
      onClose();
    }
  }, [onClose, prevStep]);

  const renderFooter = useCallback(
    (onBack, onNext) => {
      return (
        <Footer
          currentStep={currentStep}
          onBack={onBack}
          onNext={onNext}
          goBack={onClose}
          isLoading={!!isOpenRegisterActionLoading}
        />
      );
    },
    [currentStep, isOpenRegisterActionLoading, onClose],
  );

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...messages.openRegister} />}
      hasCustomFooter
      isOpen={isOpen}
      onClose={onClose}
      className={classes.root}
      supressBottomShadow
    >
      <StepProvider value={{ onNext: goNextStep, onBack: goPrevStep, renderFooter }}>
        {currentStep === OpenRegisterSteps.ReconcileBills && (
          <ReconcileBillsStep registerId={registerId} reconcileData={openRegisterData} />
        )}

        {currentStep === OpenRegisterSteps.ReconcileChecks && <ReconcileChecksStep />}
      </StepProvider>
    </DialogComponent>
  );
};

export default OpenRegisterModal;
