import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { Autocomplete } from '@material-ui/lab';
import { Box, Chip, FormControl, makeStyles, SvgIcon, Theme, Typography } from '@material-ui/core';
import { ChipProps } from '@material-ui/core/Chip/Chip';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { checkObjectPropertyExist, deepClone } from 'common/utils';

import { ReactComponent as DeleteIcon } from 'img/times.svg';
import { ReactComponent as ReplayIcon } from 'img/icons/reset.svg';

import commonMessages from 'common/messages/messages';
import { AutocompleteChangeReason } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { TextField } from 'common/components/index';

export interface ISelectItem {
  id: string | number;
  name?: string;
  imageUrl?: string;
  title?: string;
  value?: any;
  active?: boolean;
}

interface IMultipleSelectProps {
  label?: string | JSX.Element;
  placeholder?: string;
  helperText?: string;
  options: ISelectItem[];
  value?: any;
  multiple?: boolean;
  disableClearable?: boolean;
  disableTagsRender?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  onChange?: (selected, reason?: AutocompleteChangeReason) => void;
  onBlur?: () => void;
  getOptionDisabled?: (option) => boolean;
  groupBy?: (option) => string;
  loading?: boolean;
  disabled?: boolean;
  canReset?: boolean;
  resetValue?: any;
  size?: 'small' | 'medium';
  className?: string;
  recommended?: boolean;
}

export const MultipleSelectResetValue: ISelectItem[] = [{ id: '-1', name: 'Multiple' }];

const useStyles = makeStyles((theme: Theme) => ({
  disabledOption: {
    display: 'none',
  },
  mainContainer: {
    position: 'relative',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '& :hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  replayContainer: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    zIndex: 1,
    right: 0,
    borderRadius: 3,
    cursor: 'pointer',
    height: 18,
    width: 20,
    textAlign: 'center',
  },
  tagOption: {
    marginTop: theme.spacing(0.75),

    '&.inactive': {
      color: theme.palette.secondary.dark,
    },
  },
  iconReplay: {
    fill: theme.palette.background.paper,
    height: 16,
    width: 16,
    padding: '2px 2px 0px',
  },
}));

const MultipleSelect = ({
  label,
  placeholder,
  options,
  multiple,
  fullWidth,
  disableTagsRender,
  disableClearable,
  helperText,
  error,
  size,
  className,
  onChange,
  onBlur,
  getOptionDisabled,
  groupBy,
  value,
  canReset,
  resetValue = MultipleSelectResetValue,
  loading = false,
  disabled,
  recommended,
}: IMultipleSelectProps): JSX.Element => {
  const classes = useStyles();
  const [showResetButton, setShowResetButton] = useState(false);
  const lastOptionRef = useRef<ISelectItem | null>(null);

  const renderIntlMessage = useRenderIntlMessage();

  const handleToggleResetButton = (fieldValue): void => {
    if (canReset) {
      if (!multiple && fieldValue !== resetValue) {
        setShowResetButton(true);
        return;
      }

      if (multiple && resetValue && !fieldValue) {
        setShowResetButton(true);
        return;
      }

      if (
        multiple &&
        Array.isArray(fieldValue) &&
        Array.isArray(resetValue) &&
        (fieldValue.length !== resetValue.length ||
          resetValue.some(resetOpt => !fieldValue.find(fieldOpt => fieldOpt.id === resetOpt.id)))
      ) {
        setShowResetButton(true);
        return;
      }

      setShowResetButton(false);
    }
  };

  const handleOnChange = (event, values, reason: AutocompleteChangeReason) => {
    const updatedValues = deepClone(values);

    if (multiple) {
      values.forEach((val, index) => {
        if (typeof val === 'string') {
          updatedValues[index] = { title: val };
        } else if (val && val.inputValue) {
          updatedValues[index] = { title: val.inputValue };
        }
      });

      if (updatedValues.length > 1 && updatedValues[0].id === '-1') {
        updatedValues.shift();
      }
    }

    if (onChange) {
      onChange(updatedValues, reason);
    }
  };

  const getOptionLabel = (option: ISelectItem) => {
    if (option.title && checkObjectPropertyExist(option, 'active')) {
      return option.active
        ? option.title
        : `${option.title} (${renderIntlMessage(commonMessages.inactiveOption)})`;
    }
    if (option.title) return option.title;
    if (option.name) return option.name;
    return '';
  };

  const filterOptions = (items, state) => {
    const filteredOptions =
      items?.filter(item => {
        return item.title.toLowerCase().indexOf(state.inputValue?.toLowerCase() || '') >= 0;
      }) || [];

    if (filteredOptions?.length === 1) {
      const [item] = filteredOptions;
      lastOptionRef.current = item;
    } else {
      lastOptionRef.current = null;
    }

    return filteredOptions;
  };

  const onKeyDown = e => {
    if (e.code === 'Enter' && lastOptionRef.current && !multiple) {
      handleOnChange(e, lastOptionRef.current, 'select-option');
      lastOptionRef.current = null;
      e.target.blur();
    }
  };

  return (
    <FormControl
      size={size}
      variant="outlined"
      fullWidth={fullWidth}
      error={error}
      className={cx(className, {
        [classes.mainContainer]: !error && canReset && showResetButton && value !== resetValue,
      })}
    >
      {!!canReset && showResetButton && value !== resetValue && (
        <Box
          className={classes.replayContainer}
          onClick={() => {
            if (onChange) {
              onChange(resetValue);
            }

            setShowResetButton(false);
          }}
        >
          <ReplayIcon className={classes.iconReplay} />
        </Box>
      )}
      <Autocomplete
        filterOptions={multiple ? undefined : filterOptions}
        size={size}
        disableClearable={disableClearable}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        options={options}
        value={value}
        getOptionLabel={getOptionLabel}
        disabled={disabled}
        getOptionSelected={(option: ISelectItem, selected: ISelectItem) => {
          if (option.id) return option.id === selected?.id;
          if (option.name) return option.name === selected?.name;
          return false;
        }}
        loading={loading}
        getOptionDisabled={getOptionDisabled}
        filterSelectedOptions
        renderTags={(tags: ISelectItem[], getTagProps) => {
          if (disableTagsRender) {
            return <></>;
          }

          if (
            canReset &&
            tags &&
            tags.length === 1 &&
            tags[0].id === MultipleSelectResetValue[0].id
          ) {
            return (
              <Typography>
                <FormattedMessage {...commonMessages.multiple} />
              </Typography>
            );
          }

          return tags.map((tag: ISelectItem, index) => {
            const { className: tagClassName, ...otherProps } = getTagProps({
              index,
            }) as ChipProps;

            return (
              <Chip
                size="small"
                label={getOptionLabel(tag)}
                deleteIcon={
                  <SvgIcon>
                    <DeleteIcon />
                  </SvgIcon>
                }
                className={cx(tagClassName, classes.tagOption, {
                  inactive: checkObjectPropertyExist(tag, 'active') && !tag.active,
                })}
                {...otherProps}
              />
            );
          });
        }}
        onFocus={() => {
          if (canReset) handleToggleResetButton(value);
        }}
        onBlur={() => {
          if (canReset) handleToggleResetButton(value);
          if (onBlur) onBlur();
        }}
        onChange={(e, values, reason: AutocompleteChangeReason) => {
          handleOnChange(e, values, reason);
          if (canReset) handleToggleResetButton(values);
        }}
        groupBy={groupBy || undefined}
        renderInput={params => (
          <TextField
            {...params}
            helperText={helperText}
            onKeyDown={onKeyDown}
            variant="outlined"
            label={label}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              ...params.InputProps,
            }}
            error={error}
            recommended={recommended}
          />
        )}
      />
    </FormControl>
  );
};

MultipleSelect.defaultProps = {
  disableTagsRender: false,
};

export default MultipleSelect;
