import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core';

import useScrollIntoView from 'common/hooks/useScrollIntoView';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getQuantityMessage } from 'common/utils/displayServiceQuantity';

import {
  IncludedPackageBillingType,
  IncludedPaidPackageBillingTypes,
} from 'modules/services/constants/packages';

import { IEditablePackageConfiguration, IPackageService } from 'common/interfaces/membership';
import { TEditableInstance } from '../MembershipFormView';

import { FieldInfo } from 'common/components/index';
import AvailabilitiesResultBlock from 'common/components/AvailabilityScheduleSelector/AvailabilitiesResultBlock';
import BillingSectionView from '../BillingSectionView/BillingSectionView';
import IncludedInventorySectionView from '../IncludedInventorySectionView/IncludedInventorySectionView';
import FeesSectionView from '../FeesSectionView/FeesSectionView';
import { ReactComponent as AngleDownIcon } from 'img/icons/angle-down.svg';
import { ReactComponent as AngleRightIcon } from 'img/icons/angle-right.svg';

import personProfileMessages from 'common/components/PersonProfile/messages';
import inputLabels from 'common/messages/inputLabels';
import servicesMessages from 'modules/services/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  sectionBtn: {
    padding: 0,
    color: theme.palette.text.secondary,

    '& svg': {
      width: '0.75rem',
      height: '0.75rem',
      marginRight: theme.spacing(1),
    },
  },
  switchLabel: {
    width: '100%',
    margin: theme.spacing(0, 0, 1.5),

    '& .MuiFormControlLabel-label': {
      flex: 1,
      margin: theme.spacing(0, 1, 0, 0),
    },
  },
  serviceWrapper: {
    marginTop: theme.spacing(2),
  },
  renewalStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    width: '1rem',
    height: '1rem',
    marginRight: theme.spacing(0.5),

    '&.primary': {
      color: theme.palette.primary.main,
    },
    '&.error': {
      color: theme.palette.error.main,
    },
  },
}));

interface IProps {
  membershipPackage: IEditablePackageConfiguration;
  onEditPackage: (
    servicePackage: IEditablePackageConfiguration,
    instance: TEditableInstance,
  ) => void;
  onExcludePackage: (packageService: IPackageService) => void;
  onExcludeFee: (servicePackage: IEditablePackageConfiguration) => void;
  onViewInventoryInfo?: (packageId: string, inventoryId: string) => void;
}

const IncludedServicesSectionView: FC<IProps> = ({
  membershipPackage,
  onViewInventoryInfo,
  onEditPackage,
  onExcludePackage,
  onExcludeFee,
}) => {
  const [showContent, setShowContent] = useState<boolean>(true);

  const { scrollRef } = useScrollIntoView(showContent);

  const classes = useStyles();

  const renderIntlMessage = useRenderIntlMessage();

  const { services } = membershipPackage;

  if (!services?.length) {
    return null;
  }

  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Button
          onClick={() => setShowContent(!showContent)}
          startIcon={showContent ? <AngleDownIcon /> : <AngleRightIcon />}
          className={classes.sectionBtn}
        >
          <FormattedMessage {...personProfileMessages.includedServicesTitle} />
        </Button>
      </Box>
      <Collapse in={showContent} ref={scrollRef}>
        {services.map(servicePackage => (
          <Box key={servicePackage.id} className={classes.serviceWrapper}>
            <FormControlLabel
              labelPlacement="start"
              className={classes.switchLabel}
              label={
                <Typography component="span" variant="h5">
                  {servicePackage.service?.title || servicePackage.package?.title}
                </Typography>
              }
              control={
                <Switch
                  size="small"
                  color="primary"
                  checked={servicePackage.included}
                  disabled={!servicePackage.excludable}
                  onChange={(e, checked) =>
                    onExcludePackage({ ...servicePackage, included: checked })
                  }
                />
              }
            />

            {!!servicePackage.package && (
              <Grid container spacing={2}>
                <Grid item>
                  <FieldInfo
                    label={<FormattedMessage {...inputLabels.description} />}
                    description={servicePackage.package.description}
                    hasNotEllipsesDescription
                  />
                </Grid>
              </Grid>
            )}

            {!!servicePackage.service && servicePackage.included && (
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <FieldInfo
                    label={<FormattedMessage {...inputLabels.daysAndHoursForAccess} />}
                    description={
                      servicePackage.hasCustomDayTimeAvailability ? (
                        <AvailabilitiesResultBlock
                          availabilities={servicePackage.dayTimeAvailDtoList}
                        />
                      ) : (
                        <FormattedMessage
                          {...personProfileMessages.includedServicesDayAndHoursAccessSameAsParent}
                        />
                      )
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <FieldInfo
                    label={<FormattedMessage {...inputLabels.limitation} />}
                    description={`${servicePackage.limitedRedeemNumber ?? ''} ${renderIntlMessage(
                      getQuantityMessage(
                        servicePackage.service.type,
                        servicePackage.service.redeemType,
                        servicePackage.service.redeemDurationUnit,
                        servicePackage.limited,
                      ),
                    )}`}
                  />
                </Grid>
              </Grid>
            )}

            {!!servicePackage.package && servicePackage.included && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <FieldInfo
                      label={
                        <FormattedMessage
                          {...personProfileMessages.includedServicesPaymentTypeTitle}
                        />
                      }
                      description={IncludedPaidPackageBillingTypes.translate(
                        servicePackage.billingType,
                      )}
                    />
                  </Grid>

                  {!!servicePackage.billingAmount && (
                    <Grid item xs={4}>
                      <FieldInfo
                        label={
                          <FormattedMessage
                            {...personProfileMessages.includedServicesPaymentAmountTitle}
                          />
                        }
                        description={`$ ${servicePackage.billingAmount}`}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <FieldInfo
                      label={<FormattedMessage {...servicesMessages.renewalsLabel} />}
                      description={`${servicePackage.package?.renewalNumber}`}
                    />
                  </Grid>
                </Grid>

                {servicePackage.billingType === IncludedPackageBillingType.OwnBillingSettings && (
                  <Box pl={3}>
                    <BillingSectionView
                      membershipPackage={servicePackage.package}
                      onEditPackage={onEditPackage}
                    />
                  </Box>
                )}
                {servicePackage.billingType === IncludedPackageBillingType.OwnBillingSettings && (
                  <Box pl={3}>
                    <BillingSectionView
                      membershipPackage={servicePackage.package}
                      onEditPackage={onEditPackage}
                      isOutOfTerm
                    />
                  </Box>
                )}

                <Box pl={3}>
                  <IncludedServicesSectionView
                    onViewInventoryInfo={onViewInventoryInfo}
                    membershipPackage={servicePackage.package}
                    onEditPackage={onEditPackage}
                    onExcludePackage={data => onExcludePackage(data)}
                    onExcludeFee={onExcludeFee}
                  />
                </Box>

                <Box pl={3}>
                  <IncludedInventorySectionView
                    onViewInventoryInfo={onViewInventoryInfo}
                    membershipPackage={servicePackage.package}
                  />
                </Box>

                <Box pl={3}>
                  <FeesSectionView
                    membershipPackage={servicePackage.package}
                    onEditFees={onEditPackage}
                    onExcludeFee={onExcludeFee}
                  />
                </Box>
              </>
            )}
          </Box>
        ))}
      </Collapse>
    </Box>
  );
};

export default IncludedServicesSectionView;
