import React, { FC, useCallback, useEffect, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Alert, Button } from 'common/components/index';
import { IInvoiceDetailsImt, InvoiceStatus } from 'common/interfaces/invoices';
import { IPosKioskRegisterDataImt } from 'modules/pos-kiosk/interfaces/registers';
import {
  IImmutablePrimaryMemberInfo,
  IPrimaryMemberInfo,
} from 'common/components/PersonProfile/interfaces';
import { CustomTheme } from 'common/ui/interfaces';
import * as actions from 'common/state/invoice/actions';
import {
  selectPosKioskRegisterData,
  selectPosKioskRegisterNOSAmount,
} from 'modules/pos-kiosk/state/register/selectors';
import { createMember, resetCreateMemberResult } from 'common/state/newPerson/primaryInfo/actions';
import {
  selectActionCreateResult,
  selectPrimaryInfo,
} from 'common/state/newPerson/primaryInfo/selectors';
import { useAppDispatch } from 'store/hooks';
import { ActionResult } from 'common/constants';
import InvoicesSelector from './InvoicesSelector/InvoiceSelector';
import NewMemberModal from '../../PersonProfile/components/FamilyMembers/NewMemberModal/NewMemberModal';
import InvoiceCreationCart from './InvoiceCreationCart/InvoiceCreationCart';
import { PeakModules } from 'common/constants/peakModules';
import { InvoiceFooter } from './InvoiceFooter';
import { useSearchInvoiceProductsState } from 'common/createContext/searchInvoiceProductsContext';
import InvoiceSalespersonSelector from './InvoiceSalespersonSelector/InvoiceSalespersonSelector';
import InvoiceCustomerSelector from './InvoiceCustomerSelector/InvoiceCustomerSelector';
import InvoicePaymentWizard from '../InvoicePaymentWizard/InvoicePaymentWizard';
import { ReactComponent as AddIcon } from 'img/icons/add_deprecated.svg';
import { ReactComponent as WarningIcon } from 'img/icons/warning.svg';
import messages from 'common/messages/messages';
import { AlertTypes } from 'common/interfaces/alerts';
import { priceRounding } from 'common/utils';
import { AS_PAID_INVOICE_PAYMENT_SPLIT_STATUS } from '../constants';

const useStyles = makeStyles((theme: CustomTheme) => ({
  invoiceHeader: {
    flex: '0 1 auto',
    padding: theme.spacing(1, 2, 2, 2),
    boxShadow: theme.shadows[2],
  },
  invoiceSelectorWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  newInvoiceButton: {
    '& svg': {
      width: '1rem',
      height: '1rem',
    },
  },
  errorAlertsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    width: '1rem',
    height: '1rem',
  },
  alert: {
    paddingRight: theme.spacing(1),
    color: theme.palette.error.main,
    lineHeight: '14px',
  },
  invoiceCreationBlock: {
    flex: '1 1 auto',
  },
}));

interface IInvoicesForm {
  updateInvoiceCountOfRegister: () => void;
  setIsCancelInvoiceModalOpen: (value) => void;
  setIsVoidInvoiceModalOpen: (value) => void;
  disableInvoiceCreation?: boolean;
  disableInvoiceSelection?: boolean;
  isPaymentStep?: boolean;
  isCustomerSelectDisabled?: boolean;
  isCreateInvoiceActionLoading: boolean;
  selectedInvoice: IInvoiceDetailsImt;
  clubId: string;
  module: PeakModules;
  profileId?: number;
}

const InvoicesForm: FC<IInvoicesForm> = ({
  updateInvoiceCountOfRegister,
  disableInvoiceCreation,
  disableInvoiceSelection,
  setIsCancelInvoiceModalOpen,
  setIsVoidInvoiceModalOpen,
  isPaymentStep,
  isCustomerSelectDisabled,
  isCreateInvoiceActionLoading,
  selectedInvoice,
  clubId,
  module,
  profileId,
}) => {
  const classes = useStyles();

  const [isNewModalOpen, setIsNewModalOpen] = useState<boolean>(false);
  const [isInvoicePaymentWizardOpen, setIsInvoicePaymentWizardOpen] = useState<boolean>(false);
  const [isSelectCustomerAlertShown, setIsSelectCustomerAlertShown] = useState<boolean>(false);

  const { toggleShowAddInventories } = useSearchInvoiceProductsState();
  const dispatch = useAppDispatch();
  const register: IPosKioskRegisterDataImt = useRootSelector(selectPosKioskRegisterData);
  const memberInfo: IImmutablePrimaryMemberInfo = useRootSelector(selectPrimaryInfo);
  const registerNosValue = useRootSelector(selectPosKioskRegisterNOSAmount);
  const createMemberResult: ActionResult = useRootSelector(selectActionCreateResult);

  const totalInvoicePayments =
    selectedInvoice
      ?.get('invoicePaymentSplits')
      ?.filter(ips => AS_PAID_INVOICE_PAYMENT_SPLIT_STATUS.includes(ips.get('status') as any)) // TODO - PRM-3575 need types
      .reduce((acc, value) => {
        return acc + Number(value.get('paymentAmount'));
      }, 0) || 0;

  const leftToPay = priceRounding(selectedInvoice?.get('totalAmount') - totalInvoicePayments);

  const memberId = selectedInvoice?.getIn(['customer', 'id']);

  const onCloseNewModal = useCallback(() => {
    setIsNewModalOpen(false);
  }, []);

  const onOpenNewModal = useCallback(() => {
    setIsNewModalOpen(true);
  }, []);

  const onOpenPaymentWizard = () => setIsInvoicePaymentWizardOpen(true);

  const onSubmitNewMemberModal = (newMember: IPrimaryMemberInfo) => {
    dispatch(createMember({ ...newMember, initialClubId: clubId }, module));
  };

  const onSetCustomer = useCallback(
    (person: IImmutablePrimaryMemberInfo) => {
      if (selectedInvoice?.size) {
        dispatch(
          actions.setCustomerToInvoice(
            selectedInvoice.get('id'),
            person.get('id') || null,
            !!isPaymentStep,
            module,
          ),
        );
        onCloseNewModal();
      }
    },
    [dispatch, onCloseNewModal, selectedInvoice, isPaymentStep, module],
  );

  const handleCancelInvoiceBtnClick = () => {
    setIsCancelInvoiceModalOpen(true);
  };

  const handleVoidInvoiceBtnClick = () => {
    setIsVoidInvoiceModalOpen(true);
  };

  const handleNewInvoiceBtnClick = () => {
    dispatch(
      actions.createInvoice({
        module,
        registerId: register.get('id'),
        shouldFocus: true,
        isCustomer: isPaymentStep,
        callback: updateInvoiceCountOfRegister,
        profileId,
      }),
    );

    toggleShowAddInventories(false);

    if (isSelectCustomerAlertShown) {
      setIsSelectCustomerAlertShown(false);
    }
  };

  const handleInvoicePaymentWizardClose = () => setIsInvoicePaymentWizardOpen(false);

  const handleInvoiceChange = useCallback(() => {
    if (isSelectCustomerAlertShown) {
      setIsSelectCustomerAlertShown(false);
    }
  }, [isSelectCustomerAlertShown]);

  useEffect(() => {
    if (createMemberResult === ActionResult.SUCCESS_ACTION) {
      onSetCustomer(memberInfo);
      dispatch(resetCreateMemberResult());

      if (isSelectCustomerAlertShown) {
        setIsSelectCustomerAlertShown(false);
      }
    }
  }, [createMemberResult, dispatch, isSelectCustomerAlertShown, memberInfo, onSetCustomer]);

  useEffect(() => {
    if (selectedInvoice?.get('customer')) {
      setIsSelectCustomerAlertShown(false);
    }
  }, [selectedInvoice, setIsSelectCustomerAlertShown]);

  const isNOSAmountExceeded: boolean =
    selectedInvoice.get('totalAmount') > registerNosValue && !selectedInvoice?.get('customer');
  const isSalespersonMissing = !selectedInvoice?.get('salesperson');
  const hasError: boolean =
    isNOSAmountExceeded || isSalespersonMissing || isSelectCustomerAlertShown;

  const isVisibleNewInvoiceBtn = !isPaymentStep && !disableInvoiceCreation;

  return (
    <>
      <Box className={classes.invoiceHeader}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.invoiceSelectorWrapper}>
            <InvoicesSelector
              disableSelection={isPaymentStep || disableInvoiceSelection}
              selectedInvoice={selectedInvoice}
              onChange={handleInvoiceChange}
            />

            {isVisibleNewInvoiceBtn && (
              <Button
                startIcon={<AddIcon />}
                color="primary"
                className={classes.newInvoiceButton}
                onClick={handleNewInvoiceBtnClick}
                disabled={isCreateInvoiceActionLoading}
              >
                <FormattedMessage {...messages.newBtn} />
              </Button>
            )}
          </Grid>

          <Grid item xs={6}>
            <InvoiceSalespersonSelector
              module={module}
              selectedInvoice={selectedInvoice}
              isPaymentStep={isPaymentStep}
              disabled={selectedInvoice?.get('status') === InvoiceStatus.IN_PROGRESS}
            />
          </Grid>

          <Grid item xs={6}>
            <InvoiceCustomerSelector
              module={module}
              isPaymentStep={!!isPaymentStep}
              selectedInvoice={selectedInvoice}
              onOpenNewModal={onOpenNewModal}
              disabled={
                selectedInvoice?.get('status') === InvoiceStatus.IN_PROGRESS ||
                !!isCustomerSelectDisabled
              }
              setIsSelectCustomerAlertShown={setIsSelectCustomerAlertShown}
            />
          </Grid>

          {hasError && (
            <Grid item xs={12} className={classes.errorAlertsWrapper}>
              {isSalespersonMissing && (
                <>
                  <Typography component="span" className={classes.alert}>
                    <WarningIcon className={classes.warningIcon} />
                  </Typography>

                  <Typography component="span" variant="body2" className={classes.alert}>
                    <FormattedMessage {...messages.salespersonRequiredWarning} />
                  </Typography>
                </>
              )}

              {isNOSAmountExceeded && (
                <Alert
                  severity={AlertTypes.Warning}
                  title={
                    <>
                      <Typography component="span" variant="inherit">
                        <FormattedMessage
                          {...messages.notInSystemInvoiceWarning}
                          values={{ limit: registerNosValue }}
                        />
                      </Typography>
                      &nbsp;
                      <Typography component="span" variant="body1">
                        <FormattedMessage {...messages.notInSystemInvoiceWarningDescription} />
                      </Typography>
                    </>
                  }
                />
              )}

              {isSelectCustomerAlertShown && (
                <Alert
                  severity={AlertTypes.Danger}
                  title={
                    <Typography component="span" variant="inherit">
                      <FormattedMessage {...messages.selectCustomerWarningDescription} />
                    </Typography>
                  }
                />
              )}
            </Grid>
          )}
        </Grid>
      </Box>
      <Box display="flex" flexDirection="column" height="100%" width="100%" overflow="auto">
        <Box className={classes.invoiceCreationBlock} display="flex" flexDirection="column">
          <InvoiceCreationCart
            selectedInvoice={selectedInvoice}
            module={module}
            isPaymentStep={isPaymentStep}
            setIsSelectCustomerAlertShown={setIsSelectCustomerAlertShown}
          />
        </Box>
      </Box>
      <InvoiceFooter
        clubId={clubId}
        invoice={selectedInvoice}
        handleCancelInvoice={handleCancelInvoiceBtnClick}
        handleVoidInvoice={handleVoidInvoiceBtnClick}
        handleAddMember={onOpenNewModal}
        handleInvoiceActionClick={onOpenPaymentWizard}
        module={module}
        isPaymentStep={!!isPaymentStep}
        memberId={memberId}
      />

      <NewMemberModal
        isOpen={isNewModalOpen}
        onClose={onCloseNewModal}
        onSubmit={onSubmitNewMemberModal}
        onSelectMember={onSetCustomer}
        module={module}
      />

      <InvoicePaymentWizard
        clubId={clubId}
        isOpen={isInvoicePaymentWizardOpen}
        module={module}
        isPaymentStep={isPaymentStep}
        registerId={register.get('id')}
        isSystemRegister={!register.get('club')}
        leftToPay={leftToPay}
        memberId={memberId}
        onClose={handleInvoicePaymentWizardClose}
      />
    </>
  );
};

export default InvoicesForm;
