import React from 'react';
import { Box, SvgIcon, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import { ReactComponent as GridIcon } from 'img/grid.svg';
import { ReactComponent as ListIcon } from 'img/list.svg';
import { TViewType } from 'modules/crm/interfaces/leads';

const useStyles = makeStyles({
  btn: {
    width: '32px',
    minWidth: '32px',
    padding: '8px 0',
    borderRadius: 0,

    '& .MuiSvgIcon-root': {
      marginTop: 0,
    },
  },
  btnFirst: {
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
  },
  btnLast: {
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
  },
});

interface IProps {
  viewType: TViewType;
  isLoading: boolean;
  onChangeViewType: (t) => void;
}

const TableSwitch = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const { viewType, onChangeViewType, isLoading } = props;

  return (
    <Box display="flex">
      <Button
        disabled={isLoading}
        className={cx(classes.btn, classes.btnFirst)}
        variant="contained"
        color={viewType === 'pipeline' ? 'primary' : 'default'}
        onClick={viewType === 'table' ? () => onChangeViewType('pipeline') : undefined}
      >
        <SvgIcon>
          <GridIcon />
        </SvgIcon>
      </Button>

      <Button
        disabled={isLoading}
        className={cx(classes.btn, classes.btnLast)}
        variant="contained"
        color={viewType === 'table' ? 'primary' : 'default'}
        onClick={viewType === 'pipeline' ? () => onChangeViewType('table') : undefined}
      >
        <SvgIcon>
          <ListIcon />
        </SvgIcon>
      </Button>
    </Box>
  );
};

export default TableSwitch;
