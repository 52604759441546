import React, { FC } from 'react';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { MultipleSelect, Select, TimePicker } from 'common/components';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { ICameFromIndicatorDictionaryItemImt } from 'common/interfaces/dictionary';
import { CommunicationsTypes, LeadValueOptions } from 'modules/crm/constants/leads';
import messages from 'modules/crm/messages/leads';
import inputLabels from 'common/messages/inputLabels';
import CameFromFields from 'common/components/ReactHookForm/CameFromFields/CameFromFields';
import { INamedEntity, INamedEntityImt } from 'common/interfaces/common';
import { ILeadProfileImt } from 'modules/crm/interfaces/leads';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import { RequiredProfileInfo } from 'modules/corporate-settings/constants/requiredFields';

interface ISalesInfoSection {
  employees: INamedEntity<number>[];
  cameFromIndicators: ImmutableList<ICameFromIndicatorDictionaryItemImt>;
  persons: ImmutableList<INamedEntityImt>;
  getReferralMembersByValue: (search: string) => void;
  clearSearchResults: () => void;
  isPersonSearchLoading: boolean;
  primaryInfo: ILeadProfileImt;
  recommendedFields: TShortProfileFieldInfoImt;
}

const cameFromLayoutConfig = {
  singleInputField: { xs: 6 },
  dropdownSelectField: { xs: 6 },
  referralListField: { xs: 6 },
  cameFromField: { xs: 6 },
} as const;

const SalesInfoSection: FC<ISalesInfoSection> = ({
  employees,
  cameFromIndicators,
  isPersonSearchLoading,
  clearSearchResults,
  getReferralMembersByValue,
  recommendedFields,
  primaryInfo,
  persons,
}) => {
  const renderIntlMessage = useRenderIntlMessage();
  const { control, formState } = useFormContext();
  const { errors } = formState;

  const employeeToOption = (employee: INamedEntity<number>) => ({
    id: employee.id.toString(),
    name: employee.title,
    title: employee.title,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="button" color="textSecondary">
          <FormattedMessage {...messages.newLeadPrimaryInfoStepSalesInfo} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <CameFromFields
                isPersonSearchLoading={isPersonSearchLoading}
                persons={persons}
                clearSearchResults={clearSearchResults}
                getReferralMembersByValue={getReferralMembersByValue}
                cameFromIndicators={cameFromIndicators}
                layoutConfig={cameFromLayoutConfig}
                referralMember={primaryInfo.get('referralMember')}
                cameFromIndicator={primaryInfo.get('cameFromIndicator')}
              />
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Controller
              control={control}
              name="salesValue"
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={<FormattedMessage {...inputLabels.salesValue} />}
                  variant="outlined"
                  error={!!errors.salesValue}
                  helperText={renderIntlMessage(errors.salesValue?.message)}
                  fullWidth
                >
                  {LeadValueOptions.getSelectOptions()}
                </Select>
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              control={control}
              name="preferableWayOfCommunication"
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={<FormattedMessage {...inputLabels.preferableWayOfCommunication} />}
                  variant="outlined"
                  error={!!errors.preferableWayOfCommunication}
                  helperText={renderIntlMessage(errors.preferableWayOfCommunication?.message)}
                  fullWidth
                >
                  {CommunicationsTypes.getSelectOptions()}
                </Select>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="assignedSalesperson"
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <MultipleSelect
                  options={employees.length ? employees.map(employeeToOption) : []}
                  label={<FormattedMessage {...inputLabels.assignedTo} />}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  error={!!errors.assignedSalesperson}
                  helperText={renderIntlMessage(errors.assignedSalesperson?.message)}
                  fullWidth
                  recommended={Boolean(recommendedFields?.get(RequiredProfileInfo.Salesperson))}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="button" color="textSecondary">
              <FormattedMessage {...messages.newLeadPrimaryInfoStepLeadDoNotContactText} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="doNotContactHours.from"
              render={({ field: { onChange, name, value, onBlur } }) => (
                <TimePicker
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  labelMessageDescriptor={inputLabels.from}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="doNotContactHours.to"
              render={({ field: { onChange, name, value, onBlur } }) => (
                <TimePicker
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  labelMessageDescriptor={inputLabels.to}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(SalesInfoSection);
