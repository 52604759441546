import React, { FC } from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import commonMessages from 'common/messages/messages';
import messages from 'common/components/EmployeeProfile/messages';
import { IEmployeeProfileDtoImt } from 'common/interfaces/employee';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';

interface IBasicInfo {
  profile: IEmployeeProfileDtoImt;
}

const useStyles = makeStyles((theme: Theme) => ({
  profileNameContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '12px',
    marginBottom: '16px',
    '& > p': {
      lineHeight: '1.37',
    },
  },
  generalInfoTitle: {
    paddingRight: theme.spacing(3),
  },
}));

const BasicInfo: FC<IBasicInfo> = ({ profile }) => {
  const [timezoneMoment] = useTimezoneMoment();
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();
  const preferredName = profile.get('preferredName');

  let profileName = `${profile.get('firstName')} ${profile.get('lastName')} ${
    preferredName ? `(${preferredName})` : ''
  }`;

  profileName = preferredName ? profileName : profileName.trim();

  return (
    <>
      <div className={classes.profileNameContainer}>
        <Typography variant="h3">{profileName}</Typography>

        <Typography variant="body1" color={profile.get('active') ? 'primary' : 'textSecondary'}>
          {profile.get('active') ? (
            <FormattedMessage {...commonMessages.activeStatus} />
          ) : (
            <FormattedMessage {...commonMessages.inactiveStatus} />
          )}
        </Typography>
      </div>

      <Box pb={2} display="flex" flexWrap="wrap">
        {profile.get('employeeIdentifier') && (
          <Typography component="p" color="textSecondary" className={classes.generalInfoTitle}>
            <FormattedMessage
              {...messages.employeeId}
              values={{ employeeId: profile.get('employeeIdentifier') }}
            />
          </Typography>
        )}

        {profile.get('userId') && (
          <Typography component="p" color="textSecondary" className={classes.generalInfoTitle}>
            <FormattedMessage
              {...commonMessages.userId}
              values={{ userId: profile.get('userId') }}
            />
          </Typography>
        )}

        <Typography component="p" color="textSecondary" className={classes.generalInfoTitle}>
          <FormattedMessage
            {...messages.hired}
            values={{ hired: timezoneMoment(profile.get('hireDate')).format(DEFAULT_DATE_FORMAT) }}
          />
        </Typography>

        {profile.get('terminationDate') && (
          <Typography component="p" color="textSecondary" className={classes.generalInfoTitle}>
            <FormattedMessage
              {...messages.term}
              values={{
                term: timezoneMoment(profile.get('terminationDate') || undefined).format(
                  DEFAULT_DATE_FORMAT,
                ),
              }}
            />
          </Typography>
        )}

        <Typography component="p" color="textSecondary" className={classes.generalInfoTitle}>
          <FormattedMessage
            {...messages.exemptions}
            values={{
              exemptions:
                profile.get('exemptionsNumber') ?? renderIntlMessage(commonMessages.noOption),
            }}
          />
        </Typography>

        <Typography component="p" color="textSecondary" className={classes.generalInfoTitle}>
          <FormattedMessage
            {...messages.withholding}
            values={{ withholding: `$${profile.get('federalWithholding')?.toFixed(2) || '0.00'}` }}
          />
        </Typography>

        <Typography component="p" color="textSecondary" className={classes.generalInfoTitle}>
          <FormattedMessage
            {...messages.discount}
            values={{ discount: `${profile.get('posDiscountPercentage') ?? 0}%` }}
          />
        </Typography>
      </Box>
    </>
  );
};

export default BasicInfo;
