import { ConstOptions } from 'common/constants/classes';
import { InvoiceStatus, PaymentsType } from 'common/interfaces/invoices';
import { colors } from 'common/ui/theme/default';

import messages from 'modules/reports/messages/messages';
import commonMessages from 'common/messages/messages';

export const actionTypes = {
  // Errors
  PUSH_REQUEST_ERROR: 'PUSH_REQUEST_ERROR',
  RESET_REQUEST_ERRORS: 'RESET_REQUEST_ERRORS',
  // Invoices
  SELECT_INVOICE: 'SELECT_INVOICE',
  SET_SALESPERSON_TO_INVOICE_SUCCESS: 'SET_SALESPERSON_TO_INVOICE_SUCCESS',
  SET_CUSTOMER_TO_INVOICE_SUCCESS: 'SET_CUSTOMER_TO_INVOICE_SUCCESS',
  CREATE_INVOICE_LOADING: 'CREATE_INVOICE_LOADING',
  CREATE_INVOICE: 'CREATE_INVOICE',
  ADD_INVOICE_UNIT: 'ADD_INVOICE_UNIT',
  ADD_INVOICE_UNIT_LOADING: 'ADD_INVOICE_UNIT_LOADING',
  DELETE_INVOICE_UNIT: 'DELETE_INVOICE_UNIT',
  DELETE_INVOICE_UNIT_LOADING: 'DELETE_INVOICE_UNIT_LOADING',
  CHANGE_INVOICE_UNIT_NUMBER: 'CHANGE_INVOICE_UNIT_NUMBER',
  CHANGE_INVOICE_UNIT_NUMBER_LOADING: 'CHANGE_INVOICE_UNIT_NUMBER_LOADING',
  ADD_INVOICE_DISCOUNT: 'ADD_INVOICE_DISCOUNT',
  // Salesperson
  FETCH_SALESPERSON_LOADING: 'FETCH_SALESPERSON_LOADING',
  FETCH_SALESPERSON: 'FETCH_SALESPERSON',
  RESET_SALESPERSON: 'RESET_SALESPERSON',
  // Customer
  FETCH_CUSTOMERS_LIST: 'FETCH_CUSTOMERS_LIST',
  RESET_CUSTOMERS_LIST: 'RESET_CUSTOMERS_LIST',
  // Balance
  FETCH_BALANCE_BY_CUSTOMER_ID: 'INVOICE/FETCH_BALANCE_BY_CUSTOMER_ID',
  FETCH_BALANCE_BY_CUSTOMER_ID_LOADING: 'INVOICE/FETCH_BALANCE_BY_CUSTOMER_ID_LOADING',
  RESET_BALANCE: 'INVOICE/RESET_BALANCE',
  // Search Inventory
  FETCH_SEARCH_INVENTORY_LOADING: 'FETCH_SEARCH_INVENTORY_LOADING',
  FETCH_SEARCH_INVENTORY: 'FETCH_SEARCH_INVENTORY',
  RESET_SEARCH_INVENTORY_RESULT: 'RESET_SEARCH_INVENTORY_RESULT',
  // Search packages
  FETCH_SEARCH_PACKAGES_LOADING: 'FETCH_SEARCH_PACKAGES_LOADING',
  FETCH_SEARCH_PACKAGES: 'FETCH_SEARCH_PACKAGES',
  RESET_SEARCH_PACKAGES_RESULT: 'RESET_SEARCH_PACKAGES_RESULT',
  // Search gift cards
  FETCH_SEARCH_GIFT_CARDS_LOADING: 'FETCH_SEARCH_GIFT_CARDS_LOADING',
  FETCH_SEARCH_GIFT_CARDS: 'FETCH_SEARCH_GIFT_CARDS',
  RESET_SEARCH_GIFT_CARDS: 'RESET_SEARCH_GIFT_CARDS',

  SELECT_CURRENT_INVOICE: 'SELECT_CURRENT_INVOICE',
  UPDATE_INVOICE_LOADING: 'UPDATE_INVOICE_LOADING',
  REMOVE_INVOICE: 'REMOVE_INVOICE',

  CANCEL_INVOICE: 'CANCEL_INVOICE',
  CANCEL_INVOICE_LOADING: 'CANCEL_INVOICE_LOADING',
  RESET_CANCEL_INVOICE: 'RESET_CANCEL_INVOICE',

  FETCH_INVOICES_LIST: 'FETCH_INVOICES_LIST',
  FETCH_INVOICES_LIST_LOADING: 'FETCH_INVOICES_LIST_LOADING',
  UPDATE_SOCKET_INVOICES_LIST: 'UPDATE_SOCKET_INVOICES_LIST',
  REMOVE_SOCKET_INVOICE: 'REMOVE_SOCKET_INVOICE',
  RESET_INVOICES_LIST: 'RESET_INVOICES_LIST',

  RESET_INVOICE_OPERATING_STATE: 'RESET_INVOICE_OPERATING_STATE',

  FETCH_PAYMENT_METHOD_BY_BARCODE_RESULT: 'FETCH_PAYMENT_METHOD_BY_BARCODE_RESULT',
  FETCH_PAYMENT_METHOD_BY_BARCODE_LOADING: 'FETCH_PAYMENT_METHOD_BY_BARCODE_LOADING',
  FETCH_PAYMENT_METHOD_BY_BARCODE_ERROR: 'FETCH_PAYMENT_METHOD_BY_BARCODE_ERROR',
  RESET_FETCH_PAYMENT_METHOD_BY_BARCODE: 'RESET_FETCH_PAYMENT_METHOD_BY_BARCODE',

  FETCH_AVAILABLE_PAYMENT_METHODS_RESULT: 'FETCH_AVAILABLE_PAYMENT_METHODS_RESULT',
  FETCH_AVAILABLE_PAYMENT_METHODS_LOADING: 'FETCH_AVAILABLE_PAYMENT_METHODS_LOADING',
  FETCH_AVAILABLE_PAYMENT_METHODS_ERROR: 'FETCH_AVAILABLE_PAYMENT_METHODS_ERROR',
  RESET_AVAILABLE_PAYMENT_METHODS: 'RESET_AVAILABLE_PAYMENT_METHODS',

  UPDATE_INVOICE_WITH_SYNC_LOADING: 'UPDATE_INVOICE_WITH_SYNC_LOADING',
  UPDATE_INVOICE_WITH_SYNC_RESULT: 'UPDATE_INVOICE_WITH_SYNC_RESULT',
  UPDATE_INVOICE_WITH_SYNC_ERROR: 'UPDATE_INVOICE_WITH_SYNC_ERROR',
  RESET_UPDATE_INVOICE_WITH_SYNC: 'RESET_UPDATE_INVOICE_WITH_SYNC',

  SET_CHECKOUT_RESULT: 'SET_CHECKOUT_RESULT',
  RESET_CHECKOUT_RESULT: 'RESET_CHECKOUT_RESULT',

  FETCH_RECEIPT_LOADING: 'FETCH_RECEIPT_LOADING',

  RESET_STORED_CREDIT_CARDS_REDUCER: 'RESET_STORED_CREDIT_CARDS_REDUCER',
  FETCH_STORED_CREDIT_CARDS_RESULT: 'FETCH_STORED_CREDIT_CARDS_RESULT',
  FETCH_STORED_CREDIT_CARDS_LOADING: 'FETCH_STORED_CREDIT_CARDS_LOADING',
  STORE_CREDIT_CARD_RESULT: 'STORE_CREDIT_CARD_RESULT',
  STORE_CREDIT_CARD_LOADING: 'STORE_CREDIT_CARD_LOADING',
  RESET_STORED_CREDIT_CARD_RESULT: 'RESET_STORED_CREDIT_CARD_RESULT',
  FETCH_COMMERCEHUB_CREDENTIALS: 'FETCH_COMMERCEHUB_CREDENTIALS',
  RESET_COMMERCEHUB_CREDENTIALS: 'RESET_COMMERCEHUB_CREDENTIALS',
  FETCH_COMMERCEHUB_CREDENTIALS_LOADING: 'FETCH_COMMERCEHUB_CREDENTIALS_LOADING',

  FETCH_CHECKING_SAVINGS_DATA: 'INVOICE/FETCH_CHECKING_SAVINGS_DATA',
  FETCH_CHECKING_SAVINGS_DATA_LOADING: 'INVOICE/FETCH_CHECKING_SAVINGS_DATA_LOADING',
  ADD_CHECKING_SAVINGS_DATA: 'INVOICE/ADD_CHECKING_SAVINGS_DATA',
  ADD_CHECKING_SAVINGS_DATA_LOADING: 'INVOICE/ADD_CHECKING_SAVINGS_DATA_LOADING',
  ADD_CHECKING_SAVINGS_DATA_ACTION_RESULT: 'INVOICE/ADD_CHECKING_SAVINGS_DATA_ACTION_RESULT',
  DELETE_CHECKING_SAVINGS_ITEM: 'INVOICE/DELETE_CHECKING_SAVINGS_ITEM',
  DELETE_CHECKING_SAVINGS_ITEM_LOADING: 'INVOICE/DELETE_CHECKING_SAVINGS_ITEM_LOADING',
  DELETE_CHECKING_SAVINGS_ITEM_ACTION_RESULT: 'INVOICE/DELETE_CHECKING_SAVINGS_ITEM_ACTION_RESULT',
  RESET_CHECKING_SAVINGS_DATA: 'INVOICE/RESET_CHECKING_SAVINGS_DATA',

  // Rewards
  FETCH_REWARDS_BY_CUSTOMER_ID: 'INVOICE/FETCH_REWARDS_BY_CUSTOMER_ID',
  FETCH_REWARDS_BY_CUSTOMER_ID_LOADING: 'INVOICE/FETCH_REWARDS_BY_CUSTOMER_ID_LOADING',
  RESET_REWARDS: 'INVOICE/RESET_REWARDS',
};

export const InvoiceStatuses = new ConstOptions([
  { value: InvoiceStatus.OPEN, message: messages.openStatus, color: colors.text.mediumGray },
  { value: InvoiceStatus.PAID, message: messages.paid, color: colors.primary },
  { value: InvoiceStatus.UNPAID, message: messages.unpaid, color: colors.error },
  { value: InvoiceStatus.VOIDED, message: messages.voided, color: colors.error },
  { value: InvoiceStatus.CHECK_OUT, message: messages.checkout, color: colors.warning },
  { value: InvoiceStatus.PENDING, message: messages.pending, color: colors.warning },
  { value: InvoiceStatus.DEFERRED, message: messages.deferred, color: colors.error },
  { value: InvoiceStatus.IN_PROGRESS, message: messages.inProgress, color: colors.warning },
]);

export enum CheckingSavingsTypes {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
}

export const PaymentTypeLabels = new ConstOptions([
  { value: PaymentsType.CASH, message: commonMessages.cashLabel },
  { value: PaymentsType.MONEY_ORDER, message: commonMessages.moneyOrderLabel },
  { value: PaymentsType.ON_ACCOUNT, message: commonMessages.onAccountLabel },
  {
    value: PaymentsType.TAB_ON_A_CORPORATE_ACCOUNT,
    message: commonMessages.tabOnCorporateAccountLabel,
  },
  { value: PaymentsType.MEMBER_REWARDS, message: commonMessages.memberRewardsLabel },
  { value: PaymentsType.CHECKING_SAVINGS, message: commonMessages.checkingSavingsLabel },
  { value: PaymentsType.CUSTOM, message: commonMessages.giftCardLabel },
  { value: PaymentsType.CREDIT_CARD, message: commonMessages.creditCardLabel },
]);
