import React, { useState } from 'react';
import { DialogComponent } from 'common/components';
import { Grid, TextField } from '@material-ui/core';

interface IEmbeddedIframeModalProps {
  isOpen: boolean;
  onSubmit: (embeddingCode: string) => void;
  onClose: () => void;

  embeddingCode?: string;
}

const EmbeddedIframeModal: React.FC<IEmbeddedIframeModalProps> = (
  props: IEmbeddedIframeModalProps,
): JSX.Element => {
  const { isOpen, onSubmit, onClose, embeddingCode: embCode = '' } = props;
  const [embeddingCode, setEmbeddingCode] = useState<string>(embCode);

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={() => onSubmit(embeddingCode)}
      title="Enter embedding code"
      size="xs"
      disableFullScreenBehavior
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={10}
            label="Embedding code"
            variant="outlined"
            value={embeddingCode}
            onChange={e => setEmbeddingCode(e.target.value)}
          />
        </Grid>
      </Grid>
    </DialogComponent>
  );
};

export default EmbeddedIframeModal;
