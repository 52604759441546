import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';

import {
  checkIsPaymentScheduleOptionDisabled,
  countTaxedAmount,
} from 'modules/services/utils/billingUtils';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { FieldInfo, Select } from 'common/components/index';

import {
  BillingOptionType,
  FrequencyType,
  FrequencyTypes,
} from 'modules/services/constants/packages';
import { IEditablePackageConfiguration } from 'common/interfaces/membership';
import { IBillingOption } from 'modules/services/interfaces/packages';

import inputLabels from 'common/messages/inputLabels';
import servicesMessages from 'modules/services/messages/messages';

interface IAmountSection {
  membershipPackage: IEditablePackageConfiguration | null;
  billingOption: IBillingOption | null;
  isOutOfTerm: boolean;
}

const AmountSection = ({
  membershipPackage,
  billingOption,
  isOutOfTerm,
}: IAmountSection): JSX.Element => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  const renderIntlMessage = useRenderIntlMessage();

  const { totalAmount, type: optionType } = billingOption || {};
  const { revenueCode, durationType, durationEditableNumber } = membershipPackage || {};

  const { totalTax } = revenueCode || {};

  const taxedTotalAmount =
    totalAmount && totalTax ? countTaxedAmount(totalAmount, totalTax) : totalAmount;

  const valuePath = isOutOfTerm ? 'outOfTermBillingOption.' : '';

  return (
    <>
      <Grid item xs={6}>
        <FieldInfo
          inputMode
          grayedOut
          label={<FormattedMessage {...inputLabels.totalAmount} />}
          description={`$${totalAmount?.toFixed(2)}`}
        />
      </Grid>

      <Grid item xs={6}>
        <FieldInfo
          inputMode
          grayedOut
          label={<FormattedMessage {...inputLabels.taxedTotalAmount} />}
          description={`$${taxedTotalAmount?.toFixed(2)}`}
        />
      </Grid>

      {optionType !== BillingOptionType.PAID_IN_FULL && (
        <>
          <Grid item xs={6}>
            <Controller
              name={`${valuePath}paymentEditableSchedule`}
              control={control}
              render={({ field }) => {
                const { value: paymentEditableSchedule, onChange } = field;

                return (
                  <Select
                    label={<FormattedMessage {...servicesMessages.paymentScheduleLabel} />}
                    variant="outlined"
                    error={!!errors.paymentEditableSchedule}
                    helperText={renderIntlMessage(errors.paymentEditableSchedule?.message)}
                    fullWidth
                    disabled={!paymentEditableSchedule?.editable}
                    value={paymentEditableSchedule?.value || ''}
                    onChange={scheduleValue => {
                      onChange({ ...paymentEditableSchedule, value: scheduleValue });
                    }}
                  >
                    {FrequencyTypes.getSelectOptions({
                      isDisabledFunc: (type: FrequencyType) => {
                        if (durationType) {
                          return checkIsPaymentScheduleOptionDisabled(
                            durationType,
                            Number(durationEditableNumber?.value),
                            type,
                          );
                        }

                        return false;
                      },
                    })}
                  </Select>
                );
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="pricePerBilling"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <FieldInfo
                  inputMode
                  grayedOut
                  label={<FormattedMessage {...servicesMessages.pricePerBillingLabel} />}
                  description={`$${field.value?.toFixed(2)}`}
                />
              )}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default AmountSection;
