import React, { useState } from 'react';
import isNumber from 'lodash/isNumber';
import { IProfileInfoImt } from 'common/components/PersonProfile/interfaces';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { useAppDispatch } from 'store/hooks';
import { fetchLeadProfile } from 'modules/crm/state/leads/actions';
import MembershipModal from 'common/components/PersonProfile/components/MembershipBlock/MembershipModal/MembershipModal';
import { MembershipCard } from 'common/components/PersonProfile/components/index';
import { PeakModules } from 'common/constants/peakModules';
import { fetchProfileInfoView } from 'common/components/PersonProfile/state/actions';
import { getMember } from 'common/state/newPerson/primaryInfo/actions';

interface IMembershipBlockProps {
  profile: IProfileInfoImt;
  module?: PeakModuleForNewPersonType;
}

const MembershipBlock = ({ profile, module }: IMembershipBlockProps): JSX.Element => {
  const membership = profile.get('membership');
  const profileId = profile.get('id');
  const dispatch = useAppDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleModalOpen = () => {
    setIsOpenModal(true);

    if (isNumber(profileId)) {
      dispatch(getMember(profileId));
    }
  };

  const handleModalClose = () => {
    if (module === PeakModules.Members) {
      if (isNumber(profileId)) {
        dispatch(fetchProfileInfoView(profileId, module));
      }
    } else {
      if (isNumber(profileId) && module) {
        dispatch(fetchLeadProfile(profileId, module));
      }
    }

    setIsOpenModal(false);
  };

  return (
    <>
      <MembershipModal profile={profile} open={isOpenModal} onClose={handleModalClose} />

      <MembershipCard
        membership={membership}
        personId={Number(profileId)}
        openChangePackagePlan={handleModalOpen}
        module={module}
      />
    </>
  );
};

export default MembershipBlock;
