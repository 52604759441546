import { regExp } from 'common/constants';
import { EmployeePayTypes } from 'common/constants/employee';
import * as yup from 'yup';
import { getRequiredErrorMessage } from 'common/utils/validation';
import validationMessages from 'common/messages/validation';

export const nameSchema = yup
  .string()
  .nullable()
  .max(32, () => validationMessages.nameLength);

export const literalNameSchema = nameSchema.matches(
  regExp.NAME,
  () => validationMessages.nameLettersOnly,
);

export const exemptionsNumberSchema = yup
  .number()
  .transform(v => (v || v === 0 ? v : -1))
  .min(0, () => validationMessages.exemptionMinVal)
  .max(99, () => validationMessages.exemptionMaxVal);

export const idSchema = yup
  .string()
  .nullable()
  .max(50, () => validationMessages.idMaxLength);

export const jobPositionBaseSchema = {
  jobTitle: yup
    .string()
    .trim()
    .required(getRequiredErrorMessage),
  salary: yup
    .number()
    .typeError(getRequiredErrorMessage)
    .min(0, () => validationMessages.salaryMinVal),
  payType: yup
    .string()
    .oneOf(Object.values(EmployeePayTypes))
    .required(getRequiredErrorMessage),
};

export const employeeJobPositionsSchema = yup
  .array()
  .of(
    yup.object().shape({
      ...jobPositionBaseSchema,
      club: yup
        .object({
          title: yup.string().required(getRequiredErrorMessage),
        })
        .nullable()
        .required(getRequiredErrorMessage),
      department: yup
        .object({
          title: yup.string().required(getRequiredErrorMessage),
        })
        .nullable()
        .required(getRequiredErrorMessage),
      primary: yup.boolean(),
      salesperson: yup.boolean(),
    }),
  )
  .min(0);

export const peakEmployeeJobPositionsSchema = yup
  .array()
  .of(yup.object().shape(jobPositionBaseSchema))
  .min(0);
