import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: {
    id: 'app.routes.front-desk.pageTitle',
    defaultMessage: 'Main Reception',
  },
  ID: {
    id: 'app.modules.front-desk.id',
    defaultMessage: 'ID: {personId}',
  },
  SSN: {
    id: 'app.modules.front-desk.ssn',
    defaultMessage: 'SSN: {SSN}',
  },

  // CurrentCheckin
  checkingInNow: {
    id: 'app.modules.front-desk.currentCheckin.checkingInNow',
    defaultMessage: 'Checking in now',
  },
  selectEntranceText: {
    id: 'app.modules.front-desk.entranceSelector.selectEntranceText',
    defaultMessage: 'Please select an Entrance',
  },
  discardEnteredDataQuestion: {
    id: 'app.modules.front-desk.entranceSelector.discardEnteredDataQuestion',
    defaultMessage: 'Discard entered data?',
  },
  notBeAbleToSaveYourDataText: {
    id: 'app.modules.front-desk.entranceSelector.notBeAbleToSaveYourDataText',
    defaultMessage: "We won't be able to save your data if you go away.",
  },

  // Notes
  emptyNotesListTextMessage: {
    id: 'app.modules.front-desk.sections.profile.notes.empty-text.message',
    defaultMessage: 'No notes added.',
  },
  emptyNotesListTextDescription: {
    id: 'app.modules.front-desk.sections.profile.notes.empty-text.description',
    defaultMessage: 'You can add a note by pressing the button above',
  },
  // Products
  emptyProductsListMessage: {
    id: 'app.modules.front-desk.sections.profile.products.empty-text.message',
    defaultMessage: 'No suggested products.',
  },
  // Family members
  emptyFamilyMembersListTextMessage: {
    id: 'app.modules.front-desk.sections.profile.family-members.empty-text.message',
    defaultMessage: 'No family members added.',
  },
  emptyFamilyMembersListTextDescription: {
    id: 'app.modules.front-desk.sections.profile.family-members.empty-text.description',
    defaultMessage: 'You can add a family member by pressing the button above',
  },
  emptyTableTextDescription: {
    id: 'app.modules.front-desk.sections.profile.table.empty-text.description',
    defaultMessage: 'No data yet',
  },
  sessionsHistory: {
    id: 'app.modules.front-desk.sections.profile.sessions.sessionsHistory.button',
    defaultMessage: 'History',
  },
});
