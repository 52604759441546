import React, { useCallback, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import {
  IEditablePackageConfiguration,
  IPackageConfigurationImt,
  IPackageService,
} from 'common/interfaces/membership';
import { PackageCostType } from 'modules/services/constants/packages';

import EditBillingOptionModal from './modals/EditBillingOptionModal/EditBillingOptionModal';
import EditFeesModal from './modals/EditFeesModal/EditFeesModal';
import EditGeneralPackageModal from './modals/EditGeneralPackageModal/EditGeneralPackageModal';
import IncludedInventorySectionView from './IncludedInventorySectionView/IncludedInventorySectionView';
import PackageSectionView from 'common/components/MembershipFormView/PackageSectionView/PackageSectionView';
import BillingSectionView from 'common/components/MembershipFormView/BillingSectionView/BillingSectionView';
import IncludedServicesSectionView from 'common/components/MembershipFormView/IncludedServicesSectionView/IncludedServicesSectionView';
import FeesSectionView from 'common/components/MembershipFormView/FeesSectionView/FeesSectionView';
import PackageInventoryViewInfoModal from 'common/components/MembershipFormView/modals/PackageInventoryViewModal/PackageInventoryViewModal';
import { PeakModules } from 'common/constants/peakModules';

interface IProps {
  onResetConfiguration?: () => void;
  initialMembershipPackage: IPackageConfigurationImt;
  module: PeakModules;
}

export type TEditableInstance = 'billingOption' | 'fees' | 'generalPackage' | 'outOfTerm';

const initialState: {
  servicePackage: IEditablePackageConfiguration | null;
  editableInstance: TEditableInstance | null;
} = { servicePackage: null, editableInstance: null };

const MembershipFormView = ({
  onResetConfiguration,
  initialMembershipPackage,
  module,
}: IProps): JSX.Element => {
  const [editablePackage, setEditablePackage] = useState(initialState);
  const [{ membershipPackageId, inventoryId }, setInventoryData] = useState<Record<string, any>>({
    membershipPackageId: null,
    inventoryId: null,
  });

  const { setValue, control, watch } = useFormContext();

  const membershipPackage: IEditablePackageConfiguration = watch('packageConfiguration');

  const onEditPackage = useCallback(
    (formValues: IEditablePackageConfiguration) => {
      const packageId = formValues.id;

      const getUpdatedPackage = packageItem => {
        if (packageItem.id === packageId) {
          return { ...packageItem, ...formValues };
        }

        return {
          ...packageItem,
          ...(packageItem.services?.length && {
            services: packageItem.services.map(serviceItem =>
              serviceItem.package
                ? { ...serviceItem, package: getUpdatedPackage(serviceItem.package) }
                : { ...serviceItem },
            ),
          }),
        };
      };

      setValue('packageConfiguration', getUpdatedPackage(membershipPackage));
      setEditablePackage(initialState);
    },
    [membershipPackage, setValue],
  );

  const onExcludePackage = useCallback(
    (packageService: IPackageService) => {
      const getUpdatedPackage = packageItem => {
        return {
          ...packageItem,
          ...(packageItem.services?.length && {
            services: packageItem.services.map(serviceItem =>
              serviceItem.id === packageService.id
                ? { ...serviceItem, ...packageService }
                : getUpdatedPackage(serviceItem),
            ),
          }),
          ...(packageItem.package?.services?.length && {
            package: {
              ...packageItem.package,
              services: packageItem.package.services.map(serviceItem =>
                serviceItem.id === packageService.id
                  ? { ...serviceItem, ...packageService }
                  : getUpdatedPackage(serviceItem),
              ),
            },
          }),
        };
      };

      setValue('packageConfiguration', getUpdatedPackage(membershipPackage));
    },
    [membershipPackage, setValue],
  );

  const handleModalOpen = (
    servicePackage: IEditablePackageConfiguration,
    instance: TEditableInstance,
  ) => {
    setEditablePackage({ servicePackage, editableInstance: instance });
  };

  const onViewInventoryInfo = (packageId: string, id: string) => {
    setInventoryData({ membershipPackageId: packageId, inventoryId: id });
  };

  const closeInventoryInfo = () => {
    setInventoryData({ membershipPackageId: null, inventoryId: null });
  };

  const isOpenEditBillingModal = Boolean(editablePackage.servicePackage);

  return (
    <Controller
      control={control}
      name="packageConfiguration"
      render={({ field }) => {
        const packageConfiguration = field.value;

        return (
          <>
            <PackageSectionView
              onResetConfiguration={onResetConfiguration}
              membershipPackage={packageConfiguration}
              onEditPackage={handleModalOpen}
            />

            {packageConfiguration.costType !== PackageCostType.Free && (
              <BillingSectionView
                membershipPackage={packageConfiguration}
                onEditPackage={handleModalOpen}
              />
            )}
            {packageConfiguration.costType !== PackageCostType.Free && (
              <BillingSectionView
                membershipPackage={packageConfiguration}
                onEditPackage={handleModalOpen}
                isOutOfTerm
              />
            )}

            <IncludedServicesSectionView
              onViewInventoryInfo={onViewInventoryInfo}
              membershipPackage={packageConfiguration}
              onEditPackage={handleModalOpen}
              onExcludePackage={onExcludePackage}
              onExcludeFee={onEditPackage}
            />

            <IncludedInventorySectionView
              onViewInventoryInfo={onViewInventoryInfo}
              membershipPackage={packageConfiguration}
            />

            <FeesSectionView
              membershipPackage={packageConfiguration}
              onEditFees={handleModalOpen}
              onExcludeFee={onEditPackage}
            />

            {editablePackage.editableInstance === 'billingOption' && isOpenEditBillingModal && (
              <EditBillingOptionModal
                isOpen={isOpenEditBillingModal}
                onClose={() => setEditablePackage(initialState)}
                onSubmit={onEditPackage}
                membershipPackage={editablePackage.servicePackage}
                isIncludedBilling={packageConfiguration.id !== editablePackage.servicePackage?.id}
              />
            )}

            {editablePackage.editableInstance === 'outOfTerm' && isOpenEditBillingModal && (
              <EditBillingOptionModal
                isOpen={isOpenEditBillingModal}
                onClose={() => setEditablePackage(initialState)}
                onSubmit={onEditPackage}
                membershipPackage={editablePackage.servicePackage}
                isIncludedBilling={packageConfiguration.id !== editablePackage.servicePackage?.id}
                isOutOfTerm
              />
            )}

            {editablePackage.editableInstance === 'fees' && (
              <EditFeesModal
                isOpen={!!editablePackage}
                onClose={() => setEditablePackage(initialState)}
                onSubmit={onEditPackage}
                membershipPackage={editablePackage.servicePackage}
              />
            )}

            {editablePackage.editableInstance === 'generalPackage' && (
              <EditGeneralPackageModal
                isOpen={!!editablePackage}
                onClose={() => setEditablePackage(initialState)}
                onSubmit={onEditPackage}
                membershipPackage={editablePackage.servicePackage}
                initialMembershipPackage={initialMembershipPackage}
              />
            )}
            {Boolean(inventoryId && membershipPackageId) && (
              <PackageInventoryViewInfoModal
                isOpen
                module={module}
                inventoryId={inventoryId}
                packageId={membershipPackageId}
                onClose={closeInventoryInfo}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default MembershipFormView;
