import React, { forwardRef, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { billingSchema } from './billingValidationSchema';
import { IBillingSection, IPackageStepProps } from 'modules/services/interfaces/packages';
import { ScrollBox } from 'common/components';
import BillingStepForm from './BillingStepForm';
import { useStepsLogic } from 'modules/services/hooks/useStepLogics';
import { defaultEditPackageFormData, DurationType } from 'modules/services/constants/packages';

interface IBillingStepProps extends IPackageStepProps<IBillingSection> {
  durationType: DurationType | string;
  durationEditableNumber: string | number;
  startDate: string;
  endDate: string;
}

const BillingStep = forwardRef(
  (
    {
      editPackageSectionFormData,
      renderFooter,
      handleChange,
      onPackageSubmit,
      submitAttempted,
      durationType,
      durationEditableNumber,
      startDate,
      endDate,
    }: IBillingStepProps,
    ref,
  ): JSX.Element => {
    const defaultBillingSectionData: IBillingSection = useMemo(
      () => ({ ...defaultEditPackageFormData.billingSection, ...editPackageSectionFormData }),
      [editPackageSectionFormData],
    );

    const formMethods = useForm<any>({
      defaultValues: defaultBillingSectionData,
      resolver: yupResolver(billingSchema),
      mode: 'all',
      shouldUnregister: false,
    });

    const { trigger, getValues } = formMethods;

    const { onSubmit, onNext, onBack } = useStepsLogic(
      'billingSection',
      ref,
      getValues,
      trigger,
      onPackageSubmit,
      handleChange,
      submitAttempted,
    );

    return (
      <FormProvider {...formMethods}>
        <ScrollBox hasShadowsOnScroll>
          <form autoComplete="none">
            <BillingStepForm
              formMethods={formMethods}
              durationType={durationType}
              durationEditableNumber={durationEditableNumber}
              startDate={startDate}
              endDate={endDate}
            />
          </form>
        </ScrollBox>
        {renderFooter(onSubmit, onNext, onBack)}
      </FormProvider>
    );
  },
);

export default BillingStep;
