import { defineMessages } from 'react-intl';

export default defineMessages({
  newSMSTitle: {
    id: 'modules.crm.templates.newSMSTitle',
    defaultMessage: 'New SMS Template',
  },
  editSMSTitle: {
    id: 'modules.crm.templates.editSMSTitle',
    defaultMessage: 'Edit SMS Template',
  },
  editEmailTitle: {
    id: 'modules.crm.templates.editTitle',
    defaultMessage: 'Edit Email Template',
  },
  emailTemplateName: {
    id: 'app.modules.crm.templates.emailTemplateName',
    defaultMessage: 'Name must be 32 characters or less',
  },
  emailTemplateSubject: {
    id: 'app.modules.crm.templates.emailTemplateSubject',
    defaultMessage: 'Subject must be 70 characters or less',
  },
  emailTemplateFromName: {
    id: 'app.modules.crm.templates.emailTemplateFromName',
    defaultMessage: 'From name must be 32 characters or less',
  },
  newEmailTitle: {
    id: 'modules.DocumentTemplates.waiver.newEmailTitle',
    defaultMessage: 'New Email Template',
  },
  nothingFound: {
    id: 'modules.DocumentTemplates.waiver.nothingFound',
    defaultMessage: 'Nothing found',
  },
});
