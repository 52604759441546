import { fromJS } from 'immutable';
import {
  UPLOAD_FILE,
  RESET_UPLOAD_FILE,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_LOADING,
  SET_UPLOAD_DIALOG_ID,
} from 'common/state/uploadFile/constants';
import { RequestActions } from 'common/state/interfaces';

const initialState = fromJS({
  file: null,
  dialogId: null,
  loading: false,
  error: null,
});

export function uploadFileReducer(state = initialState, action: RequestActions) {
  switch (action.type) {
    case UPLOAD_FILE:
      return state
        .set('file', fromJS(action.payload.file))
        .set('dialogId', action.payload.dialogId)
        .set('loading', false)
        .set('error', null);
    case UPLOAD_FILE_LOADING:
      return state.set('loading', action.payload);
    case SET_UPLOAD_DIALOG_ID:
      return state.set('dialogId', action.payload);
    case UPLOAD_FILE_ERROR:
      return state.set('error', action.payload).set('loading', false);
    case RESET_UPLOAD_FILE:
      return initialState;
    default:
      return state;
  }
}
