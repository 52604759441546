import React, { PropsWithChildren } from 'react';
import { getCountries } from 'react-phone-number-input/input';
import labels from 'react-phone-number-input/locale/en.json';
import flags from 'react-phone-number-input/flags';
import { Box, makeStyles, MenuItem, SvgIcon, Theme, Typography } from '@material-ui/core';

import { Select } from 'common/components/index';

const useStyles = makeStyles((theme: Theme) => ({
  countrySelect: {
    position: 'absolute',
    top: '1px',
    left: '1px',
    height: '46px',

    zIndex: 1,

    '& .MuiSelect-root.MuiSelect-select': {
      padding: theme.spacing(1.5, 3, 1.5, 1),
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
}));

const countriesList = getCountries();
const countries = countriesList
  .map(countryName => ({
    FlagIcon: flags[countryName],
    label: labels[countryName],
    countryName,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

const CountryCodeSelect = (props: PropsWithChildren<any>): JSX.Element => {
  const classes = useStyles();

  const { value } = props;

  const ActiveFlagIcon = flags[value];

  return (
    <Select
      {...props}
      variant="outlined"
      className={classes.countrySelect}
      renderValue={() => (
        <Box display="flex" alignItems="center" width="25px">
          <SvgIcon>
            <ActiveFlagIcon title="US" />
          </SvgIcon>
        </Box>
      )}
    >
      {countries.map(({ FlagIcon, label, countryName }) => {
        return (
          <MenuItem value={countryName} key={countryName}>
            <SvgIcon>{FlagIcon ? <FlagIcon title={countryName} /> : null}</SvgIcon>
            <Typography>{label}</Typography>
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default CountryCodeSelect;
