import { ConstOptions } from 'common/constants/classes';

import {
  EventAttendeeType,
  EventOverbookingType,
  EventRepeatEnd,
  EventRepeatFrequency,
  EventRepeatTypes,
} from 'modules/booking/interfaces';

import commonMessages from 'common/messages/messages';
import messages from 'modules/booking/messages';

export enum BookingPaths {
  BookingEvents = '/',
  BookingShared = 'shared',
  ResourcesList = 'resources',
  ResourcesNew = 'resources/new',
  ResourcesEdit = 'resources/:id/edit',
}

export const actionTypes = {
  // Event action types
  GET_BOOKING_EVENTS: 'GET_BOOKING_EVENTS',
  GET_BOOKING_EVENTS_LOADING: 'GET_BOOKING_EVENTS_LOADING',
  RESET_BOOKING_EVENTS: 'RESET_BOOKING_EVENTS',

  GET_BOOKING_EVENT: 'GET_BOOKING_EVENT',
  GET_BOOKING_EVENT_LOADING: 'GET_BOOKING_EVENT_LOADING',
  RESET_BOOKING_EVENT: 'RESET_BOOKING_EVENT',

  CREATE_EVENT_LOADING: 'CREATE_EVENT_LOADING',
  CREATE_EVENT: 'CREATE_EVENT',

  UPDATE_EVENT_LOADING: 'UPDATE_EVENT_LOADING',
  UPDATE_EVENT: 'UPDATE_EVENT',

  CANCEL_EVENT: 'CANCEL_EVENT',
  CANCEL_EVENT_LOADING: 'CANCEL_EVENT_LOADING',

  RESTORE_EVENT: 'RESTORE_EVENT',
  RESTORE_EVENT_LOADING: 'RESTORE_EVENT_LOADING',

  DELETE_EVENT: 'DELETE_EVENT',
  DELETE_EVENT_LOADING: 'DELETE_EVENT_LOADING',

  RESET_BOOKING_EVENT_DETAILS: 'RESET_BOOKING_EVENT_DETAILS',
  RESET_EVENT_ACTION_RESULT: 'RESET_EVENT_ACTION_RESULT',

  JOIN_EVENT_CLASS: 'JOIN_EVENT_CLASS',
  JOIN_EVENT_CLASS_LOADING: 'JOIN_EVENT_CLASS_LOADING',

  // Resource action types
  FETCH_RESOURCES_LIST_LOADING: 'FETCH_RESOURCES_LOADING',
  FETCH_RESOURCES_LIST_SUCCESS: 'FETCH_RESOURCES_SUCCESS',
  RESET_RESOURCES_LIST: 'RESET_RESOURCES_LIST',

  RESET_RESOURCE_FORM: 'RESET_RESOURCE_FORM',
  RESET_RESOURCE_ACTION_RESULT: 'RESET_RESOURCE_ACTION_RESULT',

  FETCH_RESOURCE_LOADING: 'FETCH_RESOURCE_LOADING',
  FETCH_RESOURCE_SUCCESS: 'FETCH_RESOURCE_SUCCESS',

  CREATE_RESOURCE_LOADING: 'CREATE_RESOURCE_LOADING',
  CREATE_RESOURCE_SUCCESS: 'CREATE_RESOURCE_SUCCESS',
  CREATE_RESOURCE_ERROR: 'CREATE_RESOURCE_ERROR',

  UPDATE_RESOURCE_LOADING: 'UPDATE_RESOURCE_LOADING',
  UPDATE_RESOURCE_SUCCESS: 'UPDATE_RESOURCE_SUCCESS',
  UPDATE_RESOURCE_ERROR: 'UPDATE_RESOURCE_ERROR',

  DELETE_RESOURCE_LOADING: 'DELETE_RESOURCE_LOADING',
  DELETE_RESOURCE_SUCCESS: 'DELETE_RESOURCE_SUCCESS',

  CHANGE_RESOURCE_STATUS: 'CHANGE_RESOURCE_STATUS',
  CHANGE_RESOURCE_STATUS_LOADING: 'CHANGE_RESOURCE_STATUS_LOADING',
  RESET_RESOURCE_CHANGE_STATUS: 'RESET_RESOURCE_CHANGE_STATUS',

  // Event services
  FETCH_EVENT_SERVICES_LOADING: 'BOOKING/FETCH_EVENT_SERVICES_LOADING',
  FETCH_EVENT_SERVICES: 'BOOKING/FETCH_EVENT_SERVICES',
  RESET_EVENT_SERVICES: 'BOOKING/RESET_EVENT_SERVICES',

  // Duplication
  DUPLICATE_EVENT: 'DUPLICATE_EVENT',
  DUPLICATE_EVENT_LOADING: 'DUPLICATE_EVENT_LOADING',

  // Remind
  REMIND_EVENT: 'REMIND_EVENT',
  REMIND_EVENT_LOADING: 'REMIND_EVENT_LOADING',
};

// Sender availability
export const FETCH_SENDER_AVAILABILITY_LOADING = 'FETCH_SENDER_AVAILABILITY_LOADING';
export const FETCH_SENDER_AVAILABILITY = 'FETCH_SENDER_AVAILABILITY';
export const RESET_SENDER_AVAILABILITY = 'RESET_SENDER_AVAILABILITY';

export const Repeats = [
  { key: EventRepeatTypes.NONE, value: false, message: commonMessages.noneOption },
  { key: EventRepeatTypes.CUSTOM, value: true, message: commonMessages.customOption },
];

export const RepeatFrequencies = new ConstOptions([
  {
    key: EventRepeatFrequency.DAILY,
    value: EventRepeatFrequency.DAILY,
    message: messages.repeatFrequencyDaily,
  },
  {
    key: EventRepeatFrequency.WEEKLY,
    value: EventRepeatFrequency.WEEKLY,
    message: messages.repeatFrequencyWeekly,
  },
  {
    key: EventRepeatFrequency.MONTHLY,
    value: EventRepeatFrequency.MONTHLY,
    message: messages.repeatFrequencyMonthly,
  },
  {
    key: EventRepeatFrequency.SEMI_ANNUALLY,
    value: EventRepeatFrequency.SEMI_ANNUALLY,
    message: messages.repeatFrequencySemiAnnually,
  },
  {
    key: EventRepeatFrequency.ANNUALLY,
    value: EventRepeatFrequency.ANNUALLY,
    message: messages.repeatFrequencyAnnually,
  },
]);

export const RepeatEnds = new ConstOptions([
  {
    key: EventRepeatEnd.FOREVER,
    value: EventRepeatEnd.FOREVER,
    message: messages.repeatEndForever,
  },
  {
    key: EventRepeatEnd.N_TIMES,
    value: EventRepeatEnd.N_TIMES,
    message: messages.repeatEndTimes,
  },
  {
    key: EventRepeatEnd.ON_DATE,
    value: EventRepeatEnd.ON_DATE,
    message: messages.repeatEndOnDate,
  },
]);

export const AttendeeTypes = new ConstOptions([
  {
    key: EventAttendeeType.MEMBER,
    value: EventAttendeeType.MEMBER,
    message: messages.attendeeTypeMember,
  },
  {
    key: EventAttendeeType.PROSPECT,
    value: EventAttendeeType.PROSPECT,
    message: messages.attendeeTypeProspect,
  },
  {
    key: EventAttendeeType.EMPLOYEE,
    value: EventAttendeeType.EMPLOYEE,
    message: messages.attendeeTypeEmployee,
  },
  {
    key: EventAttendeeType.RESOURCE_EMPLOYEE,
    value: EventAttendeeType.RESOURCE_EMPLOYEE,
    message: messages.attendeeTypeEmployee,
  },
]);

export const OverbookingTypes = new ConstOptions([
  {
    key: EventOverbookingType.DO_NOT_ALLOW_OVERBOOKING,
    value: EventOverbookingType.DO_NOT_ALLOW_OVERBOOKING,
    message: messages.overbookingTypeNotAllowed,
  },
  {
    key: EventOverbookingType.WAIT_LIST,
    value: EventOverbookingType.WAIT_LIST,
    message: messages.overbookingTypeWaitlist,
  },
  {
    key: EventOverbookingType.DUPLICATE_EVENT_FOR_EXTRA_PARTICIPANTS,
    value: EventOverbookingType.DUPLICATE_EVENT_FOR_EXTRA_PARTICIPANTS,
    message: messages.overbookingTypeDuplicateEvent,
  },
]);
