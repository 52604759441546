import React from 'react';
import { BankCode } from '../../../modules/corporate-settings/interfaces';
import CommerceHubIFrameModal from './CommerceHubIFrameModal';
import ITransactIFrame from './ITransactIFrame';

interface ITokenizeCreditCardProps {
  memberId: number;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data) => void;
  paymentProcessorType?: BankCode;
  corporationId: string;
  isMemberPortal?: boolean;
}

const TokenizeCreditCard = ({
  memberId,
  isOpen,
  onClose,
  onSubmit,
  paymentProcessorType,
  corporationId,
  isMemberPortal = false,
}: ITokenizeCreditCardProps): JSX.Element => {
  const getIFrameComponent = (): JSX.Element => {
    switch (paymentProcessorType) {
      case BankCode.COMMERCEHUB:
        // commercehub iFrame
        return (
          <CommerceHubIFrameModal
            memberId={memberId}
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onSubmit}
            isMemberPortal={isMemberPortal}
          />
        );
      case BankCode.ITRANSACT:
        // ITransact iFrame
        return (
          <ITransactIFrame
            memberId={memberId}
            isOpen={isOpen}
            onClose={onClose}
            corpId={corporationId}
          />
        );
      default:
        return <></>;
    }
  };
  return getIFrameComponent();
};

export default TokenizeCreditCard;
