import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosRequestConfig } from 'axios';

type BaseQueryArgs = {
  url: string;
  method?: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
};

const axiosBaseQuery = (baseUrl: string = ''): BaseQueryFn<BaseQueryArgs> => {
  return async ({ url, method, data, params, headers }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });

      return { data: result };
    } catch (error) {
      return { error };
    }
  };
};

const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(process.env.REACT_APP_API_URL),
  endpoints: () => ({}),
});

export default baseApi;
