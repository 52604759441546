import React, { Dispatch, SetStateAction, useState } from 'react';
import { Typography, Box, Collapse, makeStyles, Theme, Button } from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowDown } from '@material-ui/icons';
import cx from 'classnames';

import { ReactComponent as PrinterIcon } from 'img/icons/printer_deprecated.svg';
import { WaiverStatus, WaiverStatusLabels } from 'modules/crm/constants/leads';
import SignDocumentStep from 'common/components/Steps/SignDocumentStep/SignDocumentStep';
import { IContactInfo, IPersonDocumentItemDto } from 'common/interfaces/contract';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(1),
    borderRadius: '4px',
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer',

    '&:last-child': {
      marginBottom: 0,
    },
  },
  header: {
    padding: theme.spacing(1.25, 2, 1.25, 1.5),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  subHeader: {
    display: 'flex',
    gap: '16px',
    '& > p': {
      marginTop: '4px',
    },
    '& > button': {
      width: '24px',
      height: '24px',
      padding: '4px',
      marginTop: '-2px',
      '& > span > span': {
        margin: '0 !important',
      },
      '& svg': {
        width: '20px',
        height: '20px',
        fontSize: '20px !important',
      },
    },
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  headerTitle: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  waiverTitle: {
    marginRight: theme.spacing(1),
  },
  waiverNumber: {
    opacity: '0.6',
    fontWeight: 500,
  },
  waiverStatus: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    opacity: '0.6',
    textTransform: 'uppercase',
  },
  waiverStatusSigned: {
    color: theme.palette.primary.main,
  },
  waiverStatusWaiting: {
    color: theme.palette.warning.main,
  },
  content: {
    padding: '0 16px 16px 44px',
    cursor: 'initial',
  },
  footer: {
    paddingTop: theme.spacing(2),
    flexWrap: 'nowrap',
  },
  footerMenuPaper: {
    marginTop: theme.spacing(0.7),
  },
}));

interface IWaiverItemProps {
  fetchApplySignature: (file: File, documentId: string) => void;
  onPrintWaiver: (waiverId: string) => void;
  waiver: IPersonDocumentItemDto;
  contactInfo: Partial<IContactInfo>;
  setIsSignedWaiverData: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  isSignedWaiver: boolean;
}

const WaiverItem = ({
  waiver,
  contactInfo,
  setIsSignedWaiverData,
  isSignedWaiver,
  fetchApplySignature,
  onPrintWaiver,
}: IWaiverItemProps): JSX.Element => {
  const classes = useStyles();
  const [showWaiverBody, setShowWaiverBody] = useState(false);
  const onToggleContent = () => {
    setShowWaiverBody(!showWaiverBody);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header} onClick={onToggleContent}>
        <Box className={classes.headerLeft}>
          {showWaiverBody ? <KeyboardArrowDown /> : <KeyboardArrowRight />}

          <Box className={classes.headerTitle}>
            <Typography className={classes.waiverTitle} variant="h5">
              {waiver.title}
            </Typography>

            <Typography className={classes.waiverNumber}>{`#${waiver.code}`}</Typography>
          </Box>
        </Box>

        <Box className={classes.subHeader}>
          {waiver.status !== WaiverStatus.NO_SIGN && (
            <Typography
              className={cx(classes.waiverStatus, {
                [classes.waiverStatusSigned]: isSignedWaiver,
                [classes.waiverStatusWaiting]: !isSignedWaiver,
              })}
            >
              {WaiverStatusLabels[isSignedWaiver ? WaiverStatus.SIGNED : WaiverStatus.UNSIGNED]}
            </Typography>
          )}

          <Button
            onClick={e => {
              e.stopPropagation();
              onPrintWaiver(waiver.documentId);
            }}
            type="button"
            variant="text"
            size="small"
            startIcon={<PrinterIcon />}
            color="primary"
          />
        </Box>
      </Box>

      <Collapse in={showWaiverBody}>
        <Box className={classes.content}>
          <SignDocumentStep
            additionalFieldName={waiver.documentId}
            fetchApplySignature={fetchApplySignature}
            contract={waiver}
            contactInfo={contactInfo}
            hasHiddenFooter
            hasHiddenHeader
            setIsSignedDocumentData={setIsSignedWaiverData}
            isSignedDocument={isSignedWaiver}
            canvasWidthOffset={15}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default React.memo(WaiverItem);
