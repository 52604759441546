import { createAction } from '@reduxjs/toolkit';
import Service from 'services/network';
import { GeneralThunkAction } from 'common/state/interfaces';
import {
  RESET_UPLOAD_FILE,
  SET_UPLOAD_DIALOG_ID,
  UPLOAD_FILE,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_LOADING,
} from 'common/state/uploadFile/constants';
import { IUploadFilePayload, IUploadFileResult } from 'common/interfaces/uploadFile';

const uploadAction = createAction<IUploadFilePayload>(UPLOAD_FILE);
const uploadLoadingAction = createAction<boolean>(UPLOAD_FILE_LOADING);
const setUploadDialogId = createAction<string>(SET_UPLOAD_DIALOG_ID);
const uploadErrorAction = createAction<any>(UPLOAD_FILE_ERROR);
const resetUploadAction = createAction(RESET_UPLOAD_FILE);

// Action creators
export const uploadFile = (dialogId: string, file: File): GeneralThunkAction => {
  return async dispatch => {
    try {
      dispatch(uploadLoadingAction(true));
      const fileUploadResult: IUploadFileResult = await Service.FileUpload.uploadFile(file);
      dispatch(uploadAction({ dialogId, file: fileUploadResult }));
    } catch (error) {
      dispatch(uploadErrorAction(error));
    }
  };
};

export const resetUploadFile = (): GeneralThunkAction => {
  return dispatch => dispatch(resetUploadAction());
};

export const clearUploadFile = (dialogId: string | null = null): GeneralThunkAction => {
  return dispatch => dispatch(uploadAction({ dialogId, file: null }));
};

export const setUploadId = (dialogId: string): GeneralThunkAction => {
  return dispatch => dispatch(setUploadDialogId(dialogId));
};
