import axios from 'axios';

import ApiService from 'services/network/ApiService';

import {
  ICheckInManuallySearch,
  IGuardianDayCareService,
  IKidProfile,
  IKidZoneAutocompleteCustomer,
  IKidZoneBarcodeCheckinDto,
  IKidZoneStatus,
} from 'modules/kid-zone/interfaces';
import { ICheckInResponse } from 'modules/front-desk/interfaces';
import { IPersonSearchBarValues } from 'common/components/ManualCheckInPanel/components/PersonSearchBar/interfaces';
import { IPaginatedData } from 'common/interfaces/pagination';
import {
  ICheckInHistory,
  IModificationHistory,
  IModificationHistoryItem,
  INote,
  INoteCodeRequestPayload,
  IPrimaryMemberInfo,
} from 'common/components/PersonProfile/interfaces';
import { ITableParams } from 'common/interfaces/table';
import { IStoredFileSimpleDto } from 'common/interfaces/uploadFile';
import { IAllergy } from 'common/interfaces/additionalInfo';
import { IAllergiesFormValues } from 'modules/members/interfaces';

export default class KidZoneService extends ApiService {
  public uploadPhoto = (memberId: number, image: IStoredFileSimpleDto): Promise<void> =>
    axios.put(`/api/v1/kid-zone/${memberId}/avatar`, image);

  public deletePhoto = (memberId: number): Promise<void> => {
    return axios.delete(`/api/v1/kid-zone/${memberId}/avatar`);
  };

  public fetchKidZoneStatus = (clubId: string): Promise<IKidZoneStatus> =>
    axios.get('/api/v1/kid-zone/status', { params: { clubId } });

  public getMembersBySearch = (search: IPersonSearchBarValues): Promise<ICheckInManuallySearch[]> =>
    axios.get('/api/v1/kid-zone/search', { params: search });
  // Check in

  public getRecentCheckIns = (clubId: string): Promise<ICheckInResponse[]> =>
    axios.get('/api/v1/kid-zone/checkins', { params: { clubId } });

  public checkInKid = (
    personId: number,
    broughtCustomerId: number,
    serviceInstanceId: string,
    clubId: string,
  ): Promise<ICheckInResponse> =>
    axios.put(`/api/v1/kid-zone/${personId}/checkins/manually`, null, {
      params: { broughtCustomerId, serviceInstanceId, clubCheckedInId: clubId },
    });

  public checkInAllChildren = (
    clubId: string,
    broughtCustomerId: number,
    serviceInstanceId: string,
    memberIdList: string[],
  ): Promise<ICheckInResponse[]> =>
    axios.put('/api/v1/kid-zone/checkins/all', null, {
      params: {
        broughtCustomerId,
        serviceInstanceId,
        clubCheckedInId: clubId,
        memberIdList: memberIdList.join(','),
      },
    });

  public checkOutKid = (personId: number, pickedUpMemberId: number): Promise<ICheckInResponse> =>
    axios.put(`/api/v1/kid-zone/${personId}/checkout`, null, {
      params: { pickedUpMemberId },
    });

  public getKidProfile = (personId: number): Promise<IKidProfile> =>
    axios.get(`/api/v1/kid-zone/${personId}/view`);

  public getKidProfileEdit = (personId: number): Promise<IKidProfile> =>
    axios.get(`/api/v1/kid-zone/${personId}`);

  public updateKidProfile = (
    personId: number,
    data: IPrimaryMemberInfo,
  ): Promise<IPrimaryMemberInfo> => axios.put(`/api/v1/kid-zone/${personId}`, data);

  // Notes

  public getAllNotes = (personId: number): Promise<IPaginatedData<INote>> =>
    axios.get(`/api/v1/kid-zone/${personId}/notes`);

  public createNote = (requestData: INoteCodeRequestPayload): Promise<INote> => {
    const { customerId, ...data } = requestData;

    return axios.post(`/api/v1/kid-zone/${customerId}/notes`, data);
  };

  public updateNote = (requestData: INoteCodeRequestPayload): Promise<INote> => {
    const { customerId, id, ...data } = requestData;

    return axios.put(`/api/v1/kid-zone/${customerId}/notes/${id}`, data);
  };

  public deleteNote = (personId: number, noteId: string): Promise<INote> =>
    axios.delete(`/api/v1/kid-zone/${personId}/notes/${noteId}`);

  // Allergies

  public getAllergies = (personId: number): Promise<IAllergy[]> => {
    return axios.get(`/api/v1/kid-zone/${personId}/info/allergy`);
  };

  public updateAllergies = (
    personId: number,
    requestData: IAllergiesFormValues,
  ): Promise<IAllergy> => {
    return axios.put(`/api/v1/kid-zone/${personId}/info/allergy`, requestData);
  };

  // Modification history

  public getModificationHistory = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<IModificationHistory>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/kid-zone/${personId}/modification-history`, { params: searchParams });
  };

  public getRecentModificationHistory = (personId: number): Promise<IModificationHistory[]> => {
    return axios.get(`/api/v1/kid-zone/${personId}/modification-history/recent`);
  };

  public getModificationHistoryItem = (
    personId: number,
    revisionId: number,
  ): Promise<IModificationHistoryItem> =>
    axios.get(`/api/v1/kid-zone/${personId}/modification-history/${revisionId}`);

  // CheckIns history

  public getRecentCheckInsHistory = (personId: number): Promise<Array<ICheckInHistory>> =>
    axios.get(`/api/v1/kid-zone/${personId}/checkins/history/recent`);

  public getKidCheckInsHistory = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<ICheckInHistory>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/kid-zone/${personId}/checkins/history`, { params: searchParams });
  };

  public getCheckInsHistory = (
    tableParams?: ITableParams,
    clubId?: string,
  ): Promise<IPaginatedData<ICheckInHistory>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    if (!searchParams.clubIds) {
      searchParams.clubIds = clubId;
    }

    return axios.get('/api/v1/kid-zone/checkin-history', { params: searchParams });
  };

  public getGuardianDaycareServices = (
    personId: number,
    clubId: string,
  ): Promise<Array<IGuardianDayCareService>> =>
    axios.get(`/api/v1/kid-zone/${personId}/services`, { params: { clubId } });

  public addDateOfBirth = (personId: number, date: string): Promise<IKidZoneAutocompleteCustomer> =>
    axios.put(`/api/v1/kid-zone/${personId}/birthday`, { date });

  public getMembersByBarcode = (barcode: string): Promise<IKidZoneBarcodeCheckinDto> => {
    return axios.get('/api/v1/kid-zone/barcode-check-in/members', { params: { barcode } });
  };
}
