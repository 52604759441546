import { createAction } from '@reduxjs/toolkit';
import { actionTypes } from './constants';
import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { ITableParams } from 'common/interfaces/table';
import { GeneralThunkAction } from 'common/state/interfaces';
import {
  IBilling,
  ICommerceHubCredentials,
  IPackageInstanceSubscription,
  IPackageInstanceSubscriptionUpdateDTO,
  IPastDue,
  IPaymentAccount,
} from 'common/components/PersonProfile/interfaces';
import { IPaginatedData } from 'common/interfaces/pagination';
import { PaymentAccountType } from 'common/interfaces/invoices';
import { batch } from 'react-redux';
import { IPaymentMethodItem } from 'modules/pos-settings/interfaces/paymentMethods';
import { ActionResult } from 'common/constants';
import { IAddCreditCard } from 'common/components/CreditCardData/interfaces';
import { AlertCondition } from 'common/interfaces/alerts';
import { IActionResultData } from 'common/constants/globalConstants';

const fetchPastDueBillingsAction = createAction<IPaginatedData<IPastDue>>(
  actionTypes.FETCH_PERSON_PAST_DUE_BILLINGS,
);
const fetchPastDueBillingsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PERSON_PAST_DUE_BILLINGS_LOADING,
);
export const fetchPastDues = (requestOptions: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPastDueBillingsLoadingAction(true));

    try {
      const pastDueBillings = await Services.MemberPortalProfile.getPersonPastDues(requestOptions);

      dispatch(fetchPastDueBillingsAction(pastDueBillings));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPastDueBillingsLoadingAction(false));
    }
  };
};

const fetchPersonUpcomingBillingsAction = createAction<IBilling>(
  actionTypes.FETCH_PERSON_UPCOMING_BILLINGS,
);
const fetchUpcomingBillingsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PERSON_UPCOMING_BILLINGS_LOADING,
);
export const resetUpcomingBillings = createAction(actionTypes.RESET_PERSON_UPCOMING_BILLINGS);
export const fetchUpcomingBillings = (requestOptions: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchUpcomingBillingsLoadingAction(true));

    try {
      const packageUpcomingBillings = await Services.MemberPortalProfile.getBillingSchedule(
        requestOptions,
      );

      dispatch(fetchPersonUpcomingBillingsAction(packageUpcomingBillings));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchUpcomingBillingsLoadingAction(false));
    }
  };
};

const fetchCheckingSavingsData = createAction<IPaymentAccount[]>(
  actionTypes.FETCH_CHECKING_SAVINGS_DATA,
);

const fetchPaymentMethodsModalDataLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PAYMENT_METHODS_MODAL_DATA_LOADING,
);
const fetchStoredCreditCardsResultAction = createAction<IPaymentAccount[]>(
  actionTypes.FETCH_STORED_CREDIT_CARDS,
);
const fetchPersonSubscriptionsAction = createAction<IPackageInstanceSubscription[]>(
  actionTypes.FETCH_PERSON_SUBSCRIPTIONS,
);
export const resetPersonSubscriptionsAction = createAction<any>(
  actionTypes.RESET_PERSON_SUBSCRIPTIONS,
);
const fetchPersonPaymentMethodsAction = createAction<IPaymentMethodItem[]>(
  actionTypes.FETCH_PERSON_PAYMENT_METHODS,
);

export const fetchPaymentMethodsModalData = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPaymentMethodsModalDataLoadingAction(true));

    try {
      const [storedCreditCards, memberSubscriptions, paymentMethods] = await Promise.all([
        Services.MemberPortalProfile.fetchPaymentAccounts(),
        Services.MemberPortalProfile.fetchPersonSubscriptions(),
        Services.MemberPortalProfile.fetchPersonPaymentMethods(),
      ]);

      const creditCards = storedCreditCards.filter(
        el => el.paymentAccountType === PaymentAccountType.CREDIT_CARD,
      );
      const checkings = storedCreditCards.filter(
        el => el.paymentAccountType === PaymentAccountType.ECHECK,
      );

      batch(() => {
        dispatch(fetchCheckingSavingsData(checkings));
        dispatch(fetchStoredCreditCardsResultAction(creditCards));
        dispatch(fetchPersonSubscriptionsAction(memberSubscriptions));
        dispatch(fetchPersonPaymentMethodsAction(paymentMethods.data));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPaymentMethodsModalDataLoadingAction(false));
    }
  };
};

export const fetchPersonStoredCreditCards = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPaymentMethodsModalDataLoadingAction(true));

    try {
      const storedCreditCards = await Services.MemberPortalProfile.fetchPaymentAccounts(
        PaymentAccountType.CREDIT_CARD,
      );

      dispatch(fetchStoredCreditCardsResultAction(storedCreditCards));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPaymentMethodsModalDataLoadingAction(false));
    }
  };
};

export const resetCheckingSavingsData = createAction<any>(actionTypes.RESET_CHECKING_SAVINGS_DATA);

export const storeCreditCardResultAction = createAction<ActionResult | null>(
  actionTypes.ADD_CREDIT_CARD_RESULT,
);
const storeCreditCardLoadingAction = createAction<boolean>(
  actionTypes.ADD_CREDIT_CARD_RESULT_LOADING,
);

export const storeCreditCard = (data: IAddCreditCard): GeneralThunkAction => async dispatch => {
  try {
    dispatch(storeCreditCardLoadingAction(true));
    dispatch(storeCreditCardResultAction(null));
    await Services.MemberPortalProfile.addCreditCardPaymentAccount(data);
    dispatch(storeCreditCardResultAction(ActionResult.SUCCESS_ACTION));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(storeCreditCardLoadingAction(false));
  }
};

export const deletePaymentMethodCardResultAction = createAction<IActionResultData>(
  actionTypes.DELETE_CREDIT_CARD_RESULT,
);
export const deletePaymentMethodCardResultLoadingAction = createAction<boolean>(
  actionTypes.DELETE_CREDIT_CARD_RESULT_LOADING,
);
export const resetDeletePaymentMethodCardResultAction = createAction<any>(
  actionTypes.RESET_DELETE_CREDIT_CARD_RESULT,
);
export const deletePaymentMethodCard = (
  storedCardId: string,
): GeneralThunkAction => async dispatch => {
  dispatch(deletePaymentMethodCardResultLoadingAction(true));

  try {
    await Services.MemberPortalProfile.deletePaymentAccount(storedCardId);

    dispatch(
      deletePaymentMethodCardResultAction({
        result: ActionResult.SUCCESS_ACTION,
        data: storedCardId,
      }),
    );
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(deletePaymentMethodCardResultLoadingAction(false));
  }
};

const addCheckingSavingsData = createAction<IPaymentAccount>(actionTypes.ADD_CHECKING_SAVINGS_DATA);
const addCheckingSavingsDataLoading = createAction<boolean>(
  actionTypes.ADD_CHECKING_SAVINGS_DATA_LOADING,
);
export const addCheckingSavingsDataActionResult = createAction<ActionResult | null>(
  actionTypes.ADD_CHECKING_SAVINGS_DATA_ACTION_RESULT,
);

export const addCheckingSavingsDataThunk = (
  formData: Omit<IPaymentAccount, 'id'>,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(addCheckingSavingsDataLoading(true));

    const response: IPaymentAccount = await Services.MemberPortalProfile.addCheckingPaymentAccount(
      formData,
    );

    dispatch(addCheckingSavingsData(response));
    dispatch(addCheckingSavingsDataActionResult(ActionResult.SUCCESS_ACTION));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(addCheckingSavingsDataLoading(false));
  }
};

const deleteCheckingSavingsItem = createAction<string>(actionTypes.DELETE_CHECKING_SAVINGS_ITEM);
const deleteCheckingSavingsItemLoading = createAction<boolean>(
  actionTypes.DELETE_CHECKING_SAVINGS_ITEM_LOADING,
);
export const deleteCheckingSavingsItemActionResult = createAction<ActionResult | null>(
  actionTypes.DELETE_CHECKING_SAVINGS_ITEM_ACTION_RESULT,
);

export const deleteCheckingSavingsItemThunk = (
  checkingId: string,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(deleteCheckingSavingsItemLoading(true));

    await Services.MemberPortalProfile.deletePaymentAccount(checkingId);

    dispatch(deleteCheckingSavingsItem(checkingId));
    dispatch(deleteCheckingSavingsItemActionResult(ActionResult.SUCCESS_ACTION));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(deleteCheckingSavingsItemLoading(false));
  }
};

const updatePersonSubscriptionsAction = createAction<ActionResult>(
  actionTypes.UPDATE_PERSON_SUBSCRIPTIONS,
);
const updatePersonSubscriptionsLoadingAction = createAction<boolean>(
  actionTypes.UPDATE_PERSON_SUBSCRIPTIONS_LOADING,
);
export const resetUpdatePersonSubscriptionsActionResult = createAction<any>(
  actionTypes.RESET_UPDATE_PERSON_SUBSCRIPTIONS_ACTION_RESULT,
);

export const updatePersonSubscriptions = (
  subscriptions: IPackageInstanceSubscriptionUpdateDTO[],
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonSubscriptionsLoadingAction(true));

    try {
      await Services.MemberPortalProfile.updateMemberSubscriptions(subscriptions);

      dispatch(updatePersonSubscriptionsAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePersonSubscriptionsLoadingAction(false));
    }
  };
};

export const resolvePersonMissingBillingInfoAction = createAction<AlertCondition>(
  actionTypes.RESOLVE_PERSON_MISSING_BILLING_INFO,
);

const fetchCommerceHubCredentialsAction = createAction<ICommerceHubCredentials>(
  actionTypes.FETCH_COMMERCEHUB_CREDENTIALS,
);
const fetchCommerceHubCredentialsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_COMMERCEHUB_CREDENTIALS_LOADING,
);
export const fetchCommerceHubCredentials = (): GeneralThunkAction => async dispatch => {
  try {
    dispatch(fetchCommerceHubCredentialsLoadingAction(true));

    const credentials: ICommerceHubCredentials = await Services.MemberPortalProfile.fetchCommerceHubCredentials();

    dispatch(fetchCommerceHubCredentialsAction(credentials));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(fetchCommerceHubCredentialsLoadingAction(false));
  }
};

export const tokenizeCommerceHubEncryptedCard = (
  sessionId: string,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(storeCreditCardLoadingAction(true));
    dispatch(storeCreditCardResultAction(null));

    await Services.MemberPortalProfile.tokenizeCommerceHubEncryptedCard({ sessionId });

    dispatch(storeCreditCardResultAction(ActionResult.SUCCESS_ACTION));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(storeCreditCardLoadingAction(false));
  }
};
