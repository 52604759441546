import React, { FC, useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import useRootSelector from 'common/hooks/useRootSelector';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTheme } from 'common/ui/interfaces';
import { useAppDispatch } from 'store/hooks';
import TokenService from 'services/auth/TokenService';
import * as actions from 'memberPortalModules/authentication/state/actions';
import { setUserRole } from 'common/state/userRole/actions';
import { HOME_PATH } from 'common/constants/globalConstants';
import MainImg from 'img/icons/main.png';
import { LoginSelection } from 'modules/authentication/components';
import { LoginPage as ClientPortalLoginPage } from 'modules/authentication/containers';
import { LoginPage as MemberPortalLoginPage } from 'memberPortalModules/authentication/containers';
import {
  selectIsUserLoggedIn,
  selectSelectedOrganizationColor,
} from 'modules/authentication/state/selectors';
import { selectIsMemberPortalUserLoggedIn } from 'memberPortalModules/authentication/state/selectors';
import { UserRole } from 'common/interfaces/common';

const useStyles = makeStyles((theme: CustomTheme) => {
  return {
    container: {
      backgroundColor: theme.palette.background.default,
    },
  };
});

const Main: FC = () => {
  const [loginView, setLoginView] = useState<UserRole | null>(null);
  const loginViewRef = useRef<UserRole | null>(null);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isClientPortalUserLogged: boolean = useRootSelector(selectIsUserLoggedIn);
  const isMemberPortalUserLogged: boolean = useRootSelector(selectIsMemberPortalUserLoggedIn);
  const organizationPrimaryColor = useRootSelector(selectSelectedOrganizationColor);

  const isUserLogged = isClientPortalUserLogged || isMemberPortalUserLogged;

  useEffect(() => {
    window.addEventListener('popstate', goBack);
    return () => window.removeEventListener('popstate', goBack);
  }, []);

  useEffect(() => {
    loginViewRef.current = loginView;
  }, [loginView]);

  useEffect(() => {
    if (isUserLogged && TokenService.getAccessToken()) {
      if (organizationPrimaryColor) {
        dispatch(actions.resetOrganizationPrimaryColorAction());
      }

      let userData;
      try {
        const tokenData = TokenService.getTokenData(TokenService.getAccessToken());
        userData = JSON.parse(tokenData?.userData);
      } catch (error) {
        userData = null;
      }

      const isMemberPortal = Boolean(userData?.memberPortal);

      dispatch(setUserRole(isMemberPortal ? 'member' : 'client'));

      navigate(HOME_PATH);
    }
  }, [dispatch, isUserLogged, navigate, organizationPrimaryColor]);

  const goBack = e => {
    e.preventDefault();
    if (loginViewRef) {
      setLoginView(null);
    }
  };

  switch (loginView) {
    case 'client':
      return <ClientPortalLoginPage setLoginView={setLoginView} />;
    case 'member':
      return <MemberPortalLoginPage setLoginView={setLoginView} />;
    default:
      return (
        <Box className={classes.container} display="flex" height="100%">
          <Box display="flex" alignItems="center" height="100%" width="50%" ml={8}>
            <LoginSelection onSelect={setLoginView} />
          </Box>
          <Box
            height="100%"
            width="50%"
            style={{
              backgroundImage: `url(${MainImg})`,
              backgroundSize: 'cover',
            }}
          />
        </Box>
      );
  }
};

export default Main;
