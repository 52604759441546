import React, { useMemo } from 'react';
import { List } from 'immutable';
import { makeStyles } from '@material-ui/core/styles';
// custom interfaces and components
import { Box, SvgIcon, Tooltip, Typography } from '@material-ui/core';
import { Button, LoadingBackdrop } from 'common/components/index';
import { CustomTheme } from 'common/ui/interfaces';
import { PaymentsType } from 'common/interfaces/invoices';

import { PaymentsTypeLabels } from 'modules/pos-settings/constants/paymentMethods';
import { PMethodSteps, getPayTypeIcon } from 'common/components/InvoiceOperating/constants';
import { IPaymentMethodsPosSettingsImt } from 'modules/pos-settings/interfaces/paymentMethods';
import { formatPrice } from 'common/utils';
import { snackbar } from 'common/utils/snackbarUtils';
import { FormattedMessage } from 'react-intl';
import commonMessages from 'common/messages/messages';
import messages from 'common/components/InvoiceOperating/messages';

interface ISelectPaymentTypeStepProps {
  paymentMethodsList: List<IPaymentMethodsPosSettingsImt>;
  isLoading: boolean;
  balance: number;
  memberId: number;
  onSelectStep: (step: PMethodSteps, type: PaymentsType) => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  paymentButton: {
    textTransform: 'capitalize',
    justifyContent: 'start',
    fontWeight: theme.typography.fontWeightMedium as number,
  },
  paymentIcon: {
    marginRight: theme.spacing(0.75),
  },
  loader: {
    backgroundColor: 'inherit !important',
  },
  loaderWrapper: {
    minHeight: '36px',
    width: '100%',
    position: 'relative',
  },
}));

const SelectPaymentTypeStep: React.FC<ISelectPaymentTypeStepProps> = (
  props: ISelectPaymentTypeStepProps,
): JSX.Element => {
  const classes = useStyles(props);
  const { paymentMethodsList, isLoading, onSelectStep, balance, memberId } = props;

  const paymentMethods = useMemo(() => {
    const result = paymentMethodsList.toJS();
    if (!result) return [];

    // result.push({ type: PaymentsType.CUSTOM, id: PaymentsType.CUSTOM });

    const sortedTypes = Object.values(PaymentsType);
    result.sort((opt1, opt2) => {
      const opt1Index = sortedTypes.indexOf(opt1.type);
      const opt2Index = sortedTypes.indexOf(opt2.type);
      return opt1Index - opt2Index;
    });

    return result;
  }, [paymentMethodsList]);

  const handleSelectPaymentMethodType = (type: PaymentsType): void => {
    switch (type) {
      case PaymentsType.CREDIT_CARD:
        onSelectStep(PMethodSteps.CreditCardPayment, PaymentsType.CREDIT_CARD);
        break;
      // case PaymentsType.CUSTOM:
      //   onSelectStep(PMethodSteps.OtherTypePayment, PaymentsType.CUSTOM);
      //   break;
      case PaymentsType.CHECKING_SAVINGS:
        onSelectStep(PMethodSteps.CheckingSavings, PaymentsType.CHECKING_SAVINGS);
        break;
      case PaymentsType.ON_ACCOUNT:
        onSelectStep(PMethodSteps.OnAccount, PaymentsType.ON_ACCOUNT);
        break;

      case PaymentsType.TAB_ON_A_CORPORATE_ACCOUNT:
      default:
        snackbar.warning(<FormattedMessage {...commonMessages.notImplementedYet} />);
    }
  };

  const paymentMethodButtonLabel = (type: PaymentsType): React.ReactNode =>
    type === PaymentsType.ON_ACCOUNT ? (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Typography color="inherit">{PaymentsTypeLabels[type]}</Typography>
        <Typography color="inherit">{formatPrice(balance)}</Typography>
      </Box>
    ) : (
      <Typography color="inherit">{PaymentsTypeLabels[type]}</Typography>
    );

  const renderButton = paymentMethod => {
    return (
      <Button
        key={paymentMethod.id}
        fullWidth
        variant="outlined"
        color="primary"
        startIcon={
          <SvgIcon component={getPayTypeIcon(paymentMethod.type)} className={classes.paymentIcon} />
        }
        className={classes.paymentButton}
        disabled={
          !paymentMethod.active ||
          ((paymentMethod.type === PaymentsType.ON_ACCOUNT ||
            paymentMethod.type === PaymentsType.CHECKING_SAVINGS ||
            paymentMethod.type === PaymentsType.MEMBER_REWARDS) &&
            !memberId) ||
          (paymentMethod.type === PaymentsType.ON_ACCOUNT && !balance)
        }
        onClick={() => handleSelectPaymentMethodType(paymentMethod.type)}
      >
        {paymentMethodButtonLabel(paymentMethod.type)}
      </Button>
    );
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" gridGap={8} mb={2}>
      {paymentMethods.map(paymentMethod => {
        // if (paymentMethod.type === PaymentsType.CUSTOM) {
        //   return (
        //     <Tooltip title={<FormattedMessage {...messages.disabledCustomMethodTooltipTitle} />}>
        //       <span> {renderButton(paymentMethod)} </span>
        //     </Tooltip>
        //   );
        // }
        if (
          (paymentMethod.type === PaymentsType.ON_ACCOUNT ||
            paymentMethod.type === PaymentsType.CHECKING_SAVINGS) &&
          !memberId
        ) {
          return (
            <Tooltip title={<FormattedMessage {...messages.disableMethodForNotInSystem} />}>
              <span> {renderButton(paymentMethod)} </span>
            </Tooltip>
          );
        }
        if (paymentMethod.type === PaymentsType.ON_ACCOUNT && !balance) {
          return (
            <Tooltip
              title={<FormattedMessage {...messages.disableMethodForNotEnoughFundsonBalance} />}
            >
              <span> {renderButton(paymentMethod)} </span>
            </Tooltip>
          );
        }
        return renderButton(paymentMethod);
      })}
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
};

export default React.memo(SelectPaymentTypeStep);
