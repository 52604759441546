import { ImmutableObject } from 'common/state/interfaces';
import { IShortPerson } from 'common/interfaces/common';

export enum PaymentStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
}

export enum PackageType {
  BUNDLE = 'BUNDLE',
  FEE = 'FEE',
}

export enum BillingType {
  CHARGE = 'CHARGE',
  PAST_DUE = 'PAST_DUE',
}

export interface ITotalScheduledBillingsStatistics {
  amount: number;
  billingScheduleAmount: number;
  paidBillingScheduleAmount: number;
  paidPastDueAmount: number;
  pastDueAmount: number;
  unpaidBillingScheduleAmount: number;
  unpaidPastDueAmount: number;
}

export interface ITotalScheduledBillingsStatisticsImt
  extends ImmutableObject<ITotalScheduledBillingsStatistics> {
  get<K extends keyof ITotalScheduledBillingsStatistics>(
    key: K,
  ): ITotalScheduledBillingsStatistics[K];
}

export enum ScheduledBillingsActivityType {
  PAID_BILLING = 'PAID_BILLING',
  UNPAID_BILLING = 'UNPAID_BILLING',
  PAID_PAST_DUE = 'PAID_PAST_DUE',
  UNPAID_PAST_DUE = 'UNPAID_PAST_DUE',
}

export interface IScheduledBillingsActivityItem {
  amount: number;
  total: number;
  type: ScheduledBillingsActivityType;
}

export interface IScheduledBillingActivity {
  activity: { [key: string]: IScheduledBillingsActivityItem[] };
}

export type TBillingsReport = Record<ScheduledBillingsActivityType, number> & { date: string };

export interface IScheduledBillingActivityImt extends ImmutableObject<TBillingsReport> {
  get<K extends keyof TBillingsReport>(key: K): TBillingsReport[K];
}

export interface IScheduledBillingItem {
  id: string;
  amount: number;
  date: string;
  invoiceId: string;
  member: IShortPerson;
  number: number;
  packageType: PackageType;
  status: PaymentStatus;
  title: string;
  type: BillingType;
}

export interface IScheduledBillingItemImt extends ImmutableObject<IScheduledBillingItem> {
  get<K extends keyof IScheduledBillingItem>(key: K): IScheduledBillingItem[K];
}
