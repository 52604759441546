import React, { FC, useState, useCallback, useEffect, memo } from 'react';
import { ReasonType } from '../../interfaces/dialog';
import fdMessages from 'modules/front-desk/messages';
import commonMessages from '../../messages/messages';
import IntlService from 'services/intl/IntlService';
import QuestionnaireModal, { IQuestionnaireModal } from '../QuestionnaireModal/QuestionnaireModal';

export interface IDiscardChangesModal {
  targetIds: string[];
  onSubmit?: IQuestionnaireModal['onSubmit'] | null;
  onClose?: IQuestionnaireModal['onClose'] | null;
}

const DiscardChangesModal: FC<IDiscardChangesModal> = ({ targetIds, onClose, onSubmit }) => {
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const bodyTextFirstPart = IntlService.formatMessage(fdMessages.discardEnteredDataQuestion);
  const bodyTextSecondPart = IntlService.formatMessage(fdMessages.notBeAbleToSaveYourDataText);
  const submitBtnTitle = IntlService.formatMessage(commonMessages.discardBtn);

  const onModalClose = (event: React.BaseSyntheticEvent | unknown, reason: ReasonType): void => {
    if (target) {
      setTarget(null);
    }

    if (isOpen) {
      setIsOpen(false);
      window.history.pushState(null, '');
    }

    if (onClose) {
      onClose(event, reason);
    }
  };

  const onModalSubmit = (): void => {
    if (target) {
      target.click();
      setTarget(null);
    }

    if (isOpen) {
      setIsOpen(false);
      window.history.back();
    }

    if (onSubmit) {
      onSubmit();
    }
  };

  const onClick = useCallback(
    (event): void => {
      if (!target) {
        const eventTarget: HTMLElement | null = event.target;

        if (eventTarget) {
          for (const targetId of targetIds) {
            const element = document.getElementById(targetId);

            if (element && element.contains(eventTarget)) {
              event.preventDefault();
              event.stopPropagation();
              setTarget(element);
              break;
            }
          }
        }
      }
    },
    [targetIds, target],
  );

  const onPopState = useCallback((event): void => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen(true);
  }, []);

  useEffect(() => {
    document.addEventListener('click', onClick, true);

    return () => {
      document.removeEventListener('click', onClick, true);
    };
  }, [onClick]);

  useEffect(() => {
    window.addEventListener('popstate', onPopState, true);
    window.history.pushState(null, '');

    return () => {
      window.removeEventListener('popstate', onPopState, true);
    };
  }, [onPopState]);

  return (
    <QuestionnaireModal
      isOpen={!!target || isOpen}
      onClose={onModalClose}
      onSubmit={onModalSubmit}
      body={`${bodyTextFirstPart} ${bodyTextSecondPart}`}
      submitBtnTitle={submitBtnTitle}
    />
  );
};

export default memo(DiscardChangesModal);
