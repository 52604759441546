import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, makeStyles, Typography } from '@material-ui/core';

import * as actions from 'memberPortalModules/MemberProfile/state/invoice/actions';

import { CartUnitTypes, IInvoiceDetailsImt } from 'common/interfaces/invoices';

import messages from 'common/messages/messages';

// import AddCartItems from './AddCartItems';
import { CustomTheme } from 'common/ui/interfaces';
import { useSearchInvoiceProductsState } from 'common/createContext/searchInvoiceProductsContext';
import { useAppDispatch } from 'store/hooks';
import InvoiceTopUpBalanceItem from 'common/components/InvoiceOperating/InvoiceTopUpBalanceItem/InvoiceTopUpBalanceItem';
import InvoiceProductItem from 'common/components/InvoiceOperating/InvoiceProductItem/InvoiceProductItem';
import InvoiceServiceItem from 'common/components/InvoiceOperating/InvoiceServiceItem/InvoiceServiceItem';
import InvoiceBillingItem from 'common/components/InvoiceOperating/InvoiceBillingItem/InvoiceBillingItem';
import InvoiceGiftCardItem from 'common/components/InvoiceOperating/InvoiceGiftCardItem/InvoiceGiftCardItem';
import InvoicePastDueItem from 'common/components/InvoiceOperating/InvoicePastDueItem/InvoicePastDueItem';
import InvoiceCancelPackageItem from 'common/components/InvoiceOperating/InvoiceCancelPackageItem/InvoiceCancelPackageItem';

const useStyles = makeStyles((theme: CustomTheme) => ({
  addButton: {
    padding: theme.spacing(1, 1.5),

    '& svg': {
      width: '1rem',
      height: '1rem',
    },
  },
  shiftedButton: {
    marginLeft: theme.spacing(-1),
  },
  actionItem: {
    display: 'flex',
    alignItems: 'center',
  },
  actionItemIcon: {
    width: '1rem',
    height: '1rem',
    marginRight: theme.spacing(1),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
    minHeight: 0,
  },
  emptyCartText: {
    marginBottom: theme.spacing(1.5),
    fontSize: '1rem',
  },
  cartItem: {
    borderBottom: `1px solid ${theme.palette.borderColor?.light}`,
  },
  bordered: {
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0, 2),
    borderBottom: `1px solid ${theme.palette.borderColor?.light}`,
  },
}));

interface IInvoiceCreationCartProps {
  memberInvoice?: IInvoiceDetailsImt;
}

export default function InvoiceCreationCart({
  memberInvoice,
}: IInvoiceCreationCartProps): JSX.Element {
  const dispatch = useAppDispatch();

  // const [isOpenTopUpBalanceModal, setIsOpenTopUpBalanceModal] = useState(false);

  const {
    // toggleShowAddInventories,
    // toggleShowAddServicePackages,
    // toggleShowAddGiftCards,
    showSearchProducts: { isShowAddInventories, isShowAddServicePackages, isShowAddGiftCards },
  } = useSearchInvoiceProductsState();

  const classes = useStyles();

  const transformedInvoice = useMemo(() => memberInvoice?.toJS(), [memberInvoice]);

  const disabled = (transformedInvoice?.invoicePaymentSplits || []).length > 0;

  const isSearchProductsActive =
    isShowAddInventories || isShowAddServicePackages || isShowAddGiftCards;

  const isCartEmpty = !transformedInvoice?.invoiceUnits?.length;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onProductCountChange = (invoiceUnitId: string, count: number) => {
    // dispatch(
    //   actions.changeInvoiceUnitNumber(
    //     transformedInvoice.id,
    //     invoiceUnitId,
    //     count,
    //     module,
    //     isPaymentStep,
    //   ),
    // );
  };

  // const handleButtonClick = useCallback(() => {
  //   snackbar.warning(<FormattedMessage {...messages.notImplementedYet} />);
  // }, []);

  // top up balance
  // const handleTopUpBalanceSubmit = (amountToPay: number) => {
  // dispatch(
  //   actions.addInvoiceUnit(
  //     transformedInvoice.id,
  //     {
  //       topUpBalances: [{ amount: amountToPay }],
  //       memberId: transformedInvoice.customer?.id ?? null,
  //     },
  //     CartUnitTypes.TOP_UP_BALANCE,
  //     module,
  //     isPaymentStep,
  //   ),
  // );

  //   setIsOpenTopUpBalanceModal(false);
  // };

  const onInvoiceUnitDelete = (invoiceUnitId: string) => {
    dispatch(actions.deleteInvoiceUnit(transformedInvoice?.id || '', invoiceUnitId));
  };

  // const closeTopUpBalanceModal = () => {
  //   setIsOpenTopUpBalanceModal(false);
  // };

  // const handleAddServices = useCallback(() => {
  //   if (transformedInvoice?.customer) {
  //     toggleShowAddServicePackages(true);
  //   } else {
  //     // setIsSelectCustomerAlertShown(true);
  //   }
  // }, [toggleShowAddServicePackages, transformedInvoice]);

  // const handleAddTopUpBalance = useCallback(() => {
  //   if (transformedInvoice?.customer) {
  //     setIsOpenTopUpBalanceModal(true);
  //   } else {
  //     // setIsSelectCustomerAlertShown(true);
  //   }
  // }, [transformedInvoice]);

  // useEffect(() => {
  //   if (!isShowAddInventories && !isShowAddServicePackages)
  //     batch(() => {
  //       dispatch(actions.resetSearchPackagesResult());
  //       dispatch(actions.resetSearchInventoryResult());
  //       dispatch(actions.resetSearchGiftCards());
  //     });
  // }, [dispatch, isShowAddInventories, isShowAddServicePackages, isShowAddGiftCards]);

  // useEffect(() => {
  //   return () => {
  //     batch(() => {
  //       dispatch(actions.resetSearchInventoryResult());
  //       dispatch(actions.resetSearchPackagesResult());
  //       dispatch(actions.resetSearchGiftCards());
  //     });
  //   };
  // }, [dispatch]);

  // const addButtons: Array<{ message: Message; handler: () => void }> = useMemo(
  //   () => [
  //     { message: messages.items, handler: () => toggleShowAddInventories(true) },
  //     {
  //       message: messages.services,
  //       handler: handleAddServices,
  //     },
  //     { message: messages.topUp, handler: handleAddTopUpBalance },
  //     { message: messages.giftCard, handler: () => toggleShowAddGiftCards(true) },
  //     { message: messages.tips, handler: handleButtonClick },
  //   ],
  //   [
  //     handleAddServices,
  //     handleAddTopUpBalance,
  //     handleButtonClick,
  //     toggleShowAddInventories,
  //     toggleShowAddGiftCards,
  //   ],
  // );

  // const renderButtonsAdd = (isShortView?: boolean) => (
  //   <Box
  //     display="flex"
  //     flexWrap="wrap"
  //     alignItems="center"
  //     justifyContent={isShortView ? 'space-between' : 'center'}
  //     gridGap={4}
  //   >
  //     {addButtons.slice(0, isShortView ? -1 : addButtons.length).map((button, index) => (
  //       <Button
  //         startIcon={<AddIcon />}
  //         color="primary"
  //         className={cx(classes.addButton, { [classes.shiftedButton]: !index && isShortView })}
  //         onClick={button.handler}
  //         key={button.message.id}
  //       >
  //         <FormattedMessage {...button.message} />
  //       </Button>
  //     ))}

  //     {isShortView && (
  //       <ActionsMenu icon={<DotsIcon />} iconColor="primary">
  //         {addButtons.slice(-1).map(button => (
  //           <ActionItem onClick={button.handler} key={button.message.id}>
  //             <Typography color="primary" className={classes.actionItem}>
  //               <AddIcon className={classes.actionItemIcon} />

  //               <Typography component="h6" variant="button">
  //                 <FormattedMessage {...button.message} />
  //               </Typography>
  //             </Typography>
  //           </ActionItem>
  //         ))}
  //       </ActionsMenu>
  //     )}
  //   </Box>
  // );

  const hasVisibleAddButtons = !isSearchProductsActive && !isCartEmpty && !disabled;

  return (
    <Box
      flex="0 1 auto"
      height="100%"
      display={hasVisibleAddButtons ? 'flex' : 'block'}
      flexDirection={hasVisibleAddButtons && 'column'}
      // need to force render
      key={Number(hasVisibleAddButtons)}
    >
      {/* {hasVisibleAddButtons && (
        <Box flex="0 1 auto" className={classes.bordered}>
          {renderButtonsAdd(true)}
        </Box>
      )} */}

      <Box
        className={classes.body}
        width="100%"
        height="100%"
        flex={hasVisibleAddButtons && '1 1 auto'}
        justifyContent={!isSearchProductsActive && isCartEmpty ? 'center' : 'flex-start'}
        pt={2}
      >
        {!isSearchProductsActive &&
          (!isCartEmpty ? (
            <Box mr={-2} pr={2} height="100%" minHeight="300px">
              {transformedInvoice.invoiceUnits.map(invoiceUnit => {
                const { id, type } = invoiceUnit;

                switch (type) {
                  case CartUnitTypes.TOP_UP_BALANCE: {
                    const { topUpBalance } = invoiceUnit;

                    return (
                      <Box key={id} className={classes.cartItem}>
                        <InvoiceTopUpBalanceItem
                          disabled={disabled}
                          topUpBalance={topUpBalance}
                          onDelete={() => onInvoiceUnitDelete(id)}
                        />
                      </Box>
                    );
                  }
                  case CartUnitTypes.INVENTORY: {
                    const { inventory } = invoiceUnit;

                    return (
                      <Box key={id} className={classes.cartItem}>
                        <InvoiceProductItem
                          onCountChange={count => onProductCountChange(id, count)}
                          onDelete={() => onInvoiceUnitDelete(id)}
                          product={inventory}
                          disabled={disabled}
                          selectedInvoiceStatus={transformedInvoice?.status}
                        />
                      </Box>
                    );
                  }
                  case CartUnitTypes.MEMBERSHIP_BUNDLE:
                  case CartUnitTypes.SERVICE_BUNDLE: {
                    const { bundle } = invoiceUnit;

                    return (
                      <InvoiceServiceItem
                        key={id}
                        invoicePackage={bundle}
                        onDelete={() => onInvoiceUnitDelete(id)}
                        disabled={disabled}
                      />
                    );
                  }
                  case CartUnitTypes.BILLING_SCHEDULE: {
                    const { billingSchedule } = invoiceUnit;

                    return (
                      <Box key={id}>
                        <InvoiceBillingItem
                          billing={billingSchedule}
                          disabled={disabled}
                          onDelete={() => onInvoiceUnitDelete(id)}
                        />
                      </Box>
                    );
                  }
                  case CartUnitTypes.PAST_DUE: {
                    const { pastDue, pastDueResolve } = invoiceUnit;

                    return (
                      <Box key={id}>
                        <InvoicePastDueItem
                          pastDue={pastDue}
                          disabled={disabled}
                          pastDueResolve={pastDueResolve}
                          onDelete={() => onInvoiceUnitDelete(id)}
                        />
                      </Box>
                    );
                  }
                  case CartUnitTypes.GIFT_CARD: {
                    const { giftCard } = invoiceUnit;

                    return (
                      <Box key={id}>
                        <InvoiceGiftCardItem
                          disabled={disabled}
                          giftCard={giftCard}
                          onDelete={() => onInvoiceUnitDelete(id)}
                        />
                      </Box>
                    );
                  }
                  case CartUnitTypes.REACTIVATE_MEMBERSHIP:
                  case CartUnitTypes.REACTIVATE_NON_MEMBERSHIP: {
                    const { bundle, reactivatePastDues } = invoiceUnit;

                    return (
                      <InvoiceServiceItem
                        key={id}
                        invoicePackage={bundle}
                        pastDues={reactivatePastDues}
                      />
                    );
                  }
                  case CartUnitTypes.CANCEL_MEMBERSHIP:
                  case CartUnitTypes.CANCEL_NON_MEMBERSHIP: {
                    const { bundle, cancelPastDues, cancelBillingSchedules } = invoiceUnit;

                    return (
                      <InvoiceCancelPackageItem
                        isMembership={CartUnitTypes.CANCEL_MEMBERSHIP === type}
                        key={id}
                        invoicePackage={bundle}
                        pastDues={cancelPastDues}
                        billingSchedules={cancelBillingSchedules}
                      />
                    );
                  }
                  default:
                    return null;
                }
              })}
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              pt={2}
              pb={2}
              alignSelf="center"
            >
              <Typography color="textSecondary" className={classes.emptyCartText}>
                <FormattedMessage {...messages.emptyCart} />
              </Typography>

              {/* {renderButtonsAdd()} */}
            </Box>
          ))}

        {/* <AddCartItems module={module} isPaymentStep={isPaymentStep} /> */}

        {/* {isOpenTopUpBalanceModal && (
          <TopUpBalanceModal
            onClose={closeTopUpBalanceModal}
            handleSubmit={handleTopUpBalanceSubmit}
            isOpen={isOpenTopUpBalanceModal}
          />
        )} */}
      </Box>
    </Box>
  );
}
