import React, { useCallback, useEffect, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { List as ImmutableList } from 'immutable';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as EditIcon } from 'img/icons/edit.svg';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
import { ReactComponent as XDeleteIcon } from 'img/icons/times.svg';

import * as selectors from '../../state/policies/selectors';

import { IPageMetaImt } from 'common/interfaces/pagination';
import { IActionRequestStatusImt } from 'common/state/interfaces';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { IFilterSettings } from 'common/interfaces/filter';
import { IPolicyFormData, IPolicyListItem, IPolicyListItemImt } from '../../interfaces/permissions';

import { TableOrderByParams } from 'common/constants/table';

import { ActionItem, ActionsMenu, ChangeStatusModal, Table } from 'common/components';
import { EditPolicyModal } from '..';
import ViewPolicyModal from '../ViewPolicyModal/ViewPolicyModal';

import { filterPermissionSet } from 'modules/permissions/utils/permission';

import tableHeaders from 'common/messages/tableHeaders';
import commonMessages from 'common/messages/messages';
import menuItems from 'common/messages/menuItems';
import { getPolicyLevel, PermissionsGroupsLabels } from 'modules/permissions/constants';
import { PermissionsLevelsLabels } from 'common/constants/permissions';

interface IPoliciesTableProps {
  isPoliciesListLoading: boolean;
  policiesList: ImmutableList<IPolicyListItemImt>;
  policiesListMeta: IPageMetaImt;
  tableParams: ITableParams;
  tableFilterSettings: IFilterSettings[];
  handleChangeTableProps: (tableProps: ITableParams) => void;
  editItem: (id: string, data: IPolicyFormData) => void;
  changeStatusItem: (id: string, active: boolean) => void;
  createItem: (data: IPolicyFormData) => void;
}

const headerOptions: IHeadCell[] = [
  {
    id: TableOrderByParams.TITLE,
    label: <FormattedMessage {...tableHeaders.name} />,
    sort: true,
    align: 'left',
    padding: 'none',
  },
  {
    id: 'LEVEL',
    label: <FormattedMessage {...tableHeaders.level} />,
    sort: false,
  },
  {
    id: TableOrderByParams.TYPE,
    label: <FormattedMessage {...tableHeaders.type} />,
    sort: true,
  },
  {
    id: TableOrderByParams.STATUS,
    label: <FormattedMessage {...tableHeaders.status} />,
    sort: true,
  },
  {
    id: 'actions',
    label: '',
    sort: false,
    padding: 'none',
    align: 'center',
  },
];

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
});

const PoliciesTable = (props: IPoliciesTableProps): JSX.Element => {
  const {
    isPoliciesListLoading,
    policiesList,
    policiesListMeta,
    tableParams,
    tableFilterSettings,
    handleChangeTableProps,
    editItem,
    changeStatusItem,
    createItem,
  } = props;
  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState<boolean>(false);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [showViewDialog, setShowViewDialog] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IPolicyListItem | null>(null);

  const policyItemLoading: boolean = useRootSelector(selectors.selectPolicyItemLoading);
  const policyItemActionRequestState: IActionRequestStatusImt = useRootSelector(
    selectors.selectPolicyItemRequestState,
  );

  const classes = useStyles();

  const handleEditDialogShow = useCallback((policyItem: IPolicyListItem) => {
    setSelectedRow(policyItem);
    setShowEditDialog(true);
  }, []);

  const handleEditDialogClose = useCallback(() => {
    setSelectedRow(null);
    setShowEditDialog(false);
  }, [setShowEditDialog]);

  const handleViewDialogShow = useCallback((policyItem: IPolicyListItem) => {
    setSelectedRow(policyItem);
    setShowViewDialog(true);
  }, []);

  const handleViewDialogClose = useCallback(() => {
    setSelectedRow(null);
    setShowViewDialog(false);
  }, [setShowViewDialog]);

  const handleChangeStatusDialogShow = useCallback(
    (policyItem: IPolicyListItem) => {
      setSelectedRow(policyItem);
      setShowChangeStatusDialog(true);
    },
    [setShowChangeStatusDialog],
  );

  const handleChangeStatusDialogClose = useCallback(() => {
    setSelectedRow(null);
    setShowChangeStatusDialog(false);
  }, [setShowChangeStatusDialog]);

  const handleChangeStatusPolicyItem = useCallback(() => {
    if (selectedRow) {
      changeStatusItem(selectedRow.id, !selectedRow.active);
    }
  }, [changeStatusItem, selectedRow]);

  useEffect(() => {
    if (policyItemActionRequestState.get('result')) {
      handleChangeStatusDialogClose();
    }
  }, [policyItemActionRequestState, handleChangeStatusDialogClose]);

  const createRows = (): ITableRow[] => {
    return policiesList
      .map(
        (policyValue: IPolicyListItemImt): ITableRow => {
          const itemId = policyValue.get('id');
          return {
            id: itemId,
            cells: [
              {
                label: policyValue.get('title'),
                width: '25%',
                cellComponent: (
                  <Typography
                    variant="h5"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleViewDialogShow(policyValue.toJS())}
                  >
                    {policyValue.get('title')}
                  </Typography>
                ),
              },
              {
                label: 'level',
                width: '25%',
                cellComponent: (
                  <Typography>
                    {PermissionsLevelsLabels[getPolicyLevel(policyValue.get('permissionGroup'))]}
                  </Typography>
                ),
              },
              {
                label: policyValue.get('permissionGroup'),
                width: '25%',
                cellComponent: (
                  <Typography>
                    {PermissionsGroupsLabels[policyValue.get('permissionGroup')]}
                  </Typography>
                ),
              },
              {
                label: '',
                width: '25%',
                cellComponent: (
                  <Typography
                    variant="body1"
                    color={policyValue.get('active') ? 'primary' : 'textSecondary'}
                  >
                    <FormattedMessage
                      {...(policyValue.get('active')
                        ? commonMessages.activeStatus
                        : commonMessages.inactiveStatus)}
                    />
                  </Typography>
                ),
              },
              {
                label: 'actions',
                padding: 'none',
                align: 'center',
                width: '54px',
                cellComponent: (
                  <ActionsMenu horizontal tableActionsMode>
                    <ActionItem
                      key="1"
                      disabled={!policyValue.get('active') || !policyValue.get('changeable')}
                      icon={<EditIcon className={classes.icon} />}
                      onClick={() => handleEditDialogShow(policyValue.toJS())}
                    >
                      <FormattedMessage {...menuItems.edit} />
                    </ActionItem>
                    <ActionItem
                      key="2"
                      icon={
                        policyValue.get('active') ? (
                          <XDeleteIcon className={classes.icon} />
                        ) : (
                          <CheckIcon className={classes.icon} />
                        )
                      }
                      onClick={() => handleChangeStatusDialogShow(policyValue.toJS())}
                    >
                      <Typography>
                        {policyValue.get('active') ? (
                          <FormattedMessage {...menuItems.deactivate} />
                        ) : (
                          <FormattedMessage {...menuItems.activate} />
                        )}
                      </Typography>
                    </ActionItem>
                  </ActionsMenu>
                ),
              },
            ],
          };
        },
      )
      .toJS();
  };

  return (
    <>
      <Table
        addButton={
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon className={classes.icon} />}
            onClick={() => setShowEditDialog(true)}
          >
            <FormattedMessage {...commonMessages.newBtn} />
          </Button>
        }
        filters={tableFilterSettings}
        suppressSmartBehavior
        suppressFiltersMobilePadding
        showPerPageSelect
        headerOptions={headerOptions}
        isLoading={isPoliciesListLoading}
        rows={createRows()}
        totalRows={policiesListMeta.get('total')}
        onChangeParams={handleChangeTableProps}
        tableParams={tableParams}
      />
      <ChangeStatusModal
        itemTitle={selectedRow?.title || ''}
        isOpen={showChangeStatusDialog}
        onClose={handleChangeStatusDialogClose}
        onSubmit={handleChangeStatusPolicyItem}
        isActive={!!selectedRow?.active}
        isLoading={policyItemActionRequestState.get('isLoading') || policyItemLoading}
      />
      <EditPolicyModal
        isOpen={showEditDialog}
        selectedRow={selectedRow}
        onSubmit={data => {
          if (selectedRow) {
            editItem(selectedRow.id, filterPermissionSet(data));
          } else {
            createItem(filterPermissionSet(data));
          }
        }}
        onClose={handleEditDialogClose}
        isLoading={policyItemActionRequestState.get('isLoading') || policyItemLoading}
        success={policyItemActionRequestState.get('result')?.get('success')}
      />
      <ViewPolicyModal
        isOpen={showViewDialog}
        selectedRow={selectedRow}
        onClose={handleViewDialogClose}
        isLoading={policyItemActionRequestState.get('isLoading') || policyItemLoading}
      />
    </>
  );
};

export default PoliciesTable;
