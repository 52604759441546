import * as yup from 'yup';
import inputErrors from 'common/messages/inputErrors';
import { getRequiredErrorMessage } from 'common/utils/validation';

export const redeemInventorySchema = yup.object().shape({
  club: yup
    .object()
    .nullable()
    .required(getRequiredErrorMessage),
  redeemedNumber: yup.number().required(getRequiredErrorMessage),
  pinCode: yup
    .string()
    .nullable()
    .min(4, () => inputErrors.pinCodeLengthError)
    .required(getRequiredErrorMessage),
});
