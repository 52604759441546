import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { ButtonWithCircularProgress } from 'common/components';
import commonMessages from 'common/messages/messages';
import tableHeaders from 'common/messages/tableHeaders';
import { IKidZoneAutocompleteCustomerImt } from 'modules/kid-zone/interfaces';
import GuardianInfo from './GuardianInfo';

interface IGuardianBlockProps {
  member: IKidZoneAutocompleteCustomerImt;
  isSmallScreen: boolean;
  isCheckInPanel: boolean;
  isActionLoading?: boolean;
  onCheckInKid: (guardianId: number) => void;
  onCheckOutKid?: (guardianId: number) => void;
}

const useStyles = makeStyles({
  checkOutButtonWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

const GuardianBlock = (props: IGuardianBlockProps): JSX.Element => {
  const {
    isActionLoading,
    onCheckOutKid,
    onCheckInKid,
    isCheckInPanel,
    member,
    isSmallScreen,
  } = props;

  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <GuardianInfo member={member} isSmallScreen={isSmallScreen} />
        <Grid item xs={isSmallScreen ? 6 : 12} className={classes.checkOutButtonWrap}>
          <ButtonWithCircularProgress
            variant="contained"
            fullWidth={!isSmallScreen}
            isSubmitting={!!isActionLoading}
            type="button"
            onClick={() => {
              if (isCheckInPanel) {
                onCheckInKid(member.get('id'));
              } else {
                if (onCheckOutKid) {
                  onCheckOutKid(member.get('id'));
                }
              }
            }}
          >
            <FormattedMessage
              {...(isCheckInPanel ? commonMessages.broughtBtn : tableHeaders.checkOut)}
            />
          </ButtonWithCircularProgress>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GuardianBlock;
