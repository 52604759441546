import React from 'react';
import { FieldArray, Controller, UseFormTrigger } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Control } from 'react-hook-form/dist/types/form';
import { Grid, Typography } from '@material-ui/core';
import CheckboxLabeled from 'common/components/CheckboxLabeled/CheckboxLabeled';
import { ConditionForPurchaseTypes } from 'modules/services/constants/packages';
import { MinMaxNumberInput } from 'common/components';
import messages from 'modules/services/messages/messages';
import { makeStyles } from '@material-ui/core/styles';
import { IMembershipSection } from '../../../../interfaces/packages';

interface IInitialFeeProps {
  control: Control<IMembershipSection>;
  initialFee: FieldArray;
  index: number;
  errors: any;
  trigger: UseFormTrigger<IMembershipSection>;
}

const useStyles = makeStyles(() => ({
  mainCheckboxLabel: {
    fontWeight: 500,
  },
}));

const InitialFee: React.FC<IInitialFeeProps> = ({
  control,
  trigger,
  initialFee: formInitialFee,
  index,
  errors,
}: IInitialFeeProps): JSX.Element => {
  const classes = useStyles();
  const initialFee = formInitialFee as Record<string, any>; // TODO - PRM-1810 need types

  return (
    <Grid item xs={12} key={initialFee.key}>
      <Grid container spacing={2}>
        <Controller
          control={control}
          name={`initialFees.${index}.purchaseCondition`}
          defaultValue={initialFee?.purchaseCondition}
          render={(() => null) as any} // TODO - PRM-3575 need fix
        />

        <Grid item xs={3}>
          <CheckboxLabeled
            control={control}
            defaultValue={initialFee?.active}
            name={`initialFees.${index}.active`}
            label={
              <Typography className={classes.mainCheckboxLabel}>
                {ConditionForPurchaseTypes.translate(initialFee.purchaseCondition)}
              </Typography>
            }
          />
        </Grid>

        {initialFee.active && (
          <>
            <Grid item xs={4}>
              <CheckboxLabeled
                control={control}
                defaultValue={initialFee.override}
                name={`initialFees.${index}.override`}
                label={<FormattedMessage {...messages.overrideInitialFeeLabel} />}
              />
            </Grid>

            {initialFee.override && (
              <>
                <Grid item xs={5}>
                  <CheckboxLabeled
                    control={control}
                    defaultValue={initialFee?.immediatePayment}
                    name={`initialFees.${index}.immediatePayment`}
                    label={<FormattedMessage {...messages.immediatePaymentLabel} />}
                  />
                </Grid>

                <Grid item xs={4}>
                  <MinMaxNumberInput
                    control={control}
                    errors={errors}
                    trigger={trigger}
                    baseName={`initialFees.${index}.editableAmount`}
                    label={<FormattedMessage {...messages.initialFeeLabel} />}
                    baseDefaultValue={initialFee?.editableAmount}
                    numberFormatProps={{ decimalScale: 2, prefix: '$' }}
                  />
                </Grid>

                {!initialFee.immediatePayment && (
                  <>
                    <Grid item xs={4}>
                      <MinMaxNumberInput
                        control={control}
                        errors={errors}
                        trigger={trigger}
                        baseName={`initialFees.${index}.editableNumber`}
                        label={<FormattedMessage {...messages.paymentsLabel} />}
                        baseDefaultValue={initialFee?.editableNumber}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <MinMaxNumberInput
                        control={control}
                        errors={errors}
                        trigger={trigger}
                        baseName={`initialFees.${index}.paymentEditablePeriodInDays`}
                        label={<FormattedMessage {...messages.paymentDaysLabel} />}
                        baseDefaultValue={initialFee?.paymentEditablePeriodInDays}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default InitialFee;
