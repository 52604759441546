import React, { FC, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import { makeStyles, Typography } from '@material-ui/core';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { formatNumberToPrice } from 'common/utils';
import { PeakModules } from 'common/constants/peakModules';
import commonMessages from 'common/messages/messages';
import { IPaymentImt } from 'common/components/PersonProfile/interfaces';
import { IBodyCell, IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { IFilterSettings } from 'common/interfaces/filter';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { ActionItem, ActionsMenu, Table } from 'common/components/index';
import StatusCell from 'modules/reports/components/ReportsTable/StatusCell';
import InvoiceDetailsModal from '../InvoiceDetailsModal/InvoiceDetailsModal';
import tableHeaders from 'common/messages/tableHeaders';
import { InvoiceStatus } from 'common/interfaces/invoices';

const useStyles = makeStyles(() => ({ invoiceNumberTitle: { cursor: 'pointer' } }));

interface IInvoicesTable {
  payments: List<IPaymentImt>;
  paymentsLoading: boolean;
  paymentsMeta?: IPageMetaImt;
  hideSearchInput?: boolean;
  hidePagination?: boolean;
  isExtendedView?: boolean;
  onChangeTableParams?: (tableParams: ITableParams) => void;
  setIsVoidModalOpen?: (invoiceId: string) => void;
  tableFilterSettings?: IFilterSettings[];
  tableParams?: ITableParams;
  disableSorting?: boolean;
  module: PeakModules;
  personId: number;
  hidePadding?: boolean;
  suppressFiltersMobilePadding?: boolean;
}

const InvoicesTable: FC<IInvoicesTable> = ({
  payments,
  paymentsLoading,
  paymentsMeta,
  hidePagination,
  hideSearchInput,
  isExtendedView,
  tableFilterSettings,
  onChangeTableParams,
  setIsVoidModalOpen,
  disableSorting,
  tableParams,
  module,
  personId,
  hidePadding = false,
  suppressFiltersMobilePadding = false,
}) => {
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(null);
  const [timezoneMoment] = useTimezoneMoment();
  const classes = useStyles();

  const headerOptions = useMemo(
    (): IHeadCell[] => [
      {
        id: 'number',
        label: <FormattedMessage {...tableHeaders.id} />,
        sort: !disableSorting,
      },
      {
        id: 'created_date',
        label: <FormattedMessage {...tableHeaders.created} />,
        sort: !disableSorting,
      },
      {
        id: 'salesperson',
        label: <FormattedMessage {...tableHeaders.salesperson} />,
        sort: !disableSorting,
      },
      {
        id: 'register',
        label: <FormattedMessage {...tableHeaders.register} />,
        sort: !disableSorting,
      },
      ...(isExtendedView
        ? [
            {
              id: 'paid_date',
              label: <FormattedMessage {...tableHeaders.paid} />,
              sort: !disableSorting,
            },
            {
              id: 'subtotal',
              label: <FormattedMessage {...tableHeaders.subtotal} />,
              sort: !disableSorting,
            },
            {
              id: 'tax',
              label: <FormattedMessage {...tableHeaders.taxes} />,
              sort: !disableSorting,
            },
            {
              id: 'discount',
              label: <FormattedMessage {...tableHeaders.discount} />,
              sort: false,
            },
          ]
        : []),
      {
        id: 'total',
        label: <FormattedMessage {...tableHeaders.total} />,
        sort: !disableSorting,
      },
      ...(isExtendedView
        ? [
            {
              id: 'reward',
              label: <FormattedMessage {...tableHeaders.reward} />,
              sort: false,
            },
          ]
        : []),
      {
        id: 'status',
        label: <FormattedMessage {...tableHeaders.status} />,
        sort: !disableSorting,
        align: 'left',
      },
      ...(isExtendedView
        ? [
            {
              id: 'actions',
              padding: 'none',
              align: 'center',
              label: '',
            } as IHeadCell,
          ]
        : []),
    ],
    [disableSorting, isExtendedView],
  );

  const rows = useMemo(() => {
    return payments
      ?.map(
        (paymentItem: IPaymentImt): ITableRow => {
          return {
            id: paymentItem.get('invoiceId'),
            cells: [
              {
                label: '',
                className: 'body-cell',
                cellComponent: (
                  <Typography
                    variant="h6"
                    color="primary"
                    onClick={() => setSelectedInvoiceId(paymentItem.get('invoiceId'))}
                    className={classes.invoiceNumberTitle}
                  >
                    {paymentItem.get('number')}
                  </Typography>
                ),
              },
              {
                label: `${timezoneMoment(paymentItem.get('createdDate')).format(
                  DEFAULT_DATE_TIME_FORMAT,
                )}`,
                className: 'body-cell',
              },
              {
                label: paymentItem.get('salesperson')
                  ? `${paymentItem.getIn(['salesperson', 'firstName'])} ${paymentItem.getIn([
                      'salesperson',
                      'lastName',
                    ])}`
                  : '-',
                className: 'body-cell',
              },
              {
                label: `${paymentItem.getIn(['register', 'title'])}`,
                className: 'body-cell',
              },
              ...(isExtendedView
                ? [
                    {
                      label: `${timezoneMoment(paymentItem.get('paymentDate')).format(
                        DEFAULT_DATE_TIME_FORMAT,
                      )}`,
                      className: 'body-cell',
                    },
                    {
                      label: `$${formatNumberToPrice(paymentItem.get('subtotalAmount'))}`,
                      className: 'body-cell',
                    },
                    {
                      label: `$${formatNumberToPrice(paymentItem.get('taxAmount'))}`,
                      className: 'body-cell',
                    },
                    {
                      label: `$${formatNumberToPrice(paymentItem.get('discountAmount'))}`,
                      className: 'body-cell',
                    },
                  ]
                : []),
              {
                label: `$${formatNumberToPrice(paymentItem.get('totalAmount'))}`,
                className: 'body-cell',
              },
              ...(isExtendedView
                ? [
                    {
                      label: paymentItem.get('rewardAmount')
                        ? `${paymentItem.get('rewardAmount')}`
                        : '-',
                      className: 'body-cell',
                    },
                  ]
                : []),
              {
                label: '',
                className: 'body-cell',
                cellComponent: <StatusCell status={paymentItem.get('invoiceStatus')} />,
              },
              ...(isExtendedView
                ? [
                    {
                      label: '',
                      className: 'body-actions-cell',
                      cellComponent: (
                        <ActionsMenu horizontal tableActionsMode>
                          <ActionItem
                            disabled={
                              paymentItem.get('invoiceStatus') !== InvoiceStatus.IN_PROGRESS
                            }
                            onClick={() => {
                              if (setIsVoidModalOpen) {
                                setIsVoidModalOpen(paymentItem.get('invoiceId'));
                              }
                            }}
                          >
                            <FormattedMessage {...commonMessages.voidInvoice} />
                          </ActionItem>
                        </ActionsMenu>
                      ),
                    } as IBodyCell,
                  ]
                : []),
            ],
          };
        },
      )
      .toJS();
  }, [classes, isExtendedView, payments, timezoneMoment, setIsVoidModalOpen]);

  return (
    <>
      <Table
        rows={rows}
        tableParams={tableParams}
        headerOptions={headerOptions}
        filters={tableFilterSettings}
        hideSearchInput={hideSearchInput}
        showPerPageSelect={!hidePagination}
        hidePagination={hidePagination}
        onChangeParams={onChangeTableParams}
        hideToolbar
        totalRows={paymentsMeta?.get('total')}
        page={paymentsMeta?.get('page')}
        isLoading={paymentsLoading}
        borderShadow={false}
        hidePadding={hidePadding}
        suppressFiltersMobilePadding={suppressFiltersMobilePadding}
      />

      {!!selectedInvoiceId && (
        <InvoiceDetailsModal
          isOpen={!!selectedInvoiceId}
          invoiceId={selectedInvoiceId}
          onClose={() => setSelectedInvoiceId(null)}
          module={module}
          personId={personId}
        />
      )}
    </>
  );
};

export default InvoicesTable;
