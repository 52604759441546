import { AnyAction } from 'redux';
import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { initialState, initReqStateImt } from 'common/constants/initialState';
import { actionTypes } from 'modules/kid-zone/constants';
import { actionTypes as authActionsTypes } from 'modules/authentication/state/constants';
import moment from 'moment-timezone';

const initialKidZoneStatus = fromJS({
  status: {
    notLoadedYet: true,
  },
  isLoading: false,
});

function kidZoneStatusReducer(state = initialKidZoneStatus, action: AnyAction) {
  switch (action.type) {
    case actionTypes.KID_ZONE_STATUS:
      return state.set('status', fromJS(action.payload));
    case actionTypes.KID_ZONE_STATUS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_KID_ZONE_STATUS:
      return initialKidZoneStatus;
    default:
      return state;
  }
}

const initialManualCheckInState = fromJS({
  members: [],
  addDOBAction: initialState,
  isLoading: false,
});

function manualCheckInReducer(state = initialManualCheckInState, action: AnyAction) {
  switch (action.type) {
    case actionTypes.GET_MEMBERS:
      return state.set('members', fromJS(action.payload.slice(0, 10)));
    case actionTypes.GET_MEMBERS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_MEMBERS:
      return state.set('members', fromJS([]));
    case actionTypes.ADD_DATE_OF_BIRTHDAY_ACTION:
      const { updatedPerson, cardIndex, personId } = action.payload;

      const isChild =
        moment()
          .utc()
          .diff(updatedPerson.birthday, 'years') < 18;

      return state
        .updateIn(['members', cardIndex, isChild ? 'children' : 'guardians'], members =>
          fromJS([...members.toJS(), action.payload.updatedPerson]),
        )
        .updateIn(['members', cardIndex, 'other'], members =>
          members.filter(memberItem => memberItem.get('id') !== personId),
        );
    case actionTypes.ADD_DATE_OF_BIRTHDAY_ACTION_RESULT:
      return state.setIn(['addDOBAction', 'result'], action.payload);
    case actionTypes.ADD_DATE_OF_BIRTHDAY_ACTION_LOADING:
      return state.setIn(['addDOBAction', 'isLoading'], action.payload);
    case actionTypes.RESET_ADD_DATE_OF_BIRTHDAY_ACTION_RESULT:
      return state.set('addDOBAction', initReqStateImt);
    default:
      return state;
  }
}

const initialCheckInsState = fromJS({
  list: [],
  isLoading: false,
  currentCheckIn: null,
  selectedCheckIn: null,
  foundMembersToCheckInByBarcode: initialState,

  isDaycareServicesLoading: false,
  daycareServices: [],

  checkInAllActionStatus: initialState,
  checkInActionStatus: initialState,
});

function checkInsReducer(state = initialCheckInsState, action: AnyAction) {
  switch (action.type) {
    case actionTypes.FETCH_CHECK_INS:
      return state.set('list', fromJS(action.payload));
    case actionTypes.FETCH_CHECK_INS_LOADING:
      return state.set('isLoading', action.payload);

    case actionTypes.SET_CURRENT_CHECK_IN:
      return state.get('currentCheckIn')
        ? state
            .set('currentCheckIn', fromJS(action.payload))
            .update('list', list => list.unshift(state.get('currentCheckIn')))
        : state.set('currentCheckIn', fromJS(action.payload));
    case actionTypes.RESET_CURRENT_CHECK_IN:
      return state.set('currentCheckIn', null);
    case actionTypes.SET_SELECTED_CHECK_IN:
      if (state.getIn(['currentCheckIn', 'personId']) === action.payload) {
        return state.set('selectedCheckIn', state.get('currentCheckIn'));
      }

      return state.set(
        'selectedCheckIn',
        state.get('list').find(ch => ch.get('personId') === action.payload),
      );

    case actionTypes.CHECK_OUT_KID:
      const currentCheckInId = state.getIn(['currentCheckIn', 'personId']);

      return currentCheckInId === action.payload.personId
        ? state.set('currentCheckIn', null)
        : state.update('list', list =>
            list.filter(checkIn => checkIn.get('personId') !== action.payload.personId),
          );

    case actionTypes.CHECK_IN_CHILD_LOADING:
      return state.setIn(['checkInActionStatus', 'isLoading'], action.payload);
    case actionTypes.CHECK_IN_CHILD:
      return state.setIn(['checkInActionStatus', 'result'], action.payload);
    case actionTypes.RESET_CHECK_IN_ACTION_STATUS:
      return state.set('checkInActionStatus', fromJS(initialState));

    case actionTypes.CHECK_IN_ALL_CHILDREN_LOADING:
      return state.setIn(['checkInAllActionStatus', 'isLoading'], action.payload);
    case actionTypes.CHECK_IN_ALL_CHILDREN:
      const checkInsList = fromJS(action.payload);
      const baseActionState = state.setIn(
        ['checkInAllActionStatus', 'result'],
        fromJS(action.payload),
      );
      if (!checkInsList?.size) {
        return baseActionState;
      }

      const previousCurrentCheckin = state.get('currentCheckIn');
      const currentCheckin = checkInsList.get(0);
      const toUnshift = checkInsList.slice(1) || [];
      const updatedToUnshift = previousCurrentCheckin
        ? toUnshift.push(previousCurrentCheckin)
        : toUnshift;
      return baseActionState
        .set('currentCheckIn', currentCheckin)
        .update('list', list => updatedToUnshift.concat(list));

    case actionTypes.FETCH_MEMBERS_TO_CHECK_IN_BY_BARCODE:
      return state.setIn(['foundMembersToCheckInByBarcode', 'result'], fromJS(action.payload));
    case actionTypes.RESET_MEMBERS_TO_CHECK_IN_BY_BARCODE:
      return state.set('foundMembersToCheckInByBarcode', fromJS(initialState));
    case actionTypes.FETCH_MEMBERS_TO_CHECK_IN_BY_BARCODE_ERROR:
      return state.setIn(['foundMembersToCheckInByBarcode', 'error'], fromJS(action.payload));
    case actionTypes.FETCH_MEMBERS_TO_CHECK_IN_BY_BARCODE_LOADING:
      return state.setIn(['foundMembersToCheckInByBarcode', 'isLoading'], action.payload);

    case actionTypes.RESET_CHECK_IN_ALL_CHILDREN_ACTION_RESULT:
      return state.set('checkInAllActionStatus', fromJS(initialState));
    case actionTypes.FETCH_GUARDIAN_DAYCARE_SERVICES:
      return state.set('daycareServices', fromJS(action.payload));
    case actionTypes.FETCH_GUARDIAN_DAYCARE_SERVICES_LOADING:
      return state.set('isDaycareServicesLoading', action.payload);
    case actionTypes.RESET_GUARDIAN_DAYCARE_SERVICES:
      return state.set('daycareServices', fromJS([]));
    default:
      return state;
  }
}

const initialKidZonePanelState = fromJS({
  mainPanelPersonId: null,
  rightPanelPersonId: null,
});

function panelsReducer(state = initialKidZonePanelState, action: AnyAction) {
  switch (action.type) {
    case actionTypes.SET_MAIN_PANEL_PERSON:
      return state.set('mainPanelPersonId', action.payload);
    case actionTypes.SET_RIGHT_PANEL_PERSON:
      return state.set('rightPanelPersonId', action.payload);
    case authActionsTypes.SELECT_ORGANIZATION:
      return initialKidZonePanelState;
    default:
      return state;
  }
}

const initialCheckInsHistoryState = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 10,
    total: 0,
  },
  isLoading: false,
});

function checkInsHistoryReducer(state = initialCheckInsHistoryState, action: AnyAction) {
  switch (action.type) {
    case actionTypes.FETCH_CHECK_INS_HISTORY:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.FETCH_CHECK_INS_HISTORY_LOADING:
      return state.set('isLoading', action.payload);
    default:
      return state;
  }
}

const allergiesInitState = fromJS({
  list: [],
  isLoading: false,
});

const allergiesReducer = (state = allergiesInitState, action) => {
  switch (action.type) {
    case actionTypes.SET_ALLERGIES_IS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_ALLERGIES:
    case actionTypes.UPDATE_ALLERGIES:
      return state.set('list', fromJS(action.payload));
    case actionTypes.RESET_ALLERGIES:
      return allergiesInitState;
    default:
      return state;
  }
};

export default combineReducers({
  kidZoneStatus: kidZoneStatusReducer,
  panels: panelsReducer,
  manualCheckIn: manualCheckInReducer,
  checkIns: checkInsReducer,
  checkInsHistory: checkInsHistoryReducer,
  allergies: allergiesReducer,
});
