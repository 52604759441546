import { createSelector } from 'reselect';
import { AppStoreTypeImt } from 'store';

import { IUserOrganizationImt } from 'common/interfaces/clients';
import { selectUserSelectedOrganization } from 'modules/authentication/state/selectors';
import { IPosKioskRegisterDataImt } from 'modules/pos-kiosk/interfaces/registers';

// selected registers according club ids
const selectRegistersByClubList = (state: AppStoreTypeImt) =>
  state?.getIn(['posKiosk', 'register', 'selectedRegistersByClubList']);
// select last active register in active club
export const selectSelectedRegisterId = createSelector<any, any, string>(
  [selectRegistersByClubList, selectUserSelectedOrganization],
  (list, org: IUserOrganizationImt) => list?.get(String(org?.get('id'))),
);

// register data
const posKioskRegisterDataPath = ['posKiosk', 'register', 'selectedRegister'];

export const selectPosKioskRegisterDataLoading = state =>
  state.getIn([...posKioskRegisterDataPath, 'isLoading']);

export const selectPosKioskRegisterData = state =>
  state.getIn([...posKioskRegisterDataPath, 'registerData']);

export const selectPosKioskRegisterNOSAmount = (state): number =>
  state.getIn([...posKioskRegisterDataPath, 'registerData', 'club', 'maxNosInvoiceAmount']);

export const selectPosKioskRegisterTitle = createSelector<any, any, string>(
  selectPosKioskRegisterData,
  (register: IPosKioskRegisterDataImt) => register.get('title'),
);
// register totals
export const selectPosKioskRegisterTotalsLoading = state =>
  state.getIn([...posKioskRegisterDataPath, 'isTotalsLoading']);

export const selectPosKioskRegisterTotals = state =>
  state.getIn([...posKioskRegisterDataPath, 'registerTotals']);
// open register
export const selectPosKioskOpenRegisterLoading = state =>
  state.getIn([...posKioskRegisterDataPath, 'isOpenRegisterLoading']);
export const selectPosKioskOpenRegisterResult = state =>
  state.getIn([...posKioskRegisterDataPath, 'openRegisterResult']);
// close register
export const selectPosKioskCloseRegisterLoading = state =>
  state.getIn([...posKioskRegisterDataPath, 'isCloseRegisterLoading']);
export const selectPosKioskCloseRegisterResult = state =>
  state.getIn([...posKioskRegisterDataPath, 'closeRegisterResult']);
// reconcile register
export const selectPosKioskReconcileRegisterLoading = state =>
  state.getIn([...posKioskRegisterDataPath, 'isReconcileRegisterLoading']);
export const selectPosKioskReconcileRegisterResult = state =>
  state.getIn([...posKioskRegisterDataPath, 'reconcileRegisterResult']);

export const selectPosKioskCloseRegisterInvoicesResult = state =>
  state.getIn([...posKioskRegisterDataPath, 'closeRegisterInvoicesResult']);

export const selectPosKioskCloseRegisterInvoicesLoading = state =>
  state.getIn([...posKioskRegisterDataPath, 'closeRegisterInvoicesIsLoading']);
export const selectPosKioskRegisterCashDeskInfo = state =>
  state.getIn([...posKioskRegisterDataPath, 'cashDeskInfo']);
export const selectPosKioskRegisterCashDeskInfoLoading = state =>
  state.getIn([...posKioskRegisterDataPath, 'isCashDeskInfoLoading']);
// register selection process
export const selectRegisterSelectionProcessLoading = (state): boolean => {
  return state.getIn([...posKioskRegisterDataPath, 'registerSelectionLoading']);
};
