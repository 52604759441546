import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, ListSubheader, MenuItem, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import cn from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingBackdrop, ScrollBox, SearchInput, Select } from 'common/components/index';
import MembershipFormView from 'common/components/MembershipFormView/MembershipFormView';
import {
  IMembershipFormNew,
  IMembershipTransformed,
  IPackageConfigurationImt,
  IShortPackagesImt,
} from 'common/interfaces/membership';
import { MembershipValidationSchema } from 'common/components/Steps/TrialMembershipStep/ValidationSchema';
import { StepContext } from 'common/createContext/stepContext';
import { INamedEntityImt } from 'common/interfaces/common';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import { initialValues } from 'common/components/Steps/TrialMembershipStep/initialValues';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { CustomTheme } from 'common/ui/interfaces';
import { transformPackageFromDTO } from 'common/components/MembershipFormView/utils';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import { QuestionnaireFirstPaymentModal } from 'common/components/InvoiceOperating/InvoiceModals';
import { checkIsGracePeriodAllow } from 'common/components/InvoiceOperating/utils';
import WarningTooltip from './WarningTooltip';
import { ReasonType } from 'common/interfaces/dialog';
import { PeakModules } from 'common/constants/peakModules';
import useDiscardChangesContext from 'common/hooks/useDiscardChangesContext';
import DiscardChangesModalProvider from 'common/modals/DiscardChangesModal/DiscardChangesModalProvider';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    flex: 1,
    height: '100%',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: theme.spacing(3, 2),
  },
  comingSoonBundlesTitle: {
    paddingLeft: theme.spacing(1.5),
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
  },
  comingSoonBundleItemTitle: {
    marginRight: theme.spacing(1.75),
  },
  comingSoonBundleItemRightTitle: {
    marginRight: theme.spacing(1),
  },
  packageItem: {
    '&.MuiMenuItem-root': {
      paddingLeft: `${theme.spacing(1.5)}px !important`,
    },
  },
  searchInput: {
    position: 'sticky !important' as 'sticky',
    top: 0,
    zIndex: 1,
    boxShadow: 'none',
  },
  selectMenu: {
    '& > .MuiMenu-list': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  comingSoonPackageItem: {
    '&:hover': {
      backgroundColor: 'inherit',
      color: 'inherit',
    },
  },
}));

interface IProps {
  membershipPackages: IShortPackagesImt | null;
  membershipPackage: IPackageConfigurationImt;
  membershipPackagesLoading?: boolean;
  onChangeSelectedPackage: (membershipId: string | null, clubId?: string) => void;
  onChangeSelectedClub: (clubId: string) => void;
  handleSearchMembershipPackages: (search: string) => void;
  onSubmit?: (data) => void;
  clubId?: string;
  clubs: ImmutableList<INamedEntityImt>;
  isResponseSuccess?: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  module: PeakModules;
}

const getInitialValues = (
  membershipId: string,
  clubId: string,
  membershipPackage: IPackageConfigurationImt,
) => {
  const membershipConfiguration = membershipPackage.toJS();

  return {
    ...initialValues,
    membership: membershipId,
    club: clubId,
    packageConfiguration: {
      ...transformPackageFromDTO(membershipConfiguration),
    },
  };
};

const TrialMembershipStep = (props: IProps): JSX.Element => {
  const {
    membershipPackages,
    membershipPackage,
    onChangeSelectedPackage,
    handleSearchMembershipPackages,
    membershipPackagesLoading,
    onChangeSelectedClub,
    clubId = '',
    onSubmit,
    isResponseSuccess,
    isLoading,
    isSubmitting,
    clubs,
    module,
  } = props;

  const [configurableService, setConfigurableService] = useState<IMembershipTransformed | null>(
    null,
  );
  const { onBack, renderFooter, onNext } = useContext(StepContext);
  const renderIntlMessage = useRenderIntlMessage();
  const dcContext = useDiscardChangesContext();
  const classes = useStyles();

  const formMethods = useForm<IMembershipFormNew>({
    defaultValues: initialValues,
    resolver: yupResolver(MembershipValidationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onBlur',
    shouldUnregister: false,
  });

  const { handleSubmit, control, getValues, formState, reset, watch, setValue } = formMethods;
  const { errors } = formState;
  const membershipId = watch('membership');
  const club = watch('club');
  const isDirty = Object.keys(formState).length > 0;

  useEffect(() => {
    if (clubId) {
      reset({
        ...initialValues,
        club: clubId,
      });
    }
  }, [clubId, reset]);

  useEffect(() => {
    if (onNext && isResponseSuccess) {
      onNext({ membershipId, clubId: club });
    }
  }, [isResponseSuccess, onNext, getValues, membershipId, club]);

  useComponentDidUpdate(() => {
    if (membershipPackage.size) {
      reset(getInitialValues(membershipId, club, membershipPackage));
    }
  }, [club, clubId, membershipId, membershipPackage, reset]);

  const handleResetConfiguration = useCallback(() => {
    reset(getInitialValues(membershipId, club, membershipPackage));
  }, [club, membershipId, membershipPackage, reset]);

  const handleFormSubmit = data => {
    const isPaymentGraceAllow = checkIsGracePeriodAllow(data.packageConfiguration);

    if (isPaymentGraceAllow) {
      setConfigurableService(data);
    } else {
      if (onSubmit) {
        onSubmit({
          ...data,
          packageConfiguration: {
            ...data.packageConfiguration,
            makeFirstPayment: true,
          },
        });
      }
    }
  };

  const handleSubmitFirstPaymentModal = () => {
    if (onSubmit) {
      onSubmit({
        ...configurableService,
        packageConfiguration: {
          ...configurableService?.packageConfiguration,
          makeFirstPayment: true,
        },
      });
    }

    setConfigurableService(null);
  };

  const handleCloseFirstPaymentModal = (e, reason: ReasonType) => {
    if (onSubmit && reason === 'cancelBtnClick') {
      onSubmit({
        ...configurableService,
        packageConfiguration: {
          ...configurableService?.packageConfiguration,
          makeFirstPayment: false,
        },
      });
    }

    setConfigurableService(null);
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <ScrollBox hasShadowsOnScroll>
          <form id="membership-info-form" className={classes.root} autoComplete="none">
            <Controller
              name="club"
              control={control}
              render={({ field }) => (
                <Select
                  name={field.name}
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={(id: string) => {
                    field.onChange(id);
                    onChangeSelectedClub(id);
                    setValue('membership', null as any); // TODO - PRM-3575 need types
                    onChangeSelectedPackage(null);
                  }}
                  fullWidth
                  menuClassName={classes.selectMenu}
                  label={<FormattedMessage {...inputLabels.homeClub} />}
                  error={!!errors.club}
                  helperText={renderIntlMessage(errors.club?.message)}
                >
                  {clubs?.map(clubItem => (
                    <MenuItem key={clubItem.get('id')} value={clubItem.get('id')}>
                      {clubItem.get('title')}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

            <Box marginY={1.5}>
              <Controller
                name="membership"
                control={control}
                render={({ field: { name, value, onChange, onBlur } }) => (
                  <Select
                    menuClassName={classes.selectMenu}
                    name={name}
                    onClose={() => {
                      handleSearchMembershipPackages('');
                    }}
                    onBlur={onBlur}
                    value={value}
                    onChange={e => {
                      onChange(e);
                      onChangeSelectedPackage(e, club);
                    }}
                    label={<FormattedMessage {...inputLabels.membershipPackage} />}
                    fullWidth
                    error={!!errors.membership}
                    helperText={renderIntlMessage(errors.membership?.message)}
                  >
                    {membershipPackagesLoading && <LoadingBackdrop isLoading />}
                    <SearchInput
                      className={classes.searchInput}
                      placeholder={renderIntlMessage(inputLabels.search) || ''}
                      value=""
                      autoFocus
                      onChange={handleSearchMembershipPackages}
                      onKeyDown={e => {
                        if (e.key !== 'Escape') {
                          e.stopPropagation();
                        }
                      }}
                    />
                    {membershipPackages?.get('available')?.map(servicePackage => {
                      const availableToSellByClub = servicePackage.get(
                        'availableToSellByCurrentClub',
                      );
                      const availableToSellByEmployee = servicePackage.get(
                        'availableToSellByCurrentEmployee',
                      );

                      const shouldVisibleWarning =
                        !availableToSellByClub || !availableToSellByEmployee;
                      return (
                        <MenuItem
                          key={servicePackage.get('id')}
                          value={servicePackage.get('id')}
                          className={classes.packageItem}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                          >
                            <Typography variant="h6" color="inherit">
                              {servicePackage.get('title')}
                            </Typography>

                            {shouldVisibleWarning && (
                              <WarningTooltip
                                availableToSellByClub={availableToSellByClub}
                                availableToSellByEmployee={availableToSellByEmployee}
                              />
                            )}
                          </Box>
                        </MenuItem>
                      );
                    })}

                    {!!membershipPackages?.get('comingSoon')?.size && (
                      <ListSubheader key={1} className={classes.comingSoonBundlesTitle}>
                        <FormattedMessage {...commonMessages.comingSoonBundles} />
                      </ListSubheader>
                    )}

                    {membershipPackages?.get('comingSoon')?.map(servicePackage => {
                      const availableToSellByClub = servicePackage.get(
                        'availableToSellByCurrentClub',
                      );
                      const availableToSellByEmployee = servicePackage.get(
                        'availableToSellByCurrentEmployee',
                      );

                      const shouldVisibleWarning =
                        !availableToSellByClub || !availableToSellByEmployee;

                      return (
                        <MenuItem
                          key={servicePackage.get('id')}
                          className={cn(classes.packageItem, classes.comingSoonPackageItem)}
                          disabled
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                          >
                            <Typography
                              variant="h6"
                              color="inherit"
                              className={classes.comingSoonBundleItemTitle}
                            >
                              {servicePackage.get('title')}
                            </Typography>
                            <Box display="flex" alignItems="center">
                              <Typography className={classes.comingSoonBundleItemRightTitle}>
                                {`${renderIntlMessage(inputLabels.from)} ${moment(
                                  servicePackage.get('startDate'),
                                ).format(DEFAULT_DATE_TIME_FORMAT)} `}
                              </Typography>
                              {shouldVisibleWarning && (
                                <WarningTooltip
                                  availableToSellByClub={availableToSellByClub}
                                  availableToSellByEmployee={availableToSellByEmployee}
                                />
                              )}
                            </Box>
                          </Box>
                        </MenuItem>
                      );
                    })}

                    {!membershipPackages?.get('comingSoon')?.size &&
                      !membershipPackages?.get('available')?.size && (
                        <Box display="flex" alignItems="center" justifyContent="center" pb={2}>
                          <Typography variant="body1" color="textSecondary">
                            <FormattedMessage {...commonMessages.noItemsFound} />
                          </Typography>
                        </Box>
                      )}
                  </Select>
                )}
              />
            </Box>

            <Box position="relative">
              {!!membershipPackage?.size && (
                <MembershipFormView
                  module={module}
                  onResetConfiguration={handleResetConfiguration}
                  initialMembershipPackage={membershipPackage}
                />
              )}

              <LoadingBackdrop isLoading={isLoading} />
            </Box>
          </form>
        </ScrollBox>

        {!!configurableService && (
          <QuestionnaireFirstPaymentModal
            isOpen={!!configurableService}
            onClose={handleCloseFirstPaymentModal}
            onSubmit={handleSubmitFirstPaymentModal}
          />
        )}

        {!!renderFooter &&
          renderFooter(onBack, handleSubmit(handleFormSubmit || onNext), isSubmitting)}
      </FormProvider>

      {dcContext && isDirty ? <DiscardChangesModalProvider {...dcContext} /> : null}
    </>
  );
};

export default TrialMembershipStep;
