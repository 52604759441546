import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Grid, Typography } from '@material-ui/core';
import { MultipleSelect } from 'common/components';
import { ICameFromIndicatorDictionaryItemImt } from 'common/interfaces/dictionary';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import messages from 'common/components/Steps/MemberPrimaryInfoStep/messages';
import inputLabels from 'common/messages/inputLabels';
import CameFromFields from 'common/components/ReactHookForm/CameFromFields/CameFromFields';
import { INamedEntity, INamedEntityImt } from 'common/interfaces/common';
import { IImmutablePrimaryMemberInfo } from 'common/components/PersonProfile/interfaces';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import { RequiredProfileInfo } from 'modules/corporate-settings/constants/requiredFields';

interface ISalesInfoSection {
  primaryInfo?: IImmutablePrimaryMemberInfo;
  employees: INamedEntity<number>[];
  cameFromIndicators?: ImmutableList<ICameFromIndicatorDictionaryItemImt>;
  persons: ImmutableList<INamedEntityImt>;
  getReferralMembersByValue: (search: string) => void;
  clearSearchResults: () => void;
  isPersonSearchLoading: boolean;
  recommendedFields: TShortProfileFieldInfoImt;
}

const SalesInfoSection: FC<ISalesInfoSection> = ({
  employees,
  cameFromIndicators,
  clearSearchResults,
  getReferralMembersByValue,
  persons,
  isPersonSearchLoading,
  recommendedFields,
  primaryInfo,
}) => {
  const renderIntlMessage = useRenderIntlMessage();
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="button" color="textSecondary">
          <FormattedMessage {...messages.newMemberPrimaryInfoSalesInfo} />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="salesperson"
          control={control}
          render={({ field }) => (
            <MultipleSelect
              fullWidth
              label={<FormattedMessage {...inputLabels.salesperson} />}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!errors.salesperson}
              helperText={renderIntlMessage(errors?.salesperson?.message)}
              recommended={Boolean(recommendedFields?.get(RequiredProfileInfo.Salesperson))}
              options={employees.map(item => ({
                id: item.id.toString(),
                title: item.title,
              }))}
            />
          )}
        />
      </Grid>

      <CameFromFields
        isPersonSearchLoading={isPersonSearchLoading}
        clearSearchResults={clearSearchResults}
        getReferralMembersByValue={getReferralMembersByValue}
        persons={persons}
        cameFromIndicators={cameFromIndicators}
        cameFromIndicator={primaryInfo?.get('cameFromIndicator')}
        referralMember={primaryInfo?.get('referralMember')}
      />
    </Grid>
  );
};

export default React.memo(SalesInfoSection);
