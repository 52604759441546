import { createAction } from '@reduxjs/toolkit';
import { actionTypes } from './constants';
import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { GeneralThunkAction } from 'common/state/interfaces';
import { IPersonDocuments } from 'common/components/PersonProfile/interfaces';
import { v4 as uuid } from 'uuid';

const fetchPersonDocumentsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PERSON_DOCUMENTS_LOADING,
);

const fetchPersonDocumentsAction = createAction<IPersonDocuments>(
  actionTypes.FETCH_PERSON_DOCUMENTS,
);

export const resetPersonDocuments = createAction<any>(actionTypes.RESET_PERSON_DOCUMENTS);

export const fetchPersonDocuments = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonDocumentsLoadingAction(true));

    try {
      const documents = await Services.MemberPortalProfile.getPersonDocuments();

      const transformedDocuments = documents && {
        ...documents,
        documentGroups: documents?.documentGroups.map(item => {
          return {
            id: uuid(),
            ...item,
          };
        }),
      };

      dispatch(fetchPersonDocumentsAction(transformedDocuments));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPersonDocumentsLoadingAction(false));
    }
  };
};
