import { IPaginatedData } from 'common/interfaces/pagination';
import { ITableParams } from 'common/interfaces/table';
import { ImmutableObject } from 'common/state/interfaces';
import { DictionariesTypes } from 'modules/dictionaries/constants/constants';
import { AlertCondition } from 'common/interfaces/alerts';
import { IAttachment } from 'common/interfaces/uploadFile';
import { ICameFromIndicatorShortDto } from 'common/interfaces/cameFrom';

export interface IDictionaryCommonItem {
  id?: string;
  title: string;
  active?: boolean;
}

export interface IDictionaryRequestParams extends ITableParams {
  dictionaryType?: DictionariesTypes;
}

export type IDictionaryCommonItemImt = ImmutableObject<IDictionaryCommonItem>;

export type IPaginatedDictionaries = IPaginatedData<IDictionaryCommonItem>;

export interface ITagItem {
  id?: string;
  name: string;
  active?: boolean;
}

export interface IAlertSoundItem {
  condition: AlertCondition;
  id: string;
  title: string;
  storedFileDto: IAttachment;
}

export type IAlertSoundItemImt = ImmutableObject<IAlertSoundItem>;

export type IPaginatedAlertSounds = IPaginatedData<IAlertSoundItem>;

export interface ICameFromIndicationState {
  tableParams: ITableParams;
  showChangeStatusDialog: boolean;
  showEditDialog: boolean;
  selectedRow?: ICameFromIndicatorShortDto | null;
}

export interface IDictionaryReasonItem extends IDictionaryCommonItem {
  code: string;
}

export type IDictionaryReasonItemImt = ImmutableObject<IDictionaryReasonItem>;

export enum EndReasonActionTypes {
  ZIP_CODE = 'ZIP_CODE',
  MD_LICENSE = 'MD_LICENSE',
  NOTE_CODE = 'NOTE_CODE',
}
export interface IEndReasonItem extends IDictionaryReasonItem {
  actionType: EndReasonActionTypes;
}

export type IEndReasonItemImt = ImmutableObject<IEndReasonItem>;

export interface IEndReasonFormItem {
  actionType: EndReasonActionTypes | null;
  title: string;
  active: boolean;
  code: string;
}
