import React from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import isNumber from 'lodash/isNumber';
import { selectKidZoneStatus } from 'modules/kid-zone/state/selectors';

import useComponentUpdate from 'common/hooks/useComponentUpdate';
import useTimeFromCheckIn from 'modules/kid-zone/hooks/useTimeFromCheckIn';

import { IKidZoneStatusImt } from 'modules/kid-zone/interfaces';

import { ReactComponent as ClockIcon } from 'img/icons/clock.svg';
import { ICheckInImt } from 'modules/front-desk/interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clockIcon: {
      marginLeft: theme.spacing(0.5),
    },
  }),
);

interface IProps {
  checkIn: ICheckInImt;
}

const WARNING_PERCENTAGE = 0.8;

const TimeFromCheckIn = ({ checkIn }: IProps): JSX.Element => {
  const kidZoneStatus: IKidZoneStatusImt = useRootSelector(selectKidZoneStatus);

  useComponentUpdate();

  const classes = useStyles();

  const kidZoneDuration = kidZoneStatus.get('maxDuration');

  const { elapsedTimeLabel, minutes: minutesFromCheckIn } = useTimeFromCheckIn(
    checkIn.get('checkInTime'),
  );

  const maxDuration = checkIn.getIn(['serviceLimitation', 'limited'])
    ? Math.max(kidZoneDuration, checkIn.getIn(['serviceLimitation', 'leftNumber']))
    : kidZoneDuration;

  const getClockColor = (): string => {
    if (isNumber(minutesFromCheckIn) && minutesFromCheckIn > maxDuration) {
      return 'error.main';
    }

    if (
      isNumber(minutesFromCheckIn) &&
      minutesFromCheckIn / maxDuration > WARNING_PERCENTAGE &&
      minutesFromCheckIn < maxDuration
    ) {
      return 'warning.main';
    }

    return 'darkBackground.light';
  };

  return (
    <Box display="flex" alignItems="center" color={getClockColor()}>
      <Typography color="inherit">{elapsedTimeLabel}</Typography>

      <ClockIcon className={classes.clockIcon} />
    </Box>
  );
};

export default TimeFromCheckIn;
