import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { AlertCondition } from 'common/interfaces/alerts';
import { ICustomerEmergencyContactDto } from 'common/interfaces/additionalInfo';
import { getEmergencyContactValidationSchema } from 'common/validationSchemas/emergencyContactValidationSchema';
import { DialogComponent } from 'common/components';
import EmergencySection from 'common/components/Steps/AdditionalInfoStep/EmergencySection/EmergencySection';
import messages from 'common/components/Steps/AdditionalInfoStep/messages';
import useRootSelector from 'common/hooks/useRootSelector';
import { IProfileInfoImt } from 'common/components/PersonProfile/interfaces';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { useAppDispatch } from 'store/hooks';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import { useGetEmergencyContact } from 'modules/members/api/member.api';

interface IProps {
  alert: AlertCondition | null;
  isOpen: boolean;
  onSubmit: (info: ICustomerEmergencyContactDto, alertId: string | null) => void;
  switchingModal: (alertCondition: AlertCondition | null) => void;
  profile: IProfileInfoImt;
}

const EmergencyContactModal = ({
  isOpen,
  switchingModal,
  onSubmit,
  alert,
  profile,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const profileRequiredFields = useRootSelector(selectProfileRequiredFields);
  const isSubmitted = useRootSelector(selectIsSubmittedRecommendedFieldsModal);
  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();
  const { data: emergencyContact } = useGetEmergencyContact(String(profile.get('id')));

  const formMethods = useForm<any>({
    defaultValues: {
      emergencyName: '',
      emergencyPhone: '',
      emergencyRelationship: '',
    },
    resolver: yupResolver(getEmergencyContactValidationSchema(profileRequiredFields)),
    mode: 'onBlur',
  });

  const { handleSubmit, getValues, reset } = formMethods;

  useEffect(() => {
    if (emergencyContact) {
      reset({
        emergencyName: emergencyContact.emergencyName || '',
        emergencyPhone: emergencyContact.emergencyPhone || '',
        emergencyRelationship: emergencyContact.emergencyRelationship || '',
      });
    }
  }, [reset, emergencyContact]);

  useEffect(() => {
    if (isSubmitted) {
      const values = getValues();

      onSubmit(values, alert);
      switchingModal(alert);
      dispatch(showRecommendedFieldsModal(false));
    }
  }, [alert, dispatch, getValues, isSubmitted, onSubmit, switchingModal]);

  const onSubmitForm = (values: ICustomerEmergencyContactDto): void => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty({
      emergencyContactDto: values,
    });

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(values, alert);
      switchingModal(alert);
    }
  };

  return (
    <DialogComponent
      size="sm"
      title={<FormattedMessage {...messages.newMemberAdditionalInfoEmergency} />}
      isOpen={isOpen}
      onClose={() => switchingModal(alert)}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <FormProvider {...formMethods}>
        <EmergencySection recommendedFields={profileRecommendedFields} />
      </FormProvider>
    </DialogComponent>
  );
};

export default EmergencyContactModal;
