import * as yup from 'yup';
import { getRequiredErrorMessage } from 'common/utils/validation';

export const SMSValidationSchema = yup.object().shape({
  targetPersonPhone: yup
    .object()
    .nullable()
    .required(getRequiredErrorMessage),
  text: yup.string().required(getRequiredErrorMessage),
});
