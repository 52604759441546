import React, { FC, useCallback, useEffect, useState } from 'react';
import { List as ImmutableList } from 'immutable';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { IScriptListItemImt } from 'modules/crm/interfaces/scripts';
import { IFilterSettings } from 'common/interfaces/filter';
import {
  IBodyCell,
  IHeadCell,
  IMultipleSelectData,
  ITableParams,
  ITableRow,
} from 'common/interfaces/table';
import { ISuccessResponse } from 'common/interfaces/http';

import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
import { ReactComponent as EditIcon } from 'img/icons/edit.svg';
import { ReactComponent as XDeleteIcon } from 'img/icons/x-bold_deprecated.svg';

import { PeakModules } from 'common/constants/peakModules';

import { Table, StatusLabel, ActionsMenu, ActionItem } from 'common/components';
import SmallScreenCell from './SmallScreenCell/SmallScreenCell';
import ChangeScriptStatusModal from './ChangeScriptStatusModal/ChangeScriptStatusModal';
import useMultipleSelectTableControl from 'common/hooks/useMultipleSelectTableControl';

import menuItems from 'common/messages/menuItems';
import scriptsMessages from 'modules/crm/messages/scripts';
import ptCrmMessages from 'modules/personal-training-crm/messages/messages';
import tableHeaders from 'common/messages/tableHeaders';
import commonMessages from 'common/messages/messages';
import usePermissionContext from 'common/hooks/context/usePermissionContext';

interface IProps {
  items: ImmutableList<IScriptListItemImt>;
  mainTableParams: ITableParams;
  tableFilterSettings: IFilterSettings[];
  totalRows: number;
  isLoading: boolean;
  changeScriptStatusResult: ISuccessResponse;
  onChangeParams: (tableParams: ITableParams) => void;
  changeScriptStatus: (data: IMultipleSelectData, isActive: boolean) => void;
  resetChangeScriptStatusResult: () => void;
  module: PeakModules.Crm | PeakModules.PersonalTrainingCrm;
}

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
});

const ScriptsTable: FC<IProps> = ({
  isLoading,
  items,
  totalRows,
  mainTableParams,
  tableFilterSettings,
  changeScriptStatusResult,
  onChangeParams,
  changeScriptStatus,
  resetChangeScriptStatusResult,
  module,
}) => {
  const [selectedScript, setSelectedScript] = useState<Record<string, any> | null>(null);
  const [scriptNewStatus, setScriptNewStatus] = useState<boolean>(false);
  const [selectedScriptsMultipleSelectData, setSelectedScriptsMultiSelectData] = useState(null);
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isCrmModule = module === PeakModules.Crm;
  const isPTCrmModule = module === PeakModules.PersonalTrainingCrm;
  let addBtnRedirect: string | null = null;
  let hasCreatePermission = false;
  let hasEditPermission = false;
  let hasChangeStatusPermission = false;

  const {
    CRM_SCRIPT_CREATE,
    CRM_SCRIPT_EDIT,
    CRM_SCRIPT_CHANGE_STATUS,
    PT_CRM_SCRIPT_CREATE,
    PT_CRM_SCRIPT_EDIT,
    PT_CRM_SCRIPT_CHANGE_STATUS,
  } = usePermissionContext();

  if (isCrmModule) {
    hasCreatePermission = CRM_SCRIPT_CREATE;
    hasEditPermission = CRM_SCRIPT_EDIT;
    hasChangeStatusPermission = CRM_SCRIPT_CHANGE_STATUS;
  } else if (isPTCrmModule) {
    hasCreatePermission = PT_CRM_SCRIPT_CREATE;
    hasEditPermission = PT_CRM_SCRIPT_EDIT;
    hasChangeStatusPermission = PT_CRM_SCRIPT_CHANGE_STATUS;
  }

  if (hasCreatePermission) {
    addBtnRedirect = `/${location.pathname.split('/')[1]}/scripts/new`;
  }

  const hasActions = hasEditPermission || hasChangeStatusPermission;
  const headerOptions: IHeadCell[] = [
    { id: 'name', label: <FormattedMessage {...tableHeaders.name} />, sort: true, padding: 'none' },
    { id: 'active', label: <FormattedMessage {...tableHeaders.status} />, sort: true },
    { id: 'in_use', label: <FormattedMessage {...tableHeaders.inUse} />, sort: true },
  ];

  if (hasActions) {
    headerOptions.push({
      id: 'actions',
      label: '',
      sort: false,
      padding: 'none',
      align: 'center',
    });
  }

  const onCloseModal = () => {
    setSelectedScript(null);
    setSelectedScriptsMultiSelectData(null);
    setIsChangeStatusModalOpen(false);
  };

  const { ref, tableParams } = useMultipleSelectTableControl({
    deleteResult: changeScriptStatusResult,
    closeDeleteModal: onCloseModal,
  });

  const classes = useStyles();

  useEffect(() => {
    if (changeScriptStatusResult.success) {
      resetChangeScriptStatusResult();
      setScriptNewStatus(false);
    }
  }, [changeScriptStatusResult, resetChangeScriptStatusResult]);

  const handleEditClick = useCallback(
    (resourceId: string) => {
      navigate(`/${location.pathname.split('/')[1]}/scripts/${resourceId}/edit`);
    },
    [location.pathname, navigate],
  );

  const handleActivateClick = (selected, options?) => {
    setSelectedScriptsMultiSelectData({
      ...options,
      includedIds: selected,
      tableParams,
    });
    setIsChangeStatusModalOpen(true);
    setScriptNewStatus(true);
  };
  const handleDeactivateClick = (selected, options?) => {
    setSelectedScriptsMultiSelectData({
      ...options,
      includedIds: selected,
      tableParams,
    });
    setIsChangeStatusModalOpen(true);
    setScriptNewStatus(false);
  };

  const onChangeScriptStatus = () => {
    if (selectedScript) {
      changeScriptStatus({ includedIds: [selectedScript.id] }, scriptNewStatus);
    } else {
      if (selectedScriptsMultipleSelectData) {
        changeScriptStatus(selectedScriptsMultipleSelectData, scriptNewStatus);
      }
    }
  };

  const multipleSelectActions = [
    {
      id: 'activate',
      tooltip: <FormattedMessage {...menuItems.activate} />,
      className: classes.icon,
      ButtonIcon: CheckIcon,
      onClick: handleActivateClick,
    },
    {
      id: 'deactivate',
      tooltip: <FormattedMessage {...menuItems.deactivate} />,
      className: classes.icon,
      ButtonIcon: XDeleteIcon,
      onClick: handleDeactivateClick,
    },
  ];

  const getEditActionItem = (script: IScriptListItemImt): JSX.Element => {
    return (
      <ActionItem
        key="1"
        icon={<EditIcon className={classes.icon} />}
        onClick={() => handleEditClick(String(script.get('id')))}
      >
        <FormattedMessage {...menuItems.edit} />
      </ActionItem>
    );
  };

  const getChangeStatusActionItem = (script: IScriptListItemImt): JSX.Element => {
    return (
      <ActionItem
        key="2"
        icon={
          script.get('active') ? (
            <XDeleteIcon className={classes.icon} />
          ) : (
            <CheckIcon className={classes.icon} />
          )
        }
        onClick={() => {
          setIsChangeStatusModalOpen(true);
          setScriptNewStatus(!script.get('active'));
          setSelectedScript({ name: script.get('title'), id: script.get('id') });
        }}
      >
        <Typography>
          {script.get('active') ? (
            <FormattedMessage {...menuItems.deactivate} />
          ) : (
            <FormattedMessage {...menuItems.activate} />
          )}
        </Typography>
      </ActionItem>
    );
  };

  const rows: ITableRow[] = items
    .map(
      (script: IScriptListItemImt): ITableRow => {
        const bodyCells: IBodyCell[] = [
          {
            label: script.get('title'),
            className: 'body-cell',
            variant: 'subtitle2',
          },
          {
            label: 'status',
            className: 'body-cell',
            cellComponent: <StatusLabel isActive={script.get('active')} />,
          },
          {
            label: '-',
            className: 'body-cell',
            cellComponent: (
              <StatusLabel
                isActive={script.get('inUse')}
                activeOptionMessage={commonMessages.yesOption}
                inactiveOptionMessage={commonMessages.noOption}
              />
            ),
          },
        ];

        const bodyCellsMini: IBodyCell[] = [
          {
            label: '-',
            cellComponent: (
              <SmallScreenCell
                name={script.get('title')}
                isActive={script.get('active')}
                inUse={script.get('inUse')}
              />
            ),
          },
        ];

        if (hasActions) {
          bodyCells.push({
            label: 'actions',
            className: 'body-actions-cell',
            cellComponent: (
              <ActionsMenu horizontal tableActionsMode>
                {hasEditPermission ? getEditActionItem(script) : null}
                {hasChangeStatusPermission ? getChangeStatusActionItem(script) : null}
              </ActionsMenu>
            ),
          });

          bodyCellsMini.push({
            label: '-',
            align: 'center',
            padding: 'none',
            width: '54px',
            cellComponent: (
              <ActionsMenu horizontal tableActionsMode>
                {hasEditPermission ? getEditActionItem(script) : null}
                {hasChangeStatusPermission ? getChangeStatusActionItem(script) : null}
              </ActionsMenu>
            ),
          });
        }

        return {
          id: script.get('id'),
          cells: bodyCells,
          cellsMini: bodyCellsMini,
        };
      },
    )
    .toJS();

  return (
    <>
      <Table
        title={
          <FormattedMessage
            {...(isCrmModule ? scriptsMessages.scripts : ptCrmMessages.ptScripts)}
          />
        }
        backRedirectLink={`/${location.pathname.split('/')[1]}`}
        addButtonRedirect={addBtnRedirect || undefined}
        headerOptions={headerOptions}
        showPerPageSelect
        isLoading={isLoading}
        rows={rows}
        filters={tableFilterSettings}
        totalRows={totalRows}
        onChangeParams={onChangeParams}
        tableParams={mainTableParams}
        multipleSelectActions={multipleSelectActions}
        activeSelect
        activeSelectBodyClassName="body-checkbox-cell"
        refMultipleSelectParams={ref}
      />
      <ChangeScriptStatusModal
        isLoading={isLoading}
        scriptTitle={selectedScript?.name}
        scriptNewStatus={scriptNewStatus}
        isOpen={isChangeStatusModalOpen}
        onClose={onCloseModal}
        onSubmit={onChangeScriptStatus}
      />
    </>
  );
};

export default ScriptsTable;
