import React, {
  Dispatch,
  SetStateAction,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  clearSigWebTablet,
  closeSigWebTablet,
  setSignatureConfig,
  startSigWebTablet,
  getFieldName,
} from 'helpers';
import { formatBase64ToFile, generateFileName } from 'common/utils';
import { SIGNATURE_Y_SIZE } from 'common/components/Steps/SignDocumentStep/constants';
import { ServerError } from 'common/errors/serverErrors';
import { SignType } from 'common/constants/contract';
import useWindowWidth from 'common/hooks/useWindowWidth';
import {
  ButtonsControl,
  SigningTypeSelector,
  ChosenSignatureField,
  Contract,
  ContractStatus,
} from 'common/components/Steps/SignDocumentStep/components';
import { Button, TooltipTypography } from 'common/components';
import { ReactComponent as PrinterIcon } from 'img/icons/printer_deprecated.svg';
import commonMessages from 'common/messages/messages';
import { CustomTheme } from 'common/ui/interfaces';
import { IContactInfo, IPersonDocumentItemDto } from 'common/interfaces/contract';
import { IAttachment } from 'common/interfaces/uploadFile';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { StepContext } from 'common/createContext/stepContext';
import { WaiverStatus } from 'modules/crm/constants/leads';

const useStyles = makeStyles((theme: CustomTheme) => ({
  contractStep: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  contractHeader: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    marginBottom: '10px',
  },
  contractHeaderLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    flex: 1,
  },
  contractHeaderRight: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '& button': {
      width: '24px',
      height: '24px',
      padding: '4px',
      '& > span > span': {
        margin: '0 !important',
      },
      '& svg': {
        width: '20px',
        height: '20px',
        fontSize: '20px !important',
      },
    },
  },
  controlBlock: {
    padding: theme.spacing(3, 0, 1.5),
  },
  contractTitle: {
    display: 'inline-block',
  },
  signViaSMS: {
    marginBottom: theme.spacing(2.875),
  },
  signInPerson: {
    display: 'contents',
  },
  signWrapper: {
    position: 'relative',
  },
  footer: {
    '&>div': {
      padding: theme.spacing(2, 0),
    },
  },
}));

export interface ISignDocumentStep {
  isSignedDocument: boolean;
  setIsSignedDocumentData: Dispatch<SetStateAction<Record<string, boolean>>>;
  contract: Partial<IPersonDocumentItemDto>;
  contactInfo: Partial<IContactInfo>;
  isLoading?: boolean;
  fetchApplySignature: (file: File, documentId: string) => void;
  onPrint?: () => void;
  hasHiddenFooter?: boolean;
  hasHiddenHeader?: boolean;
  onApplyTopazIsSigned?: (isSigned: boolean) => void;
  additionalFieldName?: string;
  extraClassName?: string;
  title?: string;
  canvasWidthOffset: number;
}

const SignDocumentStep: FC<ISignDocumentStep> = ({
  contract,
  contactInfo,
  fetchApplySignature,
  hasHiddenFooter,
  hasHiddenHeader,
  isSignedDocument,
  setIsSignedDocumentData,
  additionalFieldName = '',
  extraClassName,
  onPrint,
  title,
  isLoading = false,
  canvasWidthOffset,
}) => {
  const { documentText, code, signature, documentId } = contract;
  const { phones = [] } = contactInfo;
  const dispatch = useDispatch();

  const [widthSigTopaz, setWidthSigTopaz] = useState<number>(0);
  const [isConnectedTopaz, setIsConnectedTopaz] = useState(false);
  const [captureImgBase64, setCaptureImgBase64] = useState<string>();
  const [isSigWebLoading, setIsSigWebLoading] = useState(false);
  const [canClearSignature, setCanClearSignature] = useState(false);

  const ref = useRef<HTMLElement>();
  const { onBack, renderFooter } = useContext(StepContext);
  const width = useWindowWidth();
  const classes = useStyles();
  const needToSign = contract.status !== WaiverStatus.NO_SIGN;

  useEffect(() => {
    const { clientWidth = 0 } = ref.current || {};
    const widthSignatureTopaz = clientWidth - canvasWidthOffset;

    setWidthSigTopaz(widthSignatureTopaz);

    const config = {
      displayXSize: clientWidth,
      displayYSize: SIGNATURE_Y_SIZE,
    };

    setSignatureConfig({ config });
  }, [width, canvasWidthOffset]);

  const setIsSignedData = useCallback(
    (isSigned: boolean) => {
      return (prevState: Record<string, boolean>) => {
        if (Boolean(prevState[documentId || '']) !== isSigned) {
          return {
            ...prevState,
            [documentId || '']: isSigned,
          };
        }

        return prevState;
      };
    },
    [documentId],
  );

  useEffect(() => {
    if (signature?.id) {
      setIsSignedDocumentData(setIsSignedData(true));
    }
  }, [signature, setIsSignedDocumentData, documentId, setIsSignedData]);

  const { control } = useFormContext();
  const signTypeFieldName = getFieldName('signType', additionalFieldName);
  const signInPersonFieldName = getFieldName('files', additionalFieldName);

  const signType: SignType = useWatch({
    control,
    name: signTypeFieldName,
  });

  const files: IAttachment[] | undefined = useWatch({
    control,
    name: signInPersonFieldName,
  });

  const isTopaz = SignType.ViaTopaz === signType;
  const isSignContractLoading = isTopaz ? isSignedDocument && isLoading : isLoading;

  useEffect(() => {
    if (files?.length) {
      if (signType === SignType.InPerson && !isSignedDocument) {
        setIsSignedDocumentData(setIsSignedData(true));
      }
    } else if (signType === SignType.InPerson) {
      setIsSignedDocumentData(setIsSignedData(false));
    }

    if (signType === SignType.SendViaSMS || signType === SignType.SendViaEmail) {
      setIsSignedDocumentData(setIsSignedData(true));
    }

    if (signType === SignType.ViaTopaz) {
      setIsSignedDocumentData(setIsSignedData(false));
    }
  }, [files, isSignedDocument, setIsSignedDocumentData, signType, documentId, setIsSignedData]);

  const applySignDocument = () => {
    if (captureImgBase64) {
      const url = `data:image/png;base64,${captureImgBase64}`;
      const file = formatBase64ToFile(url, generateFileName('image/png'));

      setCanClearSignature(false);
      fetchApplySignature(file, documentId || '');
    }
  };

  const clearSignature = () => {
    setIsSigWebLoading(true);
    clearSigWebTablet()
      .catch(() => {
        dispatch(
          enqueueErrorNotification({
            codes: [ServerError.UNABLE_COMMUNICATE_SIGWEB],
            message: undefined,
          }),
        );
      })
      .finally(() => {
        setIsSigWebLoading(false);
        setCanClearSignature(true);
        setCaptureImgBase64(undefined);
        setIsSignedDocumentData(setIsSignedData(false));
      });
  };

  const cancelTopazConnection = () => {
    setIsSigWebLoading(true);
    closeSigWebTablet()
      .catch(() => {
        dispatch(
          enqueueErrorNotification({
            codes: [ServerError.UNABLE_COMMUNICATE_SIGWEB],
            message: undefined,
          }),
        );
      })
      .finally(() => {
        setIsConnectedTopaz(false);
        setIsSigWebLoading(false);
        setCanClearSignature(true);
        setCaptureImgBase64(undefined);
        setIsSignedDocumentData(setIsSignedData(false));
      });
  };

  const onStartSigning = () => {
    setIsSigWebLoading(true);
    startSigWebTablet({ setSigCaptured: setCaptureImgBase64 })
      .then(() => {
        setIsConnectedTopaz(true);
      })
      .catch(e => {
        dispatch(
          e.codes
            ? enqueueErrorNotification(e)
            : enqueueErrorNotification({
                codes: [ServerError.UNABLE_COMMUNICATE_SIGWEB],
                message: undefined,
              }),
        );
      })
      .finally(() => {
        setIsSigWebLoading(false);
      });
  };

  return (
    <Box className={classNames('contractStep', classes.contractStep, extraClassName)} {...{ ref }}>
      {hasHiddenHeader || (
        <div className={classes.contractHeader}>
          <div className={classes.contractHeaderLeft}>
            <TooltipTypography
              className={classes.contractTitle}
              ellipsized
              color="textPrimary"
              variant="button"
            >
              {title || <FormattedMessage {...commonMessages.membership} />}
            </TooltipTypography>

            <Typography variant="button" color="textSecondary">
              #{code}
            </Typography>
          </div>

          <div className={classes.contractHeaderRight}>
            <ContractStatus status={contract.status} />

            <Button
              type="button"
              variant="text"
              size="small"
              startIcon={<PrinterIcon />}
              onClick={onPrint}
              color="primary"
            />
          </div>
        </div>
      )}

      <Contract
        signatureId={signature?.id}
        canClearSignature={canClearSignature}
        documentText={documentText}
      />

      {needToSign && (
        <Grid
          className={classes.controlBlock}
          container
          item
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <SigningTypeSelector fieldName={signTypeFieldName} />

          {isConnectedTopaz && (
            <ButtonsControl
              isLoading={isSigWebLoading}
              isClearBtnDisabled={!canClearSignature}
              isSignedDocument={isSignedDocument}
              applySignDocument={applySignDocument}
              clearSignature={clearSignature}
              cancelTopazConnection={cancelTopazConnection}
            />
          )}
        </Grid>
      )}

      {needToSign && (
        <Grid
          item
          className={classNames(classes.signWrapper, {
            [classes.signViaSMS]: signType === SignType.SendViaSMS,
            [classes.signInPerson]: signType === SignType.InPerson,
          })}
        >
          <ChosenSignatureField
            additionalFieldName={additionalFieldName}
            contractSignType={signType}
            isConnectedTopaz={isConnectedTopaz}
            isSigWebLoading={isSigWebLoading}
            onStartSigning={onStartSigning}
            widthSigTopaz={widthSigTopaz}
            phones={phones}
            documentId={contract.documentId || ''}
          />
        </Grid>
      )}

      {hasHiddenFooter || (
        <Box className={classes.footer} position="relative" zIndex="6">
          {Boolean(renderFooter) &&
            renderFooter &&
            renderFooter(onBack, undefined, isSignContractLoading)}
        </Box>
      )}
    </Box>
  );
};

export default SignDocumentStep;
