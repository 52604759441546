import React from 'react';
import { useFormContext } from 'react-hook-form';
import { SignType } from 'common/constants/contract';
import { IPhoneInfo } from 'common/interfaces/contract';
import { SIGNATURE_Y_SIZE } from 'common/components/Steps/SignDocumentStep/constants';
import {
  SignViaSMSField,
  SignViaEmailField,
} from 'common/components/Steps/SignDocumentStep/components';
import { FormFilesToServerUploader, TopazSignature } from 'common/components';
import { getFieldName } from 'helpers';
import ESignature from 'common/components/ESignature/ESignature';

interface IChosenSignatureFieldProps {
  contractSignType: SignType;
  isSigWebLoading: boolean;
  widthSigTopaz: number;
  isConnectedTopaz: boolean;
  phones: IPhoneInfo[];
  onStartSigning: () => void;
  additionalFieldName: string;
  documentId: string;
}

const ChosenSignatureField = (props: IChosenSignatureFieldProps) => {
  const {
    contractSignType,
    isSigWebLoading,
    widthSigTopaz,
    onStartSigning,
    isConnectedTopaz,
    phones,
    additionalFieldName,
    documentId,
  } = props;

  const { control } = useFormContext();

  const signInPersonFieldName = getFieldName('files', additionalFieldName);
  const signViaSmsFieldName = getFieldName('phone', additionalFieldName);
  const signViaEmailFieldName = getFieldName('email', additionalFieldName);

  switch (contractSignType) {
    case SignType.ESignature:
      return <ESignature width={widthSigTopaz} height={SIGNATURE_Y_SIZE} documentId={documentId} />;
    case SignType.ViaTopaz:
      return (
        <TopazSignature
          isLoading={isSigWebLoading}
          isConnectedTopaz={isConnectedTopaz}
          width={widthSigTopaz}
          height={SIGNATURE_Y_SIZE}
          onStartSigning={onStartSigning}
        />
      );
    case SignType.InPerson:
      return <FormFilesToServerUploader fieldName={signInPersonFieldName} />;
    case SignType.SendViaSMS:
      return <SignViaSMSField fieldName={signViaSmsFieldName} phones={phones} control={control} />;
    case SignType.SendViaEmail:
      return <SignViaEmailField fieldName={signViaEmailFieldName} control={control} />;
    default:
      return null;
  }
};

export default ChosenSignatureField;
