import { IFilter } from 'common/interfaces/filter';
import { ITableMultiSelectParams } from 'common/interfaces/table';
import { useState, useCallback, useEffect } from 'react';

interface IMultipleSelectProps {
  selected: Array<string>;
  excluded: Array<string>;
  numSelected: number;
  isAllSelected: boolean;
  setNumSelected: (values) => void;
  setSelected: (values) => void;
  setAllSelected: (selectedTotal: number) => void;
  handleSelectClick: (selectedIndex: string) => void;
  updateSelected: (newSelected: Array<string>) => void;
  resetSelected: () => void;
  isSelected: (itemKey: string) => boolean;
  isExcluded: (itemKey: string) => boolean;
}

export interface ISelectedData {
  selectedIds: string[];
  excludedIds: string[];
  tableParams: ITableMultiSelectParams;
  isAllSelected: boolean;
}

interface IMultipleSelectInput {
  initialSelected?: string[];
  isRadioButtonMode: boolean;
  search: string;
  perPage: number;
  page: number;
  sortBy: string;
  sortDirection: string;
  filters: IFilter[];
  disabledSelectionsTotal: number;
  onSelect?: (data: ISelectedData) => void;
}

const useMultipleSelect = (props: IMultipleSelectInput): IMultipleSelectProps => {
  const {
    search,
    perPage,
    page,
    sortBy,
    sortDirection,
    filters,
    disabledSelectionsTotal,
    isRadioButtonMode,
  } = props;

  const initialSelected = props?.initialSelected || [];
  const onSelect = props?.onSelect || null;

  const [selected, setSelected] = useState<Array<string>>(initialSelected);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [numSelected, setNumSelected] = useState(0);
  const [excluded, setExcluded] = useState<string[]>([]);

  useEffect(() => {
    if (onSelect)
      onSelect({
        selectedIds: selected,
        excludedIds: excluded,
        tableParams: {
          searchStr: search,
          perPage,
          page,
          sortBy,
          sortDirection,
          tableFilters: filters,
        },
        isAllSelected,
      });
  }, [
    onSelect,
    selected,
    excluded,
    search,
    perPage,
    page,
    sortBy,
    sortDirection,
    filters,
    isAllSelected,
  ]);

  const handleSelectClick = useCallback(
    (selectedKey: string) => {
      const array = isAllSelected ? excluded : selected;
      const elementIndex = array.indexOf(selectedKey);
      let newSelected: string[] = [];

      if (elementIndex === -1) {
        if (isRadioButtonMode) {
          newSelected = [selectedKey];
        } else {
          newSelected = newSelected.concat(array, selectedKey);
        }
      } else if (elementIndex === 0) {
        newSelected = newSelected.concat(array.slice(1));
      } else if (elementIndex === array.length - 1 && !isRadioButtonMode) {
        newSelected = newSelected.concat(array.slice(0, -1));
      } else if (elementIndex > 0 && !isRadioButtonMode) {
        newSelected = newSelected.concat(
          array.slice(0, elementIndex),
          array.slice(elementIndex + 1),
        );
      }

      if (isAllSelected) {
        setExcluded(newSelected);
        setNumSelected(totalRows - newSelected.length);
      } else {
        setSelected(newSelected);
        setNumSelected(newSelected.length);
      }
    },
    [isAllSelected, excluded, selected, isRadioButtonMode, totalRows],
  );

  const updateSelected = useCallback((newSelected: Array<string>) => setSelected(newSelected), [
    setSelected,
  ]);

  const resetSelected = useCallback(() => setSelected([]), [setSelected]);

  const setAllSelected = useCallback(
    selectedTotal => {
      const selectedRowsTotal =
        selectedTotal > 0 ? selectedTotal - disabledSelectionsTotal : selectedTotal;
      setIsAllSelected(Boolean(selectedRowsTotal));
      setTotalRows(selectedRowsTotal);
      setNumSelected(selectedRowsTotal);
      setExcluded([]);
    },
    [setIsAllSelected, setTotalRows, setNumSelected, setExcluded, disabledSelectionsTotal],
  );

  const isSelected = useCallback((itemKey: string) => selected.indexOf(itemKey) !== -1, [selected]);
  const isExcluded = useCallback((itemKey: string) => excluded.indexOf(itemKey) !== -1, [excluded]);

  return {
    selected,
    setSelected,
    numSelected,
    isAllSelected,
    excluded,
    handleSelectClick,
    setAllSelected,
    updateSelected,
    resetSelected,
    setNumSelected,
    isSelected,
    isExcluded,
  };
};

export default useMultipleSelect;
