import React, { FC, useMemo, useState, useCallback } from 'react';
import SignatureContext, {
  IDocumentSignature,
  ISignatureContext,
} from 'common/contexts/SignatureContext';

interface ISignatureContextProvider {
  children: JSX.Element;
}

const SignatureContextProvider: FC<ISignatureContextProvider> = ({ children }) => {
  const [documentSignatures, setDocumentSignatures] = useState<IDocumentSignature[]>([]);

  const addDocumentSignature = useCallback(
    (file: File, documentId: string): void => {
      const docSignatureIndex = documentSignatures.findIndex(
        item => item.documentId === documentId,
      );
      const newDocumentSignature: IDocumentSignature = { documentId, file };

      if (docSignatureIndex !== -1) {
        const newDocSignatures = [...documentSignatures];

        newDocSignatures.splice(docSignatureIndex, 0, newDocumentSignature);
        setDocumentSignatures(newDocSignatures);
      } else {
        setDocumentSignatures([...documentSignatures, newDocumentSignature]);
      }
    },
    [documentSignatures],
  );

  const contextValue = useMemo((): ISignatureContext => {
    return { documentSignatures, addDocumentSignature };
  }, [documentSignatures, addDocumentSignature]);

  return <SignatureContext.Provider value={contextValue}>{children}</SignatureContext.Provider>;
};

export default SignatureContextProvider;
