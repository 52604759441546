import {
  Box,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { bindHover, bindMenu } from 'material-ui-popup-state';
import FiltersCounter from '../../../../FiltersContainer/FiltersCounter/FiltersCounter';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import React, { useCallback, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { List as ImmutableList } from 'immutable';
import { INamedEntity, INamedEntityImt } from '../../../../../interfaces/common';
import useRootSelector from 'common/hooks/useRootSelector';
import { selectCurrentUserAvailableClubs } from '../../../../../../modules/authentication/state/selectors';
import { Checkbox, ScrollBox } from 'common/components';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../FiltersContainer/messages';
import ClearButton from '../../../../FiltersContainer/ClearFilterButton/ClearFilterButton';
import ApplyFilterButton from '../../../../FiltersContainer/ApplyFilterButton/ApplyFilterButton';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    minWidth: 95,
  },
  list: {
    padding: theme.spacing(0),
  },
  menuListItemIcon: {
    marginLeft: '-3px!important',
    marginRight: '11px!important',
    '& .MuiCheckbox-root': {
      width: 18,
      height: 18,
    },
  },
  root: {
    justifyContent: 'space-between',
    display: 'flex',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& p': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
  rootSelected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  chevronIcon: {
    color: theme.palette.text.primary,
  },
  selectedColor: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  activeColor: {
    color: 'white',
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: theme.spacing(-1),
  },
}));

interface ISearchGiftCardFilter {
  searchMenuAnchorEl: Element | null;
  handleCloseMenu: () => void;
  setAvailableInClubs: (clubIds: string[]) => void;
}

const SearchGiftCardFilter = ({
  searchMenuAnchorEl,
  handleCloseMenu,
  setAvailableInClubs,
}: ISearchGiftCardFilter) => {
  const classes = useStyles();

  const theme = useTheme();
  const isDialog = useMediaQuery(theme.breakpoints.down('xs'));

  const [selectedClubs, setSelectedClubs] = useState<any[]>([]);

  const locations: ImmutableList<INamedEntityImt> = useRootSelector(
    selectCurrentUserAvailableClubs,
  );

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `search-hover-menu`,
  });

  const { isOpen, close } = popupState;
  const selected = selectedClubs?.length > 0;

  const isOptionSelected = (option: INamedEntity): boolean =>
    selectedClubs.findIndex(opt => opt.id === option.id) !== -1;

  const handleOptionClick = (option: INamedEntity): void => {
    let newSelectedOptions: INamedEntity[];

    if (isOptionSelected(option)) {
      newSelectedOptions = selectedClubs.filter(selectedOption => selectedOption.id !== option.id);
    } else {
      newSelectedOptions = [...selectedClubs, option];
    }

    // Sort options to optimize compare in container
    newSelectedOptions.sort((a, b) => a.id.localeCompare(b.id));
    setSelectedClubs(newSelectedOptions);
  };

  const handleClearFilter = useCallback(() => {
    close();
    handleCloseMenu();
    setSelectedClubs([]);
    setAvailableInClubs([]);
  }, [close, handleCloseMenu, setAvailableInClubs]);

  const handleApplyFilter = () => {
    close();
    handleCloseMenu();
    setAvailableInClubs(selectedClubs?.map(sc => sc.id));
  };

  const title = 'Club';

  const renderFilterTitle = (): JSX.Element | string | React.ReactNode => {
    if (isDialog) {
      return title;
    }

    if (!selectedClubs || selectedClubs.length === 0) {
      return (
        <Typography noWrap component="span" color="inherit">
          <FormattedMessage {...messages.filterTitle} values={{ title, filter: 'All' }} />
        </Typography>
      );
    }

    if (selectedClubs.length === 1) {
      const selectedOption = locations
        .toJS()
        ?.find((option: INamedEntity) => option.id === selectedClubs[0].id);
      return (
        <Typography noWrap component="span" color="inherit">
          {selectedOption?.title || locations[0]?.title}
        </Typography>
      );
    }

    return title;
  };

  return (
    <Menu
      id="search-filter-menu"
      keepMounted
      anchorEl={searchMenuAnchorEl}
      getContentAnchorEl={null}
      open={!!searchMenuAnchorEl}
      onClose={handleCloseMenu}
      classes={{ paper: classes.paper, list: classes.list }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuList onKeyDown={e => e.stopPropagation()}>
        {/* TODO, create switch case for isDialog condition, display popup if it is not,
         and add Dialog logic to show it on small screens */}
        <div {...bindHover(popupState)}>
          <MenuItem
            disableRipple
            className={classnames('filter-list-button', classes.root, {
              [classes.rootSelected]: isOpen,
            })}
          >
            <Typography
              className={classnames({
                [classes.selectedColor]: selected,
                [classes.activeColor]: isOpen,
              })}
            >
              {renderFilterTitle()}
            </Typography>

            <ListItemIcon className={classes.iconsContainer}>
              <FiltersCounter count={selectedClubs?.length} inverseColor={isOpen} />
              <ChevronRightIcon
                fontSize="small"
                className={classnames(classes.chevronIcon, {
                  [classes.activeColor]: isOpen,
                })}
              />
            </ListItemIcon>
          </MenuItem>

          <HoverMenu
            {...bindMenu(popupState)}
            // className={hoverMenuClassName}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <ScrollBox suppressScrollX>
              {locations.toJS()?.map((option: INamedEntity) => (
                <MenuItem key={option.id} onClick={() => handleOptionClick(option)} disableRipple>
                  <ListItemIcon className={classes.menuListItemIcon}>
                    <Checkbox checked={isOptionSelected(option)} indeterminate={false} />
                  </ListItemIcon>
                  <Typography color="inherit">{option.title}</Typography>
                </MenuItem>
              ))}
            </ScrollBox>
            <Box p={1} display="flex" justifyContent="flex-end">
              <ClearButton onClick={handleClearFilter} small />
              <ApplyFilterButton onClick={handleApplyFilter} small />
            </Box>
          </HoverMenu>
        </div>
      </MenuList>
    </Menu>
  );
};

export default SearchGiftCardFilter;
