import React from 'react';
import {
  Menu,
  MenuItem as MenuItemComponent,
  ListItemIcon,
  IconButton,
  Typography,
  Box,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Edit, FileCopy, Delete } from '@material-ui/icons';
import { ReactComponent as FlipToFront } from 'img/icons/move-to.svg';

import { IRegisterProductItem } from 'modules/pos-settings/interfaces/register';
import { MenuItemTypes } from 'common/interfaces/invoices';

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    padding: '0 !important',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  iconButtonGutterTop: {
    marginTop: theme.spacing(1),
  },
  separatorLine: {
    borderTop: `1px solid ${theme.palette.secondary.light}`,
    paddingTop: `${theme.spacing(1)}px!important`,
    marginTop: theme.spacing(1),
  },
  moveToIcon: {
    width: 16,
    height: 'auto',
    margin: 0,
    maxHeight: 17,
  },
}));

interface IRegisterItemActionsMenuProps {
  product: IRegisterProductItem;
  type: MenuItemTypes;
  anchorEl: null | HTMLElement;
  setShowDialog: (value) => void;
  setAnchorEl: (value) => void;

  menuAlign?: 'left' | 'right';
  iconColor?: 'primary' | 'default';
}

const ActionsItemMenu = (props: IRegisterItemActionsMenuProps): JSX.Element => {
  const classes = useStyles();
  const {
    product,
    menuAlign = 'left',
    iconColor,
    type,
    setShowDialog,
    setAnchorEl,
    anchorEl,
  } = props;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = value => {
    setShowDialog({ type: value, data: product });
    handleClose();
  };

  return (
    <div>
      {/* TODO: Replace to ActionsMenu */}
      <IconButton
        size="small"
        disableRipple
        className={classes.iconButton}
        color={iconColor}
        aria-label="more"
        aria-controls="actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      {open && (
        <Menu
          id="actions-menu"
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: menuAlign,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: menuAlign,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {type === MenuItemTypes.Category && (
            <Box>
              <MenuItemComponent onClick={() => handleActionClick('editCategory')}>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                <Typography>Edit</Typography>
              </MenuItemComponent>
              <Box className={classes.separatorLine} />
              <MenuItemComponent onClick={() => handleActionClick('copyTo')}>
                <ListItemIcon>
                  <FileCopy fontSize="small" />
                </ListItemIcon>
                <Typography>Copy to</Typography>
              </MenuItemComponent>
              <MenuItemComponent onClick={() => handleActionClick('moveTo')}>
                <ListItemIcon>
                  <FlipToFront className={classes.moveToIcon} />
                </ListItemIcon>
                <Typography>Move to</Typography>
              </MenuItemComponent>
              <Box className={classes.separatorLine} />
            </Box>
          )}
          <MenuItemComponent onClick={() => handleActionClick('deleteItem')}>
            <ListItemIcon>
              <Delete fontSize="small" />
            </ListItemIcon>
            <Typography>Delete</Typography>
          </MenuItemComponent>
        </Menu>
      )}
    </div>
  );
};

export default ActionsItemMenu;
