import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { Message } from '../interfaces/common';

export enum ServerError {
  // Frontend errors
  SERVER_NOT_RESPONDING = '0',
  SOMETHING_WENT_WRONG = '1',
  SOMETHING_WENT_WRONG_ON_FRONT_END = '-1',
  DEVICE_CONNECTION_ERROR = '2',
  BROWSER_NOT_SUPPORT_HID_API = '3',
  NON_UPDATED_VERSION_SIGWEB = '4',
  UNABLE_COMMUNICATE_SIGWEB = '5',
  UNSIGNED_DOCUMENT_ERROR = '6',
  UNSIGNED_DOCUMENTS_ERROR = '7',
  NO_CORRESPONDING_SIGNATURE_PAD = '8',
  // Base error
  BAD_REQUEST = '400',
  NOT_FOUND = '404',
  // 400XXX
  REQUEST_VALIDATION_ERROR = '400001',
  PASSWORD_VALIDATION_ERROR = '400002',
  EMAIL_ALREADY_EXISTS = '400003',
  DATE_TIME_RANGE_VALIDATION_ERROR = '400004',
  PARAM_MISSING = '400005',
  TAX_TYPE_ERROR = '400006',
  TAG_ALREADY_EXISTS = '400007',
  SCRIPT_STEP_UNIQUE_INDEX = '400008',
  RESOURCE_TIME_AVAILABLE_VALIDATION_ERROR = '400009',
  RESOURCE_AVAIL_DATE_VALIDATION_ERROR = '400010',
  PHONE_TO_SEND_SMS_IS_NOT_VALID = '400011',
  STOMP_HEADER_MISSING = '400012',
  CORRUPTED_FILE = '400013',
  EVENT_TIME_RESTRICTION_EXPIRED = '400014',
  BULK_FILTER_IDS_VALIDATION_ERROR = '400015',
  POLICY_EMPTY_OR_CONTAIN_PERMISSIONS_FROM_DIFFERENT_GROUPS = '400016',
  UNABLE_TO_CHANGE_POLICY_TYPE = '400017',
  SECURITY_ROLE_SHOULD_CONTAIN_UNIQUE_GROUPS = '400018',
  CLUB_ID_MISSING = '400019',
  TAX_BUNDLE_OVERRIDE_TYPE_ERROR = '400020',
  TAX_RATE_VALUE_ERROR = '400021',
  PAYMENT_SPLITS_SUM_VALIDATION = '400022',
  MEMBERSHIP_ERROR = '400023',
  SECURITY_ROLE_SHOULD_CONTAIN_POLICIES_FROM_ONE_GROUP_TYPE = '400024',
  UNABLE_TO_CHANGE_ROLE_LEVEL = '400025',
  POLICY_LEVEL_TYPE_ERROR = '400026',
  ROLE_LEVEL_TYPE_ERROR = '400027',
  ROLE_ASSIGN_ERROR = '400028',
  ROLE_ASSIGN_CLUB_ROLES_COUNT_ERROR = '400029',
  DOCUMENT_TEMPLATE_TYPES_VALIDATION_ERROR = '400030',
  CORPORATION_EMAIL_SETTINGS_VALIDATION_ERROR = '400031',
  CORPORATION_SMS_SETTINGS_VALIDATION_ERROR = '400032',
  INITIAL_CLUB_ID_OR_INITIAL_MEMBER_ID_MISSING_ERROR = '400033',
  TAX_BUNDLE_ITEMS_TYPE_ERROR = '400034',
  CORPORATION_PAYEEZY_SETTINGS_VALIDATION_ERROR = '400035',
  CLUB_NOT_ALLOWED = '400036',
  CORPORATION_ZENDESK_SETTINGS_VALIDATION_ERROR = '400037',
  METHOD_ARGUMENT_TYPE_MISMATCH = '400038',

  // 401XXX
  EMPLOYEE_INACTIVE_ERROR = '401001',
  ACCESS_TOKEN_GENERATION_ERROR = '401002',
  TOKEN_EXPIRED_ERROR = '401003',
  TOKEN_VALIDATION_ERROR = '401004',
  WRONG_CREDENTIALS_ERROR = '401005',
  REFRESH_TOKEN_GENERATION_ERROR = '401008',
  EMPLOYEE_HAVE_NOT_ACTIVE_CLUB_OR_CORPORATE_PERMISSIONS = '401009',
  // 422XXX
  ENTITY_NOT_FOUND = '422001',
  EMPLOYEE_NOT_FOUND = '422004',
  CLUB_NOT_FOUND = '422002',
  SERVICE_NOT_FOUND = '422003',
  ARCHIVED_SERVICE = '422004',
  DOCUMENT_TEMPLATE_NOT_FOUND = '422005',
  VENDOR_NOT_FOUND = '422006',
  GENERAL_NOT_IMPLEMENTED_YET = '422007',
  VENDOR_CORPORATION_LEVEL_ORIGINAL_VALIDATION_ERROR = '422008',
  VENDOR_ARCHIVED_ERROR = '422009',
  VENDOR_OVERRIDE_ARCHIVE_ERROR = '422010',
  TAX_RATE_NOT_FOUND = '422011',
  REVENUE_CODE_NOT_FOUND = '422012',
  REVENUE_CODE_INVENTORY_LINKS_VALIDATION_ERROR = '422013',
  DOCUMENT_TEMPLATE_TYPE_NOT_SUPPORTED = '422014',
  SCRIPT_NOT_FOUND = '422015',
  PAYMENT_METHOD_NOT_FOUND = '422016',
  PAYMENT_METHOD_ARCHIVED_ERROR = '422017',
  RESOURCE_NOT_FOUND = '422018',
  RECEIPT_NOT_FOUND = '422019',
  RECEIPT_FIELD_NOT_FOUND = '422020',
  RECEIPT_CLUB_NOT_FOUND = '422021',
  CAMPAIGN_NOT_FOUND = '422022',
  INVENTORY_NOT_FOUND = '422023',
  INVENTORY_CLUB_NOT_FOUND = '422024',
  ORDER_NOT_UNIQUE = '422025',
  REGISTER_NOT_FOUND = '422026',
  REGISTER_PARENT_BUTTON_NOT_FOUND = '422027',
  CUSTOMER_NOT_FOUND = '422028',
  CLUB_PHONE_NOT_FOUND = '422029',
  SMS_NOT_FOUND = '422030',
  CALL_NOTE_NOT_FOUND = '422031',
  ARCHIVED_OBJECT_ERROR = '422032',
  ACTIVE_OBJECT_ERROR = '422033',
  DOCUMENT_TEMPLATE_CAMPAIGN_LINKS_VALIDATION_ERROR = '422034',
  SINGLE_DOCUMENT_TYPE_ERROR = '422035',
  DICTIONARY_TYPE_NOT_FOUND = '422036',
  DICTIONARY_NOT_FOUND = '422037',
  ACTIVE_DICTIONARY_DELETE_ERROR = '422038',
  MALFORMED_FILE_URL = '422039',
  CRM_TASK_NOT_FOUND = '422040',
  CRM_TASK_COMPLETED = '422041',
  ALERT_SOUND_CONDITION_CREATION_ERROR = '422042',
  ALERT_NOT_FOUND = '422043',
  ALERT_CONDITION_UNIQUE = '422044',
  CUSTOM_ALERT_CONDITION_ERROR = '422045',
  ALERT_SOUND_NOT_FOUND = '422046',
  EVENT_NOT_FOUND = '422047',
  EVENT_ALREADY_RESTORED = '422048',
  EXPIRED_EVENT_START_DATE = '422049',
  REGISTER_ALREADY_OPENED = '422050',
  REGISTER_ALREADY_CLOSED = '422051',
  PAYMENT_METHOD_INSTANCE_BLOCKED = '422052',
  PAYMENT_METHOD_INSTANCE_NOT_FOUND = '422053',
  ALERT_CONDITION_IGNORE_ERROR = '422054',
  FALLTHROUGH_REASON_NOT_FOUND = '422055',
  FALLTHROUGH_LEAD_CAPTURE_NOT_FOUND = '422056',
  TAG_NOT_FOUND = '422057',
  CLUB_EMAIL_NOT_FOUND = '422058',
  CUSTOMER_EMAIL_NOT_FOUND = '422059',
  NOTE_NOT_FOUND = '422060',
  INACTIVE_DICTIONARY_UPDATE_ERROR = '422061',
  DICTIONARY_TYPE_WITHOUT_ACTIVE_ERROR = '422062',
  INVOICE_NOT_FOUND = '422063',
  INVOICE_SUBTOTAL_AMOUNT_ERROR = '422064',
  INVOICE_TAX_AMOUNT_ERROR = '422065',
  INVOICE_TOTAL_AMOUNT_ERROR = '422066',
  INVOICE_PAYMENT_AMOUNT_ERROR = '422067',
  PAYMENT_METHOD_INSTANCE_LEFT_AMOUNT_ERROR = '422068',
  REVENUE_CODE_TAX_RATE_NOT_FOUND = '422069',
  SALES_STATUS_NULL = '422070',
  FILE_NOT_FOUND = '422071',
  TMP_FILE_NOT_FOUND = '422072',
  OPEN_REGISTER_DEACTIVATED_ERROR = '422073',
  SECURITY_POLICY_NOT_FOUND = '422074',
  SECURITY_POLICIES_NOT_FOUND = '422075',
  SECURITY_ROLE_NOT_FOUND = '422076',
  ALLERGY_NOT_FOUND = '422077',
  PACKAGE_TEMPLATE_NOT_FOUND = '422078',
  ADDING_FAMILY_MEMBER_ERROR = '422079',
  PERSON_NOT_FOUND = '422080',
  PERSON_DOCUMENTS_NOT_FOUND = '422081',
  CUSTOMER_CLUB_NOT_FOUND = '422082',
  CORPORATION_NOT_FOUND = '422083',
  INVALID_TIMEZONE = '422084',
  INVALID_REGION = '422085',
  INVALID_IMAGE = '422086',
  REVENUE_CODES_NOT_FOUND = '422087',
  CLUB_DUPLICATION_ERROR = '422088',
  ALERT_PREFERENCE_NOT_FOUND = '422089',
  INCORRECT_BARCODE = '422090',
  PAYMENT_METHOD_INSTANCE_NOT_FOUND_BY_BARCODE = '422091',
  PEAK_EMPLOYEE_NOT_FOUND = '422092',
  PACKAGE_CONTRACT_NOT_FOUND = '422093',
  PERSON_CONTRACT_NOT_FOUND = '422094',
  CORPORATE_SU_EMPLOYEE_NOT_FOUND = '422095',
  SEEDER_TYPE_IS_NOT_SUPPORTED = '422096',
  CLUB_CODE_SHOULD_BE_UNIQUE = '422097',
  PERSON_WAIVER_NOT_FOUND = '422098',
  NOTIFICATION_NOT_FOUND = '422099',
  SERVICE_USED_IN_MEMBER_MEMBERSHIP_ERROR = '422100',
  CAME_FROM_INDICATOR_NOT_FOUND = '422101',
  CAME_FROM_INDICATOR_NAME_NOT_UNIQUE = '422102',
  DROPDOWN_SELECT_WITH_NO_OPTIONS = '422103',
  CAME_FROM_INDICATOR_WITHOUT_PLACEHOLDER = '422104',
  USED_CAME_FROM_INDICATOR_UPDATE = '422105',
  NOTE_CODE_NOT_FOUND = '422106',
  DEPARTMENT_NOT_FOUND = '422107',
  ADDITIONAL_FIELD_NOT_FOUND = '422108',
  NOT_OPTIONAL_CAME_FROM = '422109',
  NOTIFICATION_BY_TYPE_NOT_FOUND = '422110',
  CAME_FROM_INDICATOR_WITHOUT_OPTIONAL = '422111',
  EMAIL_HISTORY_NOT_FOUND = '422112',
  LEAD_ACTIVITY_TITLE_NOT_UNIQUE = '422113',
  CLUB_GROUP_NOT_FOUND = '422114',
  CLUB_GROUP_TITLE_NOT_UNIQUE = '422115',
  NOS_EXCEEDED = '422116',
  TIME_ACCESS_NOT_FOUND = '422117',
  TIME_ACCESS_TITLE_NOT_UNIQUE = '422118',
  HELP_INFO_NOT_FOUND = '422119',
  HELP_INFO_NOT_UNIQUE = '422120',
  INSURANCE_COMPANY_NOT_FOUND = '422121',
  INSURANCE_COMPANY_NOT_UNIQUE = '422122',
  PROFILE_REQUIRED_FIELD_NOT_FOUND = '422123',
  SERVICE_INSTANCE_NOT_FOUND = '422124',
  PERSON_DOCUMENT_NOT_FOUND = '422125',
  FEE_NAME_NOT_UNIQUE = '422126',
  FEE_TYPE_NOT_FOUND = '422127',
  TITLE_NOT_UNIQUE = '422128',
  CLUB_ENTRANCE_NOT_FOUND = '422129',
  INVALID_CHECKIN_TIME = '422130',
  EVENT_START_DATE_MISMATCH = '422131',
  EVENT_VALIDATION_ERROR = '422132',
  EVENT_PARTICIPATION_ERROR = '422133',
  BARCODE_NOT_UNIQUE = '422134',
  DEPENDENT_PACKAGE_CYCLIC_ERROR = '422135',
  LINE_MAX_LENGTH_ERROR = '422136',
  LEAD_ACTIVITY_NOT_FOUND = '422137',
  CUSTOMER_NOT_CHECKED_IN = '422138',
  EMPLOYEE_IDENTIFIER_NOT_UNIQUE = '422139',
  USER_ID_NOT_UNIQUE = '422140',
  CAN_NOT_CHECKOUT_GUARDIAN = '422141',
  KID_ZONE_MEMBER_ALREADY_CHECKED_IN = '422142',
  CORPORATION_CAMPAIGN_SETTINGS_VALIDATION_ERROR = '422143',
  PASSED_EVENT_UPDATE_ERROR = '422144',
  CASH_PAYMENT_FIELDS_NOT_PROVIDED = '422145',
  CASH_PAYMENT_AMOUNTS_DONT_MATCH = '422146',
  FREEZE_PACKAGE_OVERLAPS_ALREADY_FROZEN_PACKAGE = '422147',
  ALERT_WITH_CONDITION_NOT_FOUND = '422148',
  INACTIVE_PACKAGE_NOT_FOUND = '422149',
  CUSTOMER_HAS_ACTIVE_MEMBERSHIP = '422150',
  REGISTER_WRONG_DEVICE = '422151',
  TERMINATION_DATE_ABSENCE_ERROR = '422152',
  PAYEEZY_FAILED_REQUEST = '422153',
  PAYEEZY_WRONG_CREDIT_CARD_TYPE_REQUEST = '422154',
  BILLING_OPTION_NOT_FOUND = '422155',
  INVENTORY_INSTANCE_NOT_FOUND = '422156',
  PACKAGE_INVENTORY_MAX_NUMBER_EXCEEDED = '422157',
  CANCEL_MEMBERSHIP_VALIDATION_ERROR = '422158',
  MEMBERSHIP_NOT_FOUND = '422159',
  DEVICE_UUID_NOT_FOUND = '422160',
  DELETION_INCLUDED_PKG_BILLING_OPTION_ERROR = '422161',
  INCORRECT_OLD_PASSWORD_ERROR = '422162',
  WRONG_CONFIRM_PASSWORD = '422163',
  PIN_CODE_MISMATCH_ERROR = '422164',
  REDEEM_NUMBER_EXCEEDED = '422165',
  SERVICE_FAMILY_MEMBER_NUMBER_EXCEEDED = '422166',
  FAMILY_MEMBER_NOT_FOUND = '422167',
  REGISTER_STATUS_HISTORY_NOT_FOUND = '422168',
  PAYEEZY_DISABLED_SETTINGS_ERROR = '422169',
  PAYEEZY_MISSING_SETTINGS_ERROR = '422170',
  CREDIT_CARD_PAYMENT_FAILURE_ERROR = '422171',
  RESOURCE_NOT_AVAILABLE = '422174',
  KID_ZONE_REDEEM_NOT_AVAILABLE = '422178',
  CREDIT_CARD_INACTIVE_ERROR = '422179',
  JOB_POSITION_NOT_FOUND = '422187',
  TIME_CLOCK_MAX_NUMBER_EXCEEDED = '422188',
  TIME_CLOCK_ALREADY_EXISTS = '422189',
  IN_PROGRESS_TIME_CLOCK_NOT_FOUND = '422190',
  CUSTOM_PAYMENT_FAILURE_ERROR = '422180',
  CUSTOM_PAYMENT_METHOD_EXISTS_FOR_INVOICE = '422181',
  CUSTOM_PAYMENT_METHOD_NOT_PROVIDED = '422182',
  CUSTOM_PAYMENT_INVALID_INVOICE_PERCENTAGE = '422183',
  DUPLICATE_JOB_POSITION_ERROR = '422191',
  INVENTORY_CLUB_ON_HAND_EXCEEDED = '422186',
  TIME_CLOCK_IN_PROGRESS_EXISTS = '422192',
  INVOICE_CUSTOMER_NOT_PROVIDED = '422193',
  PAY_PERIOD_SETTINGS_DATE_RANGE_ERROR = '422194',
  PAY_PERIOD_SETTINGS_NOT_FOUND = '422195',
  PAY_PERIOD_SETTINGS_TITLE_ERROR = '422196',
  PAY_PERIOD_PASSED_EXISTS_ON_DATE_RANGE_ERROR = '422197',
  PAY_PERIOD_LOCKED_ERROR = '422198',
  PAY_PERIOD_PASSED_ERROR = '422199',
  PAY_PERIOD_INVALID_DATE_RANGE_ERROR = '422200',
  PAY_PERIOD_NOT_FOUND = '422201',
  INVOICE_PAYMENT_IS_FINISHED = '422204',
  EMPLOYEE_SELF_DEACTIVATION_ERROR = '422206',
  CANCEL_MEMBERSHIP_EXISTS = '422207',
  OPENED_REGISTER_UPDATE_ERROR = '422208',
  CHECKING_SAVINGS_EXISTS = '422210',
  CHECKING_SAVINGS_INACTIVE_ERROR = '422211',
  NOTIFICATION_EMPTY_BODY_ERROR = '422214',
  ON_ACCOUNT_PAYMENT_METHOD_INVALID_AMOUNT = '422215',
  ON_ACCOUNT_PAYMENT_FAILURE_ERROR = '422216',
  DUPLICATE_SUBPACKAGE_ERROR = '422218',
  CHANGE_PLAN_MEMBERSHIP_IS_ACTIVE_ERROR = '422220',
  CHANGE_PLAN_MEMBERSHIP_IS_IN_PROGRESS_ERROR = '422221',
  MAX_BILLING_OPTION_PAYMENT_SCHEDULE_EXCEEDED = '422223',
  INCORRECT_BILLING_OPTION_PAYMENT_SCHEDULE_OR_PACKAGE_LENGTH = '422224',
  MAX_FEE_PAYMENT_SCHEDULE_EXCEEDED = '422225',
  INCORRECT_FEE_OPTION_PAYMENT_SCHEDULE_OR_PACKAGE_LENGTH = '422226',
  AUTO_GENERATED_SECURITY_ROLE_EDIT_ERROR = '422227',
  AUTO_GENERATED_SECURITY_POLICY_EDIT_ERROR = '422228',
  PACKAGE_NOT_FOUND = '422229',
  BILLING_SCHEDULE_NOT_FOUND = '422230',
  TITLE_FIELD_LENGTH_ERROR = '422231',
  CHECK_IN_NOT_FOUND = '422232',
  I_TRANSACT_SETTINGS_NOT_CONFIGURED = '422233',
  I_TRANSACT_FAILED_TO_ADD_PAYMENT_SOURCE = '422234',
  PAYMENT_PROCESSOR_DISABLED = '422235',
  I_TRANSACT_FAILED_REQUEST = '422236',
  PAYEEZY_DOES_NOT_SUPPORT_OPERATION = '422237',
  ZENDESK_DISABLED = '422238',
  ZENDESK_CUSTOM_FIELD_NOT_FOUND = '422239',
  OVERBOOKING_RESOURCES_MISSING = '422240',
  ZENDESK_VALIDATION_EXCEPTION = '422243',
  PAYMENT_METHOD_IS_NOT_ACTIVE_EXCEPTION = '422244',
  REQUIRED_FIELD_NOT_SET_ERROR = '422246',

  INVOICE_PAYMENT_SPLIT_EXCEED_AMOUNT = '422246',

  CAME_FROM_INDICATOR_REFERRAL_LIST_ACTIVE = '422250',

  OUT_OF_TERM_OPTION_CHOSEN_AS_DEFAULT = '422252',
  FREEZE_PACKAGE_DAY_AMOUNT_EXCEEDED_ALLOW_AMOUNT = '422253',
  BANNER_ADVERTISEMENT_NOT_FOUND = '422258',
  FEE_PROCESSING_NOT_FOUND = '422264',
  FEE_SINGLE_NOT_FOUND = '422265',
  FEE_TRANSACTION_NOT_FOUND = '422269',
  FEE_LATE_NOT_FOUND = '422267',
  FEE_MONTHLY_NOT_FOUND = '422268',
  FEE_RETURN_NOT_FOUND = '422266',
  PAY_PERIOD_ALREADY_STARTED = '422252',
  END_REASON_ALREADY_EXISTS = '422256',
  CODE_NOT_UNIQUE = '422273',
  COMMERCE_HUB_CONFIGURATION_ERROR = '422263',
  PRIMARY_EMPLOYEE_JOB_POSITION_ERROR = '422276',
  EVENT_NOT_ALLOWED_FOR_SELF_BOOKING = '422271',

  // 403
  FORBIDDEN = '403001',
  // 500
  INTERNAL_SERVER_ERROR = '500000',
  OBJECT_MAPPING_ERROR = '500001',
  GATEWAY_TIMEOUT = '504',
}

const errors = [
  { key: ServerError.SERVER_NOT_RESPONDING, message: messages.serverNotRespondingError },
  { key: ServerError.SOMETHING_WENT_WRONG, message: messages.somethingWentWrongError },
  {
    key: ServerError.SOMETHING_WENT_WRONG_ON_FRONT_END,
    message: messages.somethingWentWrongErrorOnFrontEnd,
  },
  { key: ServerError.DEVICE_CONNECTION_ERROR, message: messages.deviceConnectionError },
  { key: ServerError.DEVICE_UUID_NOT_FOUND, messages: messages.deviceIsNotFoundError },
  { key: ServerError.BROWSER_NOT_SUPPORT_HID_API, message: messages.deviceConnectionError },
  { key: ServerError.NON_UPDATED_VERSION_SIGWEB, message: messages.updateYourVersionSigWeb },
  { key: ServerError.UNABLE_COMMUNICATE_SIGWEB, message: messages.unableCommunicateWithSigWeb },
  { key: ServerError.UNSIGNED_DOCUMENTS_ERROR, message: messages.unsignedDocumentsError },
  { key: ServerError.UNSIGNED_DOCUMENT_ERROR, message: messages.unsignedDocumentError },
  {
    key: ServerError.NO_CORRESPONDING_SIGNATURE_PAD,
    message: messages.noCorrespondingSignaturePad,
  },
  {
    key: ServerError.JOB_POSITION_NOT_FOUND,
    message: messages.jobPositionNotFound,
  },
  {
    key: ServerError.TIME_CLOCK_MAX_NUMBER_EXCEEDED,
    message: messages.timeclockMaxNumberExceeded,
  },
  {
    key: ServerError.TIME_CLOCK_ALREADY_EXISTS,
    message: messages.timeClockAlreadyExists,
  },
  {
    key: ServerError.IN_PROGRESS_TIME_CLOCK_NOT_FOUND,
    message: messages.InProgressTimeclockNotFound,
  },
  { key: ServerError.BAD_REQUEST, message: messages.badRequestError },
  { key: ServerError.NOT_FOUND, message: messages.notFoundError },
  { key: ServerError.INTERNAL_SERVER_ERROR, message: messages.internalServerError },
  { key: ServerError.TAX_TYPE_ERROR, message: messages.typeOfTaxInBundleOnlySingleError },
  {
    key: ServerError.ROLE_ASSIGN_ERROR,
    message: messages.employeeMultiCorpLevelRoles,
  },
  {
    key: ServerError.ROLE_ASSIGN_CLUB_ROLES_COUNT_ERROR,
    message: messages.roleAssignClubRolesCountError,
  },
  {
    key: ServerError.DOCUMENT_TEMPLATE_TYPES_VALIDATION_ERROR,
    message: messages.documentTemplateTypesValidationError,
  },
  {
    key: ServerError.CORPORATION_EMAIL_SETTINGS_VALIDATION_ERROR,
    message: messages.corporationEmailSettingValidationError,
  },
  {
    key: ServerError.CORPORATION_SMS_SETTINGS_VALIDATION_ERROR,
    message: messages.corporationSmsSettingsValidationError,
  },
  {
    key: ServerError.INITIAL_CLUB_ID_OR_INITIAL_MEMBER_ID_MISSING_ERROR,
    message: messages.initialClubIdOrInitialMemberIdMissingError,
  },
  {
    key: ServerError.TAX_BUNDLE_ITEMS_TYPE_ERROR,
    message: messages.taxBundleItemsTypeError,
  },
  {
    key: ServerError.CORPORATION_PAYEEZY_SETTINGS_VALIDATION_ERROR,
    message: messages.corporationPayeezySettingsValidationError,
  },
  {
    key: ServerError.CLUB_NOT_ALLOWED,
    message: messages.clubNotAllowedError,
  },
  {
    key: ServerError.CORPORATION_ZENDESK_SETTINGS_VALIDATION_ERROR,
    message: messages.corporationZendeskSettingsValidationError,
  },
  {
    key: ServerError.METHOD_ARGUMENT_TYPE_MISMATCH,
    message: messages.methodArgumentTypeMismatchError,
  },
  {
    key: ServerError.REQUEST_VALIDATION_ERROR,
    message: messages.requestValidationError,
  },
  {
    key: ServerError.TAX_BUNDLE_OVERRIDE_TYPE_ERROR,
    message: messages.typeOfTaxInBundleOverrideShouldBeBundle,
  },
  {
    key: ServerError.POLICY_EMPTY_OR_CONTAIN_PERMISSIONS_FROM_DIFFERENT_GROUPS,
    message: messages.emptyPolicyError,
  },
  { key: ServerError.EMPLOYEE_INACTIVE_ERROR, message: messages.employeeInactiveError },
  { key: ServerError.ACCESS_TOKEN_GENERATION_ERROR, message: messages.accessTokenGenerationError },
  { key: ServerError.TOKEN_EXPIRED_ERROR, message: messages.tokenExpiredError },
  { key: ServerError.TOKEN_VALIDATION_ERROR, message: messages.tokenExpiredError },
  { key: ServerError.WRONG_CREDENTIALS_ERROR, message: messages.wrongCredentialsError },
  {
    key: ServerError.REFRESH_TOKEN_GENERATION_ERROR,
    message: messages.refreshTokenGenerationError,
  },
  {
    key: ServerError.EMPLOYEE_HAVE_NOT_ACTIVE_CLUB_OR_CORPORATE_PERMISSIONS,
    message: messages.haveNotActiveClubOrCorporatePermissionsError,
  },
  { key: ServerError.ENTITY_NOT_FOUND, message: messages.entityNotFoundError },
  { key: ServerError.EMPLOYEE_NOT_FOUND, message: messages.employeeNotFoundError },
  { key: ServerError.CLUB_NOT_FOUND, message: messages.clubNotFoundError },
  { key: ServerError.SERVICE_NOT_FOUND, message: messages.serviceNotFoundError },
  { key: ServerError.ARCHIVED_SERVICE, message: messages.serviceArchivedError },
  { key: ServerError.DOCUMENT_TEMPLATE_NOT_FOUND, message: messages.documentTemplateNotFoundError },
  { key: ServerError.VENDOR_NOT_FOUND, message: messages.vendorNotFoundError },
  {
    key: ServerError.GENERAL_NOT_IMPLEMENTED_YET,
    message: messages.generalNotImplementedYetError,
  },
  {
    key: ServerError.VENDOR_CORPORATION_LEVEL_ORIGINAL_VALIDATION_ERROR,
    message: messages.vendorShouldNotHasOriginalVendorError,
  },
  { key: ServerError.VENDOR_ARCHIVED_ERROR, message: messages.vendorAlreadyArchivedError },
  { key: ServerError.VENDOR_OVERRIDE_ARCHIVE_ERROR, message: messages.overrideVendorArchivedError },
  { key: ServerError.TAX_RATE_NOT_FOUND, message: messages.taxRateNotFoundError },
  { key: ServerError.REVENUE_CODE_NOT_FOUND, message: messages.revenueCodeNotFoundError },
  {
    key: ServerError.REVENUE_CODE_INVENTORY_LINKS_VALIDATION_ERROR,
    message: messages.allActiveInventoryItemsModifiedLinkedError,
  },
  {
    key: ServerError.DOCUMENT_TEMPLATE_TYPE_NOT_SUPPORTED,
    message: messages.documentTemplateNotSupportedYetError,
  },
  { key: ServerError.SCRIPT_NOT_FOUND, message: messages.scriptNotFoundError },
  { key: ServerError.PAYMENT_METHOD_NOT_FOUND, message: messages.paymentMethodNotFoundError },
  {
    key: ServerError.PAYMENT_METHOD_ARCHIVED_ERROR,
    message: messages.paymentMethodAlreadyArchivedError,
  },
  { key: ServerError.RESOURCE_NOT_FOUND, message: messages.resourceNotFoundError },
  { key: ServerError.RECEIPT_NOT_FOUND, message: messages.receiptNotFoundError },
  { key: ServerError.RECEIPT_FIELD_NOT_FOUND, message: messages.receiptFieldNotFoundError },
  { key: ServerError.RECEIPT_CLUB_NOT_FOUND, message: messages.receiptClubNotFoundError },
  { key: ServerError.CAMPAIGN_NOT_FOUND, message: messages.resourceNotFoundError },
  { key: ServerError.INVENTORY_NOT_FOUND, message: messages.inventoryNotFoundError },
  { key: ServerError.INVENTORY_CLUB_NOT_FOUND, message: messages.inventoryClubNotFoundError },
  { key: ServerError.ORDER_NOT_UNIQUE, message: messages.ordersNotUniqueError },
  { key: ServerError.REGISTER_NOT_FOUND, message: messages.registerNotFoundError },
  {
    key: ServerError.OPEN_REGISTER_DEACTIVATED_ERROR,
    message: messages.openRegisterDeactivatedError,
  },
  {
    key: ServerError.REGISTER_PARENT_BUTTON_NOT_FOUND,
    message: messages.registerParentButtonNotFoundError,
  },
  { key: ServerError.CUSTOMER_NOT_FOUND, message: messages.customerNotFoundError },
  { key: ServerError.CLUB_PHONE_NOT_FOUND, message: messages.clubNotInstalledMobilePhoneError },
  { key: ServerError.SMS_NOT_FOUND, message: messages.smsNotFoundError },
  { key: ServerError.CALL_NOTE_NOT_FOUND, message: messages.callNoteNotFoundError },
  { key: ServerError.ARCHIVED_OBJECT_ERROR, message: messages.objectArchivedError },
  { key: ServerError.ACTIVE_OBJECT_ERROR, message: messages.objectMustHaveInactiveStatusError },
  {
    key: ServerError.DOCUMENT_TEMPLATE_CAMPAIGN_LINKS_VALIDATION_ERROR,
    message: messages.allActiveCampaignsModifiedLinkedError,
  },
  { key: ServerError.SINGLE_DOCUMENT_TYPE_ERROR, message: messages.activeTemplateMustBeOneError },
  {
    key: ServerError.DICTIONARY_TYPE_NOT_FOUND,
    message: messages.dictionaryTypeShouldNotBeNullError,
  },
  { key: ServerError.DICTIONARY_NOT_FOUND, message: messages.dictionaryNotFoundError },
  { key: ServerError.ACTIVE_DICTIONARY_DELETE_ERROR, message: messages.dictionaryDeletedError },
  { key: ServerError.MALFORMED_FILE_URL, message: messages.fileUrlMalformedError },
  { key: ServerError.CRM_TASK_NOT_FOUND, message: messages.crmTaskNotFoundError },
  { key: ServerError.CRM_TASK_COMPLETED, message: messages.crmTaskAlreadyCompletedError },
  {
    key: ServerError.ALERT_SOUND_CONDITION_CREATION_ERROR,
    message: messages.alertSoundConditionCreatedError,
  },
  { key: ServerError.ALERT_NOT_FOUND, message: messages.alertNotFoundError },
  { key: ServerError.ALERT_CONDITION_UNIQUE, message: messages.alertConditionAlreadyExistsError },
  { key: ServerError.CUSTOM_ALERT_CONDITION_ERROR, message: messages.alertConditionNotCustomError },
  { key: ServerError.ALERT_SOUND_NOT_FOUND, message: messages.alertSoundNotFoundError },
  { key: ServerError.EVENT_NOT_FOUND, message: messages.eventNotFoundError },
  { key: ServerError.EVENT_ALREADY_RESTORED, message: messages.eventAlreadyRestoredError },
  {
    key: ServerError.EXPIRED_EVENT_START_DATE,
    message: messages.eventStartDateHasAlreadyExpiredError,
  },
  { key: ServerError.REGISTER_ALREADY_OPENED, message: messages.registerAlreadyOpenedError },
  { key: ServerError.REGISTER_ALREADY_CLOSED, message: messages.registerAlreadyClosedError },
  {
    key: ServerError.PAYMENT_METHOD_INSTANCE_BLOCKED,
    message: messages.paymentMethodAlreadyBlockedError,
  },
  {
    key: ServerError.PAYMENT_METHOD_INSTANCE_NOT_FOUND,
    message: messages.paymentMethodInstanceNotFoundError,
  },
  { key: ServerError.ALERT_CONDITION_IGNORE_ERROR, message: messages.alertConditionIgnoreError },
  {
    key: ServerError.FALLTHROUGH_REASON_NOT_FOUND,
    message: messages.fallthroughReasonNotFoundError,
  },
  {
    key: ServerError.FALLTHROUGH_LEAD_CAPTURE_NOT_FOUND,
    message: messages.fallthroughLeadCaptureNotFoundError,
  },
  { key: ServerError.TAG_NOT_FOUND, message: messages.tagNotFoundError },
  { key: ServerError.INACTIVE_DICTIONARY_UPDATE_ERROR, message: messages.dictionaryUpdateError },
  { key: ServerError.FORBIDDEN, message: messages.accessForbiddenError },
  { key: ServerError.PASSWORD_VALIDATION_ERROR, message: messages.passwordValidationError },
  { key: ServerError.EMAIL_ALREADY_EXISTS, message: messages.emailAlreadyExistsError },
  { key: ServerError.DATE_TIME_RANGE_VALIDATION_ERROR, message: messages.dateTimeValidationError },
  { key: ServerError.PARAM_MISSING, message: messages.paramMissingError },
  { key: ServerError.TAG_ALREADY_EXISTS, message: messages.tagAlreadyExistsError },
  { key: ServerError.SCRIPT_STEP_UNIQUE_INDEX, message: messages.scriptStepUniqueIndexError },
  {
    key: ServerError.RESOURCE_TIME_AVAILABLE_VALIDATION_ERROR,
    message: messages.resourceTimeAvailableValidationError,
  },
  {
    key: ServerError.RESOURCE_AVAIL_DATE_VALIDATION_ERROR,
    message: messages.resourceAvailableDateValidationError,
  },
  {
    key: ServerError.PHONE_TO_SEND_SMS_IS_NOT_VALID,
    message: messages.phoneToSendSMSIsNotValidError,
  },
  { key: ServerError.STOMP_HEADER_MISSING, message: messages.stompHeaderMissingError },
  { key: ServerError.CORRUPTED_FILE, message: messages.corruptedFileError },
  {
    key: ServerError.EVENT_TIME_RESTRICTION_EXPIRED,
    message: messages.eventTimeRestrictionExpiredError,
  },
  {
    key: ServerError.BULK_FILTER_IDS_VALIDATION_ERROR,
    message: messages.bulkFilterIdsValidationError,
  },
  {
    key: ServerError.UNABLE_TO_CHANGE_POLICY_TYPE,
    message: messages.unableToChangePolicyTypeError,
  },
  {
    key: ServerError.SECURITY_ROLE_SHOULD_CONTAIN_UNIQUE_GROUPS,
    message: messages.securityRoleShouldContainUniqueGroupsError,
  },
  { key: ServerError.CLUB_ID_MISSING, message: messages.clubIdMissingError },
  { key: ServerError.TAX_BUNDLE_OVERRIDE_TYPE_ERROR, message: messages.taxBundleOverrideTypeError },
  { key: ServerError.TAX_RATE_VALUE_ERROR, message: messages.taxRateValueError },
  {
    key: ServerError.PAYMENT_SPLITS_SUM_VALIDATION,
    message: messages.paymentSplitsSumValidationError,
  },
  { key: ServerError.MEMBERSHIP_ERROR, message: messages.membershipError },
  {
    key: ServerError.SECURITY_ROLE_SHOULD_CONTAIN_POLICIES_FROM_ONE_GROUP_TYPE,
    message: messages.securityRoleShouldContainPoliciesFromOneGroupTypeError,
  },
  { key: ServerError.UNABLE_TO_CHANGE_ROLE_LEVEL, message: messages.unableToChangeRoleLevelError },
  { key: ServerError.POLICY_LEVEL_TYPE_ERROR, message: messages.policyLevelTypeError },
  { key: ServerError.ROLE_LEVEL_TYPE_ERROR, message: messages.roleLevelTypeError },
  {
    key: ServerError.DICTIONARY_TYPE_WITHOUT_ACTIVE_ERROR,
    message: messages.dictionaryTypeWithoutActiveError,
  },
  { key: ServerError.INVOICE_NOT_FOUND, message: messages.invoiceNotFoundError },
  { key: ServerError.INVOICE_SUBTOTAL_AMOUNT_ERROR, message: messages.invoiceSubtotalAmountError },
  { key: ServerError.INVOICE_TAX_AMOUNT_ERROR, message: messages.invoiceTaxAmountError },
  { key: ServerError.INVOICE_TOTAL_AMOUNT_ERROR, message: messages.invoiceTotalAmountError },
  { key: ServerError.INVOICE_PAYMENT_AMOUNT_ERROR, message: messages.invoicePaymentAmountError },
  {
    key: ServerError.PAYMENT_METHOD_INSTANCE_LEFT_AMOUNT_ERROR,
    message: messages.paymentMethodInstanceLeftAmountError,
  },
  {
    key: ServerError.REVENUE_CODE_TAX_RATE_NOT_FOUND,
    message: messages.revenueCodeTaxRateNotFoundError,
  },
  { key: ServerError.SALES_STATUS_NULL, message: messages.salesStatusNullError },
  { key: ServerError.FILE_NOT_FOUND, message: messages.fileNotFoundError },
  { key: ServerError.TMP_FILE_NOT_FOUND, message: messages.tmpFileNotFoundError },
  {
    key: ServerError.OPEN_REGISTER_DEACTIVATED_ERROR,
    message: messages.openRegisterDeactivatedError,
  },
  { key: ServerError.SECURITY_POLICY_NOT_FOUND, message: messages.securityPolicyNotFoundError },
  { key: ServerError.SECURITY_POLICIES_NOT_FOUND, message: messages.securityPoliciesNotFoundError },
  { key: ServerError.SECURITY_ROLE_NOT_FOUND, message: messages.securityRoleNotFoundError },
  { key: ServerError.ALLERGY_NOT_FOUND, message: messages.allergyNotFoundError },
  { key: ServerError.PACKAGE_TEMPLATE_NOT_FOUND, message: messages.packageTemplateNotFoundError },
  { key: ServerError.ADDING_FAMILY_MEMBER_ERROR, message: messages.addingFamilyMemberError },
  { key: ServerError.PERSON_NOT_FOUND, message: messages.personNotFoundError },
  { key: ServerError.PERSON_DOCUMENTS_NOT_FOUND, message: messages.personDocumentsNotFoundError },
  { key: ServerError.CUSTOMER_CLUB_NOT_FOUND, message: messages.customerClubNotFoundError },
  { key: ServerError.CORPORATION_NOT_FOUND, message: messages.corporationNotFoundError },
  { key: ServerError.INVALID_TIMEZONE, message: messages.invalidTimezoneError },
  { key: ServerError.INVALID_REGION, message: messages.invalidRegionError },
  { key: ServerError.INVALID_IMAGE, message: messages.invalidImageError },
  { key: ServerError.REVENUE_CODES_NOT_FOUND, message: messages.revenueCodesNotFoundError },
  { key: ServerError.CLUB_DUPLICATION_ERROR, message: messages.clubDuplicationError },
  { key: ServerError.ALERT_PREFERENCE_NOT_FOUND, message: messages.alertPreferenceNotFoundError },
  { key: ServerError.INCORRECT_BARCODE, message: messages.incorrectBarcodeError },
  {
    key: ServerError.PAYMENT_METHOD_INSTANCE_NOT_FOUND_BY_BARCODE,
    message: messages.paymentMethodInstanceNotFoundByBarcodeError,
  },
  { key: ServerError.PEAK_EMPLOYEE_NOT_FOUND, message: messages.peakEmployeeNotFoundError },
  { key: ServerError.PACKAGE_CONTRACT_NOT_FOUND, message: messages.packageContractNotFoundError },
  { key: ServerError.PERSON_CONTRACT_NOT_FOUND, message: messages.personContractNotFoundError },
  {
    key: ServerError.CORPORATE_SU_EMPLOYEE_NOT_FOUND,
    message: messages.corporateSuEmployeeNotFoundError,
  },
  {
    key: ServerError.SEEDER_TYPE_IS_NOT_SUPPORTED,
    message: messages.seederTypeIsNotSupportedError,
  },
  {
    key: ServerError.CLUB_CODE_SHOULD_BE_UNIQUE,
    message: messages.clubCodeShouldBeUniqueError,
  },
  { key: ServerError.PERSON_WAIVER_NOT_FOUND, message: messages.personWaiverNotFoundError },
  { key: ServerError.NOTIFICATION_NOT_FOUND, message: messages.notificationNotFoundError },
  {
    key: ServerError.SERVICE_USED_IN_MEMBER_MEMBERSHIP_ERROR,
    message: messages.serviceUsedInMemberMembershipError,
  },
  {
    key: ServerError.USED_CAME_FROM_INDICATOR_UPDATE,
    message: messages.canNotUpdateTypeAndReduceOptionsError,
  },
  {
    key: ServerError.CAME_FROM_INDICATOR_NOT_FOUND,
    message: messages.cameFromIndicatorNotFoundError,
  },
  {
    key: ServerError.CAME_FROM_INDICATOR_NAME_NOT_UNIQUE,
    message: messages.cameFromIndicatorNameNotUniqueError,
  },
  {
    key: ServerError.DROPDOWN_SELECT_WITH_NO_OPTIONS,
    message: messages.dropdownSelectHasNotAnyOptionError,
  },
  {
    key: ServerError.CAME_FROM_INDICATOR_WITHOUT_PLACEHOLDER,
    message: messages.cameFromMustContainPlaceholderError,
  },
  {
    key: ServerError.NOTE_CODE_NOT_FOUND,
    message: messages.noteCodeNotFoundError,
  },
  {
    key: ServerError.DEPARTMENT_NOT_FOUND,
    message: messages.departmentNotFoundError,
  },
  {
    key: ServerError.ADDITIONAL_FIELD_NOT_FOUND,
    message: messages.additionalFieldNotFoundError,
  },
  {
    key: ServerError.NOT_OPTIONAL_CAME_FROM,
    message: messages.cameFromIndicatorNotOptionalError,
  },
  {
    key: ServerError.NOTIFICATION_BY_TYPE_NOT_FOUND,
    message: messages.notificationForCorporationNotFoundError,
  },
  {
    key: ServerError.CAME_FROM_INDICATOR_WITHOUT_OPTIONAL,
    message: messages.cameFromMustContainOptionalFieldError,
  },
  {
    key: ServerError.EMAIL_HISTORY_NOT_FOUND,
    message: messages.emailHistoryNotFoundError,
  },
  {
    key: ServerError.LEAD_ACTIVITY_TITLE_NOT_UNIQUE,
    message: messages.leadActivityAlreadyExistsError,
  },
  {
    key: ServerError.CLUB_GROUP_NOT_FOUND,
    message: messages.clubGroupNotFoundError,
  },
  {
    key: ServerError.CLUB_GROUP_TITLE_NOT_UNIQUE,
    message: messages.clubGroupAlreadyExistError,
  },
  {
    key: ServerError.NOS_EXCEEDED,
    message: messages.invoiceTotalExceedsError,
  },
  {
    key: ServerError.TIME_ACCESS_NOT_FOUND,
    message: messages.timeAccessNotFoundError,
  },
  {
    key: ServerError.TIME_ACCESS_TITLE_NOT_UNIQUE,
    message: messages.timeAccessAlreadyExistsError,
  },
  {
    key: ServerError.HELP_INFO_NOT_FOUND,
    message: messages.helpInfoNotFoundError,
  },
  {
    key: ServerError.HELP_INFO_NOT_UNIQUE,
    message: messages.helpInfoAlreadyExistsError,
  },
  {
    key: ServerError.INSURANCE_COMPANY_NOT_FOUND,
    message: messages.insuranceCompanyNotFoundError,
  },
  {
    key: ServerError.INSURANCE_COMPANY_NOT_UNIQUE,
    message: messages.insuranceCompanyAlreadyExistsError,
  },
  {
    key: ServerError.PROFILE_REQUIRED_FIELD_NOT_FOUND,
    message: messages.profileRequiredFieldNotFound,
  },
  {
    key: ServerError.SERVICE_INSTANCE_NOT_FOUND,
    message: messages.serviceInstanceNotFound,
  },
  {
    key: ServerError.PERSON_DOCUMENT_NOT_FOUND,
    message: messages.personDocumentNotFound,
  },
  {
    key: ServerError.FEE_NAME_NOT_UNIQUE,
    message: messages.feeNameNotUnique,
  },
  {
    key: ServerError.FEE_TYPE_NOT_FOUND,
    message: messages.feeTypeNotFound,
  },
  {
    key: ServerError.CLUB_ENTRANCE_NOT_FOUND,
    message: messages.clubEntranceNotFoundError,
  },
  {
    key: ServerError.INVALID_CHECKIN_TIME,
    message: messages.entranceClosedError,
  },
  {
    key: ServerError.EVENT_START_DATE_MISMATCH,
    message: messages.eventStartDateMismatch,
  },
  {
    key: ServerError.EVENT_VALIDATION_ERROR,
    message: messages.eventValidationError,
  },
  {
    key: ServerError.EVENT_PARTICIPATION_ERROR,
    message: messages.eventParticipationError,
  },
  {
    key: ServerError.TITLE_NOT_UNIQUE,
    message: messages.titleNotUnique,
  },
  {
    key: ServerError.BARCODE_NOT_UNIQUE,
    message: messages.barcodeNotUnique,
  },
  {
    key: ServerError.DEPENDENT_PACKAGE_CYCLIC_ERROR,
    message: messages.dependentPackageCyclicError,
  },
  {
    key: ServerError.LINE_MAX_LENGTH_ERROR,
    message: messages.lineMaxLengthError,
  },
  {
    key: ServerError.LEAD_ACTIVITY_NOT_FOUND,
    message: messages.leadActivityNotFound,
  },
  {
    key: ServerError.CUSTOMER_NOT_CHECKED_IN,
    message: messages.customerNotCheckedIn,
  },
  {
    key: ServerError.EMPLOYEE_IDENTIFIER_NOT_UNIQUE,
    message: messages.employeeIdNotUnique,
  },
  {
    key: ServerError.USER_ID_NOT_UNIQUE,
    message: messages.userIdNotUnique,
  },
  {
    key: ServerError.CAN_NOT_CHECKOUT_GUARDIAN,
    message: messages.canNotCheckoutGuardian,
  },
  {
    key: ServerError.KID_ZONE_MEMBER_ALREADY_CHECKED_IN,
    message: messages.kidZoneMemberAlreadyCheckedIn,
  },
  {
    key: ServerError.CORPORATION_CAMPAIGN_SETTINGS_VALIDATION_ERROR,
    message: messages.corporationCampaignMustBeEnabled,
  },
  {
    key: ServerError.PASSED_EVENT_UPDATE_ERROR,
    message: messages.passedEventCanNotBeUpdated,
  },
  {
    key: ServerError.CASH_PAYMENT_FIELDS_NOT_PROVIDED,
    message: messages.cashPaymentFieldsNotProvided,
  },
  {
    key: ServerError.CASH_PAYMENT_AMOUNTS_DONT_MATCH,
    message: messages.cashPaymentAmountsDontMatch,
  },
  {
    key: ServerError.FREEZE_PACKAGE_OVERLAPS_ALREADY_FROZEN_PACKAGE,
    message: messages.freezePackageOverlapsAlreadyFrozenPackage,
  },
  {
    key: ServerError.ALERT_WITH_CONDITION_NOT_FOUND,
    message: messages.alertWithConditionNotFound,
  },
  {
    key: ServerError.INACTIVE_PACKAGE_NOT_FOUND,
    message: messages.inactivePackageNotFound,
  },
  {
    key: ServerError.CUSTOMER_HAS_ACTIVE_MEMBERSHIP,
    message: messages.customerHasActiveMembership,
  },
  {
    key: ServerError.REGISTER_WRONG_DEVICE,
    message: messages.registerWrongDevice,
  },
  {
    key: ServerError.TERMINATION_DATE_ABSENCE_ERROR,
    message: messages.terminationDateAbsenceError,
  },
  {
    key: ServerError.PAYEEZY_FAILED_REQUEST,
    message: messages.payeezyFailedRequest,
  },
  {
    key: ServerError.PAYEEZY_WRONG_CREDIT_CARD_TYPE_REQUEST,
    message: messages.payeezyWrongCreditCardTypeRequest,
  },
  {
    key: ServerError.BILLING_OPTION_NOT_FOUND,
    message: messages.billingOptionNotFound,
  },
  {
    key: ServerError.INVENTORY_INSTANCE_NOT_FOUND,
    message: messages.inventoryInstanceNotFound,
  },
  {
    key: ServerError.PACKAGE_INVENTORY_MAX_NUMBER_EXCEEDED,
    message: messages.packageInventoryMaxNumberExceeded,
  },
  {
    key: ServerError.DELETION_INCLUDED_PKG_BILLING_OPTION_ERROR,
    message: messages.billingOptionsCanNotBeDeleted,
  },
  {
    key: ServerError.CANCEL_MEMBERSHIP_VALIDATION_ERROR,
    message: messages.cancelMembershipValidationError,
  },
  {
    key: ServerError.MEMBERSHIP_NOT_FOUND,
    message: messages.membershipNotFound,
  },
  {
    key: ServerError.INCORRECT_OLD_PASSWORD_ERROR,
    message: messages.incorrectOldPasswordError,
  },
  {
    key: ServerError.WRONG_CONFIRM_PASSWORD,
    message: messages.wrongConfirmPassword,
  },
  {
    key: ServerError.PIN_CODE_MISMATCH_ERROR,
    message: messages.pinCodeMismatchError,
  },
  {
    key: ServerError.REDEEM_NUMBER_EXCEEDED,
    message: messages.redeemNumberExceeded,
  },
  {
    key: ServerError.SERVICE_FAMILY_MEMBER_NUMBER_EXCEEDED,
    message: messages.redeemNumberExceeded,
  },
  {
    key: ServerError.FAMILY_MEMBER_NOT_FOUND,
    message: messages.familyMemberNotFound,
  },
  {
    key: ServerError.REGISTER_STATUS_HISTORY_NOT_FOUND,
    message: messages.registerStatusHistoryNotFound,
  },
  {
    key: ServerError.PAYEEZY_DISABLED_SETTINGS_ERROR,
    message: messages.payeezyDisabledSettingsError,
  },
  {
    key: ServerError.PAYEEZY_MISSING_SETTINGS_ERROR,
    message: messages.payeezyMissingSettingsError,
  },
  {
    key: ServerError.CREDIT_CARD_PAYMENT_FAILURE_ERROR,
    message: messages.creditCardPaymentFailureError,
  },
  {
    key: ServerError.GATEWAY_TIMEOUT,
    message: messages.responseTimeoutError,
  },
  {
    key: ServerError.RESOURCE_NOT_AVAILABLE,
    message: messages.resourceNotAvailableError,
  },
  {
    key: ServerError.KID_ZONE_REDEEM_NOT_AVAILABLE,
    message: messages.kidZoneRedeemNotAvailableError,
  },
  {
    key: ServerError.CREDIT_CARD_INACTIVE_ERROR,
    message: messages.creditCardIsNotActiveError,
  },
  {
    key: ServerError.CUSTOM_PAYMENT_FAILURE_ERROR,
    message: messages.occurredDuringCustomPaymentError,
  },
  {
    key: ServerError.CUSTOM_PAYMENT_METHOD_EXISTS_FOR_INVOICE,
    message: messages.alreadyUsedGiftCardError,
  },
  {
    key: ServerError.CUSTOM_PAYMENT_METHOD_NOT_PROVIDED,
    message: messages.notProvidedCustomPaymentMethodError,
  },
  {
    key: ServerError.CUSTOM_PAYMENT_INVALID_INVOICE_PERCENTAGE,
    message: messages.invoicePercentageExceededMaxAvailableError,
  },
  {
    key: ServerError.INVOICE_PAYMENT_IS_FINISHED,
    message: messages.invoicePaymentIsFinishedError,
  },
  {
    key: ServerError.EMPLOYEE_SELF_DEACTIVATION_ERROR,
    message: messages.employeeCanNotDeactivateError,
  },
  {
    key: ServerError.DUPLICATE_JOB_POSITION_ERROR,
    message: messages.duplicateJobPositionError,
  },
  {
    key: ServerError.INVENTORY_CLUB_ON_HAND_EXCEEDED,
    message: messages.inventoryClubOnHandExceeded,
  },
  {
    key: ServerError.TIME_CLOCK_IN_PROGRESS_EXISTS,
    message: messages.timeclockInProgressExistError,
  },
  {
    key: ServerError.INVOICE_CUSTOMER_NOT_PROVIDED,
    message: messages.invoiceCustomerNotProvidedError,
  },
  {
    key: ServerError.PAY_PERIOD_SETTINGS_DATE_RANGE_ERROR,
    message: messages.payPeriodSettingsDateRangeError,
  },
  {
    key: ServerError.PAY_PERIOD_SETTINGS_NOT_FOUND,
    message: messages.payPeriodSettingsNotFound,
  },
  {
    key: ServerError.PAY_PERIOD_SETTINGS_TITLE_ERROR,
    message: messages.payPeriodSettingsTitleError,
  },
  {
    key: ServerError.PAY_PERIOD_PASSED_EXISTS_ON_DATE_RANGE_ERROR,
    message: messages.payPeriodPassedExistsOnDateRangeError,
  },
  {
    key: ServerError.PAY_PERIOD_LOCKED_ERROR,
    message: messages.payPeriodLockedError,
  },
  {
    key: ServerError.PAY_PERIOD_PASSED_ERROR,
    message: messages.payPeriodPassedError,
  },
  {
    key: ServerError.PAY_PERIOD_INVALID_DATE_RANGE_ERROR,
    message: messages.payPeriodInvalidDateRangeError,
  },
  {
    key: ServerError.PAY_PERIOD_NOT_FOUND,
    message: messages.payPeriodNotFound,
  },
  {
    key: ServerError.CANCEL_MEMBERSHIP_EXISTS,
    message: messages.cancelMembershipExistsError,
  },
  {
    key: ServerError.OPENED_REGISTER_UPDATE_ERROR,
    message: messages.openedRegisterCanNotBeUpdated,
  },
  {
    key: ServerError.CHECKING_SAVINGS_EXISTS,
    message: messages.checkingSavingsExistError,
  },
  {
    key: ServerError.CHECKING_SAVINGS_INACTIVE_ERROR,
    message: messages.checkingSavingsInactiveError,
  },
  {
    key: ServerError.NOTIFICATION_EMPTY_BODY_ERROR,
    message: messages.notificationEmptyBodyError,
  },
  {
    key: ServerError.ON_ACCOUNT_PAYMENT_METHOD_INVALID_AMOUNT,
    message: messages.onAccountPaymentMethodInvalidAmountError,
  },
  {
    key: ServerError.ON_ACCOUNT_PAYMENT_FAILURE_ERROR,
    message: messages.onAccountPaymentFailureError,
  },
  {
    key: ServerError.DUPLICATE_SUBPACKAGE_ERROR,
    message: messages.duplicateSubpackageError,
  },
  {
    key: ServerError.CHANGE_PLAN_MEMBERSHIP_IS_ACTIVE_ERROR,
    message: messages.activeMembershipError,
  },
  {
    key: ServerError.CHANGE_PLAN_MEMBERSHIP_IS_IN_PROGRESS_ERROR,
    message: messages.membershipInProgressError,
  },
  {
    key: ServerError.MAX_BILLING_OPTION_PAYMENT_SCHEDULE_EXCEEDED,
    message: messages.enlargePackageLengthError,
  },
  {
    key: ServerError.INCORRECT_BILLING_OPTION_PAYMENT_SCHEDULE_OR_PACKAGE_LENGTH,
    message: messages.enlargePackageFeeLengthError,
  },
  {
    key: ServerError.MAX_FEE_PAYMENT_SCHEDULE_EXCEEDED,
    message: messages.maxFinancedBillingPaymentScheduleError,
  },
  {
    key: ServerError.INCORRECT_FEE_OPTION_PAYMENT_SCHEDULE_OR_PACKAGE_LENGTH,
    message: messages.maxRegularFeePaymentScheduleError,
  },
  {
    key: ServerError.PACKAGE_NOT_FOUND,
    message: messages.packageNotFoundError,
  },
  {
    key: ServerError.BILLING_SCHEDULE_NOT_FOUND,
    message: messages.billingScheduleNotFoundError,
  },
  {
    key: ServerError.TITLE_FIELD_LENGTH_ERROR,
    message: messages.fieldTitleLengthError,
  },
  {
    key: ServerError.CHECK_IN_NOT_FOUND,
    message: messages.checkInNotFoundError,
  },
  {
    key: ServerError.I_TRANSACT_SETTINGS_NOT_CONFIGURED,
    message: messages.iTransactSettingsNotConfiguredError,
  },
  {
    key: ServerError.I_TRANSACT_FAILED_TO_ADD_PAYMENT_SOURCE,
    message: messages.iTransactFailedToAddPaymentSourceError,
  },
  {
    key: ServerError.PAYMENT_PROCESSOR_DISABLED,
    message: messages.paymentProcessorDisabledError,
  },
  {
    key: ServerError.I_TRANSACT_FAILED_REQUEST,
    message: messages.iTransactFailedRequestError,
  },
  {
    key: ServerError.PAYEEZY_DOES_NOT_SUPPORT_OPERATION,
    message: messages.payeezyDoesNotSupportOperationError,
  },
  {
    key: ServerError.ZENDESK_DISABLED,
    message: messages.zendeskIsDisabledError,
  },
  {
    key: ServerError.ZENDESK_CUSTOM_FIELD_NOT_FOUND,
    message: messages.zendeskCustomFieldNotFoundError,
  },
  {
    key: ServerError.OVERBOOKING_RESOURCES_MISSING,
    message: messages.overbookingResourcesMissing,
  },
  {
    key: ServerError.AUTO_GENERATED_SECURITY_ROLE_EDIT_ERROR,
    message: messages.roleCanNotBeChanged,
  },
  {
    key: ServerError.AUTO_GENERATED_SECURITY_POLICY_EDIT_ERROR,
    message: messages.policyCanNotBeChanged,
  },
  {
    key: ServerError.ZENDESK_VALIDATION_EXCEPTION,
    message: messages.zendeskValidationException,
  },
  {
    key: ServerError.PAYMENT_METHOD_IS_NOT_ACTIVE_EXCEPTION,
    message: messages.paymentMethodIsNotActiveException,
  },
  {
    key: ServerError.REQUIRED_FIELD_NOT_SET_ERROR,
    message: messages.fieldIsRequiredException,
  },
  {
    key: ServerError.INVOICE_PAYMENT_SPLIT_EXCEED_AMOUNT,
    message: messages.invoicePaymentSplitExceedAmountException,
  },
  {
    key: ServerError.CAME_FROM_INDICATOR_REFERRAL_LIST_ACTIVE,
    message: messages.cameFromIndicatorReferralListTypeIsAlreadyActive,
  },
  {
    key: ServerError.OUT_OF_TERM_OPTION_CHOSEN_AS_DEFAULT,
    message: messages.outOfTermCanNotBeDefault,
  },
  {
    key: ServerError.FREEZE_PACKAGE_DAY_AMOUNT_EXCEEDED_ALLOW_AMOUNT,
    message: messages.freezePackageDayAmountExceededAllowAmount,
  },
  {
    key: ServerError.BANNER_ADVERTISEMENT_NOT_FOUND,
    message: messages.bannerAdvertisementWithIdNotFound,
  },
  {
    key: ServerError.FEE_PROCESSING_NOT_FOUND,
    message: messages.feeProcessingNotFound,
  },
  {
    key: ServerError.FEE_SINGLE_NOT_FOUND,
    message: messages.feeSingleNotFound,
  },
  {
    key: ServerError.FEE_TRANSACTION_NOT_FOUND,
    message: messages.feeTransactionNotFound,
  },
  {
    key: ServerError.FEE_LATE_NOT_FOUND,
    message: messages.feeLateNotFound,
  },
  {
    key: ServerError.FEE_LATE_NOT_FOUND,
    message: messages.feeMonthlyNotFound,
  },
  {
    key: ServerError.FEE_RETURN_NOT_FOUND,
    message: messages.feeReturnNotFound,
  },
  {
    key: ServerError.FEE_RETURN_NOT_FOUND,
    message: messages.payPeriodAlreadyStarted,
  },
  {
    key: ServerError.END_REASON_ALREADY_EXISTS,
    message: messages.endReasonWithAlreadyExists,
  },
  {
    key: ServerError.CODE_NOT_UNIQUE,
    message: messages.codeIsNotUnique,
  },
  {
    key: ServerError.COMMERCE_HUB_CONFIGURATION_ERROR,
    message: messages.commerceHubConfigurationError,
  },
  {
    key: ServerError.PRIMARY_EMPLOYEE_JOB_POSITION_ERROR,
    message: messages.employeePrimaryJobPosition,
  },
  {
    key: ServerError.EVENT_NOT_ALLOWED_FOR_SELF_BOOKING,
    message: messages.eventIsNotAllowedForSelfBooking,
  },
];

export const MessageByCode = (code: string): Message =>
  errors.find(e => e.key === code)?.message || messages.somethingWentWrongError;

export const MessageValueByCode = (code: string): string => MessageByCode(code).defaultMessage;

export const FormattedMessageByCode = (code: string, values?: any): JSX.Element | null => {
  const message = MessageByCode(code);
  return message ? <FormattedMessage key={code} {...message} values={values} /> : null;
};
