import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PhoneNumberInput } from 'common/components';
import { RequiredProfileInfo } from 'modules/corporate-settings/constants/requiredFields';
import { IFormEmergencyContact } from 'common/interfaces/additionalInfo';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';

const EmergencyContactPhone: FC = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IFormEmergencyContact>();

  const error = errors.emergencyPhone;
  const renderIntlMessage = useRenderIntlMessage();
  const { profileRecommendedFields: recommendedFields } = useRecommendedFields();
  const recommended = Boolean(recommendedFields?.get(RequiredProfileInfo.EmergencyContactInfo));

  return (
    <Controller
      name="emergencyPhone"
      control={control}
      defaultValue=""
      render={({ field }) => (
        <PhoneNumberInput
          {...field}
          onChange={value => {
            setValue('emergencyPhone', value || '', { shouldValidate: true, shouldDirty: true });
          }}
          error={!!error}
          helperText={error ? renderIntlMessage(error.message) : undefined}
          recommended={recommended}
        />
      )}
    />
  );
};

export default EmergencyContactPhone;
