import React from 'react';
import {
  Button,
  IconButton,
  IconButtonProps as MuiIconButtonProps,
  ButtonProps as MuiButtonProps,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  noWrap: {
    whiteSpace: 'nowrap',
  },
}));

interface IProps {
  label?: string | JSX.Element;
  iconButtonTooltip?: string | JSX.Element;
  noWrap?: boolean;
  noPadding?: boolean;
  iconButton?: boolean;
  icon?: JSX.Element;
  IconButtonProps?: MuiIconButtonProps;
  ButtonProps?: MuiButtonProps;
  onClick: () => void;
}

// TODO: replace with general (common) tooltip button component
const ResourceButton = ({
  label,
  iconButtonTooltip,
  noWrap,
  iconButton,
  icon,
  IconButtonProps,
  ButtonProps,
  onClick,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const { disabled = false } = IconButtonProps || {};

  return iconButton ? (
    <Tooltip title={disabled ? '' : iconButtonTooltip || ''}>
      <span>
        <IconButton {...IconButtonProps} onClick={onClick}>
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  ) : (
    <Button
      className={cx({
        [classes.noWrap]: noWrap,
      })}
      onClick={onClick}
      {...ButtonProps}
    >
      {label}
    </Button>
  );
};

ResourceButton.defaultProps = {
  IconButtonProps: {
    disabled: false,
  },
};

export default React.memo(ResourceButton);
