import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { CustomTheme } from 'common/ui/interfaces';
import messages from './messages';
import commonMessages from 'common/messages/messages';
import { ITableRow } from 'common/interfaces/table';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    messageText: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
  }),
);

interface IProps {
  isLoading: boolean;
  rows: ITableRow[];
  loadingMessage?: string | JSX.Element;
  notFoundMessage?: string | JSX.Element;
}

function TableProgress({ isLoading, rows, loadingMessage, notFoundMessage }: IProps): JSX.Element {
  const classes = useStyles();
  return (
    <Paper>
      {!isLoading && !rows.length && (
        <Typography className={classes.messageText} align="center">
          {notFoundMessage}
        </Typography>
      )}
      {!rows.length && isLoading && (
        <Typography className={classes.messageText} align="center">
          {loadingMessage}
        </Typography>
      )}
    </Paper>
  );
}

// Set default props
TableProgress.defaultProps = {
  loadingMessage: <FormattedMessage {...messages.tableProgressLoadingMessage} />,
  notFoundMessage: <FormattedMessage {...commonMessages.noDataFound} />,
};

export default TableProgress;
