import * as yup from 'yup';
import { FallthroughReasons } from 'common/components/PersonProfile/constants';
import inputErrors from 'common/messages/inputErrors';
import { dateSchema } from 'common/validationSchemas/dateSchemas';
import { getRequiredErrorMessage } from 'common/utils/validation';

export const ValidationSchema = yup.object().shape({
  fallthroughReasonId: yup.string().required(getRequiredErrorMessage),
  fallthroughReason: yup
    .string()
    .when('fallthroughReasonId', {
      is: value => value === FallthroughReasons.OtherReason,
      then: yup.string().required(getRequiredErrorMessage),
    })
    .max(1000, () => inputErrors.reasonLengthError),
  createdDate: dateSchema.required(getRequiredErrorMessage),
  createdTime: dateSchema.required(getRequiredErrorMessage),
});
