import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { AdaptiveDatePicker } from 'common/components/index';
import { DatePickerProps } from '@material-ui/pickers';

type OnChangeType = {
  onChange: (date: string | null) => void;
};

interface IDatePickerControlProps {
  name: string;
  datePickerProps?: Partial<DatePickerProps & OnChangeType>;
  smallDatePicker?: boolean;
  hasDateError: boolean;
  disablePast?: boolean;
  dateHelperText: string;
  value: string | null;
  timePickerValue: string | null;
  dateLabelMessageDescriptor?: MessageDescriptor;
  setValue: (name: string, value: string) => void;
  onBlur: () => void;
  onChange: (date: string | null) => void;
}

export const DATE_PICKER_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_PICKER_TIME_FORMAT = 'HH:mm';

const DatePickerLayer = (props: IDatePickerControlProps): JSX.Element => {
  const {
    datePickerProps,
    smallDatePicker,
    onBlur,
    hasDateError,
    dateHelperText,
    disablePast,
    value,
    dateLabelMessageDescriptor,
    setValue,
    timePickerValue,
    onChange,
    name,
  } = props;

  const [datePickerValue, setDatePickerValue] = useState<string | null>(null);
  const datePickerValueRef = useRef<any>(value);
  const hasInitValueRef = useRef(false);

  datePickerValueRef.current = datePickerValue;

  useEffect(() => {
    if (!timePickerValue) {
      setValue(name, datePickerValueRef.current);
    }
  }, [setValue, timePickerValue, name]);

  useEffect(() => {
    if (value && timePickerValue && !datePickerValueRef.current && !hasInitValueRef.current) {
      hasInitValueRef.current = true;

      setDatePickerValue(
        moment
          .utc(`${value} ${timePickerValue}`, 'YYYY-MM-DD HH:mm')
          .tz((moment as any).defaultZone.name)
          .format('YYYY-MM-DD'),
      );
    }
  }, [setDatePickerValue, value, timePickerValue, datePickerValue]);

  return (
    <AdaptiveDatePicker
      {...datePickerProps}
      value={datePickerValue}
      onBlur={onBlur}
      size={smallDatePicker ? 'small' : 'medium'}
      onChange={(date: string | null) => {
        setDatePickerValue(date);
        onChange(date);

        if (datePickerProps?.onChange) {
          datePickerProps.onChange(date);
        }
      }}
      label={<FormattedMessage {...dateLabelMessageDescriptor} />}
      disablePast={disablePast}
      error={hasDateError}
      helperText={dateHelperText}
    />
  );
};

export default DatePickerLayer;
