import * as React from 'react';

interface AllowedToProps<Permission extends string> {
  perform?: Permission | Permission[] | boolean;
  yes?: (() => JSX.Element) | JSX.Element;
  no?: (() => JSX.Element) | JSX.Element;
  children?: React.ReactChild | React.ReactChild[];
  data?: never;
}

export const AllowedTo = <Permission extends string>({
  perform,
  children,
  yes = () => <>{children}</>,
  no = () => <></>,
}: AllowedToProps<Permission>): JSX.Element => {
  const yesResult: () => JSX.Element = () => (typeof yes === 'function' ? yes() : yes);
  const noResult: () => JSX.Element = () => (typeof no === 'function' ? no() : no);

  return perform ? yesResult() : noResult();
};
