import React, { useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Grid, TextField, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import isNumber from 'lodash/isNumber';
import messages from 'modules/services/messages/messages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { IBillingOption, IBillingRCode } from 'modules/services/interfaces/packages';
import {
  BillingOptionType,
  BillingOptionTypes,
  FrequencyType,
  FrequencyTypes,
} from 'modules/services/constants/packages';
import { NumberTextField, Select } from 'common/components';
import NumberController, {
  defaultNumberFormatProps,
} from 'common/components/NumberController/NumberController';
import CheckBoxWrap from 'common/components/CheckBoxWrap/CheckBoxWrap';
import inputLabelMessages from 'common/messages/inputLabels';
import SplitPaymentSection from 'modules/services/components/EditPackageForm/BillingStep/SplitPaymentSection/SplitPaymentSection';
import PaymentGraceSector from 'modules/services/components/EditPackageForm/BillingStep/PaymentGraceSector/PaymentGraceSector';
import CreditCardServiceFee from 'modules/services/components/EditPackageForm/BillingStep/CreditCardServiceFee/CreditCardServiceFee';
import {
  countPricePerBilling,
  countTaxedAmount,
  IDurationOptions,
  checkIsPaymentScheduleOptionDisabled,
} from 'modules/services/utils/billingUtils';
import FreezeSector from 'modules/services/components/EditPackageForm/MembershipStep/FreezeSector/FreezeSector';
import CheckboxLabeled from 'common/components/CheckboxLabeled/CheckboxLabeled';

interface IEditBillingOptionForm {
  defaultValues: IBillingOption;
  revenueCode: IBillingRCode;
  durationOption: IDurationOptions;
  formMethods: UseFormReturn<IBillingOption>;
  isOutOfTerm?: boolean;
}

const EditBillingOptionForm = ({
  defaultValues,
  revenueCode,
  durationOption,
  formMethods,
  isOutOfTerm,
}: IEditBillingOptionForm): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();

  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = formMethods;

  const totalAmount = watch('totalAmount');
  const [schedule, isAllowFreeze] = watch(['paymentEditableSchedule.value', 'freeze.allow']);

  const billingOptionType = watch('type');

  const taxedTotalAmount =
    totalAmount && revenueCode ? countTaxedAmount(totalAmount, revenueCode.totalTax) : totalAmount;
  const isFinancedBillingOptionType = billingOptionType === BillingOptionType.FINANCED;

  const pricePerBillingAmount =
    isFinancedBillingOptionType &&
    countPricePerBilling(totalAmount, revenueCode?.totalTax || 0, durationOption, schedule);

  useEffect(() => {
    if (isFinancedBillingOptionType && getValues('paymentEditableSchedule.editable') === null) {
      setValue('paymentEditableSchedule.editable', false);
      setValue('paymentEditableSchedule.value', FrequencyType.Weekly);
    }
  }, [getValues, setValue, isFinancedBillingOptionType]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Controller
          control={control}
          name="title"
          defaultValue={defaultValues?.title}
          render={({ field }) => (
            <TextField
              value={field.value}
              onChange={field.onChange}
              label={<FormattedMessage {...messages.billingOptionNameLabel} />}
              variant="outlined"
              autoComplete="off"
              fullWidth
              error={!!errors.title?.message}
              helperText={renderIntlMessage(errors.title?.message)}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="type"
          control={control}
          defaultValue={defaultValues?.type}
          render={({ field }) => (
            <Select
              fullWidth
              label={<FormattedMessage {...messages.billingOptionTypeLabel} />}
              variant="outlined"
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
            >
              {BillingOptionTypes.getSelectOptions()}
            </Select>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberController
          debounced
          control={control}
          name="totalAmount"
          label={<FormattedMessage {...messages.totalAmountLabel} />}
          defaultValue={defaultValues?.totalAmount || 0}
          error={!!errors.totalAmount?.message}
          helperText={renderIntlMessage(errors.totalAmount?.message)}
          numberFormatProps={{ prefix: '$', decimalScale: 2, maxLength: 8 }}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberTextField
          disabled
          fullWidth
          variant="outlined"
          label={<FormattedMessage {...messages.taxedAmountLabel} />}
          value={taxedTotalAmount}
          numberFormatProps={{ ...defaultNumberFormatProps, decimalScale: 2, prefix: '$' }}
        />
      </Grid>

      {isFinancedBillingOptionType && (
        <>
          <Grid item xs={6}>
            <CheckBoxWrap
              control={control}
              label={<FormattedMessage {...inputLabelMessages.editableLabel} />}
              name="paymentEditableSchedule.editable"
            >
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="paymentEditableSchedule.value"
                  defaultValue={
                    defaultValues?.paymentEditableSchedule?.value || FrequencyType.Weekly
                  }
                  render={({ field }) => (
                    <Select
                      fullWidth
                      variant="outlined"
                      label={<FormattedMessage {...messages.paymentScheduleLabel} />}
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    >
                      {FrequencyTypes.getSelectOptions({
                        isDisabledFunc: (type: FrequencyType) => {
                          if (durationOption.type && isNumber(durationOption.amount)) {
                            return checkIsPaymentScheduleOptionDisabled(
                              durationOption.type,
                              durationOption.amount,
                              type,
                            );
                          }

                          return false;
                        },
                      })}
                    </Select>
                  )}
                />
              </Grid>
            </CheckBoxWrap>
          </Grid>

          <Grid item xs={6}>
            <NumberTextField
              disabled
              fullWidth
              variant="outlined"
              label={<FormattedMessage {...messages.pricePerBillingLabel} />}
              value={pricePerBillingAmount}
              numberFormatProps={{ ...defaultNumberFormatProps, decimalScale: 2, prefix: '$' }}
            />
          </Grid>
        </>
      )}

      {!isOutOfTerm && (
        <Grid item xs={12}>
          <CheckboxLabeled
            control={control}
            defaultValue={!!defaultValues?.useAsDefault}
            name="useAsDefault"
            label={
              <Typography variant="subtitle2">
                <FormattedMessage {...messages.defaultPaymentOptionLabel} />
              </Typography>
            }
          />
        </Grid>
      )}

      <FreezeSector
        isAllowFreeze={!!isAllowFreeze}
        formMethods={formMethods}
        defaultValues={defaultValues.freeze}
      />

      {!isFinancedBillingOptionType && (
        <SplitPaymentSection formMethods={formMethods} defaultValues={defaultValues} />
      )}

      {/* <DownPaymentsSection defaultValues={defaultValues} /> */}

      <PaymentGraceSector formMethods={formMethods} defaultValues={defaultValues} />

      <CreditCardServiceFee formMethods={formMethods} />

      {/* <InitialFeeSection formMethods={formMethods} defaultValues={defaultValues} /> */}
    </Grid>
  );
};

export default EditBillingOptionForm;
