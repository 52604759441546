import React from 'react';

export interface IDocumentSignature {
  documentId: string;
  file: File;
}

export interface ISignatureContext {
  documentSignatures: IDocumentSignature[];
  addDocumentSignature: (file: File, documentId: string) => void;
}

const SignatureContext = React.createContext<ISignatureContext>({} as ISignatureContext);

export default SignatureContext;
