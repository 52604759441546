import React, { useRef, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Box, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

import { IInvoiceDetailsDto, IInvoiceDetailsImt, InvoiceStatus } from 'common/interfaces/invoices';
import * as selectors from 'common/state/invoice/selectors';
import * as actions from 'common/state/invoice/actions';
import commonMessages from 'common/messages/messages';
import { useAppDispatch } from 'store/hooks';
import { InvoiceStatuses } from 'common/constants/invoices';
import { colors } from 'common/ui/theme/default';

const useStyles = makeStyles((theme: Theme) => ({
  dropdown: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 700,
  },
  menuItemOpenStatus: {
    color: colors.primary,
  },
  menuItemInProgressStatus: {
    color: colors.colorPicker.coralReef,
  },
  menuItem: {
    '& span:first-child': {
      color: colors.text.primary,
    },
    '&:hover span': {
      color: colors.text.contrast,
    },
  },
  menuItemText: {
    color: 'inherit',
  },
  status: {
    marginLeft: theme.spacing(4),
  },
  cursorDefault: {
    cursor: 'default',
  },
}));

interface IInvoicesSelector {
  selectedInvoice: IInvoiceDetailsImt;
  disableSelection?: boolean;
  onChange: () => void;
}

const InvoicesSelector = ({
  selectedInvoice,
  disableSelection,
  onChange,
}: IInvoicesSelector): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const currentRegisterInvoicesList: Array<IInvoiceDetailsDto> | null = useRootSelector(
    selectors.selectInvoicesForRegisterList,
  ).toJS();

  const currentRegisterInvoicesListRef = useRef(currentRegisterInvoicesList);
  currentRegisterInvoicesListRef.current = currentRegisterInvoicesList;

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (invoice: IInvoiceDetailsDto) => {
    if (invoice.id !== selectedInvoice?.get('id')) {
      dispatch(actions.selectInvoice(invoice.id));
    }
    handleClose();
    onChange();
  };

  return disableSelection ? (
    <Box>
      <Typography color="textPrimary" className={cx(classes.dropdown, classes.cursorDefault)}>
        <FormattedMessage
          {...commonMessages.invoiceNumber}
          values={{ number: selectedInvoice?.get('number') }}
        />
      </Typography>
    </Box>
  ) : (
    <Box>
      <Box onClick={handleOpenMenu}>
        <Typography color="textPrimary" className={classes.dropdown}>
          <FormattedMessage
            {...commonMessages.invoiceNumber}
            values={{ number: selectedInvoice?.get('number') }}
          />
          <ArrowDropDown fontSize="medium" />
        </Typography>
      </Box>
      <Menu
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        id="menu-alert-actions"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {currentRegisterInvoicesList?.map(invoice => (
          <MenuItem
            className={cx(
              {
                [classes.menuItemOpenStatus]: invoice.status === InvoiceStatus.OPEN,
                [classes.menuItemInProgressStatus]: invoice.status === InvoiceStatus.IN_PROGRESS,
              },
              classes.menuItem,
            )}
            key={invoice.id}
            onClick={() => handleMenuItemClick(invoice)}
          >
            <Box display="flex">
              <Typography
                className={classes.menuItemText}
                component="span"
              >{`#${invoice.number}`}</Typography>
              &nbsp;
              <Typography className={classes.menuItemText} variant="subtitle2" component="span">
                {InvoiceStatuses.translate(invoice.status)}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default React.memo(InvoicesSelector);
