import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import moment, { Moment } from 'moment-timezone';
import { Button, Grid, Typography, makeStyles, Theme } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

import useSmallScreen from 'common/hooks/useSmallScreen';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { IDateRangeFilterValue, IFilter, IFilterSettings } from 'common/interfaces/filter';
import { ITableParams } from 'common/interfaces/table';

import { ToolbarNav, BookingMenu } from 'modules/booking/components';
import { FiltersContainer, ToolbarSearchInput } from 'common/components';

import messages from 'modules/booking/messages';
import inputLabels from 'common/messages/inputLabels';
import usePermissionContext from 'common/hooks/context/usePermissionContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(0, 2, 0, 2)}!important`,
    },
  },
  filters: {
    width: isSmallscreen => (isSmallscreen ? '100%' : 'auto'),
    flex: 1,
  },
  navigation: {
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  iconButton: {
    paddingLeft: '8px',
    paddingRight: '8px',
    width: '32px',
    height: '32px',
    minWidth: 0,
  },
  mobileTitle: {
    fontSize: '1.25rem',
  },
  toolbarWrapper: {
    flexWrap: 'nowrap',

    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
}));

interface IProps {
  calendarView: 'timeGridDay' | 'timeGridWeek' | 'dayGridMonth';
  onChangeCalendarView: (view) => void;
  onCalendarNav: (dir) => void;
  filterSettings: IFilterSettings[];
  tableParams?: ITableParams;
  onChangeParams: (params: IFilter[]) => void;
  onNewEventClick: (e) => void;
  onShare: () => void;
  onSearchChange: (searchStr: string) => void;
}

function Toolbar(props: IProps): JSX.Element {
  const {
    calendarView,
    tableParams,
    filterSettings,
    onChangeParams,
    onNewEventClick,
    onChangeCalendarView,
    onCalendarNav,
    onShare,
    onSearchChange,
  } = props;

  const isSmallScreen = useSmallScreen(1024);
  const classes = useStyles(isSmallScreen);
  const renderIntlMessage = useRenderIntlMessage();

  const {
    BOOKING_EVENTS_CREATE,
    BOOKING_RESOURCES_VIEW,
    BOOKING_EVENTS_VIEW,
  } = usePermissionContext();

  const formattedValues = useMemo(() => {
    const rangeFilter = tableParams?.filters?.find(filter => filter.name === 'range');

    const start: Moment = moment((rangeFilter?.value as IDateRangeFilterValue).startDate);
    const end: Moment = moment((rangeFilter?.value as IDateRangeFilterValue).endDate).subtract(
      'days',
      1,
    );
    const month: Moment = moment(start).add(end.diff(start, 'days') / 2, 'days');

    return {
      from: start.format('MMM D'),
      to: end.format('MMM D'),
      selectedMonth: month.format('MMMM YYYY'),
    };
  }, [tableParams?.filters]);

  return (
    <Grid item container spacing={2} direction="column" className={classes.root}>
      <Grid container spacing={1} item>
        <Grid item style={{ flex: 1 }} container alignItems="center">
          {calendarView === 'dayGridMonth' ? (
            <Typography display="inline">
              <Typography component="span" variant="h3">
                {formattedValues.selectedMonth}
              </Typography>
              &nbsp;
              <Typography component="span" className={isSmallScreen ? classes.mobileTitle : ''}>
                {`${formattedValues.from} - ${formattedValues.to}`}
              </Typography>
            </Typography>
          ) : (
            <Typography
              component="h2"
              variant="h3"
              className={isSmallScreen ? classes.mobileTitle : ''}
            >
              {calendarView === 'timeGridWeek'
                ? `${formattedValues.from} - ${formattedValues.to}`
                : formattedValues.from}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <BookingMenu
            onShare={onShare}
            showShareButton={BOOKING_EVENTS_VIEW}
            showResourcesButton={BOOKING_RESOURCES_VIEW}
          />
        </Grid>
        {BOOKING_EVENTS_CREATE && isSmallScreen && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onNewEventClick}
              className={classes.iconButton}
            >
              <AddIcon />
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid
        className={classes.toolbarWrapper}
        item
        style={{ flex: 0 }}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        {BOOKING_EVENTS_CREATE && !isSmallScreen && (
          <Grid item className={classes.navigation}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={onNewEventClick}
            >
              <FormattedMessage {...messages.eventBtn} />
            </Button>
          </Grid>
        )}

        <Grid item>
          <ToolbarSearchInput
            placeholder={renderIntlMessage(inputLabels.search) || ''}
            onChange={onSearchChange}
            value={tableParams?.searchStr || ''}
          />
        </Grid>

        <Grid item className={classes.filters}>
          <FiltersContainer
            settings={filterSettings}
            onFiltersChange={onChangeParams}
            toolbarFilters={tableParams?.filters}
          />
        </Grid>

        <Grid item className={classes.navigation}>
          <ToolbarNav
            calendarView={calendarView}
            onChangeCalendarView={onChangeCalendarView}
            onCalendarNav={onCalendarNav}
            isSmallScreen={isSmallScreen}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default React.memo(Toolbar);
