import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { ReactComponent as ArrowLeft } from 'img/icons/arrow-back-thin.svg';
import { IRegisterProductItem } from 'modules/pos-settings/interfaces/register';
import { BreadcrumbsComponent, ProductItem } from 'common/components';

interface IRegisterMenuProps {
  items: IRegisterProductItem[];
  onAddProductInCart?: (item: IRegisterProductItem) => void;
  getCartQuantity?: (id: string) => number | null;
  disabled?: boolean;
}

const RegisterMenu = ({
  items,
  onAddProductInCart,
  disabled,
  getCartQuantity,
}: IRegisterMenuProps): JSX.Element => {
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);
  const [displayedItems, setDisplayedItems] = useState(items);

  const getItems = useCallback((value, breadcrumbsValue) => {
    if (breadcrumbsValue.length > 0) {
      return getItems(value.subItems[breadcrumbsValue[0].index], breadcrumbsValue.slice(1));
    }
    return value;
  }, []);

  const selectCategory = (itemIndex, product) => {
    const newBreadcrumbs: any[] = [
      ...breadcrumbs,
      { id: product.id, title: product.title, index: itemIndex },
    ];
    setBreadcrumbs(newBreadcrumbs);
  };

  const clickBreadcrumb = value => {
    setBreadcrumbs(value);
  };

  useEffect(() => {
    setDisplayedItems(getItems({ subItems: items }, breadcrumbs).subItems);
  }, [items, getItems, breadcrumbs]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <BreadcrumbsComponent onBreadcrumbClick={clickBreadcrumb} breadcrumb={breadcrumbs} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {breadcrumbs.length ? (
            <Grid item xl={2} md={4} sm={6} xs={12}>
              <Paper
                elevation={2}
                onClick={() => clickBreadcrumb(breadcrumbs.slice(0, breadcrumbs.length - 1))}
                style={{ height: '100%', cursor: 'pointer' }}
              >
                <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                  <Typography align="center" variant="inherit">
                    <ArrowLeft />
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ) : null}
          {displayedItems?.map((productItem, index) => {
            return (
              <Grid item xl={2} md={4} sm={6} xs={12} key={productItem.id}>
                <ProductItem
                  product={productItem}
                  toggleSortingMode={() => {}}
                  selectCategory={() => selectCategory(index, productItem)}
                  setShowDialog={() => {}}
                  onProductClick={onAddProductInCart}
                  cartQuantity={getCartQuantity ? getCartQuantity(productItem.id) : null}
                  disabled={disabled}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default RegisterMenu;
