import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Typography, Grid, Box, FormControlLabel } from '@material-ui/core';
import useRootSelector from 'common/hooks/useRootSelector';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { fromJS } from 'immutable';
import cx from 'classnames';
import { Alert, Checkbox, DialogComponent, MultipleSelect } from 'common/components';
import { CustomTheme } from 'common/ui/interfaces';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import messages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import * as selectors from 'common/components/PersonProfile/state/servicesModals/selectors';
import RedeemSubHeader from 'common/components/PersonProfile/components/Services/modals/RedeemSubHeader/RedeemSubHeader';
import FamilyMember from 'common/components/PersonProfile/components/FamilyMembers/FamilyMember';
import { PeakModules } from 'common/constants/peakModules';
import {
  fetchFamilyMembersToRedeem,
  resetFamilyMembersToRedeem,
} from 'common/components/PersonProfile/state/servicesModals/actions';
import { useAppDispatch } from 'store/hooks';
import { AlertTypes } from 'common/interfaces/alerts';
import inputErrors from 'common/messages/inputErrors';
import { IFamilyMemberToRedeem } from 'common/interfaces/service';
import {
  selectCurrentUserClubs,
  selectUserSelectedClubId,
} from 'modules/authentication/state/selectors';
import { getRequiredErrorMessage } from 'common/utils/validation';

const useStyles = makeStyles((theme: CustomTheme) => ({
  dialogBody: {
    overflowY: 'hidden',
    paddingTop: theme.spacing(1),
  },
  disabled: {
    opacity: 0.4,
  },
}));

export const ValidationSchema = yup.object().shape({
  club: yup
    .object()
    .nullable()
    .required(getRequiredErrorMessage),
});

// TODO - PRM-1810 tmp form interface
interface IFormValues {
  familyMembers: Array<IFamilyMemberToRedeem & { selected: boolean }>;
  club: Record<string, any> | null;
}

interface IProps {
  onClose: () => void;
  onSubmit: (serviceInstanceId: string, ids: string[], clubId: string) => void;
  isOpen: boolean;
  isLoading: boolean;
  personId: number;
  module: PeakModules;
}

const RedeemFamilyMembersModal = ({
  onClose,
  isOpen,
  onSubmit,
  isLoading,
  personId,
  module,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const familyMemberServiceDetails = useRootSelector(selectors.selectFamilyMemberServiceDetails);
  const familyMembers = useRootSelector(selectors.selectFamilyMembersToRedeem);
  const familyMembersLoading = useRootSelector(selectors.selectFamilyMembersToRedeemLoading);
  const selectedFamilyMemberServiceInstanceId = useRootSelector(
    selectors.selectSelectedFamilyMemberServiceId,
  );
  const clubs = useRootSelector(selectCurrentUserClubs);
  const initialClubId: string = useRootSelector(selectUserSelectedClubId);

  const renderIntlMessage = useRenderIntlMessage();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<IFormValues>({
    defaultValues: {
      familyMembers: [],
      club: null,
    },
    resolver: yupResolver(ValidationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onChange',
    shouldUnregister: false,
  });

  const { fields } = useFieldArray({
    control,
    name: 'familyMembers',
  });

  const familyMemberFields = watch('familyMembers');
  const selectedClub = watch('club');

  const selectedFamilyMembersCount = useMemo(
    () => familyMemberFields.reduce((acc, item) => (item.selected ? acc + 1 : acc), 0),
    [familyMemberFields],
  );

  useEffect(() => {
    if (familyMembers?.size) {
      const initialClub = clubs.find(clubItem => clubItem.get('id') === initialClubId).toJS();

      reset({
        club: initialClub,
        familyMembers: familyMembers
          .toJS()
          .map(familyMemberItem => ({ ...familyMemberItem, selected: false })),
      });
    }
  }, [clubs, familyMembers, initialClubId, reset]);

  const classes = useStyles();

  useEffect(() => {
    if (selectedFamilyMemberServiceInstanceId) {
      dispatch(fetchFamilyMembersToRedeem(personId, selectedFamilyMemberServiceInstanceId, module));
    }

    return () => {
      dispatch(resetFamilyMembersToRedeem());
    };
  }, [personId, selectedFamilyMemberServiceInstanceId, module, dispatch]);

  const availableToRedeem = familyMemberServiceDetails.get('leftSpotNumber');
  const limitToRedeem = familyMemberServiceDetails.get('limitedSpotNumber');
  const limited = familyMemberServiceDetails.get('limited');
  const hasError = selectedFamilyMembersCount > availableToRedeem;
  const disabledRedeem = !availableToRedeem || !selectedFamilyMembersCount || hasError;

  const handleSubmitForm = formValues => {
    const familyMemberIds = formValues.familyMembers.reduce(
      (acc, memberItem) => (memberItem.selected ? [...acc, memberItem.id] : acc),
      [],
    );

    onSubmit(selectedFamilyMemberServiceInstanceId, familyMemberIds, formValues.club.id);
  };

  const subHeader = Boolean(familyMemberServiceDetails) && (
    <RedeemSubHeader
      limited={limited}
      totalToRedeem={limitToRedeem}
      availableToRedeem={availableToRedeem}
    />
  );

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={familyMemberServiceDetails.get('title')}
      submitBtnTitle={<FormattedMessage {...messages.redeemBtn} />}
      subHeader={subHeader || undefined}
      scroll="paper"
      supressBottomShadow
      onSubmit={handleSubmit(handleSubmitForm)}
      disabled={disabledRedeem}
      loading={isLoading || familyMembersLoading}
    >
      {familyMembers?.size ? (
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="club"
              control={control}
              render={({ field }) => (
                <MultipleSelect
                  label={<FormattedMessage {...inputLabels.club} />}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  fullWidth
                  options={clubs.toJS()}
                  error={!!errors.club}
                  helperText={renderIntlMessage(errors.club?.message)}
                />
              )}
            />
          </Grid>

          {!!selectedClub && (
            <>
              {hasError && (
                <Grid item xs={12}>
                  <Alert
                    title={
                      <span style={{ fontWeight: 'normal' }}>
                        <FormattedMessage
                          {...inputErrors.redeemFamilyMembersError}
                          values={{ value: availableToRedeem }}
                        />
                      </span>
                    }
                    severity={AlertTypes.Warning}
                  />
                </Grid>
              )}
              {fields.map((memberItem, index) => {
                const isDisabled = memberItem.redeemed;

                return (
                  <Grid item key={memberItem.id}>
                    <Box display="flex">
                      <Box alignSelf="center" pr={1}>
                        <Controller
                          name={`familyMembers.${index}.selected`}
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              className={cx({
                                [classes.disabled]: isDisabled,
                              })}
                              disabled={isDisabled}
                              control={
                                <Checkbox
                                  size="small"
                                  onBlur={field.onBlur}
                                  checked={isDisabled || field.value}
                                  onChange={e => field.onChange(e.target.checked)}
                                />
                              }
                              label=""
                            />
                          )}
                        />
                      </Box>

                      <FamilyMember personId={personId} member={fromJS(memberItem)} />
                    </Box>
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
      ) : (
        <Typography>
          <FormattedMessage
            {...inputLabels.availableItemsForRedeem}
            values={{ items: renderIntlMessage(messages.noOption) }}
          />
        </Typography>
      )}
    </DialogComponent>
  );
};

export default RedeemFamilyMembersModal;
