import React, { FC } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { Avatar, Box, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import { ReactComponent as DefaultIcon } from 'img/icons/default-icon.svg';
import { HeaderId } from 'common/enums/html.enums';
import { selectMemberProfile } from 'memberPortalModules/MemberProfile/state/profile/selectors';
import { HOME_PATH } from 'common/constants/globalConstants';
import { NavLink } from 'react-router-dom';
import { getTimezoneOffset } from 'utils/timezone.utils';

const useStyles = makeStyles(theme => ({
  clubLogo: {
    width: 36,
    height: 36,
  },

  organizationOption: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
  },
  organizationTitle: {
    textTransform: 'none',
    fontWeight: 600,
  },
  dropDownIcon: {
    marginLeft: theme.spacing(1),
  },
  link: { display: 'flex' },
}));

const MemberPortalHeaderClubLogo: FC = () => {
  const classes = useStyles();
  const selectedMember = useRootSelector(selectMemberProfile);

  const homeClub = selectedMember?.get('homeClub');

  return (
    <Grid item>
      {homeClub?.get('imageUrl') ? (
        <NavLink to={HOME_PATH}>
          <Box className={classes.link}>
            <Avatar
              id={HeaderId.HeaderBtnClubLogo}
              className={classes.clubLogo}
              variant={homeClub?.get('imageUrl') ? 'rounded' : 'circular'}
              alt={homeClub?.get('title')}
              src={homeClub?.get('imageUrl')}
            >
              <DefaultIcon />
            </Avatar>
            <Typography className={classes.organizationOption}>
              <Typography component="span" color="secondary" className={classes.organizationTitle}>
                {homeClub.get('title')}
              </Typography>

              <Typography component="span" variant="body2" color="secondary">
                {getTimezoneOffset(homeClub?.get('timezone'))}
              </Typography>
            </Typography>
          </Box>
        </NavLink>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </Grid>
  );
};

export default MemberPortalHeaderClubLogo;
