import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { makeStyles, Typography, TypographyProps } from '@material-ui/core';

import { CustomTheme } from 'common/ui/interfaces';

import commonMessages from 'common/messages/messages';

interface IProps extends TypographyProps {
  isActive: boolean;
  activeMode?: 'light' | 'dark';
  activeLabel?: string | JSX.Element;
  inactiveLabel?: string | JSX.Element;
  className?: string;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  statusBlock: {
    borderRadius: '3px',
    fontWeight: 700,
    lineHeight: '1.15',
    display: 'inline-flex',
    height: 20,
    alignItems: 'center',
    padding: theme.spacing(0.25, 0.5),
    backgroundColor: ({ isActive, activeMode }: IProps) => {
      if (isActive && activeMode === 'light') {
        return theme.palette.primary.light;
      }

      if (isActive && activeMode === 'dark') {
        return theme.palette.primary.main;
      }

      return theme.palette.background.default;
    },
    color: ({ isActive, activeMode }) => {
      if (isActive && activeMode === 'light') {
        return theme.palette.primary.main;
      }

      if (isActive && activeMode === 'dark') {
        return theme.palette.primary.contrastText;
      }

      return theme.palette.darkBackground?.light;
    },
  },
}));

const StatusBlock = (props: IProps): JSX.Element => {
  const { isActive, activeLabel, inactiveLabel, className, activeMode, ...other } = props;

  const classes = useStyles({ ...props, activeMode: activeMode || 'light' });

  return (
    <Typography className={cx(classes.statusBlock, className)} variant="button" {...other}>
      {isActive
        ? activeLabel || <FormattedMessage {...commonMessages.activeOption} />
        : inactiveLabel || <FormattedMessage {...commonMessages.inactiveOption} />}
    </Typography>
  );
};

export default StatusBlock;
