import React from 'react';
import cx from 'classnames';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

import { GuestStatus } from 'modules/front-desk/components';
import { getInitials } from 'helpers/common';
import { CustomTheme } from 'common/ui/interfaces';
import { IKidZoneAutocompleteCustomerImt } from 'modules/kid-zone/interfaces';
import { AvatarWithInitials, TooltipTypography } from 'common/components';
import { firstLetterToUppercase } from 'common/utils';

interface IGuardianInfoProps {
  isSmallScreen: boolean;
  member: IKidZoneAutocompleteCustomerImt;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  guardianItem: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarWrap: {
    position: 'relative',
    flex: 'none',
    borderRadius: '4px',
    backgroundColor: theme.palette.secondary.light,
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '14px',
    fontWeight: 700,
    color: theme.palette.darkBackground?.light,
  },
  status: {
    marginTop: 0,
  },
  relationshipTitle: {
    marginTop: theme.spacing(0.5),
  },
}));

const GuardianInfo = (props: IGuardianInfoProps): JSX.Element => {
  const { isSmallScreen, member } = props;

  const classes = useStyles();

  const initials = getInitials(member.get('firstName'), member.get('lastName'));

  const hasRelationship = Boolean(member.get('relationship'));

  const boxProps = hasRelationship
    ? {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 65px)',
      }
    : {};

  const fullName = `${member.get('firstName')} ${member.get('lastName')}`;

  return (
    <Grid item xs={isSmallScreen ? 6 : 12} className={classes.guardianItem}>
      <AvatarWithInitials
        marginRight={1}
        width={56}
        height={56}
        variant="square"
        borderRadius="3px"
        initials={initials}
        imageUrl={member.get('imageUrl')}
      />

      <Box {...boxProps}>
        {hasRelationship ? (
          <TooltipTypography ellipsized>{fullName}</TooltipTypography>
        ) : (
          <Typography>{fullName}</Typography>
        )}

        <GuestStatus
          className={cx({
            [classes.status]: hasRelationship,
          })}
          type={member.get('type')}
        />

        {hasRelationship && (
          <Typography color="textSecondary" variant="body2" className={classes.relationshipTitle}>
            {firstLetterToUppercase(member.get('relationship'))}
          </Typography>
        )}
      </Box>
    </Grid>
  );
};

export default GuardianInfo;
