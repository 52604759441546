import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import inputLabels from 'common/messages/inputLabels';
import { getAccessByPropPath } from 'common/utils/errorObject';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { PhoneNumberInput, TextField } from 'common/components';
import SectionTitle from '../SectionTitle/SectionTitle';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import { RequiredProfileInfo } from 'modules/corporate-settings/constants/requiredFields';

interface IProps {
  name?: string;
  title?: JSX.Element;
  recommendedFields: TShortProfileFieldInfoImt;
}

const EmergencySection = ({ title, name, recommendedFields }: IProps): JSX.Element => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  const renderIntlMessage = useRenderIntlMessage();

  return (
    <Grid container spacing={1}>
      {title && (
        <Grid item xs={12}>
          <SectionTitle title={title} />
        </Grid>
      )}

      <Grid item xs={6}>
        <Controller
          control={control}
          name={name ? `${name}.emergencyName` : 'emergencyName'}
          defaultValue=""
          render={({ field }) => (
            <TextField
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              variant="outlined"
              label={renderIntlMessage(inputLabels.name)}
              fullWidth
              error={
                !!getAccessByPropPath(errors, name ? `${name}.emergencyName` : 'emergencyName')
              }
              helperText={renderIntlMessage(
                getAccessByPropPath(errors, name ? `${name}.emergencyName` : 'emergencyName')
                  ?.message,
              )}
              recommended={Boolean(
                recommendedFields?.get(RequiredProfileInfo.EmergencyContactInfo),
              )}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name={name ? `${name}.emergencyPhone` : 'emergencyPhone'}
          control={control}
          render={({ field }) => (
            <PhoneNumberInput
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              error={
                !!getAccessByPropPath(errors, name ? `${name}.emergencyPhone` : 'emergencyPhone')
              }
              helperText={renderIntlMessage(
                getAccessByPropPath(errors, name ? `${name}.emergencyPhone` : 'emergencyPhone')
                  ?.message,
              )}
              recommended={Boolean(
                recommendedFields?.get(RequiredProfileInfo.EmergencyContactInfo),
              )}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          control={control}
          name={name ? `${name}.emergencyRelationship` : 'emergencyRelationship'}
          defaultValue=""
          render={({ field }) => (
            <TextField
              value={field.value}
              onBlur={field.onBlur}
              onChange={field.onChange}
              label={renderIntlMessage(inputLabels.emergencyContactRelationship)}
              variant="outlined"
              fullWidth
              error={
                !!getAccessByPropPath(
                  errors,
                  name ? `${name}.emergencyRelationship` : 'emergencyRelationship',
                )
              }
              helperText={renderIntlMessage(
                getAccessByPropPath(
                  errors,
                  name ? `${name}.emergencyRelationship` : 'emergencyRelationship',
                )?.message,
              )}
              recommended={Boolean(
                recommendedFields?.get(RequiredProfileInfo.EmergencyContactInfo),
              )}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(EmergencySection);
