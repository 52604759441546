import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import PersonSimpleView from '../PersonSimpleView/PersonSimpleView';

const useStyles = makeStyles({
  btn: {
    padding: '2px 6px 2px 0',
    textTransform: 'none',
  },
  avatar: {
    width: '28px',
    height: '28px',
  },
});

interface IMemberNameProps {
  firstName: string;
  lastName: string;
  status?: string;
  photoUrl?: string;
  onClick?: () => void;
  code?: string | number;
}

const MemberName = (props: IMemberNameProps): JSX.Element => {
  const classes = useStyles();
  const { firstName, lastName, status, photoUrl, onClick, code } = props;

  return (
    <Button className={classes.btn} onClick={onClick}>
      <PersonSimpleView
        bold
        firstName={firstName}
        lastName={lastName}
        imageUrl={photoUrl}
        memberStatus={status}
        code={code}
      />
    </Button>
  );
};

export default React.memo(MemberName);
