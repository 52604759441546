import * as yup from 'yup';
import {
  getMinMaxNumberSchema,
  minMaxNumberSchema,
} from 'common/validationSchemas/minMaxNumberSchema';
import inputErrors from 'common/messages/inputErrors';
import { getPriceFromNumber } from 'common/utils/calculate';
import { SPLIT_SUM_ERROR } from 'modules/services/components/EditPackageForm/BillingStep/billingValidationSchema';
import { FrequencyType, PackageCostType } from 'modules/services/constants/packages';
import { includedFeeTypeSchema } from 'modules/services/components/EditPackageForm/FeesStep/feesValidationSchema';
import { FeeType, IFee } from 'modules/services/interfaces/fees';
import { IBillingOption, IEditable } from 'modules/services/interfaces/packages';
import { TestContextExtended } from 'common/interfaces/validation';
import { getRequiredErrorMessage } from 'common/utils/validation';

export const feeInstanceSchema = yup.object().shape({
  excludable: yup.boolean(),
  included: yup.boolean(),
  split: yup.boolean(),

  fee: includedFeeTypeSchema.required(getRequiredErrorMessage),

  totalAmount: yup
    .object()
    .nullable()
    .when('included', {
      is: true,
      then: minMaxNumberSchema.required(getRequiredErrorMessage),
    }),
  chargeAfterDays: yup
    .object()
    .nullable()
    .when('included', {
      is: true,
      then: minMaxNumberSchema.required(getRequiredErrorMessage),
    }),

  splitPaymentSchedule: yup
    .string()
    .nullable()
    .when(['included', 'fee', 'split'], {
      is: (included: boolean, fee: IFee, split: boolean): boolean =>
        included && fee.type === FeeType.OneTime && split,
      then: yup
        .string()
        .nullable()
        .oneOf(Object.values(FrequencyType))
        .required(getRequiredErrorMessage),
    }),

  paymentSchedule: yup
    .string()
    .nullable()
    .when(['included', 'fee'], {
      is: (included: boolean, fee: IFee): boolean => included && fee.type === FeeType.Regular,
      then: yup
        .string()
        .nullable()
        .oneOf(Object.values(FrequencyType))
        .required(getRequiredErrorMessage),
    }),

  paymentsNumber: yup
    .object()
    .nullable()
    .when(['included', 'fee', 'split'], {
      is: (included: boolean, fee: IFee, split: boolean): boolean =>
        included && fee.type === FeeType.OneTime && split,
      then: minMaxNumberSchema.required(getRequiredErrorMessage),
    }),

  editableSplits: yup
    .array()
    .nullable()
    .when(['included', 'fee', 'split', 'paymentsNumber'], {
      is: (included: boolean, fee: IFee, split: boolean, paymentsNumber: IEditable) =>
        included && split && fee.type === FeeType.OneTime && !!paymentsNumber,
      then: yup
        .array()
        .of(minMaxNumberSchema)
        .test(
          SPLIT_SUM_ERROR,
          () => inputErrors.splitsSumNotEqualDownError,
          (splits, context) => {
            const ctx: any = context;

            const splitsTotal = splits?.reduce((count, split) => count + Number(split.value), 0);

            const totalAmount = ctx.from[0].value?.totalAmount?.value;

            return getPriceFromNumber(splitsTotal || '') === getPriceFromNumber(totalAmount);
          },
        ),
    }),
});

export const FeesConfigurationSchema = yup.object().shape({
  fees: yup
    .array()
    .nullable()
    .of(feeInstanceSchema),
});

const splitPaymentInstanceSchema = yup.object().shape({
  allow: yup.boolean(),
  paymentEditableNumber: yup
    .object()
    .nullable()
    .when('allow', {
      is: true,
      then: minMaxNumberSchema.required(getRequiredErrorMessage),
    }),
  paymentEditableSplits: yup.array().when('allow', {
    is: true,
    then: yup
      .array()
      .of(minMaxNumberSchema)
      .test(
        SPLIT_SUM_ERROR,
        () => inputErrors.splitsSumNotEqualBillingPriceError,
        (splits, context) => {
          if (!splits || !splits.length) return true;

          const ctx = context as TestContextExtended<IBillingOption>;

          const splitsTotal = splits.reduce((count, split) => count + Number(split.value), 0);

          const totalAmount = ctx.from[1].value?.totalAmount;

          return getPriceFromNumber(splitsTotal) === getPriceFromNumber(totalAmount || '');
        },
      ),
  }),
});

export const PackageConfigurationSchema = yup.object().shape({
  billingOption: yup
    .string()
    .nullable()
    .when('costType', {
      is: value => PackageCostType.Free !== value,
      then: yup
        .string()
        .nullable()
        .required(getRequiredErrorMessage),
    }),

  paymentGrace: yup
    .object()
    .nullable()
    .shape({
      allow: yup.boolean(),
      editableDurationNumber: yup
        .object()
        .nullable()
        .when('allow', {
          is: true,
          then: minMaxNumberSchema,
        }),
    }),

  splitting: splitPaymentInstanceSchema.nullable(),

  downPayment: yup
    .object()
    .nullable()
    .shape({
      allow: yup.boolean(),
      editableNumber: yup
        .object()
        .nullable()
        .when('allow', {
          is: true,
          then: minMaxNumberSchema,
        }),
      editableAmount: yup
        .object()
        .nullable()
        .when('allow', {
          is: true,
          then: minMaxNumberSchema,
        }),
      editableSplits: yup
        .array()
        .nullable()
        .when('allow', {
          is: true,
          then: yup
            .array()
            .of(minMaxNumberSchema)
            .test(
              SPLIT_SUM_ERROR,
              () => inputErrors.splitsSumNotEqualDownError,
              (splits, context) => {
                const ctx: any = context;

                const splitsTotal = splits?.reduce(
                  (count, split) => count + Number(split.value),
                  0,
                );

                const downPaymentTotal = ctx.from[0].value?.editableAmount?.value;

                return (
                  getPriceFromNumber(splitsTotal || '') === getPriceFromNumber(downPaymentTotal)
                );
              },
            ),
        }),
    }),
});

export const MembershipConfigurationSchema = PackageConfigurationSchema.shape({
  freeze: yup
    .object()
    .nullable()
    .shape({
      allow: yup.boolean(),
      editableDaysNumber: getMinMaxNumberSchema().nullable(),
    }),
});

export const OutOfTermBillingOptionSchema = yup.object().shape({
  outOfTermBillingOption: MembershipConfigurationSchema.shape({
    billingOption: yup.string(),
  }),
});

export const MembershipValidationSchema = yup.object().shape({
  club: yup
    .string()
    .nullable()
    .required(getRequiredErrorMessage),

  membership: yup
    .string()
    .nullable()
    .required(getRequiredErrorMessage),
});

export const ServicePackageValidationSchema = yup.object().shape({
  packageConfiguration: PackageConfigurationSchema,
});
