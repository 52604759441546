import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { Email as EmailIcon, LocationOn, PhoneIphone as PhoneIcon } from '@material-ui/icons';

import { ReactComponent as Barcode } from 'img/icons/barcode.svg';
import { IEmployeeProfileDtoImt } from 'common/interfaces/employee';
import { genderTypeLabels, maritalStatusesLabels } from 'common/constants';
import { getPersonAgeString } from 'common/utils/time';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import BasicInfo from './BasicInfo';
import { convertPhoneNumber } from 'helpers/common';

interface GeneralInfoSectionProps {
  profile: IEmployeeProfileDtoImt;
  shouldBeHiddenBasicInfo?: boolean | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  contactInfoIcon: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(0.5),
    verticalAlign: 'middle',
    color: theme.palette.text.secondary,
  },
}));

const GeneralInfoSection = (props: GeneralInfoSectionProps): JSX.Element => {
  const { profile, shouldBeHiddenBasicInfo = false } = props;
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();

  return (
    <Box fontSize="14px" display="flex" flexDirection="column">
      {shouldBeHiddenBasicInfo || <BasicInfo profile={profile} />}
      <Box pb={2}>
        <Typography color="textPrimary">
          {profile.get('gender') && (
            <Typography component="span">{`${renderIntlMessage(
              genderTypeLabels.message(profile.get('gender')),
            )}, `}</Typography>
          )}
          {profile.get('birthday') && (
            <Typography component="span">{getPersonAgeString(profile.get('birthday'))}</Typography>
          )}
          {profile.get('maritalStatus') &&
            ` ${renderIntlMessage(maritalStatusesLabels.message(profile.get('maritalStatus')))}`}
        </Typography>
      </Box>
      <Box display="flex" flexWrap="wrap" pb={2}>
        <Box pr={3} display="flex">
          <PhoneIcon className={classes.contactInfoIcon} />
          <Typography color="textPrimary">{convertPhoneNumber(profile.get('phone'))}</Typography>
        </Box>
        <Box pr={3} display="flex">
          <EmailIcon className={classes.contactInfoIcon} />
          <Typography color="textPrimary">{profile.get('email')}</Typography>
        </Box>
      </Box>
      <Box display="flex" pr={3} pb={2}>
        <LocationOn className={classes.contactInfoIcon} />
        <Typography color="textPrimary">{profile.get('addressLine')}</Typography>
      </Box>
      {profile.get('barcode') && (
        <Box display="flex">
          <Barcode className={classes.contactInfoIcon} />
          <Typography color="textPrimary">{profile.get('barcode')}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default GeneralInfoSection;
