import { string, boolean, object } from 'yup';
import { getRequiredErrorMessage } from 'common/utils/validation';
import { FeeType } from '../../../interfaces/fees';
import messages from '../../../messages/fees';

export const feeValidationSchema = object().shape({
  title: string()
    .min(2, () => messages.feeNameMinLengthError)
    .max(100, () => messages.feeNameMaxLengthError)
    .required(getRequiredErrorMessage),
  active: boolean(),
  type: string()
    .oneOf(Object.values(FeeType))
    .required(getRequiredErrorMessage),
});
