import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Grid, Typography } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import MemberStatusView from 'common/components/MemberStatusView/MemberStatusView';
import { PtCrmRoutes } from 'modules/personal-training-crm/routes';
import { CRMTemplatesRoutes } from 'modules/crm/routes';

const StyledLink = styled(Link)({
  '&:hover': {
    textDecoration: 'underline',
  },
});

const StyledAvatar = styled(Avatar)({
  width: 20,
  height: 20,
});

const useStyles = makeStyles(() => ({
  item: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
}));

interface IProps {
  id: string;
  firstName: string;
  lastName: string;
  status: string;
  photoUrl?: string;
  small?: boolean;
  isCrmModule: boolean;
}

const LeadName = ({
  id,
  firstName,
  lastName,
  status,
  small,
  photoUrl,
  isCrmModule,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const viewLeadPath = isCrmModule
    ? `/${CRMTemplatesRoutes.CRM}/${CRMTemplatesRoutes.LEADS}/${id}`
    : `/${PtCrmRoutes.PT_CRM}/${PtCrmRoutes.LEADS}/${id}`;

  return (
    <Grid container spacing={1} alignItems="center">
      {photoUrl && (
        <Grid className={classes.item} item>
          <StyledAvatar alt={firstName} src={photoUrl} />
        </Grid>
      )}

      {small ? (
        <>
          <Grid item>
            <StyledLink to={viewLeadPath}>
              <Typography variant="subtitle2">{`${firstName} ${lastName}`}</Typography>
            </StyledLink>
          </Grid>

          <Grid item>
            <MemberStatusView status={status} small full />
          </Grid>
        </>
      ) : (
        <>
          <Grid className={classes.item} item>
            <MemberStatusView status={status} />
          </Grid>

          <Grid item>
            <StyledLink to={viewLeadPath}>
              <Typography variant="subtitle2">{`${firstName} ${lastName}`}</Typography>
            </StyledLink>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default React.memo(LeadName);
