import React, { useRef } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { makeStyles } from '@material-ui/core/styles';

import EmojiTool from 'common/components/DraftEditor/EmojiTool';
import TextArea from 'common/components/TextArea/TextArea';

interface ITextAreaEmojiProps extends Omit<TextFieldProps, ''> {
  value: string;
  className?: string;
  maxSymbols?: number;
  onChange: (value) => void;
}

const useStyles = makeStyles({
  extraClassName: {
    position: 'absolute',
    top: '0',
    right: '0',

    '& .rdw-option-wrapper': {
      '&:hover, &:active': {
        boxShadow: 'none',
        backgroundColor: 'none',
      },
    },
  },
});

const TextAreaEmoji = (props: ITextAreaEmojiProps) => {
  const { onChange, value, className, InputProps } = props;

  const textFieldRef = useRef<any>(null);

  const classes = useStyles();

  const onEmojiClick = emoji => {
    const cursor = textFieldRef.current?.selectionStart;
    const text = `${value.slice(0, cursor)}${emoji}${value.slice(cursor)}`;
    onChange(text);

    // Codes added for the new cursor
    const newCursor = cursor + emoji.length;
    setTimeout(() => textFieldRef.current?.setSelectionRange(newCursor, newCursor));
  };

  return (
    <TextArea
      {...props}
      className={className}
      onInputRef={ref => {
        textFieldRef.current = ref;
      }}
      InputProps={{
        ...InputProps,
        endAdornment: <EmojiTool className={classes.extraClassName} onChange={onEmojiClick} />,
      }}
    />
  );
};

export default TextAreaEmoji;
