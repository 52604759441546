import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MenuItem } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { selectAllEvent, selectAllEventLoading } from 'modules/timeclock/state/selectors';
import useRootSelector from 'common/hooks/useRootSelector';
import { DialogComponent, Select } from 'common/components';
import {
  clockInEmployeePosition,
  clockOutEmployeePosition,
  getAllEvents,
} from 'modules/timeclock/state/actions';
import { ITimeclockEventsRequestData } from 'modules/timeclock/interfaces/timeclock';
import { getRequiredErrorMessage } from 'common/utils/validation';
import inputLabels from 'common/messages/inputLabels';
import timeclockMessages from 'modules/timeclock/messages';

// TODO - PRM-1810 tmp form interface
interface IFormValues {
  eventId: any;
}

interface IUnitEventModal {
  isOpen: boolean;
  onClose: () => void;
  selectedPosition: any;
  employeeId: number;
  isProfile: boolean;
}

const getValidationSchema = yup.object().shape({
  eventId: yup
    .string()
    .nullable()
    .required(getRequiredErrorMessage),
});

const UnitEventModal: FC<IUnitEventModal> = ({
  onClose,
  isOpen,
  selectedPosition,
  employeeId,
  isProfile,
}) => {
  const dispatch = useDispatch();
  const formId = 'unit-positions-form-id';
  const [timezoneMoment] = useTimezoneMoment();
  const renderIntlMessage = useRenderIntlMessage();
  const allEvents = useRootSelector(selectAllEvent);
  const allEventLoading = useRootSelector(selectAllEventLoading);

  const formMethods = useForm<IFormValues>({
    defaultValues: {
      eventId: null,
    },
    resolver: yupResolver<IFormValues>(getValidationSchema),
    mode: 'onBlur',
  });

  const { control, handleSubmit, formState } = formMethods;
  const { errors } = formState;

  useEffect(() => {
    const requestData: ITimeclockEventsRequestData = {
      clubIds: selectedPosition.club ? [selectedPosition.club.id] : [],
      rangeEndDate: timezoneMoment()
        .endOf('day')
        .utc()
        .format(),
      rangeStartDate: timezoneMoment()
        .startOf('day')
        .utc()
        .format(),
    };
    dispatch(getAllEvents(requestData, isProfile));
  }, [dispatch, selectedPosition, isProfile, timezoneMoment]);

  const submit = data => {
    const selectedEvent = allEvents.find(event => event.id === Number(data.eventId));

    if (selectedEvent) {
      const clockData = {
        jobPositionId: selectedPosition.id,
        eventId: data.eventId,
        eventDate: timezoneMoment(selectedEvent.date)
          .utc()
          .format(),
      };

      if (!selectedPosition.clockEdIn) {
        dispatch(clockInEmployeePosition(employeeId, clockData));
      } else {
        dispatch(clockOutEmployeePosition(employeeId, clockData));
      }

      onClose();
    }
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      loading={allEventLoading}
      onClose={onClose}
      title={<FormattedMessage {...timeclockMessages.unitOption} />}
      size="xs"
      supressBottomShadow
      hasShadowsOnScroll
      onSubmit={handleSubmit(submit)}
      formId={formId}
    >
      <form onSubmit={handleSubmit(submit)}>
        <Controller
          control={control}
          name="eventId"
          render={({ field }) => (
            <Select
              label={<FormattedMessage {...inputLabels.eventLabel} />}
              variant="outlined"
              fullWidth
              value={field.value}
              onChange={field.onChange}
              disabled={allEventLoading}
              error={!!errors.eventId}
              helperText={renderIntlMessage(errors.eventId?.message)}
            >
              {allEvents?.map(event => (
                <MenuItem key={event.id} value={event.id}>
                  {event.title}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </form>
    </DialogComponent>
  );
};

export default UnitEventModal;
