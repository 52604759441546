import React from 'react';
import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import RedeemProgress from '../../RedeemProgress/RedeemProgress';
import { Button } from 'common/components/index';
import commonMessages from 'common/messages/messages';
import { RedeemType } from 'common/constants/service';
import { DurationUnit } from 'common/interfaces/common';
import { CustomTheme } from 'common/ui/interfaces';

interface IRedeemSubHeaderProps {
  handleOnRedeemClick?: () => void;
  limited?: boolean;
  redeemType?: RedeemType;
  redeemDurationUnit?: DurationUnit;
  totalToRedeem: number;
  availableToRedeem: number;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  headerActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: `${theme.spacing(2)}px`,
    paddingTop: theme.spacing(3),
  },
}));

const RedeemSubHeader = (props: IRedeemSubHeaderProps): JSX.Element => {
  const {
    handleOnRedeemClick,
    limited = false,
    redeemType,
    totalToRedeem,
    redeemDurationUnit,
    availableToRedeem,
    disabled = false,
  } = props;

  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Box>
        <RedeemProgress
          limited={limited}
          redeemType={redeemType}
          redeemUnit={redeemDurationUnit}
          totalToRedeem={totalToRedeem}
          availableToRedeem={availableToRedeem}
        />
      </Box>
      {Boolean(handleOnRedeemClick) && (
        <Box className={classes.headerActions}>
          <Button
            disabled={disabled}
            color="primary"
            variant="contained"
            fullWidth
            size="medium"
            onClick={handleOnRedeemClick}
          >
            <FormattedMessage {...commonMessages.redeemBtn} />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default RedeemSubHeader;
