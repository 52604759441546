/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { Component } from 'react';
import { Box, List, StyledComponentProps, Typography, withStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { List as ImmutableList } from 'immutable';
import { Connect } from 'common/decorators';
import { LoadingBackdrop, ScrollBox } from 'common/components';
import { DispatchFunction } from 'common/state/interfaces';
import notesMessages from './messages/notes';
import { CheckInBlock, Slider } from 'common/components/PersonProfile/components';
import {
  INote,
  INoteCodeRequestPayload,
  INoteImt,
} from 'common/components/PersonProfile/interfaces';
import {
  makeSelectCreatedNote,
  makeSelectCreateNoteLoading,
  makeSelectDeletedNote,
  makeSelectDeletedNoteLoading,
  makeSelectNoteCodes,
  makeSelectNoteCodesIsLoading,
  makeSelectPersonNotes,
  makeSelectPersonNotesLoading,
  makeSelectUpdatedNote,
  makeSelectUpdatedNoteLoading,
} from 'common/components/PersonProfile/state/selectors';
import * as actions from 'common/components/PersonProfile/state/actions';
import messages from 'common/components/PersonProfile/messages';
import { FormattedMessage, injectIntl } from 'react-intl';
import NotesItem from './NotesItem';
import NoteModal from './modals/NoteModal';
import DeleteNoteModal from './modals/DeleteNoteModal';
import { INoteCodeDictionaryItemImt } from 'common/interfaces/dictionary';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';
import { Button } from 'common/components/index';
import { selectRoot } from 'common/hooks/useRootSelector';

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%',
      minHeight: '150px',
    },
    list: {
      padding: '0',
      marginRight: '-16px',
      height: 'calc(100% - 30px)',
      overflow: 'hidden',
    },
    noteInput: {
      marginBottom: theme.spacing(0.5),
      '& .MuiOutlinedInput-multiline': {
        padding: theme.spacing(2, 1.5),
      },
      '& textarea': {
        minHeight: '113px',
        maxHeight: '200px',
        overflowY: 'auto!important',
      },
    },
    symbols: {
      textAlign: 'right',
      opacity: 0.4,
    },
    label: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(1.5, 0, -1, 0),
      '& span': {
        fontWeight: 500,
      },
    },
    notesInputWrapper: {
      overflow: 'hidden',
    },
  });

interface INotesProps extends StyledComponentProps {
  customerId: number;
  intl: any;
  module: PeakModuleForNewPersonType;
  notes?: ImmutableList<INoteImt>;
  noteCodes?: ImmutableList<INoteCodeDictionaryItemImt>;
  noteCodesIsLoading?: boolean;
  createdNote?: INoteImt;
  updatedNote?: INoteImt;
  deletedNote?: INoteImt;
  notesLoading?: boolean;
  createNoteLoading?: boolean;
  updateNoteLoading?: boolean;
  deleteNoteLoading?: boolean;
  isMobile?: boolean;

  fetchPersonNotes?: (customerId: number, module: PeakModuleForNewPersonType) => void;
  addPersonNote?: (
    requestData: INoteCodeRequestPayload,
    module: PeakModuleForNewPersonType,
  ) => void;
  updatePersonNote?: (
    requestData: INoteCodeRequestPayload,
    module: PeakModuleForNewPersonType,
  ) => void;
  deletePersonNote?: (id: string, customerId: number, module: PeakModuleForNewPersonType) => void;
  searchNoteCodes?: (search: string, customerId: number) => void;
  clearSearchNoteCodesResult?: (customerId: number) => void;
  resetCreatedNote?: (customerId: number) => void;
}

interface INotesState {
  isNoteModalOpen: boolean;
  isDeleteNoteModalOpen: boolean;
  clickedNote: INote | null;
}

@(Connect({
  makeStateToProps: () => {
    return (state, ownProps) => ({
      notes: selectRoot(makeSelectPersonNotes())(state, ownProps),
      noteCodes: selectRoot(makeSelectNoteCodes)(state, ownProps),
      createdNote: selectRoot(makeSelectCreatedNote())(state, ownProps),
      updatedNote: selectRoot(makeSelectUpdatedNote())(state, ownProps),
      deletedNote: selectRoot(makeSelectDeletedNote())(state, ownProps),
      notesLoading: selectRoot(makeSelectPersonNotesLoading())(state, ownProps),
      noteCodesIsLoading: selectRoot(makeSelectNoteCodesIsLoading)(state, ownProps),
      createNoteLoading: selectRoot(makeSelectCreateNoteLoading())(state, ownProps),
      updateNoteLoading: selectRoot(makeSelectUpdatedNoteLoading())(state, ownProps),
      deleteNoteLoading: selectRoot(makeSelectDeletedNoteLoading())(state, ownProps),
    });
  },
  mapDispatchToProps: (dispatch: DispatchFunction) => ({
    fetchPersonNotes: (customerId: number, module) =>
      dispatch(actions.fetchPersonNotes(customerId, module)),
    addPersonNote: (requestData: INoteCodeRequestPayload, module) =>
      dispatch(actions.createPersonNote(requestData, module)),
    updatePersonNote: (requestData: INoteCodeRequestPayload, module) =>
      dispatch(actions.updatePersonNoteById(requestData, module)),
    deletePersonNote: (id: string, customerId: number, module) =>
      dispatch(actions.deletePersonNote(id, customerId, module)),
    searchNoteCodes: (search: string, customerId: number) =>
      dispatch(actions.searchNoteCodesThunk(search, customerId)),
    clearSearchNoteCodesResult: (customerId: number) =>
      dispatch(actions.resetNoteCodes(null, customerId)),
    resetCreatedNote: (customerId: number) =>
      dispatch(actions.resetPersonCreatedNoteAction(null, customerId)),
  }),
}) as any)
@(withStyles(styles) as any)
class Notes extends Component<INotesProps, INotesState> {
  constructor(props: INotesProps) {
    super(props);
    this.state = {
      isNoteModalOpen: false,
      isDeleteNoteModalOpen: false,
      clickedNote: null,
    };
  }

  componentDidMount(): void {
    const { customerId, fetchPersonNotes, module } = this.props;

    if (fetchPersonNotes) {
      fetchPersonNotes(customerId, module);
    }
  }

  componentDidUpdate(prevProps: INotesProps): void {
    const {
      createdNote,
      updatedNote,
      deletedNote,
      customerId,
      fetchPersonNotes,
      module,
    } = this.props;

    if (prevProps.createdNote !== createdNote) {
      this.onCreateNote();
    }
    if (prevProps.updatedNote !== updatedNote) {
      this.onEditNote();
    }
    if (prevProps.deletedNote !== deletedNote) {
      this.onDeleteNote();
    }

    if (prevProps.customerId !== customerId && fetchPersonNotes) {
      fetchPersonNotes(customerId, module);
    }
  }

  private onCreateNote = (): void => {
    const { fetchPersonNotes, customerId, module } = this.props;

    if (fetchPersonNotes) {
      fetchPersonNotes(customerId, module);
    }

    this.setState({ isNoteModalOpen: false });
  };

  private onEditNote = (): void => {
    const { fetchPersonNotes, customerId, module } = this.props;

    if (fetchPersonNotes) {
      fetchPersonNotes(customerId, module);
    }

    this.setState({ isNoteModalOpen: false, clickedNote: null });
  };

  private onDeleteNote = (): void => {
    const { fetchPersonNotes, customerId, module } = this.props;

    if (fetchPersonNotes) {
      fetchPersonNotes(customerId, module);
    }

    this.setState({ isDeleteNoteModalOpen: false, clickedNote: null });
  };

  private onEditClick = (note: INote): void => {
    this.setState({ isNoteModalOpen: true, clickedNote: note });
  };

  private onDeleteClick = (note: INote): void => {
    this.setState({ isDeleteNoteModalOpen: true, clickedNote: note });
  };

  private updatePersonNoteHandler = (note: INoteCodeRequestPayload): void => {
    const { updatePersonNote, customerId, module } = this.props;

    if (updatePersonNote) {
      updatePersonNote({ ...note, customerId }, module);
    }
  };

  private deletePersonNoteHandler = (id: string): void => {
    const { deletePersonNote, customerId, module } = this.props;

    if (deletePersonNote) {
      deletePersonNote(id, customerId, module);
    }
  };

  private onToggleAddNoteModal = (): void => {
    this.setState({ isNoteModalOpen: true });
  };

  private clearNoteCodesResult = () => {
    const { customerId, clearSearchNoteCodesResult } = this.props;

    if (clearSearchNoteCodesResult) {
      clearSearchNoteCodesResult(customerId);
    }
  };

  private onSubmitNoteModal = (values: INoteCodeRequestPayload) => {
    const { addPersonNote, customerId, module } = this.props;
    const { id } = values;

    if (id) {
      this.updatePersonNoteHandler(values);
    } else {
      if (addPersonNote) {
        addPersonNote({ ...values, customerId }, module);
      }
    }
  };

  render(): JSX.Element {
    const {
      classes,
      isMobile,
      notes,
      noteCodes,
      notesLoading,
      searchNoteCodes,
      customerId,
      createNoteLoading,
      updateNoteLoading,
      deleteNoteLoading,
      noteCodesIsLoading,
      intl,
    } = this.props;
    const { isNoteModalOpen, clickedNote, isDeleteNoteModalOpen } = this.state;

    const listItems = notes?.map(item => (
      <NotesItem
        key={item.get('id')}
        note={item}
        onDeleteClick={this.onDeleteClick}
        onEditClick={this.onEditClick}
      />
    ));

    return (
      <CheckInBlock
        title={intl.formatMessage(notesMessages.notes)}
        buttonTitle={
          <Button color="primary" hasHoverStyle>
            <AddIcon width={20} height={20} />
          </Button>
        }
        onClickButton={this.onToggleAddNoteModal}
        className={classes?.wrapper}
      >
        {notes?.size ? (
          <List className={classes?.list}>
            {isMobile ? <Slider rows={2}>{listItems}</Slider> : <ScrollBox>{listItems}</ScrollBox>}
          </List>
        ) : (
          <Box className="empty-section-placeholder">
            <Typography className="empty-text">
              <FormattedMessage {...messages.emptyNotesListTextMessage} />
            </Typography>
            <Typography className="empty-text">
              <FormattedMessage {...messages.emptyNotesListTextDescription} />
            </Typography>
          </Box>
        )}
        <LoadingBackdrop isLoading={notesLoading} />
        {isNoteModalOpen && (
          <NoteModal
            onCloseModal={() => this.setState({ isNoteModalOpen: false, clickedNote: null })}
            loading={createNoteLoading || !!updateNoteLoading}
            noteCodesIsLoading={!!noteCodesIsLoading}
            isNoteModalOpen={isNoteModalOpen}
            noteCodes={noteCodes || ImmutableList()}
            onSubmit={this.onSubmitNoteModal}
            clearSearchResults={this.clearNoteCodesResult}
            customerId={customerId}
            searchNoteCodes={searchNoteCodes || (() => {})}
            editNote={clickedNote as any} // TODO - PRM-3575 need types
          />
        )}
        <DeleteNoteModal
          noteToDelete={clickedNote as any} // TODO - PRM-3575 need types
          isNoteModalOpen={isDeleteNoteModalOpen}
          deletePersonNote={this.deletePersonNoteHandler}
          loading={!!deleteNoteLoading}
          onCloseModal={() => this.setState({ isDeleteNoteModalOpen: false })}
        />
      </CheckInBlock>
    );
  }
}

export default injectIntl(Notes);
