import * as yup from 'yup';
import { getRequiredErrorMessage } from 'common/utils/validation';
import validationMessages from 'common/messages/validation';

export const webPassSchema = yup
  .string()
  .min(8, () => validationMessages.passwordLength)
  .max(20, () => validationMessages.passwordLength)
  .matches(
    /^[A-Za-z0-9!@#&()–:;'`,?/*~$^+=<>.%_]*$/,
    () => validationMessages.passwordAllowedSymbols,
  )
  .matches(/^(?=.*[0-9])/, () => validationMessages.passwordDigitRequired)
  .matches(/^(?=.*[a-z])/, () => validationMessages.passwordLowerCaseCharRequired)
  .matches(/^(?=.*[A-Z])/, () => validationMessages.passwordUpperCaseCharRequired)
  .matches(
    /^(?=.*[!@#&()–:;'`,?/*~$^+=<>%._])/,
    () => validationMessages.passwordSpecialCharRequired,
  );

export const passHintSchema = yup
  .string()
  .max(50, () => validationMessages.passwordHintLength)
  .required(getRequiredErrorMessage);
