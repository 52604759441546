/* eslint no-template-curly-in-string: 0 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  // predefined alerts
  alertConditionsAnniversary: {
    id: 'app.modules.frontDesk.alertConditions.anniversary',
    defaultMessage: `Wish {firstName} a happy anniversary!`,
  },
  alertConditionsBirthday: {
    id: 'app.modules.frontDesk.alertConditions.birthday',
    defaultMessage: `Wish {firstName} a happy birthday!`,
  },
  alertConditionsMissingPhoto: {
    id: 'app.modules.frontDesk.alertConditions.missingPhoto',
    defaultMessage: `Missing photo`,
  },
  alertConditionsMissingFirstName: {
    id: 'app.modules.frontDesk.alertConditions.missingFirstName',
    defaultMessage: `Missing first name`,
  },
  alertConditionsMissingLastName: {
    id: 'app.modules.frontDesk.alertConditions.missingLastName',
    defaultMessage: `Missing last name`,
  },
  alertConditionsMissingBirthday: {
    id: 'app.modules.frontDesk.alertConditions.missingBirthDate',
    defaultMessage: `Missing birth date`,
  },
  alertConditionsMissingSSN: {
    id: 'app.modules.frontDesk.alertConditions.missingSSN',
    defaultMessage: `Missing SSN`,
  },
  alertConditionsMissingPhone: {
    id: 'app.modules.frontDesk.alertConditions.missingPhone',
    defaultMessage: `Missing phone number`,
  },
  alertConditionsMissingEmail: {
    id: 'app.modules.frontDesk.alertConditions.missingEmail',
    defaultMessage: `Missing email`,
  },
  alertConditionsMissingAddress: {
    id: 'app.modules.frontDesk.alertConditions.missingAddress',
    defaultMessage: `Missing address`,
  },
  alertConditionsNotRegisteredForClubLife: {
    id: 'app.modules.frontDesk.alertConditions.notRegisteredForClubLife',
    defaultMessage: `Not registered for club life`,
  },
  alertConditionsCreditCardExpired: {
    id: 'app.modules.frontDesk.alertConditions.creditCardExpired',
    defaultMessage: `Credit card expired`,
  },
  alertConditionsUnsignedWaiver: {
    id: 'app.modules.frontDesk.alertConditions.unsignedWaiver',
    defaultMessage: `Unsigned waiver`,
  },
  alertConditionsMemberWillExpireSoon: {
    id: 'app.modules.personProfile.alertConditions.memberWillExpireSoon',
    defaultMessage: `Member will expire soon`,
  },
  alertConditionsMemberIsFrozen: {
    id: 'app.modules.frontDesk.alertConditions.memberIsFrozen',
    defaultMessage: `Member is frozen until {date}`,
  },
  alertConditionsPastDuePayments: {
    id: 'app.modules.frontDesk.alertConditions.pastDuePayments',
    defaultMessage:
      'Past due payment: {days} days passed since payment day and ${price} to be paid',
  },
  alertConditionsBalanceIsDue: {
    id: 'app.modules.frontDesk.alertConditions.balanceIsDue',
    defaultMessage: 'A balance is due.',
  },
  alertConditionsNotAllowedAtThisLocation: {
    id: 'app.modules.frontDesk.alertConditions.notAllowedAtThisLocation',
    defaultMessage: `Not allowed at this location`,
  },
  alertConditionsNotAllowedAtThisDayTime: {
    id: 'app.modules.frontDesk.alertConditions.notAllowedAtThisDayTime',
    defaultMessage: `Not allowed at this day time`,
  },
  alertConditionsInactiveMember: {
    id: 'app.modules.frontDesk.alertConditions.inactiveMember',
    defaultMessage: `Inactive member`,
  },
  // custom alerts
  alertConditionsUpcomingAppointment: {
    id: 'app.modules.frontDesk.alertConditions.upcomingAppointment',
    defaultMessage: `Upcoming appointment`,
  },
  alertConditionsMissedAppointment: {
    id: 'app.modules.frontDesk.alertConditions.missedAppointment',
    defaultMessage: `Missed appointment`,
  },

  alertConditionsNote: {
    id: 'app.modules.frontDesk.alertConditions.note',
    defaultMessage: `Note`,
  },
  alertConditionsMissingEmergencyContactInfo: {
    id: 'app.modules.frontDesk.alertConditions.missingEmergencyContactInfo',
    defaultMessage: `Missing emergency contact info`,
  },
  alertConditionsMissingBillingInfo: {
    id: 'app.modules.frontDesk.alertConditions.missingBillingInfo',
    defaultMessage: `Missing billing info`,
  },
  alertConditionsMissingInsuranceInfo: {
    id: 'app.modules.frontDesk.alertConditions.missingInsuranceInfo',
    defaultMessage: `Missing insurance info`,
  },
  alertConditionsMissingAllergies: {
    id: 'app.modules.frontDesk.alertConditions.missingAllergies',
    defaultMessage: `Missing allergies`,
  },
  alertConditionsMissingSalesperson: {
    id: 'app.modules.frontDesk.alertConditions.missingSalesperson',
    defaultMessage: `Missing salesperson`,
  },
  // alert types
  danger: {
    id: 'app.common.menuItems.danger',
    defaultMessage: 'Danger',
  },
  warning: {
    id: 'app.common.menuItems.warning',
    defaultMessage: 'Warning',
  },
  info: {
    id: 'app.common.menuItems.info',
    defaultMessage: 'Info',
  },
  // alert preferences
  usualCheckInsSound: {
    id: 'app.modules.frontDesk.preferences.usualCheckInsSound',
    defaultMessage: 'Sound alert on usual check-in',
  },
  stopAtTheDoorCheckInsSound: {
    id: 'app.modules.frontDesk.preferences.stopAtTheDoorCheckInsSound',
    defaultMessage: 'Sound alert on stop at the door check-in',
  },
  disableFrontDeskCheckInsSound: {
    id: 'app.modules.frontDesk.preferences.disableFrontDeskCheckInsSound',
    defaultMessage: 'Sound alerts only when not on the Frond desk screen',
  },
});
