import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  SvgIconTypeMap,
  makeStyles,
  Theme,
  Grid,
  Card,
  Typography,
  SvgIcon,
} from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { snackbar } from 'common/utils/snackbarUtils';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { Message } from 'common/interfaces/common';

interface IManePageMenuItemProps {
  path: string;
  title: Message;
  icon: OverridableComponent<SvgIconTypeMap> | null;
  isDisabled?: boolean;
  viewBox?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  routeItemWrapper: {
    minWidth: '200px',
  },
  routeItem: {
    padding: theme.spacing(2),
    boxShadow: theme.shadows[3],

    '&:hover': {
      boxShadow: theme.shadows[4],
    },
  },
  routeItemIcon: {
    fontSize: '40px',
    marginBottom: theme.spacing(1),
  },
}));

const MainPageMenuItem = ({
  path,
  isDisabled,
  icon,
  title,
  viewBox,
}: IManePageMenuItemProps): JSX.Element => {
  const classes = useStyles();
  const renderIntlMessage = useRenderIntlMessage();

  const menuItemTitle = renderIntlMessage(title);

  return (
    <Grid item key={title.id} className={classes.routeItemWrapper} xs={12} sm={6} md={4} lg={2}>
      <NavLink
        to={path}
        onClick={event => {
          if (isDisabled) {
            event.preventDefault();
            event.stopPropagation();
            snackbar.warning('Not implemented yet on this permissions level.');
          }
        }}
      >
        <Card className={classes.routeItem}>
          <Typography color="primary" className={classes.routeItemIcon}>
            {icon ? <SvgIcon fontSize="inherit" component={icon} viewBox={viewBox} /> : null}
          </Typography>

          <Typography variant="h5" component="span">
            {menuItemTitle}
          </Typography>
        </Card>
      </NavLink>
    </Grid>
  );
};

export default MainPageMenuItem;
